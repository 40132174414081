import React, {useState,useMemo, useEffect} from 'react'
import Utils from '../../../../../../library/Utils';
import { useDispatch } from 'react-redux';

import { Table,SelectColumnFilter, DateRangeColumnFilter } from '../../../../../../components/Table/Table';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../../../library/Variables';
import { selectAmlHistorydata,getAmlHistory,getAmlHistoryRecord,resetAmlHistoryRecord } 
    from '../../../../../../redux/Slices/IdVerification/Kenya/AmlHistory';
import Modal from "react-modal";
import AmlBusinessLookupModal from '../../../../../../components/Modals/AmlModals/AmlBRecordLookupModal';
import AmlIndividualLookupModal from '../../../../../../components/Modals/AmlModals/AmlIndividualLookupModal';



function AmlExtractionHistory({setContentToShow,accessToken}) {
    const dispatch = useDispatch()
    const amlHistoryState = useSelector(selectAmlHistorydata)
    const [amlBusinessModalOpen,setAmlBusinessModalOpen] = useState(false)
    const [amlIndividualModalOpen,setAmlIndividualModalOpen] = useState(false)
    const amlRecordData = useSelector(selectAmlHistorydata)
    let content
    let tableData = [];

    const closeAmlBusinessModal = ()=>{
      setAmlBusinessModalOpen(false)
    }

    const closeAmlIndividualModal = ()=>{
      setAmlIndividualModalOpen(false)
    }

    const customStyles = {
      content: {
          transition: "0.125s ease-in-out",
          transform: "scale(1)",
          height: "40%",
          width: "50%",
          top: "33%",
          left: "33%",
          padding: "0 0 0 0",
          overflowY: "scroll"
      },
      overlay: {
          zIndex: 1000,
          position: "absolute"
      }
      };
    


    const viewHistorySection = (key,type)=>{

      dispatch(resetAmlHistoryRecord())
      dispatch(getAmlHistoryRecord({accessToken,historyId:key}))

      if(type==='INDIVIDUAL'){
        setAmlIndividualModalOpen(true)
      }

      else if(type==='BUSINESS'){
        setAmlBusinessModalOpen(true)
      }
      

   /**
    *    if(type==='INDIVIDUAL'){
        setContentToShow('amlDetails')
      }
      else if(type==='BUSINESS'){
        setContentToShow('amlBusDetails')
      }
    */
      
    }


    useEffect(()=>{
      dispatch(getAmlHistory({accessToken}))
    },[])


    const theadersHistory = useMemo(() => [
        { 
          Header: 'Name',
          accessor: 'Name'
        },
        { 
          Cell: data => {
            let statusDisplay = data.row.original.status;
            return (
              <span style={{ 
                fontWeight:'700',
                color: statusDisplay==='AVAILABLE'?'#32A071':'#FF3A44'}}>
                {statusDisplay}
              </span>
            );
          },

          Header: 'AML Status',
          accessor: 'status',
          Filter: SelectColumnFilter,
          filter: 'includes'
        },

        { 
          Cell: data => {
            let statusDisplay = data.row.original.searchDate;
            //statusDisplay = Utils.formatLongDateMonthAndYear(statusDisplay)
            return (
              <span>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Search Date',
          accessor: 'searchDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },

        { 
          Header: 'Identity Type',
          accessor: 'identity',
          Filter: SelectColumnFilter,
          filter: 'includes'
        },

          
        
        {
          id: 'button-column',
          disableSortBy: true,
          Cell: data => {
            
            return (
              <div onClick={()=>viewHistorySection(data.row.original.key,data.row.original.identity)}
                   style={{backgroundColor:'rgba(64, 123, 255, 1)',
                           display:"flex",
                           alignItems:'center',
                           justifyContent:"center",
                           color:'white',
                           padding:'10px 0px',
                           borderRadius:'4px',
                           textAlign:'center',
                           cursor:'pointer'}}>
                <span state={{ key: data.row.original.key }} style={{display:"flex",color:'white',width:'60px',textDecoration:'none',alignItems:"center",justifyContent:"center"}} >View</span>
              </div>
            );
          }
        }]
      )


      //onClick={()=>getDetails(accessToken,history.uuid,history.verificationType,history)} 
      

    const getDetails = (token,uuid,type,history)=>{
    
    }


    if(amlHistoryState.activeHistoryStatus === FetchDataStatus.INITIAL){
        content = <Spinner/>
    }


    if(amlHistoryState.activeHistoryStatus === FetchDataStatus.FETCHING){
      content = <Spinner/>
  }


    if(amlHistoryState.activeHistoryStatus===FetchDataStatus.SUCCESS){
        if(Utils.isFieldEmpty(amlHistoryState.activeHistory)){
            content = 
                <div style={{height:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
                    <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>No Extracted data to be displayed</p>
                    <p>Create new One.</p>
                </div>
        }
        else{
           amlHistoryState.activeHistory.forEach(element => {
                tableData.push({
                  key: element.uuid,
                  Name: element.name,
                  status: element.status,
                  identity:element.identityType,
                  searchDate:Utils.formatLongDateAndTime(element.searchDate)
                })
              })

              content = <div style={{backgroundColor:"white",
                                     borderRadius:"15px",
                                     boxShadow:"0 4px 8px rgba(0, 0, 0, 0.1)",
                                     paddingTop:'40px',
                                     marginTop:'40px'}}>
                          <section style={{
                            fontWeight:"700",
                            fontSize:"20px",
                            color:'#1F2228',
                            paddingLeft:'20px'
                            }}>
                              AML Screening History
                          </section>

                          <Table tableClass="statement-type-table" 
                              pagination={true} 
                              pageSize={[10]} 
                              data={tableData} 
                              columns={theadersHistory}
                              tableExtra={false} 
                              tableFilter={true}
                              rowClass={"table-row-two-colored"} />
                        </div>
             ;
             
        }
    }

    if(amlHistoryState.activeHistoryStatus===FetchDataStatus.FAILURE){
      content = <div style={{backgroundColor:"white",
                borderRadius:"15px",
                boxShadow:"0 4px 8px rgba(0, 0, 0, 0.1)",
                padding:'40px',
                marginTop:'40px'}}>
                  <section style={{
                  fontWeight:"700",
                  fontSize:"20px",
                  color:'#1F2228',
                  paddingLeft:'20px'
                  }}>
                      AML Screening History
                  </section>

                  <section style={{marginTop:'20px'}}>
                      <p style={{color:'#363C4F',
                                 marginBottom:"5px",
                                 textAlign:'center'}}>
                                 Something went wrong!
                      </p>
                      <p style={{color:"red",
                                fontWeight:"700",
                                fontSize:"24px",
                                textAlign:'center'}}>
                          {amlHistoryState.activeHistoryErrorMessage}
                      </p>
                  </section>
                 </div>
    }


    const checkType = ['REGNUMBER']
  return (
        <div>
          {content}

          <Modal isOpen={amlBusinessModalOpen} 
                 onRequestClose={closeAmlBusinessModal} 
                 style={customStyles}
                 contentLabel="Aml Stored Businness Data Fetch" >
                <AmlBusinessLookupModal closeModal={closeAmlBusinessModal} 
                                        setContentToShow={setContentToShow} 
                />
          </Modal>

          <Modal isOpen={amlIndividualModalOpen} 
                 onRequestClose={closeAmlIndividualModal} 
                 style={customStyles}
                 contentLabel='AML Stored Individual Data Fetch'>

                 <AmlIndividualLookupModal closeModal={closeAmlIndividualModal}
                                           setContentToShow={setContentToShow}
                 />

          </Modal>
        </div>
  )
}

export default AmlExtractionHistory