import React from 'react'

function VerificationCard(props) {
    const filterValue = props.filterValue
    let data = props.data
    let header

    data = data.filter(value=>value.sectionName===filterValue)
    const listOfHeaders = ['HOME_OR_BUSINESS_ASSETS_VERIFICATION','CLIENT_HOME_VERIFICATION','GUARANTOR_BUSINESS_LOCATION']
    if(listOfHeaders.includes(filterValue)){
        if(filterValue==='HOME_OR_BUSINESS_ASSETS_VERIFICATION'){
            header = 'Home or Business Assets Verification'
        }
        if(filterValue==='CLIENT_HOME_VERIFICATION'){
            header = 'Client Home Verification'
        }
        if(filterValue==='GUARANTOR_BUSINESS_LOCATION'){
            header = 'Guarantor Business Location Map'
        }
       
    }
    
  return (
    <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
    <>
    <p style={{margin:'15px 0px',fontSize:'20px',fontWeight:'700',color:'rgba(42, 56, 164, 1)'}}>{header}</p>
    {
        data.map(value=>
        <section style={{display:'flex',justifyContent:'space-between',margin:'20px 0px'}}>
            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(106, 113, 125, 1)'}}>{value.friendlyName}</span>
            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(19, 15, 38, 1)'}}>{value.value?value.value:'---'}</span>
        </section>)
    }
    </>
    </div>
  )
}

export default VerificationCard