export const creditPaymentDataOne = {
    itemOne:'Total Amount',
    valueOne:'₦150,000',

    itemTwo:'Remaining Balance',
    valueTwo:"₦50,000"
}


export const creditPaymentDataTwo = {
    itemOne:'Next Payment Due',
    valueOne:'13th June, 2024',

    itemTwo:'Next Payment Amount',
    valueTwo:"₦50,000"
}