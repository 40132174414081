import React, { useEffect, useRef, useState } from "react";

function Dots(props) {
  const [showDots, setShowDots] = useState(true);
  const pageCount = props.pageCount;
  const ref = useRef();

  const validatePageNum = (target, length) => {
    const page = target.value ? Number(target.value) - 1 : 0;
    let value = parseInt(target.value);

    if((isNaN(value) === false) || (target.value > 0 || target.value < length)) {
      props.gotoPage(page);
      setShowDots(true);
    }
  }

  const handleEnter = (e) => {
    if(e.key === "Enter") {
      validatePageNum(e.target, pageCount);
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDots(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
  }, [ref]);

  return <>
    {showDots
      ? <div className="pagination-dots" onClick={() => setShowDots(false)}>...</div>
      : <input type="text" ref={ref} onKeyDown={(e) => handleEnter(e)} onBlur={(e) => validatePageNum(e.target, pageCount)} 
          className="pagination-input" />
    }
  </>
}

export default Dots;