import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";




export const updateReviewAsync = createAsyncThunk(
  "fraudDetect/updateTransactionReview",
  async (data) => {
    let body = JSON.stringify(data)
    const response = await clientApi.post(`/api/transactions/review`, body,
      {
        "Content-Type": "application/json",
      }
    );

    return response;
  }
);





