export const CreditList = {
    data:[
        {Description:'Loan Repayment',
            Amount:'₦150,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'13th June, 2024',
            PaymentStatus:'ONGOING',
            holder:'holder'
        },
        {Description:'Loan Repayment',
            Amount:'₦200,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'COMPLETED',
            holder:'holder'
        },
        {Description:'Service Repayment',
            Amount:'₦200,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'ONGOING',
            holder:'holder'
        },
        {Description:'Loan Repayment',
            Amount:'₦250,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'COMPLETED',
            holder:'holder'
        },
        {Description:'Loan Servicing',
            Amount:'₦200,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'COMPLETED',
            holder:'holder'
        },
        {Description:'Loan Repayment',
            Amount:'₦200,000',
            ApprovalDate:'7th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'ONGOING',
            holder:'holder'
        },
        {Description:'Loan Repayment',
            Amount:'₦50,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'COMPLETED',
            holder:'holder'
        },
        {Description:'Loan Repayment',
            Amount:'₦200,000',
            ApprovalDate:'12th April, 2024',
            PaymentDate:'14th June, 2024',
            PaymentStatus:'COMPLETED',
            holder:'holder'
        },
    ]
}



export const CreditListDataTwo = {
    data:[{
        description:'Installment 3',
        amount:"₦50,000",
        ApprovalDate:"12th April, 2024",
        paymentDate:"13th June, 2024",
        PaymentStatus:"ONGOING"
    },
    {
        description:'Installment 4',
        amount:"₦75,000",
        ApprovalDate:"15th April, 2024",
        paymentDate:"17th June, 2024",
        PaymentStatus:"COMPLETED"
    },
    {
        description:'Installment 5',
        amount:"₦95,000",
        ApprovalDate:"12th April, 2024",
        paymentDate:"13th June, 2024",
        PaymentStatus:"COMPLETED"
    },
    {
        description:'Installment 7',
        amount:"₦50,000",
        ApprovalDate:"12th April, 2024",
        paymentDate:"13th June, 2024",
        PaymentStatus:"ONGOING"
    }]

}