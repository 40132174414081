import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
let trackNo

 




export const getBusinessRcDetails = createAsyncThunk('kenyaKybID/businessRC', async (obj) => {
    trackNo = obj.data.number
    
    let data = JSON.stringify(obj.data)
    
   const naiData = await client.post('/verify/regnumber',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + obj.accessToken,
      'Content-Type': 'application/json'
     }
   });
    return naiData;
   }
  );



  export const getAmlDetails = createAsyncThunk('kenyaKybID/AML', async (params) => {
    
   
    const data = JSON.stringify(params.data)
    
   const amlResponse = await client.post('/verify/aml-screen',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });
  
    return amlResponse;
   }
  );

  
  export const getSpecificAml = createAsyncThunk('kenyaKybID/particularAml',async (params)=> {
    const amlSpecificData = await client.get(`/verify/aml-screen/${params.key}`,
    {
      "headers":
      {
       "Authorization": "Bearer " + params.accessToken,
      
      }
    });
     return amlSpecificData.data;
  })




export const getStandAloneDataHistory = createAsyncThunk('kenyaKybID/KybHistoryData',async (params)=> {

  try {
    const response = await client.get(
      `/verify/kychistory/${params.page}/${params.perPage}/BUSINESS`,
      {
        headers: {
          Authorization: "Bearer " + params.accessToken,
        },
      }
    );
 // This should now correctly log the data
    return response.data; // Make sure to return the correct data
  } catch (error) {
    console.error('Error fetching KYB history', error);
    throw error; // Throw error so it can be handled by Redux
  }
})


export const getReportDetailKyb = createAsyncThunk('kenyaKybID/KybReportData',async(params)=>{
  const type = params.type.toLowerCase()

  try {
    const response = await client.get(`/verify/history/${type}/${params.uuid}`,{
        headers: {
          Authorization: "Bearer " + params.accessToken,
        },
      }
    );
 
    return response.data; // Make sure to return the correct data
  } catch (error) {
    console.error('Error fetching KYB history', error);
    throw error; // Throw error so it can be handled by Redux
  }


})


export const kenyaKybDataSlice = createSlice({
  name: 'kenyaKybVerification',
  initialState: {
    activeExtractions: [],
    cacExtraction: [],
    tinExtraction:[],
    extractionStatus: PostDataStatus.INITIAL,
    historyStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    reportData:null,
    reportStatus:FetchDataStatus.INITIAL,
    reportErrorMessage:null,
    isReportError:false,
    standAloneStatus:FetchDataStatus.INITIAL,
    standAloneData:null,
    standAloneErrorMessage:null,


    rbcExtraction: {},
    rbcExtractionStatus: PostDataStatus.INITIAL,
    rbcExtractionisError: false,
    rbcExtractionErrorMessage: null,


    amlExtraction: null,
    amlExtractionStatus: PostDataStatus.INITIAL,
    amlExtractionisError: false,
    amlExtractionErrorMessage: null,

    amlParticipantExtraction: null,
    amlParticipantExtractionStatus: FetchDataStatus.INITIAL,
    amlParticipantExtractionisError: false,
    amlParticipantExtractionErrorMessage: null,

  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      //state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetKybData: (state)=>{
      state.extractionStatus = PostDataStatus.INITIAL
      state.isError = false
      state.errorMessage = null
    },

    resetReportKyb: (state)=>{
      state.reportData = null;
      state.reportStatus = FetchDataStatus.INITIAL
      state.reportErrorMessage = null
      state.isReportError = false
    }
    ,
    resetRbExtractionStatus: (state)=>{
      state.rbcExtraction = null;
      state.rbcExtractionStatus = PostDataStatus.INITIAL
      state.rbcExtractionErrorMessage = null
      state.rbcExtractionisError = false
    }
,
    resetAmlParticipant: (state)=>{
      state.amlParticipantExtraction = null;
      state.amlParticipantExtractionStatus = FetchDataStatus.INITIAL;
      state.amlParticipantExtractionisError = false;
      state.amlParticipantExtractionErrorMessage = null;
    }
  },
  
  extraReducers(builder) {

  builder.addCase(getBusinessRcDetails.pending, (state)=>{
    state.rbcExtractionStatus = PostDataStatus.FETCHING
    state.extractionStatus = PostDataStatus.FETCHING
  
  })
  .addCase(getBusinessRcDetails.fulfilled,(state,action)=>{
    const newObj = action.payload
    const mimeType = 'data:image/jpeg;base64,'
    const img = mimeType + action.payload['photo']
    const mainObj = {}
    delete newObj.photo;
    mainObj.data = newObj
    mainObj.verificationType = "REGNUMBER"
    mainObj.status = 'FOUND'
    mainObj.trackingNumber = action.payload['regNumber']
    mainObj.fullName = action.payload['businessName']
    mainObj.img = img
    state.rbcExtractionStatus = PostDataStatus.SUCCESS;
    state.extractionStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [mainObj,...state.activeExtractions]
    state.rbcExtraction = action.payload

  })
  .addCase(getBusinessRcDetails.rejected,(state,action)=>{
    state.rbcExtractionStatus = PostDataStatus.FAILURE
    state.extractionStatus = PostDataStatus.FAILURE
    state.rbcExtractionisError = true
    state.rbcExtractionErrorMessage = action.error.message
  })




  .addCase(getReportDetailKyb.pending, (state)=>{
    state.reportStatus = FetchDataStatus.FETCHING
  })
  
  .addCase(getReportDetailKyb.fulfilled, (state,action)=>{
    state.reportStatus = FetchDataStatus.SUCCESS
    state.reportData = action.payload
  })
  
  .addCase(getReportDetailKyb.rejected,(state,action)=>{
    state.reportStatus = FetchDataStatus.FAILURE
    state.reportErrorMessage = action.error.message
    state.isReportError = true
  })


  .addCase(getStandAloneDataHistory.pending, (state)=>{
    state.standAloneStatus = FetchDataStatus.FETCHING
  })
  .addCase(getStandAloneDataHistory.fulfilled,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.SUCCESS
    state.activeExtractions = action.payload
  })
  .addCase(getStandAloneDataHistory.rejected,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.FAILURE
    state.standAloneErrorMessage = action.error.message
  })


  .addCase(getAmlDetails.pending, (state)=>{
    state.amlExtractionStatus = PostDataStatus.FETCHING
  })
  .addCase(getAmlDetails.fulfilled,(state,action)=>{
    state.amlExtractionStatus = PostDataStatus.SUCCESS
    state.amlExtraction = action.payload
  })
  .addCase(getAmlDetails.rejected,(state,action)=>{
    state.amlExtractionStatus = PostDataStatus.FAILURE
    state.amlExtractionErrorMessage = action.error.message
  })


  .addCase(getSpecificAml.pending, (state)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.FETCHING
  })
  .addCase(getSpecificAml.fulfilled,(state,action)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.SUCCESS
    state.amlParticipantExtraction = action.payload
  })
  .addCase(getSpecificAml.rejected,(state,action)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.FAILURE
    state.amlParticipantExtractionErrorMessage = action.error.message
  })


  
  }
})


export const { addToExtractedData,resetKybData,resetAmlParticipant} = kenyaKybDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export const kybEtxractionStatus = state => state.kybEntireData.extractionStatus
export const selectKenyaKybExtractions = state => state.kenyaKybVerification
export const kybErrorState = state => state.kybEntireData.isError
export const kybErrorMsg = state => state.kybEntireData.errorMessage

export const {resetReportKyb,resetRbExtractionStatus} = kenyaKybDataSlice.actions


export default kenyaKybDataSlice.reducer;


/**
 * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    let mainArray = action.payload
    let newArray = []
    const mimeType = 'data:image/jpeg;base64,'
    for(let i=0;i<mainArray.length;i++){
      const newObj = mainArray[i]
      let img;
      let signature
      if(action.payload[i]['base64Image']){
        img = mimeType + action.payload[i]['base64Image']
      }
      
      if(action.payload[i]['photo']){
        img = action.payload[i]['photo']
      }
      if(action.payload[i]['signature']){
        signature = action.payload[i]['signature']
      }
      else{
        img = null;
      }
      
      const mainObj = {}
      delete newObj.base64Image;
      delete newObj.photo;
      delete newObj.signature;
      mainObj.data = newObj
      mainObj.kycType = action.payload[i]['verificationType']
      mainObj.status = 'FOUND'
      mainObj.img = img
      mainObj.signature = signature
      newArray.push(mainObj)
    }

    state.activeExtractions = [...newArray]
    state.historyStatus = PostDataStatus.SUCCESS;
  })
 */