import React from "react";

function FilterIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="filter-icon">
      <defs>
        <clipPath id="clip-filter">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.00037 2H21.5V21.5007H2.00037V2Z" fill="white"/>
        </clipPath>
      </defs>
      <g id="filter" clipPath="url(#clip-filter)">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.77337 11.5987C8.79137 11.6147 8.80837 11.6297 8.82537 11.6477C9.90437 12.7537 10.4994 14.2187 10.4994 15.7737V19.7577L12.7354 18.5397C12.9114 18.4437 13.0204 18.2557 13.0204 18.0487V15.7617C13.0204 14.2127 13.6094 12.7527 14.6784 11.6527L19.5154 6.5077C19.8284 6.1747 20.0004 5.7377 20.0004 5.2767V4.3407C20.0004 3.8767 19.6344 3.4997 19.1864 3.4997H4.31537C3.86637 3.4997 3.50037 3.8767 3.50037 4.3407V5.2767C3.50037 5.7377 3.67237 6.1747 3.98537 6.5067L8.77337 11.5987ZM10.1464 21.5007C9.94437 21.5007 9.74437 21.4467 9.56237 21.3387C9.21037 21.1287 8.99937 20.7577 8.99937 20.3457V15.7737C8.99937 14.6387 8.57637 13.5697 7.80537 12.7507C7.78237 12.7317 7.75937 12.7107 7.73937 12.6887L2.89337 7.5357C2.31737 6.9237 2.00037 6.1207 2.00037 5.2767V4.3407C2.00037 3.0497 3.03937 1.9997 4.31537 1.9997H19.1864C20.4614 1.9997 21.5004 3.0497 21.5004 4.3407V5.2767C21.5004 6.1197 21.1834 6.9217 20.6094 7.5347L15.7624 12.6887C14.9594 13.5167 14.5204 14.6057 14.5204 15.7617V18.0487C14.5204 18.8047 14.1114 19.4967 13.4534 19.8567L10.6924 21.3607C10.5204 21.4537 10.3334 21.5007 10.1464 21.5007Z" fill={props.iconFill}/>
      </g>
    </svg>
  )
}

export default FilterIcon;