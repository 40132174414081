import React,{useState,useEffect,useMemo} from 'react'
import { selectAuditTrailRecord,resetAuditTrail,generateAuditTrail } from '../../../../redux/Slices/Loans/AuditTrail/AuditTrail'
import { useSelector,useDispatch } from 'react-redux'
import Spinner from '../../../../svg/Spinner/Spinner'
import Utils from '../../../../library/Utils'
import './audit.css'
import { FetchDataStatus } from '../../../../library/Variables'
import { useParams } from 'react-router'
import { Table,SelectColumnFilter, DateRangeColumnFilter } from '../../../../components/Table/Table'

function AuditTrail({accessToken}) {
    const AuditTrailState = useSelector(selectAuditTrailRecord)
    const dispatch = useDispatch()
    const { key } = useParams();
    const [selectedTrail,setSelectedTrail] = useState(0)
    const [selectedData,setSelectedData] = useState({})
    //const [mainContent,setMainContent] = useState(<Spinner/>)
    let mainContent

    useEffect(()=>{
        dispatch(generateAuditTrail({accessToken,loanKey:key}))
    },[])


    useEffect(()=>{
        if(AuditTrailState.auditTrailStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(AuditTrailState.auditTrailData)){
            setSelectedData(AuditTrailState.auditTrailData[selectedTrail]) 
        }
    },[AuditTrailState.auditTrailStatus,selectedTrail])
    
        if(AuditTrailState.auditTrailStatus === FetchDataStatus.INITIAL){
        
            mainContent = <Spinner/>
            //setMainContent(content)
        }

        if(AuditTrailState.auditTrailStatus === FetchDataStatus.FETCHING){
            mainContent = <Spinner/>
            //setMainContent(content)
        }

        
        if(AuditTrailState.auditTrailStatus === FetchDataStatus.SUCCESS){
            
        if(Utils.isFieldEmpty(AuditTrailState.auditTrailData)){
            mainContent = <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
                                <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>No records currently in Audit Trail</p>
                                <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{AuditTrailState.errorAuditTrail}</p>
                            </div>
        }

        else{
            mainContent = <>
            <section className='auditRight'>
                <section style={{display:"flex",width:"100%",justifyContent:"space-between",margin:"10px auto"}}>
                    <span style={{textAlign:"left",width:"20%",fontSize:"18px",fontWeight:"700"}}>Time</span>
                    <span style={{textAlign:"left",width:"75%",fontSize:"18px",fontWeight:"700"}}>Activity</span>
                </section>
                <section className='auditRightContent'>
                    {AuditTrailState.auditTrailData.map((element,index)=>
                    <div onClick={()=>setSelectedTrail(index)} 
                         key={index} 
                         className={selectedTrail===index?'selectedTrail':'unSelectedTrail'}>
                        <section>
                            {element.date}
                        </section>
                        <section style={{textAlign:"left",width:"75%"}}>
                            {`${element.userName} changed the ${element.name} feature`}
                        </section>
                    </div>)}
                </section>
            </section>

            <section className='auditLeft'>
                <span style={{fontWeight:'700',fontSize:"20px"}}>About this Activity</span>
                <section style={{display:"flex",justifyContent:"space-between",margin:"15px auto"}}>
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <span>
                            User
                        </span>
                        <span style={{color:"#407BFF"}}>
                            {selectedData.userName}
                        </span>
                    </div>

                    <div style={{display:"flex",flexDirection:"column"}}>
                        <span>
                            Email
                        </span>
                        <span>
                            {selectedData.email}
                        </span>
                    </div>
                </section>

                <section style={{display:"flex",justifyContent:"space-between",margin:"15px auto"}}>
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <span>
                            Date
                        </span>
                        <span style={{color:"#407BFF"}}>
                            {selectedData.date}
                        </span>
                    </div>

                   
                </section>

                <section style={{display:"flex",justifyContent:"space-between",margin:"15px auto",flexDirection:"column"}}>
                    <span style={{fontWeight:'500',fontSize:"18px"}}>Property</span>
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <span >
                            {selectedData.name}
                        </span>
                    </div>

                   
                </section>


                <section style={{display:"flex",justifyContent:"space-between",margin:"15px auto",flexDirection:"column"}}>
                    <span style={{fontWeight:'500',fontSize:"18px"}}>Values</span>
                    <section style={{display:"flex",justifyContent:"space-between",margin:"5px auto",width:"100%"}}>
                    <div style={{display:"flex",flexDirection:"column",width:"50%"}}>
                        <span>
                            Old Value
                        </span>
                        <span style={{color:"#F30D0D"}}>
                            {selectedData.oldValue}
                        </span>
                    </div>

                    <div style={{display:"flex",flexDirection:"column",width:"50%"}}>
                        <span>
                            New Value
                        </span>
                        <span style={{color:"#00770C"}}>
                            {selectedData.newValue}
                        </span>
                    </div>
                </section>

                   
                </section>



            </section>
        </>
        }
        

        //setMainContent(content)
        }

        if (AuditTrailState.auditTrailStatus === FetchDataStatus.FAILURE){
            mainContent = <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
                                <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
                                <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{AuditTrailState.errorAuditTrail}</p>
                            </div>
            //setMainContent(content)
        }


    
    

    

   


  return (
    <div className='auditHold'>
        {mainContent}
    </div>
  )
}

export default AuditTrail