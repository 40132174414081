import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

const initialState = {
    status: FetchDataStatus.INITIAL,
    isError: false,  
    errorMessage: null,
    user: null
  };

export const getUserAsync = createAsyncThunk('user',  async (accessToken) => {    
    let response = await client.get('/platformuser',
      {
        "headers":
        {
          "Authorization": "Bearer " + accessToken
        }
      });

    return response.data;
  });

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      initial: initialState,
      setUser: (state, action) => {
        state.user = action.payload;
      }
    },
    extraReducers(builder) {
      builder.addCase(getUserAsync.pending, (state, action) => {
        state.status = FetchDataStatus.FETCHING;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.status = FetchDataStatus.SUCCESS;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.status = FetchDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
    }
  });

export const { initial, setUser} = userSlice.actions;

export const selectUser = state => state.user;

export default userSlice.reducer; 