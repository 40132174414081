import React from "react";
import "./NotEnabled.css";

function NotEnabled(props) {
  return <div className="not-enabled-body">
    <div className="not-enabled-header">
      <h2 className="detail-header">{props.name} has not been configured for your organization</h2>
    </div>
    <p>Please <a href={"mailto:support@periculum.io"} className="link-color">contact us</a> {props.message}</p>
  </div>
}

export default NotEnabled;