import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

function PermissionRow(props) {
  const row = props.row;
  const isDisabled = props.isDisabled;
  const { register, setValue } = useFormContext();
  const [val, setVal] = useState(row.state || false);
  const label = row.permissionFriendlyName || row.label;
  const fieldName = row.permissionName || row.name;

  return <div className="permission-row">
    <p>{label}</p>
    <label className="switch">
      <input type="checkbox" {...register(fieldName)} disabled={isDisabled} 
        onChange={(e) => {
          setValue(fieldName, e.target.checked);
          setVal(!val);
        }} checked={val} />
      <span className="slider"></span>
    </label>
  </div>
}

export default PermissionRow;