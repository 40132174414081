import React, { useEffect, useMemo, useState } from "react";
import Modal from 'react-modal';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmBox from "../../../../../components/Modals/ConfirmBox";
import Utils from "../../../../../library/Utils";
import { CustomCreditScoreViews, FetchDataStatus, PostDataStatus } from "../../../../../library/Variables";
import {
  getAllConsolidatedCreditScore,
  getCustomScoreCardDefinitions,
  resetAllCustomCreditScore,
  resetCustomCreditScore,
  resetUpdateCreditScoreStatus,
  selectConsolidatedCreditScoreState,
  updateConsolidatedCreditScoreAsync
} from "../../../../../redux/Slices/ConsolidatedCustomCreditScore";
import Spinner from "../../../../../svg/Spinner/Spinner";
import { configureInitialView } from "../../../../Statement/CreditScore/CreditScoreUtils";
import ScoreCardForm from "../../../../Statement/CreditScore/Sections/ScoreCardForm";
import ScoreCards from "../../../../Statement/CreditScore/Sections/ScoreCards";
import './ConsolidatedCreditScore.css';


if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

function ConsolidatedcustomCreditScore(props) {
  let content = <Spinner />;
  let initialView = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const configuration = props.configuration;
  const keyFromRoute = props.keyFromRoute;
  const user = props.user;
  const customCreditScore = useSelector(selectConsolidatedCreditScoreState);
  const [view, setView] = useState(CustomCreditScoreViews.INITIAL);
  const [radioSelected, setRadioSelected] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);


  const params = {
    accessToken: accessToken,
    statementKey: keyFromRoute
  }

  const theadersCreditScoreRules = useMemo(() => [
    { 
      id: 'friendlyLeftHandSide',
      accessor: 'friendlyLeftHandSide'
    },
    { 
      id: 'actualLeftHandSide',
      accessor: 'actualLeftHandSide'
    },
    {
      id: 'friendlyOperator',
      accessor: 'friendlyOperator'
    },
    {
      id: 'friendlyRightHandSide',
      accessor: 'friendlyRightHandSide'
    },
    {
      Cell: data => {
        return (
          <span className="detail-header">
            {data.row.original.obtainedPoints}
          </span>
        );
      },
      id: 'obtainedPoints',
      accessor: 'obtainedPoints'
    }], []
  )

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '33%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  useEffect(()=>{
    dispatch(resetAllCustomCreditScore())
    dispatch(resetCustomCreditScore())
    dispatch(resetUpdateCreditScoreStatus())
  },[])

  const handleRadioCallback = (type, selection) => {
    setRadioSelected(selection);

    if(!Utils.isFieldEmpty(customCreditScore.customScoreCards[selection].customParameters)) {
      setView(type);
    } else {
      openModal();
    }
  }

  const handleFormCallback = (formData) => {
    setView(CustomCreditScoreViews.FETCHING);

    let formParams = {
      accessToken: accessToken,
      statementKey: keyFromRoute,
      scorecardDefinitionName: customCreditScore.customScoreCards[radioSelected].scorecardDefinitionName,
      CustomScorecardFields: []
    };

    for(const property in formData) {
      formParams.CustomScorecardFields.push({
        fieldname: property,
        fieldvalue: formData[property]
      });
    }

    dispatch(updateConsolidatedCreditScoreAsync(formParams));
  }

  function openModal(){
    setIsOpen(true);
  }

  function afterOpenModal(){
  }

  function closeModal(){
    setIsOpen(false);
  }

  const handleConfirmCallback = () => {
    let formParams = {
      accessToken: accessToken,
      statementKey: keyFromRoute,
      scorecardDefinitionName: customCreditScore.customScoreCards[radioSelected].scorecardDefinitionName,
      CustomScorecardFields: []
    };

    dispatch(updateConsolidatedCreditScoreAsync(formParams));
  }

  useEffect(() => {
    if(customCreditScore.creditScoresStatus === FetchDataStatus.INITIAL) {
      dispatch(getAllConsolidatedCreditScore(params));
    }

    if(customCreditScore.customCreditScoreStatus === FetchDataStatus.INITIAL) {
      dispatch(getCustomScoreCardDefinitions(params));
    }

    if(customCreditScore.updateCustomCreditScoreStatus === PostDataStatus.SUCCESS) {
      toast.success("Score Card updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      dispatch(resetAllCustomCreditScore());
      setRadioSelected(0);
      closeModal();
      setView(CustomCreditScoreViews.INITIAL);
    } else if(customCreditScore.updateCustomCreditScoreStatus === PostDataStatus.FAILURE) {
      toast.error("Unable to update score card", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      dispatch(resetCustomCreditScore());
      setView(CustomCreditScoreViews.INITIAL);
    }
  }, [customCreditScore, dispatch])

  if((!Utils.isFieldEmpty(customCreditScore.creditScores)) && customCreditScore.creditScoresStatus === FetchDataStatus.SUCCESS
    && customCreditScore.customCreditScoreStatus === FetchDataStatus.SUCCESS) {
    initialView = configureInitialView({
      credit: customCreditScore.creditScores,
      accordion: {
        id: "custom-credit-score-accordion"
      },
      configuration: configuration, 
      tableHeaders: theadersCreditScoreRules, 
      update: () => setView(CustomCreditScoreViews.SCORE_CARDS),
      isUpdatable: true,
      user: user
    }).mainBodyContent;

    if(view === CustomCreditScoreViews.INITIAL) {
      content = initialView;
    } else if(view === CustomCreditScoreViews.SCORE_CARDS) {  
      content = <ScoreCards 
      data={customCreditScore.customScoreCards} radioSelected={radioSelected}
        parentCancelCallback={() => setView(CustomCreditScoreViews.INITIAL)} 
        parentCallback={(type, selection) => handleRadioCallback(type, selection)} />
    } else if(view === CustomCreditScoreViews.FORM) {
      content = <ScoreCardForm 
      data={customCreditScore.customScoreCards} 
      radioSelected={radioSelected} 
        parentBackCallback={() => setView(CustomCreditScoreViews.SCORE_CARDS)} 
        parentCallback={(formData) => handleFormCallback(formData)} />
    } else {
      content = <Spinner />
    }
  }
  
  return (
    <div className="cs-main-body">
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} 
        contentLabel="Update Credit Score">
        <ConfirmBox close={closeModal} typeStatus={customCreditScore.updateCustomCreditScoreStatus}
          initialStateTitle={"Update Credit Score"} failTitle={"Credit Score Update Failed"}
          initialStateMessage={"This action will incur a charge to your account. Are you sure you wish to proceed?"}
          fetchingTitle={"Updating Credit Score. Please wait..."} successTitle={"Credit Score Updated"}
          successMessage={"Click done to view"}
          resetStatus={() => dispatch(resetCustomCreditScore())} parentCallback={() => handleConfirmCallback()} />
      </Modal>
      {content}
    </div>
  )
}

export default ConsolidatedcustomCreditScore;