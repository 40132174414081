import React from "react";
import Utils from "../../library/Utils";

function Speedometer(props) {
  return (
    <>
      <svg className="speedometer" width="2042" height="1108" viewBox="0 0 2042 1108">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_7" data-name="Rectangle 7" width="2042" height="1108"/>
          </clipPath>
          <filter id="Intersection_27" x="301" y="265" width="1440" height="793" filterUnits="userSpaceOnUse">
            <feOffset input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="15" result="blur"/>
            <feFlood floodColor="#4f7bbb" floodOpacity="0.2"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="speedometer" clipPath="url(#clip-path)">
          <g id="Group_142" data-name="Group 142" transform="translate(0 4)">
            <rect id="Rectangle_365" data-name="Rectangle 365" width="2042" height="232" transform="translate(0 763)" fill="none"/>
            <text id="minScore" transform="translate(76 995) rotate(-90)" fontSize="80" fontFamily="'DM Sans', sans-serif"><tspan x="0" y="0">{props.minScore}</tspan></text>
            <text id="maxScore" transform="translate(1966 828) rotate(90)" fontSize="80" fontFamily="'DM Sans', sans-serif"><tspan x="0" y="0">{props.maxScore}</tspan></text>
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Intersection_27)">
            <path id="Intersection_27-2" data-name="Intersection 27" d="M4459.57-10285q-.568-13.922-.57-28c0-372.792,302.208-675,675-675s675,302.208,675,675q0,14.061-.57,28Z" transform="translate(-4113 11298)" fill="#fff"/>
          </g>
          <text id="_passingSentiment" transform="translate(1021 955)" fontSize="110" fontFamily="'DM Sans', sans-serif"><tspan x="0" y="0" textAnchor="middle">{props.passingSentiment}</tspan></text>
          <text id="points" transform="translate(1021 795)" fontSize="275" fontFamily="'DM Sans', sans-serif" fontWeight="600"><tspan x="0" y="0" textAnchor="middle">
              {
                props?.checkCustomGrade ?
                props?.customGrade : 
                Utils.checkNegativeNumber(props.obtainedPoints)
              }
            </tspan>
          </text>
          <text id="Score" transform="translate(1021 517)" fontSize="120" fontFamily="'DM Sans', sans-serif"><tspan x="-146.982" y="0">Score</tspan></text>
          <g id="Group_9" data-name="Group 9" transform="translate(2638 -366)">
            <path id="Path_52" data-name="Path 52" d="M1808.917,944.909a34.787,34.787,0,0,0,34.984-35.933A965.542,965.542,0,0,0,1775.4,585.6,941.593,941.593,0,0,0,1578.42,281a907.322,907.322,0,0,0-294.8-203.53,881.684,881.684,0,0,0-695.472,0A907.336,907.336,0,0,0,293.355,281,941.586,941.586,0,0,0,96.379,585.6a965.489,965.489,0,0,0-68.5,323.372,34.784,34.784,0,0,0,34.983,35.933h0c19.688,0,35.573-16.1,36.371-35.933A889.7,889.7,0,0,1,162.248,613.8a867.731,867.731,0,0,1,181.52-280.7A836.136,836.136,0,0,1,615.434,145.531a812.507,812.507,0,0,1,640.9,0A836.1,836.1,0,0,1,1528,333.091a867.743,867.743,0,0,1,181.525,280.7,889.8,889.8,0,0,1,63.019,295.181c.8,19.834,16.681,35.933,36.369,35.933Z" transform="translate(-2552.857 439.546)" fill="#4eb151"/>
            <path id="Path_59" data-name="Path 59" d="M61.119,944.909a33.091,33.091,0,0,1-33.263-34.137c6.57-186.647,66.895-367.329,173.623-518.708C314.7,231.472,474.31,112.133,657.34,51.217a880.879,880.879,0,0,1,562.691,1.852c172.325,58.608,323.482,169.082,434.275,316.876,11.09,14.8,7.648,35.749-7.163,46.749l-3.376,2.508c-15.307,11.369-36.843,7.762-48.379-7.472-101.86-134.511-240.167-235.088-397.657-288.652a811.74,811.74,0,0,0-518.522-1.707C510.548,177.506,363.468,287.477,259.133,435.465c-97.839,138.771-153.361,304.261-159.9,475.311-.72,18.841-15.813,34.133-34.515,34.133Z" transform="translate(-2552.904 439.546)" fill="#87de65"/>
            <path id="Path_60" data-name="Path 60" d="M61.119,944.909a33.091,33.091,0,0,1-33.263-34.137A963.011,963.011,0,0,1,138.109,495.5C210.85,357.63,315.892,240.813,443.625,155.74S717.7,20.052,869.259,8.531a882.4,882.4,0,0,1,411.5,67.7c17.015,7.212,24.418,27.192,16.953,44.211l-2.049,4.67c-7.667,17.47-28.035,25.131-45.534,17.81A813.058,813.058,0,0,0,874.493,82.037C734.832,92.654,599.979,139.294,482.272,217.689S267.769,403.73,200.739,530.776a887.358,887.358,0,0,0-101.5,380c-.72,18.845-15.813,34.137-34.516,34.137Z" transform="translate(-2552.904 439.545)" fill="#f2d366"/>
            <path id="Path_61" data-name="Path 61" d="M61.119,919.5a33.091,33.091,0,0,1-33.263-34.137c6.983-198.407,74.692-389.757,193.822-546.276,118.893-156.206,282.487-269.161,467-323.069,18.105-5.29,36.614,6.133,40.956,24.628l1.214,5.175c4.272,18.2-6.8,36.414-24.612,41.663C536.968,137.376,386.908,241.231,277.746,384.653,168.5,528.181,106.185,703.491,99.238,885.366c-.72,18.845-15.813,34.137-34.515,34.137Z" transform="translate(-2552.904 464.952)" fill="#ffbc73"/>
            <path id="Path_62" data-name="Path 62" d="M61.119,633.182a33.091,33.091,0,0,1-33.263-34.137c5.883-167.122,54.869-329.6,141.872-470.609,10.04-16.271,31.608-20.194,47.115-9.111l3.4,2.426c15.011,10.727,18.718,31.543,9.02,47.305C149.934,298.007,105.07,446.378,99.238,599.049c-.72,18.841-15.813,34.133-34.515,34.133Z" transform="translate(-2552.904 751.273)" fill="#ff725e"/>
          </g>
        </g>
      </svg>
      <svg width="153.037" height="823" viewBox="0 0 153.037 823" className="needle" transform={Utils.transformSpeedometerNeedle(props.minScore, props.maxScore, props.obtainedPoints)} >
        <g id="Group_143" data-name="Group 143" transform="translate(-944.482 -190.001)">
          <path id="Polygon_12" data-name="Polygon 12" d="M66.988,12.455a12,12,0,0,1,19.06,0l52.226,68.253A12,12,0,0,1,128.745,100H24.292a12,12,0,0,1-9.53-19.292Z" transform="translate(944.482 190.001)" fill="#2A38A4" />
          <rect id="Rectangle_361" data-name="Rectangle 361" width="85" height="723" transform="translate(979.001 290.001)" fill="none" />
        </g>
      </svg>
    </>
  )
}

export default Speedometer;