export const rulesSelector = (state) => state.rules;
export const createRuleSelector = (state) => state.createRule;

export const updateRuleSelector = (state) => state.updateRule;
export const deleteRuleSelector = (state) => state.deleteRule;

export const deleteCustomRuleSelector = (state) => state.deleteCustomRule;
export const ruleSelector = (state) => state.rule;

export const createCustomRuleSelector = (state) => state.createCustomRule;

export const customRuleSelector = (state) => state.customRules;

export const customRuleByIdSelector = (state) => state.customRule;
