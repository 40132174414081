import React, { useMemo,useEffect,useState } from "react";
import { Chart as 
  ChartJS, 
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip, 
  Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DetailsCard from '../../../components/DetailsCard/DetailsCard';
import List from '../../../components/List/List';
import Utils from "../../../library/Utils";
import { Table } from '../../../components/Table/Table';
import dropDownIcon from '../../../assests/icons/dropDownIconNew.png';
import dropLeft from '../../../assests/icons/dropLeftNewIcon.png'
//import dropdownIcon from '../../../assests/icons/dropdownIcon.png'

function Pattern(props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  );

  let content = null;
  let overviewContent = null;
  let transactionsPerAgentLocation = [];
  let transactionsPerAgentLocationContent = null;
  let saccoTransactions = [];
  let saccoTransactionsContent = null;
  const [selfTransferOutflowTransactionsTable,setselfTransferOutflowTransactionsTable] = useState([]);
  const [selfTransferOutflowYear,setselfTransferOutflowYear] = useState([]);
  const [selfTransferInflowTransactionsTable,setselfTransferInflowTransactionsTable] = useState([]);
  let selfTransferTransactionsContent = null;
  let breakdownContent = null;
  let pieLabelsDynamic = [];

  const pattern = props.data;
  const agentLocation = props.agentLocation;
  const configuration = props.configuration;
  const pieChartColors = ["#4EB151", "#FF725E", "#CEF266", "#F2D366", "#FFAB5E", "#87DE65", "#DD2E15"];
  let OutflowTransfer = [
    {month:'January',data:[],total:0},
    {month:'February',data:[],total:0},
    {month:'March',data:[],total:0},
    {month:'April',data:[],total:0},
    {month:'May',data:[],total:0},
    {month:'June',data:[],total:0},
    {month:'July',data:[],total:0},
    {month:'August',data:[],total:0},
    {month:'September',data:[],total:0},
    {month:'October',data:[],total:0},
    {month:'November',data:[],total:0},
    {month:'December',data:[],total:0},
  ]

  let InflowTransfer = [
    {month:'January',data:[],total:0},
    {month:'February',data:[],total:0},
    {month:'March',data:[],total:0},
    {month:'April',data:[],total:0},
    {month:'May',data:[],total:0},
    {month:'June',data:[],total:0},
    {month:'July',data:[],total:0},
    {month:'August',data:[],total:0},
    {month:'September',data:[],total:0},
    {month:'October',data:[],total:0},
    {month:'November',data:[],total:0},
    {month:'December',data:[],total:0},
  ]
  const monthsData = ['January','February','March','April','May','June','July','August','September','October','November','December']
  const [OrderedOutflowTransfer,setOrderedOutflow] = useState(OutflowTransfer)
  const [outflowIndex,setOutflowIndex] = useState(null)
  const [outflowYearIndex,setOutflowYearIndex] = useState(null)
  const [OrderedInflowTransfer,setOrderedInflow] = useState(InflowTransfer)
  const [inflowIndex,setInflowIndex] = useState(null)
  const [inflowYearIndex,setInflowYearIndex] = useState(null)
  const[trigger,setTrigger] = useState(false)


  useEffect(()=>{
    if(!Utils.isFieldEmpty(pattern?.selfTransferOutflowTransactions)){
    let yearArray = []
    let restructuredArray = []
    pattern?.selfTransferOutflowTransactions.forEach((element,index)=>{
      let entireDate = element.date.split(' ')[0]
      let monthNumber = parseInt(entireDate.split('-')[1]) 
      let yearNumber = entireDate.split('-')[0]

      if(!yearArray.includes(yearNumber)){
        yearArray.push(yearNumber)
      }
      
    })

    for (let i = 0; i < yearArray.length; i++) {
      // Create a new object with the current entry as a key
      let orderedNew = [
        {month:'January',data:[],total:0},
        {month:'February',data:[],total:0},
        {month:'March',data:[],total:0},
        {month:'April',data:[],total:0},
        {month:'May',data:[],total:0},
        {month:'June',data:[],total:0},
        {month:'July',data:[],total:0},
        {month:'August',data:[],total:0},
        {month:'September',data:[],total:0},
        {month:'October',data:[],total:0},
        {month:'November',data:[],total:0},
        {month:'December',data:[],total:0},
      ]
      let newObj = {};
      newObj.count = 0
      newObj.year = yearArray[i]; // You can set the value to something specific if needed
      newObj.total = 0

      pattern?.selfTransferOutflowTransactions.forEach((element,index)=>{
        
        let entireDate = element.date.split(' ')[0]
        let monthNumber = parseInt(entireDate.split('-')[1]) 
        let yearNumber = entireDate.split('-')[0]

        if(yearArray[i]===yearNumber){
          newObj.total += element.amount

          if(orderedNew[monthNumber-1]?.data){
            orderedNew[monthNumber-1].data?.push({date:element.date,amount:element.amount})
            orderedNew[monthNumber-1].total += element.amount
            newObj.count +=1
          
          }
          newObj.details = orderedNew
          // Push the new object to the newArray
        }
      })

      restructuredArray.push(newObj);
      
  }

  setOrderedOutflow(restructuredArray)
    }

    if(!Utils.isFieldEmpty(pattern?.selfTransferInflowTransactions)){
      let yearArray = []
      let restructuredArray = []

      pattern?.selfTransferInflowTransactions.forEach((element,index)=>{
        let entireDate = element.date.split(' ')[0]
        let monthNumber = parseInt(entireDate.split('-')[1]) 
        let yearNumber = entireDate.split('-')[0]
  
        if(!yearArray.includes(yearNumber)){
          yearArray.push(yearNumber)
        }
        
      })
      
      for (let i = 0; i < yearArray.length; i++) {
        // Create a new object with the current entry as a key
       
        let orderedNew = [
          {month:'January',data:[],total:0},
          {month:'February',data:[],total:0},
          {month:'March',data:[],total:0},
          {month:'April',data:[],total:0},
          {month:'May',data:[],total:0},
          {month:'June',data:[],total:0},
          {month:'July',data:[],total:0},
          {month:'August',data:[],total:0},
          {month:'September',data:[],total:0},
          {month:'October',data:[],total:0},
          {month:'November',data:[],total:0},
          {month:'December',data:[],total:0},
        ]
        let newObj = {};
        newObj.count = 0
        newObj.year = yearArray[i]; // You can set the value to something specific if needed
        newObj.total = 0
  
        pattern?.selfTransferInflowTransactions.forEach((element,index)=>{
          
          let entireDate = element.date.split(' ')[0]
          
          let yearNumber = entireDate.split('-')[0]
  
          if(yearArray[i]===yearNumber){
            newObj.total += element.amount
            let monthNumber = parseInt(entireDate.split('-')[1]) 
            if(orderedNew[monthNumber-1]?.data){
              orderedNew[monthNumber-1].data?.push({date:element.date,amount:element.amount})
              orderedNew[monthNumber-1].total += element.amount
              newObj.count +=1
            
            }
            newObj.details = orderedNew
            // Push the new object to the newArray
          }
        })
  
        restructuredArray.push(newObj);
        
    }
    setOrderedInflow(restructuredArray)
    
      }

    
  },[pattern?.selfTransferOutflowTransactions,pattern?.selfTransferInflowTransactions])

  const pieChartOptions = {
    cutout: 90,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let dataset = context.dataset.data;
            let total = 1;
            let currentValue = dataset[context.dataIndex];
            let percentage = Utils.formatPercentage(currentValue/total);
      
            return percentage;
          }
        }
      },
      legend: {
        display: false
      },
    }
  }

  const theadersAgentTransactions = useMemo(() => [
    {
      Header: 'Overview',
      disableSortBy: true,
      columns: [
        {
          Header: 'Agent Location',
          disableSortBy: true,
          Cell: data => {
            return Utils.capitalizeFirstLetter(data.row.original.agentLocation).replace("-", "")
          }
        },
        {
          Header: 'Total Transactions',
          accessor: 'numberOfTransactions',
          disableSortBy: true,
        }
      ]
    },
    {
      Header: 'Type',
      disableSortBy: true,
      columns: [
        {
          Header: 'Credit',
          accessor: 'credit',
          disableSortBy: true,
        },
        {
          Header: 'Debit',
          accessor: 'debit',
          disableSortBy: true,
        }
      ]
    },
    {
      Header: 'Period',
      disableSortBy: true,
      columns: [
        {
          Header: 'Morning',
          accessor: 'morning',
          disableSortBy: true,
        },
        {
          Header: 'Afternoon',
          accessor: 'afternoon',
          disableSortBy: true,
        },
        {
          Header: 'Evening',
          accessor: 'evening',
          disableSortBy: true,
        },
      ]
    }], []
  )

  const theadersSaccoTransactions = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Type',
      accessor: 'type'
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    }], []
  )

  const theadersSelfTransferTransactions = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      disableSortBy: true,
    }], []
  )

  const prepareTransactionPatternConfigurationBucket = (array) => {
    let buckets = array.slice();
    // Initial sort of buckets by min value in asc order
    let bucketsSorted = buckets.sort((firstMin, secondMin) => firstMin.min - secondMin.min);
    let pieData = [];
    let mostFrequentRange = null;
    let lowestRange = null;

    let bucketPercentages = [];

    bucketsSorted.forEach((element, i) => {
      if(i === 0){
        lowestRange = Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.max)
        pieLabelsDynamic[i] = 'Less Than ' + lowestRange;
        bucketPercentages.push({ percent: element.percentageOfTransactionsInBucket, label: 'Less Than ' + lowestRange });
      } else if(i === (bucketsSorted.length - 1)){
        pieLabelsDynamic[i] = 'Greater Than ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.min);
        bucketPercentages.push({ percent: element.percentageOfTransactionsInBucket, label: 'Greater Than ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.min) });
      } else {
        pieLabelsDynamic[i] = 'Between ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.min) +
        ' To ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.max);
        bucketPercentages.push({ percent: element.percentageOfTransactionsInBucket, label: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.min) +
        ' To ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.max) });
      }

      pieData.push(Utils.checkNull(element.percentageOfTransactionsInBucket))
    })

    // Sort buckets again by percentageOfTransactionsInBucket in desc order
    let bucketPercentageSorted = bucketPercentages.sort((firstMin, secondMin) => secondMin.percent - firstMin.percent);
    
    mostFrequentRange = bucketPercentageSorted[0].label;

    return { pieData, mostFrequentRange, lowestRange };
  }

  if(configuration.featureFlags.isMissingTransactionsEnabled === true) {
    overviewContent = <DetailsCard detailClassName={"missing-transactions-enabled"} detailContent={<>
      <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} detailClass="detail-header"
        listContent={[
          {
            header: 'Total Transactions',
            detail: Utils.checkNull(pattern.totalNumberOfTransactions)
          },
          {
            header: 'Card Request',
            detail: Utils.checkNull(pattern.numberOfCardRequests)
          }
        ]}
      />
      <hr/>
      <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} detailClass="detail-header"
        listContent={[
          {
            header: 'Return Cheque',
            detail: Utils.checkNull(pattern.returnCheque)
          },
          {
            header: 'Missing Transactions',
            detail: Utils.checkNull(pattern.missingTransactions)
          }
        ]} 
      />
    </>} />
  } else {
    overviewContent = <DetailsCard title={'Overview'} titleClass={"detail-header"} detailClassName={'st-detail-content'}
      detailContent={<List detailClass="detail-header" listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
        listContent={[
          {
            iconSrc: <img src="/assets/icons/receipt-color.svg" alt="" />,
            header: 'Total Transactions',
            detail: Utils.checkNull(pattern.totalNumberOfTransactions),
          },
          {
            iconSrc: <img src="/assets/icons/card-tick-color.svg" alt="" />,
            header: 'Card Request',
            detail: Utils.checkNull(pattern.numberOfCardRequests),
          },
          {
            iconSrc: <img src="/assets/icons/card-color.svg" alt="" />,
            header: 'Return Cheque',
            detail: Utils.checkNull(pattern.returnCheque),
          }
        ]} 
      />} 
    />
  }

  if (!Utils.isFieldEmpty(agentLocation) && !Utils.isFieldEmpty(agentLocation.transactionsPerAgentLocation)) {
    agentLocation.transactionsPerAgentLocation.forEach(element => {
      transactionsPerAgentLocation.push(element)
    });

    transactionsPerAgentLocation.sort((a, b) => b.numberOfTransactions - a.numberOfTransactions);

    transactionsPerAgentLocationContent = <div className="st-details-card-container-row st-row-agent-transactions">
      <div className='cards'>
        <div className='simple-table-main-container'>
          <h2 className="detail-header">Agent Transactions</h2>
          <Table tableClass="responsive-scroll" data={transactionsPerAgentLocation} columns={theadersAgentTransactions} 
            simpleTable={true} pagination={true} tableFilter={true} pageSize={[5]} rowClass={"table-row-two-colored"} />
        </div>
        <div className='st-row-agent-transactions-total'>
          <h3>Total Agent Transactions</h3>
          <h3>{Utils.checkNull(agentLocation.numberOfTransactionsViaAgent)}</h3>
        </div>
      </div>
    </div>
  }

  if(!Utils.isFieldEmpty(pattern.saccoTransactions)) {
    pattern.saccoTransactions.forEach(element => {
      saccoTransactions.push({
        date: Utils.formatLongDate(Utils.formatDate(element.date)),
        type: Utils.capitalizeFirstLetter(element.type),
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, 
                                                     configuration.currency.symbol, 
                                                     element.amount))
      })
    });
    saccoTransactions.sort((a, b) => b.numberOfTransactions - a.numberOfTransactions);

    saccoTransactionsContent = <div className="st-details-card-container-row st-sacco-transactions">
      <div className='st-details-scroll-card'>
        <div className='cards details-table-container'>
          <h2 className="detail-header">Sacco Transactions</h2>
          <Table data={saccoTransactions} columns={theadersSaccoTransactions} tableExtra={true} rowClass={"table-row-two-colored"} />
        </div>
      </div>
    </div>
  }

  /* Self Transfer Transactions Flows */



  const populateOutflow = (index,yearIndex)=>{
    if(yearIndex===outflowIndex){
      setOutflowIndex(null)
    }
    else{
      let outArray = []
      OrderedOutflowTransfer[index].details[yearIndex].data.forEach(element=>{
        outArray.push({
          date: Utils.formatLongDateAndTime(element.date),
          amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, 
                                                       configuration.currency.symbol, 
                                                       element.amount)),
        })
      })
      setselfTransferOutflowTransactionsTable(()=>outArray)
      setOutflowIndex(yearIndex)
    }
  }



  const populateOutflowYear = (index)=>{
    if(index===outflowYearIndex){
      setOutflowIndex(null)
      setOutflowYearIndex(null)
    }
    else{
      setOutflowIndex(null)
      let outArray = []
      OrderedOutflowTransfer[index].details.forEach(element=>{
        outArray.push({
          month: element.month,
          amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, 
                                                       configuration.currency.symbol, 
                                                       element.total)),
        })
      })

      setselfTransferOutflowTransactionsTable(()=>outArray)
      setOutflowYearIndex(index)
    }
  }


  const populateInflow = (index,yearIndex)=>{
    if(yearIndex===inflowIndex){
      setInflowIndex(null)
    }
    else{
      let inArray = []
      OrderedInflowTransfer[index].details[yearIndex].data.forEach(element=>{
        inArray.push({
          date: Utils.formatLongDateAndTime(element.date),
          amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, 
                                  configuration.currency.symbol, element.amount)),
        })
      })
      setselfTransferInflowTransactionsTable(()=>inArray)
      setInflowIndex(yearIndex)
    }
  }


  const populateInflowYear = (index)=>{
    if(index===inflowYearIndex){
      setInflowIndex(null)
      setInflowYearIndex(null)
    }
    else{
      setInflowIndex(null)
      let inArray = []
      OrderedInflowTransfer[index].details.forEach(element=>{
        inArray.push({
          month: element.month,
          amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, 
                                  configuration.currency.symbol, element.total)),
        })
      })

      setselfTransferInflowTransactionsTable(()=>inArray)
      setInflowYearIndex(index)
    }
  }



  useEffect(()=>{
    setTrigger(!trigger)
  },[selfTransferOutflowTransactionsTable,selfTransferInflowTransactionsTable])

  
 
  if ((!Utils.isFieldEmpty(OutflowTransfer) || !Utils.isFieldEmpty(InflowTransfer)) ) {
    selfTransferTransactionsContent = <div className="st-details-card-container-row st-details-table-row table-two-col" >
      <div className="st-details-scroll-card">
        <div className='cards details-table-container'>
          <h2 className="detail-header">Self Transfer Outflow Transactions</h2>
          {!Utils.isFieldEmpty(pattern?.selfTransferOutflowTransactions)
            ?<div>
                <section style={{display:'flex',justifyContent:"space-between",margin:'10px 0px',marginBottom:'15px',padding:'0px 10px',boxSizing:"border-box"}}>
                  <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Year</p>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Count</p>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Total Amount</p>
                  </div>
                  <div style={{width:'10%',display:"flex",alignItems:'center',justifyContent:"right"}}>

                  </div>
                </section>
                {OrderedOutflowTransfer.map((element,index)=>{
                  if(element.total!==0 && !Utils.isFieldEmpty(element.details)){
                    return <div key={index} style={{border:'1px solid #D9E5FF',borderWidth:"0px 0px 1px 0px"}}>
                      <section onClick={()=>populateOutflowYear(index)} style={{display:'flex',cursor:'pointer',justifyContent:"space-between",margin:'15px 0px',padding:'0px 10px',boxSizing:"border-box"}}>
                        <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.year}</p>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.count}</p>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.total))}</p>
                        </div>
                        <p style={{width:'10%'
                          ,display:"flex",alignItems:'center',justifyContent:"right"}}>
                          <img src={Utils.isFieldEmpty(outflowYearIndex)?dropLeft: dropDownIcon}/>
                        </p>
                      </section>

                      <div style={{border:'1px solid #D9E5FF',borderWidth:"0px 0px 1px 0px",display:index===outflowYearIndex?'block':'none',marginTop:"20px"}}>
                        <section style={{display:'flex',justifyContent:"space-between",margin:'10px 0px',marginBottom:'15px',padding:'0px 10px',boxSizing:"border-box"}}>
                            <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Months</p>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Count</p>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Total Amount</p>
                            </div>
                            <div style={{width:'10%',display:"flex",alignItems:'center',justifyContent:"right"}}>
                            </div>
                        </section>
                        {OrderedOutflowTransfer[index].details.map((element,monthIndex)=>
                        {
                        
                          if(element.total!==0){
                          return <>
                              <section onClick={()=>populateOutflow(index,monthIndex)} style={{display:'flex',cursor:'pointer',justifyContent:"space-between",margin:'15px 0px',padding:'0px 10px',boxSizing:"border-box"}}>
                                <div style={{width:'90%',display:'flex',justifyContent:"space-between",flex:'1'}}>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.month}</p>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.data.length}</p>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.total))}</p>
                                </div>
                                <p style={{width:'10%'
                                  ,display:"flex",alignItems:'center',justifyContent:"right"}}>
                                  <img src={(!Utils.isFieldEmpty(outflowIndex) && monthIndex===outflowIndex )?dropDownIcon:dropLeft}/>
                                </p>
                              </section>
                        
                              <section style={{display:monthIndex===outflowIndex && selfTransferOutflowTransactionsTable.length>0?'block':'none',overflow:'auto'}}>
                              {selfTransferOutflowTransactionsTable.length>0?
                                <Table data={selfTransferOutflowTransactionsTable} 
                                        columns={theadersSelfTransferTransactions} 
                                        tableFilter={false} 
                                        rowClass={"table-row-two-colored"} tableExtra={true}/>
                                :''}
                              </section>
                          </>
                        }
                        }
                          )}
                        

                      </div>
                      
                    </div>
                  }
              }
              )
              } 
             </div>
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
      <div className="st-details-scroll-card">      
        <div className='cards details-table-container'>
          <h2 className="detail-header">Self Transfer Inflow Transactions</h2> 

          {!Utils.isFieldEmpty(pattern?.selfTransferInflowTransactions)
            ?<div>
                <section style={{display:'flex',justifyContent:"space-between",margin:'10px 0px',marginBottom:'15px',padding:'0px 10px',boxSizing:"border-box"}}>
                  <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Year</p>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Count</p>
                    <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Total Amount</p>
                  </div>
                  <div style={{width:'10%',display:"flex",alignItems:'center',justifyContent:"right"}}>

                  </div>
                </section>
                {OrderedInflowTransfer.map((element,index)=>{
                  if(element.total!==0 && !Utils.isFieldEmpty(element.details)){
                    return <div key={index} style={{border:'1px solid #D9E5FF',borderWidth:"0px 0px 1px 0px"}}>
                      <section onClick={()=>populateInflowYear(index)} style={{display:'flex',cursor:'pointer',justifyContent:"space-between",margin:'15px 0px',padding:'0px 10px',boxSizing:"border-box"}}>
                        <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.year}</p>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.count}</p>
                          <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.total))}</p>
                        </div>
                        <p style={{width:'10%'
                          ,display:"flex",alignItems:'center',justifyContent:"right"}}>
                          <img src={Utils.isFieldEmpty(inflowYearIndex)?dropLeft: dropDownIcon}/>
                        </p>
                      </section>

                      <div style={{border:'1px solid #D9E5FF',borderWidth:"0px 0px 1px 0px",display:index===inflowYearIndex?'block':'none',marginTop:"20px"}}>
                        <section style={{display:'flex',justifyContent:"space-between",margin:'10px 0px',marginBottom:'15px',padding:'0px 10px',boxSizing:"border-box"}}>
                            <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Months</p>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Count</p>
                              <p style={{fontWeight:'700',flex: 1, flexBasis: '33.33%',textAlign:'center'}}>Total Amount</p>
                            </div>
                            <div style={{width:'10%',display:"flex",alignItems:'center',justifyContent:"right"}}>
                            </div>
                        </section>
                        {OrderedInflowTransfer[index].details.map((element,monthIndex)=>
                        {
                        
                          if(element.total!==0){
                          return <>
                              <section onClick={()=>populateInflow(index,monthIndex)} style={{display:'flex',cursor:'pointer',justifyContent:"space-between",margin:'15px 0px',padding:'0px 10px',boxSizing:"border-box"}}>
                                <div style={{width:'90%',display:'flex',justifyContent:"space-between"}}>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.month}</p>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{element.data.length}</p>
                                  <p style={{flex: 1, flexBasis: '33.33%',textAlign:'center'}}>{Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.total))}</p>
                                </div>
                                <p style={{width:'10%'
                                  ,display:"flex",alignItems:'center',justifyContent:"right"}}>
                                  <img src={(!Utils.isFieldEmpty(inflowIndex) && monthIndex===inflowIndex )?dropDownIcon:dropLeft}/>
                                </p>
                              </section>
                        
                              <section style={{display:monthIndex===inflowIndex && selfTransferInflowTransactionsTable.length>0?'block':'none',overflow:'auto'}}>
                              {selfTransferInflowTransactionsTable.length>0?
                                <Table data={selfTransferInflowTransactionsTable} 
                                        columns={theadersSelfTransferTransactions} 
                                        tableFilter={false} 
                                        rowClass={"table-row-two-colored"} tableExtra={true}/>
                                :''}
                              </section>
                          </>
                        }
                        }
                          )}
                        

                      </div>
                      
                    </div>
                  }
              }
              )
              } 
             </div>
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }


        </div>
      </div>
    </div>
  }

  if(configuration.featureFlags.isConfigurableTransactionPatternEnabled === true) {
    let balanceDataSets = [];
    let transactionDataSets = [];
    let mostFrequentTransactionRange = null;
    let mostFrequentBalanceRange = null;
    let lowestTransactionRange = null;
    let lowestBalanceRange = null;
    let percentNumberOfDaysBalancesInSmallestBucket = null;
    let percentNumberOfDaysTransactionsInSmallestBucket = null;

    if(!Utils.isFieldEmpty(pattern.balanceBreakdown)) {
      let balanceBreakdown = pattern.balanceBreakdown;

      balanceDataSets = prepareTransactionPatternConfigurationBucket(balanceBreakdown).pieData;
      mostFrequentBalanceRange = prepareTransactionPatternConfigurationBucket(balanceBreakdown).mostFrequentRange;
      lowestBalanceRange = prepareTransactionPatternConfigurationBucket(balanceBreakdown).lowestRange;
      percentNumberOfDaysBalancesInSmallestBucket = Utils.formatPercentage(pattern.percentNumberOfDaysBalancesInSmallestBucket);
    }

    if(!Utils.isFieldEmpty(pattern.transactionBreakdown)) {
      let transactionBreakdown = pattern.transactionBreakdown;

      transactionDataSets = prepareTransactionPatternConfigurationBucket(transactionBreakdown).pieData;
      mostFrequentTransactionRange = prepareTransactionPatternConfigurationBucket(transactionBreakdown).mostFrequentRange;
      lowestTransactionRange = prepareTransactionPatternConfigurationBucket(transactionBreakdown).lowestRange;
      percentNumberOfDaysTransactionsInSmallestBucket = Utils.formatPercentage(pattern.percentNumberOfDaysTransactionsInSmallestBucket);
    }
    

    if ((!Utils.isFieldEmpty(balanceDataSets)) || (!Utils.isFieldEmpty(transactionDataSets))) {
      breakdownContent = <div className="st-details-card-container-row st-details-pattern-bottom-row">
        <DetailsCard title={'Transactions'} titleClass="detail-header" detailClassName={'st-detail-color-background-container'} detailContent={<>
          <div className="details-doughnut" >
            <Doughnut data={{
                labels: pieLabelsDynamic,
                datasets: [
                  {
                    data: transactionDataSets,
                    backgroundColor: pieChartColors
                  }
                ]
              }} options={pieChartOptions} />
          </div>
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
            listContent={pieLabelsDynamic.map((e, i) => {
              return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(transactionDataSets[i]) }
            })} />
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              {
                header: 'Most Frequent Transaction Range',
                detail: mostFrequentTransactionRange
              },
              {
                header: 'Number Of Days Transactions Were Less Than ' + lowestTransactionRange,
                detail: percentNumberOfDaysTransactionsInSmallestBucket
              }
            ]} />
        </>} />
        <DetailsCard title={'Balance'} titleClass="detail-header" detailClassName={'st-detail-color-background-container'} detailContent={<>
          <div className="details-doughnut" >
            <Doughnut data={{
                labels: pieLabelsDynamic,
                datasets: [
                  {
                    data: balanceDataSets,
                    backgroundColor: pieChartColors
                  }
                ]
              }} options={pieChartOptions}/>
          </div>
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
              listContent={pieLabelsDynamic.map((e, i) => {
                return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(balanceDataSets[i]) }
              })} />
            <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
              listContent={[
                {
                  header: 'Most Frequent Balance Range',
                  detail: mostFrequentBalanceRange
                },
                {
                  header: 'Number Of Days Balance Was Less Than ' + lowestBalanceRange, 
                  detail: percentNumberOfDaysBalancesInSmallestBucket
                }
              ]} />
          </>} />
      </div> 
    }
  } else {
    let pieLabels = ['Less Than ₦10,000', 'Between ₦10,000 To ₦100,000', 'Between ₦100,000 To ₦500,000', 'Between ₦500,000 To ₦1,000,000', 'Greater Than ₦1,000,000']
    let pieTransactionsData = [
      Utils.checkNull(pattern.percentOfTransactionsLessThan10ThousandNaira),
      Utils.checkNull(pattern.percentOfTransactionsBetween10ThousandTo100ThousandNaira),
      Utils.checkNull(pattern.percentOfTransactionsBetween100ThousandTo500ThousandNaira),
      Utils.checkNull(pattern.percentOfTransactionsBetween500ThousandToOneMillionNaira),
      Utils.checkNull(pattern.percentOfTransactionsGreaterThanOneMillionNaira)
    ];
  


    let pieBalanceData = [
      Utils.checkNull(pattern.percentOfBalancesLessThan10ThousandNaira),
      Utils.checkNull(pattern.percentOfBalancesBetween10ThousandTo100ThousandNaira),
      Utils.checkNull(pattern.percentOfBalancesBetween100ThousandTo500ThousandNaira),
      Utils.checkNull(pattern.percentOfBalancesBetween500ThousandToOneMillionNaira),
      Utils.checkNull(pattern.percentOfBalancesGreaterThanOneMillionNaira)
    ];

    breakdownContent = <div className="st-details-card-container-row st-details-pattern-bottom-row">
      <DetailsCard title={'Transactions'} titleClass="detail-header" detailClassName={'st-detail-color-background-container'} 
        detailContent={<>
          <div className="details-doughnut" >
            <Doughnut data={{
                labels: pieLabels,
                datasets: [
                  {
                    data: pieTransactionsData,
                    backgroundColor: pieChartColors
                  }
                ]
              }} options={pieChartOptions} />
          </div>
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
            listContent={pieLabels.map((e, i) => {
              return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(pieTransactionsData[i]) }
            })} />
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              {
                header: 'Most Frequent Transaction Range',
                detail: pattern.mostFrequentTransactionRange
              },
              {
                header: 'Number Of Days Transactions Were Less Than ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 10000),
                detail: Utils.checkNull(Utils.formatPercentage(pattern.percentNumberOfDaysTransactionsWasLessThan10ThousandNaira))
              }
            ]} />
        </>} />
      <DetailsCard title={'Balance'} titleClass="detail-header" detailClassName={'st-detail-color-background-container'} 
        detailContent={<>
          <div className="details-doughnut" >
            <Doughnut data={{
                labels: pieLabels,
                datasets: [
                  {
                    data: pieBalanceData,
                    backgroundColor: pieChartColors
                  }
                ]
              }} options={pieChartOptions}/>
          </div>
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
            listContent={pieLabels.map((e, i) => {
              return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(pieBalanceData[i]) }
            })} />
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              {
                header: 'Most Frequent Balance Range',
                detail: pattern.mostFrequentBalanceRange
              },
              {
                header: 'Number Of Days Balance Was Less Than ' + Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 10000), 
                detail: Utils.checkNull(Utils.formatPercentage(pattern.percentNumberOfDaysBalanceWasLessThan10ThousandNaira))
              }
            ]} />
        </>} />
    </div>
  }

  content = <div className="st-details-card-container pattern-cards">
    {configuration.featureFlags.isAgentLocationAnalysisEnabled === true &&
      transactionsPerAgentLocationContent
    }
    {configuration.featureFlags.isAgentLocationAnalysisEnabled === true &&
      saccoTransactionsContent
    }
    <div className="st-details-card-container-row st-details-pattern-top-row">
      {overviewContent}
      <DetailsCard title={'Transactions'} titleClass={"detail-header"}
        detailClassName={'st-details-pattern st-details-pattern-transactions'} detailContent={<>
          <List listClass={'st-details-list-pattern'} listContainerClass={'st-details-list-main-container'}
            title={'Debit'} detailSymbol={'🡮'} detailSymbolClass="detail-header"
            detail={Utils.checkNull(Utils.formatPercentage(pattern.percentDebitTransactions))} 
            listContent={[
              {
                header: 'Last Used:',
                detail: Utils.checkNull(Utils.formatLongDate(pattern.lastDayOfDebit))
              },
              {
                header: 'Most Frequent Transfer:',
                detail: Utils.capitalizeFirstLetter(Utils.checkNull(pattern.mostFrequentDebitTransfer))
              }
            ]} />
          <List listClass={'st-details-list-pattern'} listContainerClass={'st-details-list-main-container'} 
            title={'Credit'} detailSymbol={'🡥'} detailSymbolClass="detail-header-light"
            detail={Utils.checkNull(Utils.formatPercentage(pattern.percentCreditTransactions))} 
            listContent={[
              {
                header: 'Last Used:',
                detail: Utils.checkNull(Utils.formatLongDate(pattern.lastDayOfCredit))
              },
              {
                header: 'Most Frequent Transfer:',
                detail: Utils.capitalizeFirstLetter(Utils.checkNull(pattern.mostFrequentCreditTransfer))
              }
            ]} />
          </>} 
      />            
    </div>
    <div className="st-details-card-container-row self-transfer-flows">
      <DetailsCard detailContent={
        <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} detailClass="detail-header"
          listContent={[
            {
              header: "Self Transfer Outflows",
              detail: Utils.checkNull(pattern.noOfSelfTransferOutflows)
            },
            {
              header: "Self Transfer Outflow Amount",
              detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, pattern.selfTransferOutflowAmount))
            }
          ]} 
        />} 
      />
      <DetailsCard detailContent={
        <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
          detailClass="detail-header" listContent={[
            {
              header: "Self Transfer Inflows",
              detail: Utils.checkNull(pattern.noOfSelfTransferInflows)
            },
            {
              header: "Self Transfer Inflow Amount",
              detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, pattern.selfTransferInflowAmount))
            }
          ]} 
        />} 
      />
    </div>
    {selfTransferTransactionsContent}
    {breakdownContent}
  </div>

  return (
    content
  )
}

export default Pattern;




