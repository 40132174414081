import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const fetchFlaggedTransactionAsync = createAsyncThunk(
  "fraudDetect/fetchTransactionnAsync",
  async (params) => {
    const response = await clientApi.get(`/api/transactions/profiles`);

    return response;
  }
);

export const fetchPendingTransactionAsync = createAsyncThunk(
  "fraudDetect/fetchPendingTransactionnAsync",
  async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await clientApi.get(
      `/api/transactions/status?${queryString}`
    );

    return response;
  }
);

export const fetchReviewedTransactionAsync = createAsyncThunk(
  "fraudDetect/fetchReviewedTransactionnAsync",
  async (params) => {
    const queryString = new URLSearchParams(params).toString();
    const response = await clientApi.get(
      `/api/transactions/status?${queryString}`
    );

    return response;
  }
);

export const fetchTransactionStatisticAsync = createAsyncThunk(
  "fraudDetect/fetchTransactionStatistic",
  async (params) => {
    const response = await clientApi.get(`/api/transactions/statistics`);

    return response;
  }
);

export const fetchTransactionProfileAsync = createAsyncThunk(
  "fraudDetect/fetchUserTransactionProfile",
  async (params) => {
    const response = await clientApi.get(
      `/api/transactions/${params?.userId}/transaction-profile`
    );

    return response;
  }
);

export const fetchTransactionDetailsAsync = createAsyncThunk(
  "fraudDetect/fetchTransactionDetails",
  async (params) => {
    const response = await clientApi.get(
      `/api/transactions/${params?.id}/details`
    );

    return response;
  }
);

export const fetchTransactionsByIdAsync = createAsyncThunk(
  "fraudDetect/fetchTransactionsByIdAsync",
  async (params) => {
    const response = await clientApi.get(`/api/transactions/${params?.id}`);

    return response;
  }
);

export const viewTransactionFileAsync = createAsyncThunk(
  "fraudDetect/viewTransactionFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        `/api/transactions/view-file`,
        data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
