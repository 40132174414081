export const FetchDataStatus = {
  INITIAL: "INITIAL",
  FETCHING: "FETCHING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
}

export const PostDataStatus = {
  INITIAL: "INITIAL",
  FETCHING: "POSTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
}

export const LoginFormTypes = {
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  LOGIN: "LOGIN"
}

export const PaymentProviders = {
  PAYSTACK: "PAYSTACK"
}

export const MobileBankAccountType = {
  BANK_ACCOUNT: "BANK_ACCOUNT",
  LENDING_ACCOUNT: "LENDING_ACCOUNT"
}

export const StatementStatus = {
  FAILED: "FAILED",
  UPLOADED: "UPLOADED",
  NOTENOUGHTRANSACTIONS: "NOTENOUGHTRANSACTIONS",
  LESSTHAN6MONTHS: "LESSTHAN6MONTHS",
  ALTERED: "ALTERED",
  PROCESSED: "PROCESSED"
}

export const CustomCreditScoreViews = {
  INITIAL: "INITIAL",
  SCORE_CARDS: "SCORE_CARDS",
  FORM: "FORM",
  CONFIRM: "CONFIRM",
  FETCHING: "FETCHING"
}

export const InputType = {
  SELECT: "SELECT"
}

export const ParameterName = {
  EMPLOYMENT: "EMPLOYMENT"
}

export const BureauGenerateViewType = {
  INITIAL: "INITIAL",
  FORM: "FORM",
  CUSTOM: "CUSTOM",
  CREDITCHEK: "CREDITCHEK",
  BLACKLIST: "BLACKLIST"
}

export const CRBGhanaCheckTypes = {
  COMMERCIAL: "COMMERCIAL",
  CONSUMER: "CONSUMER",
}

export const CRBKenyaCheckTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  BUSINESS: "BUSINESS",
}




export const CrbGhanaCardFields = [
  {
    title: "CRB Commercial",
    subtitle: "This check requires enterprise name.",
    type: CRBGhanaCheckTypes.COMMERCIAL,
    price: 64.50
  },

  {
    title: "CRB Consumer",
    subtitle: "This check requires consumer name and date of birth.",
    type: CRBGhanaCheckTypes.CONSUMER,
    price: 64.50
  },

]

export const CrbKenyaCardFields = [
  {
    title: "CRB Commercial",
    subtitle: "This check requires enterprise name.",
    type: CRBKenyaCheckTypes.BUSINESS,
  
  },

  {
    title: "Bureau Data Check for Individual",
    subtitle: "Please ensure details are correct before submitting.",
    type: CRBKenyaCheckTypes.INDIVIDUAL,
  
  },

]










export const BureauCreditCheckTypes = {
  CRA_NANO: "CRA_NANO",
  INDIVIDUAL: "INDIVIDUAL",
  BUSINESS: "BUSINESS"
}

export const CreditCheckCardFields = [
  {
    title: "CRC Nano Data",
    subtitle: "This check requires BVN",
    type: BureauCreditCheckTypes.CRA_NANO,
    price: 64.50
  },
  {
    title: "Bureau Data for Individual",
    subtitle: "This check requires BVN of the individual",
    type: BureauCreditCheckTypes.INDIVIDUAL,
    sources: [
      {
        name: "crc",
        label: "CRC",
        price: 230
      },
      {
        name: "first-central",
        label: "First Central",
        price: 200
      },
      {
        name: "first-central-premium",
        label: "First-Central-Premium",
        price: 350
      },
      {
        name: "credit-registry",
        label: "Credit Registry",
        price: 250
      },
      {
        name: "premium",
        label: "Premium",
        price: 500
      }
    ],
    price: 230
  },
  {
    title: "Bureau Data for Business",
    subtitle: "This check requires BVN of the business",
    type: BureauCreditCheckTypes.BUSINESS,
    price: 550
  }
];

export const BureauCreditchekSourceTypes = {
  CRC: "CRC",
  FIRST_CENTRAL: "FIRST_CENTRAL",
  PREMIUM: "PREMIUM",
  CREDIT_REGISTRY: "CREDIT_REGISTRY",
  SME_CRC: "SME_CRC"
}

export const PositiveText = [
  "YES",
  "POSITIVE CASH FLOW",
  StatementStatus.PROCESSED,
  "PASSED",
  "FOUND",
  "ACTIVE"
]

export const NegativeText = [
  "NO",
  "NEGATIVE CASH FLOW",
  StatementStatus.FAILED,
  StatementStatus.NOTENOUGHTRANSACTIONS,
  StatementStatus.LESSTHAN6MONTHS,
  StatementStatus.ALTERED
]

export const NeutralText = [
  "EQUAL CASH FLOW",
  StatementStatus.UPLOADED,
  "NOT FOUND",
  "0",
  "INVITE SENT"
]

export const UserRoleTypes = {
  CUSTOM: "CUSTOM",
}

export const TypeOfPermissions = {
  GENERAL: "GENERAL",
  MANAGE: "MANAGE",
  SETTINGS: "SETTINGS"
}