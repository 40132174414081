import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { resetKybData,selectKenyaKybExtractions,getBusinessRcDetails,resetRbExtractionStatus} from '../../../../../redux/Slices/IdVerificationKenyaKyb'
import { PostDataStatus } from '../../../../../library/Variables'
import errorImage from '../../../../../assests/icons/errorImage.png'
import selectedIcon from '../../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../../assests/icons/nonSelected.png'
import Utils from '../../../../../library/Utils'
import './cacModal.css'
import { getReportDetailKyb,resetReportKyb } from '../../../../../redux/Slices/IdVerificationKenyaKyb'


function RbcModal({accessToken,inputStyle,setKybToShow,customStyles,setViewReport,setVerificationType}) {
  const dispatch = useDispatch()
  const [simpleSearchType,setSimpleSearchType] = useState()
  const kybState = useSelector(selectKenyaKybExtractions)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmit = (formData) => {
    const data = {
      number:formData.inputName,
      statementKey:null,
      checkType: simpleSearchType
    }
    const obj = {data,
                accessToken}
                
   dispatch(getBusinessRcDetails(obj))

  }

  const getDetails = (token,uuid,type)=>{
    setKybToShow(null);
    setIsOpen(false)
    dispatch(resetRbExtractionStatus())
    setVerificationType(type);
    setViewReport(true)
    dispatch(resetReportKyb())
    dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    dispatch(resetKybData())
}


const SearchTypeSet = (data)=>{
    setSimpleSearchType(data)

}




  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div style={{padding:'15px',
               height:'380px',
               display:"flex",
               justifyContent:"space-around",
               flexDirection:'column'}}>
       <p className='title-class'>BRS Verification</p>

       <div style={{marginTop:'15px'}}>
           <p>Input RC Number</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       
       <div style={{margin:'15px 0px'}}>
            <p style={{color:'rgba(54, 60, 79, 1)',fontSize:'16px'}}>Select Check type</p>
            <div style={{display:"flex",rowGap:"20px",alignItems:'flex-start',marginTop:"15px",flexDirection:"column"}}>
                <section onClick={()=>SearchTypeSet('CR12')} 
                         style={{display:'flex',
                                 cursor:'pointer',
                                 justifyContent:"center",
                                 alignItems:"center",
                                 columnGap:"5px"}}>
                    <span 
                          style={{display:'flex',
                                  justifyContent:"center",
                                  alignItems:"center"}}>
                          <img src={simpleSearchType==='CR12'?selectedIcon:unselectedIcon}/></span>
                    <span style={{fontWeight:simpleSearchType==='CR12'?'700':'400'}}>CR12</span>
                    <span>( Company Search )</span>
                </section>
                <section onClick={()=>SearchTypeSet('CR13')} 
                         style={{display:'flex',
                                 cursor:'pointer',
                                 justifyContent:"center",
                                 alignItems:"center",
                                 columnGap:"5px"}}>
                    <span 
                          style={{display:'flex',
                                  justifyContent:"center",
                                  alignItems:"center"}}>
                          <img src={simpleSearchType==='CR13'?selectedIcon:unselectedIcon}/></span>
                    <span style={{fontWeight:simpleSearchType==='CR13'?'700':'400'}}>CR13</span>
                    <span>( Business Search )</span>
                </section>

                <section onClick={()=>SearchTypeSet('LLP')} 
                          style={{display:'flex',
                                  cursor:'pointer',
                                  justifyContent:"center",
                                  alignItems:"center",
                                  columnGap:"5px"}}>
                    <span style={{display:'flex',
                                  justifyContent:"center",
                                  alignItems:"center"}}>
                          <img src={simpleSearchType==='LLP'?selectedIcon:unselectedIcon}/></span>
                    <span style={{fontWeight:simpleSearchType==='LLP'?'700':'400'}}>LLP</span>
                    <span>( Limited liability partnership )</span>
                </section>
            </div>
       </div>

       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}


       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKybToShow(null);
                                                  dispatch(resetKybData())
                                                  dispatch(resetRbExtractionStatus())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kybState.rbcExtractionErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKybToShow(null);
                                                  dispatch(resetRbExtractionStatus())}}>Cancel</span>
           
       </div>
  </div>


const ModalSuccesful = <div className='modal-success'>
                          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <p style={{textAlign:'center',fontWeight:'700'}}>Successfull!!</p>
                          </div>
                          <p style={{textAlign:'center'}}>BRS Extraction Completed</p>

                          <div className='modal-initial-button-hold'>
                                <span className='close' onClick={()=>getDetails(accessToken,kybState?.rbcExtraction?.uuid,
                                                                                kybState?.rbcExtraction?.type)}>Close</span>
                                
                          </div>
                        </div>


  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kybState.rbcExtractionStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kybState.rbcExtractionStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kybState.rbcExtractionStatus===PostDataStatus.SUCCESS){
            setViewReport(false)
            setModal(ModalSuccesful)
    
        }

        if(kybState.rbcExtractionStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kybState,simpleSearchType])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default RbcModal