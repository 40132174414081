import React from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { uploadSignature,resetGottenSignature,selectSignatureState} from '../../../redux/Slices/LoanSignatureUpload';
import closeModalFile from '../../../assests/icons/closeModalFile.png'



function ViewSignatureModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectSignatureState)
    const accessToken = props.accessToken
    const lastThreeChars = props.lastThreeChars

    let content = null;
   

  

    if( loanState.getSignatureStatus===PostDataStatus.INITIAL){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",
                    justifyContent:"space-between",width:lastThreeChars==='pdf'?'90vh':'auto'}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Fetching...</p>
                    <Spinner/>
            </div>
    }

    if(loanState.getSignatureStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",
                    justifyContent:"space-between",width:lastThreeChars==='pdf'?'90vh':'auto'}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Fetching...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.getSignatureStatus===PostDataStatus.SUCCESS){
       
        let signatureExtension  = lastThreeChars==='png'?'image/png':lastThreeChars==='pdf'?'application/pdf':'image/jpeg'

        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between",height:'100%'}}>
            <section style={{display:'flex',alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <p style={{textAlign:"center",fontSize:'16px'}}>View File</p>
                <span onClick={()=>{props.closeModal();dispatch(resetGottenSignature())}} style={{display:"flex",cursor:"pointer",alignItems:"center",justifyContent:"center"}}>
                    <img src={closeModalFile}/>
                </span>
            </section>
            <div style={{width:lastThreeChars==='pdf'?'100%':'40%',height:'100%',margin:'0px auto',display:"flex",alignItems:"center",justifyContent:"Center"}}>{
                signatureExtension==='application/pdf'?
                <iframe
                    type="application/pdf"
                    title="PDF Viewer"
                    style={{width:'80vw',height:"100%",margin:'auto'}}
                    src={`data:${signatureExtension};base64,${loanState.signatureBase64.data}`}
                />
               
                :
                <img style={{width:'400px',objectFit:'contain'}} src={`data:${signatureExtension};base64,${loanState.signatureBase64.data}`}/>
            }</div>
             
        </div>
    }

  return (
    <div style={{height:lastThreeChars==='pdf'?'90vh':'auto',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default ViewSignatureModal