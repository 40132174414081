import Utils from "../../../../library/Utils";
import { CamelDataSerializer } from "./CrbCamelCaseSerializer";




export const AccountTypeSerializer = (obj)=>{
    let AccountTypeReshape = []
    let mainObj = {...obj}
    delete mainObj.rating
 
    let reshapedData = CamelDataSerializer(mainObj)
    const basicDataKeys = Object.keys(mainObj);
    const basicDataValues = Object.values(mainObj)

    for(let i=0;i<basicDataKeys.length;i+=2){
        const newItem = {
            accType:reshapedData[i].itemKey,
            bad:reshapedData[i].itemValue,
            good:reshapedData[i+1].itemValue,
        }

        AccountTypeReshape.push(newItem)

    }
    return AccountTypeReshape
}