import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";


export const getLoanParameter = createAsyncThunk ('loan/getLoanParameters',async(params)=>{
    const loanParameters = await client.get('/loan/loan-application-parameters',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return loanParameters.data;
} )



export const uploadLoanForm = createAsyncThunk('loan/formUpload', async (params) => {
  const data = JSON.stringify(params.data)
  const loanFormData = await client.post('/loan/application', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken,
        'Content-Type': 'application/json'
      }
  });
  return loanFormData;
});


export const getAllLoanData = createAsyncThunk('loan/allData',async(params)=>{
       const loanData = await client.get('/loan',
       {
         "headers":
         {
           "Authorization": "Bearer " + params.accessToken
         }
       });
     
       return loanData.data;
     } )


     export const getAllLoanSummary = createAsyncThunk('loan/allDataSummary',async(params)=>{
      const loanSummary = await client.get('/loan/summary',
      {
        "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
      });
    
      return loanSummary.data;
    } )


    export const getFilteredLoan = createAsyncThunk('loan/filtered',async(params)=>{
      const loanFiltered = await client.get(`/loan/filter/${params.filter}`,
      {
        "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
      });
    
      return loanFiltered.data;
    } )






export const loanSlice = createSlice({
    name: 'loan',
    initialState: {
      loanParameters: [],
      loanParametersStatus: FetchDataStatus.INITIAL,
      formUpload:[],
      formUploadStatus:PostDataStatus.INITIAL,
      getLoanDataStatus:FetchDataStatus.INITIAL,
      isErrorParameters: false,
      errorMessageParameters: null,
      isErrorFormUpload: false,
      errorMessageFormUpload: null,
      isErrorGetLoanData: false,
      errorGetLoanData: null,

      loanSummaryStatus: FetchDataStatus.INITIAL,
      loanSummaryData: null,
      isErrorLoanSummary:false,
      errorSummaryData:null,

      loanFilterStatus: FetchDataStatus.INITIAL,
      loanFilterData: null,
      isErrorLoanFilter:false,
      errorFilterData:null
    },

    reducers: {
        resetLoanParameter: state => {
            state.loanParameters = [];
            state.loanParametersStatus = FetchDataStatus.INITIAL;
            state.isErrorParameters = false;
            state.errorMessageParameters = null;
        }
        ,
        resetGetAllLoanData:state => {
          state.formUpload = [];
          state.getLoanDataStatus = FetchDataStatus.INITIAL;
          state.isErrorGetLoanData = false;
          state.errorGetLoanData = null;
        }
        ,

        resetLoanSummary: state => {
          state.loanSummaryData = null;
          state.loanSummaryStatus = FetchDataStatus.INITIAL;
          state.isErrorLoanSummary = false;
          state.errorSummaryData = null
        }
        ,

        resetLoanFilter: state => {
          state.loanFilterStatus = FetchDataStatus.INITIAL;
          state.loanFilterData = null;
          state.isErrorLoanFilter = false;
          state.errorFilterData = null
        }
    },
    extraReducers(builder) {
        builder.addCase(getLoanParameter.pending, (state) => {
            state.loanParametersStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getLoanParameter.fulfilled, (state, action) => {
            state.loanParametersStatus = FetchDataStatus.SUCCESS;
            state.loanParameters = action.payload;
          })
          .addCase(getLoanParameter.rejected, (state, action) => {
            state.loanParametersStatus = FetchDataStatus.FAILURE;
            state.isErrorParameters = true;
            state.errorMessageParameters = action.error.message;
          })
          .addCase(uploadLoanForm.pending, (state) => {
            state.formUploadStatus = PostDataStatus.FETCHING;
          })
          .addCase(uploadLoanForm.fulfilled, (state, action) => {
            state.formUploadStatus = PostDataStatus.SUCCESS;
            const formResponse = action.payload
            let newFormObj = {
              key:formResponse.data.key,
              name:formResponse.data.middleName?`${formResponse.data.firstName} ${formResponse.data.middleName} ${formResponse.data.lastName}`
                :`${formResponse.data.firstName}  ${formResponse.data.lastName}`,
              status:formResponse.data.approvalStatus,
              clientId:formResponse.data.clientId,
              createdDate:formResponse.data.createdDate,
              updatedDate:formResponse.data.updatedDate,
            }
            state.formUpload = [newFormObj,...state.formUpload];
          })
          .addCase(uploadLoanForm.rejected, (state, action) => {
            state.formUploadStatus = PostDataStatus.FAILURE;
            state.isErrorFormUpload = true;
            state.errorMessageFormUpload = action.error.message;
          })
          .addCase(getAllLoanData.pending, (state) => {
            state.getLoanDataStatus = PostDataStatus.FETCHING;
          })
          .addCase(getAllLoanData.fulfilled, (state, action) => {
            state.getLoanDataStatus = PostDataStatus.SUCCESS;
            state.formUpload = action.payload;
          })
          .addCase(getAllLoanData.rejected, (state, action) => {
            state.getLoanDataStatus = PostDataStatus.FAILURE;
            state.isErrorGetLoanData = true;
            state.errorGetLoanData = action.error.message;
          })


          .addCase(getAllLoanSummary.pending, (state) => {
            state.loanSummaryStatus = PostDataStatus.FETCHING;
          })
          .addCase(getAllLoanSummary.fulfilled, (state, action) => {
            state.loanSummaryStatus = PostDataStatus.SUCCESS;
            state.loanSummaryData = action.payload;
          })
          .addCase(getAllLoanSummary.rejected, (state, action) => {
            state.loanSummaryStatus = PostDataStatus.FAILURE;
            state.isErrorLoanSummary = true;
            state.errorSummaryData = action.error.message;
          })


          .addCase(getFilteredLoan.pending, (state) => {
            state.loanFilterStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getFilteredLoan.fulfilled, (state, action) => {
            state.loanFilterStatus = FetchDataStatus.SUCCESS;
            state.loanFilterData = action.payload;
          })
          .addCase(getFilteredLoan.rejected, (state, action) => {
            state.loanFilterStatus = FetchDataStatus.FAILURE;
            state.isErrorLoanFilter = true;
            state.errorFilterData = action.error.message;
          })

    }
})


export const { resetLoanParameter,resetSignature,resetGetAllLoanData,resetLoanSummary,resetLoanFilter } = loanSlice.actions;

export const selectLoanState = state => state.loan
export default loanSlice.reducer;