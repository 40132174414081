import React,{useState,useEffect} from 'react'
import idVerifyIcon from '../../assests/icons/IdVerificationColored.png'
import './IdVerification.css'
import Tabs from '../../components/Tabs/Tabs';
import KycContentSection from './VerificationContent/KycContent/KycContentSection';
import KybContentSection from './VerificationContent/KybContent/KybContentSection';
import AmlContentSection from './VerificationContent/AmlContent/AmlContentSection';
import AmlBusProceed from './VerificationContent/AmlContent/AmlComponents/AmlBusinessProceed';
import AmlProceed from './VerificationContent/AmlContent/AmlComponents/AmlProceed';
import { useParams } from 'react-router';

function IdVerification(props) {
  const { key } = useParams();
  const accessToken = props.accessToken
  const [contentToShow,setContentToShow] = useState('main')
  const [mainContent,setMainContent] = useState('')
  let tabBodiesArray = [];


  tabBodiesArray.push({
    title: "KYC Verification ",
    content: <KycContentSection accessToken={accessToken} keyFromRoute={key}/>
  });

  tabBodiesArray.push({
    title: "KYB Verification ",
    content: <KybContentSection 
                accessToken={accessToken} 
                keyFromRoute={key}

            />
  });

  tabBodiesArray.push({
    title: "AML",
    content: <AmlContentSection 
                accessToken={accessToken} 
                keyFromRoute={key} 
                setContentToShow={setContentToShow}
            
            />
  });


  useEffect(()=>{
    if(contentToShow==='main'){
     const content = <Tabs tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
      setMainContent(content)
    }

    else if(contentToShow==='amlDetails'){
      const Content = <AmlProceed accessToken={accessToken} setContentToShow={setContentToShow}/>
      setMainContent(Content)
    }

    else if(contentToShow==='amlBusDetails'){
      const Content = <AmlBusProceed accessToken={accessToken} setContentToShow={setContentToShow}/>
      setMainContent(Content)
    }
},[contentToShow,accessToken])


  return (
    
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section className='idVerifyHead'>
            <section className='idVerifyContent'>
                <span><img src={idVerifyIcon} alt=''/></span>
                <p>Identity Verification</p>
            </section>
        </section>
        <section style={{marginTop:'30px'}}>
          {mainContent}
        </section>
    </div>
  )
}

export default IdVerification