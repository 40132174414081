import React, { useState } from 'react';
import './Accordion.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoanAccordion(props) {
  const [activeRow, setActiveRow] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const user = props.user

  let activeRowBody = props.accordionBodies[activeRow];
  const openBreakdown = (bool)=>{
    if(user.user.permissions.viewScorecardBreakdown){
        setIsOpen(bool)
    }

    else{
        toast.info('You are not permitted to view Score-breakdown', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
    }
  }

  if(activeRowBody === null || activeRowBody === undefined){
    return null;
  }
 
  const accordionRows = props.accordionBodies.map((accordionBody, i) => {
    
    if(activeRow === i) {
      return <div key={i + "-" + activeRow}>
        <div className="acc-row" key={i} id={isOpen ? 'active-acc-row-open' : 'active-acc-row'} >
          {accordionBody.headers.map((header, j) =>
            typeof header === 'object'
            ? <h4 style={{ color: header.color, background: header.color + "1A"}} key={i + "-" + j}>{header.text}</h4>
            : <h4  key={i + "-" + j}>{header}</h4>
          )}
          <div className="acc-row-right">
            <button onClick={() => { openBreakdown(!isOpen) } }>{isOpen ? "-" : "+"}</button>
          </div>
        </div>
        {isOpen && 
          <div className="acc-bodies-container">
            {accordionBody.content}
          </div>
        }
      </div>
    } else {
      return <div className="acc-row" key={i + "-" + activeRow}>
        {accordionBody.headers.map((header, j) =>
          typeof header === 'object'
          ? <h4 style={{ color: header.color, background: header.color + "1A" }}  key={i + "-" + j}>{header.text}</h4>
          : <h4 key={i + "-" + j}>{header}</h4>
        )}
        <div className="acc-row-right">
          <button onClick={() => { setActiveRow(i); setIsOpen(true) } }>{"+"}</button>
        </div>
      </div>
    }
  })

  return (
    <div className="accordion-container scrollbar" key={activeRowBody.title}>
      {accordionRows}
    </div>
  )
}

export default LoanAccordion;