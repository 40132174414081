import React,{useState,useEffect} from 'react'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import nairaIcon from '../../../../assests/icons/nairaIcon.png'
import AmountInput from '../../../../components/loanComponents/AmountInput';
import AmountInputReadOnly from '../../../../components/loanComponents/AmountInputReadOnly';
import MinMaxInput from '../../../../components/loanComponents/MinMaxInput';
import Utils from '../../../../library/Utils';


function SalesEstimateSection(props) {
  const register = props.register
  const errors = props?.errors
  const setValue = props.setValue
  const getValues = props.getValues
  const arrayofSales = lodash.sortBy(props.info,'rank')

   
   const firstLayer = [arrayofSales[0]]
   const secondLayer = arrayofSales.slice(1,4)
   const thirdLayer = arrayofSales.slice(4,6)
   const fourthLayer = [arrayofSales[6]]
   const [typeSelected,setTypeSelected] = useState('')
   const [averageSalesAmount,setAverageSalesAmount] = useState()
   const [averageMonthlySalesAmount,setAverageMonthlySalesAmount] = useState()
   const [averageSalesYearlyAmount,setAverageYearlySalesAmount] = useState()
   const [alternator,setAlternator] = useState(false)
   const setTriggerAmountSave = props.setTriggerAmountSave
   const triggerAmountSave = props.triggerAmountSave
   const triggerPhoneSave = props.triggerPhoneSave
   const setTriggerPhoneSave = props.setTriggerPhoneSave
   const slicedData = props?.slicedData ? props.slicedData : ''


   useEffect(()=>{
    if(!Utils.isFieldEmpty(slicedData)){
      let phoneObject = {}
      let amountObject = {}
      arrayofSales.forEach((element,index) => {
          slicedData.forEach((sliced)=>{
            if(sliced.friendlyName===element.friendlyName){
              if(sliced.displayType === 'CURRENCY'){
              
                let key = sliced.friendlyName;
                let value = sliced.value;
                amountObject[key] = value;
                  
              }

              else if(sliced.friendlyName.includes('Phone')){
                let key = sliced.friendlyName;
                let value = sliced.value;
                phoneObject[key] = value;
                setTriggerPhoneSave(sliced.value)
              }

              else{

                setValue(element.name,sliced.value)
              }
            }

            setTriggerPhoneSave(phoneObject)
            setTriggerAmountSave(amountObject)
          })
      });
    }
},[])

   useEffect(()=>{
    const data = getValues()
    const miniVal = parseFloat(data.Min)
    const maxiVal = parseFloat(data.Max)
    if(typeSelected==='Daily'){
       const average_sales =  (miniVal + maxiVal)/2
       const average_year = average_sales*365
       const average_monthly = average_year/12

       setAverageMonthlySalesAmount(average_monthly)
       setAverageSalesAmount(average_sales)
       setAverageYearlySalesAmount(average_year)
    }

    else if (typeSelected==='Weekly'){
      const average_sales =  (miniVal + maxiVal)/2
       const average_year = average_sales*52
       const average_monthly = average_year/12

       setAverageMonthlySalesAmount(average_monthly)
       setAverageSalesAmount(average_sales)
       setAverageYearlySalesAmount(average_year)
    }

    else{
      const average_sales =  (miniVal + maxiVal)/2
       const average_year = average_sales*12
       const average_monthly = average_year/12

       setAverageMonthlySalesAmount(average_monthly)
       setAverageSalesAmount(average_sales)
       setAverageYearlySalesAmount(average_year)
    }
   },[typeSelected,alternator])
  const SalesList = ['Daily','Weekly','Monthly']



  //onChange={(e) => props.handleListSelect(e.target.value)}  
    return (<div style={{width:'100%',boxSizing:"border-box",boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius: '10px',backgroundColor:"white",padding:'2em'}}>
    <p className='formTitle'>Sales Estimates</p>
    

    {
      <section className='sign-up-details-inputs-sales-two'>
      {
        firstLayer.map((data,id)=><div style={{width:"100%"}} id={id}  key={id}>
      { data.displayType==='SELECT'?
        <div className='sign-up-details-input-container'>
        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
        <div className='selectHolder'>
          <select  {...register(`${data.name}`, { required: data.isRequired?true:false })} 
            onChange={(e) => {props.handleListSelect(e.target.value);setTypeSelected(e.target.value)}}>
            <option value="">Select an option</option>
            {data.options.map((element, i) => {
              return <option value={element} key={i}>{element}</option>
            })}
          </select>
        </div>
        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
        </div>:
  
        ''
      }
      </div>)
    }
      </section>
    }


    {
      <section className='sign-up-details-inputs-sales-two'>
      {
        secondLayer.map((data,id)=><div style={{width:"100%"}} id={id}  key={id}>
      {data.name ==='AverageSales'?
      <AmountInputReadOnly data={data} value={averageSalesAmount} errors={errors} register={register} setValue={setValue}/>
      : 
      data.name ==='AverageSalesMonthly'?
      <AmountInputReadOnly data={data} value={averageMonthlySalesAmount} errors={errors} register={register} setValue={setValue}/>
      :
      data.name ==='AverageSalesAnnual'?
      <AmountInputReadOnly data={data} value={averageSalesYearlyAmount} errors={errors} register={register} setValue={setValue}/>
      : 
      data.name === 'Min' || 'Max'?
      <MinMaxInput data={data} register={register} setValue={setValue} errors={errors} setAlternator={setAlternator} 
                  alternator={alternator} triggerAmountSave={triggerAmountSave}/>:
        ''
      }
      </div>)
    }
      </section>
    }


    {
      <section className='sign-up-details-inputs-sales-two'>
      {
        thirdLayer.map((data,id)=><div style={{width:"100%"}} id={id}  key={id}>
      {data.name ==='AverageSales'?
      <AmountInputReadOnly data={data} value={averageSalesAmount} errors={errors} register={register} setValue={setValue}/>
      : 
      data.name ==='AverageSalesMonthly'?
      <AmountInputReadOnly data={data} value={averageMonthlySalesAmount} errors={errors} register={register} setValue={setValue}/>
      :
      data.name ==='AverageSalesAnnual'?
      <AmountInputReadOnly data={data} value={averageSalesYearlyAmount} errors={errors} register={register} setValue={setValue}/>
      : 
        data.displayType==='SELECT'?
        <div className='sign-up-details-input-container'>
        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
        <div className='selectHolder'>
          <select  {...register(`${data.name}`, { required: data.isRequired?true:false })} 
            onChange={(e) => {props.handleListSelect(e.target.value);setTypeSelected(e.target.value)}}>
            <option value="">Select an option</option>
            {data.options.map((element, i) => {
              return <option value={element} key={i}>{element}</option>
            })}
          </select>
        </div>
        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
        </div>:
  
        data.displayType==='TEXT'|| data.displayType==='NUMBER'?
        <div className='sign-up-details-input-container'> 
            <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
            <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
            { 
              required: data.isRequired?true:false, 
              
            })} />
            <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
        </div>
        :
        <AmountInput data={data} register={register} setValue={setValue} errors={errors}/>
      }
      </div>)
    }
      </section>
    }


    {
      <section className='sign-up-details-inputs-sales-two'>
      {
        fourthLayer.map((data,id)=><div style={{width:"100%"}} id={id}  key={id}>
      {data.displayType==='SELECT'?
        <div className='sign-up-details-input-container'>
        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
        <div className='selectHolder'>
          <select  {...register(`${data.name}`, { required: data.isRequired?true:false })} 
            onChange={(e) => props.handleListSelect(e.target.value)}>
            <option value="">Select an option</option>
            {data.options.map((element, i) => {
              return <option value={element} key={i}>{element}</option>
            })}
          </select>
        </div>
        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
        </div>:
  
        data.displayType==='TEXT'|| data.displayType==='NUMBER'?
        <div className='sign-up-details-input-container'> 
            <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
            <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
            { 
              required: data.isRequired?true:false, 
              
            })} />
            <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
        </div>
        :
        <AmountInput data={data} register={register} setValue={setValue} errors={errors}/>
      }
      </div>)
    }
      </section>
    }



    
  </div>
    )
  }


export default SalesEstimateSection

/**
 *  required: data.isRequired?true:false, 
                pattern: {
                  value: /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/,
                  message: `${data.name} needs to be filled`
                }
 */