import React from 'react'
import Utils from '../../../../library/Utils'


function AgreementExtraInfoSlice({data}) {
  return (
    <div className='extraInfoParent'>
        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Account Number
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.accountNo)?
                         data.accountNo:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Closed Date
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.closedDate)?
                         data.closedDate:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Sub Account Number
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.subAccountNo)?
                         data.subAccountNo:'--'}
                </section>
            </div>

        </section>





        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Account Status Code
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.accountStatusCode)?
                         data.accountStatusCode:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Current Balance Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.currentBalanceAmt)?
                         data.currentBalanceAmt:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Date Account Opened
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.dateAccountOpened)?
                         data.dateAccountOpened:'--'}
                </section>
            </div>


        </section>



        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Changed on Date
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.changedOnDate)?
                         data.changedOnDate:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Months in Arrears
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.monthsInArrears)?
                         data.monthsInArrears:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Installment Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.instalmentAmount)?
                         data.instalmentAmount:'--'}
                </section>
            </div>


        </section>





        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Opening Balance Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.indicatorDescription)?
                         data.indicatorDescription:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Indicator Description
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.repaymentFrequency)?
                         data.repaymentFrequency:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Current Balance Debit Ind
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.openingBalanceAmt)?
                         data.openingBalanceAmt:'--'}
                </section>
            </div>


        </section>


    </div>
  )
}

export default AgreementExtraInfoSlice