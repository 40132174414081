import React, { useState } from 'react'
import Modal from "react-modal"
import { useDispatch } from 'react-redux'
import DeleteIconRed from '../../../../../assests/svg-icons/DeleteIconRed.svg'
import Utils from '../../../../../library/Utils'
import { resetDeleteRulesByKey, resetRules } from '../../../../../redux/Slices/SelfServiceDecision'
import DeleteRuleModals from '../../../Modals/DeleteRuleModal'
import './ParameterList.css'

function RuleList({setSection,selfScoreDef,setcreateRuleIsOpen,accessToken,setShowScoreCard,setParamMetaData}) {
  const [headers, setHeader] = useState(['Rule Name','Rule Point','Created Date','Action'])
  const [deleteRuleModalIsOpen, setDeleteRuleModalIsOpen] = useState(false);
  const [ruleKey,setRuleKey] = useState('')
  const [cardRuleName,setCardRuleName] = useState('')

  const dispatch = useDispatch()

    const onRuleCreation = ()=>{
        dispatch(resetRules());
        setcreateRuleIsOpen(true)
    }

    function afterOpenModal() { }

    const deleteParamRecord = (key,name)=>{
        setCardRuleName(name)
        setRuleKey(key)
        dispatch(resetDeleteRulesByKey())
        setDeleteRuleModalIsOpen(true)
        
    }


    function closeDeletParameterModal() {
        setDeleteRuleModalIsOpen(false)

    //dispatch(resetDownload());
    }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '80%',
      top: '33%',
      left: '33%',
      padding: '20px',
      boxSizing:'border-box',
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };



  return (
    <div>
        <section>
            <div style={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                <div>
                    <span style={{fontSize:"24px",fontWeight:"700",color:"black"}}>Rules</span>
                </div>
                
                <div className='createParameter' onClick={()=>onRuleCreation()}>
                    <span style={{fontSize:"18px",fontWeight:"700",color:"white"}}>Create Rules</span>     
                </div>
               
                
            </div>

            <div style={{marginTop:'20px'}}>{Utils.isFieldEmpty(selfScoreDef.selfServiceGetRulesByKeyData)?
                <>
                    <section className='EmptyParams'>
                        <i className='title' >There is currently no Rule to display.</i>
                        <span className='proceed' >Please, click the button above to proceed.</span>
                    </section>
                </>
                :
                <>
                    <section style={{display:"flex",justifyContent:"space-between"}}>
                        {headers.map((head,index)=><section style={{fontWeight:'700',fontSize:'16px',width:"25%",paddingLeft:"10px",boxSizing:"border-box"}} key={index}>{head}</section>)}
                    </section>
                    <section style={{display:"flex",justifyContent:"space-between",height:'auto',maxHeight:"300px",overflow:'auto',flexDirection:"column",rowGap:'25px',marginTop:"10px"}}>
                        {selfScoreDef.selfServiceGetRulesByKeyData.map((item,index)=>
                            <div key={index} style={{display:"flex",padding:"10px 0px",width:"100%",justifyContent:"space-between",borderRadius:"7px",backgroundColor:"white",alignItems:'center'}}>
                                <section style={{fontWeight:'500',fontSize:'16px',width:"25%",color:'#1F2227',paddingLeft:"20px",boxSizing:"border-box"}}>{item.friendlyRuleTitle}</section>
                                <section style={{fontWeight:"400",fontSize:'16px',width:"25%",paddingLeft:"20px",boxSizing:"border-box"}}>{item.points}</section>
                                <section style={{fontWeight:"400",fontSize:'16px',width:"25%",paddingLeft:"20px",boxSizing:"border-box"}}>{!Utils.isFieldEmpty(item.createdDate)? Utils.formatLongDateAndTime(item.createdDate):'--' }</section>
                                <div style={{display:"flex",justifyContent:'left',width:"25%",columnGap:'45px',paddingLeft:"30px",boxSizing:"border-box"}}>
                                    <section className='editButton' >Edit</section>
                                    <span className='imageHolder' onClick={()=>deleteParamRecord(item.key,item.friendlyRuleTitle)}><img src={DeleteIconRed}/></span>
                                </div>
                            </div>
                            )}
                    </section>
                </>
                
                }  
            </div>
        </section>

        <Modal isOpen={deleteRuleModalIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Delte Rule Modal" >
               <DeleteRuleModals closeModal={closeDeletParameterModal}
                                    accessToken={accessToken}
                                    ruleKey={ruleKey}
                                    name={cardRuleName}
                                    />
        </Modal>
    </div>
  )
}

export default RuleList