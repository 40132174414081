import Utils from "../../../../../../library/Utils";
import TableReform from "./TableReform";


function LenderSectorSerializer({data}) {
    const bodyHolder = []
    const headers = ['Lender Sector','NPA Acccount','Performing Account','Performing Account History']
    const bodyKeys = Object.keys(data)

    for(let i=0; i<bodyKeys.length; i++){
      const tempHolder = []
      const holderObj = data[bodyKeys[i]]
      const internalKeys = Object.keys(data[bodyKeys[i]])
      if(i===0){
        tempHolder.push('Bank')
      }
      else{
        tempHolder.push('Others')
      }
      internalKeys.forEach(key => {
        tempHolder.push(holderObj[key])
      });

      bodyHolder.push(tempHolder)

    }


  return (
    <>
      {!Utils.isFieldEmpty(bodyHolder) && <TableReform headers={headers}
                                                       Body={bodyHolder}
      />}
    </>
  )
}

export default LenderSectorSerializer