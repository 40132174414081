import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { addToExtractedData,
         getBvnDataAsync,
         resetKycData,
         selectKycEntireDataState,
         resetBvnData } from '../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../library/Variables'
import errorImage from '../../../../assests/icons/errorImage.png'
import { getReportDetail,resetReport } from '../../../../redux/Slices/KycOptions'
import { getReportDetailKyb ,resetReportKyb} from '../../../../redux/Slices/KybOptions'
import successImg from '../../../../assests/icons/amlFound.png'
import './bvnholder.css'


function BvnModal({setVerificationType,accessToken,keyFromRoute,
                  setViewReport,setKycToShow,inputStyle,customStyles}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();


  const onSubmit = (formData) => {
  const obj = {bvn:formData.inputName,
                statementKey: keyFromRoute,
                accessToken}
             
    dispatch(getBvnDataAsync(obj))
  }

  const getDetails = (token,uuid,type)=>{
    setIsOpen(false);
    setKycToShow(null);
    dispatch(resetBvnData());
    dispatch(resetKycData());

    if(['CAC','TIN'].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    }
    else{
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReport())
        dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
}


  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p className='title-class'>BVN Verification</p>
       <div style={{}}>
           <p>Input BVN</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                            setKycToShow(null);
                                            dispatch(resetBvnData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>


const ModalSuccess = <div className='modal-error'>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
      </div>
      <p style={{textAlign:'center',
                 color:"#2A38A4",
                 fontSize:"24px",
                 fontWeight:"700"}}>BVN Extraction Successfull</p>
      <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
          Click Done to see
      </p>
      <div className='modal-initial-button-hold'>
            <span className='check' 
            onClick={()=>getDetails(accessToken,kycState.bvnExtraction.uuid,kycState.bvnExtraction.type)}>
              Done
            </span>
            
        </div>
      </div>


  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.bvnErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKycToShow(null);
                                                  dispatch(resetBvnData())}}>Cancel</span>
           
       </div>
  </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.bvnStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.bvnStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.bvnStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess)
            
        }

        if(kycState.bvnStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="BVN Model" >
            {modal}
    </Modal>
  )
}

export default BvnModal