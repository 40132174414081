import React, { useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useNavigate } from "react-router-dom";
import Form from '../../../components/Form/Form';
import Utils from '../../../library/Utils';
import { FetchDataStatus } from "../../../library/Variables";
import { getPaginatedScoreCardDefinitions, getParticularCustomDecisionCreditScore, resetPaginationDefinition, selectCustomDecisionCreditScoreState } from '../../../redux/Slices/CustomDecisionCustomCreditScore';
import { resetSelfService } from '../../../redux/Slices/SelfServiceDecision';
import Spinner from "../../../svg/Spinner/Spinner";
import { Table } from '../../FraudDetect/components/Table';
import './HistoryPage.css';



function HistoryPage({setPageActive, update,setFilters,filters,setSelectedPage,selectedPage,setShowBreakDownSpinner,selectedPageconfiguration,preparedTableData, updateInitialView, scoreData, handleViewScore, accessToken, user}) {
  let content = <Spinner />;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const filteredResult = useSelector(selectCustomDecisionCreditScoreState)
  
  const [isActiveSearch, setIsActiveSearch] = useState(false);

    function handleParentCallback(activeFields) {
      setIsActiveSearch(true);
      const filterObj = {accessToken,
                         pageNumber:1,
                         pageSize:10,
                         customerId:activeFields.name,
                         startDate:activeFields.startDate,
                         endDate:activeFields.endDate,
                         scorecardDefinitionKey:''}
      dispatch(getPaginatedScoreCardDefinitions(filterObj))
      //dispatch(getAllCustomDecisionEngineScores(activeFields));
    }

    const previousPage = ()=>{
      if(selectedPage!==1){
        setSelectedPage(selectedPage-1)
        dispatch(resetPaginationDefinition())
      }
    }

    const nextPage = ()=>{
      if(selectedPage!==filteredResult.paginatedScoreDefinitionsData?.metaData?.pages){
        setSelectedPage(selectedPage+1)
        dispatch(resetPaginationDefinition())
      }
    }

    const params = { 
      accessToken: accessToken,
      startDate: '',
      endDate: '',
      name: '',
      type: ''
    };

    function handleFormReset() {
      params.startDate = '';
      params.endDate = '';
      params.name = '';
      params.type = "";
  
      setIsActiveSearch(false);
      //dispatch(getAllCustomDecisionEngineScores(params));
    }
   

    const navigateToSelfService = ()=>{
      dispatch(resetSelfService());
      navigate('/DecisionEngineSelfService')

    }


    /**const scoreHistory = useMemo(() => [
        {
            Header: 'Customer ID',
            accessor: 'customerId',
        },
        { 
          Header: 'Scorecard Name',
          accessor: 'scorecardName',
        },        
        { 
            Header: 'Created Date',
            accessor: 'createdDate',
            Filter: DateRangeColumnFilter,
            filter: 'dateRange'
        },
        {
            id: 'button-column',
            disableSortBy: true,
            Cell: data => {
              return (
                <div className="table-row-buttons-container">
                  <button state={{ key: data.row.original.key }} onClick={() => {dispatch(getParticularCustomDecisionCreditScore({accessToken:accessToken,key:data.row.original.key}));
                   updateInitialView();
                   handleFormReset()
                  
                  }} className="button-square table-row-buttons edit button-view">View</button>
                </div>
              );
            }
        }
    ]) **/


    const searchFormFields = [
      {
        type: "text",
        id: "st-name-search",
        placeholder: "Customer ID",
        param: "name",
        className: "form-default-container name"
      },
      {
        type: "DATE_RANGE",
        params: { first: 'startDate', second: 'endDate' },
        header: { value: 'Insert date range', label: 'Created Date Range' },
        className: "form-date-range-container",
      },
      {
        type: "SELECT",
        hiddenOptionLabel: "Scorecard",
        options: filteredResult?.customDecisionCustomScoreCards,
        id: "st-type-search",
        param: "type",
        className: "form-select-container type"
      }
    ]

    //preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
    
      if( filteredResult.paginatedScoreDefinitionsState === FetchDataStatus.FETCHING) {
        content = <Spinner />
      }else {

        if(filteredResult.paginatedScoreDefinitionsData?.metaData?.pages<1){
          content = <div className="cs-ns-current-container">
                      <div className="cs-current-header">
                          <h2 className="detail-header">There are currently no Credit Scores to display.</h2>
                      </div>
                      <p>Please click the Generate Score or 
                        <a href={"mailto:support@periculum.io"} 
                          className='link-color'>contact us

                          </a> for more information.</p>
                    </div>
        }
        else{
          content = <div className='score-history-table'>
          <Table
            showPagination={true}
            pageCount={filteredResult.paginatedScoreDefinitionsData?.metaData?.pages}
            loading={filteredResult.paginatedScoreDefinitionsState.status === FetchDataStatus.FETCHING}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            previousPage={previousPage}
            nextPage={nextPage}
            rowClass={"table-row-two-colored"}
            maxPagesNumber={filteredResult.paginatedScoreDefinitionsData?.metaData?.pages}
            gotoPage={(page) =>{ 
              setFilters((prev) => ({ ...prev, pageNumber: page }));
              setSelectedPage(page)
              dispatch(resetPaginationDefinition())
              }
              
            }
            columns={[
              {
                title: "Customer Id",
                field: "customerId",
                sortable: true,
              },
              {
                title: "ScoreCard Name",
                field: "scorecardName",
                sortable: true,
              },

              {
                title: "Created Date",
                field: "createdDate",
                sortable: true,
              },
              
              
              {
                title: (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <span>Action</span>

                    <span>
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.501 12C22.501 17.52 18.021 22 12.501 22C6.98098 22 3.61098 16.44 3.61098 16.44M3.61098 16.44H8.13098M3.61098 16.44V21.44M2.50098 12C2.50098 6.48 6.94098 2 12.501 2C19.171 2 22.501 7.56 22.501 7.56M22.501 7.56V2.56M22.501 7.56H18.061"
                          stroke="#2A38A4"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                ),
                field: "action",
                Cell: ({ entry }) => (
                  <div>
                    <button
                      onClick={() => {setShowBreakDownSpinner(true);
                                      dispatch(getParticularCustomDecisionCreditScore({accessToken:accessToken,key:entry.key})); 
                                      updateInitialView();
                                      //handleFormReset()
                                      }}
                     
                    style={{backgroundColor:'#2B52AA',color:"white",padding:"10px 30px",border:"0px",outline:"none",borderRadius:"5px"}}>
                      View
                    </button>
                  </div>
                ),
              },
            ].filter(Boolean)}
            data={filteredResult.paginatedScoreDefinitionsData?.data?.scoresBreakdowns?.map((item) => {
              return {
                ...item,
            
                createdDate: Utils.formatLongDateAndTime(item.createdDate),
             
              };
            })}
          />
        </div>  
        }
        
      }
    


  return (
    <>
        <div className='subpage-header'>
            <div>
                <h3>Score History</h3>
                <p>See all scores generated so far</p>
            </div>
          
        </div>

        {
          (user.user.permissions.checkScorecard === true || user.user.permissions?.viewCurrentScore === true) && 
          <> 
            <div className='dash-body decision-engine'>
              <div className="st-search-container" 
                id={Utils.isFieldEmpty(preparedTableData) && isActiveSearch === false ? "no-statements-seach" : null}>
                <Form header={"Search"} accessToken={accessToken} parentCallback={(activeFields) => handleParentCallback(activeFields)} 
                  searchFormFields={searchFormFields} />
              </div>
            </div>
            <div className='selfBoxHold'>
            
              {
              (user.user.permissions.generateScorecard === true) && <>
                <section className='selfGenerate' onClick={update} >Generate Score</section>
              </>}
           
            </div>

            { content }
          </>
        }




    </>
  )
}

export default HistoryPage



/***
 *  if( filteredResult.customDecisionStatementStatus === FetchDataStatus.FETCHING) {
        content = <Spinner />
      }else { content = <div className='score-history-table'>

            <Table tableClass="accounts-type-table consolidated-overview-table all-accounts-table" pagination={true} pageSize={[10]} data={preparedTableData} columns={scoreHistory}
                tableExtra={false} tableFilter={false} />   
          </div> 
 */

          //import { Table, SelectColumnFilter, DateRangeColumnFilter } from '../../../components/Table/Table';