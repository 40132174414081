import React,{useState,useEffect} from 'react'
import './kyc.css'
import logoHold from '../../../assests/icons/kycLogo.png'
import Modal from 'react-modal'
import BankAccountModal from './BankAccountHolder/BankAccountModal'
import PhoneModal from './PhoneNumberHolder/PhoneNumberModal'
import { useSelector } from 'react-redux'
import { selectKenyaStatementData } from '../../../redux/Slices/StatementKyc/Kenya/KenyaStatementKyc'
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables'
import { getKenyaStatementKycHistory } from '../../../redux/Slices/StatementKyc/Kenya/KenyaStatementKyc'
import Spinner from '../../../svg/Spinner/Spinner'
import { useDispatch } from 'react-redux'
import Utils from '../../../library/Utils'
import { Table,SelectColumnFilter, DateRangeColumnFilter } from '../../../components/Table/Table'
import KenyaKycHistory from './kycHistory/KenyaKycHistory'
import { CountryCodes } from '../../../library/CountryCodes'


if (process.env.NODE_ENV !== "test") {
    Modal.setAppElement("#root");
  }


function KycKenya({configuration,accessToken,keyFromRoute}) {
  
  const kycState = useSelector(selectKenyaStatementData)
  const [viewReport,setViewReport] = useState(false)
  const [historyArray,setHistoryArray] =  useState([])
  const [historyView,setHistoryView] = useState()
  const [selectedFilter,setSelectedFilter] = useState('PHONE_NUMBER')
 
  const dispatch = useDispatch()

  /**
   * const changeFilter = (filter)=>{
    //dispatch(resetLoanFilter())
    setSelectedFilter(filter)
    //dispatch(getFilteredLoan({accessToken,filter}))
 }
   */




  const cardHolder = [{id:0,title:'Phone Number Verification',
                            info:'This check requires phone number of the individual'},
                            {id:1,title:'Bank Account Verification',
                            info:'This check requires bank account of the individual'}]

  const [modalIsOpen, setIsOpen] = useState(false);

  const [kycToShow,setKycToShow] = useState(null)
  const [kycModal,setKycModal] = useState(null)
  const [reportData,setReportData] = useState(null)
  const [tableHeaders,setTableHeaders] = useState(['Status','Verification Type','Full Name', 'Phone Number'])
  const [verificationType,setVerificationType] = useState()
  const customStyles = {
      content: {
          transition: "0.125s ease-in-out",
          transform: "scale(1)",
          height: "33%",
          width: "50%",
          top: "33%",
          left: "33%",
          padding: "0 0 0 0",
          overflowY: "scroll",
      },
      overlay: {
          zIndex: 1000,
          position: "absolute"
      }
      };

  const inputStyle = {
    width: "100%",
    marginTop: "10px",
    border: 'none',
    padding: '0px 24px 0px 24px',
    boxSizing: "border-box",
    backgroundColor: 'rgba(239, 244, 255, 1)',
    outline: "none",
    height: '40px',
  };

  const accountHeaders = ['Status','Verification Type','Full Name','Account Name','Account Number']
  const phoneHeaders = ['Status','Verification Type','Full Name', 'Phone Number']

  function openModal(){
      setIsOpen(true);
    }
        
          function afterOpenModal(){}


  useEffect(()=>{

    if(kycState.historyStatus===FetchDataStatus.FETCHING){

    }
    if(kycState.historyStatus===FetchDataStatus.SUCCESS){
      const filteredArray = kycState.historyExtractions
                            ?.filter(element => element.verificationType=== 'PHONE_NUMBER');
      setHistoryArray(filteredArray)
    }
  },[kycState.extractionStatus,kycState.historyStatus])


  useEffect(()=>{
   
      dispatch(getKenyaStatementKycHistory({key:keyFromRoute,accessToken}))
       
  },[])


   
  return (
    <>
        
        <div>

            {kycModal}
            {kycToShow===null?null
            :kycToShow===0?<PhoneModal keyFromRoute={keyFromRoute} 
                                       accessToken={accessToken} 
                                       setViewReport={setViewReport} 
                                       customStyles={customStyles} 
                                       setKycToShow={setKycToShow} 
                                       inputStyle={inputStyle}/>:
            kycToShow===1?<BankAccountModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}/>:
            ''}
            <p className='title-class'>KYC Verification</p>
            <p className='title-subclass'>Select a KYC verification method below.</p>
        
            <div style={{display:"flex",flexWrap:'wrap',justifyContent:'even',alignItems:'center',marginTop:"30px",rowGap:"10px",columnGap:"10px"}}>
            {configuration.countryCode === CountryCodes.KENYA? cardHolder.map(card=>
                <div onClick={()=>{setKycToShow(card.id)}} style={{display:"flex",flex:'1',margin:'10px 0px',padding:"15px 15px",height:"150px",cursor:"pointer",borderRadius:'4px',flexDirection:'column',justifyContent:"space-around",boxSizing:'border-box',backgroundColor:"rgba(255, 255, 255, 1)"}} key={card.id}>
                    <p><img src={logoHold}/></p>
                    <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                    <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                </div>)
                :<div style={{minHeight:"150px",
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:"space-around",
                                backgroundColor:'white',
                                width:'100%',
                                borderRadius:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                margin:'30px auto'}}>
                        <p style={{fontWeight:'700',
                                   fontStyle:'italic',
                                   fontSize:'22px'}}>
                                Anticipate KYC!
                        </p>

                        <p style={{textAlign:'center',
                                    fontSize:'15px',
                                    marginTop:'10px'}}>
                                    Coming Soon
                        </p>
                    </div>}
            </div>

            {(kycState.historyStatus===FetchDataStatus.FETCHING)?
            <div className="spinner-container">
                <Spinner></Spinner>
            </div>
          :
          (kycState.historyStatus==='SUCCESS' && Utils.isFieldEmpty(kycState.historyExtractions))?
          <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
            You currently have no historically extracted KYC document on this Statement.
          </div>:
          (kycState.historyStatus==='SUCCESS' && kycState.historyExtractions?.length>0 )?
          <KenyaKycHistory 
              setVerificationType={setVerificationType}
              historyArray={historyArray} tableHeaders={tableHeaders}
              accessToken={accessToken}
              setHistoryArray={setHistoryArray}
              setSelectedFilter={setSelectedFilter}
              fullData = {kycState.historyExtractions}
              selectedFilter={selectedFilter}
              setTableHeaders={setTableHeaders}
              accountHeaders={accountHeaders}
              phoneHeaders={phoneHeaders}
              />:
          (kycState.historyStatus==='FAILURE')?
          <div style={{width:'100%',color:'red',textAlign:'center',marginTop:'30px'}}>
            Something went wrong!.
          </div>:''}
            
           
      
        
              </div>
    </>
  )
}

export default KycKenya

//{Object.keys(historyArray).map(key=> <p>{key}</p>)}

//<div>{historyArray.map(data=><p>{Object.keys(data).map(key=> <span>{key}</span>)}</p>)}</div>}

/**
 * 
            {historyArray.length>0 &&
            }
 */


  //{(historyArray.length>0 && !viewReport) && <ExtractionHistory setReportData={setReportData} setViewReport={setViewReport} viewReport={viewReport} historyArray={historyArray} tableHeaders={tableHeaders}/>}


  /**
   * <div className="spinner-container">
            <Spinner></Spinner>
          </div>
   */


  /**
   *  
   */