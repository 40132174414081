import React, { useState, useMemo } from "react";
import Utils from "../../../../library/Utils";
import { Table, DateRangeColumnFilter, SelectColumnFilter } from "../../../../components/Table/Table";
import DetailsCard from "../../../../components/DetailsCard/DetailsCard";
import List from "../../../../components/List/List";

function BureauAccountView(props) {
  const account = props.account;
  const configuration = props.configuration;

  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showAddressHistory, setShowAddressHistory] = useState(false);
  const [showContactHistory, setShowContactHistory] = useState(false);
  
  let paymentHistoryTable = [];

  const theadersPaymentHistory = useMemo(() => [
    { 
      Header: "Date",
      accessor: "date",
      Filter: DateRangeColumnFilter,
      sortType: (first, second) => {
        return new Date(first.original.hiddenDate) - new Date(second.original.hiddenDate);
      }
    },
    { 
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
    }]
  )
  
  const theadersAddressHistory = useMemo(() => [
    {
      Header: "Date Reported",
      accessor: "dateReported",
      Filter: DateRangeColumnFilter,
      Cell: data => {
        return Utils.formatLongDate(data.row.original.dateReported)
      } 
    },
    {
      Header: "Address",
      accessor: "address"
    },
    {
      Header: "Address Type",
      accessor: "addressType"
    }
  ])
  
  const theadersContactHistory = useMemo(() => [
    {
      Header: "Date Reported",
      accessor: "dateReported",
      Filter: DateRangeColumnFilter,
      Cell: data => {
        return Utils.formatLongDate(data.row.original.dateReported)
      }   
    },
    {
      Header: "Contact Type",
      accessor: "contactType"
    },
    {
      Header: "Details",
      accessor: "details"
    }
  ])
        
  if(!Utils.isFieldEmpty(account.statementBureauAccountPaymentHistory)) {
    account.statementBureauAccountPaymentHistory.forEach(payment => {
      paymentHistoryTable.push({
        hiddenDate: payment.year + "-" + payment.month + "- 01",
        date: payment.month + " " + payment.year,
        status: payment.status
      })
    });

    paymentHistoryTable.sort((first, second) => new Date(second.hiddenDate) - new Date(first.hiddenDate));
  }

  return (
    <>
      <div className="bureau-report-view-col">
        <DetailsCard bodyExtraClass="details-bottom" title="Account Details" titleClass="detail-header" detailContent={
          <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"}
            listContent={[
              { header: "Account Name", detail: Utils.checkNull(account.accountName)},
              { header: "Account Number", detail: Utils.checkNull(account.accountNo)},
              { header: "Account Type", detail: Utils.checkNull(account.type)},
              { header: "Pay Status", detail: Utils.checkNull(account.payStatus)},
              { header: "Payment Terms", detail: Utils.checkNull(account.paymentTerms)},
              { header: "Date Opened", detail: Utils.formatLongDate(account.dateOpened)},
              { header: "Responsibility", detail: Utils.checkNull(account.responsibility)},
              { header: "Address Identification Number", detail: Utils.checkNull(account.addressIdentificationNumber)},
              { header: "Balance", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, account.balance)},
              { header: "Credit Limit", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, account.creditLimit)},
              { header: "High Balance", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, account.highBalance)},
              { header: "Monthly Payment", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, account.monthlyPayment)},
              { header: "Recent Payment Amount", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, account.recentPaymentAmount)}
            ]} />
          }
        />
      </div>
      {!Utils.isFieldEmpty(paymentHistoryTable) &&
        <div className="bureau-form-section-account-tables cards">
          <div className="bureau-form-account-header-container">
            <h4>Payment History</h4>
            <button onClick={() => setShowPaymentHistory(!showPaymentHistory)} className="button-square button-solid">
              {showPaymentHistory ? "Hide" : "Show"}
            </button>
          </div>
          {showPaymentHistory &&
          <div>
            <Table data={paymentHistoryTable} columns={theadersPaymentHistory} pagination={true} pageSize={[5]}
              tableClass="bureau-account-payment-history-table" />
          </div>
          }
        </div>
      }
      {!Utils.isFieldEmpty(account.statementBureauAccountAddressHistory) &&
        <div className="bureau-form-section-account-tables cards">
          <div className="bureau-form-account-header-container">
            <h4>Address History</h4>
            <button onClick={() => setShowAddressHistory(!showAddressHistory)} className="button-square button-solid">
              {showAddressHistory ? "Hide" : "Show"}
            </button>
          </div>
          {showAddressHistory &&
            <div>
              <Table data={account.statementBureauAccountAddressHistory} columns={theadersAddressHistory} pagination={true} 
              pageSize={[5]} tableClass="bureau-account-header-reason-table" />
            </div>
          }
        </div>
      }
      {!Utils.isFieldEmpty(account.statementBureauAccountContactHistory) &&
        <div className="bureau-form-section-account-tables cards">
          <div className="bureau-form-account-header-container">
            <h4>Contact History</h4>
            <button onClick={() => setShowContactHistory(!showContactHistory)} className="button-square button-solid">
              {showContactHistory ? "Hide" : "Show"}
            </button>
          </div>
          {showContactHistory &&
            <div>
              <Table data={account.statementBureauAccountContactHistory} columns={theadersContactHistory} pagination={true} 
              pageSize={[5]} tableClass="bureau-account-header-reason-table" />
            </div>
          }
        </div>
      }
    </>
  )
}

export default BureauAccountView;