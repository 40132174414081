export const personalDetailsData = {
    mainTitle:'Personal Details',
    listOne: [
        {title:'Full Name',value:'Emmanuel Job'},
        {title:'Agent ID Number',value:'ID10012'},
        {title:'Phone Number',value:'0000 123 4568'},
        {title:'Residential Address',value:'Plot 2, Behind Pepsi Depot, Gbagada'},
        {title:'Email Address',value:'emmajob@example.com'},
        {title:'Identification Type',value:'National Identification Number (NIN)'},
        {title:'Identification Number',value:'124567896676'}
    ],
    listTwo:[
        {title:'Email Address',value:'emmajob@example.com'},
        {title:'Identification Type',value:'National Identification Number (NIN)'},
        {title:'Identification Number',value:'124567896676'}
    ]
}

export const businessDetailsData = {
    mainTitle:'Business Details',
    listOne: [
        {title:'Business Name',value:'Starships Ventures'},
        {title:'CAC Number',value:'RC 000000'},
        {title:'Account Age',value:'8 Months'},
        {title:'Business Address',value:'Plot 2, Behind Pepsi Depot, Gbagada'},
        {title:'Utility Bill',value:'Electricity Bill'},
        {title:'Number of POS Machine (s)',value:'1'},
        {title:'POS ID Number',value:'123456678'}
    ],
    listTwo:[
        {title:'Utility Bill',value:'Electricity Bill'},
        {title:'Number of POS Machine (s)',value:'1'},
        {title:'POS ID Number',value:'123456678'}
    ]
}