import React,{useState}from 'react'
import deleteIcon from '../../../../assests/svg-icons/minusButton.svg'
import AddIcon from '../../../../assests/svg-icons/addButton.svg'

function BreakDownSlice({indexSelected,index,element}) {
    const [showBreakDown,setShowBreakDown] = useState(false)
  return (
    <section style={{display:indexSelected===index?'flex':'none',
                                   
        alignItems:"flex-start",
        flexWrap: 'wrap',
        flexDirection:"column",
        width:'45%',
        }}>

        <section className='tabToggleHold'>
            <section style={{display:"flex",
                            alignItems:"center",
                            justifyContent:"start",
                            columnGap:'5px',
                            paddingLeft:"10px",
                            width:'100%',
                            borderRadius:"7px",
                            backgroundColor:"white",
                            boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)',
                            margin:'10px auto',
                            padding:'10px 10px',
                            boxSizing:"border-box",
                            cursor:'pointer'}}
                            onClick={()=>setShowBreakDown(!showBreakDown)}>
                <img src={showBreakDown?deleteIcon:AddIcon}/>
                <section style={{
                    color:'#2A38A4',
                    fontWeight:'700',
                    fontSize:"18px"
                }}>
                    <div>
                        {element[0].year}
                    </div>
                </section>
            
             </section>

        </section>

        {showBreakDown && <section className='tabToggleHold'>
            <div className='tabSpanHolder'>
                <span className='tabSpanHeader'>Year</span>
                <span className='tabSpanHeader'>Month</span>
                <span className='tabSpanHeader'>Payment Status</span>
            </div>

            { element.map((item,index)=>
                <div className='bodySpanHolder'>
                        <span>
                            {item.year}
                        </span>
                        <span>
                            {item.month}
                        </span>
                        <span>
                            {item.status}
                        </span>
                </div>
            )}

        </section>}
    </section>
  )
}

export default BreakDownSlice