import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  customRuleByIdSelector,
  fetchCustomRuleByIdAsync,
} from "../../../../redux/Slices/fraudDetect/ruleSlice";
import { Table } from "../../components/Table";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import styles from "./index.module.css";

const ViewCustomRule = () => {
  const navigate = useNavigate();

  const customRule = useSelector(customRuleByIdSelector);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    dispatch(fetchCustomRuleByIdAsync({ id: params.id }));
  }, [dispatch, params.id]);

  if (customRule.error && customRule.error.code === 404) {
    return (
      <InnerLayout>
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          Hmm... this page does not exist.
        </div>
      </InnerLayout>
    );
  }

  return (
    <InnerLayout>
      <div className={styles.formWrapper}>
        <div className={classNames(styles.titleWrapper)}>
          <h5 className={styles.formtTitle}>Custom List</h5>
        </div>
        <div
          className={classNames(styles.tableContainer, styles.tableContainerBg)}
        >
          <Table
            showPagination={true}
            loading={customRule.status === "loading"}
            pageCount={customRule?.data?.data?.totalPages}
            gotoPage={(page) =>
              setFilters((prev) => ({ ...prev, pageNumber: page }))
            }
            columns={[
              {
                title: "User",
                field: "user",
              },
              {
                title: "Bank Name",
                field: "bankName",
              },

              {
                title: "Account Name",
                field: "accountName",
              },

              {
                title: "Account Number",
                field: "accountNumber",
              },

              {
                title: "Email Addredd",
                field: "email",
              },

              {
                title: "Phone Number",
                field: "phoneNumber",
              },
            ].filter(Boolean)}
            data={[
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
              {
                user: "Emmanuel Job",
                bankName: "Zenith bank",
                accountName: "Emmanuel Job",
                accountNumber: "0000000000",
                email: "emmajob@example.com",
                phoneNumber: "0000 000 0000",
              },
            ]}
          />
        </div>
      </div>
    </InnerLayout>
  );
};

export default ViewCustomRule;
