import React from 'react'
import { selectDraftState,resetEditDraft } from '../../../redux/Slices/DraftAndEditLoan';
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { resetGetAllLoanData } from '../../../redux/Slices/Loan';

function DraftEditModal(props) {
    const dispatch = useDispatch()
    const draftState = useSelector(selectDraftState)
    const accessToken = props.accessToken
    const setUploadError = props.setUploadError
    const setShowForm = props.setShowForm


    let content = null;
 
    if(draftState.editDraftStatus===PostDataStatus.FETCHING){
 

        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(draftState.editDraftStatus===PostDataStatus.SUCCESS){
      
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Form Saved as Draft</p>
            <div className='centerButton'>   
                <section className='proceed' onClick={()=>{props.closeModal();setShowForm(false);dispatch(resetGetAllLoanData())}}>Done!</section>
            </div> 
        </div>
    }

    if(draftState.editDraftStatus===PostDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{draftState.errorEditDraft}</p>

            <div className='centerButton'>   
                <section className='proceed' onClick={()=>{props.closeModal();dispatch(resetEditDraft())}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default DraftEditModal