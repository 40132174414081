import { Controller, FormProvider, useForm } from "react-hook-form";

import { components } from "react-select";

import { Label, Select, TextField } from "../form";
import styles from "./index.module.css";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66109 7.58042C7.06093 8.3142 5.93907 8.3142 5.33891 7.58042L1.1424 2.44967C0.341306 1.47023 1.03816 0 2.30349 0H10.6965C11.9618 0 12.6587 1.47023 11.8576 2.44967L7.66109 7.58042Z"
          fill="#407BFF"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const FilterForm = ({ showFilter }) => {
  const methods = useForm({
    mode: "onChange",
  });

  return (
    <div>
      <div className={styles.filterHeader}>
        <span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4806_101358)">
              <path
                d="M0.638672 3.46811L0.638672 3.46792L0.638672 2.72569C0.638672 2.54152 0.711836 2.36488 0.84207 2.23465C0.972303 2.10441 1.14894 2.03125 1.33312 2.03125H14.6664C14.8506 2.03125 15.0273 2.10441 15.1575 2.23465C15.2877 2.36488 15.3609 2.54152 15.3609 2.72569V3.47993C15.3623 3.61987 15.3358 3.75869 15.2829 3.88829C15.2299 4.01824 15.1515 4.13631 15.0523 4.23557C15.0523 4.23565 15.0522 4.23573 15.0521 4.2358M0.638672 3.46811L14.8753 4.05903L15.0521 4.2358M0.638672 3.46811C0.638781 3.60607 0.666192 3.74265 0.719324 3.86996C0.772253 3.99679 0.849665 4.11194 0.947128 4.20882M0.638672 3.46811L1.12423 4.03236M15.0521 4.2358L10.0276 9.2735M15.0521 4.2358L10.0276 9.2735M10.0276 9.2735V15.1701V15.5377L9.68572 15.4027L8.79683 15.0515L8.63867 14.9891V14.819V8.94792V8.9397L8.63881 8.9397C8.64188 8.84633 8.66375 8.75454 8.70313 8.66982C8.74148 8.5873 8.79567 8.51314 8.8626 8.45154L13.8897 3.42014H2.11903L7.15871 8.45551L7.1595 8.45631C7.22386 8.5212 7.27478 8.59815 7.30934 8.68276C7.3438 8.76711 7.36131 8.85742 7.36089 8.94853M10.0276 9.2735L7.11089 8.94792M7.36089 8.94853C7.36089 8.94881 7.36089 8.94909 7.36089 8.94936L7.11089 8.94792M7.36089 8.94853V8.94792H7.11089M7.36089 8.94853V14.0768V14.4392L7.02209 14.3105L6.1332 13.9727L5.972 13.9115V13.739V9.30817M7.11089 8.94792V13.8094L7.1997 13.8431L7.11089 14.0768H6.86089V13.9818L6.222 13.739V9.20569M5.972 9.30817L6.04393 9.38117L6.222 9.20569M5.972 9.30817V9.20569H6.222M5.972 9.30817L0.947128 4.20882M6.222 9.20569L1.12423 4.03236M9.77756 15.1701L9.52756 15.0714V15.1701H9.77756ZM9.77756 15.1701V14.9013L9.8694 14.9376L9.77756 15.1701ZM1.12423 4.03236L0.94825 4.20993C0.947876 4.20956 0.947501 4.20919 0.947128 4.20882M1.12423 4.03236L0.946155 4.20783L0.947128 4.20882M1.77756 3.43236H2.02756V3.42014L1.77756 3.43236ZM1.77756 3.42014V3.17014H2.02756L1.77756 3.42014Z"
                fill="#407BFF"
                stroke="#407BFF"
                stroke-width="0.5"
              />
            </g>
            <defs>
              <clipPath id="clip0_4806_101358">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
        <span>Filter</span>
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className={styles.formInput}>
            <Label className={styles.inputLabel}>Name</Label>
            <TextField
              placeholder="Name"
              required={true}
              name="Name"
              inputIsFullWidth={true}
              inputContainer={styles.inputContainer}
            />
          </div>

          <div className={styles.formInput}>
            <Label className={styles.inputLabel}>Transaction Date</Label>
            <TextField
              placeholder="Select date"
              required={true}
              name="Name"
              inputContainer={styles.inputContainer}
              inputIsFullWidth={true}
            />
          </div>

          <div className={styles.formInput}>
            <Controller
              name="paramter"
              render={({ field: { value, onChange } }) => (
                <>
                  <Label className={styles.inputLabel}>Type</Label>
                  <Select
                    className={styles.selectContainer}
                    options={[
                      {
                        label: "Card",
                        value: "Card",
                      },
                      {
                        label: "Bank",
                        value: "Bank",
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                    components={{ DropdownIndicator }}
                    controlStyle={(provided, state) => ({
                      borderColor: state.isFocused
                        ? "#407BFF"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #407BFF"
                        : provided.boxShadow,
                      border: "1px solid #407BFF",
                      "&:hover": {
                        border: "1px solid #407BFF",
                      },
                    })}
                  />
                </>
              )}
            />
          </div>
          <div className={styles.formInput}>
            <Controller
              name="action"
              render={({ field: { value, onChange } }) => (
                <>
                  <Label className={styles.inputLabel}>Status</Label>
                  <Select
                    className={styles.selectContainer}
                    options={[
                      {
                        label: "Allowed",
                        value: "Allowed",
                      },
                      {
                        label: "Reviewed",
                        value: "Reviewed",
                      },
                      {
                        label: "Blocked",
                        value: "Blocked",
                      },
                      {
                        label: "Pending",
                        value: "Pending",
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                    components={{ DropdownIndicator }}
                    controlStyle={(provided, state) => ({
                      borderColor: state.isFocused
                        ? "#407BFF"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #407BFF"
                        : provided.boxShadow,
                      border: "1px solid #407BFF",
                      "&:hover": {
                        border: "1px solid #407BFF",
                      },
                    })}
                  />
                </>
              )}
            />
          </div>

          <div className={styles.btnWrapper}>
            <button className={styles.contained}>Apply Filter</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
