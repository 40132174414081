import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../../../library/Variables";




  export const postCommercialCredit = createAsyncThunk('crbGhana/pCommercialCredit', async (params) => {
    
    const data = JSON.stringify(params.data)
    const pCommercialCreditResponse = await client.post('/statements/create-commercial-credit',data,
        {
            "headers":
              {
                "Authorization": "Bearer " + params.accessToken,
                'Content-Type': 'application/json'
              }
          });
    
        return pCommercialCreditResponse;
    }
    );



  export const postConsumerCredit = createAsyncThunk('crbGhana/pConsumerCredit', async (params) => {
    const data = JSON.stringify(params.data)
   const pConsumerCreditResponse = await client.post('/statements/create-consumer-credit',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });
  
    return pConsumerCreditResponse;
   }
  );


  export const getConsumerCredit = createAsyncThunk('crbGhana/gConsumerCredit',async(params)=>{
    const gConsumertData = await client.get(`/statements/get-consumer-credit/${params.ref}`,{
      "headers":{
        "Authorization": "Bearer " + params.accessToken,
      }
    });
    return gConsumertData.data;
  })


  export const getCommercialCredit = createAsyncThunk('crbGhana/gCommercialCredit',async(params)=>{
    const gCommercialData = await client.get(`/statements/get-commercial-credit/${params.ref}`,{
      "headers":{
        "Authorization": "Bearer " + params.accessToken,
      }
    });
    return gCommercialData.data;
  })




export const crbGhanaDataSlice = createSlice({
  name: 'crbGhanaData',
  initialState: {
    commercialCreditPost: null,
    commercialCreditPostStatus: PostDataStatus.INITIAL,
    commercialCreditPostisError: false,
    commercialCreditPostErrorMessage:null,


    consumerCreditPost: null,
    consumerCreditPostStatus: PostDataStatus.INITIAL,
    consumerCreditPostisError: false,
    consumerCreditPostErrorMessage:null,


    consumerCreditGet: null,
    consumerCreditGetStatus: FetchDataStatus.INITIAL,
    consumerCreditGetisError: false,
    consumerCreditGetErrorMessage:null,

    
    commercialCreditGet: null,
    commercialCreditGetStatus: FetchDataStatus.INITIAL,
    commercialCreditGetisError: false,
    commercialCreditGetErrorMessage:null,

  },
  reducers: {
  
    resetCommercialCreditPost: (state)=>{
      state.commercialCreditPost = null;
      state.commercialCreditPostStatus = PostDataStatus.INITIAL;
      state.commercialCreditPostisError = false;
      state.commercialCreditPostErrorMessage = null;
    },


    resetConsumerCreditPost: (state)=>{
        state.consumerCreditPost = null;
        state.consumerCreditPostStatus = PostDataStatus.INITIAL;
        state.consumerCreditPostisError = false;
        state.consumerCreditPostErrorMessage = null;
    },


    resetConsumerCreditGet: (state)=>{
      state.consumerCreditGet = null;
      state.consumerCreditGetStatus = FetchDataStatus.INITIAL;
      state.consumerCreditGetisError = false;
      state.consumerCreditGetErrorMessage = null;
  },

  resetCommercialCreditGet: (state)=>{
    state.commercialCreditGet = null;
    state.commercialCreditGetStatus = FetchDataStatus.INITIAL;
    state.commercialCreditGetisError = false;
    state.commercialCreditGetErrorMessage = null;
}
  },
  
  extraReducers(builder) {

  builder.addCase(postCommercialCredit.pending, (state)=>{
    state.commercialCreditPostStatus = PostDataStatus.FETCHING
  })
  .addCase(postCommercialCredit.fulfilled,(state,action)=>{
    state.commercialCreditPostStatus = PostDataStatus.SUCCESS
    state.commercialCreditPost = action.payload
  })
  .addCase(postCommercialCredit.rejected,(state,action)=>{
    state.commercialCreditPostStatus = PostDataStatus.FAILURE
    state.commercialCreditPostErrorMessage = action.error.message
    state.commercialCreditPostisError = true
  })



  .addCase(postConsumerCredit.pending, (state)=>{
    state.consumerCreditPostStatus = PostDataStatus.FETCHING
  })
  .addCase(postConsumerCredit.fulfilled,(state,action)=>{
    state.consumerCreditPostStatus = PostDataStatus.SUCCESS
    state.commercialCreditPost = action.payload
  })
  .addCase(postConsumerCredit.rejected,(state,action)=>{
    state.consumerCreditPostStatus = PostDataStatus.FAILURE
    state.consumerCreditPostErrorMessage = action.error.message
    state.consumerCreditPostisError = true
  })



  .addCase(getConsumerCredit.pending, (state)=>{
    state.consumerCreditGetStatus = PostDataStatus.FETCHING
  })
  .addCase(getConsumerCredit.fulfilled,(state,action)=>{
    state.consumerCreditGetStatus = PostDataStatus.SUCCESS
    state.consumerCreditGet = action.payload
  })
  .addCase(getConsumerCredit.rejected,(state,action)=>{
    state.consumerCreditGetStatus = PostDataStatus.FAILURE
    state.consumerCreditGetErrorMessage = action.error.message
    state.consumerCreditGetisError = true
  })


  .addCase(getCommercialCredit.pending, (state)=>{
    state.commercialCreditGetStatus = PostDataStatus.FETCHING
  })
  .addCase(getCommercialCredit.fulfilled,(state,action)=>{
    state.commercialCreditGetStatus = PostDataStatus.SUCCESS
    state.commercialCreditGet = action.payload
  })
  .addCase(getCommercialCredit.rejected,(state,action)=>{
    state.commercialCreditGetStatus = PostDataStatus.FAILURE
    state.commercialCreditGetErrorMessage = action.error.message
    state.commercialCreditGetisError = true
  })


  
  }
})


export const { resetConsumerCreditPost,
               resetConsumerCreditGet,
               resetCommercialCreditPost,
               resetCommercialCreditGet} = crbGhanaDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export const selectCrbGhanaData  = state => state.crbGhanaData


export default crbGhanaDataSlice.reducer;