import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from 'react';
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import {
  selectMobileInsightState,
  getMobileInsightDetails,
  getMobileBanks,
  getMobileLenders
} from '../../redux/Slices//MobileInsight';
import Spinner from '../../svg/Spinner/Spinner';
import Utils from '../../library/Utils';
import Tabs from '../../components/Tabs/Tabs';
import Overview from './Overview';
import Accounts from './Accounts';
import './MobileInsight.css';
import { FetchDataStatus } from "../../library/Variables";

function MobileInsight(props) {  
  let tabBodiesArray = [];
  let overviewContent = null;
  let accountContent = null;

  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const { key } = useParams();
  const accessToken = props.accessToken;
  const insight = useSelector(selectMobileInsightState);
  const configuration = props.configuration;

  localStorage.setItem('insightsLastVisited', `/mobileinsight/${key}`);

  const params = 
  { 
    accessToken: accessToken,
    key: key
  };

  let keyFromRoute = window.location.href.split('/');
  keyFromRoute = keyFromRoute[keyFromRoute.length-1];
  params.keyFromRoute = keyFromRoute;
  
  /* Prepare Top Tabs */
  if(!Utils.isFieldEmpty(insight.activeInsight)) {
    overviewContent = <Overview overview={insight.activeInsight} configuration={configuration} />

    tabBodiesArray.push(
      {
        title: 'Overview',
        content: overviewContent
      }
    )
  }

  if(!(Utils.isFieldEmpty(insight.banks)) && (!Utils.isFieldEmpty(insight.lenders))) {
    accountContent = <Accounts accounts={{ banks: insight.banks, lenders: insight.lenders }} configuration={configuration} accessToken={accessToken}
    insightName={insight.activeInsight.insightName} lastUpdated={insight.activeInsight.lastUpdated} />
      
    tabBodiesArray.push(
      {
        title: 'Accounts',
        content: accountContent
      }
    )
  }

  useEffect(() => {
    if((Utils.isFieldEmpty(insight)) || insight.mobileInsightStatus === FetchDataStatus.INITIAL) {
      dispatch(getMobileInsightDetails(params));
    }

    if((Utils.isFieldEmpty(insight.banks)) || insight.banksStatus === FetchDataStatus.INITIAL) {
      dispatch(getMobileBanks(params));
    }

    if((Utils.isFieldEmpty(insight.lenders)) || insight.lendersStatus === FetchDataStatus.INITIAL) {
      dispatch(getMobileLenders(params));
    }

    if((insight.mobileInsightStatus === FetchDataStatus.FAILURE) || 
      (insight.banksStatus === FetchDataStatus.FAILURE) || 
      (insight.lendersStatus === FetchDataStatus.FAILURE)) {
      navigate('/error');
    }
  }, [dispatch, insight])

  if(Utils.isFieldEmpty(insight) || insight.isLoading === true) {
    return <div className="st-details-body-spinner" >
      <Spinner />
    </div>
  } else if(insight.isLoading === false) {
    return (
      <div className="mb-details-body">
      <div className="mb-details-main-header">
        <h2 className="detail-header">{insight.activeInsight.name}</h2>
        <div className='mb-details-main-header-sub'>
          <img src='/assets/icons/calendar-color.svg' alt='' />
          <label>Last updated:</label>
          <p>{Utils.formatLongDate(insight.activeInsight.updatedTime)}</p>
        </div>
      </div>
      <div className='mb-insight-tabs-container'>
        {Utils.isFieldEmpty(insight.banks.banks) && Utils.isFieldEmpty(insight.lenders.lenders)
          ? overviewContent
          : <Tabs tabBodies={tabBodiesArray} extraClass='top-level-tabs'/>
        }
      </div>
    </div>
    )
  }
}

export default MobileInsight;