import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { resetKycData,selectKycEntireDataState,getVinDataAsync,resetVinData } from '../../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../../library/Variables'
import { getReportDetail,resetReport} from '../../../../../redux/Slices/KycOptions'
import errorImage from '../../../../../assests/icons/errorImage.png'
import successImg from '../../../../../assests/icons/amlFound.png'
import './vinModal.css'


function VinModal({setVerificationType,accessToken,keyFromRoute,
    setViewReport,setKycToShow,inputStyle,customStyles}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm();
  const onSubmit = (formData) => {
    const obj = {data:{number:formData.number,
                    dob:formData.dob,
                    first_name:formData.first_name,
                    last_name:formData.last_name,
                    lga:formData.lga,
                    state:formData.state},
                accessToken}
    dispatch(getVinDataAsync(obj))
  }

  const getDetails = (token,uuid,type)=>{
    setIsOpen(false);
    setKycToShow(null);
    dispatch(resetVinData());
    dispatch(resetKycData());

    if(!['CAC','TIN'].includes(type)){
      setVerificationType(type);
      setViewReport(true)
      dispatch(resetReport())
      dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
  
}


  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div style={{border:"15px", height:"auto",padding:"15px"}}>
       <p className='title-class'>PVC/VIN</p>
       <div className="fieldHolder">
           <p>First Name</p>
           <input  style={inputStyle}{...register("first_name", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>

       <div className="fieldHolder">
           <p>Last Name</p>
           <input  style={inputStyle}{...register("last_name", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>

       <div className="fieldHolder">
           <p>Voters Card Number</p>
           <input  style={inputStyle}{...register("number", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <div className="fieldHolder">
           <p>Date of Birth</p>
           <input  style={inputStyle}{...register("dob", { required: true })} type='date' />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <div className="fieldHolder">
           <p>LGA</p>
           <input  style={inputStyle}{...register("lga", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <div className="fieldHolder">
           <p>State</p>
           <input  style={inputStyle}{...register("state", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKycToShow(null);
                                                  dispatch(resetVinData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

const ModalSuccess = 
    <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
        </div>
        <p style={{textAlign:'center',
                color:"#2A38A4",
                fontSize:"24px",
                fontWeight:"700"}}>
                VIN Extraction Successfull
        </p>
        <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
            Click Done to see
        </p>
        <div className='modal-initial-button-hold'>
            <span className='check' onClick={()=>getDetails(accessToken,kycState?.vinExtraction?.uuid,
                                                            kycState.vinExtraction?.type)}>
                                    Done
            </span>
            
        </div>
    </div>


  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.vinErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                            setKycToShow(null);
                                            dispatch(resetVinData())}}>Cancel</span>
           
       </div>
  </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.vinStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.vinStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.vinStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess)  
        }

        if(kycState.vinStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default VinModal