import React from 'react'
import calendar from '../../../assests/icons/Calendar.png'
import profile from '../../../assests/icons/Profile.png'
import Speedometer from '../../../svg/Speedometer/Speedometer'
import List from '../../List/List'

function StepFive({setTourSection,setEngineTour}) {

    const pop = <div style={{right:'5%',top:"120px",position:'fixed',zIndex:"10",height:"150px",width:'300px'}}>
    <div style={{width:"20px",height:"20px",transform:"rotate(45deg)",position:'absolute',bottom:"-18px",left:"20px",border:"1px solid lightblue",zIndex:"4"}}></div>
    <div style={{width:"19px",height:"19px",transform:"rotate(45deg)",position:'absolute',bottom:"-17px",left:"21px",backgroundColor:"white",zIndex:"14"}}></div>
    <div style={{height:'100%',padding:'5px',zIndex:'10',borderRadius:'7px',border:"1px solid rgba(195, 212, 255, 1)",position:"absolute",top:'0px',left:"0px",right:'0px',backgroundColor:"white",display:'flex',flexDirection:"column",justifyContent:"space-between"}}>
    <div style={{height:"60%",boxSizing:"border-box",display:"flex",flexDirection:"column",justifyContent:'space-around'}}>
      <p><strong></strong> You can view credit score based on parameters filled.</p>
    </div>

    <div style={{border:"0.1px solid lightblue",display:"flex",justifyContent:"space-around",alignItems:"center",boxSizing:"border-box",borderWidth:'0.1px 0px 0px 0px',height:"40%"}}>
        <span onClick={()=>setTourSection('stepFour')} style={{width:"70px",cursor:'pointer',padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"white",color:'#407BFF',border:"1px solid #407BFF"}}>Back</span>
        <span><strong>4 of 4</strong></span>
        <span onClick={()=>{setTourSection('stepOne')}} style={{width:"70px",cursor:'pointer',padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"#407BFF",color:'white'}}>Cancel</span>
    </div>
    </div>
  </div>

const proceed = 
<>
    <div style={{width:'90%',margin:"0px auto",}}>
        <div style={{display:"flex",columnGap:'10px'}}>
           <span><img src={profile}/></span>
           <span>Customer ID:</span>
           <span><strong>New Guy</strong></span>
        </div> 

        <div style={{display:"flex",columnGap:'10px'}}>
           <span><img src={calendar}/></span>
           <span>Date Generated:</span>
           <span><strong>April 11th 2023</strong></span>
        </div> 
    
    </div>
</>

const speedometerProp = {one:415,two:295,three:900,four:'POOR',five:'D',six:false}

const basePoint = {one:295,
                  two:[
                    {header:'Age',detail:'35',sublist:{header:"less than 22",detail:"0",textColor:"#f2d366"}},
                    {header:'Employment',detail:'Self-Employed',sublist:{header:"equals Self Employed",detail:"+40",textColor:"#04b942"}},
                    {header:'Sum of salary and rent',detail:'2000003',sublist:{header:"greater than 5",detail:"+ 20",textColor:"#04b942"}},
                    {header:'Years of employment',detail:'5',sublist:{header:"between 1 to 5",detail:"+ 50",textColor:"#04b942"}}],
                  three:405}

  const currentScoreContent = 
  <div className="cs-current-speedometer-container">
    <Speedometer obtainedPoints={speedometerProp.one} minScore={speedometerProp.two} maxScore={speedometerProp.three} 
      passingSentiment={speedometerProp.four} customGrade={speedometerProp.five} checkCustomGrade={speedometerProp.six} />
</div> 

const currentBreakdownContent = <>

<>
  <div className="cs-base-points-container detail-header">
    <h3>Base Points</h3>
    <p>{basePoint.one}</p>
  </div>
  <hr className="separator-blue" />
</>

<div className="cs-breakdown-scroll-container scrollbar">
  <List listContent={basePoint.two} lineColor={true} />
</div>
<hr className="separator-blue" />
<div className="cs-breakdown-total-container detail-header">
  <h4>Total</h4>
  <p>
    {basePoint.three}
  </p>
</div>
</>
  return (
    <div>
    <div style={{padding:'25px 25px'}}>
        {proceed}
    </div> 
    
    <div style={{width:'90%',margin:"10px auto",padding:'5px',display:'flex',flexWrap:'wrap',height:"150px",position:'relative'}}>
   
    <div className="cs-main-body">
        <div className="cs-current-container">

            <div className="cs-current-score">
              <div className="cs-current-header">
                <div className="cs-current-header-text">
                  <h3 className="detail-header">Current Score</h3>
                  
                    <p className="text-medium">Scorecard used:
                      <span className="text-dark">ScoreCard 1</span>
                    </p>
                  
                  <p className="text-medium">Last updated:
                    <span className="text-dark">September 24th 2023</span>
                  </p>
                </div>
              </div>
              {currentScoreContent}
            </div>
          


         
            <div className="cs-current-breakdown">
              { currentBreakdownContent }
            </div>
          
        </div>
      </div>
         
    </div>
    {pop}

    
</div>
  )
}

export default StepFive