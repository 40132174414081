import React from 'react'
import { selectDraftState,resetSubmitDraft} from '../../../redux/Slices/DraftAndEditLoan';
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { resetGetAllLoanData,resetLoanSummary,resetLoanFilter } from '../../../redux/Slices/Loan';
import { useNavigate } from 'react-router';

function DraftSubmitModal(props) {
    const dispatch = useDispatch()
    const draftState = useSelector(selectDraftState)
    const accessToken = props.accessToken
    const uploadError = props.uploadError
    const imgFile = props.imgFile
    const setUploadError = props.setUploadError
    const setShowForm = props.setShowForm
    const navigate = useNavigate()

    const successDone = ()=>{

        props.closeModal();setShowForm(false);
        navigate('/loanApproval');dispatch(resetGetAllLoanData())
        dispatch(resetLoanSummary())
        dispatch(resetLoanFilter())
       
    }


    let content = null;
    const params = {
        files:props.imgFile,
        accessToken,
    }

  

 

    if(draftState.savedDraftDataStatus===PostDataStatus.FETCHING){
 

        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(draftState.savedDraftDataStatus===PostDataStatus.SUCCESS){
      
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Form Succesfully Submitted</p>
            <div className='centerButton'>   
                <section className='proceed' onClick={()=>successDone()}>Done!</section>
            </div> 
        </div>
    }

    if(draftState.savedDraftDataStatus===PostDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{draftState.errorSavedDraftData}</p>

            <div className='centerButton'>   
                <section className='proceed'  onClick={()=>{props.closeModal();setShowForm(false);dispatch(resetSubmitDraft())}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default DraftSubmitModal