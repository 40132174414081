import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";


export const uploadSignature = createAsyncThunk('loan/signature', async (params) => {
    let formData = new FormData()

    if (Array.isArray(params.files)) {
      params.files.forEach((img, index) =>formData.append(`files`,img))
  } else {
    formData.append(`files`,params.files)
  }

          const signatureData = await client.post('/loan/upload-signature', formData, 
        {
          "headers":
            {
              "Authorization": "Bearer " + params.accessToken
            }
        });
        return signatureData;
  });


  export const uploadVerificationSignature = createAsyncThunk('loan/verificationSignature', async (params) => {
    let formData = new FormData()

    if (Array.isArray(params.files)) {
      params.files.forEach((img, index) =>formData.append(`files`,img))
  } else {
    formData.append(`files`,params.files)
  }

          const signatureData = await client.post('/loan/upload', formData, 
        {
          "headers":
            {
              "Authorization": "Bearer " + params.accessToken
            }
        });
        return signatureData;
  });




  export const getSignature = createAsyncThunk('loan/getSignature',async (params) => {
  
  
    const data = JSON.stringify(params.data)
    const signature = await client.post('/loan/view-file', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return signature;
  })


  export const deleteImage = createAsyncThunk('loan/deleteSignature',async(params)=>{
    const data = JSON.stringify(params.data)

    const response = await client.post('/loan/deletefile',data,
    {
      "headers":
      {
        "Authorization" : "Bearer " + params.accessToken,
        "Content-Type" : 'application/json'
      }
    });
    return response
  })

  export const signatureSlice = createSlice({
    name: 'loanSignature',
    initialState: {
      signature:null,
      signatureStatus: PostDataStatus.INITIAL,
      isErrorSignature: false,
      errorMessageSignature:null,


      signatureBase64:null,
      getSignatureStatus:PostDataStatus.INITIAL,
      isErrorgetSignature:false,
      errorGetSignature:null,

      deleteImageStatus : PostDataStatus.INITIAL,
      deleteSignatureData : null,
      isErrordeleteImage: false,
      errordeleteImage : null,


      verificationSignature:null,
      verificationSignatureStatus: PostDataStatus.INITIAL,
      isverificationSignatureError: false,
      errorMessageVerificationSignature:null,
    },

    reducers: {
      
        resetSignature:state => {
          state.signature = null;
          state.signatureStatus = PostDataStatus.INITIAL;
          state.isErrorSignature = false;
          state.errorMessageSignature = null
        },

        resetGottenSignature:state =>{
          state.getSignatureStatus = PostDataStatus.INITIAL;
          state.signatureBase64 = null;
          state.isErrorgetSignature = false;
          state.errorGetSignature = null
          
        },

        resetDeleteImg: state =>{
          state.deleteImageStatus  = PostDataStatus.INITIAL;
          state.deleteSignatureData = null;
          state.isErrordeleteImage = false;
          state.errordeleteImage = null
        },

        resetVerificationSig: state=>{
          state.verificationSignature =null;
          state.verificationSignatureStatus = PostDataStatus.INITIAL;
          state.isverificationSignatureError = false;
          state.errorMessageVerificationSignature =null;
        }
    
    },
    extraReducers(builder) {
        builder.addCase(uploadSignature.pending, (state) => {
            state.signatureStatus = PostDataStatus.FETCHING;
          })
          .addCase(uploadSignature.fulfilled, (state, action) => {
            state.signature = action.payload;
            state.signatureStatus = PostDataStatus.SUCCESS;
            
          })
          .addCase(uploadSignature.rejected, (state, action) => {
            state.signatureStatus = PostDataStatus.FAILURE;
            state.isErrorSignature = true;
            state.errorMessageSignature = action.error.message;
          })
          .addCase(getSignature.pending, (state) => {
            state.getSignatureStatus = PostDataStatus.FETCHING;
          })
          .addCase(getSignature.fulfilled, (state, action) => {
            state.signatureBase64 = action.payload;
            state.getSignatureStatus = PostDataStatus.SUCCESS;
            
          })
          .addCase(getSignature.rejected, (state, action) => {
            state.getSignatureStatus = PostDataStatus.FAILURE;
            state.isErrorgetSignature = true;
            state.errorGetSignature = action.error.message;
       
          })

          .addCase(deleteImage.pending, (state) => {
            state.deleteImageStatus = PostDataStatus.FETCHING;
          })
          .addCase(deleteImage.fulfilled, (state, action) => {
            state.deleteSignatureData = action.payload;
            state.deleteImageStatus = PostDataStatus.SUCCESS;
            
          })
          .addCase(deleteImage.rejected, (state, action) => {
            state.deleteImageStatus = PostDataStatus.FAILURE;
            state.isErrordeleteImage =  true;
            state.errordeleteImage = action.error.message;
          })

          .addCase(uploadVerificationSignature.pending, (state) => {
            state.verificationSignatureStatus = PostDataStatus.FETCHING;
          })
          .addCase(uploadVerificationSignature.fulfilled, (state, action) => {
            state.verificationSignature = action.payload;
            state.verificationSignatureStatus = PostDataStatus.SUCCESS;
            
          })
          .addCase(uploadVerificationSignature.rejected, (state, action) => {
            state.verificationSignatureStatus = PostDataStatus.FAILURE;
            state.isverificationSignatureError = true;
            state.errorMessageVerificationSignature = action.error.message;
          })
          
    }
})


export const { resetSignature,resetGottenSignature,resetDeleteImg,resetVerificationSig} = signatureSlice.actions;

export const selectSignatureState = state => state.loanSignature
export default signatureSlice.reducer;
  
  