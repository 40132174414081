import React from "react";
import Utils from "../../library/Utils";
import DetailsCard from "../../components/DetailsCard/DetailsCard";

function Overview(props) {
  const overview = props.overview;
  const configuration = props.configuration;

  return (
    <>
      <div className='cards mb-insight-details'>
        <div className='mb-details-subheader-item' >
          <h4>DEVICE NAME</h4>
          <p>{Utils.checkNull(overview.deviceName).toUpperCase()}</p>
        </div>
        <div className='mb-details-subheader-item'>
          <h4>BRAND</h4>
          <p>{Utils.checkNull(overview.brand).toUpperCase()}</p>
        </div>
        <div className='mb-details-subheader-item'>
          <h4>NETWORK CARRIER:</h4>
          <p>{Utils.checkNull(overview.networkCarrier).toUpperCase()}</p>
        </div>
        <div className='mb-details-subheader-item'>
          <h4>FIRST INSTALLED TIME:</h4>
          <p>{Utils.formatLongDate(Utils.checkNull(overview.firstInstalledTime))}</p>
        </div>
      </div>
      <div className='mb-insight-balance-row'>
        <DetailsCard title="Total Closing Balance" titleClass="detail-header" subtitleClass="text-medium"
          subtitle={'This is the total closing balance of all bank accounts'} detailContent={<h2 className="detail-header">
            {Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, overview.totalClosingBalance))}
          </h2>} />
        <DetailsCard title="Total Number of Transactions" titleClass="detail-header" subtitleClass="text-medium"
          subtitle={'This is the total number of transactions across bank accounts'} detailContent={<h2 className="detail-header">
            {Utils.checkNull(overview.totalNumberOfTransactions)}
          </h2>} />
      </div>
    </>
  )
}

export default Overview;