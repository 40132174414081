import { useState } from "react";

import { useLocation, useNavigate } from "react-router";
import styles from "./index.module.css";

export const CustomTabs = ({
  items,
  defaultActiveKey,
  headerClass,
  containerClass,
  headerItemClass,
  headerActiveClass,
  name,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(defaultActiveKey);
  const selectedTab = items.find((item) => item.key === selected);

  const contStr = `${styles.container} ${containerClass}`
    .split(" ")
    .filter(Boolean)
    .join(" ");
  const headerStr = `${styles.header} ${headerClass}`
    .split(" ")
    .filter(Boolean)
    .join(" ");
  const headerItemStr = `${styles.headerItem} ${headerItemClass}`
    .split(" ")
    .filter(Boolean)
    .join(" ");
  const headerActiveStr = `${styles.headerActive} ${headerActiveClass}`
    .split(" ")
    .filter(Boolean)
    .join(" ");

  const tabHandler = (value) => {
    const queryParam = new URLSearchParams(location.search);
    const pathname = location.pathname;
    queryParam.set(name, value);
    setSelected(value);

    if (name) {
      navigate(`${pathname}?${queryParam.toString()}`, { replace: true });
    }
  };
  return (
    <div className={contStr}>
      <div className={styles.headerContainer}>
        <div className={headerStr}>
          {items.map((item) => (
            <div
              onClick={() => tabHandler(item.key)}
              key={item.key}
              className={`${headerItemStr} ${
                selected === item.key && headerActiveStr
              }`}
            >
              {item?.label}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.body}>{selectedTab?.children}</div>
    </div>
  );
};
