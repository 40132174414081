import React from 'react'

function ScoreDefinitionSuccess({cardState,setToggleState,selectedIcon,unselectedIcon,setSection,closeModal}) {


  return (
    
        <div style={{marginTop:"20px"}}> 
                <section style={{rowGap:"20px",padding:"20px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center'
                        ,backgroundColor:"white",marginTop:"20px"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{cardState?.name}</p>
                    
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Base Score: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{cardState?.baseScore? cardState.baseScore:'nil'}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Score-card Type: &nbsp; &nbsp;</span>
                            <div style={{display:'flex',columnGap:"20px"}}>
                                <section className='selectToggle'>
                                    <span><img src={selectedIcon}/></span>
                                    <span className='toggletext'>{cardState?.type==='RULES'?'Decision-based':'Point-based'}</span>
                                </section>
                                    
                            </div>
                                
                        </div>



                </section>

        
            </div>

  )
}

export default ScoreDefinitionSuccess