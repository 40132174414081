import React, { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { downloadParamTemplate } from '../../../redux/Slices/SelfServiceDecision'

function UploadParamSection({closepop,activeStyle,acceptStyle,rejectStyle,baseStyle,accessToken,setFilesRejected,setFilesSelected,filesRejected,acceptedFileItems,filesSelected,removeFile,submitFileForProcessing}) {
  
  const dispatch = useDispatch()
    

  const onDrop = useCallback(acceptedFiles => {
    const fileItem = {
      pdf: acceptedFiles,
      categoryId: "",
      name: '',
      statementType:''
      // accessToken: accessToken,
      // uniqueIdentifier: userId,
      // baseStatementKey: statementKey
    }
    setFilesSelected([...filesSelected, fileItem]);
    setFilesRejected([]);
  }, [filesSelected])

  const onDropRejected = useCallback(fileRejections => {
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], },
    filesizeBase: 1000,
    multiple: false,
    onDrop,
    onDropRejected
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  
    return (
    <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload Parameter File</h2>
        <div className="close" onClick={()=>closepop()}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div> 
      </div>
      <div onClick={()=>dispatch(downloadParamTemplate({accessToken}))} style={{width:"100%",cursor:"pointer",color:'#1162DC'}}>Download parameter template</div>
      <div className="modal-body-container scrollbar">
        <div className="scrollable-content">
          <div {...getRootProps({style})} className="drop-container">
            <input {...getInputProps()} />
            <img src="/assets/icons/download-color.svg" alt="" />
            <p className="drop-caption">Drag "n" drop your file here, or <span className="detail-header"  >
              Open File Dialog</span>
            </p>
            <p className="drop-subcaption text-medium">FILE SUPPORTED: CSV</p>
          </div>

          {filesRejected.length > 0 &&
            <span className="ap-file-rejected-error-message error-msg">
              {filesRejected[0].errors[0].code === "file-too-large" 
                ? "File is larger than 10MB"
                : filesRejected[0].errors[0].message}
            </span>
          }
          {
           
          acceptedFileItems.length > 0 ?
            <>
           
              <h4 className="text-medium">SELECTED FILE:</h4>
              {
                filesSelected?.map((file, id) => {
                  return (
                  <div className="ap-accepted-file-container" key={id}>
                      <ul>
                        <li key={file.pdf[0].path}>
                          <div className="ap-accepted-file-info">
                            <img src="/assets/icons/statement-color.svg" alt="" />
                            <div>
                              <h4 className="text-dark">{file.pdf[0].path}</h4>
                              <span className="text-medium">{file.pdf[0].size / 1000000} MB</span>
                            </div> 
                          </div>
                          <button onClick={() => removeFile(file)} className="delete-pdf-button">
                            <img src="/assets/icons/delete-color.svg" alt="Delete" />
                          </button>
                        </li>
                      </ul>
                     

                  </div>
                  )
                })
              }

              <div className="submit-container">
                <button className="btn-submit button-solid" onClick={() => submitFileForProcessing()}>Upload</button>
              </div>
            </>:''
          }
        </div>
      </div>
      </div>
  )
}

export default UploadParamSection