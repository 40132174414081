import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const getParameters = () => clientApi.get("/api/parameters");

export const fetchParametersAsync = createAsyncThunk(
  "fraudDetect/fetchParameters",
  async () => {
    const response = await getParameters();

    return response;
  }
);
