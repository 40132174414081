export const AgentsList = {
    title:'List of Agents',
    data:[
        {ID:'1234C21',
            AgentName:'Emmanuel Job',
            BusinessName:'Starship Ventures',
            Location:'Iyana-Ipaja',
            amount:'122,000',
            holder:'holder'
        },
        {ID:'7834C21',
            AgentName:'Daniel Zag',
            BusinessName:'Zag and Sons',
            Location:'Oshodi',
            amount:'93,000',
            holder:'holder'
        },
        {ID: '3838DJJ',
            AgentName:'Zigi Semiu',
            BusinessName:'Lighta House',
            Location:'Orile-Iganmu',
            amount:'90,000',
            holder:'holder'
        },
        {ID:'8DD9930',
            AgentName:'Ajayi Benard',
            BusinessName:'Soft Lounge',
            Location:'Surulere',
            amount:'90,000',
            holder:'holder'
        },
        {ID:'QQW0092',
            AgentName:'Korede Ismail',
            BusinessName:'Footaball Academy',
            Location:'Okota',
            amount:'85,000',
            holder:'holder'
        },
        {ID:'1234RRT',
            AgentName:'Obinna Nsofor',
            BusinessName:'Auto Card Dealership',
            Location:'Festac',
            amount:'80,000',
            holder:'holder'
        },
        {ID:'9021C21',
            AgentName:'John Joe',
            BusinessName:'Jon Studios',
            Location:'VI',
            amount:'80,000',
            holder:'holder'
        },
        {ID:'7645E21',
            AgentName:'Daniel Stoke',
            BusinessName:'Car Rentals',
            Location:'Apapa',
            amount:'80,000',
            holder:'holder'
        }
    ]
}