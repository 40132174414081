import "../Modal.css";
import "./UserManagement.css";
import Permissions from "../../Permissions/Permissions";
import { FormProvider, useForm } from "react-hook-form";

function ViewUser(props) {
  let role = {};

  const user = props.user;
  const userManagement = props.userManagement;
  const methods = useForm();

  userManagement.userRoles.forEach(element => {
    if(element.roleId === user.roleId) {
      role = element;
    }
  })

  const groups = [
    { 
      title: "General",
      rows: role.generalPermissions
    },
    {
      title: "Manage roles and permissions",
      rows: role.managePermissions
    },
    {
      title: "Settings",
      rows: role.settingsPermissions
    }
  ]
  
  return(
    <div className="modal-dialog user-management-modal">
      <div className="user-management-form-container">
        <FormProvider {...methods}><Permissions groups={groups} type={user.role} isDisabled={true} /></FormProvider>
        <div className="submit-container">
          <button type="button" className="button-square button-outline" onClick={props.close}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default ViewUser;