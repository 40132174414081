import React,{useEffect,useState} from 'react'
import { useForm } from "react-hook-form"
import Utils from '../../../../../library/Utils'
import { useDispatch } from 'react-redux'
import { getAmlDetails } from '../../../../../redux/Slices/IdVerification/Kenya/IdVerificationKenyaAml'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function IndividualScreening({slideState,setSlideState,setAmlModalOpen,accessToken}) {
    const dispatch = useDispatch()
    const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm() 
    const [dateOfBirth,setDateOfBirth] = useState('')

    const fetchDat = ()=>{
        let dat = getValues()
        dat = {...dat,"date of birth":dateOfBirth}

        if(Utils.isFieldEmpty(dat.last_name) || Utils.isFieldEmpty(dat.first_name)){
            toast.warning("Fill all neccessary Fields", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }

          else{
            setAmlModalOpen(true)
            dispatch(getAmlDetails({data:dat,accessToken}))
          }
      }



  return (
     <section>

     <div className='amlScreenHoldIndi'>
                <section className='amlScreenTitle'>AML Individual Screening</section>
                <section className='amlScreenDescript'>Fill up the input fields with the appropriate information</section>

                <div style={{marginTop:'15px'}}>
                    <section className='ScreenInputHold' >
                        <div className='ScreenInputDiv'> 
                            <label>First Name <span className="required">*</span></label>
                            <input className="input-pill-single" placeholder='input first name' autoComplete="off" type='TEXT' 
                            {...register('first_name', 
                                { 
                                required:{
                                    value:true,
                                    message:'First Name is required'}, 
                                
                                })}/>
                            <span style={{ color: "red"}}>{errors?.firstname?.message}</span>
                        </div>

                        <div className='ScreenInputDiv'> 
                        <label>Middle Name </label>
                        <input className="input-pill-single" placeholder='input middle name' autoComplete="off" type='TEXT' 
                        {...register('middle_name')}/>
                        </div>
                    </section>

                    <section className='ScreenInputHold'>
                        <div className='ScreenInputDiv'> 
                            <label>Last Name <span className="required">*</span></label>
                            <input className="input-pill-single" placeholder='input last name' autoComplete="off" type='TEXT' 
                            {...register('last_name', 
                                { 
                                required:{
                                    value:true,
                                    message:'Last Name is required'}, 
                                
                                })}/>
                            <span style={{ color: "red"}}>{errors?.lastname?.message}</span>
                        </div>
                    
                    
                        <div className='ScreenInputDiv'> 
                            <label>Date of Birth</label>
                            <input className="input-pill-single" autoComplete="off" type='date' 
                            onChange={(event)=>setDateOfBirth(event.target.value)} />
                            
                        </div>
                    </section>
                
                    <section style={{display:'flex',flexDirection:"column",rowGap:'6px'}}>
                        <label>Match score</label>
                        <section className='ScreenSlidingInput'>
                            <span>0%</span>
                            <input type='range'onChange={(event)=>setSlideState(event.target.value)} min="0" max="100"/>
                            <span>{Utils.isFieldEmpty(slideState)?'50%':`${slideState}%`}</span>
                        </section>
                        
                    </section>
                </div>
            </div>

            <section style={{width:'100%',display:"flex",justifyContent:"end",marginTop:'30px',cursor:"pointer"}}>
                <section onClick={()=>fetchDat()} className='proceedScreen'>Proceed</section>
            </section>
     </section>
  )
}

export default IndividualScreening