import React,{useState,useEffect} from 'react'
import './index.css'
import { updateReviewAsync } from '../../../../redux/Slices/fraudDetect/reviewSlice/thunks'
import { useDispatch,useSelector } from 'react-redux'
import { updateReviewSelector } from '../../../../redux/Slices/fraudDetect/reviewSlice/selector'
import { FetchDataStatus,PostDataStatus } from '../../../../library/Variables'
import successLogo from '../../../../assests/icons/amlFound.png'
import failureLogo from '../../../../assests/icons/amlEmpty.png'
import Spinner from '../../../../svg/Spinner/Spinner'
import { resetTransactionReview } from '../../../../redux/Slices/fraudDetect/reviewSlice'

function TransactionReview({accessToken,id,setOpenTransactionReview}) {
  const reviewState =  useSelector(updateReviewSelector)
  const dispatch = useDispatch()
  const [reviewType,setReviewType] = useState('intro')
  const [reviewSection, setReviewSection] = useState('')

  const blockTransaction = ()=>{
    dispatch(updateReviewAsync({  
        "transactionId": id,
        "status": "BLOCKED"}))
  }

  const allowTransaction = ()=>{
    dispatch(updateReviewAsync({  
            "transactionId": id,
            "status": "ALLOWED"}))
  }

  const closeModal = ()=>{
    dispatch(resetTransactionReview())
    setOpenTransactionReview(false)
  }

  const reviewSectionIntro = <>
                            <section className='title'>
                                Flagged Transaction Decision
                            </section>

                            <section className='info'>
                                This Flagged Transaction requires your decision. Please choose carefully.
                            </section>

                            <section className='buttonHolder'>
                                <button className='block'
                                        onClick={()=>setReviewType('block')}>
                                    Block
                                </button>

                                <button className='allow'
                                        onClick={()=>setReviewType('allow')}>
                                    Allow
                                </button>
                            </section>
                        </>

    const reviewSectionAllow = <>
                                    <section className='title'>
                                    Are you sure you want to allow this transaction?
                                    </section>

                                    <section className='buttonHolder'>
                                        <button className='cancel'
                                                onClick={()=>setReviewType('intro')} >
                                            Cancel
                                        </button>

                                        <button onClick={()=>allowTransaction()} className='allow'>
                                            Yes, Allow
                                        </button>
                                    </section>
                                </>

    const reviewSectionBlock = <>
                                <section className='title-block'>
                                Are you sure you want to block this transaction?
                                </section>

                                <section className='buttonHolder'>
                                    <button className='cancel'
                                            onClick={()=>setReviewType('intro')} >
                                        Cancel
                                    </button>

                                    <button onClick={()=>blockTransaction()} className='block-solid'>
                                        Yes, Block
                                    </button>
                                </section>
                            </>

    const reviewSectionLoading = <>
                                    <section className='title'>
                                    {reviewType==='block'?'Blocking transaction...':
                                        'Allowing transaction...'}
                                    </section>

                                    <section className='buttonHolder'>
                                        <Spinner/>
                                    </section>
                                </>

    const reviewSectionSuccess = <>
                                    <section className='imgHold'>
                                        <img src={successLogo}/>
                                    </section>
                                    <section className='title'>
                                        {reviewType==='block'?'Transaction was Blocked Successfully.':
                                        'Transaction was Allowed Successfully.'}
                                    </section>

                                    <section className='buttonClose'>
                                        <button onClick={()=>closeModal()} className='done'>
                                            Done
                                        </button>
                                    </section>
                                </>

    const reviewSectionFailed = <>
                                    <section className='imgHold'>
                                        <img src={failureLogo}/>
                                    </section>
                                    <section className='title-block'>
                                        Transaction Review Failed
                                    </section>

                                    <section className='info'>
                                        {reviewState.error}
                                    </section>

                                    <section className='buttonClose'>
                                        <button onClick={()=>closeModal()}  className='close'>
                                            Close
                                        </button>
                                    </section>
                                </>

  useEffect(()=>{
    if(reviewType==='intro' && reviewState.status === 'idle'){       
        setReviewSection(reviewSectionIntro)
    }

    else if(reviewType==='block' && reviewState.status === 'idle'){
        setReviewSection(reviewSectionBlock)
    }

    else if(reviewType==='allow' && reviewState.status === 'idle'){
        setReviewSection(reviewSectionAllow)
    }

    else if(reviewState.status === 'loading'){
        setReviewSection(reviewSectionLoading)
    }

    else if(reviewState.status === 'succeeded'){
        setReviewSection(reviewSectionSuccess)
    }
    else if(reviewState.status === 'failed'){
        setReviewSection(reviewSectionFailed)
    }
  },[reviewType,reviewState.status])


  return (
    <div className='Review-hold'>
        {reviewSection}
    </div>
  )
}

export default TransactionReview