import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { getPhoneDataID,
         resetPhoneNumber,
         selectKycEntireDataState,
         resetDataStatus
 } from '../../../../../../redux/Slices/IdVerificationKenya'

import { PostDataStatus } from '../../../../../../library/Variables'
import './phoneholder.css'
import successImg from '../../../../../../assests/icons/amlFound.png'
import errorImg from '../../../../../../assests/icons/amlEmpty.png'
import { getReportDetail,resetReport } from '../../../../../../redux/Slices/IdVerificationKenya'
import { getReportDetailKyb,resetReportKyb } from '../../../../../../redux/Slices/IdVerificationKenyaKyb'

function PhoneModal({accessToken,keyFromRoute,setKycToShow,inputStyle,setViewReport,customStyles,setVerificationType}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmit = (formData) => {

    const obj = {bankAccount:formData.bankAccount,
        statementKey: null,
        phoneNumber:`254${formData.phoneNumber}`,
        accessToken
        }
       
        dispatch(getPhoneDataID(obj))

  }

  const getDetails = (token,uuid,type)=>{

    setKycToShow(null);
    setIsOpen(false);
    dispatch(resetDataStatus());
    dispatch(resetPhoneNumber())

    if(['CAC','TIN'].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    }
    else if(['IDNUMBER',
            'ALIENIDNUMBER',
            'DRIVER_LICENCE_KENYA',
            'KRAPIN',
            'PLATE_NUMBER',
            'PHONE_NUMBER',
            'ACCOUNT_NUMBER'
             ].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReport())
        dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
}





  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p className='title-class'>Phone Number Verification</p>
       <div style={{}}>
           <p>Input Phone Number</p>
           <section style={{display:"flex",alignItems:"center",columnGap:"7px",height:"50px"}}>
                <section style={{display:"flex",
                              alignItems:"center",
                              height:"40px",
                              fontWeight:"700",
                              fontSize:"16px",
                              marginTop:"10px"
                              }}>
                    254
                </section>
                <input  style={inputStyle}{...register("phoneNumber",{
                        required: "Phone number is required",
                        maxLength: {
                            value: 9,
                            message: "Phone number cannot exceed 7 characters"
                        }
                        })}
                        type='number' />
           </section>
           
           {errors.phoneNumber && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       <span className="error-msg">{errors.chargeAccept?.message}</span>

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                    setKycToShow(null);dispatch(resetPhoneNumber())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div style={{height:"300px"}}>
    <Spinner></Spinner>
    <p style={{textAlign:'center',color:'#2A38A4',fontSize:"24px",fontWeight:"700"}}>Verification in progress...</p>
    <p style={{textAlign:'center',color:"#363C4F",fontSize:"16px",fontWeight:'400'}}>Hold on. This will only take a few seconds</p>
  </div>

 

const ModalError = <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={errorImg}/>
        </div>
        <p style={{textAlign:'center',
                    fontSize:"24px",
                    fontWeight:"700",
                    color:'#E40000'}}>
                    {kycState.PhoneDataErrorMessage}
        </p>


        <div className='modal-initial-button-hold'>
            <span className='failed' onClick={()=>{setIsOpen(false);
                                                   setKycToShow(null);
                                                   dispatch(resetPhoneNumber())}}
                                                   >
                Cancel
            </span>
            
        </div>
        </div>


    const ModalSuccess = <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
        </div>
        <p style={{textAlign:'center',
                color:"#2A38A4",
                fontSize:"24px",
                fontWeight:"700"}}>Phone Number checked successfull</p>
        <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
            Click Done to see
        </p>
        <div className='modal-initial-button-hold'>
            <span className='check' onClick={()=>getDetails(accessToken,kycState?.PhoneDataExtraction?.uuid,
                                                            kycState.PhoneDataExtraction?.type)}>Done</span>
            
        </div>
    </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.PhoneDataStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.PhoneDataStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.PhoneDataStatus===PostDataStatus.SUCCESS){
            
            //props.setKycToShow(null)
            //setIsOpen(false)
            //dispatch(resetKenyaKycData())
            setModal(ModalSuccess)
            
        }

        if(kycState.PhoneDataStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default PhoneModal