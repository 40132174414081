import React from "react";
import Utils from "../../../../library/Utils";
import Tabs from '../../../../components/Tabs/Tabs';
import BureauAccountView from "./BureauAccountView";
import DetailsCard from "../../../../components/DetailsCard/DetailsCard";
import List from "../../../../components/List/List";
import "./BureauReport.css";

function BureauReport(props) {
  const configuration = props.configuration;

  let tabBodiesArray = [];
  let fieldData = props.report;
  let totalNumberOfDeliqFacilitiesArray = [
    Utils.checkNullNumber(fieldData.creditNumberOfDelinqCreditFacilities),
    Utils.checkNullNumber(fieldData.mfCreditNumberOfDelinqCreditFacilities),
    Utils.checkNullNumber(fieldData.mgCreditNumberOfDelinqCreditFacilities)
  ]
  let overallHasCreditFacilitiesArray = [
    Utils.checkNull(fieldData.creditHasCreditFacilities),
    Utils.checkNull(fieldData.mfCreditHasCreditFacilities),
    Utils.checkNull(fieldData.mgcreditHasCreditFacilities)
  ]
  let totalNumberOfDeliqFacilities = totalNumberOfDeliqFacilitiesArray.reduce((a, b) => a + b);
  let overallHasCreditFacilities = null;

  if(overallHasCreditFacilitiesArray.every(element => element === 'N/A') === true) {
    overallHasCreditFacilities = 'N/A'
  } else {
    overallHasCreditFacilities = 'NO';
    
    overallHasCreditFacilitiesArray.forEach(element => {
      if(element === 'YES') {
        overallHasCreditFacilities = 'YES'
      }
    });
  }

  // Prepare tabs
  if(!Utils.isFieldEmpty(fieldData)) {
    let content = <div className="bureau-report-view-col">
      <DetailsCard title="Customer Profile" bodyExtraClass="details-bottom" titleClass="detail-header"
        detailContent={
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
            listContent={[
              { header: 'First Name', detail: Utils.checkNull(fieldData.firstName)},
              { header: 'Last Name', detail: Utils.checkNull(fieldData.lastName)},
              { header: 'Citizenship', detail: Utils.formatRegionNames(Utils.checkNull(fieldData.citizenship))},
              { header: 'Date of Birth', detail: Utils.formatLongDate(fieldData.dateOfBirth)}
            ]} />
        } 
      />
      <DetailsCard title="Report Info" bodyExtraClass="details-bottom" titleClass="detail-header"
        detailContent={
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
            listContent={[
              { header: 'Product Name', detail: Utils.checkNull(fieldData.productName)},
              { header: 'Report Date', detail: Utils.formatLongDate(fieldData.reportDate)},
              { header: 'Has Credit Facilities', detail: Utils.checkNull(overallHasCreditFacilities)},
              { header: 'Total Number of Delinquent Facilities', detail: Utils.checkNull(totalNumberOfDeliqFacilities)}
            ]} />
        }
      />
    </div>

    let creditContent = <div className="bureau-report-view-col">
      <DetailsCard title="Credit" bodyExtraClass="details-bottom" titleClass="detail-header"
        detailContent={
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
            listContent={[
              { header: 'Has Credit Facilities', detail: Utils.checkNull(fieldData.creditHasCreditFacilities)},
              { header: 'Number of Delinquent Credit Facilities', detail: Utils.checkNull(fieldData.creditNumberOfDelinqCreditFacilities)},
              { header: 'Last Reported Date', detail: Utils.formatLongDate(fieldData.creditLastReportedDate)}
            ]} />
        }
      />
      <DetailsCard title="MF Credit" bodyExtraClass="details-bottom" titleClass="detail-header"
        detailContent={ <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
          listContent={[
            { header: 'Has Credit Facilities', detail: Utils.checkNull(fieldData.mfCreditHasCreditFacilities)},
            { header: 'Number of Delinquent Credit Facilities', detail: Utils.checkNull(fieldData.mfCreditNumberOfDelinqCreditFacilities)}
          ]} />
        }
      />
      <DetailsCard title="MG Credit" bodyExtraClass="details-bottom" titleClass="detail-header"
        detailContent={ <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
          listContent={[
            { header: 'Has Credit Facilities', detail: Utils.checkNull(fieldData.mgcreditHasCreditFacilities)},
          { header: 'Number of Delinquent Credit Facilities', detail: Utils.checkNull(fieldData.mgCreditNumberOfDelinqCreditFacilities)}
          ]} />
        }
      />
    </div>

    tabBodiesArray.push({
      title: 'Overall',
      content: content
    });

    tabBodiesArray.push({
      title: 'Credits',
      content: creditContent
    })
  }

  if(!Utils.isFieldEmpty(fieldData.statementBureauAccountData)) {
    let content = fieldData.statementBureauAccountData.map((account, i) => {
      return <BureauAccountView key={i} configuration={configuration} account={account} />
    })

    tabBodiesArray.push({
      title: 'Accounts',
      content: content
    });
  }
  
  return (
    <div className="bureau-report-main">
      <Tabs tabBodies={tabBodiesArray} extraClass='analytics-tabs' background={true} />
      <div className="bureau-back-button-container">
        <button className="button-square button-outline" onClick={props.callbackFunction}>Back</button>
      </div>
    </div>
  )
}

export default BureauReport;