import Spinner from "../../../svg/Spinner/Spinner";
import "../Modal.css";
import "./UserManagement.css";
import { useState } from "react";
import { PostDataStatus, TypeOfPermissions, UserRoleTypes } from "../../../library/Variables";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { inviteNewUserAsync, resetInviteSend } from "../../../redux/Slices/UserManagement";

function InviteUser(props) {
  let content = null;

  const dispatch = useDispatch();
  const platformUser = props.platformUser;
  const accessToken = props.accessToken;
  const userManagement = props.userManagement;
  const permissions = props.permissions;
  const generalPermissions = props.permissions.filter(p => (p.type).toUpperCase() === TypeOfPermissions.GENERAL);
  const managePermissions = props.permissions.filter(p => (p.type).toUpperCase() === TypeOfPermissions.MANAGE);
  const settingsPermissions = props.permissions.filter(p => (p.type).toUpperCase() === TypeOfPermissions.SETTINGS);
  const { register, watch, formState: { errors }, handleSubmit, reset } = useForm();
  const [showCustomRole, setShowCustomRole] = useState(false);
  const watchEmail = watch("email");

  const params = {
    accessToken: accessToken,
    data: {}
  }

  const onSubmit = (formData) => {
    params.data.emailAddress = formData.email;
    params.data.firstName = formData.firstName;
    params.data.lastName = formData.lastName; 
    params.data.businessUserId = formData?.businessUserId?formData?.businessUserId:'';
    params.data.branchNumber = formData?.branchNumber ?formData?.branchNumber:''   

    if(formData.role === UserRoleTypes.CUSTOM) {
      params.data.role = UserRoleTypes.CUSTOM;
      params.data.newRole = {
        roleName: formData.roleName,
        permissionKeys: []
      };

      permissions.forEach(element => {
        if(formData[element.permissionName] === true) {
          params.data.newRole.permissionKeys.push(element.key);
        }
      });
    } else {
      params.data.role = userManagement.userRoles[formData.role - 1].role;
    }

    dispatch(inviteNewUserAsync(params));
  }

  const handleSelectRole = (index) => {
    index === UserRoleTypes.CUSTOM ? setShowCustomRole(true) : setShowCustomRole(false);
  }

  const resetForm = () => {
    reset();
    dispatch(resetInviteSend());
  }

  if(userManagement.inviteNewUserStatus === PostDataStatus.INITIAL) {
    content = <form onSubmit={handleSubmit(onSubmit)} className="user-management-form-container">
      <div className="modal-body-container user-management-form-fields scrollbar">
        <div className="scrollable-content">
          <div className="user-management-input-container">
            <label className="text-darker">First name</label>
            <input className="inputs" type="text" placeholder="Enter first name" 
              {...register("firstName", { required: "First name is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.firstName?.message}</span>
          </div>
          <div className="user-management-input-container">
            <label className="text-darker">Last name</label>
            <input className="inputs" type="text" placeholder="Enter last name" 
              {...register("lastName", { required: "Last name is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.lastName?.message}</span>
          </div>
          <div className="user-management-input-container">
            <label className="text-darker">Email address</label>
            <input className="inputs" type="text" placeholder="Enter email address" {...register("email", 
              { required: "Email address is required", 
              pattern: {
                value:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/,
                message: "Invalid email address"
              } } )} />
            <span className="user-management-error-fields error-msg">
              {errors.email?.message}</span>
          </div>
          <div className="user-management-input-container">
            <label className="text-darker">Role</label>
            <select {...register("role")} onChange={(e) => {handleSelectRole(e.target.value); 
            }} className="user-management-select-inputs-container">
              {userManagement.userRoles.map((element, i) => {
                return <option key={i} value={i + 1}>{element.label}</option>
              })}
              {platformUser.user.permissions.managePermissions === true &&
              <option value={UserRoleTypes.CUSTOM}>Add custom role...</option>}
            </select>
            <span className="user-management-error-fields error-msg"></span>
          </div>
          {showCustomRole && 
            <div className="user-management-custom-role-container">
              <h3>Permissions</h3>
              <hr />
              <div className="user-management-input-container">
                <label className="text-darker">Role name</label>
                <input className="inputs" type="text" placeholder="Enter role name" 
                  {...register("roleName", { required: "Role name is required" } )} />
                <span className="user-management-error-fields error-msg">
                  {errors.roleName?.message}</span>
              </div>
              <div className="user-management-input-container">
                <label className="text-darker">GENERAL</label>
                <div className="user-management-checkbox-inputs-container">
                  {generalPermissions.map((element, i) => {
                    return <label className="user-management-checkbox-input" key={i}>
                      {element.permissionFriendlyName}
                      <input name="role" type={"checkbox"} {...register(element.permissionName)} />
                      <span className="user-management-checkmark"></span>
                    </label>
                  })}
                </div>
                <span className="user-management-error-fields error-msg"></span>
              </div>
              <div className="user-management-input-container">
                <label className="text-darker">MANAGE USERS</label>
                <div className="user-management-checkbox-inputs-container">
                  {managePermissions.map((element, i) => {
                    return <label className="user-management-checkbox-input" key={i}>
                      {element.permissionFriendlyName}
                      <input name="role" type={"checkbox"} {...register(element.permissionName)} />
                      <span className="user-management-checkmark"></span>
                    </label>
                  })}
                </div>
                <span className="user-management-error-fields error-msg"></span>
              </div>
              <div className="user-management-input-container">
                <label className="text-darker">SETTINGS</label>
                <div className="user-management-checkbox-inputs-container">
                  {settingsPermissions.map((element, i) => {
                    return <label className="user-management-checkbox-input" key={i}>
                      {element.permissionFriendlyName}
                      <input name="role" type={"checkbox"} {...register(element.permissionName)} />
                      <span className="user-management-checkmark"></span>
                    </label>
                  })}
                </div>
                <span className="user-management-error-fields error-msg"></span>
              </div>
            </div>
          }
          <div className="user-management-input-container">
            <label className="text-darker">Business User ID</label>
            <input className="inputs" type="text" placeholder="Enter business user ID" 
              {...register("businessUserId")} />
            <span className="user-management-error-fields error-msg">
              {errors.businessUserId?.message}</span>
          </div>

          <div className="user-management-input-container">
            <label className="text-darker">Branch Number</label>
            <input className="inputs" type="text" placeholder="Enter branch number" 
              {...register("branchNumber")} />
            <span className="user-management-error-fields error-msg">
              {errors.branchNumber?.message}</span>
          </div>
        </div>
      </div>
      <div className="submit-container">
        <button type="button" className="button-square button-outline" onClick={props.close}>Cancel</button>
        <button type="submit" className="button-square button-solid">Send invite</button>
      </div>
    </form>
  } else if(userManagement.inviteNewUserStatus === PostDataStatus.FETCHING) {
    content = <Spinner />
  } else if(userManagement.inviteNewUserStatus === PostDataStatus.SUCCESS) {
    content = <div className="modal-body-container">
      <div className="modal-body-message">
        <p>An email has been sent to {watchEmail}</p>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={props.close} >Close</button>
      </div>
    </div>
  } else if(userManagement.inviteNewUserStatus === PostDataStatus.FAILURE) {
    
    const errorMessage = userManagement.errorMessage.replace(/[\\"]/g, '')
    content = <div className="modal-body-container">
      <p>{errorMessage}</p>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => resetForm()}>Try again</button>
      </div>
    </div>
  }
  
  return(
    <div className="modal-dialog user-management-modal">
      <div className="modal-header">
        <h2 className="detail-header">Invite New User</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <hr />
      {content}
    </div>
  )
}

export default InviteUser;