import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PostDataStatus,CRBGhanaCheckTypes,CRBKenyaCheckTypes } from "../../../../library/Variables";
import amlFilled from '../../../../assests/icons/amlFound.png'
import Spinner from "../../../../svg/Spinner/Spinner";
import "../crbModal.css";
import 'react-toastify/dist/ReactToastify.css';
import { resetAll } from "../../../../redux/Slices/Bureau";

import { postCommercialCredit,
         postConsumerCredit,
         resetConsumerCreditPost,
         resetConsumerCreditGet,
         resetCommercialCreditPost,
         selectCrbGhanaData } from "../../../../redux/Slices/CRB/Ghana/CrbGhana";
import { postBasicCrb,postAdvancedCrb,postBusinessCrb,selectCrbKenyaData,
        resetCrbKenyaBasic,resetCrbKenyaBusiness,resetCrbKenyaAdvanced
      } from "../../../../redux/Slices/CRB/Kenya/CrbKenya";
import selectedIcon from '../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../assests/icons/nonSelected.png'

function PostKenyaCrbModal(props) {
  let content = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const statementKey = props.statementKey;
  const configuration = props.configuration;
  const [dateOfBirth,setDateOfBirth] = useState('')
  const [reportState,setReportState] = useState('')
  const field = props.field;
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const kenyaCrb = useSelector(selectCrbKenyaData);
  const [view, setView] = useState(PostDataStatus.INITIAL);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);
  const [basicformat,setBasicFormat] = useState('basic')

  const resetForm = () => {
    dispatch(resetCrbKenyaAdvanced());
    dispatch(resetCrbKenyaBusiness());
    dispatch(resetCrbKenyaBasic())
    reset();
    props.close()
  }
  
  useEffect(()=>{
      if(field.type === CRBGhanaCheckTypes.COMMERCIAL){
        setBasicFormat('business')
      }
      else{
        setBasicFormat('basic')
      }
  },[field.type])


  const onSubmit = (formData) => {
    if (field.type === CRBKenyaCheckTypes.INDIVIDUAL) {
      const data = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        statementKey: null,
        national_id: formData.national_id,
        registration_no: ''
    }
      if(basicformat==='basic'){
        
      dispatch(postBasicCrb({
          data,
          accessToken
      }));
      }

      else{
        dispatch(postAdvancedCrb({
          data,
          accessToken
      }));
      }
      
      handleStateChanges(PostDataStatus.FETCHING)
      
    }

    else{
      const data = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        statementKey: null,
        national_id: formData.national_id,
        registration_no: formData.registration_no
    }

    dispatch(postBusinessCrb({data,accessToken}))
    }
  }

  const proceedToView = ()=>{
    props.close()
    dispatch(props.setViewReport(false))
    resetForm()
}

  const successfulProceed = ()=>{
    dispatch(resetAll())
    props.close()
    props.setViewReport(false)
    resetForm()
  }

  const handleReportSelect = (data)=>{
        setReportState(data)
  }

  const handleStateChanges = (state) => {
    if(state === PostDataStatus.INITIAL) {
      setView(PostDataStatus.INITIAL);
    } else if(state === PostDataStatus.FETCHING) {
      setView(PostDataStatus.FETCHING);
    } else if(state === PostDataStatus.FAILURE) {
      setView(PostDataStatus.FAILURE);
    }
    else if(state === PostDataStatus.SUCCESS) {
        setView(PostDataStatus.SUCCESS);
      }
  }

  useEffect(()=>{
    if(kenyaCrb.crbKenyaBusinessStatus === PostDataStatus.FAILURE 
        || 
        kenyaCrb.crbKenyaAdvancedStatus === PostDataStatus.FAILURE
        || 
        kenyaCrb.crbKenyaBasicStatus === PostDataStatus.FAILURE){
        handleStateChanges(PostDataStatus.FAILURE)
    }

    if(kenyaCrb.crbKenyaBasicStatus === PostDataStatus.FETCHING 
      || 
      kenyaCrb.crbKenyaAdvancedStatus === PostDataStatus.FETCHING
      || 
      kenyaCrb.crbKenyaBusinessStatus === PostDataStatus.FETCHING){
      handleStateChanges(PostDataStatus.FETCHING)
     }

    if(kenyaCrb.crbKenyaBasicStatus === PostDataStatus.SUCCESS 
        || 
        kenyaCrb.crbKenyaAdvancedStatus === PostDataStatus.SUCCESS
        || 
        kenyaCrb.crbKenyaBusinessStatus === PostDataStatus.SUCCESS){
        handleStateChanges(PostDataStatus.SUCCESS)
       }
  },[kenyaCrb.crbKenyaBusinessStatus,kenyaCrb.crbKenyaAdvancedStatus,kenyaCrb.crbKenyaBasicStatus])

  const SearchTypeSet = (data)=>{
      setBasicFormat(data)
  }


  if(view === PostDataStatus.INITIAL) {


    content = <form onSubmit={handleSubmit(onSubmit)} className="bureau-credit-check-form">
      <h4 className="detail-header">{field.title}</h4>
      <p className="text-medium-dark">
        <strong>Note: </strong>{field.subtitle}</p>
     
     
      <>
        <div style={{display:"flex",columnGap:"10px",marginTop:"5px"}}>
          {field.type === CRBKenyaCheckTypes.INDIVIDUAL &&
            <>
              <section onClick={()=>SearchTypeSet('basic')} 
                style={{display:'flex',
                        cursor:'pointer',
                        justifyContent:"center",
                        alignItems:"center",
                        columnGap:"5px"}}>
                <span style={{display:'flex',
                              justifyContent:"center",
                              alignItems:"center"}}>
                  <img src={basicformat==='basic'?selectedIcon:unselectedIcon}/>
                </span>
                <span style={{fontWeight:basicformat==='basic'?'700':'400'}}>
                    Basic Report
                </span>
              </section>
              <section onClick={()=>SearchTypeSet('advanced')} 
                       style={{display:'none',
                               cursor:'pointer',
                               justifyContent:"center",
                               alignItems:"center",columnGap:"5px"}}>
                  <span style={{display:'flex',
                                justifyContent:"center",
                                alignItems:"center"}}>
                    <img src={basicformat==='advanced'?selectedIcon:unselectedIcon}/>
                  </span>
                  <span style={{fontWeight:basicformat==='advanced'?'700':'400'}}>
                      Advanced Report
                  </span>
              </section>
          </>}
        </div>
        <div className="bureau-credit-check-input-container">
          <label>First Name</label>
          <input type="text" className="inputs" {...register("first_name", { required: "First Name is required" })}/>
        </div>
        <div className="bureau-credit-check-input-container">
        <label>Last Name</label>
        <input type="text" className="inputs" {...register("last_name", { required: "Last Name is required" })}/>
      </div>

      <div className="bureau-credit-check-input-container">
        <label>National ID Number</label>
        <input type="text" className="inputs" {...register("national_id", { required: "ID number is required" })}/>
      </div>

      {field.type === CRBKenyaCheckTypes.BUSINESS && <div className="bureau-credit-check-input-container">
        <label>Registration Number</label>
        <input type="text" className="inputs" {...register("registration_no", { required: "Registration Number is required" })}/>
      </div>}
      </>
     

      <label className="bureau-credit-check-accept-check">
        <input type="checkbox" {...register("chargeAccept",
          { required: "You must agree to the charge before generating a report" })} />
        <p>By checking this box, you are agreeing to the call fee</p>
      </label>
      {errors.name?.message && <span className="text-error">{errors.name?.message}</span>}
      {errors.report_type?.message && <span className="text-error">{errors.report_type?.message}</span>}
      {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}
      <div>
        <button type="submit" className="bureau-credit-check-button button-solid button-square">
          Generate
        </button>
      </div>
    </form>
  } else if(view === PostDataStatus.FETCHING) {
    content = <Spinner />
  } 
  
    else if(view === PostDataStatus.SUCCESS){
       content =  <div style={{display:"flex",
                              flexDirection:"column",
                              height:"250px",
                              justifyContent:"space-around",
                              alignItems:"center"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={amlFilled}/></span>
                <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>CRB Check Available</p>
                <p style={{color:'#363C4F'}}>Click view report to see </p>

                <span 
                    onClick={()=>successfulProceed()} 
                    className='buttonCrb'>
                    View Report
                </span>
        </div>
    }
    
    else if(view === PostDataStatus.FAILURE) {
    content = <div className="bureau-credit-check-form">
      <div>
        <h4 className="detail-header">Request Failed</h4>
        <p>We were unable to retrieve any data.</p>
        <p>{basicformat==='basic'?
            kenyaCrb.crbKenyaBasicErrorMessage:
            basicformat==='advanced'?
            kenyaCrb.crbKenyaAdvancedErrorMessage
          :kenyaCrb.crbKenyaBusinessErrorMessage}</p>
        <button className="bureau-credit-check-button button-solid button-square" onClick={() => resetForm()}>
          Cancel
        </button>
      </div>
    </div>
  } 

  return (
    <div className="bureau-credit-check-dialog">
      <div className="bureau-credit-check-header">
        <img src="/assets/icons/close-color.svg" alt="Close" onClick={props.close} />
      </div>
      {content}
    </div>
  )
}

export default PostKenyaCrbModal;