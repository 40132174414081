import React from 'react'
import { selectDraftState,resetDraftData } from '../../../redux/Slices/DraftAndEditLoan';
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import {resetGetAllLoanData,resetLoanFilter,resetLoanSummary} from '../../../redux/Slices/Loan'
import { useNavigate } from 'react-router';


//import { resetGetAllLoanData } from '../../../redux/Slices/Loan';


function DraftUploadModal(props) {
    const dispatch = useDispatch()
    const draftState = useSelector(selectDraftState)
    const accessToken = props.accessToken
    const setUploadError = props.setUploadError
    const setShowForm = props.setShowForm
    const navigate = useNavigate()


    const successDone = ()=>{
        dispatch(resetLoanSummary())
        dispatch(resetLoanFilter())
        props.closeModal();
        setShowForm(false);
        navigate('/loanApproval');
        dispatch(resetGetAllLoanData())
    }


    let content = null;
  

    if(draftState.draftedDataStatus===PostDataStatus.FETCHING){
 

        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(draftState.draftedDataStatus===PostDataStatus.SUCCESS){
      
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Form Saved as Draft</p>
            <div className='centerButton'>   
                <section className='proceed' onClick={()=>successDone()}>Done!</section>
            </div> 
        </div>
    }

    if(draftState.draftedDataStatus===PostDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{draftState.errordraftedData}</p>

            <div className='centerButton'>   
                <section className='proceed' onClick={()=> {props.closeModal();dispatch(resetDraftData())}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default DraftUploadModal