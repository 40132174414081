import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parameterCreatedIcon from '../../../../../assests/icons/amlFound.png'
import { resetGetRulesByKey, resetParameterByKey, selectSelfServiceState } from '../../../../../redux/Slices/SelfServiceDecision'

function DefineParameterSuccess({parameterState,closeModal,setParamMetaData,setShowScoreCard,setMainParameterKey}) {
  const dispatch = useDispatch()
  const selfState = useSelector(selectSelfServiceState)
  const closeParam = ()=>{
        if(setParamMetaData&&setShowScoreCard&&setMainParameterKey){
            setShowScoreCard(false)
            setMainParameterKey(selfState.customParametersState.key)
            setParamMetaData({name:parameterState.name,type:selfState.customParametersState.displayType,key:selfState.customParametersState.key})
            dispatch(resetGetRulesByKey())
            dispatch(resetParameterByKey())
            closeModal()
        }
        else{
            dispatch(resetParameterByKey())
            closeModal()
        }
       
  }
  return (
        <section style={{margin:'10px 0px',padding:'10px 0px',flex:'1'}}>
            
            <section className='successHoldBody'>
                <span className='imgFlex'>
                    <img src={parameterCreatedIcon}/>
                </span>
                <span className='cardInfo'>Your Parameter was created successfully.</span>
                <span className='cardDirection'>Click done to see</span>

                <section style={{display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    width:"100%"}}>
                    <span onClick={()=>closeParam()} className='button'>
                        Done
                    </span>
                </section>
                    
            </section>
        </section>
  )
}

export default DefineParameterSuccess