import React,{useState} from 'react'
import { selectKenyaAmlExtractions } from '../../../redux/Slices/IdVerification/Kenya/IdVerificationKenyaAml'
import { useDispatch,useSelector } from 'react-redux'
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables'
import Spinner from '../../../svg/Spinner/Spinner'
import amlFilled from '../../../assests/icons/amlFound.png'
import amlEmpty from '../../../assests/icons/amlEmpty.png'
import errorImg from '../../../assests/icons/errorImage.png'
import './amlExtract.css'
import { selectAmlHistorydata,resetAmlHistoryRecord} from '../../../redux/Slices/IdVerification/Kenya/AmlHistory'



function AmlIndividualLookupModal({setContentToShow,closeModal}) {
    const dispatch = useDispatch()
    const amlData = useSelector(selectAmlHistorydata)
  
    let mainContent

    const proceedToView = ()=>{
        closeModal()
        setContentToShow('amlDetails')
    }

    const closeView = ()=>{
        dispatch(resetAmlHistoryRecord())
        closeModal()
    }


    if(amlData.activeHistoryRecordStatus === PostDataStatus.INITIAL){
        mainContent = <div className='InitialAmlModal'>
                <Spinner/>
                <p className='title'>AML History Fetch in Progress</p>
                <p className='info'>Hold on. This will only take a few seconds</p>
        </div>
    }

    if(amlData.activeHistoryRecordStatus === FetchDataStatus.FETCHING){
        mainContent = <div className='FetchAmlModal' >
                <Spinner/>
                <p className='title'>AML History Fetch in Progress</p>
                <p className='info'>Hold on. This will only take a few seconds</p>
        </div>
    }

    if(amlData.activeHistoryRecordStatus === FetchDataStatus.SUCCESS){
        if(amlData.activeHistoryRecord.entity.MatchResults.length>0){
            mainContent = <div style={{display:"flex",flexDirection:"column",height:"250px",justifyContent:"space-around",alignItems:"center"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={amlFilled}/></span>
                <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>AML Information Available</p>
                <p style={{color:'#363C4F'}}>Click view report to see </p>

                <span onClick={()=>proceedToView()} className='buttonAml'>View Report</span>
        </div>
        }
        else{
        mainContent = <div style={{display:"flex",flexDirection:"column",height:"250px",justifyContent:"space-around",alignItems:"center"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={amlEmpty}/></span>
                <p style={{color:"#FB6D06",fontWeight:"700",fontSize:"24px"}}>No AML Information Available</p>
                <p style={{color:'#363C4F'}}>Click the close button to go back</p>
                <span onClick={()=>closeView()} className='buttonAml'>Close</span>
        </div>
        }
    }

    if(amlData.amlBusinessExtractionStatus === PostDataStatus.FAILURE){
        mainContent =<div style={{display:"flex",flexDirection:"column",height:"200px",justifyContent:"space-around",alignItems:"center"}}>
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",padding:"5px"}}><img style={{width:"30px",height:"30px"}} src={errorImg}/></span>
        <p style={{color:'#363C4F'}}>Something went wrong!</p>
        <p style={{color:"red",fontWeight:"700",fontSize:"24px"}}>{amlData.activeHistoryRecordMessage}</p>

        <span onClick={()=>closeView()} className='buttonAml'>Close</span>
</div>
    }
  return (
    <div style={{height:'auto'}}>
        {mainContent}
    </div>
  )
}

export default AmlIndividualLookupModal