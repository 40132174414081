import React,{useState,useEffect} from 'react'
import { getSupportDocData,selectSupportDocs,resetDocsFetch } from '../../../../redux/Slices/SupportDocs'
import { useDispatch,useSelector } from 'react-redux'
import Utils from '../../../../library/Utils'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useParams } from 'react-router';
import { FetchDataStatus } from '../../../../library/Variables'
import './supportDocs.css'
import AddSupportDoc from '../../../../components/Modals/SupportModals/AddSupportDoc'
import Modal from 'react-modal';
import SupportDocImg from '../../../../assests/icons/supportDocImg.png'
import viewSupport from '../../../../assests/icons/viewSupport.png'
import cancelSupport from '../../../../assests/icons/cancelSupport.png'
import { getSupportDocFile } from '../../../../redux/Slices/SupportDocs'
import ViewSupportModal from '../../../../components/Modals/SupportModals/ViewSupportDocModal'
import DeleteSupportModal from '../../../../components/Modals/SupportModals/DeleteSupportDocModal'
import { deleteSupportDocFile } from '../../../../redux/Slices/SupportDocs'


function SupportDocument(props) {
    const { key } = useParams();
    const supportState = useSelector(selectSupportDocs)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [lastThreeChars,setLastThreeChars] = useState('')
    const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);
    const [deleteSupportModalIsOpen, setDeleteSupportModalIsOpen] = useState(false);
    const dispatch = useDispatch()
    const configuration = props.configuration
    const accessToken = props.accessToken
    let content 
    let BusinessCat = []
    let HouseCat = []
    let CollateralCat = []
    let OtherCat = []

    useEffect(()=>{
        dispatch(resetDocsFetch())
    },[])

    
    const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '33%',
          width: '50%',
          top: '33%',
          left: '33%',
          padding: '0 0 0 0',
          overflowY: 'scroll'
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };

      const supportCustomStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: 'auto',
          width: 'auto',
          
          padding: '0 0 0 0',
          overflowY: 'scroll'
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };

    useEffect(()=>{
        if(supportState.supportDocDataStatus === FetchDataStatus.INITIAL){
            dispatch(getSupportDocData({accessToken,key}))
        }
        
    },[supportState])

    function openModal(){
        setIsOpen(true);
      }


      function closeModal(){
        setIsOpen(false);
    }

    function closeSupportModal(){
        setSupportModalIsOpen(false);
    }

    function closeDeleteSupport(){
        setDeleteSupportModalIsOpen(false);
    }
    
   
    function afterOpenModal(){}

    const selectView = (element)=>{
        const threeChars = element.filePath.split('.')[1]
        const loadObj = {accessToken,data:{"type":'support',path:element.filePath}}
        setLastThreeChars(threeChars)
        dispatch(getSupportDocFile(loadObj))
        setSupportModalIsOpen(true)
    }

    const deleteView = (element)=>{
        const loadObj = {accessToken,data:{filepath:element.filePath}}
      
        dispatch(deleteSupportDocFile(loadObj))
        setDeleteSupportModalIsOpen(true)
    }


    
    if(supportState.supportDocDataStatus === FetchDataStatus.INITIAL){
        content = <Spinner/>
    }

    else if(supportState.supportDocDataStatus === FetchDataStatus.FETCHING){
        content = <Spinner/>
    }

    else if(supportState.supportDocDataStatus === FetchDataStatus.SUCCESS){
        if(Utils.isFieldEmpty(supportState.supportDocData)){
            content = 
            <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",width:'70%',margin:'30px auto'}}>
                <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px',color:'rgba(42, 56, 164, 1)'}}>There is currently no supporting document to display.</p>
                <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px',color:'rgba(54, 60, 79, 1)'}}>Please, click the button below to add document</p>
                <section onClick={()=>openModal()} className='supportButton'>Add Support Document</section>
            </div>
        }

        else{
            supportState.supportDocData.forEach((element,index) => {
                if(element.categoryName === 'Collateral'){
                    CollateralCat.push(element)
                }

                else if (element.categoryName === 'Business'){
                    BusinessCat.push(element)
                }

                else if (element.categoryName === 'Household'){
                    HouseCat.push(element)
                }

                else{
                    OtherCat.push(element)
                }
            })
            content = 
            <div>
                <section onClick={()=>openModal()} style={{textAlign:'right',color:'rgba(64, 123, 255, 1)',cursor:'pointer'}}>
                        Add Additional Document
                </section>
                {BusinessCat.length>0 && <section className='supportClassHold'>
                    <p style={{color:'rgba(106, 113, 125, 1)',fontSize:'18px'}}>Business Documents</p>
                    <div style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
                        {BusinessCat.map((element,index)=>
                        <div key={index} className='docHolder'>
                            <span style={{width:"15%"}}><img src={SupportDocImg}/></span>
                            <div style={{display:'flex',width:'85%',height:"100%"}}>
                                <section style={{display:'flex',flexDirection:"column",width:'85%',height:"100%"}}>
                                    <p style={{color:'rgba(54, 60, 79, 1)',fontSize:"19px",fontWeight:"700"}}>{element.name}</p>
                                    <p>{`Date Uploaded: ${element.date}`}</p>
                                </section>
                                <section style={{display:'flex',width:'15%',height:"100%",columnGap:"10px"}}>
                                    <span onClick={()=>selectView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={viewSupport}/></span>
                                    <span onClick={()=> deleteView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={cancelSupport}/></span>
                                </section>
                            </div>
                        </div>)}    
                    </div>
                </section>}

                {HouseCat.length>0 && <section className='supportClassHold'>
                    <p style={{color:'rgba(106, 113, 125, 1)',fontSize:'18px'}}>Household Documents</p>
                 
                    <div style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
                        {HouseCat.map((element,index)=>
                        <div key={index} className='docHolder'>
                            <span style={{width:"15%"}}><img src={SupportDocImg}/></span>
                            <div style={{display:'flex',width:'85%',height:"100%"}}>
                                <section style={{display:'flex',flexDirection:"column",width:'85%',height:"100%"}}>
                                    <p style={{color:'rgba(54, 60, 79, 1)',fontSize:"19px",fontWeight:"700"}}>{element.name}</p>
                                    <p>{`Date Uploaded: ${element.date}`}</p>
                                </section>
                                <section style={{display:'flex',width:'15%',height:"100%",columnGap:"10px"}}>
                                    <span onClick={()=>selectView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={viewSupport}/></span>
                                    <span onClick={()=> deleteView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={cancelSupport}/></span>
                                </section>
                            </div>
                        </div>)}    
                    </div>
                </section>}

                {CollateralCat.length>0 && <section className='supportClassHold'>
                    <p style={{color:'rgba(106, 113, 125, 1)',fontSize:'18px'}}>Collateral Documents</p>
                
                    <div style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
                        {CollateralCat.map((element,index)=>
                        <div key={index} className='docHolder'>
                            <span style={{width:"15%"}}><img src={SupportDocImg}/></span>
                            <div style={{display:'flex',width:'85%',height:"100%"}}>
                                <section style={{display:'flex',flexDirection:"column",width:'85%',height:"100%"}}>
                                    <p style={{color:'rgba(54, 60, 79, 1)',fontSize:"19px",fontWeight:"700"}}>{element.name}</p>
                                    <p>{`Date Uploaded: ${element.date}`}</p>
                                </section>
                                <section style={{display:'flex',width:'15%',height:"100%",columnGap:"10px"}}>
                                    <span onClick={()=>selectView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={viewSupport}/></span>
                                    <span onClick={()=> deleteView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={cancelSupport}/></span>
                                </section>
                            </div>
                        </div>)}    
                    </div>
                </section>}

                {OtherCat.length>0 && <section className='supportClassHold'>
                    <p style={{color:'rgba(106, 113, 125, 1)',fontSize:'18px'}}>Other Documents</p>

                    <div style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
                        {OtherCat.map((element,index)=>
                        <div key={index} className='docHolder'>
                            <span style={{width:"15%"}}><img src={SupportDocImg}/></span>
                            <div style={{display:'flex',width:'85%',height:"100%"}}>
                                <section style={{display:'flex',flexDirection:"column",width:'85%',height:"100%"}}>
                                    <p style={{color:'rgba(54, 60, 79, 1)',fontSize:"19px",fontWeight:"700"}}>{element.name}</p>
                                    <p>{`Date Uploaded: ${element.date}`}</p>
                                </section>
                                <section style={{display:'flex',width:'15%',height:"100%",columnGap:"10px"}}>
                                    <span onClick={()=>selectView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={viewSupport}/></span>
                                    <span onClick={()=> deleteView(element)} style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={cancelSupport}/></span>
                                </section>
                            </div>
                        </div>)}    
                    </div>

                </section>}
            </div>
        }
        
    }

    else if(supportState.supportDocDataStatus === FetchDataStatus.FAILURE){
        content = 
            <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
                <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
                <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{supportState.supportDocDataerrorMessage}</p>
            </div>
        }


  return (
    <div>
        {content}
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
            contentLabel="Add Support Document" >
            <AddSupportDoc configuration={configuration} accessToken={accessToken}  close={closeModal}/>
      </Modal>

      <Modal isOpen={supportModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeSupportModal} style={supportCustomStyles}
            contentLabel="View Support Document" >
            <ViewSupportModal configuration={configuration} lastThreeChars={lastThreeChars} accessToken={accessToken}  close={closeSupportModal}/>
      </Modal>

      <Modal isOpen={deleteSupportModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeDeleteSupport} style={customStyles}
            contentLabel="View Support Document" >
            <DeleteSupportModal configuration={configuration} accessToken={accessToken}  close={closeDeleteSupport}/>
      </Modal>
    </div>
  )
}

export default SupportDocument

/**
 * {acceptedFileItems.length > 0 &&<section style={{height:'50px',marginTop:'15px',border:'1px dotted rgba(64, 123, 255, 1)',display:"flex",alignItems:"center",justifyContent:'center'}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:'end',columnGap:"10px"}}>
                    <span style={{display:"flex",alignItems:"center",justifyContent:'center'}}><img src={addDocs}/></span>
                    <span style={{color:'rgba(64, 123, 255, 1)',fontSize:"18px"}}>Add supporting document</span>
                </div>
          </section>}
 */