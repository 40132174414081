import React,{useMemo,useState,useCallback,useEffect} from 'react'
import {useDropzone} from "react-dropzone";
import { useNavigate } from 'react-router';
import { uploadLinkedPdfAsync,selectAddPdfStatus,reset } from '../../../redux/Slices/AddPdf';
import './supportModal.css'
import { useDispatch,useSelector } from 'react-redux';
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables';
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import addDocs from '../../../assests/icons/addDocs.png'
import { resetDocUpload,resetDocsFetch,selectSupportDocs,uploadSupportPdf,getCategories } 
from '../../../redux/Slices/SupportDocs';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function AddSupportDoc(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {key} = useParams()
  const userId = props.userId
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);
  const [userIdentity,setUserIdentity] = useState(userId?userId:'')
  const accessToken = props.accessToken;
  const configuration = props.configuration;
  const statementKey = props.statementKey;
  const addPdfState = useSelector(selectAddPdfStatus);
  const supportDocState = useSelector(selectSupportDocs)
  const [bvnData,setBvnData] = useState('')
  const setRefreshStatement = props.setRefreshStatement
  const refreshStatement = props.refreshStatement

  useEffect(()=>{
      dispatch(getCategories({accessToken}))
  },[])


  const maxFileSize = configuration?.featureFlags?.maxFileUploadSize ?configuration.featureFlags.maxFileUploadSize :10000000
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  
  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };


  const onDrop = useCallback(acceptedFiles => {
    const fileItem = {
      pdf: acceptedFiles,
      categoryId: "",
      name: '',
      statementType:''
      // accessToken: accessToken,
      // uniqueIdentifier: userId,
      // baseStatementKey: statementKey
    }

    setFilesSelected([...filesSelected, fileItem]);
    setFilesRejected([]);
  }, [filesSelected])

  const onDropRejected = useCallback(fileRejections => {
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'application/pdf': ['.pdf'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'], },
    maxSize: maxFileSize,
    filesizeBase: 1000,
    multiple: false,
    onDrop,
    onDropRejected
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const acceptedFileItems = filesSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeFile(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));

  const handleStementTypeChange = (e, id, property,key) => {
    const {value} = e.target;

    const updatedArray = [...filesSelected];
    updatedArray[id] = {...updatedArray[id], [property] : value,categoryId:key}
    setFilesSelected(updatedArray)
  }


  const handleDocNameChange = (e, id, property) => {
    const {value} = e.target;

    const updatedArray = [...filesSelected];
    updatedArray[id] = {...updatedArray[id], [property] : value}
    setFilesSelected(updatedArray)
  }


  function closepop(props) {
    dispatch(resetDocUpload());
    //setRefreshStatement(true)
    props.close();
  }

  const removeFile = (file) => {
    let newFilesArray = [...filesSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setFilesSelected(newFilesArray);
  }

  // if(filesSelected.length > 1) {
  //   removeFile(filesSelected[0]);
  // }

  const submitFileForProcessing = () => {
    let filesArray = []
    let statementTypeArray = []
    let categoryIds = []

    filesSelected.map(({pdf, categoryId, name}) => {
      filesArray.push(pdf[0])
      categoryIds.push(categoryId)
      statementTypeArray.push(name)
    })

    if(Utils.isFieldEmpty(filesArray) || Utils.isFieldEmpty(statementTypeArray) || Utils.isFieldEmpty(categoryIds
      || statementTypeArray.includes(' ') || categoryIds.includes(' '))){
      toast.warning("Input all Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    else{
      let params = {
        accessToken: accessToken,
        loanApplicationKey:key,
        files: filesArray,
        categoryIds: categoryIds,
        statementTypes: statementTypeArray,
      }
      dispatch(uploadSupportPdf(params));
    }
    
    

  }



  let component = null;
  if(supportDocState.supportDocUploadStatus === PostDataStatus.INITIAL){

   component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload A Support Document</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container scrollbar">
        <div className="scrollable-content">
          <div {...getRootProps({style})} className="drop-container">
            <input {...getInputProps()} />
            <img src="/assets/icons/download-color.svg" alt="" />
            <p className="drop-caption">Drag "n" drop your file here, or <span className="detail-header"  >
              Open File Dialog</span>
            </p>
            <p className="drop-subcaption text-medium">{`MAX FILE SIZE: ${Math.round((maxFileSize/1048576).toFixed(2))}MB`}</p>
            <p className="drop-subcaption text-medium">FILE SUPPORTED: PDF</p>
          </div>

          {acceptedFileItems.length > 0 &&<section onClick={()=>open()}  style={{height:'50px',cursor:'pointer',marginTop:'15px',border:'1px dotted rgba(64, 123, 255, 1)',display:"flex",alignItems:"center",justifyContent:'center'}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:'end',columnGap:"10px"}}>
                    <span style={{display:"flex",alignItems:"center",justifyContent:'center'}}><img src={addDocs}/></span>
                    <span style={{color:'rgba(64, 123, 255, 1)',fontSize:"18px"}}>Add supporting document</span>
                </div>
          </section>}

          {filesRejected.length > 0 &&
            <span className="ap-file-rejected-error-message error-msg">
              {filesRejected[0].errors[0].code === "file-too-large" 
                ? "File is larger than 10MB"
                : filesRejected[0].errors[0].message}
            </span>
          }
          {
            supportDocState.supportCategoriesStatus === FetchDataStatus.INITIAL?
          <Spinner/>:
          supportDocState.supportCategoriesStatus === FetchDataStatus.FETCHING?
          <Spinner/>:
          supportDocState.supportCategoriesStatus === FetchDataStatus.FAILURE?
          <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around"
              ,backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              ,margin:'30px auto'}}>
                <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
                <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{supportDocState.supportCategoryerrorMessage}</p>
          </div>
          :
          (acceptedFileItems.length > 0 && supportDocState.supportCategoriesStatus === FetchDataStatus.SUCCESS)?
            <>
           
              <h4 className="text-medium">SELECTED FILE:</h4>
              {
                filesSelected?.map((file, id) => {
                  return (
                  <div className="ap-accepted-file-container" key={id}>
                      <ul>
                        <li key={file.pdf[0].path}>
                          <div className="ap-accepted-file-info">
                            <img src="/assets/icons/statement-color.svg" alt="" />
                            <div>
                              <h4 className="text-dark">{file.pdf[0].path}</h4>
                              <span className="text-medium">{file.pdf[0].size / 1000000} MB</span>
                            </div> 
                          </div>
                          <button onClick={() => removeFile(file)} className="delete-pdf-button">
                            <img src="/assets/icons/delete-color.svg" alt="Delete" />
                          </button>
                        </li>
                      </ul>
                      <div className="ap-statement-type-container">
                        <h4 className="text-dark">Select Suport Document Type</h4>
                        <div className="ap-statement-type-radio-buttons-container">
                          {supportDocState.supportCategoryData.map((element,index)=>
                          <div className="ap-statement-type" key={index}>
                              <label>{element.name}</label>
                              <input type="radio" name={`${element.name}-${id}`} value={element.name} 
                                checked={file.statementType === element.name} 
                                onChange={(e) => handleStementTypeChange(e, id, 'statementType',element.key)} />
                          </div>)}
                        </div>
                      </div> 

                      <div style={{marginTop:'15px',display:"flex",flexDirection:"column",rowGap:'5px'}}>
                        <label>File name</label>
                        <input onChange={(e) => handleDocNameChange(e, id, 'name')}  style={{height:'45px',border:'1px solid #407BFF',paddingLeft:"10px",
                              boxSizing:"border-box",borderRadius:"7px",outline:"none"}} placeholder='input file name'/>
                      </div>
                  </div>
                  )
                })
              }

              <div className="submit-container">
                <button className="btn-submit button-solid" onClick={() => submitFileForProcessing()}>Upload</button>
              </div>
            </>:''
          }
        </div>
      </div>
    </div>
  }

  else if(supportDocState.supportDocUploadStatus === PostDataStatus.FETCHING){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Please wait...</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <Spinner />
    </div>
  } else if(supportDocState.supportDocUploadStatus === PostDataStatus.SUCCESS){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">PDF Statement Uploaded</h2>
        <div className="close" onClick={() => closepop(props)}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <p>Upload complete. Click done to see.</p>
        <div className="submit-container">
          <button className="btn-submit button-solid" onClick={() => {closepop(props);dispatch(resetDocsFetch())}}>Done</button>
        </div>
      </div>
    </div>
  } else if(supportDocState.supportDocUploadStatus === PostDataStatus.FAILURE) {
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload Failed</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <div className="modal-body-message">
          <p>{!Utils.isFieldEmpty(supportDocState.supportDocUploaderrorMessage) && (supportDocState.supportDocUploaderrorMessage).replace(/[""]/g, "")}</p>
          <p>If the problem persists, please contact support for further inquiries.</p>
        </div>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => dispatch(resetDocUpload())}>Try Again</button>
      </div>
    </div>
  } 

  return (
    <div>{component}</div>
  )
}

export default AddSupportDoc