import React from 'react'

function AccountRatingPreview({accountTypes}) {
  return (
    <div className='contentTwoBody'>
        <div className='rbcTable'>
            <div className='header-hold'>
                <div className='Attri'>
                    Account Types
                </div>

                <div className='currencyTitle'>
                    Bad Accounts
                </div>

                <div className='currencyTitle'>
                    Good Accounts
                </div>
            </div>

            <div className='body'>
                      
                {accountTypes.map(accType=>
                    <div className='inner' key={accountTypes.indexOf(accType)}>
                        <p className='found' >{accType.accType}</p>
                        <p className='element'>{accType.bad}</p>
                        <p className='element'>{accType.good}</p>
                    </div>)
                }
            </div>
        </div>
    </div>
  )
}

export default AccountRatingPreview