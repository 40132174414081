import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
let trackNo

  export const getLicenseDataAsync = createAsyncThunk('getLicenseData', async (params) => {
    trackNo = params.licenseNumber
    let data = {
      licenseNumber: params.licenseNumber,
      statementKey: params.statementKey,
    }
    data = JSON.stringify(data)
    
   const licenseData = await client.post('/verify/license',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });

    return licenseData;
   }
 );

 export const getBvnDataAsync = createAsyncThunk('getBvnData', async (params) => {
  trackNo = params.bvn
  let data = {
    bvn: params.bvn,
    statementKey: params.statementKey,
  }
  data = JSON.stringify(data)
 const bvnData = await client.post('/verify/bvn',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });
  return bvnData;
 }
);

export const getNinDataAsync = createAsyncThunk('getNinData', async (params) => {
  trackNo = params.nin
  let data = {
    nin: params.nin,
    statementKey: params.statementKey,
  }
  data = JSON.stringify(data)
 const ninData = await client.post('/verify/nin',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return ninData;
 }
);

export const getVinDataAsync = createAsyncThunk('getVinData', async (params) => {
  trackNo = params.data.number
  let data = {...params.data}
  data = JSON.stringify(data)
 const vinData = await client.post('/verify/vin',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return vinData;
 }
);



export const getPassportDataAsync = createAsyncThunk('getPassportData', async (params) => {
  trackNo = params.passportNumber
  let data = {
    passportNumber: params.passportNumber,
    lastName: params.lastName,
    statementKey: params.statementKey,
  }
  data = JSON.stringify(data)
 const passportData = await client.post('/verify/passport',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return passportData;
 }
);

export const getAllKycDataHistory = createAsyncThunk('getHistoryData',async (params)=> {
  const HistoricalData = await client.get(`/statements/${params.key}/kyc`,
  {
    "headers":
    {
     "Authorization": "Bearer " + params.accessToken,
    
    }
  });
   return HistoricalData.data;
})

export const getStandAloneDataHistory = createAsyncThunk('getStandAloneHistoryData',async (params)=> {
  const HistoricalData = await client.get(`/verify/kychistory/${params.page}/${params.perPage}/INDIVIDUAL`,
  {
    "headers":
    {
     "Authorization": "Bearer " + params.accessToken,
    
    }
  });

  
   return HistoricalData.data;
})

export const getReportDetail = createAsyncThunk('getReportData',async(params)=>{
  const type = params.type.toLowerCase()
  const reportData = await client.get(`/verify/history/${type}/${params.uuid}`,{
    "headers":{
      "Authorization": "Bearer " + params.accessToken,
    }
  });
  return reportData.data;
})


export const kycDataSlice = createSlice({
  name: 'kycEntireData',
  initialState: {
    activeExtractions: [],


    bvnExtraction: [],
    bvnStatus: PostDataStatus.INITIAL,
    bvnIsError:false,
    bvnErrorMessage:null,


    ninExtraction:[],
    ninStatus: PostDataStatus.INITIAL,
    ninIsError:false,
    ninErrorMessage:null,


    passportExtraction:[],
    passportStatus:PostDataStatus.INITIAL,
    passportIsError:false,
    passportErrorMessage:null,


    licenseExtraction:[],
    licenseStatus:PostDataStatus.INITIAL,
    licenseIsError:false,
    licenseErrorMessage:null,


    
    vinExtraction:[],
    vinStatus:PostDataStatus.INITIAL,
    vinIsError:false,
    vinErrorMessage:null,


    extractionStatus: PostDataStatus.INITIAL,
    historyStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    reportData:null,
    reportStatus:FetchDataStatus.INITIAL,
    reportErrorMessage:null,
    isReportError:false,
    standAloneStatus:FetchDataStatus.INITIAL,
    standAloneData:null,
    standAloneErrorMessage:null
  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      //state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetKycData: (state)=>{
      state.extractionStatus = PostDataStatus.INITIAL
      state.isError = false
      state.errorMessage = null
    },

    resetReport: (state)=>{
      state.reportData = null;
      state.reportStatus = FetchDataStatus.INITIAL
      state.reportErrorMessage = null
      state.isReportError = false
    },

    resetBvnData: (state)=>{
      state.bvnExtraction =  [];
      state.bvnStatus =  PostDataStatus.INITIAL;
      state.bvnIsError = false;
      state.bvnErrorMessage = null;
    },

    resetNinData: (state)=>{
      state.ninExtraction =  [];
      state.ninStatus =  PostDataStatus.INITIAL;
      state.ninIsError = false;
      state.ninErrorMessage = null;
    },

    resetLicenseData:  (state)=>{
      state.licenseExtraction =  [];
      state.licenseStatus =  PostDataStatus.INITIAL;
      state.licenseIsError = false;
      state.licenseErrorMessage = null;
    },

    resetPassportData: (state)=>{
      state.passportExtraction =  [];
      state.passportStatus =  PostDataStatus.INITIAL;
      state.passportIsError = false;
      state.passportErrorMessage = null;
    },

    resetVinData : (state)=>{
      state.vinExtraction =  [];
      state.vinStatus =  PostDataStatus.INITIAL;
      state.vinIsError = false;
      state.vinErrorMessage = null;
    }


  },
  
  extraReducers(builder) {
  builder.addCase(getLicenseDataAsync.pending, (state) => {
    state.licenseStatus = PostDataStatus.FETCHING;
  }).addCase(getLicenseDataAsync.fulfilled, (state,action) => {
    const newObj = action.payload
    const mimeType = 'data:image/jpeg;base64,'
    const img = mimeType + action.payload['photo']
    const mainObj = {}
    delete newObj.photo;
    mainObj.data = newObj
    mainObj.kycType = "DRIVER'S LICENSE"
    mainObj.status = 'FOUND'
    mainObj.img = img
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.licenseStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [mainObj,...state.activeExtractions]
    state.licenseExtraction = action.payload
  }).addCase(getLicenseDataAsync.rejected, (state,action) => {
    state.licenseStatus = PostDataStatus.FAILURE;
    state.licenseIsError = true;
    state.licenseErrorMessage = action.error.message;
  })



  .addCase(getBvnDataAsync.pending, (state) => {
    state.bvnStatus = PostDataStatus.FETCHING;
  })
  .addCase(getBvnDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',verificationType:action.payload.type,
                    dateCreated:action.payload.registerationDate,
                    fullName: `${action.payload.firstName} ${action.payload.middleName} ${action.payload.lastName}`,
                    trackingNumber: trackNo,
                    uuid:action.payload.uuid};
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.bvnStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [newObj,...state.activeExtractions]
    state.bvnExtraction = action.payload
  }).addCase(getBvnDataAsync.rejected, (state,action) => {
    state.bvnStatus = PostDataStatus.FAILURE;
    state.bvnIsError = true;
    state.bvnErrorMessage = action.error.message;
  })




  .addCase(getVinDataAsync.pending, (state) => {
    state.vinStatus = PostDataStatus.FETCHING;
  })
  .addCase(getVinDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',verificationType:action.payload.type,
                    dateCreated:action.payload?.registerationDate?action.payload?.registerationDate:'',
                    fullName: `${action.payload.firstName} ${action.payload.middleName} ${action.payload.lastName}`,
                    trackingNumber: trackNo,
                    uuid:action.payload.uuid};
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.vinStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [newObj,...state.activeExtractions]
    state.vinExtraction = action.payload
  }).addCase(getVinDataAsync.rejected, (state,action) => {
    state.vinStatus = PostDataStatus.FAILURE;
    state.vinIsError = true;
    state.vinErrorMessage = action.error.message;
  })





  .addCase(getPassportDataAsync.pending, (state) => {
    state.passportStatus = PostDataStatus.FETCHING;
  })
  .addCase(getPassportDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',verificationType:action.payload.type,
                    dateCreated:action.payload.registerationDate,
                    fullName: `${action.payload.firstName} ${action.payload.middleName} ${action.payload.lastName}`,
                    trackingNumber: trackNo,
                    uuid:action.payload.uuid}
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.passportStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [newObj,...state.activeExtractions] 
    state.passportExtraction = action.payload
  })
  .addCase(getPassportDataAsync.rejected, (state,action) => {
    state.passportStatus = PostDataStatus.FAILURE;
    state.passportIsError = true;
    state.passportErrorMessage = action.error.message;
  })




  .addCase(getNinDataAsync.pending, (state) => {
    state.ninStatus = PostDataStatus.FETCHING;
  })
  .addCase(getNinDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',verificationType:action.payload.type,
                    dateCreated:action.payload.registerationDate,
                    fullName: `${action.payload.firstName} ${action.payload.middleName} ${action.payload.lastName}`,
                    trackingNumber: trackNo,
                    uuid:action.payload.uuid}
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.ninStatus =PostDataStatus.SUCCESS
    //state.activeExtractions = [newObj,...state.activeExtractions]
    state.ninExtraction = action.payload
  })
  .addCase(getNinDataAsync.rejected, (state,action) => {
    state.ninStatus = PostDataStatus.FAILURE;
    state.ninIsError = true;
    state.ninErrorMessage = action.error.message;
  })



  .addCase(getAllKycDataHistory.pending, (state) =>{
    state.historyStatus = PostDataStatus.FETCHING;
  })
  .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    
    state.activeExtractions = [...action.payload]
    state.historyStatus = PostDataStatus.SUCCESS;
  })


  

  .addCase(getReportDetail.pending, (state)=>{
    state.reportStatus = FetchDataStatus.FETCHING
  })
  
  .addCase(getReportDetail.fulfilled, (state,action)=>{
    state.reportStatus = FetchDataStatus.SUCCESS
    state.reportData = action.payload
  })
  
  .addCase(getReportDetail.rejected,(state,action)=>{
    state.reportStatus = FetchDataStatus.FAILURE
    state.reportErrorMessage = action.error.message
    state.isReportError = true
  })



  .addCase(getStandAloneDataHistory.pending, (state)=>{
    state.standAloneStatus = FetchDataStatus.FETCHING
  })
  .addCase(getStandAloneDataHistory.fulfilled,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.SUCCESS
    state.activeExtractions = action.payload
  })
  .addCase(getStandAloneDataHistory.rejected,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.FAILURE
    state.standAloneErrorMessage = action.error.message
  })
  
  }
})


export const { addToExtractedData,
               resetKycData,
               resetNinData,
               resetBvnData,
               resetPassportData,
               resetVinData,
               resetLicenseData} = kycDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;
export const selecKycExtractions = state => state.kycEntireData.activeExtractions;
export const kycEtxractionStatus = state => state.kycEntireData.extractionStatus
export const selectKycEntireDataState = state => state.kycEntireData
export const kycErrorState = state => state.kycEntireData.isError
export const kycErrorMsg = state => state.kycEntireData.errorMessage

export const {resetReport} = kycDataSlice.actions


export default kycDataSlice.reducer;


/**
 * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    let mainArray = action.payload
    let newArray = []
    const mimeType = 'data:image/jpeg;base64,'
    for(let i=0;i<mainArray.length;i++){
      const newObj = mainArray[i]
      let img;
      let signature
      if(action.payload[i]['base64Image']){
        img = mimeType + action.payload[i]['base64Image']
      }
      
      if(action.payload[i]['photo']){
        img = action.payload[i]['photo']
      }
      if(action.payload[i]['signature']){
        signature = action.payload[i]['signature']
      }
      else{
        img = null;
      }
      
      const mainObj = {}
      delete newObj.base64Image;
      delete newObj.photo;
      delete newObj.signature;
      mainObj.data = newObj
      mainObj.kycType = action.payload[i]['verificationType']
      mainObj.status = 'FOUND'
      mainObj.img = img
      mainObj.signature = signature
      newArray.push(mainObj)
    }

    state.activeExtractions = [...newArray]
    state.historyStatus = PostDataStatus.SUCCESS;
  })
 */