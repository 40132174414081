import React, {useEffect, useState} from 'react'
import './kychistory.css'
import Utils from '../../../../library/Utils';
import { useDispatch } from 'react-redux';
import { getReportDetail,resetReport } from '../../../../redux/Slices/KycOptions';
import { getReportDetailKyb,resetReportKyb } from '../../../../redux/Slices/KybOptions';
import { getStandAloneDataHistory } from '../../../../redux/Slices/KycOptions';
import { Table } from '../../../FraudDetect/components/Table';
import { useNavigate } from 'react-router';
import { FetchDataStatus } from '../../../../library/Variables';

function ExtractionHistory({viewReport,setViewReport,historyArray,tableHeaders,accessToken,setVerificationType,kycState}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [maxPagesNumber,setMaxPagesNumber] = useState(0)

    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 10,
        status: "flagged",
      });

    const [selectedPage,setSelectedPage] = useState('')

    const nextPage = ()=>{
        if(selectedPage+1 <= maxPagesNumber){
            let page = parseInt(selectedPage)+1
            dispatch(getStandAloneDataHistory({accessToken,page,perPage:10}))
        }
        
    }

    const previousPage = ()=>{
        if(selectedPage > 1){
            let page = parseInt(selectedPage)-1
            dispatch(getStandAloneDataHistory({accessToken,page,perPage:10}))
        }
    }

    useEffect(()=>{
        if(kycState.standAloneStatus===FetchDataStatus.SUCCESS){
            setSelectedPage(kycState.activeExtractions.metaData.page)
            setMaxPagesNumber(kycState.activeExtractions.metaData.pages)
        }
    },[])
    
    const getDetails = (token,uuid,type,history)=>{
        if(['CAC','TIN'].includes(type)){
            setVerificationType(history.verificationType);
            setViewReport(true)
            dispatch(resetReportKyb())
            dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
        }
        else{
            setVerificationType(history.verificationType);
            setViewReport(true)
            dispatch(resetReport())
            dispatch(getReportDetail({type,uuid,accessToken:token}))
        }
    }

  return (
    <div className='kyc-history-holder'>
                
                <div className='top-section'>
                    <span className={viewReport?'span-view-report':'span-view-history'}>History</span>
                    <span>/</span>
                    <span className={viewReport?'span-view-history':'span-view-report'} >View report</span>
                </div>

                <p style={{color:"rgba(42, 56, 164, 1)",fontSize:"24px",fontWeight:"700"}}>Check History</p>



                <Table
                    showPagination={true}
                    pageCount={kycState?.activeExtractions?.metaData?.pages}
                    loading={kycState.standAloneStatus === "FETCHING"}
                    selectedPage={selectedPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    maxPagesNumber={maxPagesNumber}
                    gotoPage={(page) =>
                        dispatch(getStandAloneDataHistory({accessToken,page,perPage:10}))
                    }
                    columns={[
                        {
                        title: "Status",
                        field: "status",
                        sortable: true,
                        Cell: ({ entry }) => (
                            <span
                            style={{
                                color: entry.status==='FOUND'?'green':'red',
                                fontWeight:'700'
                            }}
                            >
                            {entry.status}
                            </span>
                        ),
                        },
                        {
                        title: "KYC Type",
                        field: "verificationType",
                        sortable: true,
                        },

                        {
                        title: "Created Date",
                        field: "dateCreated",
                        sortable: true,
                        },
                        {
                        title: "Full Name",
                        field: "fullName",
                        sortable: true,
                        },
                        {
                        title: "Tracking Number",
                        field: "trackingNumber",
                        sortable: true,
                        },
                      
                        {
                        title: (
                            <div
                            style={{ display: "flex", alignItems: "center", gap: "8px" }}
                            >
                            <span>Action</span>

                           

                            <span>
                                <svg
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M22.501 12C22.501 17.52 18.021 22 12.501 22C6.98098 22 3.61098 16.44 3.61098 16.44M3.61098 16.44H8.13098M3.61098 16.44V21.44M2.50098 12C2.50098 6.48 6.94098 2 12.501 2C19.171 2 22.501 7.56 22.501 7.56M22.501 7.56V2.56M22.501 7.56H18.061"
                                    stroke="#2A38A4"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                </svg>
                            </span>
                            </div>
                        ),
                        field: "action",
                        Cell: ({ entry }) => (
                            <div>
                            <button
                                onClick={() =>{
                                //navigate(`/fraud-detect/${entry?.userKey}/transactions`);
                                getDetails(accessToken,entry.uuid,entry.verificationType,entry)}
                                }
                                className='containedButt'
                            >
                                View
                            </button>
                            </div>
                        ),
                        },
                    ].filter(Boolean)}
                    data={historyArray.map((item) => {
                        return {
                        ...item,
                        dateCreated: Utils.formatLongDateAndTime(item.dateCreated),
                        };
                    })}
                    />


            </div>
  )
}

export default ExtractionHistory




/**
 * 
 * <div className='kyc-table-hold'>
                    <div className='header-hold'>
                        {tableHeaders.map(head=><p key={tableHeaders.indexOf(head)}>{head}</p>)}
                        <div className='divider'></div>
                    </div>
                    <div className='body' >{historyArray.map(history=>
                        ['BVN','PASSPORT',"DRIVER'S LICENSE",'NIN','VIN'].includes(history.verificationType)?
                        <div className='inner' key={historyArray.indexOf(history)}>
                            <p className='found' >{history.status}</p>
                            <p className='element'>{history.verificationType}</p>
                            <p className='element'>{Utils.formatLongDateAndTime(history.dateCreated)}</p>
                            <p className='element'><span>{history.fullName}</span></p>
                            <p className='element'>{history.trackingNumber}</p>
                            <div onClick={()=>getDetails(accessToken,history.uuid,history.verificationType,history)} className='viewHold'>
                                <span>View</span>
                            </div>
                        </div>
                        :null)}
                    </div>

                </div>
 */