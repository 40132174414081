import React, { useMemo } from "react";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import { Table } from "../../../components/Table/Table";
import Utils from "../../../library/Utils";

function People(props) {
  const tableData = props.data;
  const platformUser = props.platformUser;
  

  const theadersUsers = useMemo(() => [
    { 
      Header: "Name",
      accessor: "firstName",
      Cell: data => {
        return data.row.original.firstName + " " + data.row.original.lastName
      },
    },
    {
      Header: "Email address",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "role",
      disableGlobalFilter: true,
    },
    {
      Cell: data => {
        return (
          <span style={{ 
            fontWeight: "600",
            color: Utils.formatTextColor(data.row.original.tag, "#000", "#FF725E", "#0CBC8B", "#FFBC73", null)}}>
            {data.row.original.tag}
          </span>
        );
      },
      Header: "Tag",
      accessor: "tag",
      disableGlobalFilter: true,
    },
    {
      id: "action-menu",
      disableSortBy: true,
      disableGlobalFilter: true,
      Header: "Action",
      Cell: data => {
        let links = []

        if(platformUser.user.permissions.canUpdateSettings === true) {
          links.push({
            action: "Edit",
            function: () => props.editCallback(data.row.original)
          })  
        }


        if(!Utils.isFieldEmpty(data.row.original.roleId)) {
          links.push({
            action: "View permissions",
            function: () => props.viewCallback(data.row.original)
          })  
        }

        if(data.row.original.roleId !== 1 && platformUser.user.permissions.removeUsers === true) {
          links.push({
            action: "Delete",
            color: "#FF725E",
            function: () => props.deleteCallback(data.row.original)
          })  
        }
        
        return !Utils.isFieldEmpty(links) ? <ActionMenu links={links} /> : null
      }
    }]
  )

  const handleTableCallback = () => {
    props.inviteNewUser();
  }

  return <div className="user-management-people-main">
    <Table tableClass="user-management-type-table" data={tableData} columns={theadersUsers} tableExtra={false} tableFilterWithButton={true}
      buttonText="+ &nbsp; Invite new user" buttonFunction={() => handleTableCallback()} platformUser={platformUser}
      pagination={true} pageSize={[10]} />
  </div>
}

export default People;