import React,{useState,useEffect} from 'react'
import './Enginetour.css'
import StepOne from './StepOne/StepOne'
import StepTwo from './StepTwo/StepTwo'
import Button from '../Button/Button'
import StepThree from './StepThree/StepThree'
import StepFour from './StepFour/StepFour'
import StepFive from './StepFive/StepFive'

function EngineTour({tourSection,setTourSection,setEngineTour}) {
const top =  <div style={{position:'relative'}}>
              <div className='engine-page-header'>
                <div onClick={()=>setEngineTour(false)} className='de-btn-wrap'>
                    <Button name={"Back To List"} className="button-link back-button detail-header-dark" iconSrc="/assets/icons/arrow-left.png" />
                </div>  
                <div className='de-subhead-wrap'>
                    <img src='/assets/icons/scan.svg' alt=''/>
                    <h2>Custom Decision Engine </h2>
                </div>           
              </div>
              <div style={{position:"absolute",zIndex:'3',top:'0px',left:"0px",bottom:'0px',right:"0px",backgroundColor:"black",opacity:"0.6"}}>

              </div>

             </div>



const [step,setstep] = useState(0)
  return (
    <div className="tour-prompt">
      <div style={{width:"100%",height:"100%",backgroundColor:"white",position:"relative"}}> 
        {tourSection==='stepOne'?<StepOne setEngineTour={setEngineTour} setTourSection={setTourSection} step={step} setstep={setstep}/>
        :
        <>
          {top}
          {tourSection==='stepTwo'?
          <StepTwo step={step} setTourSection={setTourSection} setstep={setstep}/>
          :tourSection==='stepThree'? <StepThree setTourSection={setTourSection} />
          :tourSection==='stepFour'?<StepFour setTourSection={setTourSection}/>
          :tourSection==='stepFive'?<StepFive setTourSection={setTourSection} setEngineTour={setEngineTour}/>
          :""
          }
        </>
        }
      </div>
    </div>
  )
}

export default EngineTour