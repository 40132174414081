import React from 'react'
import './TotalStatementCard.css'

function TotalStatementCard({title, titleValue, processedValue, failedValue}) {
  return (
    <div className='statement-card-wrapper'>
      <div className='header-card'>
        <div className='left-section-card'>
          <img src='/assets/icons/document-total.svg' />
          <p className='header-text'>{title}</p>
        </div>

        <h3 className='value-text'>{titleValue}</h3>
      </div>

      <div className='content-card'>
        <div className='processed-info' >
          <p>Total Processed</p>
          <p className='value-text'>{processedValue}</p>
        </div>

        <div className='failed-info' >
        <p>Total failed</p>
        <p className='value-text'>{failedValue}</p>
        </div>
      </div>
    </div>

  )
}

export default TotalStatementCard