import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const getActionValues = (params) =>
  clientApi.get(
    `/api/parameters/${params.id}/actions/${params.actionId}/values`
  );
export const fetchParameterActionValuesAsync = createAsyncThunk(
  "fraudDetect/fetchParameterActionsValues",
  async (params) => {
    const response = await getActionValues(params);

    return response;
  }
);
