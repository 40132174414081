import React, { useEffect, useState } from 'react'
import ArrowUp from '../../../../assests/svg-icons/arrowDownIcon.svg'
import arrowDownIcon from '../../../../assests/svg-icons/arrowDownThick.svg'
import Utils from '../../../../library/Utils'
import './agreement.css'
import AgreementExtraInfoSlice from './AgreementExtraInfo'
import { agreementMonthlyReShape } from './agreementObjectSerializer'

function AgreementMonthlyTable({userReport}) {

    const headers = ['Amount Overdue','Currency','Subscriber Name',' ']
    const [mainContent,setMainContent] = useState([])
    const [indexSelected,setIndexSelected] = useState()

    useEffect(()=>{
        const data = agreementMonthlyReShape(userReport.data.report.creditAgreementSummary)
        setMainContent(data.data)
    },[])

    const generateBreakDown = (index)=>{
        if(index===indexSelected){
            setIndexSelected()
            
        }
        else{
            setIndexSelected(index)
        }
        
    }

  return (
    <div className='repaymentHolder'>
        <section className='paySectionOne'>
            Account Information
        </section>

        <section className='paySectionTwo'>
            {headers.map((element,index)=>
            <section className={index===headers.length-1?'AgreesecondTitle': 'AgreefirstTitle'}>
                {element}
            </section>)}
        </section>
        <section >
                {mainContent.map((element,index)=>
                <>
                <section className='paySectionThree'>
                    {element.map((item,indexTwo)=>
                    <section className='AgreefirstTitle'>
                        {item}
                    </section>)}
                    <section className='AgreesecondTitle'
                             onClick={()=>generateBreakDown(index)}>
                        <img style={{cursor:"pointer"}} src={index===indexSelected?ArrowUp: arrowDownIcon}/>
                    </section>
                </section>
                
                {(index===indexSelected && !Utils.isFieldEmpty(userReport.data.report.creditAgreementSummary[indexSelected])) && 
                    <AgreementExtraInfoSlice data={userReport.data.report.creditAgreementSummary[indexSelected]}/>
                }
                </>)}
                    
            </section>
    </div>
  )
}

export default AgreementMonthlyTable