import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

export const InnerLayout = ({
  children,
  backUrl = "/fraud-detect",
  fullWidth = true,
}) => {
  const navigate = useNavigate()
  return (
    <div style={{ backgroundColor: "transparent", height: "100%" }}>
      <section className="idVerifyHead">
        <section onClick={()=>navigate(-1)} style={{cursor:"pointer"}} className={styles.backWrapper}>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.56945 18.8172C9.37945 18.8172 9.18945 18.7472 9.03945 18.5972L2.96945 12.5272C2.67945 12.2372 2.67945 11.7572 2.96945 11.4672L9.03945 5.39719C9.32945 5.10719 9.80945 5.10719 10.0995 5.39719C10.3895 5.68719 10.3895 6.16719 10.0995 6.45719L4.55945 11.9972L10.0995 17.5372C10.3895 17.8272 10.3895 18.3072 10.0995 18.5972C9.95945 18.7472 9.75945 18.8172 9.56945 18.8172Z"
                fill="#1F2228"
              />
              <path
                d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
                fill="#1F2228"
              />
            </svg>
          </span>
          <span>Back to Dashboard</span>
        </section>
        <section className="idVerifyContent">
          <span>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 20C16.1421 20 19.5 16.6421 19.5 12.5C19.5 8.35786 16.1421 5 12 5C7.85786 5 4.5 8.35786 4.5 12.5C4.5 16.6421 7.85786 20 12 20Z"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 4.5V2.5"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12.5H2"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 20.5V22.5"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 12.5H22"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p>Fraud Detect</p>
        </section>
      </section>

      {fullWidth ? (
        <section
          style={{
            marginTop: "30px",
          }}
          className={styles.bodyWrapper}
        >
          {children}
        </section>
      ) : (
        children
      )}
    </div>
  );
};
