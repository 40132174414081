import React from 'react'

function PaymentCard({payData}) {
  return (
    <div style={{backgroundColor:"white",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-around",
                alignItems:"center",
                padding:"20px",
                rowGap:'15px',
                width:"300px",
                boxSizing:"border-box",
                borderRadius:"10px"}}>
        <section style={{backgroundColor:"white",
                display:"flex",
                justifyContent:"center",
                flexDirection:"column",
                alignItems:"center",
                rowGap:'5px'}}>
            <section>{payData.itemOne}</section>
            <section style={{color:"#2A38A4",fontSize:"24px",fontWeight:"700"}}>{payData.valueOne}</section>
        </section>

        <section style={{backgroundColor:"white",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:'column',
                rowGap:'5px'}}>
            <section>{payData.itemTwo}</section>
            <section style={{color:"#2A38A4",fontSize:"24px",fontWeight:"700"}}>{payData.valueTwo}</section>
        </section>
    </div>
  )
}

export default PaymentCard