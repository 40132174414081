import React from "react";
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>&copy; Copyright by Periculum Technologies Ltd.</p>
    </div>
  )
}

export default Footer;