import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus,PostDataStatus } from "../../../../library/Variables";

let download = require('downloadjs');

export const getAllLoanAgents = createAsyncThunk ('loanManagement/getLoanAgents',async(params)=>{
    const loanAgents = await client.get('/platformuser/all',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return loanAgents.data;
} )


export const reAssignLoanAgents = createAsyncThunk ('loanManagement/reAssign',async(params)=>{
    const loanAgents = await client.get(`/loan/reassign-agent/${params.loanKey}/${params.userkey}`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return loanAgents.data;
} )


export const downLoadAdminReport = createAsyncThunk('loanManagement/filteredDownload', async (params) => {
  try {
    const data = JSON.stringify(params.data);
    const response = await client.getPostCsv('/loan/download-admin-report', data, {
        headers: {
            "Authorization": "Bearer " + params.accessToken,
            'Content-Type': 'application/json'
        },
     
    });

    const blob = new Blob([response], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'downloaded_report.csv');

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);

} catch (error) {
    console.error('Error downloading CSV:', error);
}
  });
 


export const loanManagementSlice = createSlice({
    name: 'loanManagement',
    initialState: {

      loanAgentStatus: FetchDataStatus.INITIAL,
      loanAgentData: null,
      isErrorLoanAgent:false,
      errorLoanAgent:null,

      reAssignAgentStatus: FetchDataStatus.INITIAL,
      reAssignAgentData: null,
      isErrorReAssignAgent:false,
      errorReAssignAgent:null,

      downloadAdminReport: null,
      downloadAdminReportStatus:PostDataStatus.INITIAL,
      isErrordownloadAdminReport:false,
      errorDownloadAdminReport:null
    },

    reducers: {
        resetLoanAgent: state => {

            state.loanAgentStatus =  FetchDataStatus.INITIAL;
            state.loanAgentData =  null;
            state.isErrorLoanAgent = false;
            state.errorLoanAgent = null;
        }
        ,
        resetAgentReAssignment:state => {
            state.reAssignAgentStatus =  FetchDataStatus.INITIAL;
            state.reAssignAgentData =  null;
            state.isErrorReAssignAgent = false;
            state.errorReAssignAgent = null;
        },

        resetDownloadAdminReport:state =>{
          state.downloadAdminReportStatus =  PostDataStatus.INITIAL;
          state.downloadAdminReport =  null;
          state.isErrordownloadAdminReport = false;
          state.errorDownloadAdminReport = null;
        },

      
      setDownloadingFalse: state => {
        state.isDownloading = false;
      },
      setDownloadingTrue: state => {
        state.isDownloading = true;
      }
       
    },
    extraReducers(builder) {
        builder.addCase(getAllLoanAgents.pending, (state) => {
            state.loanAgentStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getAllLoanAgents.fulfilled, (state, action) => {
            state.loanAgentStatus = FetchDataStatus.SUCCESS;
            state.loanAgentData = action.payload;
          })
          .addCase(getAllLoanAgents.rejected, (state, action) => {
            state.loanAgentStatus = FetchDataStatus.FAILURE;
            state.isErrorLoanAgent = true;
            state.errorLoanAgent = action.error.message;
          })


          .addCase(reAssignLoanAgents.pending, (state) => {
            state.reAssignAgentStatus = FetchDataStatus.FETCHING;
          })
          .addCase(reAssignLoanAgents.fulfilled, (state, action) => {
            state.reAssignAgentStatus = FetchDataStatus.SUCCESS;
            state.reAssignAgentData = action.payload;
          })
          .addCase(reAssignLoanAgents.rejected, (state, action) => {
            state.reAssignAgentStatus = FetchDataStatus.FAILURE;
            state.isErrorReAssignAgent = true;
            state.errorReAssignAgent = action.error.message;
          })



          .addCase(downLoadAdminReport.pending, (state) => {
            state.downloadAdminReportStatus = PostDataStatus.FETCHING;
          })
          .addCase(downLoadAdminReport.fulfilled, (state, action) => {
            state.downloadAdminReportStatus = PostDataStatus.SUCCESS;
            state.downloadAdminReport = action.payload;
          })
          .addCase(downLoadAdminReport.rejected, (state, action) => {
            state.downloadAdminReportStatus = PostDataStatus.FAILURE;
            state.isErrordownloadAdminReport = true;
            state.errorDownloadAdminReport = action.error.message;
          })
         

    }
})


export const { resetLoanAgent,resetAgentReAssignment,resetDownloadAdminReport, setDownloadingTrue,setDownloadingFalse } = loanManagementSlice.actions;

export const selectLoanManagement = state => state.loanManagement
export default loanManagementSlice.reducer;