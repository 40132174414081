import React from 'react'
import { RegionBreakDownData } from '../MakeUpData/PerformingRegions/PerformingRegionsHold'

function RegionBreakDown() {
    const listHeader = ['States','No Of Agents','Total GDP (₦ millions)','Value','Volume','Top Services']
    return (
      <section className='AgentTable'>
              <section className='titleBreakHold'>
                <p className='title'>{RegionBreakDownData.title}</p>
                
              </section>
  
              <div>
                <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                  {listHeader.map((header,index)=>(header==='Action'?
                    <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                  :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                    )}
                </section>
                <section>
                  {RegionBreakDownData.data.map((content,index)=>
                  
                  <section className='AgentTableRow' key={index}>
                    <section>{content.state}</section>
                    <section>{content.noOfAgents}</section>
                    <section>{content.gdp}</section>
                    <section>{content.value}</section>
                    <section>{content.volume}</section>
                    <section>{content.service}</section>
                  </section>)}
                </section>
              </div>
          </section>
    )}

export default RegionBreakDown