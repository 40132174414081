export const businessDetailsData = {
    mainTitle:'Bank Details',
    listOne: [
        {title:'Bank Name',value:'Access Bank'},
        {title:'Account Name',value:'Starships Venture'},
        {title:'Account Number',value:'123476257'},
        {title:'Account Tier',value:'Tier 3'},
        {title:'Bank Verification Number',value:'7152534119162'}
    ]
}


export const TransactionDataHistory ={title:'Transaction History', data: [
    {date:'April 9, 2022',
    description:'Transfer',
    type:'₦10,000',
    amount:'₦10,000',
    balance:'₦100,000'
    },

    {date:'April 9, 2022',
     description:'Transfer',
     type:'₦7,000',
     amount:'₦13,000',
     balance:'₦100,000'
     },


     {date:'May 9, 2022',
        description:'Transfer',
        type:'₦3,000',
        amount:'₦10,000',
        balance:'₦100,000'
        },


    {date:'May 20, 2022',
        description:'Transfer',
        type:'₦14,000',
        amount:'₦10,000',
        balance:'₦100,000'
        },


   {date:'June 9, 2022',
    description:'Transfer',
    type:'₦10,000',
    amount:'₦10,000',
    balance:'₦90,000'
    },
]}

export const CashFlowOutData ={title:'Outflow', data: [
    {year:'2023',
    count:'3',
    amount:'₦100,000',
    },
    {year:'2022',
    count:'11',
    amount:'₦450,000',
    },
    {year:'2022',
    count:'3',
    amount:'₦200,000',
    },
    {year:'2021',
    count:'10',
    amount:'₦1,200,000',
    },
    {year:'2020',
    count:'6',
    amount:'₦800,000',
    },
]}

export const CashFlowInData ={title:'Inflow', data: [
    {year:'2023',
    count:'5',
    amount:'₦110,000',
    },
    {year:'2022',
    count:'7',
    amount:'₦650,000',
    },
    {year:'2022',
    count:'10',
    amount:'₦2,200,000',
    },
    {year:'2021',
    count:'10',
    amount:'₦1,200,000',
    },
    {year:'2020',
    count:'6',
    amount:'₦800,000',
    },
]}

export const AffordabilityData ={title:'Affordability Breakdown', data: [
    {date:'April 11th 2020',
    loanTenure:'6 months',
    dti:'15%',
    },

    {date:'May 13th 2022',
    loanTenure:'3 months',
    dti:'10%',
    },

    {date:'June 10th 2022',
    loanTenure:'6 months',
    dti:'15%',
    },

    {date:'September 2nd 2023',
    loanTenure:'4 months',
    dti:'15%',
    },
]}