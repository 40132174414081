export const AgentsPreview ={title:'Agents Location', data: [
    {place:'Lekki-Epe',number:2500},
    {place:'Egbeda',number:2000},
    {place:'Ikeja',number:1500},
    {place:'Orile',number:1200},
    {place:'Mile-2',number:1000},
    {place:'Oshodi',number:700},
    {place:'Ikoyi',number:500}
]}


