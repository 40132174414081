import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { CreditList } from '../MakeUpData/CreditHistoryData/CreditHistoryHold'
import CreditListTwo from './CreditListTwo'


function CreditListUi() {
    const listCreditHeader = ['Description','Amount','Approval Date','Payment Date','Payment Status','Action']
    const navigate = useNavigate()
    const [creditPage,setCreditPage] = useState(1)
  return (
     <div>
        <div style={{
                    height:'auto',
                    width:"95%",
                    margin:'40px auto'
                  }}>
          <p style={{
            color:'#1F2227',
            fontSize:"24px",
            fontWeight:'700',
            marginBottom:"10px"
          }}>
            80% Complete
          </p>
          <section style={{width:'100%',
                          height:'10px',
                          borderRadius:"5px",
                          backgroundColor:"#ccedde",
                          position:'relative'}}>

            <div style={{width:'80%',
                          height:'100%',
                          borderRadius:"5px",
                          backgroundColor:"#2DA771",
                          position:'absolute',
                          left:'0px',
                          top:'0px'}}>

            </div>
          </section>
        </div>

        <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            columnGap:"25px"
        }}>
          <section style={{
            width:'300px',
            height:'250px',
            backgroundColor:"white",
            borderRadius:"10px",
            padding:"10px",
            boxSizing:"border-box",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:'column',
            rowGap:"15px"

          }}>

            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:'column'
            }}>
              <p style={{
                color:'#1F2228',
                fontSize:"14px",
                fontWeight:"400"
              }}>
              Total Amount
              </p>

              <p style={{
                color:'#2A38A4',
                fontSize:"24px",
                fontWeight:"700"
              }}>
              ₦150,000
              </p>
            </div>

            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:'column'
            }}>
              <p style={{
                color:'#1F2228',
                fontSize:"14px",
                fontWeight:"400"
              }}>
              Remaining Balance
              </p>

              <p style={{
                color:'#2A38A4',
                fontSize:"24px",
                fontWeight:"700"
              }}>
              ₦150,000
              </p>
            </div>

          </section>



          <section style={{
            width:'300px',
            height:'250px',
            backgroundColor:"white",
            borderRadius:"10px",
            padding:"10px",
            boxSizing:"border-box",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            flexDirection:'column',
            rowGap:"15px"

          }}>

            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:'column'
            }}>
              <p style={{
                color:'#1F2228',
                fontSize:"14px",
                fontWeight:"400"
              }}>
              Next Payment Due
              </p>

              <p style={{
                color:'#2A38A4',
                fontSize:"24px",
                fontWeight:"700"
              }}>
              13th June, 2024
              </p>
            </div>

            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              flexDirection:'column'
            }}>
              <p style={{
                color:'#1F2228',
                fontSize:"14px",
                fontWeight:"400"
              }}>
              Next Payment Amount
              </p>

              <p style={{
                color:'#2A38A4',
                fontSize:"24px",
                fontWeight:"700"
              }}>
              ₦50,000
              </p>
            </div>


          </section>

         

        </div>
        {creditPage===1?
          <section className='AgentTable'>
            

            <div>
              <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                {listCreditHeader.map((header,index)=>(header==='Action'?
                  <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                  )}
              </section>
              <section>
                {CreditList.data.map((content,index)=>
                
                <section className='AgentTableRow' key={index}>
                  <section>{content.Description}</section>
                  <section>{content.Amount}</section>
                  <section>{content.ApprovalDate}</section>
                  <section>{content.PaymentDate}</section>
                  <section style={{color:content.PaymentStatus==='ONGOING'?'#FB6D06':'#2DA771'}}>{content.PaymentStatus}</section>
                  <div 
                  onClick={()=>{navigate('/agentBreakdown');setCreditPage(2)}}
                  style={{fontWeight:"700",width:"10%",display:'flex',
                              justifyContent:"center",alignItems:"center",
                              cursor:"pointer",backgroundColor:"#2B52AA",
                              color:"white",padding:"10px 0px",
                              borderRadius:"5px"}}>View</div>
                </section>)}
              </section>
            </div>
          </section>
        :<CreditListTwo/>}
     </div>
     
  )
}

export default CreditListUi