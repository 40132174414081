export const flaggedTransactionSelector = (state) =>
  state.flaggedFraudTransactions;

export const pendingTransactionSelector = (state) =>
  state.pendingFraudTransactions;
export const reviewTransactionSelector = (state) =>
  state.reviewFraudTransactions;

export const transactionStatisticSelector = (state) =>
  state.fraudTransactionsStatistic;

export const userTransactionProfileSelector = (state) =>
  state.userTransactionProfile;

export const fraudTransactionDetailsSelector = (state) =>
  state.fraudTransactionDetails;

export const fraudTransactionByIdSelector = (state) =>
  state.fraudTransactionsById;
