import React from 'react'
import { CreditListDataTwo } from '../MakeUpData/CreditHistoryData/CreditHistoryHold'
import PaymentCard from '../MakeUpData/CreditHistoryData/PaymentCard'
import { creditPaymentDataOne, creditPaymentDataTwo } from '../MakeUpData/CreditHistoryData/PaymentData'

function CreditListTwo() {

  const listCreditHeader = ['Description','Amount','Approval Date','Payment Date','Payment Status']

  return (
    <div>
        <section style={{display:"flex",justifyContent:"center",columnGap:"30px",marginBottom:"30px"}}>
            <PaymentCard payData={creditPaymentDataOne}/>
            <PaymentCard payData={creditPaymentDataTwo}/>
        </section>

        <section className='AgentTable'>

            <div>
              <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                {listCreditHeader.map((header,index)=>(header==='Action'?
                  <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                  )}
              </section>
              <section>
                {CreditListDataTwo.data.map((content,index)=>
                
                <section className='AgentTableRow' key={index}>
                  <section>{content.description}</section>
                  <section>{content.amount}</section>
                  <section>{content.ApprovalDate}</section>
                  <section>{content.paymentDate}</section>
                  <section style={{color:content.PaymentStatus==='ONGOING'?'#FB6D06':'#2DA771'}}>{content.PaymentStatus}</section>
                </section>)}
              </section>
            </div>
        </section>
    </div>
  )
}

export default CreditListTwo