import { createSlice } from "@reduxjs/toolkit";

import { fetchParametersAsync } from "./thunks";

import { PARAMETERS } from "../../../../pages/FraudDetect/Rules/constant";

const initialState = {
  data: null,
  status: "idle",
};

export const parameterSlice = createSlice({
  name: "parameters",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchParametersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchParametersAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchParametersAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
        state.data = PARAMETERS;
      });
  },
});
