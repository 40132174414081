import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getStatementCount = createAsyncThunk('main/getStatementCount', async (params) => {
  const totalStatementCount = await client.get('/statements/count',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return totalStatementCount.data;
});

export const getAllStatements = createAsyncThunk('main/getStatementList', async (params) => {
  const statements = await client.get('/statements/filter?name=' + params.name + '&processingStatus=' + params.status + '&startDate=' + params.startDate + "&endDate=" + params.endDate + "&type=" + params.type,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return statements.data;
});

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    statements: [],
    statementStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    totalStatementCount: 0,
    totalStatementCountStatus: FetchDataStatus.INITIAL    
  },
  reducers: {
    resetMain: state => {
      state.statements = [];
      state.statementStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
      state.totalStatementCount = 0;
      state.totalStatementCountStatus = FetchDataStatus.INITIAL;
    },
  },
  extraReducers(builder) {
    builder.addCase(getStatementCount.pending, (state) => {
      state.totalStatementCountStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getStatementCount.fulfilled, (state, action) => {
      state.totalStatementCountStatus = FetchDataStatus.SUCCESS;
      state.totalStatementCount = action.payload;
    })
    .addCase(getStatementCount.rejected, (state, action) => {
      state.totalStatementCountStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })    
    .addCase(getAllStatements.pending, (state) => {
      state.statementStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllStatements.fulfilled, (state, action) => {
      state.statementStatus = FetchDataStatus.SUCCESS;
      state.statements = action.payload;
    })
    .addCase(getAllStatements.rejected, (state, action) => {
      state.statementStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, statements, resetMain } = mainSlice.actions;

export const selectMainState = state => state.main;

export default mainSlice.reducer;