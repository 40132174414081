import React from 'react'

function CreditAcctSummaryPreview({accountSumm}) {
  return (
    <div className='contentTwoBody'>
        <div className='rbcTable'>
            <div className='header-hold'>
                <div className='Attri'>
                    Attribute
                </div>

                <div className='currencyTitle'>
                    EUR
                </div>

                <div className='currencyTitle'>
                    GBP
                </div>

                <div className='currencyTitle'>
                    GHC
                </div>

                <div className='currencyTitle'>
                    USD
                </div>
                            
            </div>

            <div className='body' >
                {

                accountSumm.map(accSumm=>
                    
                    <>
                        
                        <div className='inner' key={accountSumm.indexOf(accSumm)}>
                            <p className='foundAcc' >{accSumm.attribute}</p>
                            <p className='elementAcc'>{accSumm.eur}</p>
                            <p className='elementAcc'>{accSumm.gbp}</p>
                            <p className='elementAcc'><span>{accSumm.ghs}</span></p>
                            <p className='elementAcc'>{accSumm.usd}</p>
                            
                        </div> 
                    </>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default CreditAcctSummaryPreview