import React from 'react'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import nairaIcon from '../../../../../assests/icons/nairaIcon.png'


function ClientHomeVerification(props) {

  const register = props.register
    const errors = props.errors

  const optionChecker = (data)=>{
    if(data.friendlyName==='Number Of Dependents'){
      let numbersArray = [];
      for (let i = 1; i <= 20; i++) {
          numbersArray.push(i);
      }
      return numbersArray
    }

  

    else{
      return data.options
    }
}

const selectArray = ['Confirmed', 'Not confirmed']

  return (
    <div style={{width:'100%',boxSizing:"border-box",boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius: '10px',backgroundColor:"white",padding:'2em'}}>
              <p className='formTitle'>Client Home Verification</p>
              
              <section className='sign-up-details-inputs' >
              {
                lodash.sortBy(props.info,'rank').map((data,id)=><div style={{width:'100%'}} id={id}>
                {data.displayType==='SELECT'?
                  <div className='sign-up-details-input-container'>
                  <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                  <div className='selectHolder'>
                      <select  {...register(`${data.name}`, { required: data.isRequired?true:false })} 
                    >
                        <option value="">Select an option</option>
                        {selectArray.map((element, i) => {
                          return <option value={element} key={i}>{element}</option>
                        })}
                      </select>
                  </div>
                  <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                  </div>:

                  data.displayType==='TEXT'|| data.displayType==='NUMBER'?
                        <div className='sign-up-details-input-container'> 
                        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                        <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
                        { 
                          required: data.isRequired?true:false, 
                        
                        })} />
                        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                        </div>
                        :
                  <div className='sign-up-details-input-container'> 
                  <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>

                  <section className='currency-section'>
                  <span><img src={nairaIcon}/></span>
                  <input placeholder={`input ${data.friendlyName}`} autocomplete="off" type='NUMBER' {...register(`${data.name}`, 
                  { 
                    required: data.isRequired?true:false, 
                    
                  })} />
                  </section>
                  <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                  
                  </div>
                }
                </div>)
              }</section>
      </div>
  )
}

export default ClientHomeVerification