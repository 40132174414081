import React,{useState} from 'react'
import ClientCard from '../clientInfo/ClientCard'
import Utils from '../../../../library/Utils'
import Modal from 'react-modal'
import ViewSignatureModal from '../../../../components/Modals/LoanModals/ViewSignatureModal'
import { getSignature } from '../../../../redux/Slices/LoanSignatureUpload'
import { useDispatch } from 'react-redux'
import './businessInfo.css'
import editLoanIcon from '../../../../assests/icons/editLoanIcon.png'

function BusinessInfo({customData,configuration,accessToken,signaturePath,
                       setSectionType,setEditModalOpen,setSlicedData,setSectionName,approvalStatus,permissions}) {
  const dispatch = useDispatch()
  const salesData = customData.filter(value=>value.sectionName==='SALES_ESTIMATE').slice(0,6)
  
  const headers = ['Duration','Minimum','Maximum','Average']
  let operationalCost = salesData[6]
  const [lastThreeChars,setLastThreeChars] = useState()

  const SalesHeader = ['Duration','Minimum','Maximum','Average Sales','Average Monthly Sales','Average Annual Sales']

  
  const [modalIsOpen, setIsOpen] = useState(false);
  

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: 'auto',
      width: 'auto',
      
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const signaturePreview = (index)=>{
        const signatureAddress =  {path:signaturePath[index].filePath,type:'signature'}
        let threeChars = signaturePath[index].filePath.slice(-3);
        setLastThreeChars(threeChars)
        dispatch(getSignature({accessToken,data:signatureAddress}));
        setIsOpen(true)
  }

  const openEditSection = ()=>{
    if(permissions.updateLoanApplication){

      setSectionType("Sales Estimate")
      setSlicedData(salesData)
      setEditModalOpen(true)
      setSectionName("SALES_ESTIMATE")
    }
  }

  return (
    <div style={{width:'100%',boxSizing:'border-box',}}>
        <ClientCard filterValue='BUSINESS_INFO' data={customData} 
                    setEditModalOpen={setEditModalOpen} setSectionType={setSectionType} setSectionName={setSectionName}
                    setSlicedData={setSlicedData} approvalStatus={approvalStatus} permissions={permissions}
                    />
        
        <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto',overflowX:'auto'}}>
            <section style={{display:"flex",alignItems:"Center-",justifyContent:"space-between"}}>
                <p style={{margin:'15px 0px',fontSize:'20px',fontWeight:'700',color:'rgba(42, 56, 164, 1)'}}>Sales Estimates</p>
                {approvalStatus==='PENDING' && <span onClick={()=>openEditSection()} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
                    {permissions.updateLoanApplication && <img src={editLoanIcon}/>}
                </span>}
            </section>
            <section className='salesEstHolder'>
            <div className='SalesEstTitles'>
              {SalesHeader.map((sales,index)=>
                <div className='titleValues' key={index}>{sales}</div>)}
            </div>

            <div className='SalesEstFields'>
              {salesData.map((sales,index)=>
                sales.displayType==='CURRENCY'?
                    <div className='fieldValues'
                              key={index}>{sales.value?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, sales.value)
                    :'---'}
                    </div>:
                    <div className='fieldValues'
                      
                              key={index}>{sales.value}
                    </div>)}
            </div>
            </section>

        </div>

        <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
            <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Business operational expenses</p>
            <span style={{color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'400'}}>{
              operationalCost?.value?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, operationalCost.value)
                  :'---'
                  }</span>
            
        </div>

        <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",
                    alignItems:'center',backgroundColor:'white',borderRadius:"10px",padding:"10px",
                    boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
            <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Client Signature</p>
            <div style={{display:"flex",columnGap:'10px',marginTop:'20px'}}>
                        
                {signaturePath.map((holder,index)=>
                  <div  className='imageViewHold' >
                      <section className='imageNameHold'>{holder.name}</section>
                      <span onClick={()=>signaturePreview(index)} className='imgView'>view</span>
                  </div>)
                  }
                          
            </div> 
            
        </div>

        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Add Signature" >
          <ViewSignatureModal lastThreeChars={lastThreeChars}  accessToken={accessToken} closeModal={closeModal}/>
      </Modal>
    </div>
  )
}

export default BusinessInfo

//Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview?.totalClosingBalance))