import React, { useEffect, useMemo } from "react";
import { Table, DateRangeColumnFilter, SelectColumnFilter,AmountRangeColumnFilter } from "../../../components/Table/Table";
import { 
  selectTransactionState,
  getTransactions 
} from '../../../redux/Slices/Transactions';
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import './Transactions.css';
import { useNavigate } from "react-router-dom";
import { FetchDataStatus } from "../../../library/Variables";

function Transactions(props) {
  let preparedTableData = [];

  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const transactions = useSelector(selectTransactionState); 
  const configuration = props.configuration;
  const keyFromRoute = props.keyFromRoute;

  const params = { 
    accessToken: props.accessToken,
    key: keyFromRoute
  };

  const theadersTransactions = useMemo(() => [
    { 
      Header: 'Date',
      accessor: 'date',
      Filter: DateRangeColumnFilter,
      filter: 'dateRange'
    },
    { 
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Filter:AmountRangeColumnFilter,
      filter:'amountRange'
    },
    {
      Header: 'Balance',
      accessor: 'balance',
      disableFilters: true
    }], []
  )

  useEffect(() => {
    if((Utils.isFieldEmpty(transactions)) || transactions.transactionStatus === FetchDataStatus.INITIAL) {
      dispatch(getTransactions(params));
    }

    if(transactions.transactionStatus === FetchDataStatus.FAILURE) {
      navigate('/error');
    }
  }, [transactions.transactionStatus, navigate, dispatch])

  if((transactions.transactionStatus === FetchDataStatus.SUCCESS) && (!Utils.isFieldEmpty(transactions.transactions))) {
    transactions.transactions.forEach(element => {
      preparedTableData.push({
        "date": Utils.formatLongDate(element.date),
        "description": element.description,
        "type": element.type,
        "amount": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount),
        "balance": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.balance)
      })
    });

    preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
  }          

  return (
    <div className="transactions-main">
      <h3 className="detail-header">Transaction History</h3>
      {transactions.isLoading === true 
        ? <div className="spinner-container"><Spinner /></div>
        : <Table tableClass="transactions-type-table" pagination={true} pageSize={[10]} data={preparedTableData}
            columns={theadersTransactions} tableFilter={true} rowClass={"table-row-two-colored"} />
      }
    </div>
  )
}

export default Transactions;