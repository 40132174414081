import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';
let download = require('downloadjs');


export const downloadLoanPdfAsync = createAsyncThunk('loanReportingPdf', async (params) => {
    setDownloadingTrue();
    const loanReport = await client.getBlob(`/loan/${params.key}/download`,
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
    })
    .then(res => {
      res.data.then(blob => {
        setDownloadingFalse();
        download(blob, params.loanName+params.key, "application/pdf");
      });
    });
  
    return loanReport;
  });

  export const loanReportPdfSlice = createSlice({
    name: 'loanReportingPdf',
    initialState: {
      loanPdf: null,
      loanPdfStatus: FetchDataStatus.INITIAL,
      isError: false,
      errorMessage: null,
      isDownloading: false,
    },
    reducers: {
      resetLoanDownload: state => {
        state.loanPdf = null;
        state.loanPdfStatus = FetchDataStatus.INITIAL;
        state.isError = false;
        state.errorMessage = null;
        state.isDownloading = false;
      },
      setDownloadingFalse: state => {
        state.isDownloading = false;
      },
      setDownloadingTrue: state => {
        state.isDownloading = true;
      }
    },
    extraReducers(builder) {
      builder.addCase(downloadLoanPdfAsync.pending, (state) => {
        state.loanPdfStatus = FetchDataStatus.FETCHING;
        state.isDownloading = true;
      })
      .addCase(downloadLoanPdfAsync.fulfilled, (state, action) => {      
        state.loanPdfStatus = FetchDataStatus.SUCCESS;
        state.loanPdf = action.payload;
        state.isDownloading = true;
      })
      .addCase(downloadLoanPdfAsync.rejected, (state, action) => {
        state.loanPdfStatus = FetchDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
        state.isDownloading = false;
      })
    }
  });


  export const { resetLoanDownload, setDownloadingFalse, setDownloadingTrue } = loanReportPdfSlice.actions;

  export const selectLoanReportPdfStatus = state => state.loanReportingPdf;

export default loanReportPdfSlice.reducer;