import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

export const getAllCustomDecisionCreditScore = createAsyncThunk('customDecisionCreditScore', async (params) => {
  const customDecisionCreditScore = await client.get(`/creditscore/custom-decision-breakdown`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customDecisionCreditScore.data;
})

export const getCustomDecisionScoreCardDefinitions = createAsyncThunk('customDecisionCreditScore/scorecard-definitions', async (params) => {
  const customDecisionCreditScore = await client.get(`/creditscore/custom-decision-scorecard-definitions`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customDecisionCreditScore.data;
})


export const getPaginatedScoreCardDefinitions = createAsyncThunk('customDecisionCreditScore/paginated/scorecard-definitions', async (params) => {
  const PaginatedResponse = await client.get(`/creditscore/custom-decision-breakdown/filterv2/${params.pageNumber}/${params.pageSize}?customerId=${params.customerId}&startDate=${params.startDate}&endDate=${params.endDate}&scorecardDefinitionKey=${params.scorecardDefinitionKey}`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return PaginatedResponse.data;
})



export const getAllCustomDecisionEngineScores = createAsyncThunk('customDecisionCreditScore/getCustomStatementList', async (params) => {
  const customDecisionCreditScore = await client.get('/creditscore/custom-decision-breakdown/filter?customerId=' + params.name + '&startDate=' + params.startDate + "&endDate=" + params.endDate + "&scorecardDefinitionKey=" + params.type,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return customDecisionCreditScore.data;
});


export const updateCustomDecisionCreditScoreAsync = createAsyncThunk('customDecisionCreditScore/custom-decision', async (params) => {
  let data = {
    CustomerId: params.CustomerId,
    scorecardDefinitionName: params.scorecardDefinitionName,
    CustomScorecardFields: params.CustomScorecardFields
  }
  data = JSON.stringify(data)
  const customDecisionCreditScore = await client.post(`/creditscore/custom-decision`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
  if(!customDecisionCreditScore?.data){
    return customDecisionCreditScore
  }
  else{
    return customDecisionCreditScore.data;
  }
});


export const getParticularCustomDecisionCreditScore = createAsyncThunk('customDecisionCreditScore/particular', async (params) => {
  const customDecisionCreditScore = await client.get(`/creditscore/custom-decision-breakdown-by-key/${params.key}`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customDecisionCreditScore.data;
})


export const customDecisionCreditScoreSlice = createSlice({
  name: 'customDecisionCreditScore',
  initialState: {
    customDecisionCreditScores: { },
    customDecisionCreditScoresStatus: FetchDataStatus.INITIAL,
    customDecisionCustomScoreCards: [],
    customDecisionStatements: [],
    particularDecisionCreditScore : { },
    particularDecisionCreditScoresStatus: FetchDataStatus.INITIAL,
    customDecisionStatementStatus: FetchDataStatus.INITIAL, 
    customDecisionCreditScoreStatus: FetchDataStatus.INITIAL,
    updateCustomDecisionCreditScoreStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null,


    paginatedScoreDefinitionsState: FetchDataStatus.INITIAL,
    paginatedScoreDefinitionsData: {},
    paginatedScoreDefinitionsisError:false,
    paginatedScoreDefinitionsErrorMessage:null,
  },
  reducers: {
    resetAllCustomDecisionCreditScore: state => {
      state.customDecisionCreditScores = { };
      state.particularDecisionCreditScore = { };
      state.particularDecisionCreditScoresStatus = FetchDataStatus.INITIAL
      state.customDecisionCreditScoresStatus = FetchDataStatus.INITIAL;
      state.customDecisionCustomScoreCards = [];
      state.customDecisionCreditScoreStatus = FetchDataStatus.INITIAL;
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomDecisionCreditScore: state => {
      state.customDecisionCustomScoreCards = [];
      state.customDecisionCreditScoreStatus = FetchDataStatus.INITIAL;
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomUpdateCreditScoreStatus: state => {
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetPaginationDefinition: state => {
      state.paginatedScoreDefinitionsState =FetchDataStatus.INITIAL;
      state.paginatedScoreDefinitionsData = {};
      state.paginatedScoreDefinitionsErrorMessage = null;
      state.paginatedScoreDefinitionsisError = false
    }
  },
  extraReducers(builder) {
    builder.addCase(getAllCustomDecisionCreditScore.pending, (state) => {
      state.customDecisionCreditScoresStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllCustomDecisionCreditScore.fulfilled, (state, action) => {      
      state.customDecisionCreditScoresStatus = FetchDataStatus.SUCCESS;
      state.customDecisionCreditScores = action.payload;
    })
    .addCase(getAllCustomDecisionCreditScore.rejected, (state, action) => {
      state.customDecisionCreditScoresStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getCustomDecisionScoreCardDefinitions.pending, (state) => {
      state.customDecisionCreditScoreStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getCustomDecisionScoreCardDefinitions.fulfilled, (state, action) => {      
      state.customDecisionCreditScoreStatus = FetchDataStatus.SUCCESS;
      state.customDecisionCustomScoreCards = action.payload;
    })
    .addCase(getCustomDecisionScoreCardDefinitions.rejected, (state, action) => {
      state.customDecisionCreditScoreStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateCustomDecisionCreditScoreAsync.pending, (state) => {
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.FETCHING;
    })
    .addCase(updateCustomDecisionCreditScoreAsync.fulfilled, (state) => {      
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.SUCCESS;
    })
    .addCase(updateCustomDecisionCreditScoreAsync.rejected, (state, action) => {
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getAllCustomDecisionEngineScores.pending, (state) => {
      state.customDecisionStatementStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllCustomDecisionEngineScores.fulfilled, (state, action) => {
      state.customDecisionStatementStatus = FetchDataStatus.SUCCESS;
      state.customDecisionStatements = action.payload;
    })
    .addCase(getAllCustomDecisionEngineScores.rejected, (state, action) => {
      state.customDecisionStatementStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    builder.addCase(getParticularCustomDecisionCreditScore.pending, (state) => {
      state.particularDecisionCreditScoresStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getParticularCustomDecisionCreditScore.fulfilled, (state, action) => {      
      state.particularDecisionCreditScoresStatus = FetchDataStatus.SUCCESS;
      state.particularDecisionCreditScore = action.payload;
    })
    .addCase(getParticularCustomDecisionCreditScore.rejected, (state, action) => {
      state.particularDecisionCreditScoresStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })



    builder.addCase(getPaginatedScoreCardDefinitions.pending, (state) => {
      state.paginatedScoreDefinitionsState = FetchDataStatus.FETCHING;
    })
    .addCase(getPaginatedScoreCardDefinitions.fulfilled, (state, action) => {      
      state.paginatedScoreDefinitionsState = FetchDataStatus.SUCCESS;
      state.paginatedScoreDefinitionsData = action.payload;
    })
    .addCase(getPaginatedScoreCardDefinitions.rejected, (state, action) => {
      state.paginatedScoreDefinitionsState = FetchDataStatus.FAILURE;
      state.paginatedScoreDefinitionsisError = true;
      state.paginatedScoreDefinitionsErrorMessage = action.error.message;
    })
  }
});

export const { resetCustomDecisionCreditScore, resetCustomUpdateCreditScoreStatus, resetAllCustomDecisionCreditScore,resetPaginationDefinition } = customDecisionCreditScoreSlice.actions;

export const selectCustomDecisionCreditScoreState = state => state.customDecisionCreditScore;

export default customDecisionCreditScoreSlice.reducer;