import React, { useEffect, useState } from "react";
import {
  useSelector,
  useDispatch
} from 'react-redux';
import './Modal.css';
import { 
  updateWalletFunds, 
  selectWalletState, 
  getPaymentGateways, 
  initiatePayment,
  resetInitializePayment,
  resetWallet
} from '../../redux/Slices/Wallet';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
import Spinner from '../../svg/Spinner/Spinner';
import Utils from "../../library/Utils";
import { useForm } from "react-hook-form";
import { usePaystackPayment } from 'react-paystack';

function Payment(props) {
  let component = null;

  const env = window._jsenv || process.env;
  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const user = props.user;
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const wallet = useSelector(selectWalletState);
  const configuration = props.configuration;
  const [paymentKey, setPaymentKey] = useState(null);
  const [amount, setAmount] = useState(null);
  const [initializePayment, setInitializePayment] = useState(false);

  const componentProps = {
    email: user.email,
    amount: amount,
    currency: configuration.currency.symbol,
    metadata: {
      name: user.firstName
    },
    publicKey: env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };

  const initializePaystack = usePaystackPayment(componentProps);

  const onSuccess = (reference) => {
    dispatch(resetInitializePayment());
    handlePaystackCallback(reference);
  };

  const onClose = () => {
    dispatch(resetWallet());
    handleCancelPaystack();
  }

  const onSubmit = (formData) => {
    dispatch(initiatePayment({ 
      accessToken: accessToken,
      userKey: user.key,
      email: user.email,
      amount: formData.amount,
      paymentGatewayKey: 1
    }));

    setAmount(parseFloat(formData.amount) * 100);
  }

  const handleCancelPaystack = () => {
    setInitializePayment(false);
    reset();
    props.close();
  }

  const handlePaystackCallback = (reference) => {
    setInitializePayment(false);
    reset();

    dispatch(updateWalletFunds({
      accessToken: accessToken,
      reference: reference,
      paymentKey: paymentKey
    }));
  }

  if(wallet.updateWalletFundsStatus === FetchDataStatus.INITIAL) {
    if(initializePayment === true && wallet.initiatePaymentStatus === PostDataStatus.SUCCESS) {
      component = <Spinner />;
      initializePaystack(onSuccess, onClose);
    } else {
      component = <div className="modal-dialog payment-main-body">
        <div className="modal-header">
          <h2 className="detail-header">Fund Wallet</h2>
          <div className="close" onClick={props.close}>
            <img src="/assets/icons/close-color.svg" alt="Close" />
          </div>        
        </div>
        <div className="modal-body-container payment-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="payment-field-container">
              <label>Amount</label>
              <div className="input-container">
                <span className="input-span-currency">
                  {Utils.displayCurrencySymbol(configuration.currency.format, configuration.currency.symbol)}
                </span>
                <input type={'text'} {...register("amount", 
                  { 
                    required: "Amount is required", 
                    pattern: {
                      value: /^\d{0,5}\.?[0-9]{0,2}$/,
                      message: "Amount entered is not a number"
                    },
                    validate: (value) => value >= configuration?.minimumAmount || `Amount must be at least ${(Number(configuration?.minimumAmount))?.toLocaleString()}`
                  })} />
              </div>
              <span className="payment-input-error-span error-msg">
                {errors.amount?.message}
              </span>
            </div>
            <button type="submit" className="button-solid-darker payment-button">Proceed</button>
          </form>
        </div>
      </div>
    } 
  } else if(wallet.updateWalletFundsStatus === FetchDataStatus.FETCHING) {
    component = <div className="modal-dialog payment-main-body">
      <div className="modal-header">
        <h2 className="detail-header">Payment Successful</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>
      </div>
      <div className="centered-container">
        <Spinner />
        <p>Updating your wallet...</p>
      </div>
    </div>
  } else if(wallet.updateWalletFundsStatus === FetchDataStatus.SUCCESS) {
    component = <div className="modal-dialog payment-main-body">
      <div className="modal-header">
        <h2 className="detail-header">Wallet Updated</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>
      </div>
      <div className="centered-container">
        <p>Your wallet was updated</p>
      </div>
    </div>
  } else if(wallet.updateWalletFundsStatus === FetchDataStatus.FAILURE) {
    component = <div className="modal-dialog payment-main-body">
      <div className="modal-header">
        <h2 className="detail-header">Payment Failed</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>
      </div>
      <div className="centered-container">
        <p>Your payment has failed. Please contact us for help solving this issue.</p>
      </div>
    </div>
  }

  useEffect(() => {
    if(wallet.initiatePaymentStatus === PostDataStatus.SUCCESS && wallet.updateWalletFundsStatus === FetchDataStatus.INITIAL
      && initializePayment === false) {
      setPaymentKey(wallet.initiatePayment.paymentKey);
    
      if(initializePayment === false) {
        setInitializePayment(true);
      }
    }

    if(wallet.providersStatus === FetchDataStatus.INITIAL) {
      dispatch(getPaymentGateways(accessToken));
    }
  }, [wallet, initializePayment])

  return (
    component
  )
}
export default Payment;