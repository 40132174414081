import { createSlice } from "@reduxjs/toolkit";

import { fetchParameterActionValuesAsync } from "./thunks";

const initialState = {
  data: null,
  status: "idle",
};

export const parameterActionValueSlice = createSlice({
  name: "parameterActionValues",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchParameterActionValuesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchParameterActionValuesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchParameterActionValuesAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});
