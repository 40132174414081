import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectKycEntireDataState,
        getAlienId,
        resetAlienstatus,
        resetDataStatus} from '../../../../../../redux/Slices/IdVerificationKenya'
import { PostDataStatus } from '../../../../../../library/Variables'
import errorImage from '../../../../../../assests/icons/errorImage.png'
import { getReportDetail,resetReport } from '../../../../../../redux/Slices/IdVerificationKenya'
import { getReportDetailKyb,resetReportKyb } from '../../../../../../redux/Slices/IdVerificationKenyaKyb'
import './naiModal.css'


function AlienIdModal({accessToken,keyFromRoute,setKycToShow,inputStyle,setViewReport,customStyles,setVerificationType}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const onSubmit = (formData) => {
  const obj = {idNumber:formData.inputName,
                statementKey: keyFromRoute,
                accessToken}
             
    dispatch(getAlienId(obj))
  }


  const getDetails = (token,uuid,type)=>{

    setKycToShow(null);
    setIsOpen(false);
    dispatch(resetDataStatus());
    dispatch(resetAlienstatus())

    if(['CAC','TIN'].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    }
    else if(['IDNUMBER',
            'ALIENIDNUMBER',
            'DRIVER_LICENCE_KENYA',
            'KRAPIN',
            'PLATE_NUMBER',
            'PHONE_NUMBER',
            'ACCOUNT_NUMBER'
             ].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReport())
        dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
}

  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p style={{fontSize:'23px',fontWeight:'700'}}>Alien ID Verification</p>
       <div style={{}}>
           <p>Input ID Number</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);setKycToShow(null);dispatch(resetAlienstatus())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.alienExtractionErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);setKycToShow(null);dispatch(resetAlienstatus())}}>Cancel</span>
           
       </div>
  </div>

const ModalSuccess = <div className='modal-success'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <p style={{textAlign:'center',fontWeight:'700'}}>Successfull!!</p>
        </div>
        <p style={{textAlign:'center'}}>Alien ID Extraction Completed</p>

        <div className='modal-initial-button-hold'>
              <span className='close' onClick={()=>getDetails(accessToken,kycState?.alienExtraction?.uuid,
                                                            kycState.alienExtraction?.type)}>Close</span>
              
        </div>
    </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.alienExtractionStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.alienExtractionStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.alienExtractionStatus===PostDataStatus.SUCCESS){
            setViewReport(false)
            setModal(ModalSuccess)
        }

        if(kycState.alienExtractionStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Alien ID Modal" >
                {modal}
    </Modal>
  )
}

export default AlienIdModal