import React, { useState } from "react";
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import List from "../../../components/List/List";

function MobileAnalytics(props) {
  let analyticsContent = null;
  let atmListContent = [];

  const analytics = props.analytics; 
  const configuration = props.configuration;
  const [isLoading, setLoading] = useState(true);

  if(!Utils.isFieldEmpty(analytics)) {
    if(isLoading === true) {
      setLoading(false);
    }

    if(!Utils.isFieldEmpty(analytics.atmLocations)) {
      (analytics.atmLocations).forEach(element => {
        atmListContent.push({ detail: element })
      })
    }

    let incomeContent = <div className="mb-account-analytics-section mb-income">
      <h2 className="detail-header">Income</h2>
      <DetailsCard title="Salary" detailContent={ <List listClass={'st-details-list'} 
        listContainerClass={'st-details-list-main-container'} detailClass="detail-header" listContent={[
          { 
            header: 'Average predicted salary', 
            detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.averagePredictedSalary)
          },
          { 
            header: 'Last salary payment date', 
            detail: Utils.formatLongDate(analytics.lastDateOfSalaryPayment) 
          }
        ]} />
      } />
    </div>;      
    
    let spendContent = <div className="mb-account-analytics-section mb-spend">
      <h2 className="detail-header">Spend</h2>
      <DetailsCard title="ATM Locations" cardID="st-atm-list" detailClassName="st-atm-list-container scrollbar"
        detailContent={ <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
          listContent={atmListContent} listRowClass={'list-row-two-colored'} />} 
      />
    </div>;
    
    let patternContent = <div className="mb-account-analytics-section mb-pattern">
      <h2 className="detail-header">Pattern</h2>
      <div className="mb-account-analytics-double-cards-container">
        <DetailsCard title="Card Requests" subtitle={Utils.checkNull(analytics.numberOfCardRequests)} />
        <DetailsCard title="Return Cheque" subtitle={Utils.checkNull(analytics.returnCheque)} />
      </div>
    </div>;

    let behavioralContent = <div className="mb-account-analytics-section mb-behavioral">
      <h2 className="detail-header">Behavioral</h2>
      <div className="mb-account-analytics-double-cards-container">
        <DetailsCard detailClassName="mb-account-section-double-list" detailContent={<>
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} title="Gambling"
            detailClass="detail-header" listContent={[
              { 
                header: 'Gambling Rate', 
                detail: Utils.formatPercentage(analytics.gamblingRate) 
              },
              { 
                header: 'Avgerage Monthly Spend On Gambling', 
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.averageMonthlySpendOnGambling),
              },
              { 
                header: 'Total Spend On Gambling',
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.totalSpendOnGambling), 
              }
            ]} />
          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} title="Account"
            detailClass="detail-header" listContent={[
              { header: 'Account Activity', detail: Utils.formatPercentage(analytics.accountActivity) }
            ]} />
        </>} />
        <DetailsCard title="Loans" detailContent={
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} detailClass="detail-header"
              listContent={[
              { 
                header: 'Average Monthly Loan Amount', 
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.averageMonthlyLoanAmount),
              },
              { 
                header: 'Total Loan Amount', 
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.totalLoanAmount),
              },
              { 
                header: 'Credit Loan Transaction(s)', 
                detail: Utils.checkNull(analytics.numberOfCreditLoanTransactions)
              },
              { 
                header: 'Average Monthly Loan Repayment Amount', 
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.averageMonthlyLoanRepaymentAmount),
              },
              { 
                header: 'Total Loan Repayment Amount', 
                detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.totalLoanRepaymentAmount),
              },
              { 
                header: 'Debit Repayment Transaction(s)', 
                detail: Utils.checkNull(analytics.numberOfDebitRepaymentTransactions)
              }
            ]} />
          } />
      </div>
    </div>;

    let cashFlowContent = <div className="mb-account-analytics-section mb-cash">
      <h2 className="detail-header">Cash Flow</h2>
      <DetailsCard detailContent={ 
        <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} detailClass="detail-header"
          listContent={[
          { 
            header: "Total Credit Turnover", 
            detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.totalCreditTurnover)
          },
          { 
            header: "Total Debit Turnover", 
            detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, analytics.totalDebitTurnover)
          }
        ]} />
      } />
    </div>;

    analyticsContent = <>
      {incomeContent}
      {!Utils.isFieldEmpty(atmListContent) &&
        spendContent
      }      
      {patternContent}
      {behavioralContent}
      {cashFlowContent}
    </>
  }

  return (
    <div className="mb-account-analytics">
      {isLoading === true 
        ? <Spinner />
        : analyticsContent
      }
    </div>
  )
}

export default MobileAnalytics;