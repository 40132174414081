import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';
let download = require('downloadjs');

export const downloadWalletUsageAsync = createAsyncThunk('downloadWalletUsage', async (params) => {
  setDownloadingTrue();
  const statement = await client.getBlob(`/wallet/download/usage-report`,
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, "Wallet-Usage.csv", "text/csv");
    });
  });

  return statement;
});

export const downloadWalletUsageSlice = createSlice({
  name: 'downloadWalletUsage',
  initialState: {
    walletUsage: null,
    walletUsageStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    isDownloading: false,
  },
  reducers: {
    resetDownloadWalletUsage: state => {
      state.walletUsage = null;
      state.walletUsageStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
      state.isDownloading = false;
    },
    setDownloadingFalse: state => {
      state.isDownloading = false;
    },
    setDownloadingTrue: state => {
      state.isDownloading = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(downloadWalletUsageAsync.pending, (state) => {
      state.walletUsageStatus = FetchDataStatus.FETCHING;
      state.isDownloading = true;
    })
    .addCase(downloadWalletUsageAsync.fulfilled, (state, action) => {      
      state.walletUsageStatus = FetchDataStatus.SUCCESS;
      state.walletUsage = action.payload;
      state.isDownloading = true;
    })
    .addCase(downloadWalletUsageAsync.rejected, (state, action) => {
      state.walletUsageStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
      state.isDownloading = false;
    })
  }
});

export const { resetDownloadWalletUsage, setDownloadingFalse, setDownloadingTrue } = downloadWalletUsageSlice.actions;

export const selectDownloadWalletUsageStatus = state => state.downloadWalletUsage;

export default downloadWalletUsageSlice.reducer;