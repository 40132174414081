import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { WebHookClient } from "../../../../api/webHookApi";
import { FetchDataStatus,PostDataStatus } from "../../../../library/Variables";



 export const linkUsersToTenant = createAsyncThunk('tenantPerUser', async (params) => {

   let data = params.data
   data = JSON.stringify(data)
   const linkedUser = await WebHookClient.updateDetails('/onboard/link-new-tenant-per-specific-user',data,
    {
      "headers":
      {
       "Authorization": "Bearer " + params.accessToken,
       'Content-Type': 'application/json'
      }
    });

    return linkedUser;
   }
 );


 export const fetchAllClients = createAsyncThunk('tenantPerUser/allClients', async (params) => {

  const linkedUser = await client.get('/platformuser/all',
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
     }
   });

   return linkedUser.data;
  })


export const tenantPerUserSlice = createSlice({
  name: 'tenantPerUser',
  initialState: {
    linkedTenantData: { },
    linkedTenantStatus: FetchDataStatus.INITIAL,
    linkedTenantIsError: false,
    linkedTenantErrorMessage: null,


    allTenantUsersData: {},
    allTenantUserStatus: FetchDataStatus.INITIAL,
    allTenantUserIsError: false,
    allTenantUserErrorMessage: null
  },
  reducers: {
    resetLinkedTenant: state => {
      state.linkedTenantData = { };
      state.linkedTenantStatus = PostDataStatus.INITIAL;
      state.linkedTenantIsError = false;
      state.linkedTenantErrorMessage = null
    },
    
    resetAllClientsFetch: state => {
      state.allTenantUsersData = { };
      state.allTenantUserStatus = FetchDataStatus.INITIAL;
      state.allTenantUserIsError = false;
      state.allTenantUserErrorMessage = null
    }
  },
  extraReducers(builder) {
    builder.addCase(linkUsersToTenant.pending, (state) => {
      state.linkedTenantStatus = PostDataStatus.FETCHING;
    })
    .addCase(linkUsersToTenant.fulfilled, (state, action) => {      
      state.linkedTenantStatus = PostDataStatus.SUCCESS;
      state.linkedTenantData = action.payload;
    })
    .addCase(linkUsersToTenant.rejected, (state, action) => {
      state.linkedTenantStatus = PostDataStatus.FAILURE;
      state.linkedTenantIsError = true;
      state.linkedTenantErrorMessage = action.error.message;
    })

    builder.addCase(fetchAllClients.pending, (state) => {
      state.allTenantUserStatus = FetchDataStatus.FETCHING;
    })
    .addCase(fetchAllClients.fulfilled, (state, action) => {      
      state.allTenantUserStatus = FetchDataStatus.SUCCESS;
      state.allTenantUsersData = action.payload;
    })
    .addCase(fetchAllClients.rejected, (state, action) => {
      state.allTenantUserStatus = FetchDataStatus.FAILURE;
      state.allTenantUserIsError = true;
      state.allTenantUserErrorMessage = action.error.message;
    })
 
  }
});

export const { resetLinkedTenant,resetAllClientsFetch } = tenantPerUserSlice.actions;

export const selectTenantUsersState = state => state.tenantPerUser;

export default tenantPerUserSlice.reducer;