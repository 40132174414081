import React from 'react'
import './TransactionOverviewCard.css'

function TransactionOverviewCard({topDescription, topValue, bottomDescription, bottomValue}) {
  return (
    <div className='transaction-overview-wrap'>
        <div className='image-wrap'>
            <img src='/assets/icons/wallet-3.svg' />
        </div>

        <div className='text-content-wrap'>
            <div className='top-text-section'>
                <p>{topDescription}</p>

                <h4>{topValue}</h4>
            </div>

            <div className='bottom-text-section'>
                <p>{bottomDescription}</p>

                <h4>{bottomValue}</h4>
            </div>
        </div>
    </div>
  )
}

export default TransactionOverviewCard