import { useFormContext } from "react-hook-form";

const ErrorDisplay = ({ fieldName }) => {
  const methods = useFormContext();

  if (!methods) return null;

  const { formState } = methods;

  const dirtyField = formState.errors[fieldName];

  return (
    <div style={{ color: "red", fontSize: "14px" }}>
      <span>{dirtyField?.message}</span>
    </div>
  );
};

export default ErrorDisplay;
