import React, { useMemo } from "react";
import { Chart as 
  ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import List from '../../../components/List/List';
import DetailsCard from '../../../components/DetailsCard/DetailsCard';
import Utils from "../../../library/Utils";
import { Table } from "../../../components/Table/Table";

function Spend(props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
  );

  let data = [];
  let avgPercentSorted = [];
  let totPercentSorted = [];
  let avgLabelSorted = [];
  let totLabelSorted = [];
  let atmListContent = [];

  const configuration = props.configuration;
  const spend = props.data;

  const theadersDetailsSpend = useMemo(() => [
    { 
      Header: 'Expense',
      accessor: 'expense'
    },
    { 
      Header: 'Avg. Monthly Spending',
      accessor: 'averageMonthlySpending'
    },
    {
      Header: 'Total Spending',
      accessor: 'totalSpeding'
    }], []
  )

  const barChartOptions = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let dataset = context.dataset.data;
            let currentValue = dataset[context.dataIndex];
            let percentage = Utils.formatDecimalPercentage(currentValue);

            return percentage;
          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        min: 0,
        max: 1,
        ticks: {
          callback: function (value) {
            return Utils.formatPercentage(value);
          }
        }
      },
      y: {
        grid: {
          display: false
        }
      },
    }
  }

  let dataMap = [
    {
      label: 'Airtime And Data',
      average: Utils.checkNull(spend.averageMonthlySpendOnAirtimeAndData),
      total: Utils.checkNull(spend.totalSpendOnAirtimeAndData)
    },
    {
      label: 'ATM Withdrawls And POS',
      average: Utils.checkNull(spend.averageMonthlySpendOnAtmAndPOS),
      total: Utils.checkNull(spend.totalSpendOnAtmAndPOS)
    },
    {
      label: 'Charges And Stamp Duty',
      average: Utils.checkNull(spend.averageMonthlySpendOnChargesAndStampDuty),
      total: Utils.checkNull(spend.totalSpendOnChargesAndStampDuty)
    },
    {
      label: 'Crypto',
      average: Utils.checkNull(spend.averageMonthlySpendOnCrypto),
      total: Utils.checkNull(spend.totalSpendOnCrypto)
    },
    {
      label: 'Entertainment',
      average: Utils.checkNull(spend.averageMonthlySpendOnEntertainment),
      total: Utils.checkNull(spend.totalSpendOnEntertainment)
    },
    {
      label: 'Gambling',
      average: Utils.checkNull(spend.averageMonthlySpendOnGambling),
      total: Utils.checkNull(spend.totalSpendOnGambling)
    },
    {
      label: 'Health',
      average: Utils.checkNull(spend.averageMonthlySpendOnHealth),
      total: Utils.checkNull(spend.totalSpendOnHealth)
    },
    {
      label: 'Hospitality And Food',
      average: Utils.checkNull(spend.averageMonthlySpendOnHospitalityAndFood),
      total: Utils.checkNull(spend.totalSpendOnHospitalityAndFood)
    },
    {
      label: 'Insurance',
      average: Utils.checkNull(spend.averageMonthlySpendOnInsurance),
      total: Utils.checkNull(spend.totalSpendOnInsurance)
    },
    {
      label: 'International Transactions',
      average: Utils.checkNull(spend.averageMonthlySpendOnInternationalTransactions),
      total: Utils.checkNull(spend.totalSpendOnInternationalTransactions)
    },
    {
      label: 'Online And Web',
      average: Utils.checkNull(spend.averageMonthlySpendOnOnlineAndWeb),
      total: Utils.checkNull(spend.totalSpendOnOnlineAndWeb)
    },
    {
      label: 'Rent',
      average: Utils.checkNull(spend.averageMonthlySpendOnRent),
      total: Utils.checkNull(spend.totalSpendOnRent)
    },
    {
      label: 'Savings and Investments',
      average: Utils.checkNull(spend.averageMonthlySpendOnSavingsAndInvestments),
      total: Utils.checkNull(spend.totalSpendOnSavingsAndInvestments)
    },
    {
      label: 'Transfer',
      average: Utils.checkNull(spend.averageMonthlySpendOnTransfer),
      total: Utils.checkNull(spend.totalSpendOnTransfer)
    },
    {
      label: 'Transportation',
      average: Utils.checkNull(spend.averageMonthlySpendOnTransportation),
      total: Utils.checkNull(spend.totalSpendOnTransportation)
    },
    {
      label: 'Travel',
      average: Utils.checkNull(spend.averageMonthlySpendOnTravel),
      total: Utils.checkNull(spend.totalSpendOnTravel)
    },
    {
      label: 'USSD',
      average: Utils.checkNull(spend.averageMonthlySpendOnUSSD),
      total: Utils.checkNull(spend.totalSpendOnUSSD)
    },
    {
      label: 'Utilities',
      average: Utils.checkNull(spend.averageMonthlySpendOnUtilities),
      total: Utils.checkNull(spend.totalSpendOnUtilities)
    }
  ]

  if (configuration.featureFlags.isSpendOnOthersVariableEnabled === true) {
    dataMap.push({
      label: 'Others',
      average: Utils.checkNull(spend.averageMonthlySpendOnOthers),
      total: Utils.checkNull(spend.totalSpendOnOthers)
    })
  }

  if (configuration.featureFlags.isAgentLocationAnalysisEnabled === true) {
    dataMap.push({
      label: 'Agent Transactions',
      average: Utils.checkNull(spend.averageMonthlyTransactionsViaAgents),
      total: Utils.checkNull(spend.totalTransactionsViaAgents)
    })
  }

  const divide = (number, total) => {
    let result = 0;

    result = number / total;

    if(Number.isNaN(result) === true) {
      result = 0;
    }

    return result;
  }

  dataMap.sort((a, b) => a.label - b.label);

  dataMap.forEach(field => {
    data.push({
      expense: field.label, 
      averageMonthlySpending: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, field.average), 
      totalSpeding: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, field.total),
      avgPercentDifference: divide(field.average, Utils.checkNull(spend.averageMonthlyTotalExpenses)),
      totPercentDifference: divide(field.total, Utils.checkNull(spend.totalExpenses))
    })
  });

  // Sort Array for Total
  data.sort((firstItem, secondItem) => secondItem.totPercentDifference - firstItem.totPercentDifference);

  // Make new set of labels and percent once sorted
  //Total
  data.forEach(element => {
    totPercentSorted.push(element.totPercentDifference);
    totLabelSorted.push(element.expense);
  })

  // Sort Array for Average
  data.sort((firstItem, secondItem) => secondItem.avgPercentDifference - firstItem.avgPercentDifference);

  // Make new set of labels and percent once sorted
  // Average
  data.forEach(element => {
    avgPercentSorted.push(element.avgPercentDifference);
    avgLabelSorted.push(element.expense);
  });

  if(!Utils.isFieldEmpty(spend.atmLocations)) {
    (spend.atmLocations).forEach(element => {
      atmListContent.push({ detail: element })
    })
  }

  return (
    <div className="st-details-card-container">
      <div className="st-details-card-container-row st-analytics-spend-top-row-container">
        <div className='st-analytics-spend-top-row'>
          <DetailsCard title={Utils.capitalizeFirstLetter(spend.mostFrequentSpendCategory.replace(/_/g, " "))} titleClass={"detail-header"} 
            subtitle={"Most Frequent Spend Category"} visualContent={<img src="/assets/icons/money-send-color.svg" alt="" />} />
          <DetailsCard title={Utils.capitalizeFirstLetter(spend.mostFrequentExpense)} titleClass={"detail-header"} 
            subtitle={"Most Frequent Expense"} visualContent={<img src="/assets/icons/money-send-color.svg" alt="" />} />
          <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, spend.mostFrequentExpenseAmount)}  
             titleClass={"detail-header"} subtitle={"Most Frequent Expense Amount"} 
             visualContent={<img src="/assets/icons/money-send-color.svg" alt="" />} />
        </div>
        <div className='st-analytics-spend-top-row'>
          <DetailsCard detailContent={
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} headerClass={"detail-header"}
              listContent={[
                {
                  header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(spend.highestSpend)),
                  detail: 'Highest Spend'
                },
                {
                  header: Utils.formatLongDateMonth(spend.monthWithHighestSpend),
                  detail: 'Occured On'
                }
              ]} 
            />} 
          />
          <DetailsCard detailContent={
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} headerClass={"detail-header"}
              listContent={[
                {
                  header: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, spend.averageMonthlyTotalExpenses)),
                  detail: 'Average Monthly Expenses'
                },
                {
                  header: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, spend.averageMonthlyRecurringExpense)),
                  detail: 'Average Monthly Recurring Expense'
                }
              ]} 
            />} 
          />
          <DetailsCard detailContent={
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} headerClass={"detail-header"}
              listContent={[
                {
                  header: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, spend.totalExpenses)),
                  detail: 'Total Expenses'
                },
                {
                  header: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, spend.totalRecurringExpense)),
                  detail: 'Total Recurring Expense'
                }
              ]} 
            />} 
          />
        </div>
      </div>
      <div className='st-details-card-container-row st-spend-table-plus-atm'>
        <div className="st-details-scroll-card">      
          <div className='cards details-table-container'>
            <h2 className="detail-header">Expenses</h2>
            <Table data={data} columns={theadersDetailsSpend} pageSize={[50]} tableExtra={true} />
          </div> 
        </div>
        {atmListContent.length > 0 &&
          <DetailsCard title="ATM Locations" titleClass="detail-header" cardID="st-atm-list" 
            detailClassName="st-atm-list-container scrollbar" headerExtra={<img src="/assets/icons/location-color.svg" alt="" />}
            detailContent={<List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'}
            listContent={atmListContent} listRowClass={'list-row-two-colored'} />}
          />
        }       
      </div>
      <div className="st-details-card-container-row st-details-graphs-row">
        <DetailsCard title="Monthly Average Spend" titleClass="detail-header" detailContent={
          <div className='details-stacked-bar-container scrollbar'>
            <div className='stacked-bar'>
              <Bar options={barChartOptions} data={
                {
                  labels: avgLabelSorted,
                  datasets: [
                    {
                      label: 'Percent of Average Monthly Spend',
                      data: avgPercentSorted,
                      borderRadius: 6,
                      backgroundColor: "#2A38A4"
                    }
                  ]
                }} 
              />
            </div>
          </div>}
        />
        <DetailsCard title="Total Statement Spend" titleClass="detail-header" detailContent={
          <div className='details-stacked-bar-container scrollbar'>
            <div className='stacked-bar'>
              <Bar options={barChartOptions} data={
                {
                  labels: totLabelSorted,
                  datasets: [
                    {
                      label: 'Percent of Total Statement Spend',
                      data: totPercentSorted,
                      borderRadius: 6,
                      backgroundColor: "#2A38A4"
                    }
                  ]
                }} 
              />
            </div>
          </div>}
        />
      </div>
    </div>
  )
}

export default Spend;