import React from 'react'
import Utils from '../../../../library/Utils'
import lodash from 'lodash'
import editLoanIcon from '../../../../assests/icons/editLoanIcon.png'

function ClientCard(props) {
    const filterValue = props.filterValue
    let data = props.data
    const configuration = props.configuration
    const setSectionType = props.setSectionType
    const setEditModalOpen= props.setEditModalOpen
    const setSlicedData = props.setSlicedData
    const setSectionName = props.setSectionName
    const approvalStatus = props.approvalStatus
    const permissions= props.permissions

    let header
    data = data.filter(value=>value.sectionName===filterValue)
    const listOfHeaders = ['CLIENT_INFO','LOAN_DETAILS','RESIDENTIAL_ADDRESS','BUSINESS_INFO']
    if(listOfHeaders.includes(filterValue)){
        if(filterValue==='CLIENT_INFO'){
            header = 'Client Information'
        }
        if(filterValue==='RESIDENTIAL_ADDRESS'){
            header = 'Residential Address'
        }
        if(filterValue==='LOAN_DETAILS'){
            header = 'Loan Details'
        }
        if(filterValue==='BUSINESS_INFO'){
            header = 'BUSINESS DETAILS'
        }
    }

  const openEditSection = ()=>{
    if(permissions.updateLoanApplication){

        setSectionType(header)
        setSlicedData(data)
        setEditModalOpen(true)
        setSectionName(filterValue)
    }
  }

  return (
    <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
    <>
    <section style={{display:"flex",alignItems:"Center-",justifyContent:"space-between"}}>
        <p style={{margin:'15px 0px',fontSize:'20px',fontWeight:'700',color:'rgba(42, 56, 164, 1)'}}>{header}</p>
        {approvalStatus==='PENDING' && <span onClick={()=>openEditSection()} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
                    {permissions.updateLoanApplication && <img src={editLoanIcon}/>}
                </span>}
    </section>
    
    {
        data.map((value,index)=>{
            if(value.displayType === 'CURRENCY'){
                return <section key={index} style={{display:'flex',justifyContent:'space-between',margin:'20px 0px'}}>
                            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(106, 113, 125, 1)'}}>{value.friendlyName}</span>
                            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(19, 15, 38, 1)'}}>
                                {value.value?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, value.value):'---'}
                            </span>
                        </section>
            }

            else{
                return <section style={{display:'flex',justifyContent:'space-between',margin:'20px 0px'}}>
                            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(106, 113, 125, 1)'}}>{value.friendlyName}</span>
                            <span style={{fontSize:'16px',fontWeight:'500',color:'rgba(19, 15, 38, 1)'}}>
                                {value.value?value.value:'---'}
                            </span>
                        </section>
            }
        }
        )
    }
    </>
    </div>
  )
}

export default ClientCard