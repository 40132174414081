import React, { useState, useRef, useEffect } from "react";
import "./Form.css";
import Utils from "../../library/Utils";
import { useForm } from "react-hook-form";

function Form(props) {
  const accessToken = props.accessToken;
  const fields = props.searchFormFields;
  const childRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dateSelection, setDateSelection] = useState({ label: "Created Date", isActive: false });
  const [activeField, setActiveField] = useState({});
  const { register, handleSubmit, reset } = useForm();

  const inputFields = fields.map((field, i) => {
    if(field.type === "SELECT") {
      return <div className={"form-inputs " + field.className} key={i + field.param} tabIndex={i}>
        <select {...register(field.param)} className="dropdown-select-container"
          id={activeField[field.param] ? "active-select" : null} >
            <option hidden value={""}>{field.hiddenOptionLabel}</option>
            {field.options.map((option, j) => {
              if(option.name) {
                return <option value={option.name} key={i + "-" + j + option.label}>{option.label}</option>
              }else {
                return <option value={option.scorecardDefinitionKey} key={i + "-" + j + option.scorecardDefinitionName}>{option.scorecardDefinitionName}</option>
              }

              })
            }
        </select>
      </div>
    } else if(field.type === "DATE_RANGE") {
      return <div className="dropdown-body form-date-range-container" ref={childRef} key={i + field.param} tabIndex={i}>
        <div onClick={() => setIsOpen(!isOpen)} className="dropdown-select-container" 
          id={dateSelection.isActive ? "active-select" : null} >
          <p>{dateSelection.label}</p>
          {isOpen 
            ? <img src="/assets/icons/lift-color.svg" alt="" />
            : <img src="/assets/icons/drop-color.svg" alt="" />
          }
        </div>
        {isOpen &&
          <div className="dropdown-options-container">
            <h3 className="dropdown-header detail-header">Insert date range</h3>
            <div id="dropdown-date-option">
              <div className="dropdown-date-range-container">
                <label>Start date</label>
                <input type="date" className="dropdown-date-inputs" {...register("startDate")} />
              </div>
              <div className="dropdown-date-range-container">
                <label>End date</label>
                <input type="date" className="dropdown-date-inputs" {...register("endDate")} />
              </div>
            </div>
          </div>
        }
      </div>
    } else {
      return <input type={field.type} className={"form-inputs " + field.className} {...register(field.param)} tabIndex={i}
        placeholder={field.placeholder} id={activeField[field.param] ? "active-input" : null} key={i + field.param} />
    }
  })
  
  const onSubmit = (formData) => {
    let activeFields = {
      accessToken: accessToken,
      startDate: formData.startDate,
      endDate: formData.endDate
    }

    for(const property in formData) {
      activeFields[property] = formData[property];
      checkActiveField(property, formData[property])
    }

    if(Utils.isFieldEmpty(formData.endDate) && !Utils.isFieldEmpty(formData.startDate)) {
      setDateSelection({ label: formData.startDate, isActive: true });
    } else if(!Utils.isFieldEmpty(formData.endDate) && Utils.isFieldEmpty(formData.startDate)) {
      setDateSelection({ label: formData.endDate, isActive: true });
    } else if(!Utils.isFieldEmpty(formData.endDate) && !Utils.isFieldEmpty(formData.startDate)) {
      setDateSelection({ label: formData.startDate + " - " + formData.endDate, isActive: true });
    }

    props.parentCallback(activeFields);
  }

  const checkActiveField = (field, value) => {
    if(!Utils.isFieldEmpty(value)) {
      setActiveField(prevState => ({ ...prevState, [field]: true }));
    } 
  }

  const clearForm = () => {
    reset();
    setDateSelection({ label: "Created Date Range", isActive: false });
    setActiveField({});
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (childRef.current && !childRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [childRef]);

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="header">{props.header}</h2>
      {inputFields}
      <div className="form-buttons-container">
        <button className="button-square form-buttons clear"
          onClick={() => clearForm()}>
          Clear
        </button>
        <button type="submit" className="button-square form-buttons search">
          Search
        </button>
      </div>
    </form>
  )
}

export default Form;