const storage = {
  getToken: () => {
    return window.localStorage.getItem(`insightsAccessToken`);
  },
  setToken: (key, token) => {
    window.localStorage.setItem(key, JSON.stringify(token));
  },
  clearToken: (key) => {
    window.localStorage.removeItem(key);
  },
};

export default storage;
