export const monthlyPayReformat = (val)=>{
    //const historyObj = {title:"Name History",data:[]}
    let data = val
    const keyList = Object.keys(data)
    let keyNeeded = []
    let keyBreakDown = []
    let arrangedBreakDown = []
    for (let i =0; i<keyList.length; i++){
        
       if(keyList[i].includes('mH')){
            keyNeeded.push(keyList[i])
       }
    }

    for (let i =0; i<keyNeeded.length; i++){
        for(let j=0;j<keyList.length;j++){
            if((keyNeeded[i].slice(-2)=== keyList[j].slice(-2)) && (keyList[j].length===keyNeeded[i].length-1)){
                const splitList = data[keyNeeded[i]].split(' ')
                const newObj = {year:splitList[0],month:splitList[1],status:data[keyList[j]]}
                keyBreakDown.push(newObj)
            }
        }
     
     }

     for (let i =0; i<keyBreakDown.length; i++){
        
        if(arrangedBreakDown.length===0){
            arrangedBreakDown.push([keyBreakDown[0]])
        }
        else{
            if(keyBreakDown[i].year === keyBreakDown[i-1].year){
              
                arrangedBreakDown[arrangedBreakDown.length-1].push(keyBreakDown[i])
            }
            else{
                arrangedBreakDown.push([keyBreakDown[i]])
            }
        }
     }

  

    return arrangedBreakDown

}