import React from 'react'
import Utils from '../../../../library/Utils'


function ExtraInfoSlice({data}) {
  return (
    <div className='extraInfoParent'>
        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Sub Account Number
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.subAccountNo)?
                         data.subAccountNo:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Currency
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.currency)?
                         data.currency:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Instalment Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.instalmentAmount)?
                         data.instalmentAmount:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Amount Overdue
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.amountOverdue)?
                         data.amountOverdue:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Currrent Balance Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.currentBalanceAmt)?
                         data.currentBalanceAmt:'--'}
                </section>
            </div>

        </section>





        <section className='extraInfoHold'>
            <div className='extraInfoBlock'>
                <section className='title'>
                    Indicator Description
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.indicatorDescription)?
                         data.indicatorDescription:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Repayment Frequency
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.repaymentFrequency)?
                         data.repaymentFrequency:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Opening Balance Amount
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.openingBalanceAmt)?
                         data.openingBalanceAmt:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Current Balance Debit Ind
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.currentBalanceDebitInd)?
                         data.currentBalanceDebitInd:'--'}
                </section>
            </div>

            <div className='extraInfoBlock'>
                <section className='title'>
                    Last Updated Date
                </section>
                <section className='value'>{!Utils.isFieldEmpty(data.lastUpdatedDate)?
                         data.lastUpdatedDate:'--'}
                </section>
            </div>

        </section>
    </div>
  )
}

export default ExtraInfoSlice