import NigeriaIcon from '../assests/svg-icons/nigeriaIcon.svg'
import GhanaIcon from '../assests/svg-icons/ghanaIcon.svg'
import KenyaIcon from '../assests/svg-icons/kenyaIcon.svg'
import MozambiqueIcon from '../assests/svg-icons/mozambiqueIcon.svg'
import RwandaIcon from '../assests/svg-icons/rwandaIcon.svg'
import SierraLeoneIcon from '../assests/svg-icons/sierraLeoneIcon.svg'
import UgandaIcon from '../assests/svg-icons/ugandaIcon.svg'
import ZimbabweIcon from '../assests/svg-icons/zimbabweIcon.svg'
import ZambiaIcon from '../assests/svg-icons/zambiaIcon.svg'
import BotswanaIcon from '../assests/svg-icons/botswanaIcon.svg'
import CanadaIcon from '../assests/svg-icons/canadaIcon.svg'


export const CountryCodes = {
    NIGERIA: "NGA",
    CANADA:	"CAN",
    UGANDA : "UGA",
    ZIMBABWE :	"ZIM",
    RWANDA : "RWA",
    KENYA : "KEN",
    BOTSWANA :	"BWA",
    GHANA :	"GHA",
    ZAMBIA : "ZBM",
    MOZAMBIQUE : "MOZ",
    SIERRA_LEONE: "SLL"
  }



  export const CountryCodesAndFlag = [
    {code:"NGA",
     flag:NigeriaIcon
    },

   {code:"CAN",
    flag:CanadaIcon
    },

    {code:"UGA",
     flag:UgandaIcon
    },

    {code:"ZIM",
     flag:ZimbabweIcon
    },

    {code:"RWA",
     flag:RwandaIcon
    }, 

    {code:"KEN",
     flag:KenyaIcon
    },

    {code:"BWA",
     flag:BotswanaIcon
    },

    {code:"GHA",
     flag:GhanaIcon
    },

    {code:"ZBM",
     flag:ZambiaIcon
    },

    {code:"MOZ",
     flag:MozambiqueIcon
    },

    {code:"SLL",
     flag:SierraLeoneIcon
    }, 
              ]