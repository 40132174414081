import auth0 from "auth0-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { toast } from "react-toastify";
import ChangePassWordModal from "../../../components/Modals/PasswordModals/ChangePasswordModal";
import Utils from "../../../library/Utils";
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables';
import {
  reset,
  resetPasswordAsync,
  selectresetPasswordState
} from '../../../redux/Slices/Auth';
import {
  postChangePassword,
  selectChangePassword
} from "../../../redux/Slices/PasswordCollection/ChangePassword/ChangePasswordClient";
import PasswordInput from "./PasswordInput/PasswordInput";
import './profile.css';

function Profile(props) {
  const dispatch = useDispatch();
  const user = props.user;
  const configuration = props.configuration;
  const accessToken = props.accessToken;
  const resetPassword2 = useSelector(selectresetPasswordState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [changePassword,setChangePassword] = useState(false)
  const { register, formState: { errors }, handleSubmit } = useForm();
  const changePassState = useSelector(selectChangePassword)


  const [auth] = useState(new auth0.WebAuth({
    domain: configuration.auth0.domain,
    clientID: configuration.auth0.clientId,
    responseType: "token id_token"
  }));


  const resetPasswordNew = () => {
    if(resetPassword2.resetPasswordStatus === FetchDataStatus.INITIAL) {
      dispatch(resetPasswordAsync({
        email: user.user.email
      }));
    }
  }

  useEffect(() => {
    if (resetPassword2.resetPasswordStatus === FetchDataStatus.SUCCESS) {
      toast.success("A reset password request has been sent", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(reset());
      }else if(resetPassword2.resetPasswordStatus === FetchDataStatus.FAILURE){
        toast.error(resetPassword.errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        dispatch(reset());
    }
  }, [resetPassword2, dispatch])

  const resetPassword = () => {
    auth.changePassword({
      connection: configuration.auth0.realm,
      email: user.user.email
    }, function (err, resp) {
      if(err) {
        toast.error(err.description, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if(resp) {
        toast.success("A reset password request has been sent", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    })
  }

  function onSubmitLogin(formData){

    if(changePassState.changePassStatus === PostDataStatus.INITIAL){
      const body = {oldPassword: formData.oldPassword,
                  cPassword: formData.cPassword,
                  password:formData.password}
      dispatch(postChangePassword({
        accessToken,
        body
      }))

    }

  }

  useEffect(()=>{
    if(changePassState.changePassStatus===PostDataStatus.FAILURE ){
        setIsOpen(true)
    }

    if(changePassState.changePassStatus===PostDataStatus.SUCCESS){
        setIsOpen(true)
    }
  },[changePassState.changePassStatus])


  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };



  return (
    <div className="settings-main-container profile">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}
          contentLabel="Change Password Modal" >
          <ChangePassWordModal closeModal={closeModal} showLogin={false}/>
      </Modal> 
      <div className='top-section'>
          <span 
              onClick={()=>setChangePassword(false)}
              className={changePassword?'span-view-details':'span-view-password'}>
                  Personal Details
          </span>
          <span>/</span>
          <span 
              onClick={()=>setChangePassword(true)}
              className={changePassword?'span-view-password':'span-view-details'} >
              Change Password
          </span>
      </div>
      {changePassword?<div className="settings-section-container">
        <h2 className="detail-header settings-title">Change Password</h2>
        <p className="text-light settings-subheader settings-subtitle">Change your password</p>
        <hr />
        <form onSubmit={handleSubmit(onSubmitLogin)} className="login-form-container">
            <div className="passwordHolderSect">
              <section>
                  <PasswordInput 
                    register={register} 
                    name={'oldPassword'} 
                    errors={errors} 
                    title={'Current Password'}
                  
                  />
                
              </section>

              <section className='policyTitle'>
            <section className='policyHeader'>Our Password policy includes:</section>
            <ul>
              <li>{`Password must contain minimum length of 
                  ${configuration.passwordPolicyConfiguration.minimumLength}
                  letters.`}
              </li>

              <li>{`Password must contain maximum length of 
                  ${configuration.passwordPolicyConfiguration.maximumLength}
                  letters.`}
              </li>

               <li>{`Password must contain at least
                  ${configuration.passwordPolicyConfiguration.lowercase}
                  lowercase letter.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.passwordPolicyConfiguration.uppercase}
                  uppercase letter.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.passwordPolicyConfiguration.number}
                  number.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.passwordPolicyConfiguration.specialCharacter}
                  special character.`}
              </li>

              {(!Utils.isFieldEmpty(configuration.passwordPolicyConfiguration.duration)  
                && configuration.passwordPolicyConfiguration.duration>0)
                && 
                <li>{`Password would only be valid for a period of 
                    ${configuration.passwordPolicyConfiguration.duration}
                    days.`}
                </li>
              }

              
            </ul>
          </section>

              <section className="confirmPasswordSection">
                  <PasswordInput 
                    register={register} 
                    name={'password'} 
                    errors={errors} 
                    title={'New Password'}
                  
                  />

                  <PasswordInput 
                    register={register} 
                    name={'cPassword'} 
                    errors={errors} 
                    title={'Confirm Password'}
                  
                  />
              </section>
              <div style={{ display: 'flex',justifyContent: 'end'}}>
                <section className="changeButtonHold">
                    <button 
                      type='submit' 
                      className="button-square btn-login">
                      {
                        changePassState.changePassStatus===PostDataStatus.FETCHING 
                        ?'Changing Password...'
                        :'Change Password'
                      }
                      
                    </button>
                </section>
              </div>
            </div>

        </form>
       
      </div>:
      
       <div className="settings-section-container">
        <h2 className="detail-header settings-title">Personal Details</h2>
        <p className="text-light settings-subheader settings-subtitle">Your personal details</p>
        <hr />
        <div className="settings-personal-details-container">
          <div className="settings-field-container">
            <label className="text-darker">First name</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.firstName)}
            </div>
          </div>
          <div className="settings-field-container">
            <label className="text-darker">Last name</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.lastName)}
            </div>
          </div>
          <div className="settings-field-container">
            <label className="text-darker">Email</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.email)}
            </div>
          </div>
          <div className="settings-field-container">
            <label className="text-darker">Phone</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.phone)}
            </div>
          </div>

          <div className="settings-field-container">
            <label className="text-darker">Business User ID</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.businessUserId)}
            </div>
          </div>

          <div className="settings-field-container">
            <label className="text-darker">Branch Number</label>
            <div className="settings-input-field">
              {Utils.checkNull(user.user.branchNumber)}
            </div>
          </div>
        </div>
        
      </div>
      
      }
    </div>
  )
}

export default Profile;