import React from "react";
import { useFieldArray } from "react-hook-form";

export default ({ nestIndex, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `statementBureauAccountData[${nestIndex}].statementBureauAccountPaymentHistory`
  });

  return (
    <>
      {fields.map((item, index) => {
        return <fieldset key={item.id}>
        <h3 style={{ margin: '0' }}>Payment History</h3>
        <div className="bureau-form-section-inputs">
          <div className="bureau-input-container">
            <label>Month</label>
            <select className="inputs account-form-inputs" placeholder="MM"
              {...register(`statementBureauAccountData[${nestIndex}].statementBureauAccountPaymentHistory[${index}].month`, { required: true })} >
              <option name="" value="" style={{ display: 'none'}}>MM</option>
              <option name="January" value="January">January</option>
              <option name="February" value="February">February</option>
              <option name="March" value="March">March</option>
              <option name="April" value="April">April</option>
              <option name="May" value="May">May</option>
              <option name="June" value="June">June</option>
              <option name="July" value="July">July</option>
              <option name="August" value="August">August</option>
              <option name="September" value="September">September</option>
              <option name="October" value="October">October</option>
              <option name="November" value="November">November</option>
              <option name="December" value="December">December</option>
            </select>
          </div>
          <div className="bureau-input-container">
            <label>Year</label>
            <input className="inputs account-form-inputs" type={'year'}
              {...register(`statementBureauAccountData[${nestIndex}].statementBureauAccountPaymentHistory[${index}].year`, 
              { required: true,
                pattern: {
                  value: /^[^\s]+(\s+[^\s]+)*$/,
                  message: "Invalid year"
                } })} />
          </div>
          <div className="bureau-input-container">
            <label>Status</label>
            <input className="inputs account-form-inputs" type={'text'}
              {...register(`statementBureauAccountData[${nestIndex}].statementBureauAccountPaymentHistory[${index}].status`, 
              { required: true,
                pattern: {
                  value: /^[^\s]+(\s+[^\s]+)*$/,
                  message: "Invalid status"
                } })} />
          </div>
        </div>
        <br />
        <button type="button" onClick={() => remove(index)} className="button-square button-outline">
          Remove Payment
        </button>
        </fieldset>
      })}
      <br />
      <div className="bureau-form-buttons">
        <button type="button" onClick={() => append()} className="button-square button-solid">
          Add Payment
        </button>
      </div>
    </>
  );
};
