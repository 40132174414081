import React, { useState } from 'react';
import './Tabs.css';
import TabBody from './TabBody';


function Tabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const showForm = props?.showForm

  const headerClick = (tabBody,i)=>{
      setActiveTab(i)

      if(tabBody.title === 'KYC Verification'){
        props.setKycTourPrompt(true)
      }

  }

  const headers = props.tabBodies.map((tabBody, i) => {
    return (
      <div className="st-details-header" id={activeTab === i ? 'active' : 'inactive'} key={i + tabBody.title} 
        onClick={() => headerClick(tabBody,i)} >
        {tabBody.iconSrc && tabBody.iconSrc} 
        <h4>{tabBody.title}</h4>
      </div>
    )
  });

  let activeTabBody = props.tabBodies[activeTab];

  if(activeTabBody === null || activeTabBody === undefined){
    return null;
  }

  return (
    <div className="tabs-container" key={activeTabBody.title}>
      <div className={"tabs-header-row " + props.extraClass} >
        {headers}
      </div>
      <div className="tab-bodies-container">
        {props.tabBodyHeader && <h2 className='tab-bodies-header detail-header'>
          {props.tabBodyHeader.header}
        </h2>}
        <TabBody content={activeTabBody.content} showForm={showForm} title={activeTabBody.title} headerItems={activeTabBody.headerItems} />
      </div>
    </div>
  )
}

export default Tabs;