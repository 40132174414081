import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import amlEmpty from '../../../../../assests/icons/amlEmpty.png';
import errorImg from '../../../../../assests/icons/errorImage.png';
import { DateRangeColumnFilter, Table } from "../../../../../components/Table/Table";
import Utils from "../../../../../library/Utils";
import { FetchDataStatus } from "../../../../../library/Variables";
import { getAuditDetails, resetClientAudit, selectClientAuditStatus } from "../../../../../redux/Slices/Settings/userManagement/ClientAudit";
import Spinner from "../../../../../svg/Spinner/Spinner";
import './AuditPage.css';


function AuditSection(props) {
  const accessToken = props.accessToken
  const dispatch = useDispatch()
  const [selectedAudit,setSelectedAudit] = useState()
  const [selectedRow,setSelectedRow] = useState('0')
  let preparedTableData = [];
  const auditStatus = useSelector(selectClientAuditStatus)
  let comp 



  useEffect(()=>{
    dispatch(resetClientAudit())
  },[])


  const itemSelector = (data,key)=>{
    setSelectedAudit(data[parseInt(key)])
    setSelectedRow(parseInt(key))
  }

  const theadersAudits = useMemo(() => [
    
    {
        Header: "Time",
        accessor: "date",
        Filter: DateRangeColumnFilter,
        filter: 'dateRange'
    },


    { 
      Header: "Activity",
      accessor: row => `${row.user} updated the ${row.action} section`,
      Cell: data => {
        return <section
                onClick={()=>{itemSelector(auditStatus.clientAuditData.adminAuditLogs,data.row.id)}}
               style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <p >
                      {`${data.row.original.user} updated the ${data.row.original.action} section`} 
                  </p>
                  <span style={{fontSize:'20px',
                                cursor:"pointer",
                                padding:"15px",
                                boxSizing:"border-box",
                                display:'flex',
                                alignItems:"center",
                                justifyContent:"center",
                                boxShadow: data.row.id==selectedRow? '0 4px 6px rgba(0, 0, 0, 0.1)':''}}>
                    {'>'}
                  </span>
               </section> 
          
      },
    },
]
  )



  if(auditStatus.clientAuditStatus === FetchDataStatus.INITIAL){
    comp = <Spinner/>
    dispatch(getAuditDetails({accessToken}))
  }


  if(auditStatus.clientAuditStatus === FetchDataStatus.FETCHING){
    comp = <Spinner/>

  }

  
  if(auditStatus.clientAuditStatus === FetchDataStatus.SUCCESS ) {
    if(!Utils.isFieldEmpty(auditStatus.clientAuditData.adminAuditLogs)){
      auditStatus.clientAuditData.adminAuditLogs.forEach(element => {
        preparedTableData.push({
          "date": Utils.formatAuditLongDateMonthAndYear(element.createdDate),
          "action": element.actionType,
          "user":element.userName,
          "role":element.userRole,
           })
      });
   

      comp = <Table tableClass="transactions-type-table" pagination={true} pageSize={[10]} data={preparedTableData}
            columns={theadersAudits} tableFilter={true} rowClass={"table-row-two-colored"} selectedRow={selectedRow}/>
    }

    else{
      comp = <div style={{display:"flex",
                         flexDirection:"column",
                         height:"250px",
                         justifyContent:"space-around",
                         alignItems:"center"}}>
                  <span style={{display:"flex",
                                alignItems:"center",
                                justifyContent:"center"}}>
                      <img src={amlEmpty}/></span>
                  <p style={{color:"#FB6D06",
                             fontWeight:"700",
                             fontSize:"24px"}}>
                             No Audit History
                  </p>
              </div>
      }
    
  }   

  if(auditStatus.clientAuditStatus === FetchDataStatus.FAILURE ) {
    comp = <div style={{display:"flex",
                        flexDirection:"column",
                        height:"200px",
                        justifyContent:"space-around",
                        alignItems:"center"}}>
              <span style={{display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            padding:"5px"}}>
                  <img style={{width:"30px",height:"30px"}} src={errorImg}/></span>
              <p style={{color:'#363C4F'}}>Something went wrong!</p>
              <p style={{color:"red",
                         fontWeight:"700",
                         fontSize:"24px"}}>
                  {auditStatus.clientAuditErrorMessage}
              </p>
           </div>
   }  


  useEffect(()=>{
    if(auditStatus.clientAuditStatus === FetchDataStatus.SUCCESS 
      && !Utils.isFieldEmpty(auditStatus.clientAuditData.adminAuditLogs)){
        setSelectedAudit(auditStatus.clientAuditData.adminAuditLogs[0])
      }
  },[auditStatus.clientAuditStatus])

   
 


/**
 *   const handleTableCallback = () => {
    props.inviteNewUser();
  }
 */

  return <div className="user-management-people-main">
    <section style={{display:"flex",justifyContent:"space-between"}}>
      <div style={{width:"60%"}}>
        {comp}
      </div>

      {(auditStatus.clientAuditStatus === FetchDataStatus.SUCCESS &&
        !Utils.isFieldEmpty(auditStatus.clientAuditData.adminAuditLogs)) && 
        <div className="tableHighlight">
          <section className="header">About this Activity</section>
          <section className="Audit-rows">
              <div className="Audit-cols">
                <span className="header">User</span>
                <span>{selectedAudit?.userName}</span>
              </div>
              <div className="Audit-cols">
                <span className="header">Role</span>
                <span>{selectedAudit?.userRole}</span>
              </div>
          </section>
          <section className="Audit-rows">
              <div className="Audit-cols">
                <span className="header">Action</span>
                <span>{selectedAudit?.actionType}</span>
              </div>
          </section>
          <section className="Audit-rows">
              <div className="Audit-cols">
                <span className="header">Date</span>
                <span>{Utils.formatAuditLongDateMonthAndYear(selectedAudit?.createdDate)}</span>
              </div>
          </section>

      </div>}
    </section>
  </div>
}

export default AuditSection;



/**
 * 
 *   if((auditStatus.clientAuditStatus === FetchDataStatus.SUCCESS) && (!Utils.isFieldEmpty(transactions.transactions))) {
    transactions.transactions.forEach(element => {
      preparedTableData.push({
        "date": Utils.formatLongDate(element.createdDate),
        "action": element.actionType,
        "user":element.userName,
        "role":element.userRole,
         })
    });
 */

/**
 * 
 * <Table tableClass="user-management-type-table" data={tableData} columns={theadersUsers} tableExtra={false} tableFilterWithButton={true}
      buttonText="+ &nbsp; Invite new user" buttonFunction={() => handleTableCallback()} platformUser={platformUser}
      pagination={true} pageSize={[10]} />

<Table tableClass="transactions-type-table" pagination={true} pageSize={[10]} data={preparedTableData}
            columns={theadersTransactions} tableFilter={true} rowClass={"table-row-two-colored"} />
 */