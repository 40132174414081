// import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchParameterActionAsync,
  parameterActions,
} from "../../../../redux/Slices/fraudDetect/actionSlice";
import { fetchParametersAsync } from "../../../../redux/Slices/fraudDetect/parametersSlice";
import { parameterSelector } from "../../../../redux/Slices/fraudDetect/parametersSlice/selector";
import {
  createCustomRuleSelector,
  createCustomRulesAsync,
  customRuleSelector,
  deleteCustomRuleSelector,
  deleteCustomRulesAsync,
  fetchCustomRuleAsync,
} from "../../../../redux/Slices/fraudDetect/ruleSlice";
import { reset } from "../../../../redux/Slices/fraudDetect/ruleSlice/customRule";
import dayjs from "../../../../utils/dayjs";
import { generateFormData } from "../../../../utils/generateFormData";
import { AlertModal } from "../../components/AlertModal";
import { ConfirmDialog } from "../../components/AlertModal/ConfirmDialog";
import Dropdown from "../../components/DropdownMenu/CustomDropdown";
import FileUploadDrop from "../../components/FileUpload";
import { FilterForm } from "../../components/FilterForm";
import { CustomDialog } from "../../components/Modal";
import { Table } from "../../components/Table";
import { Label, Select, TextField } from "../../components/form";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import Spinner from "../../components/loader";
import SkeletonLoader from "../../components/loader/skeletonLoader";
import styles from "./index.module.css";

const CustomRules = ({ configuration, accessToken, user }) => {
  const [openCustomRuleModal, setOpenCustomRuleModal] = useState();
  const dispatch = useDispatch();
  const parameters = useSelector(parameterSelector);
  const actions = useSelector(parameterActions);
  const navigate = useNavigate();
  const deleteRule = useSelector(deleteCustomRuleSelector);

  const [refresh, setRefresh] = useState(false);

  const [showFilter] = useState(false);

  const [files, setFiles] = useState([]);

  const customRules = useSelector(customRuleSelector);

  const createRuleApi = useSelector(createCustomRuleSelector);

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(createCustomRuleSchema),
  });

  useEffect(() => {
    dispatch(fetchCustomRuleAsync());
  }, [accessToken, dispatch, refresh]);

  useEffect(() => {
    dispatch(fetchParametersAsync({ accessToken }));
  }, [accessToken, dispatch]);

  const removeFile = (index) => {
    setFiles((l) => [...l.slice(0, index), ...l.slice(index + 1)]);
  };

  const createCustomRuleHandler = (data) => {
    const payload = {
      name: data.name,
      friendlyName: data.name,
      parameterKey: data.parameterKey?.value,
      fraudIndicatorKey: data.fraudIndicatorKey?.value,
      file: files.length ? files[0].file : "",
      // FileUrl: files.length ? files[0].file : "",
    };

    dispatch(createCustomRulesAsync(generateFormData(payload)));
  };
  useEffect(() => {
    if (
      !deleteRule.status !== "loading" &&
      deleteRule &&
      deleteRule.data &&
      deleteRule.data.data
    ) {
      setRefresh((prev) => !prev);
    }
  }, [deleteRule]);
  return (
    <InnerLayout fullWidth={false}>
      <div className={styles.formWrapper}>
        <div
          className={classNames(styles.titleWrapper, styles.titleWrapperCustom)}
        >
          <h5 className={styles.formtTitle}>Custom List</h5>

          {customRules.data && customRules.data.data.length > 0 && (
            <>
              <button
                className={styles.applyBtnCustomOutline}
                onClick={() => setOpenCustomRuleModal(true)}
              >
                Create New List
              </button>
              <button
                className={styles.applyBtnCustomOContained}
                onClick={() => navigate("/fraud-detect/create-rule")}
              >
                Manage Fraud List
              </button>
            </>
          )}

          {/* {!showNoData && (
            <>
              <button
                className={styles.applyBtnCustomOutline}
                onClick={() => setOpenCustomRuleModal(true)}
              >
                Create New List
              </button>
              <button
                className={styles.applyBtnCustomOContained}
                onClick={() => navigate("/fraud-detect/create-rule")}
              >
                Manage Fraud List
              </button>
            </>
          )} */}
        </div>

        {customRules.status !== "loading" &&
        customRules.data &&
        !customRules.data.data.length ? (
          <div className={styles.wrapper}>
            <div className={styles.containerWrap}>
              <div className={styles.noItemWrapper}>
                <h2 className={styles.containerTitle}>
                  There is currently no custom list to display.
                </h2>
                <p className={styles.containerPara}>
                  Please, click the button below to create custom list
                </p>
                <div className={styles.btnWrapper}>
                  <button
                    onClick={() => setOpenCustomRuleModal(true)}
                    className={styles.createListBtn}
                  >
                    Create New List
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {customRules.status === "loading" && (
          <div className={styles.wrapper}>
            <div className={styles.containerWrap}>
              <div className={styles.noItemWrapper}>
                <div style={{ marginBottom: "20px" }}>
                  <SkeletonLoader count={1} height={50} width={500} />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <SkeletonLoader count={1} height={30} width={300} />
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <SkeletonLoader count={1} height={30} width={200} />
                </div>
              </div>
            </div>
          </div>
        )}

        {customRules.status !== "loading" &&
        customRules.data &&
        customRules.data.data.length ? (
          <div className={styles.customTableContainer}>
            <Table
              showPagination={true}
              pageCount={10}
              columns={[
                {
                  title: "Name",
                  field: "name",
                },
                {
                  title: "Parameter",
                  field: "parameter",
                },

                {
                  title: "Fraud Indicators",
                  field: "fraudIndicator",
                },

                {
                  title: "Created Date",
                  field: "createdAt",
                },

                {
                  title: "Updated Date",
                  field: "updatedAt",
                },
                {
                  title: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <span>Action</span>

                      {showFilter && (
                        <span>
                          <Dropdown
                            triggerElem={
                              <svg
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.89844 2.10156H19.0984C20.1984 2.10156 21.0984 3.00156 21.0984 4.10156V6.30156C21.0984 7.10156 20.5984 8.10156 20.0984 8.60156L15.7984 12.4016C15.1984 12.9016 14.7984 13.9016 14.7984 14.7016V19.0016C14.7984 19.6016 14.3984 20.4016 13.8984 20.7016L12.4984 21.6016C11.1984 22.4016 9.39844 21.5016 9.39844 19.9016V14.6016C9.39844 13.9016 8.99844 13.0016 8.59844 12.5016L4.79844 8.50156C4.29844 8.00156 3.89844 7.10156 3.89844 6.50156V4.20156C3.89844 3.00156 4.79844 2.10156 5.89844 2.10156Z"
                                  stroke="#2A38A4"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                          >
                            <FilterForm />
                          </Dropdown>
                        </span>
                      )}
                    </div>
                  ),
                  field: "action",
                  Cell: ({ entry }) => <ActionMenu entry={entry} />,
                },
              ].filter(Boolean)}
              data={
                customRules.data &&
                customRules.data?.data.map((item) => ({
                  ...item,
                  updatedAt: dayjs(item.updatedAt).format("MMMM Do, YYYY"),
                  createdAt: dayjs(item.createdAt).format("MMMM Do, YYYY"),
                }))
              }
            />
          </div>
        ) : null}
      </div>

      {openCustomRuleModal && (
        <CustomDialog
          open={openCustomRuleModal}
          toggleModal={() => setOpenCustomRuleModal(false)}
          title="Create New List"
          contentStyle={{
            width: "650px",
            height: "800px",
            overflowY: "auto",
          }}
        >
          <div className={styles.customRuleForm}>
            <FormProvider {...methods}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  methods.handleSubmit(createCustomRuleHandler)(event);
                }}
              >
                <div className={styles.customFormContainers}>
                  <div className={styles.formInput}>
                    <Controller
                      name="name"
                      control={methods.control}
                      rules={{
                        required: "Name is required.",
                      }}
                      render={({ field: { value, onChange }, formState }) => {
                        return (
                          <>
                            <Label>Name</Label>
                            <TextField
                              name="name"
                              inputIsFullWidth={true}
                              value={value}
                              onChange={onChange}
                              inputContainer={styles.inputContainer}
                            />
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className={styles.formFull}>
                    <div className={styles.formInput}>
                      <Controller
                        control={methods.control}
                        name="parameterKey"
                        rules={{
                          required: "Parameter is required.",
                        }}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <Label>Select Parameter</Label>
                            <Select
                              name="parameterKey"
                              className={styles.selectContainer}
                              options={parameters?.data?.data.map((item) => ({
                                value: item.key,
                                label: item.name,
                              }))}
                              value={value}
                              onChange={(e) => {
                                dispatch(
                                  fetchParameterActionAsync({ id: e.value })
                                );
                                onChange(e);
                              }}
                              isLoading={parameters.status === "loading"}
                              isDisabled={parameters.status === "loading"}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles.formFull}>
                    <div className={styles.formInput}>
                      <Controller
                        control={methods.control}
                        name="fraudIndicatorKey"
                        rules={{
                          required: "Fraud indicator is required.",
                        }}
                        render={({ field: { value, onChange }, formState }) => {
                          //   const parameter = methods.watch("parameterKey");

                          return (
                            <>
                              <Label>Type of Fraud Indicator</Label>
                              <Select
                                className={styles.selectContainer}
                                name="fraudIndicatorKey"
                                options={actions?.data?.data.map((item) => ({
                                  value: item.key,
                                  label: item.name,
                                }))}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                isLoading={actions?.status === "loading"}
                                isDisabled={
                                  actions?.status === "loading" ||
                                  actions?.status === "idle"
                                }
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.formFull}>
                    <FileUploadDrop
                      files={files}
                      handleFile={(files) => setFiles(files)}
                      removeFile={removeFile}
                      excel={true}
                    />
                  </div>

                  <div className={styles.customFormBtnWrapper}>
                    <button
                      type="submit"
                      disabled={createRuleApi.status === "loading"}
                      className={styles.customRuleSaveBtn}
                    >
                      {createRuleApi.status === "loading" ? (
                        <Spinner
                          visible={createRuleApi.status}
                          color="#fff"
                          size={20}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </CustomDialog>
      )}
      {createRuleApi.status === "succeeded" && (
        <AlertModal
          showTitle={false}
          showCancelBtn={false}
          open={createRuleApi.status === "succeeded"}
          toggleModal={() => {}}
          handleDone={() => {
            dispatch(reset());
            setRefresh((prev) => !prev);
            setOpenCustomRuleModal(false);
          }}
        />
      )}
    </InnerLayout>
  );
};

const ActionMenu = ({ entry }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const deleteRule = useSelector(deleteCustomRuleSelector);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <button
          onClick={() => navigate(`/fraud-detect/custom-rule/${entry?.key}`)}
          className={styles.contained}
        >
          View
        </button>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setOpenDeleteDialog(true)}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.3248 9.96875C19.3248 9.96875 18.7818 16.7037 18.4668 19.5407C18.3168 20.8957 17.4798 21.6898 16.1088 21.7148C13.4998 21.7618 10.8878 21.7648 8.27979 21.7098C6.96079 21.6828 6.13779 20.8788 5.99079 19.5478C5.67379 16.6858 5.13379 9.96875 5.13379 9.96875"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.708 6.74219H3.75"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.4406 6.739C16.6556 6.739 15.9796 6.184 15.8256 5.415L15.5826 4.199C15.4326 3.638 14.9246 3.25 14.3456 3.25H10.1126C9.53358 3.25 9.02558 3.638 8.87558 4.199L8.63258 5.415C8.47858 6.184 7.80258 6.739 7.01758 6.739"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      {openDeleteDialog && (
        <ConfirmDialog
          showTitle={false}
          showCancelBtn={false}
          open={openDeleteDialog}
          toggleModal={() => {}}
          description="Are you sure you want to block this rule?"
          loading={deleteRule.status === "loading"}
          handleDone={() => navigate("/fraud-detect/rules")}
          okHandler={() => dispatch(deleteCustomRulesAsync({ id: entry.key }))}
          cancelHandler={() => setOpenDeleteDialog(false)}
        />
      )}
    </>
  );
};

export default CustomRules;
