import React,{useMemo,useState,useCallback} from 'react'
import {useDropzone} from "react-dropzone";
import { useNavigate } from 'react-router';
import { uploadLinkedPdfAsync,selectAddPdfStatus,reset } from '../../redux/Slices/AddPdf';
import './ModalNig.css'
import { useDispatch,useSelector } from 'react-redux';
import { PostDataStatus } from '../../library/Variables';
import Utils from '../../library/Utils';
import Spinner from '../../svg/Spinner/Spinner';
import { resetActiveStatement } from '../../redux/Slices/ConsolidatedStatement';


function AddLinkedPdfNg(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = props.userId
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);
  const [userIdentity,setUserIdentity] = useState(userId?userId:'')
  const accessToken = props.accessToken;
  const configuration = props.configuration;
  const statementKey = props.statementKey;
  const addPdfState = useSelector(selectAddPdfStatus);
  const [bvnData,setBvnData] = useState('')
  const setRefreshStatement = props.setRefreshStatement
  const refreshStatement = props.refreshStatement
  const maxFileSize = configuration?.featureFlags?.maxFileUploadSize ?configuration.featureFlags.maxFileUploadSize :10000000
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  
  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };


  const onDrop = useCallback(acceptedFiles => {
    const fileItem = {
      pdf: acceptedFiles,
      filePassword: "",
      statementType: 'CONSUMER',
      // accessToken: accessToken,
      // uniqueIdentifier: userId,
      // baseStatementKey: statementKey
    }

    setFilesSelected([...filesSelected, fileItem]);
    setFilesRejected([]);
  }, [filesSelected])

  const onDropRejected = useCallback(fileRejections => {
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: maxFileSize,
    filesizeBase: 1000,
    multiple: false,
    onDrop,
    onDropRejected
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const acceptedFileItems = filesSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeFile(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));

  const handleStementTypeChange = (e, id, property) => {
    const {value} = e.target;


    const updatedArray = [...filesSelected];
    updatedArray[id] = {...updatedArray[id], [property] : value}

    setFilesSelected(updatedArray)
  }

  function closepop(props) {
    dispatch(reset());
    //setRefreshStatement(true)
    props.close();
  }

  const removeFile = (file) => {
    let newFilesArray = [...filesSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setFilesSelected(newFilesArray);
  }

  // if(filesSelected.length > 1) {
  //   removeFile(filesSelected[0]);
  // }

  const submitFileForProcessing = () => {
    let filesArray = []
    let passwordArray = []
    let statementTypeArray = []

    filesSelected.map(({pdf, filePassword, statementType}) => {
      filesArray.push(pdf[0])
      passwordArray.push(filePassword)
      statementTypeArray.push(statementType)
    })


    
    let params = {
      accessToken: accessToken,
      uniqueIdentifier: userIdentity,
      baseStatementKey: statementKey,
      files: filesArray,
      passwords: passwordArray,
      statementTypes: statementTypeArray,
    }
    dispatch(uploadLinkedPdfAsync(params));

  }



  let component = null;
  if(addPdfState.status === PostDataStatus.INITIAL){

   component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload A PDF Statement</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container scrollbar">
        <div className="scrollable-content">
          <div {...getRootProps({style})} className="drop-container">
            <input {...getInputProps()} />
            <img src="/assets/icons/download-color.svg" alt="" />
            <p className="drop-caption">Drag "n" drop your file here, or <span className="detail-header"  >
              Open File Dialog</span>
            </p>
            <p className="drop-subcaption text-medium">{`MAX FILE SIZE: ${Math.round((maxFileSize/1048576).toFixed(2))}MB`}</p>
            <p className="drop-subcaption text-medium">FILE SUPPORTED: PDF</p>
          </div>
          {filesRejected.length > 0 &&
            <span className="ap-file-rejected-error-message error-msg">
              {filesRejected[0].errors[0].code === "file-too-large" 
                ? "File is larger than 10MB"
                : filesRejected[0].errors[0].message}
            </span>
          }
          {acceptedFileItems.length > 0 &&
            <>
            <div className="ap-password-container">
                  <h4 className="text-dark">Loan Id<span className="required">*</span></h4>
                  <input type="text" value={userIdentity} onChange={(e=>setUserIdentity(e.target.value))} /> 
                  <p className="unique-id-info">This is used to identify consolidated statements reports like customer code</p>
                </div>
              <h4 className="text-medium">SELECTED FILE:</h4>
              {
                filesSelected?.map((file, id) => {
                  return (
                  <div className="ap-accepted-file-container" key={id}>
                      <ul>
                        <li key={file.pdf[0].path}>
                          <div className="ap-accepted-file-info">
                            <img src="/assets/icons/statement-color.svg" alt="" />
                            <div>
                              <h4 className="text-dark">{file.pdf[0].path}</h4>
                              <span className="text-medium">{file.pdf[0].size / 1000000} MB</span>
                            </div> 
                          </div>
                          <button onClick={() => removeFile(file)} className="delete-pdf-button">
                            <img src="/assets/icons/delete-color.svg" alt="Delete" />
                          </button>
                        </li>
                      </ul>
                      <div className="ap-statement-type-container">
                        <h4 className="text-dark">Select a Statement Type</h4>
                        <div className="ap-statement-type-radio-buttons-container">
                          <div className="ap-statement-type">
                        <label>Individual</label>
                        <input type="radio" name={`CONSUMER-${id}`} value={"CONSUMER"} 
                          checked={file.statementType === "CONSUMER"} 
                          onChange={(e) => handleStementTypeChange(e, id, 'statementType')} />
                      </div>  
                      <div className="ap-statement-type">
                        <label>Business</label>
                        <input type="radio" name={`BUSINESS-${id}`} value={"BUSINESS"} 
                          checked={file.statementType === "BUSINESS" } 
                          onChange={(e) => handleStementTypeChange(e, id, 'statementType')} />
                      </div> 
                      <div className="ap-statement-type">
                        <label>Mobile Money</label>
                        <input type="radio" name={`MOBILE_MONEY-${id}`} value={"MOBILE_MONEY"} 
                          checked={file.statementType === "MOBILE_MONEY" } 
                          onChange={(e) => handleStementTypeChange(e, id, 'statementType')} />
                      </div> 
                      <div className="ap-statement-type">
                        <label>Mobile Money Business</label>
                        <input type="radio" name={`MOBILE_MONEY_BUSINESS-${id}`} value={"MOBILE_MONEY_BUSINESS"} 
                          checked={file.statementType === "MOBILE_MONEY_BUSINESS" } 
                          onChange={(e) => handleStementTypeChange(e, id, 'statementType')} />
                      </div>                       
                        </div>       
                      </div>
                      <div className="ap-password-container">
                        <h4 className="text-dark">File Password <span className="text-medium">(optional)</span></h4>
                        <input type="password" id="st-password" autocomplete="off" value={file.filePassword} onChange={(e) => handleStementTypeChange(e, id, 'filePassword')} /> 
                      </div>
                  </div>
                  )
                })
              }

              <div className="submit-container">
                <button className="btn-submit button-solid" onClick={() => submitFileForProcessing()}>Upload</button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  }

  else if(addPdfState.status === PostDataStatus.FETCHING){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Please wait...</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <Spinner />
    </div>
  } else if(addPdfState.status === PostDataStatus.SUCCESS){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">PDF Statement Uploaded</h2>
        <div className="close" onClick={() => closepop(props)}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <p>Upload complete. Click done to see.</p>
        <div className="submit-container">
          <button className="btn-submit button-solid" onClick={() => closepop(props)}>Done</button>
        </div>
      </div>
    </div>
  } else if(addPdfState.status === PostDataStatus.FAILURE) {
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload Failed</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <div className="modal-body-message">
          <p>{!Utils.isFieldEmpty(addPdfState.errorMessage) && (addPdfState.errorMessage).replace(/[""]/g, "")}</p>
          <p>If the problem persists, please contact support for further inquiries.</p>
        </div>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => dispatch(reset())}>Try Again</button>
      </div>
    </div>
  } 

  return (
    <div>{component}</div>
  )
}

export default AddLinkedPdfNg