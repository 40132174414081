import Utils from "../../../../library/Utils";



function solution(string) {
    let splitStr = string.split("");
    let newStr = string.split("");
    let capStr = string.toUpperCase().split("");
    for (let i = splitStr.length - 1; i >= 0; i--) {
      if (splitStr[i] === capStr[i]) {
        newStr.splice(i, 0, ' ');
      }
    }
    return newStr.join("");
  }

export const CamelDataSerializer = (obj)=>{
    let keyStore = []
    let camelDataReshaped = []
    const basicDataKeys = Object.keys(obj);
    const basicDataValues = Object.values(obj)

    basicDataKeys.map((element)=>
        keyStore.push(solution(element)) 
    )

    for(let j=0; j<basicDataKeys.length; j++ ){
        camelDataReshaped.push({itemKey:keyStore[j],itemValue:basicDataValues[j]})
    }

    return camelDataReshaped
}