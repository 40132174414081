export const agreementMonthlyReShape = (data)=>{
    const historyObj = {title:"Name History",data:[]}

    for (let i =0; i<data.length; i++){
        
        let newArr = []
        newArr.push(data[i].amountOverdue)
        newArr.push(data[i].currency)
        newArr.push(data[i].subscriberName)

        historyObj.data.push(newArr)
    }

    return historyObj

}