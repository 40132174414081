import React, {useState,useEffect} from 'react'
import logoHold from '../../../../assests/icons/kycLogo.png'
import BvnModal from '../../../Statement/KYC/BvnHolder/BvnModal'
import NinModal from '../../../Statement/KYC/NinHolder/NinModal'
import PassportModal from '../../../Statement/KYC/PassportHolder/PassportModal'
import LicenseModal from '../../../Statement/KYC/LicenseHolder/LicenseModal'
import VinModal from './KycModal/VinModal'
import { selectKycEntireDataState } from '../../../../redux/Slices/KycOptions'
import { useSelector,useDispatch } from 'react-redux'
import ExtractionReport from '../../../Statement/KYC/kycReporter/ExtractionReport'
import Spinner from '../../../../svg/Spinner/Spinner'
import ExtractionHistory from '../../../Statement/KYC/kycHistory/ExtractionHistory'
import KybExtractionReport from '../KybContent/KybDocumentation/KybReport'
import { getAllKycDataHistory } from '../../../../redux/Slices/KycOptions'
import { getStandAloneDataHistory } from '../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../library/Variables'
import { FetchDataStatus } from '../../../../library/Variables'
import './KycContent.css'


function KycContentSection(props) {
    const kycState = useSelector(selectKycEntireDataState)
    const dispatch = useDispatch()
    const accessToken = props.accessToken
    const keyFromRoute = props.keyFromRoute
    const [viewReport,setViewReport] = useState(false)
    const [reportData,setReportData] = useState(null)
  const [historyArray,setHistoryArray] =  useState([])
  const [verificationType,setVerificationType] = useState()

    const [kycToShow,setKycToShow] = useState(null)
    const cardHolder = [{id:0,title:'BVN',
                      info:'This check requires BVN of the individual'},
                      {id:1,title:'NIN',
                      info:'This check requires NIN of the individual'},
                      {id:2,title:'Passport',
                      info:'This check requires the passport number'},
                      {id:3,title:'PVC',
                      info:"This check requires Voter's number"},
                      {id:4,title:'Driver’s License',
                      info:'This check requires license number'}]
                      const customStyles = {
                        content: {
                            transition: "0.125s ease-in-out",
                            transform: "scale(1)",
                            height: "33%",
                            width: "50%",
                            top: "33%",
                            left: "33%",
                            padding: "0 0 0 0",
                            overflowY: "scroll"
                        },
                        overlay: {
                            zIndex: 1000,
                            position: "absolute"
                        }
                        };
                  
                    const inputStyle = {
                      width: "100%",
                      marginTop: "10px",
                      border: 'none',
                      padding: '0px 24px 0px 24px',
                      boxSizing: "border-box",
                      backgroundColor: 'rgba(239, 244, 255, 1)',
                      outline: "none",
                      height: '40px'
                    };
                    
    const tableHeaders = ['Status','KYC Type','Created Date','Full Name','Tracking Number']

    useEffect(()=>{
        if(kycState.standAloneStatus===FetchDataStatus.SUCCESS||kycState.standAloneStatus===FetchDataStatus.SUCCESS){
          setHistoryArray([...kycState.activeExtractions.kycDataSummaries])
        }
      },[kycState.extractionStatus,kycState.standAloneStatus])
    

      useEffect(()=>{
        if(kycState.standAloneStatus===FetchDataStatus.SUCCESS
          ||kycState.standAloneStatus===FetchDataStatus.SUCCESS
          ||kycState.bvnStatus===PostDataStatus.SUCCESS
          ||kycState.ninStatus===PostDataStatus.SUCCESS
          ||kycState.passportStatus===PostDataStatus.SUCCESS
          ||kycState.licenseStatus===PostDataStatus.SUCCESS
          ||kycState.vinStatus===PostDataStatus.SUCCESS
          ){
          setHistoryArray([...kycState.activeExtractions.kycDataSummaries])
        }
      },[kycState.extractionStatus,kycState.standAloneStatus,kycState.bvnStatus,
         kycState.ninStatus,kycState.passportStatus,
         kycState.licenseStatus, kycState.vinStatus
      ])

      

      useEffect(()=>{
       
        //dispatch(getStandAloneDataHistoryKenya({accessToken}))
        if(kycState.extractionStatus===FetchDataStatus.INITIAL){
          dispatch(getStandAloneDataHistory({accessToken,page:1,perPage:10}))
        }
      
        
    },[kycState.extractionStatus])
   
    return (
        <div>
            {kycToShow===null?null
                :kycToShow===0?<BvnModal keyFromRoute={keyFromRoute} 
                                        accessToken={accessToken} 
                                        setViewReport={setViewReport} 
                                        customStyles={customStyles} 
                                        setKycToShow={setKycToShow} 
                                        inputStyle={inputStyle}
                                        setVerificationType={setVerificationType}
                                        />:

                kycToShow===1?<NinModal keyFromRoute={keyFromRoute} 
                                        accessToken={accessToken}                                         
                                        setViewReport={setViewReport} 
                                        customStyles={customStyles} 
                                        setKycToShow={setKycToShow} 
                                        inputStyle={inputStyle}
                                        setVerificationType={setVerificationType}
                                        />:

                kycToShow===2?<PassportModal keyFromRoute={keyFromRoute} 
                                             accessToken={accessToken} 
                                             setViewReport={setViewReport} 
                                             customStyles={customStyles} 
                                             setKycToShow={setKycToShow} 
                                             inputStyle={inputStyle}
                                             setVerificationType={setVerificationType}
                                             />:

                kycToShow===3?<VinModal keyFromRoute={keyFromRoute} 
                                        accessToken={accessToken} 
                                        setViewReport={setViewReport} 
                                        customStyles={customStyles} 
                                        setKycToShow={setKycToShow} 
                                        inputStyle={inputStyle}
                                        setVerificationType={setVerificationType}
                                        />:

                kycToShow===4?<LicenseModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}
                                            setVerificationType={setVerificationType}
                                            />:''
            }
            <div style={{display:"flex",flexWrap:'wrap',justifyContent:'start',columnGap:'10px',rowGap:"10px",alignItems:'center',marginTop:"30px"}}>{cardHolder.map(card=>
                    <div onClick={()=>{setKycToShow(card.id)}} 
                         style={{display:"flex",
                                cursor:"pointer"
                                ,width:"31%",
                                borderRadius:'4px',
                                height:'130px',
                                flexDirection:'column',
                                justifyContent:"space-around",
                                padding:"10px",
                                boxSizing:'border-box',
                                backgroundColor:"rgba(255, 255, 255, 1)"}} 
                                key={card.id}>
                        <p><img src={logoHold}/></p>
                        <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                        <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                    </div>)}
            </div>

            {viewReport && (
                ['CAC','TIN'].includes(verificationType)?
                <KybExtractionReport verificationType={verificationType} 
                                     viewReport={viewReport} 
                                     setViewReport={setViewReport}/>:

                <ExtractionReport verificationType={verificationType} 
                                  viewReport={viewReport} 
                                  setViewReport={setViewReport}/>)}


            {(kycState.standAloneStatus===FetchDataStatus.FETCHING && !viewReport)?
                <div className="spinner-container">
                    <Spinner></Spinner>
                </div>
            :
            (kycState.standAloneStatus===FetchDataStatus.SUCCESS && historyArray.length===0 && !viewReport)?
            <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                You currently have no historically extracted KYC document.
            </div>:
            (kycState.standAloneStatus==='SUCCESS' && historyArray.length>0 && !viewReport)?
            <ExtractionHistory 
                setVerificationType={setVerificationType}
                accessToken={accessToken}
                setViewReport={setViewReport} viewReport={viewReport} 
                historyArray={historyArray} tableHeaders={tableHeaders}
                kycState={kycState}
                />:
                (kycState.standAloneStatus==='FAILURE' && !viewReport)?
                <div style={{width:'100%',color:'red',textAlign:'center',marginTop:'30px'}}>
                    Something went wrong!.
                </div>:''}
        </div>
    )
    }

    export default KycContentSection