import React,{useState} from 'react'
import eyeSlashIcon from '../../../../assests/svg-icons/eye-slash.svg'
import eyeOpenIcon from '../../../../assests/svg-icons/eye.svg'

function PasswordInput({register,name,errors,title}) {

  const [showPassWord,setShowPassword] = useState(false)
  return (
    <div className="change-pass-container">
        <label className="input-labels">{title}</label>
        <div className="input-container">
            <input 
                className="login-inputs inputs" 
                type={showPassWord?'text':'password'} 
                placeholder="Enter your password"
                {...register(name, { required: "Password is required" })} />
            <img 
                onClick={()=>setShowPassword(!showPassWord)} 
                src={showPassWord?eyeOpenIcon:eyeSlashIcon} 
                className="login-icon input-icon lock" 
                alt='Password icon'

            />
        </div>

        <span className="error-msg">{errors.password?.message}</span>
    </div>
  )
}

export default PasswordInput