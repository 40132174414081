import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';
let download = require('downloadjs');

export const downloadPdfAsync = createAsyncThunk('download', async (params) => {
  setDownloadingTrue();
  const statement = await client.getBlob(`/statements/${params.statementKey}/download?downloadAnalytics=${params.downloadAnalytics}&downloadBureau=${params.downloadBureau}&downloadAffordability=${params.downloadAffordability}&downloadCreditScore=${params.downloadCreditScore}`,
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, params.statementName, "application/pdf");
    });
  });

  return statement;
});

export const downloadPdfSlice = createSlice({
  name: 'downloadPdf',
  initialState: {
    pdf: null,
    pdfStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    isDownloading: false,
  },
  reducers: {
    resetDownload: state => {
      state.pdf = null;
      state.pdfStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
      state.isDownloading = false;
    },
    setDownloadingFalse: state => {
      state.isDownloading = false;
    },
    setDownloadingTrue: state => {
      state.isDownloading = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(downloadPdfAsync.pending, (state) => {
      state.pdfStatus = FetchDataStatus.FETCHING;
      state.isDownloading = true;
    })
    .addCase(downloadPdfAsync.fulfilled, (state, action) => {      
      state.pdfStatus = FetchDataStatus.SUCCESS;
      state.pdf = action.payload;
      state.isDownloading = true;
    })
    .addCase(downloadPdfAsync.rejected, (state, action) => {
      state.pdfStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
      state.isDownloading = false;
    })
  }
});

export const { resetDownload, setDownloadingFalse, setDownloadingTrue } = downloadPdfSlice.actions;

export const selectDownloadPdfStatus = state => state.downloadPdf;

export default downloadPdfSlice.reducer;