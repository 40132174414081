import React, { useState,useMemo,useCallback,useRef, useEffect} from 'react'
import './loanForm.css'
import { useForm } from "react-hook-form";
import nairaIcon from '../../../assests/icons/nairaIcon.png'
import { useSelector,useDispatch } from 'react-redux';
import { getLoanParameter,selectLoanState,resetSignature,uploadLoanForm } from '../../../redux/Slices/Loan';
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables';
import {useDropzone} from "react-dropzone";
import Spinner from '../../../svg/Spinner/Spinner';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash'
import ClientIdSection from './subSections/ClientIdSection';
import ResidentialAddressSection from './subSections/ResidentialAddressSection';
import LoanDetSection from './subSections/LoanDetSection';
import BusinessInfoSection from './subSections/BusinessInfoSection';
import SalesEstimateSection from './subSections/SalesEstimateSection';
import Modal from "react-modal";
import SignatureUploadModal from '../../../components/Modals/LoanModals/SignatureUploadModal';
import FormUploadModal from '../../../components/Modals/LoanModals/FormUploadModals';
import Utils from '../../../library/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectSignatureState } from '../../../redux/Slices/LoanSignatureUpload';
import cancelImg from '../../../assests/icons/cancelImage.png'
import PreviewDeleteModal from '../../../components/Modals/LoanModals/PreviewAndDeleteSig';
import { generateDraft } from '../../../redux/Slices/DraftAndEditLoan';
import DraftUploadModal from '../../../components/Modals/LoanModals/DraftUploadModals';

function LoanForm(props) {
  const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm()
  const dispatch = useDispatch()
  const loanDataState = useSelector(selectLoanState)
  const signatureState = useSelector(selectSignatureState)
  const setFormPage = props.setFormPage
  const accessToken = props.accessToken
  const setShowForm = props.setShowForm
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);
  const [imgFile,setImgFile] = useState(null)
  const [imgUrl,setImgUrl] = useState(null)
  const [fileHold,setFilehold] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [draftModalIsOpen, setDarftIsOpen] = useState(false);
  const [modalFormIsOpen, setFormIsOpen] = useState(false);
  const [previewDeleteIsOpen, setPreviewDeleteIsOpen] = useState(false);
  const [uploadError,setUploadError] = useState(null)
  const [fixedClientFields,setFixedClientFields] = useState({firstName:'',lastName:'',middleName:'',clientId:''})
  const [formObject,setFormObject] = useState(null)
  const [clientSignaturePath,setclientSignaturePath] = useState([])
  const [showName,setShowName] = useState(false)
  const navRef = props.navRef
  const formPage = props.formPage
  const [errorFilling,setErrorFilling] = useState(false)
  const [selectedImgList,setSelectedImgList] = useState([])
  const [previewObj,setPreviewObj] = useState()
  const [hitDelete,setHitDelete] = useState()
  const [stateForPreview, setStateForPreview] = useState([])


  const fillFormObj = async()=>{
    const isValid = await trigger(); 
    if (isValid && !Utils.isFieldEmpty(fixedClientFields.firstName) && !Utils.isFieldEmpty(fixedClientFields.lastName)
    && !Utils.isFieldEmpty(fixedClientFields.clientId)) {

    const allFields = getValues()

        if(Utils.isFieldEmpty(imgFile)){
          toast.warning("upload signature", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }

        else{
          const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>({fieldname:key,fieldvalue:value}))
          const customFieldObj = {
            "firstName":fixedClientFields.firstName,
            "lastName":fixedClientFields.lastName,
            "middleName":fixedClientFields.middleName,
            "clientId":fixedClientFields.clientId,
            clientSignaturePath,
            files:clientSignaturePath,
            customLoanFields: [...arrayOfObjects]
          }
      
          const loanObject = {data:customFieldObj,accessToken}
          setFormIsOpen(true)
          dispatch(uploadLoanForm(loanObject))
        }
    }
    
    else{
      toast.error("ensure all neccessary fields are filled", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    
    //const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>{customFieldAArray.push({fieldname:key,fieldvalue:value})})
    
  }

  const submitDraft = ()=>{
    const allFields = getValues()
    const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>({fieldname:key,fieldvalue:value}))
          const customFieldObj = {
            "firstName":fixedClientFields.firstName,
            "lastName":fixedClientFields.lastName,
            "middleName":fixedClientFields.middleName,
            "clientId":fixedClientFields.clientId,
            clientSignaturePath,
            files:clientSignaturePath,
            customLoanFields: [...arrayOfObjects]
          }
    const draftObject = {data:customFieldObj,accessToken}
    dispatch(generateDraft(draftObject))
    setDarftIsOpen(true)
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  function closeDraftModal() {
    setDarftIsOpen(false);
    //dispatch(resetDownload());
  }

  function closeFormModal() {

    setFormIsOpen(false)
    //dispatch(resetDownload());
  }


  function closePreviewDeleteModal() {
    setPreviewDeleteIsOpen(false)

    //dispatch(resetDownload());
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };

  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const removeFile = (file) => {
    let newFilesArray = [...filesSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setFilesSelected(newFilesArray);
  }

  const onDrop = useCallback(acceptedFiles => {
    const imgUrlArray = []
    if(acceptedFiles){
      const {path, name,type} = acceptedFiles[0]
      let filesProcessed = 0;
      const file = acceptedFiles; 
      setImgFile([...acceptedFiles])
      let filebody  = {
        id : uuidv4(),
        path : path,
        name : name
      };
      
      file.forEach((file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const previewURL = e.target.result;
          imgUrlArray.push(previewURL);
          setImgUrl(imgUrlArray)
          filesProcessed++
          


          if (filesProcessed === acceptedFiles.length) {
            // Set the state array with preview URLs
            //setImgFile(file); // Set the image file
            setIsOpen(true); // Set isOpen to true
          }

        };

       
      });
   
      
      setFilehold(filebody)
    }
   
  }, [fileHold])


  
  useEffect(()=>{
    navRef.current?.scrollIntoView({behavior: 'smooth'});
  },[formPage])

  

  const onDropRejected = useCallback(fileRejections => {
    setUploadError(fileRejections[0].errors)
    setIsOpen(true)
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])
//uploadError is an array of objects containing code & message
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'] 
          },
    maxSize: 10000000,
    filesizeBase: 1000,
    multiple: true,
    onDrop,
    onDropRejected
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const acceptedFileItems = filesSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeFile(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));

 

  let formContent 
  let client_Info = []
  let business_Info = []
  let residential_Address = []
  let loan_Details = []
  let sales_Estimate = []
  const handleListSelect = (value) => {}
/**
 *   const onSubmit = (formData) => {
    let formParams = {
      //accessToken: accessToken,
      //email: params.email,
      //authUserId: params.authUserId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      country: formData.country,
      referralCode: formData.referralCode
    }
    //dispatch(updateUserDetails(formParams));
  }
 */
  

  const onSubmit = () => {

  /**
   *   

    const formData = getValues();
  
    // Handle the form data
   */
    
  };

  const preparePreviewObj = (index,state)=>{
        let prevObj = {
                      path:stateForPreview[index], 
                      name:selectedImgList[index].file.name,index,
                      imgUrl:imgUrl[index]
                    }
        if(state===true){
          setPreviewObj(prevObj)
          setHitDelete(true)
          setPreviewDeleteIsOpen(true)
        }
        else{
          setPreviewObj(prevObj)
          setHitDelete(false)
          setPreviewDeleteIsOpen(true)
        }
       
  }




  if(loanDataState.loanParametersStatus===FetchDataStatus.FETCHING && loanDataState.signatureStatus!==PostDataStatus.SUCCESS){
    formContent = <Spinner/>
  }

  if(loanDataState.loanParametersStatus===FetchDataStatus.SUCCESS && loanDataState.signatureStatus!==PostDataStatus.SUCCESS){
    loanDataState.loanParameters.filter(item=>
        {
          if(item.sectionKey===1){
            business_Info.push(item)
          }
          else if(item.sectionKey===2){
            client_Info.push(item)
          }
          else if(item.sectionKey===3){
            residential_Address.push(item)
          }

          else if(item.sectionKey===4){
            loan_Details.push(item)
          }
          else if(item.sectionKey===5){
            sales_Estimate.push(item)
          }
        }
      )

     
    
    formContent = 
    <form onSubmit={handleSubmit(onSubmit)} style={{display:'flex',flexDirection:'column',backgroundColor:'transparent'}}>
          <>
            
            <div style={{width:'100%',display:formPage===1?'flex':'none',flexDirection:'column',gap:'2em',alignItems:"center"}}>
                <ClientIdSection fixedClientFields={fixedClientFields} setValue={setValue} setFixedClientFields={setFixedClientFields} register={register} erros={errors} handleListSelect={handleListSelect} info={client_Info}/>
                <ResidentialAddressSection register={register} setValue={setValue} erros={errors} handleListSelect={handleListSelect} info={residential_Address}/>
                <LoanDetSection register={register} setValue={setValue} erros={errors} handleListSelect={handleListSelect} info={loan_Details}/>

                <div className='buttonHold'>
                  <section onClick={()=>{setFormPage(2)}}>Next</section>
                </div>
            </div>
            <div style={{width:'100%',display:formPage===2?'flex':'none',flexDirection:'column',gap:'2em',alignItems:"center"}}>
                <BusinessInfoSection register={register} erros={errors} setValue={setValue} handleListSelect={handleListSelect} info={business_Info}/>
              <SalesEstimateSection register={register} erros={errors} setValue={setValue} getValues={getValues} handleListSelect={handleListSelect} info={sales_Estimate}/>
                <div {...getRootProps({style})} style={{borderRadius:'7px',cursor:'pointer',width:'100%'}}>
                    <p style={{fontSize:'16px'}}>Client Signature</p>
                    <section style={{height:'70px',fontSize:'16px',border:'1px dashed rgba(64, 123, 255, 1)',display:'flex',alignItems:'center',color:'rgba(240, 246, 255, 1)',justifyContent:'center',color:'rgba(64, 123, 255, 1)'}}>
                        {'Upload file here'}
                        <input {...getInputProps()} />
                    </section>
                    
                </div>
                <div style={{display:"flex",columnGap:'5px',marginTop:'20px'}}>
                        
                    {showName?selectedImgList.map((holder,index)=>
                      <div  className='imageAndCancelPreHold' >
                          <section onClick={()=>preparePreviewObj(index,false)} className='imagePreHold'>{holder.file.name}</section>
                          <span onClick={()=>preparePreviewObj(index,true)} className='imgCancel'><img src={cancelImg}/></span>
                      </div>)
                      :''
                      }
                          
                </div> 
                <div  className='buttonHold'>
                  <div onClick={()=>submitDraft()} className='saveText'>Save in Drafts.</div>
                  <section className='back' onClick={()=>{setFormPage(1)}}>Back</section>
                  <section  onClick={()=>fillFormObj()}>Submit</section>
                  
                </div>
                
            </div>
          </>
    </form>
  }

  if(loanDataState.loanParametersStatus === FetchDataStatus.FAILURE){
    formContent = 
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanDataState.errorMessageParameters}</p>
      </div>
  }

  //onClick={()=>{setFormPage(2);fillFormObj()}}
  useEffect(()=>{
    if(signatureState.signatureStatus===PostDataStatus.SUCCESS && signatureState.signature!==null){
      const UUIDholder = []
      const rawSignatureData = [...signatureState.signature.data]
      rawSignatureData.forEach((data,index)=>{
        let mainUUID = data.split('/')[0]
        UUIDholder.push(mainUUID)
      })
      setclientSignaturePath(UUIDholder)
      
    }
  },[signatureState])

  useEffect(()=>{
    if(!showName){
      setImgUrl(null);
      setImgFile(null)
      setFilehold(null)
    }
  },[showName])



  return (
    <div className="sign-up-details-form-main">
      <div style={{width:'100%',height:'100%',display:"flex"}}>
        {formContent}
       
      </div>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Add Signature" >
          <SignatureUploadModal setStateForPreview={setStateForPreview} setShowForm={setShowForm} setShowName={setShowName} 
                                stateForPreview={stateForPreview} imgUrl={imgUrl} setImgUrl={setImgUrl} 
                                imgFile={imgFile} accessToken={accessToken} setUploadError={setUploadError} 
                                closeModal={closeModal} uploadError={uploadError} setSelectedImgList={setSelectedImgList}
                                selectedImgList={selectedImgList}
                                />
      </Modal>
      
      <Modal isOpen={modalFormIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeFormModal} style={customStyles}
          contentLabel="Form Upload" >
          <FormUploadModal setShowForm={setShowForm} imgUrl={imgUrl} imgFile={imgFile} 
                           accessToken={accessToken} setUploadError={setUploadError} 
                           closeModal={closeFormModal} uploadError={uploadError}/>
      </Modal> 

      <Modal isOpen={draftModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeDraftModal} style={customStyles}
          contentLabel="Draft Upload" >
          <DraftUploadModal setShowForm={setShowForm}
                           accessToken={accessToken} 
                           closeModal={closeDraftModal}/>
      </Modal> 

      <Modal isOpen={previewDeleteIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closePreviewDeleteModal} style={customStyles}
          contentLabel="Signature View And Delete." >
        <PreviewDeleteModal hitDelete={hitDelete} previewObj={previewObj} accessToken={accessToken} 
                            selectedImgList={selectedImgList} setSelectedImgList={setSelectedImgList}
                            closeModal={closePreviewDeleteModal}
                            />
      </Modal>
    </div>
  )
}

export default LoanForm


/**
 *  <form onSubmit={handleSubmit(onSubmit)} className="card">
        {formContent}
      </form>
 */