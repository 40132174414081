import React from 'react'
import './TableReform.css'


function TableReform({headers,Body}) {
  return (
    <div className='tablePremblyHold'>
        <section className='roof'>
            {headers.map(element=>
            <section className='itemHold'>
                {element}
            </section>)}
        </section>
        <section >
            {Body.map(element=>
            <section className='body' >
                {element.map(item=>
                <section className='itemHoldBody'>
                    {item}
                </section>)}
            </section>)}
        </section>
    </div>
  )
}

export default TableReform