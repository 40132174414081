import React from 'react'
import Utils from '../../../../../library/Utils'

function SerialOutPutPrint({keys,data,header}) {
  return (
    <div style={{
        width:"100%",
        backgroundColor:"white",
        boxSizing:"border-box",
        marginTop:"30px",
        padding:'20px',
        borderRadius:"10px"
    }}>

        <section style={{
            color:"#2A38A4",
            fontSize:"24px",
            fontWeight:'700'
        }}>
            {header}
        </section>

        <section style={{display:"flex",flexDirection:"column",rowGap:'5px'}}>
            {keys.map((element,index)=>
            <div style={{
                display:"flex",
                justifyContent:'space-between',
                marginTop:"15px"
            }}>
                <section style={{}}>
                    {element}
                </section>

                <section style={{
                    color:'#000000',
                    fontSize:"16px",
                    fontWeight:"700"
                }}>
                    {!Utils.isFieldEmpty(data[element])? data[element]:'--'}
                </section>
            </div>)}
        </section>
    </div>
  )
}

export default SerialOutPutPrint