import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus,PostDataStatus } from "../../../../library/Variables";



 export const getAuditDetails = createAsyncThunk('clientAudit', async (params) => {
   const audit = await client.get('/platformuser/get-audit-logs/1/300',
 
   {
     "headers":
     {
       "Authorization": "Bearer " + params.accessToken
     }
   });

    return audit.data;
   }
 );


export const clientAuditSlice = createSlice({
  name: 'clientAudit',
  initialState: {
    clientAuditData: { },
    clientAuditStatus: FetchDataStatus.INITIAL,
    clientAuditIsError: false,
    clientAuditErrorMessage: null
  },
  reducers: {
    resetClientAudit: state => {
      state.clientAuditData = { };
      state.clientAuditStatus = FetchDataStatus.INITIAL;
      state.clientAuditIsError = false;
      state.clientAuditErrorMessage = null
    }
  },
  extraReducers(builder) {
    builder.addCase(getAuditDetails.pending, (state) => {
      state.clientAuditStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAuditDetails.fulfilled, (state, action) => {      
      state.clientAuditStatus = FetchDataStatus.SUCCESS;
      state.clientAuditData = action.payload;
    })
    .addCase(getAuditDetails.rejected, (state, action) => {
      state.clientAuditStatus = FetchDataStatus.FAILURE;
      state.clientAuditIsError = true;
      state.clientAuditErrorMessage = action.error.message;
    })
 
  }
});

export const { resetClientAudit } = clientAuditSlice.actions;

export const selectClientAuditStatus = state => state.clientAudit;

export default clientAuditSlice.reducer;