import { CustomDialog } from "../Modal";
import Spinner from "../loader";

import styles from "./index.module.css";

export const ConfirmDialog = ({
  open,
  toggleModal,
  okHandler,
  cancelHandler,
  cancelText = "Cancel",
  okText = "Submit",
  loading,
  description = "Are you sure you want to block this transaction?",
}) => {
  return (
    <CustomDialog
      open={open}
      toggleModal={toggleModal}
      showTitle={false}
      showCancelBtn={false}
      contentStyle={{
        maxWidth: "446px",
        borderRadius: "20px",
        paddingBlock: "60px",
      }}
    >
      <div className={styles.containerWrapper}>
        <p className={styles.confirmText}>{description}</p>

        <div className={styles.btnGroup}>
          <button onClick={cancelHandler} className={styles.confirmOutlined}>
            {cancelText}
          </button>
          <button
            disabled={loading}
            onClick={okHandler}
            className={styles.confirmContained}
          >
            {loading ? (
              <Spinner visible={loading} color="#fff" size={20} />
            ) : (
              okText
            )}
          </button>
        </div>
      </div>
    </CustomDialog>
  );
};
