import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PostDataStatus } from '../../../library/Variables'
import { selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import RulesAndParameters from './DefineNewRules/RulesAndParameters'
import DefineScoreCard from './DefineScoreCard/DefineScoreCard'

function RuleParamCardNavigator({setSection,accessToken}) {
  const [showCardCreated,setShowCardCreated] = useState(true)
  const [cardState,setCardState] = useState()
  const [paramName,setParamName] = useState('')
  const [mainScoreCardKey,setMainScoreCardKey] = useState('')
  const [mainParameterKey,setMainParameterKey] = useState('')
  const [paramMetaData,setParamMetaData] = useState('')
  const [customDecisionCard,setCustomDecisionCard] = useState(false)
  const selfState = useSelector(selectSelfServiceState)

  useEffect(()=>{
      if(selfState.customParametersCreationStatus === PostDataStatus.SUCCESS){
        setMainParameterKey(selfState.customParametersState?.key)
      }

      if(selfState.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
        setMainScoreCardKey(selfState.selfServiceCardRepsonse?.key)
      }
  },[selfState.customParametersCreationStatus,selfState.selfServiceCardNameStatus])

  return (
    <div>
        {showCardCreated?
            <DefineScoreCard setSection={setSection}
                            accessToken={accessToken}
                            setShowCardCreated={setShowCardCreated}
                            setParamName={setParamName}
                            cardState={cardState}
                            setCardState={setCardState}
                            mainScoreCardKey={mainScoreCardKey}
                            setMainParameterKey={setMainParameterKey}
                            paramName={paramName}
                            mainParameterKey={mainParameterKey}
                            setParamMetaData={setParamMetaData}
                            customDecisionCard={customDecisionCard}
                            setCustomDecisionCard={setCustomDecisionCard}
                            />
        :
            <RulesAndParameters setSection={setSection}
                            accessToken={accessToken}
                            setShowCardCreated={setShowCardCreated}
                            paramName={paramName}
                            setParamMetaData={setParamMetaData}
                            paramMetaData={paramMetaData}
                            mainParameterKey={mainParameterKey}
                            mainScoreCardKey={mainScoreCardKey}

        />}
    </div>
  )
}

export default RuleParamCardNavigator