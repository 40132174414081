import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { FetchDataStatus, PostDataStatus } from "../../../library/Variables";
import {
  selectCrbGhanaData
} from "../../../redux/Slices/CRB/Ghana/CrbGhana";
import dropDown from '../../../assests/svg-icons/dropDownIconTenant.svg'
import { linkUsersToTenant } from "../../../redux/Slices/Settings/MultiTenantManagement/TenantPerUser";
import cancelTenant from '../../../assests/svg-icons/cancelTenant.svg'
import FlagComponents from "./FlagComponents";
import { getAllTenants } from "../../../redux/Slices/Multinenant/MultiTenancyFile";
import { selectMutilTenantSlice } from "../../../redux/Slices/Multinenant/MultiTenancyFile";
import { fetchAllClients, selectTenantUsersState } from "../../../redux/Slices/Settings/MultiTenantManagement/TenantPerUser";
import './addTenant.css';
import './tenantModal.css';
import Utils from "../../../library/Utils";
import amlFilled from '../../../assests/icons/amlFound.png'
import amlEmpty from '../../../assests/icons/amlEmpty.png'
import errorImg from '../../../assests/icons/errorImage.png'
import { toast } from 'react-toastify';
import Spinner from "../../../svg/Spinner/Spinner";
import 'react-toastify/dist/ReactToastify.css';
import { resetAllClientsFetch,resetLinkedTenant } from "../../../redux/Slices/Settings/MultiTenantManagement/TenantPerUser";



function EditTenantModal({accessToken,close,existingTenants,clientName,setSelectedId,clientEmail,clientFlags}) {
  const dispatch = useDispatch();
  const multiAccountBDown = useSelector(selectMutilTenantSlice)
  const tenantState = useSelector(selectTenantUsersState)
  const [modalUiState,setModalUiState] = useState(null)
  const [userLists,setUserList] = useState([])
  const [loadTenants,setLoadTenants] = useState(true)
  const [tenantLists,setTenantLists] = useState([])
  const [openList,setOpenList] = useState(false)
  const [selectedTenants,setSelectedTenants] = useState([...existingTenants])
  const [userChoice,setUserChoice] = useState('')

  useEffect(()=>{
    if(tenantState.allTenantUserStatus === FetchDataStatus.INITIAL){
        dispatch(fetchAllClients({accessToken}))
    }

    if(tenantState.allTenantUserStatus === FetchDataStatus.SUCCESS){
        let userTempList = []
        tenantState.allTenantUsersData.forEach(item => {
            userTempList.push({ value: item.email, label: item.firstName + ' '+ item.lastName},);
          });
        setUserList(userTempList)
    }
    
  },[tenantState.allTenantUserStatus])

  const cancelNewTenant = (url,name)=>{
    setSelectedTenants((prevItems) => prevItems.filter((item) => item.name !== name));
    setTenantLists([...tenantLists,{flag:url,identifier:name}])
}


const closeSucModal = ()=>{
  dispatch(resetAllClientsFetch())
  setSelectedId('')
  close()
}

const closeFailView = ()=>{
  close()
  setSelectedId('')
  dispatch(resetLinkedTenant())
}

  const saveTenantDetails = ()=>{
    if(Utils.isFieldEmpty(selectedTenants || Utils.isFieldEmpty(userChoice))){
      toast.info('Fill all neccessary fields.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    else{
      const email = clientEmail
      //const baseIdentifier = 
      const baseTenantArr = multiAccountBDown.fetchTenantData.linkedTenants.filter((item) => item.isCurrentTenant === true)
      const baseTenant = baseTenantArr[0].identifier
      let allTenants = []
      selectedTenants.forEach(item => {
          allTenants.push(item.name);
        
        
        });
      
  


      const payload = {baseIdentifer:baseTenant,subIdentifier:allTenants,emailAddress:[email]}

      dispatch(linkUsersToTenant({data:payload,accessToken}))
    }
  }

  useEffect(()=>{
    if(multiAccountBDown.fetchTenantStatus===FetchDataStatus.INITIAL){
        dispatch(getAllTenants())
        setLoadTenants(true)
    }
    else if(multiAccountBDown.fetchTenantStatus===FetchDataStatus.FETCHING){
        setLoadTenants(true)
    }

    else if(multiAccountBDown.fetchTenantStatus===FetchDataStatus.SUCCESS){
      setLoadTenants(false)
      let tenantTempList = []
      const filteredTenants = multiAccountBDown.fetchTenantData.linkedTenants.filter((item) => !selectedTenants.includes(item.name));
      filteredTenants.forEach(item => {
          tenantTempList.push({ flag: item.flag, identifier: item.identifier},);
          });
      setTenantLists(tenantTempList)
  }

  
      
  },[multiAccountBDown.fetchTenantStatus])

  

  /**
   * const resetForm = () => {
    dispatch(resetConsumerCreditPost());
    dispatch(resetCommercialCreditPost())
  }
   */
  


  useEffect(()=>{
    setOpenList(true)
  },[selectedTenants])



  useEffect(()=>{
    if(tenantState.linkedTenantStatus===PostDataStatus.INITIAL){
        const InitialState = <section className="tenantModalBox">
                                 <div className='User'>
                                    Edit Exisiting User
                                </div>
                                <section style={{marginTop:"20px",display:'flex',flexDirection:"column",rowGap:"20px"}}>
                                    <div style={{display:'flex',flexDirection:'column',rowGap:"5px"}}>
                                        <span style={{fontSize:"16px",fontWeight:'500',color:"#363C4F"}}>User Name</span>
                                        <input style={{outline:'none',borderRadius:'7px',height:'35px',appearance:"none"}} value={clientName}/>
                                    </div>
                                    <div>
                                        <span style={{fontSize:"16px",fontWeight:'500',color:"#363C4F"}}>Accounts</span>
                                
                                        <section style={{width:'100%',height:"auto",maxHeight:'200px',overflow:'auto',border:'2px solid #DFE8FF',boxSizing:"border-box"}}>
                                          <div style={{display:"flex",height:"35px",alignItems:"center",padding:"0px 10px",justifyContent:"space-between"}}>
                                              <span style={{color:'#9598A2',fontSize:"16px"}}>
                                                Select Tenants...
                                              </span>
                                              <span onClick={()=>setOpenList(!openList)} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:'pointer'}}>
                                                <img src={dropDown}/>
                                              </span>
                                          </div>
                                          {openList && <section>
                                            {tenantLists.map((el)=><FlagComponents url={el.flag} 
                                                                                   name={el.identifier} 
                                                                                   selectedTenants={selectedTenants}
                                                                                   setTenantLists={setTenantLists}
                                                                                   setSelectedTenants={setSelectedTenants}
                                                                                   />)}
                                        </section>}
                                        {!Utils.isFieldEmpty(selectedTenants) && <>
                                          <section style={{display:"flex",
                                                           flexWrap:'wrap',
                                                           width:'90%',
                                                           margin:'10px auto',
                                                            rowGap:'10px',
                                                           columnGap:'10px'}}>
                                            {selectedTenants.map(comp=>
                                            <section style={{backgroundColor:"#D2E5FE",
                                                              display:"flex",
                                                              justifyContent:'space-around',
                                                              alignItems:'center',
                                                              width:"200px",
                                                              height:"40px",
                                                              borderRadius:"10px"}}>
                                                      <div style={{display:"flex",
                                                              
                                                              justifyContent:'center',
                                                              alignItems:'center',
                                                              columnGap:'5px',
                                                             }}>
                                                          <span style={{display:"flex",
                                                              justifyContent:'center',
                                                              alignItems:'center',}}>
                                                            <img src={comp.url}/>
                                                          </span>
                                                          <span>
                                                            {comp.name}
                                                          </span>
                                                      </div>
                                                      <section onClick={()=>cancelNewTenant(comp.url,comp.name)} style={{display:"flex",
                                                                        alignItems:"center",
                                                                        justifyContent:"center",
                                                                        cursor:"pointer"}}  >
                                                          <img src={cancelTenant}/>
                                                      </section>
                                            </section>)}
                                          </section>
                                        </>}
                                        </section>
                                        
                                    </div>
                                </section>
                                <section style={{marginTop:"20px",
                                                height:"50px",
                                                display:'flex',
                                                justifyContent:'flex-end',
                                                columnGap:'5px'}}>
                                    <div className="cancelTenant" onClick={()=>{close();setSelectedId('')}}>
                                      Cancel
                                    </div>
                                    <div className="saveTenant" onClick={()=>saveTenantDetails()}>
                                      Save
                                    </div>

                                </section>
                            </section>
        setModalUiState(InitialState)
    }

    if(tenantState.linkedTenantStatus===PostDataStatus.FETCHING){
      
      setModalUiState(<Spinner/>)
    }

    if(tenantState.linkedTenantStatus===PostDataStatus.SUCCESS){
      const successState = <div className="HolderTenantModal">
                              <span className="imgFlex"><img src={amlFilled}/></span>
                              <p style={{color:"#2A38A4",
                                         fontWeight:"700",
                                         fontSize:"24px"}}>User Successfullly linked to Tenant(s)</p>
                              <p style={{color:'#363C4F'}}>Proceed</p>

                              <span onClick={()=>closeSucModal()} className='successButton'>View Report</span>
                        </div>
      setModalUiState(successState)
    }
    

    if(tenantState.linkedTenantStatus===PostDataStatus.FAILURE){
      const failState = <div className="HolderTenantModal">
        <span className="imgFlexFail">
          <img style={{width:"30px",height:"30px"}} src={errorImg}/>
        </span>
        <p style={{color:'#363C4F'}}>Something went wrong!</p>
        <p style={{color:"red",fontWeight:"700",fontSize:"24px"}}>{tenantState.allTenantUserErrorMessage}</p>

        <span onClick={()=>closeFailView()} className='successButton'>Close</span>
    </div>
      setModalUiState(failState)
    }
    
    

  },[tenantState.linkedTenantStatus,tenantState.allTenantUserStatus,userLists,openList,selectedTenants,tenantLists,userChoice]
  )
  



  return (
    <div className="bureau-credit-check-dialog">
      <div className="bureau-credit-check-header">
        <img src="/assets/icons/close-color.svg" alt="Close" onClick={close} />
      </div>
      {modalUiState}
    </div>
  )
}

export default EditTenantModal;