import React from "react";
import { useFieldArray } from "react-hook-form";
import PaymentNestedFieldset from "./PaymentsNestedFieldset";
import AddressNestedFieldset from "./AddressNestedFieldset";
import ContactNestedFieldset from "./ContactNestedFieldset";

export default function Fields({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "statementBureauAccountData"
  });

  return (
    <div className="bureau-account-form-main" >
      <div className="bureau-form-header-with-button">
        <h3 className="detail-header">Accounts</h3>
        <div className="bureau-form-buttons">
          <button type="button" onClick={() => append()} className="button-square button-solid">Add Account</button>
        </div>
      </div>
      {fields.map((item, index) => {
        return <fieldset key={item.id}>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Account Name</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.accountName`, 
                { required: true,
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Invalid account name"
                  } })} />
            </div>
            <div className="bureau-input-container">
              <label>Account Number</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.accountNo`, 
                { required: true,
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Invalid account number"
                  } })} />
            </div>
            <div className="bureau-input-container">
              <label>Balance</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.balance`)} />
            </div>
            <div className="bureau-input-container">
              <label>Type</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.type`)} />
            </div>
            <div className="bureau-input-container">
              <label>Pay Status</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.payStatus`)} />
            </div>
            <div className="bureau-input-container">
              <label>Payment Terms</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.paymentTerms`)} />
            </div>
            <div className="bureau-input-container">
              <label>Date Opened</label>
              <input className="inputs account-form-inputs" type={'date'} {...register(`statementBureauAccountData.${index}.dateOpened`)} />
            </div>
            <div className="bureau-input-container">
              <label>Responsibility</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.responsibility`)} />
            </div>
            <div className="bureau-input-container">
              <label>Address Identification Number</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.addressIdentificationNumber`)} />
            </div>
            <div className="bureau-input-container">
              <label>Credit Limit</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.creditLimit`)} />
            </div>
            <div className="bureau-input-container">
              <label>High Balance</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.highBalance`)} />
            </div>
            <div className="bureau-input-container">
              <label>Monthly Payment</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.monthlyPayment`)} />
            </div>
            <div className="bureau-input-container">
              <label>Recent Payment Amount</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(`statementBureauAccountData.${index}.recentPaymentAmount`)} />
            </div>
          </div>
          <br />
          {/* Payment History */}
          <PaymentNestedFieldset nestIndex={index} {...{ control, register }} />
          <br />
          {/* Address History */}
          <AddressNestedFieldset nestIndex={index} {...{ control, register }} />
          <br />
          {/* Contact History */}
          <ContactNestedFieldset nestIndex={index} {...{ control, register }} />
          <br />
          <button type="button" onClick={() => remove(index)} className="button-square button-outline">Remove Account</button>
        </fieldset>
      })}
      <br />
    </div>
  );
}
