export const nameHistoryReShape = (data)=>{
    const historyObj = {title:"Name History",data:[]}

    for (let i =0; i<data.length; i++){
        
        let newArr = []
        newArr.push(data[i].titleCode)
        newArr.push(data[i].surName)
        newArr.push(data[i].firstName)
        newArr.push(data[i].otherNames)
        newArr.push(data[i].birthDate)
        newArr.push(data[i].lastUpdatedDate)

        historyObj.data.push(newArr)
    }

    return historyObj

}


export const telephoneHistoryReShape = (data)=>{
    const historyObj = {title:"Telephone History",data:[]}

    for (let i =0; i<data.length; i++){
        let newArr = []
        newArr.push(data[i].homeNoUpdatedonDate)
        newArr.push(data[i].homeTelephoneNumber)
        newArr.push(data[i].workNoUpdatedonDate)
        newArr.push(data[i].workTelephoneNumber)
        newArr.push(data[i].mobileNoUpdatedonDate)
        newArr.push(data[i].mobileTelephoneNumber)

        historyObj.data.push(newArr)
    }

    return historyObj

}


export const addressHistoryReShape = (data)=>{
    const historyObj = {title:"Address History",data:[]}

    for (let i =0; i<data.length; i++){
        let newArr = []
        newArr.push(data[i].address1 + ', '+data[i].address2 + ', '+data[i].address3 )
        newArr.push(data[i].addressTypeInd)
        newArr.push(data[i].upDateDate)
        newArr.push(data[i].upDateOnDate)
    

        historyObj.data.push(newArr)
    }

    return historyObj

}


export const employmentHistoryReShape = (data)=>{
    const historyObj = {title:"Employment History",data:[]}

    for (let i =0; i<data.length; i++){
        let newArr = []
        newArr.push(data[i].occupation)
        newArr.push(data[i].employerDetail)
        newArr.push(data[i].upDateDate)
        newArr.push(data[i].upDateOnDate)

        historyObj.data.push(newArr)
    }

    return historyObj

}


export const identificationHistoryReShape = (data)=>{
    const historyObj = {title:"Identification History",data:[]}

    for (let i =0; i<data.length; i++){
        let newArr = []
        newArr.push(data[i].identificationType)
        newArr.push(data[i].identificationNumber)
        newArr.push(data[i].upDateDate)
        newArr.push(data[i].upDateOnDate)

        historyObj.data.push(newArr)
    }

    return historyObj

}