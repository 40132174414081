import React,{useState} from 'react'
import Utils from '../../../../../library/Utils'

function HistoryComponentSlice({pData,ArrowUp,ArrowDown}) {
  const [showSlice,setShowSlice] = useState(false)
  return (
    <div style={{width:'100%',padding:'20px 0px'}}>
                <section style={{width:'100%',display:"flex",alignItems:'center',padding:'20px 0px'}}>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData.loanProvider)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.accountNumber)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.type)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.currency)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.loanAmount)}
                    </span>

                    <span style={{cursor:"pointer"}} onClick={()=>setShowSlice(!showSlice)}>
                        <img src={showSlice?ArrowUp:ArrowDown}/>
                    </span>
                </section>

                <>{showSlice&&
                <section style={{width:'100%',display:"flex",alignItems:'center',padding:'20px 0px',flexWrap:"wrap",rowGap:"20px"}}>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Outstanding Balance</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.outstandingBalance)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Installment Amount</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.installmentAmount)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Repayment Frequency</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.repayment)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Overdue Amount</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.overdueAmount)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Loan Duration</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.loanDuration)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Date Reported</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.dateReported)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Disbursed Date</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.disbursedDate)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Expiry Date</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.expiryDate)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Status</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.status)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Performance Status</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.performanceStatus)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Last Payment Date</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.lastPaymentDate)}</span>
                        
                    </div>
                </section>}</>
            </div>
  )
}

export default HistoryComponentSlice