import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WebHookClient } from "../../../api/webHookApi";
import { PostDataStatus,FetchDataStatus } from "../../../library/Variables";



const {getRequest} = WebHookClient

export const getAllTenants = createAsyncThunk ('multiaccount/refetch',async()=>{
    const detailsResponse = await getRequest('/insights/getmultiaccountinfo');
    return detailsResponse.data;
} )



 


export const multiAccountSLice = createSlice({
    name: 'multiAccount',
    initialState: {

      fetchTenantStatus: FetchDataStatus.INITIAL,
      fetchTenantData: null,
      isErrorfetchTenant:false,
      errorfetchTenant:null,
    },

    reducers: {
        resetMultiTenant: state => {

            state.fetchTenantStatus =  FetchDataStatus.INITIAL;
            state.fetchTenantData =  null;
            state.isErrorfetchTenant = false;
            state.errorfetchTenant = null;
        }
      
    },
    extraReducers(builder) {
        builder.addCase(getAllTenants.pending, (state) => {
            state.fetchTenantStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getAllTenants.fulfilled, (state, action) => {
            state.fetchTenantStatus = FetchDataStatus.SUCCESS;
            state.fetchTenantData = action.payload;
          })
          .addCase(getAllTenants.rejected, (state, action) => {
            state.fetchTenantStatus = FetchDataStatus.FAILURE;
            state.isErrorfetchTenant = true;
            state.errorfetchTenant = action.error.message;
          })

    }
})


export const { resetMultiTenant } = multiAccountSLice.actions;

export const selectMutilTenantSlice = state => state.multiAccount
export default multiAccountSLice.reducer;