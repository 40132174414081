import React from "react";

function CreditCheckCards(props) {
  const field = props.field;

  return <div className="bureau-creditcheck-type-card" onClick={() => props.parentCallback()}>
    <img src="/assets/icons/card-check-background.svg" alt=""/>
    <h3 className="text-darker">{field.title}</h3>
    <p className="text-dark">{field.subtitle}</p>
  </div>
}

export default CreditCheckCards;