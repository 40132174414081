import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getConfigurationAsync = createAsyncThunk('configuration', async (params) => {
  const configuration = await client.get('/configuration');
  return configuration.data;
});

export const configSlice = createSlice({
  name: 'configuration',
  initialState: {
    configurationStatus: FetchDataStatus.INITIAL,
    configuration: {},
    isError: false,
    errorMessage: null
  },
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getConfigurationAsync.pending, (state) => {
      state.configurationStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getConfigurationAsync.fulfilled, (state, action) => {      
      state.configurationStatus = FetchDataStatus.SUCCESS;
      state.configuration = action.payload;
    })
    .addCase(getConfigurationAsync.rejected, (state, action) => {
      state.configurationStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const selectConfigurationState = state => state.configuration;

export default configSlice.reducer;