import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { PostDataStatus } from "../../library/Variables";


export const keyCloackMigration = createAsyncThunk('migrant', async (params) => {
    const data = JSON.stringify(params)
    const newUser = await client.post(`/application/create-keycloak-user`,data,{
        "headers":
          {
            'Content-Type': 'application/json'
          }
      });
    return newUser.data;
    }
);


export const migrationSlice = createSlice({
    name: 'migrant',
    initialState:{
         migrationStatus: PostDataStatus.INITIAL
    },
    reducers: {
        resetMigrationState: state => {
            state.migrationStatus = PostDataStatus.INITIAL
        }
    },
    extraReducers(builder) {
        builder.addCase(keyCloackMigration.pending, (state) => {
            state.migrationStatus = PostDataStatus.FETCHING
        })
        .addCase(keyCloackMigration.fulfilled, (state)=>{
            state.migrationStatus = PostDataStatus.SUCCESS
        })
        .addCase(keyCloackMigration.rejected, (state)=>{
            state.migrationStatus = PostDataStatus.FAILURE
        })
    }
})


export const { resetMigrationState } = migrationSlice.actions;
export const selectMigrationState = state => state.migrant
export default migrationSlice.reducer;


