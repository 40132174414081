import React from 'react'

function FlagComponents({url,name,setTenantLists,setSelectedTenants,selectedTenants}) {

  const selectNewTenant = (url,name)=>{
    setTenantLists((prevItems) => prevItems.filter((item) => item.identifier !== name));
    setSelectedTenants([...selectedTenants,{name,url}])
}

  return (
    <div onClick={()=>selectNewTenant(url,name)} style={{width:"90%",
                 height:"40px",
                 display:"flex",
                 columnGap:"10px",
                 justifyContent:"flex-start",
                 alignItems:"center",
                 cursor:'pointer',
                 border:'1px solid #DFE8FF',
                 margin:"15px auto"}}>
        <span style={{width:"40px",
                 display:"flex",
                 alignItems:"center",
                 justifyContent:"center"}}>
            <img src={url}/>
        </span>
        <span>
            {name}
        </span>
    </div>
  )
}

export default FlagComponents