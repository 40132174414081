import './Login.css';
import {useEffect, useState,useRef } from 'react';
import auth0 from  'auth0-js';
import Utils from "../../library/Utils";
import { 
  useSelector,
  useDispatch 
} from 'react-redux';
import { 
  selectLoggedIn, 
  selectLoggingIn,
  failedLogin,
  loggedIn,
  loggingIn,
  reset,
  resetResetPassWord,
  getKeyCloakToken,
  addNewUserAsync,
  resetPasswordAsync,
  selectaddNewUserState,
  selectresetPasswordState,
  selectkeycloakTokenState
} from '../../redux/Slices/Auth';
import { selectMigrationState,
  resetMigrationState} 
  from '../../redux/Slices/CreateKeyCloackUser';
import Spinner from '../../svg/Spinner/Spinner';
import { DateTime } from 'luxon';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginFormTypes, FetchDataStatus, PostDataStatus } from '../../library/Variables';
import { keyCloackMigration } from '../../redux/Slices/CreateKeyCloackUser';

function Login(props) {
  let mainContent = <div style={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-around',
                            rowGap:'15px'
                          }}>
                          <Spinner />
                          <p>Please Wait . . .</p>
                    </div>;
  let formContent = null;
  const dispatch = useDispatch();
  const loggedInState = useSelector(selectLoggedIn);
  const loggingInState = useSelector(selectLoggingIn);
  const keycloakToken = useSelector(selectkeycloakTokenState);
  const resetPassword = useSelector(selectresetPasswordState)
  const [mainUser,setMainUser] = useState('')
  const configuration = props.configuration;
  const publicKey = configuration.publicKey
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { 
    register: registerResetPassword,
    formState: { errors: errorsResetPassword },
    handleSubmit: handleSubmitResetPassword } = useForm();
  const [view, setView] = useState(LoginFormTypes.LOGIN);
  const [auth] = useState(new auth0.WebAuth({
    domain: configuration.auth0.domain,
    clientID: configuration.auth0.clientId,
    responseType: 'token id_token'
  }));

  function onSubmitLogin(formData){
    dispatch(loggingIn());
    if(keycloakToken.keyCloakTokenStatus === PostDataStatus.INITIAL){
      setMainUser(formData.username)
      dispatch(getKeyCloakToken({
        realm: configuration.keyCloack.realm,
        username: formData.username,
        password: formData.password,
        client_id: configuration.keyCloack.frontendClientId,
        grant_type: 'password',
        base_url : configuration.keyCloack.baseUrl
      }) );
    }
      
  }


  useEffect(() => {
    if (keycloakToken.keyCloakTokenStatus === PostDataStatus.SUCCESS 
      && !Utils.isFieldEmpty(keycloakToken.keyCloakToken.access_token)) {
        localStorage.setItem('insightsAccessToken', keycloakToken.keyCloakToken.access_token);
        localStorage.setItem('insightsAccessExpiry', (keycloakToken.keyCloakToken.expires_in - 300));
        localStorage.setItem('insightsLoggedInTime', DateTime.now());
    
        dispatch(loggedIn({'accessToken': keycloakToken.keyCloakToken.access_token}));
      }else if(keycloakToken.keyCloakTokenStatus === PostDataStatus.FAILURE){
        dispatch(failedLogin());
        toast.error('Invalid Login Credentials', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        dispatch(reset());
    }
  }, [keycloakToken, dispatch])

  const onSubmitResetPassword = (formData) => {
    if(resetPassword.resetPasswordStatus === PostDataStatus.INITIAL) {
      dispatch(resetPasswordAsync({
        email: formData.email,
        tenant: configuration.identifier
      }));
    }
  }

  useEffect(() => {
    if (resetPassword.resetPasswordStatus === PostDataStatus.SUCCESS) {
        toast.success('A reset password request has been sent', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setView(LoginFormTypes.LOGIN);
        dispatch(reset());
      }

    if(resetPassword.resetPasswordStatus === PostDataStatus.FAILURE){
      dispatch(reset())
      toast.error('Something went wrong, kindly try again.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [resetPassword, dispatch])


  

  if(!loggingInState && !loggedInState ) {
    if(view === LoginFormTypes.LOGIN) {
      formContent = <>
        <h2 className='detail-header'>Log in to Insights</h2>
        <form onSubmit={handleSubmit(onSubmitLogin)} className="login-form-container">
          <div className="login-input-container">
            <label className="input-labels">Username</label>
            <div className="input-container">
              <input className="login-inputs inputs" type={'text'} placeholder="Enter your username"
                {...register("username", { required: "Username is required" })} />
              <img src={'/assets/icons/profile.svg'} className="login-icon input-icon user" alt='User icon' />
            </div>
            <span className="error-msg">{errors.username?.message}</span>
          </div>
          <div className="login-input-container">
            <label className="input-labels">Password</label>
            <div className="input-container">
              <input className="login-inputs inputs" type={'password'} placeholder="Enter your password"
                {...register("password", { required: "Password is required" })} />
              <img src={'/assets/icons/lock.svg'} className="login-icon input-icon lock" alt='Password icon' />
            </div>
            <span className="error-msg">{errors.password?.message}</span>
          </div>
          <div className="login-link" onClick={() => setView(LoginFormTypes.FORGOT_PASSWORD)}>Forgot Password?</div>
          <div className="buttons-container">
            <button type='submit' className="button-square btn-login">Log In</button>
          </div>
        </form>
      </>
    } else if(view === LoginFormTypes.FORGOT_PASSWORD) {
      formContent = <>
        <h2 className='detail-header'>Reset Your Password</h2>
          <form onSubmit={handleSubmitResetPassword(onSubmitResetPassword)} className="login-form-container">
          <div className="login-input-container">
            <label className="input-labels">Email</label>
            <div className="input-container">
              <input className="login-inputs inputs" type={'text'} placeholder="Enter your email"
                {...registerResetPassword("email",  { required: "Email is required", pattern: {
                  value: /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,7}$/,
                  message: "Invalid email" }})
                } />
              <img src={'/assets/icons/profile.svg'} className="login-icon input-icon user" alt='User icon' />
            </div>
            <span className="error-msg">{errorsResetPassword.email?.message}</span>
          </div>
          <div className="login-link" onClick={() => setView(LoginFormTypes.LOGIN)}>{"<<"} Back to Login</div>
          <div className="buttons-container">
            <button type='submit' className="button-square btn-login">
                {resetPassword.resetPasswordStatus === PostDataStatus.FETCHING?
                  'SUBMITTING...':
                  'SUBMIT'}
            </button>
          </div>
        </form>
      </>
    }
    
    mainContent = <div className="login-right-container">
      <img src='/assets/logo/logo_small.svg' className='login-insights-logo' alt='Insights' />
      {formContent}
    </div>
  }

  

  return(
    <div className="login-body">
      <div className="login-main">
        <div className="login-left">
          <div className='login-left-container'>
            <img src='/assets/images/bg-image.svg' alt='' />
          </div>
        </div>     
        <div className="login-right">
          {mainContent}      
        </div>
      </div>
    </div>
  )
}

export default Login;