import Utils from "../../../../../library/Utils"


export const CrbHistoryKenyaSerializer = (arr)=>{
    let crbHistoryReshaped = []
    for (let i=0;i<arr.length;i++){
      
            const newObj = {}
            newObj.status = 'FOUND'
            newObj.createdDate = !Utils.isFieldEmpty(arr[i].createdDate)?arr[i].createdDate:'--'
            newObj.report = 'Bureau Data'
            newObj.fullName = !Utils.isFieldEmpty(arr[i]?.data?.personal_profile?.full_name)?arr[i]?.data?.personal_profile?.full_name:'--'
            newObj.dateOfBirth = !Utils.isFieldEmpty(arr[i]?.data?.personal_profile?.date_of_birth)?arr[i]?.data?.personal_profile?.date_of_birth:'--'
            newObj.tag = !Utils.isFieldEmpty(arr[i]?.crbType)?arr[i]?.crbType:'--'
            newObj.idNumber = Utils.checkNull(arr[i]?.idOrRegistration)
            newObj.index = i

            crbHistoryReshaped.push(newObj)
   

    }
        return crbHistoryReshaped

    
}



/**
 * newObj.IDNUMBER = !Utils.isFieldEmpty(arr[i].ghanaConsumerCreditData
                               .personalDetailsSummary.cellularNo)
                               ?arr[i].ghanaConsumerCreditData
                               .personalDetailsSummary.cellularNo
                               :'--'
 */