import React, { useMemo,useEffect } from "react";
import Utils from "../../../../library/Utils";
import DetailsCard from "../../../../components/DetailsCard/DetailsCard";
import List from "../../../../components/List/List";
import "./BureauReport.css";
import { TableView } from "./TableView";
import { BureauCreditchekSourceTypes } from "../../../../library/Variables";

function BureauReportIndividual(props) {
  const configuration = props.configuration;
  const fieldData = props.report;

  
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])

  const theadersCreditEnquiries = useMemo(() => [
    { 
      Header: "Date",
      accessor: "date",
      disableSortBy: true
    },
    { 
      Header: "Loan Type",
      accessor: "loanType",
      disableSortBy: true
    },
    {
      Header: "Institution Type",
      accessor: "institutionType",
      disableSortBy: true
    }], []
  )

  const theadersCreditEnquiriesProvider = useMemo(() => [
    { 
      Header: "Date",
      accessor: "date",
      disableSortBy: true
    },
    { 
      Header: "Loan Provider",
      accessor: "loanProvider",
      disableSortBy: true
    }], []
  )

  const theadersLoanPerformance = useMemo(() => [
    { 
      Header: "Provider",
      accessor: "loanProvider",
      disableSortBy: true
    },
    { 
      Header: "Amount",
      accessor: "loanAmount",
      disableSortBy: true
    },
    { 
      Header: "Status",
      accessor: "status",
      disableSortBy: true
    },
    { 
      Header: "Outstanding Balance",
      accessor: "outstandingBalance",
      disableSortBy: true
    },
    { 
      Header: "Overdue Amount",
      accessor: "overdueAmount",
      disableSortBy: true
    },
    { 
      Header: "Performance Status",
      accessor: "performanceStatus",
      disableSortBy: true
    }], []
  )

  const theadersLoanHistory = useMemo(() => [
    { 
      Header: "Provider",
      accessor: "loanProvider",
      disableSortBy: true
    },
    { 
      Header: "Account",
      accessor: "accountNumber",
      disableSortBy: true
    },
    { 
      Header: "Type",
      accessor: "type",
      disableSortBy: true
    },
    { 
      Header: "Amount",
      accessor: "loanAmount",
      disableSortBy: true
    },
    { 
      Header: "Status",
      accessor: "status",
      disableSortBy: true
    },
    { 
      Header: "Outstanding Balance",
      accessor: "outstandingBalance",
      disableSortBy: true
    }], []
  ) 

  const theadersCrcLoanHistory = useMemo(() => [
    { 
      Header: "Provider",
      accessor: "loanProvider",
      disableSortBy: true
    },
    { 
      Header: "Account",
      accessor: "accountNumber",
      disableSortBy: true
    },
    { 
      Header: "Type",
      accessor: "type",
      disableSortBy: true
    },
    { 
      Header: "Amount",
      accessor: "loanAmount",
      disableSortBy: true
    },
    { 
      Header: "Status",
      accessor: "status",
      disableSortBy: true
    }], []
  ) 
  
  return (
    <div className="bureau-report-main">
      <div className="bureau-report-view-col">
        <DetailsCard title="Customer Profile" titleClass="detail-header" bodyExtraClass="details-bottom"
          detailContent={
            <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"}
              listContent={[
                { header: "Full Name", detail: Utils.checkNull(fieldData.fullName)},
                { header: "BVN", detail: fieldData.bvn },
                { header: "Business ID", detail: Utils.formatRegionNames(Utils.checkNull(fieldData.businessId))},
                { header: "Date of Birth", detail: fieldData.dateOfBirth},
                { header: "Gender", detail: fieldData.gender},
                { header: "Address", detail: fieldData.address}
              ]} />
          } 
        />
        <DetailsCard title="Report Info" titleClass="detail-header" bodyExtraClass="details-bottom"
          detailContent={
            fieldData.data.map((element, i) => {
              let listItems = [
                { header: "Total Number of Delinquent Facilities", detail: Utils.checkNull(element.totalNoOfDelinquentFacilities)},
                { header: "Last reported date", detail: Utils.checkNull(Utils.formatDate(element.lastReportedDate))},
                { header: "Total number of institutions", detail: Utils.checkNull(element.totalNoOfInstitutions)},
                { header: "Total number of loans", detail: Utils.checkNull(element.totalNoOfLoans)},
                { header: "Total number of performing loans", detail: Utils.checkNull(element.totalNoOfPerformingLoans)},
                { header: "Total number of active loans", detail: Utils.checkNull(element.totalNoOfActiveLoans)},
                { 
                  header: "Total borrowed", 
                  detail: Utils.formatCurrency(
                    configuration.currency.format, 
                    configuration.currency.symbol, 
                    parseFloat(element.totalBorrowed.replaceAll(",", "")))
                },
                { 
                  header: "Total outstanding", 
                  detail: Utils.formatCurrency(
                    configuration.currency.format, 
                    configuration.currency.symbol, 
                    parseFloat(element.totalOutstanding.replaceAll(",", "")))
                },
                { 
                  header: "Total overdue", 
                  detail: Utils.formatCurrency(
                    configuration.currency.format, 
                    configuration.currency.symbol, 
                    parseFloat(element.totalOverdue.replaceAll(",", "")))
                },
                { 
                  header: "Highest Loan Amount", 
                  detail:  Utils.formatCurrency(
                    configuration.currency.format, 
                    configuration.currency.symbol, 
                    element.highestLoanAmount)
                },
                { header: "Maximum number of days", detail: Utils.checkNull(element.maxNoOfDays)},
                { header: "Total number of closed loans", detail: Utils.checkNull(element.totalNoOfClosedLoans)}
              ];

              if(!Utils.isFieldEmpty(element.crcReportOrderNumber)) {
                listItems.push({ header: "CRC report order number", detail: Utils.checkNull(element.crcReportOrderNumber) })
              }

              if(!Utils.isFieldEmpty(element.firstCentralEnquiryResultID)) {
                listItems.push({ header: "First Central Enquiry Result ID", detail: Utils.checkNull(element.firstCentralEnquiryResultID) })
              }

              if(!Utils.isFieldEmpty(element.firstCentralEnquiryEngineID)) {
                listItems.push({ header: "First Central Enquiry Engine ID", detail: Utils.checkNull(element.firstCentralEnquiryEngineID) })
              }

              return <React.Fragment key={i}>
                <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"} 
                  title={element.sourceType.replaceAll("_", " ")} listContent={listItems} />
                {!Utils.isFieldEmpty(element.creditEnquiries)
                  ? Utils.isFieldEmpty(element.creditEnquiries[0].loanProvider)
                    ? <TableView data={element.creditEnquiries} theaders={theadersCreditEnquiries} title="Credit Enquiries" />
                    : <TableView data={element.creditEnquiries} theaders={theadersCreditEnquiriesProvider} title="Credit Enquiries" />
                  : null
                }
                {!Utils.isFieldEmpty(element.loanPerformance)
                  ? <TableView data={element.loanPerformance} theaders={theadersLoanPerformance} title="Loan Performance" />
                  : null
                }
                {!Utils.isFieldEmpty(element.loanHistory) 
                  ? element.sourceType === BureauCreditchekSourceTypes.CRC
                    ? <TableView data={element.loanHistory} theaders={theadersCrcLoanHistory} title="Loan History" />
                    : <TableView data={element.loanHistory} theaders={theadersLoanHistory} title="Loan History" />
                  : null
                }
              </React.Fragment>
            })
          }
        />
      </div>
      <div className="bureau-back-button-container">
        <button className="button-square button-outline" onClick={props.callbackFunction}>Back</button>
      </div>
    </div>
  )
}

export default BureauReportIndividual;