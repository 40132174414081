import { useEffect, useState } from "react";
import { clientApi } from "../../../../api/clientApi";
import { CustomDialog } from "../Modal";
import Spinner from "../loader";

export const AssetDialog = ({ open, toggleModal, docUrl, docName }) => {
  const ext = docName.split(".").pop();

  const fileExt = ext?.toLowerCase();

  const [url, setUrl] = useState();

  const [loading, setLoading] = useState("loading");
  useEffect(() => {
    const getFileHandler = async () => {
      try {
        setLoading("loading");
        const data = { FileUri: docUrl };
        const response = await clientApi.post(
          `/api/transactions/view-file`,
          JSON.stringify(data),
          {
            "Content-Type": "application/json",
          }
        );

        setUrl(response?.data);
        setLoading("success");
      } catch (ex) {
        setLoading("fail");
      }
    };

    getFileHandler();
  }, [docUrl]);
  return (
    <CustomDialog
      open={open}
      toggleModal={toggleModal}
      showTitle={false}
      showCancelBtn={true}
      contentStyle={{
        maxWidth: "600px",
        borderRadius: "10px",
        paddingBlock: "30px",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {loading === "loading" && (
        <div
          className="spinnerWrapper"
          style={{ width: "100%", height: "80vh", position: "relative" }}
        >
          <Spinner visible={loading} color="#130F26" size={20} />
        </div>
      )}
      {loading === "fail" && (
        <div
          className="spinnerWrapper"
          style={{ width: "100%", height: "80vh", position: "relative" }}
        >
          Something went wrong.
        </div>
      )}
      {url && (
        <>
          {fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg" ? (
            <figure
              style={{ width: "500px", height: "80vh", position: "relative" }}
            >
              <img
                alt={docName}
                src={`data:image/png;base64,${url}`}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  objectFit: "cover",
                  top: "0",
                  left: "0",
                  bottom: 0,
                  right: 0,
                  display: "block",
                }}
              />
            </figure>
          ) : (
            <iframe
              title="Display"
              style={{ width: "100%", height: "80vh", margin: "auto" }}
              src={`data:application/pdf;base64,${url}`}
            />
          )}
        </>
      )}

      {/* <PDFViewer docUrl={docUrl} /> */}
    </CustomDialog>
  );
};
