import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getStatementDetails = createAsyncThunk('statements/details', async (params) => {
    const statement = await client.get(`/statements/${params.keyFromRoute}`,
    {
      "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
    });

    return statement.data;
  }
);

export const statementDetailsSlice = createSlice({
  name: 'statementDetails',
  initialState: {
    activeStatement: { },
    statementDetailsStatus: FetchDataStatus.INITIAL,
    isLoading: true,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetActiveStatement: state => {
      state.activeStatement = {};
      state.statementDetailsStatus = FetchDataStatus.INITIAL;
      state.isLoading = true;
      state.errorMessage = null;
      state.isLoading = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(getStatementDetails.pending, (state) => {
      state.statementDetailsStatus = FetchDataStatus.FETCHING;
      state.isLoading = true;
    })
    .addCase(getStatementDetails.fulfilled, (state, action) => {      
      state.statementDetailsStatus = FetchDataStatus.SUCCESS;
      state.isLoading = false;
      state.activeStatement = action.payload;
    })
    .addCase(getStatementDetails.rejected, (state, action) => {
      state.statementDetailsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, statement, resetActiveStatement } = statementDetailsSlice.actions;

export const selectStatementDetailsState = state => state.statementDetails;

export default statementDetailsSlice.reducer;