import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

  export const getCreditScores = createAsyncThunk('creditScore', async (params) => {
   const creditScore = await client.get(`/creditscore/breakdown/${params.key}`,
   {
     "headers":
     {
       "Authorization": "Bearer " + params.accessToken
     }
   });

    return creditScore.data;
   }
 );

export const updateCreditScoreAsync = createAsyncThunk('updateCreditScore', async (params) => {
  let data={};
  data = JSON.stringify(data)
  const creditScore = await client.post(`/creditscore/${params.key}`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
  if(!creditScore?.data){
    return creditScore
  }
  else{
    return creditScore.data;
  }
  }
);

export const creditScoreSlice = createSlice({
  name: 'creditScore',
  initialState: {
    activeCreditScore: { },
    creditScoreStatus: FetchDataStatus.INITIAL,
    updateCreditScoreStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetCreditScore: state => {
      state.activeCreditScore = { };
      state.creditScoreStatus = FetchDataStatus.INITIAL;
      state.updateCreditScoreStatus = PostDataStatus.INITIAL;
    },
    resetCreditScoreStatus: state => {
      state.updateCreditScoreStatus = PostDataStatus.INITIAL;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCreditScores.pending, (state) => {
      state.creditScoreStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getCreditScores.fulfilled, (state, action) => {      
      state.creditScoreStatus = FetchDataStatus.SUCCESS;
      state.activeCreditScore = action.payload;
    })
    .addCase(getCreditScores.rejected, (state, action) => {
      state.creditScoreStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateCreditScoreAsync.pending, (state) => {
      state.updateCreditScoreStatus = PostDataStatus.FETCHING;
    })
    .addCase(updateCreditScoreAsync.fulfilled, (state) => {      
      state.updateCreditScoreStatus = PostDataStatus.SUCCESS;
    })
    .addCase(updateCreditScoreAsync.rejected, (state, action) => {
      state.updateCreditScoreStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, creditScore, resetCreditScore, resetCreditScoreStatus } = creditScoreSlice.actions;

export const selectCreditScoreState = state => state.creditScore;

export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export default creditScoreSlice.reducer;