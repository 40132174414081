import { createSlice } from "@reduxjs/toolkit";

import {
  fetchFlaggedTransactionAsync,
  fetchPendingTransactionAsync,
  fetchReviewedTransactionAsync,
  fetchTransactionDetailsAsync,
  fetchTransactionsByIdAsync,
  uploadTransactionAsync
} from "./thunks";

const initialState = {
  data: null,
  status: "idle",
};

export const flaggedFraudTransactionSlice = createSlice({
  name: "flaggedTransactionSlice",
  initialState,
  reducers: {
    resetFlaggedTransaction: (state) => {
      state.data = null;
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFlaggedTransactionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlaggedTransactionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchFlaggedTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { resetFlaggedTransaction } = flaggedFraudTransactionSlice.actions;



export const pendingFraudTransactionSlice = createSlice({
  name: "pendingTransactionSlice",
  initialState: {
    data: null,
    status: "idle",
  },
  reducers: {
    resetPendingTransaction: (state) => {
    state.data = null;
    state.status = "idle";
  },},

  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingTransactionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPendingTransactionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchPendingTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});
export const { resetPendingTransaction } = pendingFraudTransactionSlice.actions;


export const reviewedFraudTransactionSlice = createSlice({
  name: "reviewTransactionSlice",
  initialState: {
    data: null,
    status: "idle",
  },
  reducers: {
    resetReviewedTransaction: (state) => {
      state.data = null;
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewedTransactionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviewedTransactionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchReviewedTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});
export const { resetReviewedTransaction } = reviewedFraudTransactionSlice.actions;




export const fraudTransactionDetailSlice = createSlice({
  name: "transactionDetailsSlice",
  initialState: {
    data: null,
    status: "idle",
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactionDetailsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchTransactionDetailsAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const fraudTransactionByIdSlice = createSlice({
  name: "fraudTransactionByIdSlice",
  initialState: {
    data: null,
    status: "idle",
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionsByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactionsByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchTransactionsByIdAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});


