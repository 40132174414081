import React from "react";

import style from "./index.module.css";

import SvgSpinner from "../../../../svg/Spinner/Spinner";

const Spinner = ({ visible, size = 40, color = "#273066" }) => {
  if (visible) {
    return (
      <div className={style.container}>
        <SvgSpinner style={{ width: "100px", height: "100px" }} />
      </div>
    );
  }
  return null;
};
export default Spinner;
