import React,{useState,useEffect} from 'react'
import './kybReport.css'
import { resetReport } from '../../../../../redux/Slices/KybOptions';
import { selectKenyaKybExtractions } from '../../../../../redux/Slices/IdVerificationKenyaKyb';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../../library/Variables';
import Utils from '../../../../../library/Utils';
import RegNumberType from './ReportTypes/RegNumberType';
import { BrsObjectSerializer } from './KybSerializations/BrsSerializations';


function KybExtractionReport(props) {
  let kybState = useSelector(selectKenyaKybExtractions)
  const mimeType = 'data:image/jpeg;base64,'
  const [reformedBrsData,setReformedBrsData] = useState({business:[],persons:[]})
  let userReport 

  useEffect(()=>{
    if(kybState.reportStatus === FetchDataStatus.SUCCESS){
      const plainBrsObject = BrsObjectSerializer(userReport)
      setReformedBrsData(plainBrsObject)
    }

  },[kybState.reportStatus])
  
    let content

    if(kybState.reportStatus === FetchDataStatus.FETCHING){
      content = <Spinner/>
    }

    if(kybState.reportStatus === FetchDataStatus.SUCCESS){
      userReport = {...kybState.reportData}
      let userImg = null;
      let userSignature = null;

      if(!Utils.isFieldEmpty(kybState.reportData?.base64Image)){
        userImg = mimeType + kybState.reportData?.base64Image
      }
      if(!Utils.isFieldEmpty(kybState.reportData?.signaturee)){
        userSignature = mimeType + kybState.reportData?.signature
      }
      
    

      delete userReport?.base64Image;
      delete userReport?.photo;
      delete userReport?.signature;
      
    let reportKeys = Object.keys(userReport);
    let filteredKeys = []
    let unfiltered 

    for(let i=0;i<reportKeys.length;i++){
        if(!Array.isArray(userReport[reportKeys[i]])){
            filteredKeys.push(reportKeys[i])
        }
        else{
          unfiltered = userReport[reportKeys[i]]
        }
    }
      content = 
          <>
            <div className='roof'>
              <span 
                  className={props.viewReport?'span-view-report':'span-view-history'} 
                  onClick={()=>{props.setViewReport(false)}}>
                  History
              </span>
              <span>/</span>
              <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
            </div>

            <p className='title-class'>{`${props.verificationType} Verification`}</p>
            <p style={{margin:'10px 0px'}}>{`Here's the report from the ${props.verificationType} identity check`}</p>

            { props.verificationType === 'REGNUMBER'?
            <RegNumberType reformedBrsData={reformedBrsData}/>
            : <div className='report-content'>
              <div className='report-fields'>
                  {filteredKeys.map(key=> !Utils.isFieldEmpty(userReport[key])? 
                  <div className='keyValue-holder' key={filteredKeys.indexOf(key)}>
                    <p className='key'>{key}</p>
                    <p className='value'>{userReport[key]}</p>
                  </div>:null
                  )}
              </div>

              {userImg?<div className='img-container'>
                  <p className='photo'>Photo</p>
                  <div className='imgKycBox'>
                    <img src={userImg} style={{width:'auto'}}/>
                  </div>
                
              </div>: ''
              }
            </div>}
          </>
    }

  if(kybState.reportStatus===FetchDataStatus.FAILURE){
    content = content = 
    <>
      <div className='roof'>
        <span 
            className={props.viewReport?'span-view-report':'span-view-history'} 
            onClick={()=>{props.setViewReport(false)}}>
            History
        </span>
        <span>/</span>
        <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
      </div>
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{kybState.reportErrorMessage?kybState.reportErrorMessage:'Try again'}</p>
      </div>
    </>
    
  }
  return (
   
    <div className='report-hold'>
      {content}
    </div>

  )
}

export default KybExtractionReport

//