import React,{useEffect} from "react";
import Utils from "../../../../library/Utils";
import DetailsCard from "../../../../components/DetailsCard/DetailsCard";
import List from "../../../../components/List/List";
import "./BureauReport.css";

function BureauReportBusiness(props) {
  const configuration = props.configuration;
  const fieldData = props.report;

  
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])
  
  return (
    <div className="bureau-report-main">
      <div className="bureau-report-view-col">
        <DetailsCard title="Customer Profile" titleClass="detail-header" bodyExtraClass="details-bottom"
          detailContent={
            <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"}
              listContent={[
                { header: "Businesss ID", detail: Utils.formatRegionNames(Utils.checkNull(fieldData.businessId))},
                { header: "Business registration number", detail: Utils.formatRegionNames(Utils.checkNull(fieldData.businessRegisterationNumber))},
                { header: "Business name", detail: Utils.formatRegionNames(Utils.checkNull(fieldData.fullName))},
                { header: "Phone number", detail: Utils.checkNull(fieldData.phone)},
                { header: "Date of registration", detail: Utils.checkNull(fieldData.dateOfRegistration)},
                { header: "Address", detail: fieldData.address}
              ]} />
          } 
        />
        <DetailsCard title="Report Info" titleClass="detail-header" bodyExtraClass="details-bottom"
          detailContent={
            fieldData.data.map((element, i) => {
              let listItems = [
                { header: "Total number of Delinquent Facilities", detail: Utils.checkNull(element.totalNoOfDelinquentFacilities)},
                { header: "Last reported date", detail: Utils.formatDate(element.lastReportedDate)},
                { header: "Total number of loans", detail: Utils.checkNull(element.totalNoOfLoans)},
                { header: "Total number of institutions", detail: Utils.checkNull(element.totalNoOfInstitutions)},
                { header: "Total number of active loans", detail: Utils.checkNull(element.totalNoOfActiveLoans)},
                { header: "Total borrowed", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.totalBorrowed)},
                { header: "Total outstanding", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.totalOutstanding)},
                { header: "Total overdue", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.totalOverdue)},
                { header: "Total number of performing loans", detail: Utils.checkNull(element.totalNoOfPerformingLoans)},
                { header: "Total number of closed loans", detail: Utils.checkNull(element.totalNoOfClosedLoans)},
                { header: "Total number of active loans", detail: Utils.checkNull(element.totalNoOfActiveLoans)},
                { header: "Highest loan amount", detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.highestLoanAmount)},
                { 
                  header: "Credit enquiries", 
                  detail: Utils.checkNull(element.creditEnquiries) === false || Utils.isFieldEmpty(element.creditEnquiries) 
                    ? "FALSE" 
                    : "TRUE"
                },
                { header: "Credit profile overview", detail: Utils.checkNull(element.creditProfileOverview)},
                { 
                  header: "Loan performance", 
                  detail: Utils.checkNull(element.loanPerformance) === false || Utils.isFieldEmpty(element.loanPerformance) 
                    ? "FALSE" 
                    : "TRUE"
                },
                { 
                  header: "Loan history", 
                  detail: Utils.checkNull(element.loanHistory) === false || Utils.isFieldEmpty(element.loanHistory) 
                    ? "FALSE" 
                    : "TRUE"
                }
              ]

              if(!Utils.isFieldEmpty(element.crcReportOrderNumber)) {
                listItems.push({ header: "CRC report order number", detail: Utils.checkNull(element.crcReportOrderNumber) })
              }

              return <List key={i} listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"} 
                title={element.sourceType.replaceAll("_", " ")} listContent={listItems} />
            })
          }
        />
      </div>
      <div className="bureau-back-button-container">
        <button className="button-square button-outline" onClick={props.callbackFunction}>Back</button>
      </div>
    </div>
  )
}

export default BureauReportBusiness;