import React, {useState,useEffect} from 'react'
import Utils from '../../library/Utils';

function PhoneInput({data,errors,register,setValue,triggerPhoneSave}) {
  const [inputValue, setInputValue] = useState('');

  useEffect(()=>{
     if(!Utils.isFieldEmpty(triggerPhoneSave)){
        
        if(!Utils.isFieldEmpty(triggerPhoneSave[data?.friendlyName])){
            setInputValue(triggerPhoneSave[data?.friendlyName])
            setValue(data.name,triggerPhoneSave[data?.friendlyName].toString())
        }
        
     }
  },[triggerPhoneSave])



  const formatPhoneContact = (e)=>{
    let val = e.target.value;
    val = val.split('')
    if(e.target.value === ''){
        setInputValue('')
        setValue(data.name,inputValue.toString())
    }

    else{

        if(isNaN(parseInt(val[val.length-1]))){
            setInputValue(inputValue)
            setValue(data.name,inputValue.toString())
        }
        else{
            if(val.length>11){
                setInputValue(inputValue)
                setValue(data.name,inputValue.toString())
            }
            else{
                setInputValue(e.target.value)
                setValue(data.name,inputValue.toString())
            }
        }
    }
  }


  return (
        <div className='sign-up-details-input-container'> 
            <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
            <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} 
            autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
            { 
            required: data.isRequired?true:false, 
            }
            )}
            value={inputValue}
            onChange={(event)=>formatPhoneContact(event,data)} />
            <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                  
        </div>
  )
}

export default PhoneInput