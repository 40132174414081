import React,{useState,useEffect,useRef} from 'react'
import nairaIcon from '../../assests/icons/nairaIcon.png'
import Utils from '../../library/Utils'

function MinMaxInput({data,errors,register,setValue,alternator,setAlternator,triggerAmountSave}) {
    const [inputValue,setInputValue] = useState('')
    const [exportValue,setExportValue] = useState()
    const [currentValue,setCurrentValue] = useState(null)
    const amountRef = useRef(null)

    function formatAmount(e,data) {
        let number = e.target.value
        
        if((number.length >currentValue?.length) || !currentValue){
          number = number.split("")
          if(isNaN(parseInt(number[number.length-1]))){
            if(number[0]===number[number.length-1]){
              e.target.value = ''
              setAlternator(!alternator)
            }
            else{

              const formatter = new Intl.NumberFormat('en-US');
              const formattedNumber = formatter.format(exportValue);
              e.target.value = formattedNumber
              setAlternator(!alternator)
            }
          
          }
          else{

            let mainValue = inputValue+number[number.length-1]
            setInputValue(mainValue)
            mainValue = parseInt(mainValue)
            setExportValue(mainValue.toString())
            setValue(data.name,mainValue.toString())
            const formatter = new Intl.NumberFormat('en-US');
            
            const formattedNumber = formatter.format(mainValue);
            // Remove non-numeric characters
            setCurrentValue(formattedNumber)
            e.target.value = formattedNumber
            setAlternator(!alternator)
            
          }
        }
        else{
          if(number===''){
            e.target.value = ''
            setCurrentValue(null)
            setInputValue('')
            setExportValue('')
            setValue(data.name,'')
            setAlternator(!alternator)
          }
          else{

            let number = exportValue.toString()
            number = number.split("")
            number.splice(number.length-1,1)
            number = number.join('')
            number = parseInt(number)
            setExportValue(number.toString())
            setValue(data.name,number.toString())
            setInputValue(number.toString())
            const formatter = new Intl.NumberFormat('en-US');
  
            const formattedNumber = formatter.format(number);
            setCurrentValue(formattedNumber)
            e.target.value = formattedNumber
            setAlternator(!alternator)
        
          }
        }
        
      }
  

      useEffect(()=>{
        if(!Utils.isFieldEmpty(triggerAmountSave)){
          if(!isNaN(parseInt(triggerAmountSave[data.friendlyName]))){
            setInputValue(triggerAmountSave[data.friendlyName])
          setExportValue(triggerAmountSave[data.friendlyName].toString())

          setValue(data.name,triggerAmountSave[data.friendlyName].toString())
          const formatter = new Intl.NumberFormat('en-US');
          
          const formattedNumber = formatter.format(triggerAmountSave[data.friendlyName]);
          // Remove non-numeric characters
          setCurrentValue(formattedNumber)
          amountRef.current.value = formattedNumber
          setAlternator(!alternator)
          }


          else{
          amountRef.current.value = ''
          setCurrentValue(null)
          setInputValue('')
          setExportValue('')
          setValue(data.name,'')
          setAlternator(!alternator)
          }
        }
      
  },[triggerAmountSave])

  return (
    <div className='sign-up-details-input-container'> 
        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>

        <section className='currency-section'>
            <span><img src={nairaIcon}/></span>
            <div style={{position:'relative',width: `calc(100% - 68.8px)`,height:'30px',padding: '.7em .2em'}}>
              <div style={{position:'absolute',top:'0px',bottom:'0px',width:"100%",height:"100%",left:"0px",right:'0px',zIndex:'10'}}>
                <input style={{width:"100%",height:"100%",boxSizing:"border-box"}} placeholder={`input ${data.friendlyName}`} autoComplete="off" type='TEXT'  
                  onChange={(event)=>formatAmount(event,data)}
                  ref={amountRef}
                />
              </div>
              <input type='TEXT' style={{visibility:'hidden',width:'0px',height:'0px',overflow:'hidden',position:'absolute',top:'0px',bottom:'0px',left:"0px",right:'0px',zIndex:'3'}} {...register(`${data.name}`, 
                { 
                required: data.isRequired?true:false, 
                
                })} value={exportValue} />
            </div>
            
        </section>
        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
    </div>
  )
}

export default MinMaxInput