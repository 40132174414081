import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './SideBar.css';
import { 
  useDispatch
} from 'react-redux';
import { logout } from '../../redux/Slices/Auth';
import SideBarContent from "./SideBarContent";
import { resetAllCustomDecisionCreditScore } from "../../redux/Slices/CustomDecisionCustomCreditScore";
import { getAllLoanData,resetGetAllLoanData } from "../../redux/Slices/Loan";

function SideBar(props) {
  const user = props.user;
  const configuration = props.configuration;
  const dispatch = useDispatch();
  const [burger, setBurger] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  
  const loanCall = ()=>{
      dispatch(resetGetAllLoanData())
  }

  const logoutFunc = () => {
    localStorage.setItem('insightsRedirectToHome', true);
    dispatch(logout());
    props.setAccessTokenState('')
    navigate('/');
    
  }

  const clearHistory = () => {
    
    dispatch(resetAllCustomDecisionCreditScore());
    
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setBurger(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

  }, [sidebarRef]);

  return (
    <div className='sidebar-main' ref={sidebarRef} >
      <div className="burger-container">
        <button onClick={() => setBurger(!burger)} className="burger-button">{
          burger === false 
          ? <img src={'/assets/icons/open.svg'} alt="open" className="burger-icon" /> 
          : <img src={'/assets/icons/close.svg'} alt="close" className="burger-icon" />
        }</button>
        {burger === true &&
          <div className="sidebar-burger">
            <SideBarContent logout={() => logoutFunc()} loanCall={()=>loanCall()} clearHistory = {()=> clearHistory()} user={user} configuration={configuration} />
          </div>
        }
      </div>
      <div className="sidebar">
        <SideBarContent logout={() => logoutFunc()} loanCall={()=>loanCall()} user={user} clearHistory = {()=> clearHistory()} configuration={configuration} />
      </div>
    </div>
  )
}

export default SideBar;