import storage from "../utils/storage";

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export async function client(
  endpoint,
  { body, ...customConfig } = defaultHeaders,
  getType,
  baseUrl = process.env.REACT_APP_API_BASE_URL
) {
  const headers = {
    "Content-Type": "application/json",
  };

  const token = storage.getToken();

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const env = window._jsenv || process.env;
  // const baseUrl = env.REACT_APP_API_BASE_URL;

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await window.fetch(baseUrl + endpoint, config);
    if (response.ok) {
      return {
        status: response.status,
        data: getType === "blob" ? response.blob() : response.json(),
        headers: response.headers,
        url: response.url,
      };
    }
    const error = await response.json()
    throw new Error(error.message);
  } catch (error) {
    return Promise.reject(error.message ? error.message : data);
  }
}

export async function keycloakClient(
  endpoint,
  { body, ...customConfig } = {},
  getType
) {
  const env = window._jsenv || process.env;
  // const baseUrl = env.REACT_APP_API_BASE_URL;

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  var urlencoded = new URLSearchParams();
  urlencoded.append("client_id", body.client_id);
  urlencoded.append("username", body.username);
  urlencoded.append("password", body.password);
  urlencoded.append("grant_type", body.grant_type);

  if (body) {
    config.body = urlencoded;
  }

  let data;
  try {
    const response = await fetch(endpoint, config);

    if (!response.ok) {
      throw new Error("Login failed");
    }

    return {
      status: response.status,
      data: response.json(),
      headers: response.headers,
      url: response.url,
    };
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

export async function selfServiceClient(
  endpoint,
  { body, ...customConfig } = {},
  getType
) {
  const headers = {
    "Content-Type": "application/json",
  };

  const env = window._jsenv || process.env;
  const baseUrl = env.REACT_APP_SELF_SERVICE_API_BASE_URL;

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await window.fetch(baseUrl + endpoint, config);
    if (response.ok) {
      return {
        status: response.status,
        data: getType === "blob" ? response.blob() : response.json(),
        headers: response.headers,
        url: response.url,
      };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}
client.get = function (endpoint, customConfig = {}, baseurl) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.getBlob = function (endpoint, customConfig) {
  return client(endpoint, { ...customConfig, method: "GET" }, "blob");
};

client.put = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env;
  const baseUrl = env.REACT_APP_API_BASE_URL;

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: "PUT",
      body: body,
      headers: {
        ...customConfig.headers,
      },
    });
    if (response.ok) {
      return Promise.resolve({});
    }
    throw new Error(
      await response.json().then((data) => JSON.stringify(data.message))
    );
  } catch (err) {
    return Promise.reject(err.message ? err.message : body);
  }
};

/**
 * 
 * @param { client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}
 } endpoint 
 * @param {*} body 
 * @param {*} customConfig 
 * @returns 
 */

client.post = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env;
  const baseUrl = env.REACT_APP_API_BASE_URL;

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: "POST",
      body,
      ...customConfig,
    });
    if (response.ok) {
      const data = await response.json(); // Assuming the response is in JSON format
      if (data) {
        return Promise.resolve(data);
      } else {
        return Promise.resolve({});
      }
    }
    throw new Error(
      await response.json().then((data) => JSON.stringify(data.message))
    );
  } catch (err) {
    return Promise.reject(err.message ? err.message : body);
  }
};

client.getPostCsv = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env;
  const baseUrl = env.REACT_APP_API_BASE_URL;

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: "POST",
      body,
      ...customConfig,
    });
    if (response.ok) {
      const data = await response.blob();
      return data;
    }
    throw new Error(
      await response.json().then((data) => JSON.stringify(data.message))
    );
  } catch (err) {
    return Promise.reject(err.message ? err.message : body);
  }
};

client.postKeycloak = function (endpoint, body, customConfig = {}) {
  return keycloakClient(endpoint, { ...customConfig, body });
};

client.postSelfService = function (endpoint, body, customConfig = {}) {
  return selfServiceClient(endpoint, { ...customConfig, body });
};

client.patch = async function (endpoint, body, customConfig = {}) {
  const env = window._jsenv || process.env;
  const baseUrl = env.REACT_APP_API_BASE_URL;
  const headers = {
    "Content-Type": "application/json",
  };

  if (body) {
    body = JSON.stringify(body);
  }

  try {
    const response = await window.fetch(baseUrl + endpoint, {
      method: "PATCH",
      body: body,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    });
    if (response.ok) {
      return Promise.resolve({});
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : body);
  }
};
