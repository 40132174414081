import React,{useState} from 'react'
import { selectChangePassword,
         resetChangePassword } 
         from '../../../redux/Slices/PasswordCollection/ChangePassword/ChangePasswordClient'
import { useDispatch,useSelector } from 'react-redux'
import { PostDataStatus } from '../../../library/Variables'
import amlFilled from '../../../assests/icons/amlFound.png'
import errorImg from '../../../assests/icons/errorImage.png'
import { useNavigate } from 'react-router'
import './ResetPass.css'



function ChangePassWordModal({closeModal,showLogin}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const passData = useSelector(selectChangePassword)
    let mainContent

    const proceedToView = ()=>{
        if(showLogin){
            closeModal()
            dispatch(resetChangePassword())
            navigate('/login')
        }
        else{
            closeModal()
            dispatch(resetChangePassword())
        }
      
    }

    const closeView = ()=>{
        closeModal()
        dispatch(resetChangePassword())
    }


  

    if(passData.changePassStatus === PostDataStatus.SUCCESS){
        mainContent =   <div style={{display:"flex",
                                     flexDirection:"column",
                                     height:"250px",
                                     justifyContent:"space-around",
                                     alignItems:"center"}}>
                            <span style={{display:"flex",
                                          alignItems:"center",
                                          justifyContent:"center"}}>
                                          <img src={amlFilled}/>
                            </span>
                            <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>Password Change Completed</p>
                            {showLogin && <p>Kindly click proceed.</p>}
                            <span onClick={()=>proceedToView()} className='buttonAml'>{showLogin?'Proceed':'Close'}</span>
                        </div>
    }


    if(passData.changePassStatus === PostDataStatus.FAILURE){
        mainContent =<div style={{display:"flex",
                                  flexDirection:"column",
                                  height:"300px",
                                  justifyContent:"space-around",
                                  alignItems:"center",
                                  padding:'20px',
                                  boxSizing:"border-box"}}>
        <span style={{display:"flex",
                      alignItems:"center",
                      justifyContent:"center",
                      padding:"5px"}}>
                      <img style={{width:"30px",height:"30px"}} src={errorImg}/>
        </span>
        <p style={{color:'#363C4F'}}>Something went wrong!</p>
        <p style={{color:"red",fontWeight:"700",fontSize:"24px",width:"60%",
        margin:"0px auto"}}>{passData.errorChangePass}</p>

        <span onClick={()=>closeView()} className='buttonAml'>Close</span>
</div>
    }
  return (
    <div style={{height:'auto'}}>
        {mainContent}
    </div>
  )
}

export default ChangePassWordModal