import { useNavigate, useLocation } from "react-router-dom";
import { logout } from '../../redux/Slices/Auth';
import {
  useDispatch
} from 'react-redux';
import './Error.css';
import { useState, useEffect, useRef } from 'react';
import Spinner from "../../svg/Spinner/Spinner";
import Button from "../../components/Button/Button";

function Error(props) {
  let location = useLocation();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loggoutCounter, setLoggoutCounter] = useState(5);
  const configuration = props.configuration;
  const user = props.user;
  const errorInterval = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  let content = <Spinner />

  const logoutFunction = () => {
    dispatch(logout());
    localStorage.clear();
        
    if(location.pathname !== '/') {
      navigate('/');
    }
  }

  const navigateBack = () => {
    if(user.user === null || user.user === undefined) {
      navigate(-1);
    } else {
      navigate("/home");
    }
  }

  if(isLoading === false) {
    content = <>
      <div className='error-top-icon' >
        <img src='/assets/icons/danger-color.svg' alt='' />
      </div>
      <section className='error-message-section'>
        <h1 className="detail-header">We're Sorry</h1>
        <p>The request has failed due to an internal error. Please contact us 
          at <a href={"mailto:support@periculum.io?subject=Insights - " + configuration.displayName +
          " Internal Error"}>support@periculum.io</a> to
          resolve the problem.</p>
      </section>
      <section>
        <p>You will be logged out and redirected in {loggoutCounter}...</p>
      </section>
      <Button name={"Back"} className="button-pill button-solid" clickFunction={() => navigateBack()} />
    </>
  }

  useEffect(() => {
    setIsLoading(false);

    errorInterval.current = setInterval(() => {
      if(loggoutCounter > 0) {
        setLoggoutCounter(loggoutCounter - 1);
      } else if(loggoutCounter === 0) {
        clearInterval(errorInterval.current);
        logoutFunction();
      }
    }, 1000)  

    return () => clearInterval(errorInterval.current);
  }, [loggoutCounter, dispatch, errorInterval])
  
  return (
    <div className="error-body">
      {content}
    </div>
  )
}

export default Error;