import React from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';
import { useSelector,useDispatch } from 'react-redux';
import { getCustomDecisionScoreCardDefinitions,
        selectCustomDecisionCreditScoreState } 
        from '../../../../redux/Slices/CustomDecisionCustomCreditScore';
import { FetchDataStatus } from '../../../../library/Variables';
import Spinner from '../../../../svg/Spinner/Spinner';
import ProceedSection from './sections/ProceedSection';
import { generateScoreBreakDown,selectLoanScoreState } from '../../../../redux/Slices/LoanScores';



function FormCreditScore(props) {
  const newlyVerified = props.newlyVerified
  const verifiedStatus = props.verifiedStatus
  const { key } = useParams();
  const dispatch = useDispatch()
  const decisionState = useSelector(selectCustomDecisionCreditScoreState)
  const loanScoreStatus = useSelector(selectLoanScoreState)
  const accessToken = props.accessToken
  const scoreStatus = props.scoreStatus
  const newlyScored = props.newlyScored
  const setNewScore = props.setNewScore
  const refreshScore = props.refreshScore
  const setRefreshScore = props.setRefreshScore
  const user = props.user
  const permissions = user.user.permissions
  const configuration = props.configuration
  let content

  const generateScore = ()=>{
    if(newlyVerified || verifiedStatus){
      //generate definition cards
      dispatch(getCustomDecisionScoreCardDefinitions({accessToken}))
    }
   
    else{
      toast.warning("Fill Verification form", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });    
    }
  }

  if(verifiedStatus){
    if(scoreStatus || newlyScored){
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.SUCCESS ){
        if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.SUCCESS){

          content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
          setNewScore={setNewScore} scoreStatus={scoreStatus} newlyScored={newlyScored} 
          accessToken={accessToken} setRefreshScore={setRefreshScore}/>
        }
          else{
            if(!refreshScore){
              dispatch(generateScoreBreakDown({accessToken,loanKey:key}))
            }
          content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
                                    setNewScore={setNewScore} scoreStatus={scoreStatus} newlyScored={newlyScored} 
                                    accessToken={accessToken} setRefreshScore={setRefreshScore}/>
          }
      }

      else{

          if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.INITIAL){
            content = <Spinner/>
            generateScore()
          }

          if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.FETCHING){
            content = <Spinner/>
          }
        
      }
    }

    else{

      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.INITIAL ){
        content = <Spinner/>
            generateScore()
      
      }
    
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.FETCHING ){
        content = <Spinner/>
      }
    
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.SUCCESS ){
        content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
                  setNewScore={setNewScore} scoreStatus={scoreStatus} newlyScored={newlyScored} 
                  setRefreshScore={setRefreshScore} accessToken={accessToken}/>
      }

      if(decisionState.customDecisionCreditScoreStatus===FetchDataStatus.FAILURE){
        content = 
        <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{decisionState.errorMessage}</p>
        </div>
      }
    }
  }

  else{

    if(scoreStatus || newlyScored){

      if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.SUCCESS){
        content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
        setNewScore={setNewScore} scoreStatus={scoreStatus} newlyScored={newlyScored} 
        accessToken={accessToken} setRefreshScore={setRefreshScore}/>
      }
      else{

      dispatch(generateScoreBreakDown({accessToken,loanKey:key}))
      content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
                                setNewScore={setNewScore} scoreStatus={scoreStatus} newlyScored={newlyScored} 
                                accessToken={accessToken} setRefreshScore={setRefreshScore}/>
      }
    }

    else{

      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.INITIAL|| !newlyVerified ){
        content = <div style={{height:"150px",padding:'20px 0px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px',color:'rgba(42, 56, 164, 1)'}}>There is currently no credit score to display.</p>
          <p>Please, click the button below to generate.</p>
      
          <div className='centerButton'>
              <section className='proceed' onClick={()=>generateScore()}>Generate Credit Score</section>
          </div>
        </div>
      }
  
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.INITIAL && newlyVerified){
        content = <Spinner/>
        generateScore()
      }
    
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.FETCHING && newlyVerified ){
        content = <Spinner/>
      }
    
      if(decisionState.customDecisionCreditScoreStatus === FetchDataStatus.SUCCESS && newlyVerified){
        content = <ProceedSection user={user} configuration={configuration} data={decisionState.customDecisionCustomScoreCards} 
                                  accessToken={accessToken} setNewScore={setNewScore} scoreStatus={scoreStatus} 
                                  setRefreshScore={setRefreshScore} newlyScored={newlyScored}/>
      }
    }


  }

  
  return ( <div>
    {content}
  </div>)
}

export default FormCreditScore