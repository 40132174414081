import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";

export const getAllUsers = createAsyncThunk("userManagement/getUserList", async (accessToken) => {
  const users = await client.get("/platformuser/all",
  {
    "headers":
    {
      "Authorization": "Bearer " + accessToken
    }
  });

  return users.data;
});

export const getUserRoles = createAsyncThunk("userManagement/getUserRoles", async (accessToken) => {
  const users = await client.get("/platformuser/roles",
  {
    "headers":
    {
      "Authorization": "Bearer " + accessToken
    }
  });

  return users.data;
});

export const getAllPermissions = createAsyncThunk("userManagement/getAllPermissions", async (accessToken) => {
  const users = await client.get("/platformuser/getallpermissions",
  {
    "headers":
    {
      "Authorization": "Bearer " + accessToken
    }
  });

  return users.data;
})

export const inviteNewUserAsync = createAsyncThunk("userManagement/inviteNew", async (params) => {
  let data = JSON.stringify(params.data)
  
  const users = await client.post("/application/create-user", data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
  if(!users?.data){
    return users
  }
  else{
    return users.data;
  }
});

export const updateUserAsync = createAsyncThunk("userManagement/update", async (params) => {
  let data = JSON.stringify(params.data)
  const users = await client.post("/platformuser/edituser", data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });

  if(!users?.data){
    return users
  }
  else{

    return users.data;
  }
});

export const deleteUserAsync = createAsyncThunk("userManagement/delete", async (params) => {
  const users = await client.get(`/platformuser/deleteuser/${params.userKey}`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return users.data;
});

export const updateRoleAsync = createAsyncThunk("userManagement/updateRole", async (params) => {
  let data = JSON.stringify(params.data)
  const users = await client.post("/platformuser/editrolepermissions", data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });

  if(!users?.data){
    return users
  }
  else{

    return users.data;
  }
});

export const addCustomRoleAsync = createAsyncThunk("userManagement/addRole", async (params) => {
  let data = JSON.stringify(params.data)
  const users = await client.post("/platformuser/addnewrole", data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });

  if(!users?.data){
    return users
  }
  else{

    return users.data;
  }
});

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    userList: [],
    userListStatus: FetchDataStatus.INITIAL,
    userRoles: [],
    userRolesStatus: FetchDataStatus.INITIAL,
    permissionsList: [],
    permissionsListStatus: FetchDataStatus.INITIAL,
    inviteNewUserStatus: PostDataStatus.INITIAL,
    updateUserStatus: PostDataStatus.INITIAL,
    deleteUserStatus: FetchDataStatus.INITIAL,
    updateRoleStatus: PostDataStatus.INITIAL,
    addCustomRoleStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetUserManagement: state => {
      state.userList = [];
      state.userListStatus = FetchDataStatus.INITIAL;
      state.userRoles = [];
      state.userRolesStatus = FetchDataStatus.INITIAL;
      state.permissionsList = [];
      state.permissionsListStatus = FetchDataStatus.INITIAL;
      state.inviteNewUserStatus = PostDataStatus.INITIAL;
      state.updateUserStatus = PostDataStatus.INITIAL;
      state.deleteUserStatus = FetchDataStatus.INITIAL;
      state.updateRoleStatus = PostDataStatus.INITIAL;
      state.addCustomRoleStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetInviteSend: state => {
      state.inviteNewUserStatus = PostDataStatus.INITIAL;
      state.userRoles = [];
      state.userRolesStatus = FetchDataStatus.INITIAL;
      state.userList = [];
      state.userListStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetUpdateUser: state => {
      state.updateUserStatus = PostDataStatus.INITIAL;
      state.userList = [];
      state.userListStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetDeleteUser: state => {
      state.deleteUserStatus = FetchDataStatus.INITIAL;
      state.userList = [];
      state.userListStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetUpdateRole: state => {
      state.updateRoleStatus = PostDataStatus.INITIAL;
      state.userRoles = [];
      state.userRolesStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetAddCustomRole: state => {
      state.addCustomRoleStatus = PostDataStatus.INITIAL;
      state.userRoles = [];
      state.userRolesStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getAllUsers.pending, (state) => {
      state.userListStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllUsers.fulfilled, (state, action) => {
      state.userListStatus = FetchDataStatus.SUCCESS;
      state.userList = action.payload;
    })
    .addCase(getAllUsers.rejected, (state, action) => {
      state.userListStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })





    .addCase(getUserRoles.pending, (state) => {
      state.userRolesStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getUserRoles.fulfilled, (state, action) => {
      state.userRolesStatus = FetchDataStatus.SUCCESS;
      state.userRoles = action.payload;
    })
    .addCase(getUserRoles.rejected, (state, action) => {
      state.userRolesStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })



    
    .addCase(getAllPermissions.pending, (state) => {
      state.permissionsListStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllPermissions.fulfilled, (state, action) => {
      state.permissionsListStatus = FetchDataStatus.SUCCESS;
      state.permissionsList = action.payload;
    })
    .addCase(getAllPermissions.rejected, (state, action) => {
      state.permissionsListStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(inviteNewUserAsync.pending, (state) => {
      state.inviteNewUserStatus = PostDataStatus.FETCHING;
    })
    .addCase(inviteNewUserAsync.fulfilled, (state) => {
      state.inviteNewUserStatus = PostDataStatus.SUCCESS;
    })
    .addCase(inviteNewUserAsync.rejected, (state, action) => {
      state.inviteNewUserStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateUserAsync.pending, (state) => {
      state.updateUserStatus = PostDataStatus.FETCHING;
    })
    .addCase(updateUserAsync.fulfilled, (state) => {
      state.updateUserStatus = PostDataStatus.SUCCESS;
    })
    .addCase(updateUserAsync.rejected, (state, action) => {
      state.updateUserStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(deleteUserAsync.pending, (state) => {
      state.deleteUserStatus = FetchDataStatus.FETCHING;
    })
    .addCase(deleteUserAsync.fulfilled, (state) => {
      state.deleteUserStatus = FetchDataStatus.SUCCESS;
    })
    .addCase(deleteUserAsync.rejected, (state, action) => {
      state.deleteUserStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateRoleAsync.pending, (state) => {
      state.updateRoleStatus = PostDataStatus.FETCHING;
    })
    .addCase(updateRoleAsync.fulfilled, (state) => {
      state.updateRoleStatus = PostDataStatus.SUCCESS;
    })
    .addCase(updateRoleAsync.rejected, (state, action) => {
      state.updateRoleStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(addCustomRoleAsync.pending, (state) => {
      state.addCustomRoleStatus = PostDataStatus.FETCHING;
    })
    .addCase(addCustomRoleAsync.fulfilled, (state) => {
      state.addCustomRoleStatus = PostDataStatus.SUCCESS;
    })
    .addCase(addCustomRoleAsync.rejected, (state, action) => {
      state.addCustomRoleStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetUserManagement, resetInviteSend, resetDeleteUser, resetUpdateUser, resetUpdateRole, resetAddCustomRole } = userManagementSlice.actions;

export const selectUserManagementState = state => state.userManagement;

export default userManagementSlice.reducer;