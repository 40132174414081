import React from "react";
import Utils from "../../library/Utils";
import './DetailsCard.css';
import { useEffect,useState } from "react";

function DetailsCard(props) {
  const subscriptionState = props.subscriptionState
  const [flagSub,setFlagSub] = useState(false) 
  useEffect(()=>{
    if(!Utils.isFieldEmpty(subscriptionState)){
      if(subscriptionState?.isActive 
        && subscriptionState?.hasSubscription 
        && subscriptionState?.daysLeft<=7){
          setFlagSub(true)
        }
      else{
        setFlagSub(false)
      }
    }

    else{
      setFlagSub(false)
    }
   
  },[subscriptionState?.isActive])


  return (
    <div className="st-details-card-main" id={props.cardID}>
      <div className='cards st-details-card'>
      { props.hoverTitle==='View Wallet' && 
         <p className={flagSub
                      ?"subscription-flag-off"
                      :"subscription-hidden"}>
                      {'subscription expires in '+ `${subscriptionState?.daysLeft}` +' days'}
         </p>}
         {props.title && <>
          {props.visualContent && <div className="st-details-icon-container">{props.visualContent}</div>}
          <div className="details-header-row">
            {!Utils.isFieldEmpty(props.headerExtra) && props.headerExtra}
            {!Utils.isFieldEmpty(props.headerCaption) && <h5>{props.headerCaption}</h5>}
            {!Utils.isFieldEmpty(props.headerLink) 
              ? <div className="details-header-with-link-container" onClick={() => props.headerLink()}>
                  <h3 className={props.titleClass}>{props.title}</h3>
                  <span>🡲</span>
                </div>
              :props.hoverTitle==='View Wallet'?<h3 className={props.titleClass}>
                  {(props.role==='ADMIN'&&subscriptionState?.plan==='UNLIMITED_TIER')
                  ?props.title:(props.role!=='ADMIN'&&subscriptionState?.plan==='UNLIMITED_TIER')
                  ?`${props.title[0]}*****`:props.title}</h3>
              :<h3 className={props.titleClass}>{props.title}</h3>
            }
            {!Utils.isFieldEmpty(props.subtitle) && <h4 className={props.subtitleClass}>{props.subtitle}</h4>}
          </div>
        </>}
        {props.separator && <hr className={props.separatorClass} />}
        {props.detailContent &&
          <div className={props.detailClassName} >
            {props.detailContent}
          </div>
        }
      </div>
      {props.clickableFunction &&
        <div className="st-details-overlay">
          <button onClick={() => props.clickableFunction()}>{props.hoverTitle}</button>
      </div>
      }
    </div>
  )
}

export default DetailsCard;