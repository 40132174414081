import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectKycEntireDataState,
          getAlienId,
          resetAlienstatus,
          getPlateNumber,
          resetPlateNumbertatus,
          resetDataStatus} from '../../../../../../redux/Slices/IdVerificationKenya'
import { PostDataStatus } from '../../../../../../library/Variables'
import errorImage from '../../../../../../assests/icons/errorImage.png'
import { getReportDetail,resetReport } from '../../../../../../redux/Slices/IdVerificationKenya'
import { getReportDetailKyb,resetReportKyb } from '../../../../../../redux/Slices/IdVerificationKenyaKyb'



function VehiclePlateModal({accessToken,keyFromRoute,setKycToShow,inputStyle,setViewReport,customStyles,setVerificationType}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const onSubmit = (formData) => {
  const obj = {number:formData.inputName,
               statementKey: null}      
    dispatch(getPlateNumber({obj,accessToken}))
  }

  const getDetails = (token,uuid,type)=>{

    setKycToShow(null);
    setIsOpen(false);
    dispatch(resetDataStatus());
    dispatch(resetPlateNumbertatus())

    if(['CAC','TIN'].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    }
    else if(['IDNUMBER',
            'ALIENIDNUMBER',
            'DRIVER_LICENCE_KENYA',
            'KRAPIN',
            'PLATE_NUMBER',
            'PHONE_NUMBER',
            'ACCOUNT_NUMBER'
             ].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReport())
        dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
}



  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p style={{fontSize:'23px',fontWeight:'700'}}>Vehicle Number Plate</p>
       <div style={{}}>
           <p>Input Vehicle Number Plate</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' 
              onClick={()=>{
                            setIsOpen(false);
                            setKycToShow(null);
                            dispatch(resetPlateNumbertatus())}}>
              Cancel
           </span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.plateExtractionErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' 
              onClick={()=>{
                            setIsOpen(false);
                            setKycToShow(null);
                            dispatch(resetPlateNumbertatus())}}>
              Cancel
          </span>
           
       </div>
  </div>

const ModalSuccess = <div className='modal-success'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <p style={{textAlign:'center',fontWeight:'700'}}>Successfull!!</p>
        </div>
        <p style={{textAlign:'center'}}>Vehicle Number Plate Extraction Completed</p>

        <div className='modal-initial-button-hold'>
              <span className='close' onClick={()=>getDetails(accessToken,kycState?.PlateExtraction?.uuid,
                                                            kycState.PlateExtraction?.type)}>Close</span>
              
        </div>
    </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.plateExtractionStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.plateExtractionStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.plateExtractionStatus===PostDataStatus.SUCCESS){
            setViewReport(false)
            setModal(ModalSuccess)
        }

        if(kycState.plateExtractionStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Plate Number ID Modal" >
                {modal}
    </Modal>
  )
}

export default VehiclePlateModal