

import Utils from "../../../../../../library/Utils";
import TableReform from "./TableReform";


function AccountSerializationSerializer({data}) {
    const bodyHolder = []
    const headers = ['Attributes','Last 3 Months','Last 6 Months','Last 12 Months']
    const mainObj = [data.no_of_bounced_cheques,data.no_of_credit_applications,data.no_of_enquiries]
    //const bodyKeys = Object.keys(data)

    for(let i=0; i<mainObj.length; i++){
      const tempHolder = []

      if(i===0){
        tempHolder.push('Bounced Cheques')
      }
      else if(i===1){
        tempHolder.push('Credit Applications')
      }
      else{
        tempHolder.push('Enquiries')
      }
      Object.keys(mainObj[i]).forEach(key => {
        tempHolder.push(mainObj[i][key])
      });

      bodyHolder.push(tempHolder)

    }

  return (
    <>
      {!Utils.isFieldEmpty(bodyHolder) && <TableReform headers={headers}
                                                       Body={bodyHolder}
      />}
    </>
  )
}

export default AccountSerializationSerializer