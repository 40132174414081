import React, { useState, useMemo, useEffect, useRef } from "react";
import * as matchSorter from "match-sorter";
import "./Table.css";
import { 
  useTable,
  useFilters,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useGroupBy,
  useSortBy } from "react-table";
import Filter from "../../svg/Filter";
import Utils from "../../library/Utils";
import { DOTS, useCustomPagination } from "./UseCustomPagination";
import Dots from "./Dots";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  return (
    <input className="inputs" id="global-filter" value={value || ""} onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }} placeholder={`${count} record(s)`}
    />
  )
}

export function GlobalFilterWithButton({
  globalFilter,
  setGlobalFilter
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  return (
    <div className="global-filter-with-button-input-container">
      <img src="/assets/icons/search.svg" />
      <input value={value || ""} onChange={e => { setValue(e.target.value); onChange(e.target.value); }} 
        placeholder={"Search user by name or email"} />
    </div>
    
  )
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, setFilter, Header, id },
}) {
  return (
    <>
      <label>{Header}</label>
      <input className="inputs tb-filter-inputs tb-filter-default" value={filterValue || ""}
        placeholder={"Filter by " + Header} onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    </>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <>
      <label>{Header}</label>
      <select className="inputs tb-filter-inputs" value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i + "-" + option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  )
}

export function DateRangeColumnFilter({
  column: { filterValue = [], setFilter, Header, id },
}) {
  return (
    <>
      <label>{Header}</label>
      <div className="table-date-range-container">
        <input value={filterValue[0] || ""} className="inputs tb-filter-inputs" type="date"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? val : undefined, old[1]])
          }}
        />
        <span>to</span>
        <input value={filterValue[1] || ""} className="inputs tb-filter-inputs" type="date"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? (val) : undefined]);
          }}
        />
      </div>
    </>
  )
}

export function AmountRangeColumnFilter({
  column: { filterValue = [], setFilter, Header, id },
}) {
  return (
    <>
      <label>{Header}</label>
      <div className="table-date-range-container">
        <input value={filterValue[0] || ""} className="inputs tb-filter-inputs" type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? val : undefined, old[1]])
          }}
        />
        <span>to</span>
        <input value={filterValue[1] || ""} className="inputs tb-filter-inputs" type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? (val) : undefined]);
          }}
        />
      </div>
    </>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function dateRangeFilterFn(rows, id, filterValues) {
  let start_date = new Date(filterValues[0]);
  let end_date = new Date(filterValues[1]);

  if(filterValues[0] !== undefined && filterValues[1] !== undefined) {
    return rows.filter(row => {
      let time = new Date(row.values[id]);
      if(filterValues.length === 0) {
        return rows;
      } else {
        return (
          time >= start_date && time <= end_date
        )
      }
    })
  } else {
    return rows;
  }
}

dateRangeFilterFn.autoRemove = val => !val;





function amountRangeFilterFn(rows, id, filterValues) {
  
  let start_amount = parseInt(filterValues[0]);
  let end_amount = parseInt(filterValues[1]);

  if(filterValues[0] !== undefined && filterValues[1] !== undefined) {
    return rows.filter(row => {
      let new_amount = row.values[id];
     
      new_amount = parseFloat(new_amount.replace(/[^0-9.]/g, ''));
   
    
      
      if(filterValues.length === 0) {
        return rows;
      } else {
        return (
          new_amount >= start_amount && new_amount <= end_amount
        )
      }
    })
  } else {
    return rows;
  }
}

amountRangeFilterFn.autoRemove = val => !val;

// Our table component


export function Table(props) {
  const data = props.data;
  const columns = props.columns;
  const [pagination] = useState(props.pagination);
  const [isFilterShown, setFilterShown] = useState(false);
  const filterRef = useRef(null);
  const selectedRow = props?.selectedRow

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      dateRange: dateRangeFilterFn,
      amountRange:amountRangeFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, 
        pageSize: props.pageSize !== undefined ? props.pageSize[0] : data.length
      },
      defaultColumn,
      filterTypes
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    usePagination,
    useRowSelect
  )

  
  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterShown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
  }, [filterRef]);

  const paginationRange = useCustomPagination({
    totalPageCount: pageCount,
    currentPage: pageIndex
  });

  let filterArray = [];
  headerGroups.forEach(headerGroup => {
    headerGroup.headers.forEach(column => {
      filterArray.push(
        column.canFilter ? column.render("Filter") : null
      )
    });
  });

  return (
    <div className="table-container">
      {props.tableFilter === true && 
        <div className={"tb-filter-container " + props.filterClass} ref={filterRef} title="Filter">
          <button id="tb-filter-button" onClick={() => setFilterShown(!isFilterShown)}>
            <Filter iconFill={!Utils.isFieldEmpty(props.filterColor) ? props.filterColor : "#407BFF"} />
          </button>
          {isFilterShown &&
            <div className="tb-filters">
              {filterArray.map((filter, i) => (
                <div className="table-filter-field-container" key={i}>{filter}</div>
              ))}
            </div>
          }
        </div>
      }
      {props.tableFilterTop === true && 
        <>
          <h3 className="tb-filter-top-header">Filter Table</h3>
          <div className="tb-filter-top-container" ref={filterRef}>
            {filterArray.map((filter, i) => (
              <div className="table-filter-field-container" key={i}>{filter}</div>
            ))}
          </div>
        </>
      }
      {props.tableFilterWithButton === true &&
        <div className="tb-global-and-button-container">
          <GlobalFilterWithButton preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} 
            setGlobalFilter={setGlobalFilter} />
          {props.platformUser.user.permissions.addNewUsers === true &&
            <button className="button-square button-solid" onClick={() => props.buttonFunction()}>{props.buttonText}</button>
          }
        </div>
      }
      <table {...getTableProps()} className={props.tableClass}>
        {props.showHeader !== false &&
          <thead className={props.tableExtra === true ? "table-head-container" : null}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th className="header-row" key={column.id} 
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="text-medium">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? "   🡣"
                        : "   🡡"
                      : column.disableSortBy === true ? "" : " ⇅ Sort"
                    }
                  </span>
                </th>
                ))}
              </tr>
            ))}
          </thead>
        }
        <tbody {...getTableBodyProps()} className={props.tableExtra === true ? "table-body-container scrollbar" : null}>
          {page.map((row, i) => {
            prepareRow(row)
            if(Utils.isFieldEmpty(selectedRow)){
                return (
                <tr {...row.getRowProps()} key={row.id} id={row.id} className={props.rowClass}>
                  {row.cells.map(cell => {
                    
                    return <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  })}
                </tr>
                )
            }
            else{

              return (
                <tr {...row.getRowProps()} key={row.id} id={row.id} className={parseInt(row.id)===parseInt(selectedRow)?'tableAudit': props.rowClass}>
                  {row.cells.map(cell => {
                    
                    return <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  })}
                </tr>
                )
            }
            
          })}
        </tbody>
      </table>
      {pagination && 
        <div className="pagination">
          <div className="pagination-arrows">
            <button onClick={() => {
              gotoPage(0);
            }} disabled={!canPreviousPage}>
              <img src="/assets/icons/left-double.svg" alt="" />
            </button>
            <button onClick={() => {
                previousPage();
              }} disabled={!canPreviousPage}>
              <img src="/assets/icons/left.svg" alt="" />
            </button>
          </div>
          <div className="pagination-center">
          {paginationRange?.map((pageNumber, i) => {
            if(pageNumber === DOTS) {
              return <Dots key={i} pageCount={pageCount} gotoPage={(page) => gotoPage(page)} />
            } else {
              return <button key={i} className="pagination-page-number-button" id={(pageIndex + 1) === pageNumber ? "active-button-page" : null} 
                onClick={() => gotoPage(pageNumber - 1)}>{pageNumber}</button>
            }
          })}
          </div>
          <div className="pagination-arrows">
            <button onClick={() => {
                nextPage();
              }} disabled={!canNextPage}>
              <img src="/assets/icons/right.svg" alt="" />
            </button>
            <button onClick={() => {
              gotoPage(pageCount - 1);
            }} disabled={!canNextPage}>
              <img src="/assets/icons/right-double.svg" alt="" />
            </button>
          </div>
        </div>
      }
    </div>
  )
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

filterGreaterThan.autoRemove = val => typeof val !== "number"