import React, { useState, useMemo, useEffect } from "react";
import { 
  getBalance,
  getDebits,
  getCredits,
  resetWallet,
  selectWalletState
} from '../../redux/Slices/Wallet';
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import './Wallet.css';
import Tabs from "../../components/Tabs/Tabs";
import Spinner from "../../svg/Spinner/Spinner";
import Utils from "../../library/Utils";
import { Table, SelectColumnFilter, DateRangeColumnFilter } from '../../components/Table/Table';
import PaymentBox from '../../components/Modals/Payment';
import Modal from 'react-modal';
import { FetchDataStatus } from '../../library/Variables';
import { selectDownloadCsvStatus, downloadWalletUsageAsync, resetDownloadWalletUsage } from "../../redux/Slices/DownloadWalletUsage";

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

function Wallet(props) {
  localStorage.setItem('insightsLastVisited', '/wallet');

  let balance = 0;
  let tabBodiesArray = [];
  let debitsData = [];
  let creditsData = [];
  let debitContent = null;
  let creditContent = null;

  const configuration = props.configuration;
  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const user = props.user;
  const wallet = useSelector(selectWalletState);
  const [isLoading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const subscriptionState = props.subscriptionState.subscription;

  
  const params = 
  { 
    accessToken: accessToken,
    startDate: '',
    endDate: '',
  };

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      fontFamily: configuration.fontFamily,
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const theadersWalletDebits = useMemo(() => [
    { 
      Header: 'Date',
      accessor: 'date',
      Filter: DateRangeColumnFilter,
      filter: 'dateRange'
    },
    { 
      Header: 'User',
      accessor: 'user'
    },
    { 
      Header: 'Statement Name',
      accessor: 'statementName'
    },    
    {
      Header: 'Description',
      accessor: 'description',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Cell: data => {
        let statusCode = data.row.original.status;
        return (
          <span style={{ 
            fontWeight:'700',
            color: statusColor(statusCode)}}>
            { statusDescription(statusCode) }
          </span>
        );
      },
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
  ], []
  )

  const theadersWalletCredits = useMemo(() => [
    { 
      Header: 'Date',
      accessor: 'date',
      Filter: DateRangeColumnFilter,
      filter: 'dateRange'
    },
    { 
      Header: 'Description',
      accessor: 'description',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Old Balance',
      accessor: 'oldBalance',
      disableFilters: true
    },
    {
      Header: 'New Balance',
      accessor: 'newBalance',
      disableFilters: true
    }
  ], []
  )

  if(wallet.balanceStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(wallet.currentBalance)) {
    balance = wallet.currentBalance;
  }

  if(wallet.debitsStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(wallet.debits)) {
    wallet.debits.forEach(element => {
      debitsData.push(
        {
          "date": Utils.formatLongDate(element.date),
          "user": Utils.isFieldEmpty(element.user) ? element.firstName : element.user,
          "description": Utils.capitalizeFirstLetter(element.description),
          "statementName": Utils.capitalizeFirstLetter(element.statementName),
          "status": Utils.checkNull(element.statusCode),
          "amount": Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, element.amount)
        }
      )
    });

    debitsData.sort((firstItem, secondItem) => new Date(secondItem.date) - new Date(firstItem.date))

    debitContent = <Table data={debitsData} columns={theadersWalletDebits} pagination={true} pageSize={[10]} filterColor={"#2A38A4"} 
      filterClass="filter-primary-darkest" tableExtra={false} tableFilter={true} rowClass={"table-row-two-colored"} />

    tabBodiesArray.push({
      title: 'Debits',
      content: debitContent
    });
  }

  if(wallet.creditsStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(wallet.credits)) {
    wallet.credits.forEach(element => {
      creditsData.push(
        {
          "date": Utils.formatLongDate(element.date),
          "description": Utils.capitalizeFirstLetter(element.description),
          "amount": Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, element.amount),
          "oldBalance": Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, element.oldBalance),
          "newBalance": Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, element.newBalance)
        }
      )
    });

    creditsData.sort((firstItem, secondItem) => new Date(secondItem.date) - new Date(firstItem.date))

    creditContent = <Table data={creditsData} columns={theadersWalletCredits} pagination={true} pageSize={[10]} tableExtra={false} 
      tableFilter={true} filterColor={"#2A38A4"} filterClass="filter-primary-darkest" rowClass={"table-row-two-colored"} />

    tabBodiesArray.push({
      title: 'Credits',
      content: creditContent
    });
  }

  if((wallet.balanceStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(wallet.currentBalance))) {
    if(isLoading === true) {
      setLoading(false);
    }
  }

  function afterOpenModal() { }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    dispatch(resetWallet());
  }

  function exportwalletusage(params) {
    dispatch(downloadWalletUsageAsync(params));
    dispatch(resetDownloadWalletUsage());
  }

  useEffect(() => {  
    if((Utils.isFieldEmpty(wallet)) || wallet.balanceStatus === FetchDataStatus.INITIAL) {
      dispatch(getBalance(params));
    }
  
    if((Utils.isFieldEmpty(wallet)) || wallet.debitsStatus === FetchDataStatus.INITIAL) {
      dispatch(getDebits(params));
    }
  
    if((Utils.isFieldEmpty(wallet)) || wallet.creditsStatus === FetchDataStatus.INITIAL) {
      dispatch(getCredits(params));
    }
  }, [wallet, dispatch])

  if(isLoading === true || wallet.debitsStatus === FetchDataStatus.FETCHING || wallet.creditsStatus === FetchDataStatus.FETCHING) {
    return <div className="wallet-body-spinner" >
      <Spinner />
    </div>
  } else if(isLoading === false) {
    return (
      <div className="wallet-body">
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="">
          <PaymentBox accessToken={accessToken} close={closeModal} user={user.user} configuration={configuration} />
        </Modal>
        <div className="wallet-main-body">
          <div className="wallet-balance-container" style={{ backgroundImage: 'url(/assets/images/waves.png)' }}>
            <div className="wallet-balance-header">
              <h2>Current Wallet Balance</h2>
              <h3>{(user.user.role==='ADMIN' && subscriptionState?.plan==='UNLIMITED_TIER')
              ?Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, balance)
              :(user.user.role!=='ADMIN' && subscriptionState?.plan==='UNLIMITED_TIER')
              ?`${Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, balance)[0]}*****`
              :Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, balance)}</h3>
            </div>
            <div className="wallet-balance-button-container">
            {configuration.featureFlags.isFundingWalletEnabled === true && user.user.permissions.fundWallet === true &&
             
              <button className="btn-wallet-add" onClick={() => openModal()}>Fund Wallet</button>
            }
            <br></br>
            <button className="btn-wallet-add" onClick={() => exportwalletusage(params)}>Export Wallet Usage</button>

            </div>
          </div>
          {!Utils.isFieldEmpty(creditContent) && !Utils.isFieldEmpty(debitContent)
            ? <Tabs tabBodies={tabBodiesArray} extraClass='top-level-tabs' tabBodyHeader={{ header: "Wallet Usage" }} />
            : !Utils.isFieldEmpty(creditContent) && Utils.isFieldEmpty(debitContent)
              ? <div className="wallet-transactions-container">
                  <h2 className="single-table-header detail-header">Credit History</h2>
                  {creditContent}
                </div>
              : !Utils.isFieldEmpty(debitContent) && Utils.isFieldEmpty(creditContent)
                ? <div className="wallet-transactions-container">
                    <h2 className="single-table-header detail-header">Debit History</h2>
                    {debitContent}
                  </div>
                : <h3 className="single-table-header">No Wallet History Data to Show</h3>
          }
        </div>    
      </div>
    )
  }
}

function statusColor(statusCode){
  var color = "#000000"
  if(statusCode == '200'){
    color = "#4da64b"
  }
  else if(statusCode == '400' || statusCode == '404'){
    color = "#f5880c"
  }
  else if(statusCode == '500'){
    color = "#c93f3c"
  }
  return color;
}

function statusDescription(statusCode){
  var status = statusCode
  if(statusCode == '200'){
    status = "SUCCESS"
  }
  else if(statusCode == '400'){
    status = "INVALID REQUEST"
  }
  else if(statusCode == '404'){
    status = "NOT FOUND"
  }
  else if(statusCode == '500'){
    status = "FAILED"
  }
  return status;
}

export default Wallet;