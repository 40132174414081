import React, { useEffect, useRef, useState } from "react";
import "./custom.css";

const CustomDropDownMenu = ({ triggerText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    setFocusedIndex(-1);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
    setFocusedIndex(-1);
  };

  const handleItemClick = (item) => {
    console.log(`Selected item: ${item}`);
    handleCloseDropdown();
  };

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="dropdown">
      <span onClick={handleToggleDropdown}>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.89844 2.10156H19.0984C20.1984 2.10156 21.0984 3.00156 21.0984 4.10156V6.30156C21.0984 7.10156 20.5984 8.10156 20.0984 8.60156L15.7984 12.4016C15.1984 12.9016 14.7984 13.9016 14.7984 14.7016V19.0016C14.7984 19.6016 14.3984 20.4016 13.8984 20.7016L12.4984 21.6016C11.1984 22.4016 9.39844 21.5016 9.39844 19.9016V14.6016C9.39844 13.9016 8.99844 13.0016 8.59844 12.5016L4.79844 8.50156C4.29844 8.00156 3.89844 7.10156 3.89844 6.50156V4.20156C3.89844 3.00156 4.79844 2.10156 5.89844 2.10156Z"
            stroke="#2A38A4"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      {isOpen && (
        <div
          ref={dropdownRef}
          tabIndex="-1"
          className="dropdown-menu"
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              handleCloseDropdown();
            }
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default CustomDropDownMenu;
