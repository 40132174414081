import React, { useEffect, useState } from 'react';
import RuleCreationFailIcon from '../../../assests/icons/amlEmpty.png';
import RuleCreatedIcon from '../../../assests/icons/amlFound.png';
import { resetRules } from '../../../redux/Slices/SelfServiceDecision';
import '../RulesDefinition/defineRules.css';
import NewRule from '../RulesDefinition/rulesComponents/NewRule';

import { useDispatch, useSelector } from 'react-redux';
import { createRules, resetGetRulesByKey, resetParameterByKey, selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision';

import Utils from '../../../library/Utils';
import { PostDataStatus } from '../../../library/Variables';
import Spinner from '../../../svg/Spinner/Spinner';

function RulesModal({accessToken,scoreCardKey,closeModal,mainScoreCardKey,setMainParameterKey,mainParameterKey,paramName,setParamName,setParameterType,parameterType,customDecisionCard}) {
    const [rulesLength,setRulesLength] = useState([{key:'1',content:{}}])
    const dispatch = useDispatch()
    const selfState = useSelector(selectSelfServiceState)
    const [mainContent,setMainContent] = useState()
    const [scoreCard,setScoreCard] = useState()
    const [definitionRules,setDefinitionRules] = useState([])
    const [submittable,setSubmittable] = useState(false)
    const [storeScoreCardDet,setScoreCardDet] = useState([])
    const [triggerSaveRules,setTriggerSaveRules] = useState(false)
    const [triggerSubmit,setTriggerSubmit] = useState(false)
    
    const createSaveRules = ()=>{
          setTriggerSaveRules(true)
    }

    const closeRule = ()=>{
        dispatch(resetGetRulesByKey())
        dispatch(resetRules())
        dispatch(resetParameterByKey())
        closeModal()
    }

/**
 *     useEffect(()=>{
        dispatch(getSelfServiceCustomParams({accessToken}))

        if(selfState.selfServiceParametersStatus === FetchDataStatus.SUCCESS){
            setScoreCardDet(selfState?.selfServiceDefinitionList)
        }
        
    },[])
 */

    useEffect(()=>{
        if(!Utils.isFieldEmpty(scoreCard)){
            setSubmittable(true)
        }
        else{
            setSubmittable(false)
        }
    },[scoreCard])

    const submitAllRules = ()=>{
        const filledRules = {"scoreCardDefinitionKey": parseInt(mainScoreCardKey),
        "scoreCardDefinitionRules":definitionRules}

        dispatch(createRules({data:filledRules,accessToken}))
   
    }

    useEffect(()=>{
        if(triggerSubmit){
            submitAllRules()
        }
    },[triggerSubmit])

    const addRuleCard = ()=>{
        const newRuleCard = [...rulesLength,{key:`${rulesLength.length+1}`,content:{}}]
        setRulesLength(()=>newRuleCard)
    } 


    const ruleLoaded = 
                        <section style={{padding:'10px 10px',boxSizing:"border-box",height:'auto',overflowY:'auto'}}>

                            <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                                    ,marginBottom:'25px'}}>
                                <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                                    <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                                        Define Rules
                                    </span>
                                </div>
        
                            </section>

                            
                            <div >
                                {rulesLength.map((ruleContent,index)=><NewRule ruleIndex={index} setRulesLength={setRulesLength} 
                                                rulesLength={rulesLength} ruleContent={ruleContent} 
                                                paramData={selfState?.selfServiceParameterData}
                                                scoreCardList={selfState?.selfServiceDefinitionList} definitionRules={definitionRules}
                                                setDefinitionRules={setDefinitionRules} scoreCard={scoreCard} 
                                                mainParameterKey={mainParameterKey}
                                                accessToken={accessToken} setScoreCardDet={setScoreCardDet}
                                                storeScoreCardDet={storeScoreCardDet}
                                                triggerSaveRules={triggerSaveRules}
                                                setTriggerSubmit={setTriggerSubmit}
                                                parameterType={parameterType}
                                                paramName={paramName}
                                                setParamName={setParamName}
                                                customDecisionCard={customDecisionCard}
                                                scoreCardKey={scoreCardKey}
                                                setMainParameterKey={setMainParameterKey}
                                                setParameterType={setParameterType}
                                                mainScoreCardKey={mainScoreCardKey}
                                                />)}      
                            </div>
                            <section style={{display:'flex',alignItems:'center',justifyContent:'end',columnGap:'15px'}}>
                                <div className='cancelParameter' onClick={()=>closeModal()}>Close</div>
                                <div className='createParameter' onClick={()=>createSaveRules()}>Submit</div>
                            </section>
                        </section>
    useEffect(()=>{
     
        setMainContent(ruleLoaded)
        
     /**
      *    if(!Utils.isFieldEmpty(setScoreCardDet(selfState?.selfServiceDefinitionList))){
            setScoreCardDet(selfState?.selfServiceDefinitionList)
        }
      */

        if(selfState.parameterRulesStatus === PostDataStatus.FETCHING){
            setMainContent(<Spinner/>)
        }
        if(selfState.parameterRulesStatus === PostDataStatus.SUCCESS){
            let show = 
            <section style={{padding:'10px 10px',boxSizing:"border-box"}}>
                <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                        ,marginBottom:'25px'}}>
                    <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                            Decision Engine
                        </span>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                            Customize your Decision Engine Rules
                        </span>
                    </div>

                </section>
                <section className='successHoldBody'>
                    <span className='imgFlex'>
                        <img src={RuleCreatedIcon}/>
                    </span>
                    <span className='cardInfo'>Your Rule was created successfully.</span>
                    <span className='cardDirection'>Click done to see</span>

                    <section style={{display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        width:"100%"}}>
                        <span onClick={()=>closeRule()} className='button'>
                            Done
                        </span>
                    </section>
                                    
                </section>
                </section>
            
            setMainContent(show)
        }

        if(selfState.parameterRulesStatus === PostDataStatus.FAILURE){
            let show = 
            <section style={{padding:'10px 10px',boxSizing:"border-box"}}>
                <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                        ,marginBottom:'25px'}}>
                    <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                            Decision Engine
                        </span>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                            Customize your Decision Engine Rules
                        </span>
                    </div>

                </section>
            

                <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={RuleCreationFailIcon}/>
                                </span>
                                <span className='cardInfo'>Rule Creation Failed</span>
                                <span className='cardFailed'>{selfState.parameterOptionsStatusErrorMessage}</span>

                                <section style={{display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                width:"100%"}}>
                                    <span onClick={()=>{dispatch(resetRules());closeModal()}} className='button'>
                                        Close
                                    </span>
                                </section>
                                    
                            </section>
                
            </section>
            
            setMainContent(show)
        }
        
    },[selfState,rulesLength,definitionRules,scoreCard,storeScoreCardDet,triggerSaveRules,triggerSubmit])
    
  return (
    <section  >
        {mainContent}
    </section>
  )
}

export default RulesModal