import React, { useEffect } from "react";
import {
  selectMobileInsightState,
  getBankInsights,
  getBankTransactions,
  resetActiveBankAccountInsights
} from '../../../redux/Slices//MobileInsight';
import { useParams } from "react-router-dom";
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import Tabs from '../../../components/Tabs/Tabs';
import Spinner from "../../../svg/Spinner/Spinner";
import MobileAnalytics from "./MobileAnalytics";
import MobileTransactions from "./MobileTransactions";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import Utils from "../../../library/Utils";
import List from "../../../components/List/List";
import './BankAccount.css';
import { FetchDataStatus } from "../../../library/Variables";

function BankAccount(props) {
  let { key } = useParams();
  let keyFromRoute = window.location.href.split('/');
  let tabBodiesArray = [];
  let overallAccountDetailsContent = null;
  let transactionsContent = null;
  let analyticsContent = null;
  let content = <Spinner />;

  const dispatch = useDispatch();
  const account = props.accountInfo;
  const configuration = props.configuration;
  const accessToken = props.accessToken;
  const insight = useSelector(selectMobileInsightState);

  const params = { 
    accessToken: accessToken,
    key: key,
    senderKey: account.senderKey,
    accountKey: account.accountKey
  };

  keyFromRoute = keyFromRoute[keyFromRoute.length - 1];
  params.keyFromRoute = keyFromRoute;

  let backButton = <button onClick={() => { dispatch(resetActiveBankAccountInsights()); props.parentCallback()}} 
    className="button-solid-darkest mb-back-button">🡨 Back to accounts</button>

  if(!Utils.isFieldEmpty(insight.bankInsight) || !Utils.isFieldEmpty(insight.bankTransactions)) {
    overallAccountDetailsContent = <>
      <DetailsCard title={(account.name).toUpperCase()} subtitle={account.accountNumber} 
        subtitleClass="detail-header" extraClass="mb-account-analytics-details" detailClassName="mb-account-overall-details"
        detailContent={ <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
          detailClass="detail-header" listContent={[
            { header: 'Total transactions', detail: Utils.checkNull(insight.bankInsight.totalTransactionsAnalyzed) },
            { header: 'First date of transaction', detail: Utils.formatLongDate(insight.bankInsight.firstDate) },
            { header: 'Last date of transaction', detail: Utils.formatLongDate(insight.bankInsight.lastDate) }
          ]} />
        }
      />
    </>
  }
  
  if(!Utils.isFieldEmpty(insight.bankInsight)) {
    analyticsContent = <MobileAnalytics analytics={insight.bankInsight} configuration={configuration} />
      
    tabBodiesArray.push(
      {
        title: 'Analytics',
        content: analyticsContent
      }
    )
  }

  if(!Utils.isFieldEmpty(insight.bankTransactions)) {
    transactionsContent = <div className="mb-account-transactions">
      <MobileTransactions transactions={insight.bankTransactions} configuration={configuration} />
    </div>

    tabBodiesArray.push(
      {
        title: 'Transactions',
        content: transactionsContent
      }
    )
  }

  if(!Utils.isFieldEmpty(transactionsContent) || !Utils.isFieldEmpty(analyticsContent)) {
    if(Object.keys(insight.bankTransactions).length >= 10) {
      content = <Tabs tabBodies={tabBodiesArray} extraClass='analytics-tabs' background={true} />
    } else {
      content = transactionsContent;
    }
  } else {
    content = <div className="cards mb-details-no-table-data-container">
      <h2 className="detail-header">There is no data to display at this time</h2>
    </div>;
  }

  useEffect(() => {
    if(Utils.isFieldEmpty(insight.bankInsight) && insight.bankInsightStatus === FetchDataStatus.INITIAL) {
      dispatch(getBankInsights(params));
    } 
    
    if(Utils.isFieldEmpty(insight.bankTransactions) && insight.bankTransactionsStatus === FetchDataStatus.INITIAL) {
      dispatch(getBankTransactions(params));
    }
  }, [insight, dispatch])

  return (
    insight.bankInsightStatus !== FetchDataStatus.SUCCESS && insight.bankTransactionsStatus !== FetchDataStatus.SUCCESS
    ? <Spinner />
    : <div className='mb-insight-tabs-container bank-account'>
        {backButton}
        {overallAccountDetailsContent}
        {content}
      </div>
  )
}

export default BankAccount;