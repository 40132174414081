import React from 'react';
import Utils from '../../../../library/Utils';
import { useForm } from "react-hook-form";
import "./CustomScore.css";
import { InputType, ParameterName } from '../../../../library/Variables';

function ScoreCardForm(props) {
  let inputs = null;

  const data = props.data;
  const radioSelected = props.radioSelected;
  const cardSelected = data[radioSelected];
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmit = (formData) => {
    let data = {};

    
  for(const property in formData) {
      if(property !== "chargeAccept") {
        data[property] = formData[property];
      }      
    }
    props.parentCallback(data);
     
  }

  if(!Utils.isFieldEmpty(cardSelected.customParameters)) {
    inputs = cardSelected.customParameters.map((parameter, i) => {
      let field = null;

      if(parameter.displayType.toUpperCase() === InputType.SELECT) {
        field = <select {...register(parameter.name)} >
          {parameter.options.map((option, j) => {
            return <option key={j} value={option}>{option}</option>
          })}
        </select>      
      } else {
        field = <input type={parameter.displayType} {...register(parameter.name)} />
      }

      return <div className={parameter.isComputed? "emptyField": "custom-credit-score-input-field"} key={parameter.displayType + i}>
        <label className="input-labels text-darker">{Utils.separateText(parameter.name)}</label>
        {field}
        <span className="error-msg">{errors[parameter.name]?.message}</span>
      </div>
    })
  }

  return (
    <div className="custom-score-main-container">
      <div>
        <h2>{cardSelected.scorecardDefinitionName}</h2>
        <p className="text-medium">Add variables</p>
      </div>      
      <form onSubmit={handleSubmit(onSubmit)} className="score-card-form">
        <div className="score-card-form-inputs-container">
          {inputs}
        </div>
        <div>
          <label className="custom-credit-accept-check">
            <input type="checkbox" id="custom-credit-charge-input" {...register("chargeAccept", 
              { required: "You must agree to the charge before generating a report" })} />
            <p>By checking this box, you are agreeing to the call fee</p>
          </label>
          <span className="error-msg">{errors.chargeAccept?.message}</span>
        </div>
        <div className="score-card-submit-container">
          <button className="button-outline" onClick={() => props.parentBackCallback()}>Back</button>
          <button type="submit" className="button-solid">Proceed</button>
        </div>
      </form>
    </div>
  )
}

export default ScoreCardForm;