import React from 'react'

function DynamicCard({data}) {
  return (
    <div style={{backgroundColor:'#EFF4FF',padding:"15px 35px",boxSizing:"border-box"}}>
        <p style={{fontSize:"20px",fontWeight:"500"}}>{data.mainTitle}</p>

        <div style={{display:"flex",justifyContent:'start',alignItems:'center',marginTop:"10px",flexWrap:'wrap',columnGap:"20px",rowGap:"30px"}}>
            {data.listOne.map((element,index)=>
            <div key={index} style={{display:"flex",flexDirection:"column",rowGap:"10px",width:"30%",}}>
                <span style={{color:"#363C4F",fontSize:"16px",fontWeight:"500"}}>{element.title}</span>
                <span style={{color:"#1F2228",fontSize:"20px",fontWeight:"500"}}>{element.value}</span>
            </div>)}
        </div>

       
    </div>
  )
}

export default DynamicCard