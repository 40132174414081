import React from 'react'
import { selectLoanState,resetGetAllLoanData,resetLoanFilter,resetLoanSummary} from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';

function FormUploadModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectLoanState)
    const accessToken = props.accessToken
    const uploadError = props.uploadError
    const imgFile = props.imgFile
    const setUploadError = props.setUploadError
    const setShowForm = props.setShowForm


    let content = null;
    const params = {
        files:props.imgFile,
        accessToken,
    }

    const successDone = ()=>{
        props.closeModal();setShowForm(false);
        dispatch(resetGetAllLoanData());
        dispatch(resetLoanSummary())
        dispatch(resetLoanFilter())
       
    }

  

 

    if(loanState.formUploadStatus===PostDataStatus.FETCHING){
 

        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.formUploadStatus===PostDataStatus.SUCCESS){
      
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Form Succesfully Submitted</p>
            <div className='buttonHold'>   
                <section onClick={()=>successDone()}>Done!</section>
            </div> 
        </div>
    }

    if(loanState.formUploadStatus===PostDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanState.errorMessageFormUpload}</p>

            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();setShowForm(false)}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default FormUploadModal