import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Utils from '../../../../library/Utils';
import { selectBureauState } from '../../../../redux/Slices/Bureau';
import KenyaCrbComp from './KenyaObjSerializerPrembly/Component/KenyaCrbComp';
import CreditAndEnquire from './KenyaObjSerializers/CreditAndEnquire';
import LoanAccountsOverview from './KenyaObjSerializers/LoanAccountsOverview';
import LoanAmounts from './KenyaObjSerializers/LoanAmounts';
import NonPerformingAccounts from './KenyaObjSerializers/NonPerformingAccounts';
import ScoreOutput from './KenyaObjSerializers/ScoreOutput';
import './bureauDataKen.css';


function BureauDataKenBasic({index,viewReport,setViewReport,reportFormat,premblyUi,configuration}) {
    const bureau = useSelector(selectBureauState);
    let userReport = {...bureau.CrbHistoricalData[index]}

    let content;
    let contentTwo


    const [tabSelectedFull,setTabSelectedFull] = useState('Score Output_Data')
    const contentTwoHeadsFull = ['Score Output Data','Loan Accounts Overview','Non Performing Accounts','Loan Amounts','Credit History Enquiries']

    if(!premblyUi){
      const reportKeys = Object.keys(userReport);
      const personalDet = userReport.data.personal_profile
      const personalKeys = Object.keys(personalDet)


      content = 
          <>
            
            <section>
              
                <section className='reportOverallContent'>
                    <section className='headerItem'>
                      Personal Details
                    </section>
                    <div className='report-contentBureauKen'>
                      <div className='personalDetKen'>
                          {personalKeys.map(key=>
                          <div className='keyValue-holder' key={personalKeys.indexOf(key)}>
                            <p className='key'>{key}</p>
                            <p className='value'>
                                {!Utils.isFieldEmpty(personalDet[key])?
                                    personalDet[key]
                                    :'--'}
                            </p>
                          </div>)}
                      </div>
                    </div>
                </section>
            </section>

            </>

    
    contentTwo = 
    <section style={{marginTop:'40px'}}>
               
        <section className='contentTwoTabsKen'>
        { contentTwoHeadsFull.map((element,index)=>
            <div 
                onClick={()=>setTabSelectedFull(element)}
                className={tabSelectedFull===element?'selecTed':'unselecTed'}>
                {element}
            </div>)}
        </section>

        <section>
            {tabSelectedFull==='Score Output Data'?
            
            <ScoreOutput data={bureau.CrbHistoricalData[index]['data']['score_output']}/>:
            tabSelectedFull==='Loan Accounts Overview'?
            <LoanAccountsOverview data={bureau.CrbHistoricalData[index]['data']['summary']}/>:
            tabSelectedFull==='Non Performing Accounts'?
            <NonPerformingAccounts data={bureau.CrbHistoricalData[index]['data']['summary']}/>:
            tabSelectedFull==='Loan Amounts'?
            <LoanAmounts data={bureau.CrbHistoricalData[index]['data']['summary']}/>:
            <CreditAndEnquire data={bureau.CrbHistoricalData[index]['data']['summary']}/>  }
        </section>
    </section>

    }


    

    useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])


    


  return (
    <div>
        <div className='report-hold'>
          <div className='roof'>
              <span 
                  className={viewReport?'span-view-report':'span-view-history'} 
                  onClick={()=>{setViewReport(false)}}>
                  History
              </span>
              <span>/</span>
              <span className={viewReport?'span-view-history':'span-view-report'}>View report</span>
          </div>

          <p className='title-class'>{reportFormat==='Individual'? 'Bureau Data for Individual':'Bureau Data for Business'}</p>
          <p style={{margin:'10px 0px'}}>{`Here's the report from the Bureau Data check`}</p>

          {
            premblyUi?

            <KenyaCrbComp data= {userReport} configuration={configuration}/>

            :
            <>
              <>
                {content}
              </>

              <>
                {contentTwo}
              </>
            </>

            
          }
      

   
    </div>
    </div>
  )
}

export default BureauDataKenBasic

//