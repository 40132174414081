
import Utils from "../../../library/Utils";

export const numberPlateSerializer = (data)=>{

const lookOutList = ['owner','vehicle']
let ownerReshaped = {}
let vehicleReshaped = {}
let logbookReshaped = {}
let entryReshaped = {}
const reportKeys = Object.keys(data);

if(!Utils.isFieldEmpty(data.owner[0])){
    //ownerReshaped = {...reportKeys.owner[0]}

    const ownerKeys = Object.keys(data.owner[0]);

    ownerKeys.map((element)=>
        (!Utils.isFieldEmpty(data['owner'][0][element]) && 
        (typeof(data['owner'][0][element])==='string' || 
        typeof(data['owner'][0][element])==='number' ||
        typeof(data['owner'][0][element])==='boolean' ))?
        ownerReshaped[element] = data['owner'][0][element]
        :''
    )
}


if(!Utils.isFieldEmpty(data.vehicle)){
    const vehicleKeys = Object.keys(data.vehicle);

    vehicleKeys.map((element)=>
        (!Utils.isFieldEmpty(data['vehicle'][element]) && 
        (typeof(data['vehicle'][element])==='string' || 
        typeof(data['vehicle'][element])==='number' ||
        typeof(data['vehicle'][element])==='boolean' ))
        ?
        vehicleReshaped[element] = data.vehicle[element]
        :''
    )

    const logBookKeys = Object.keys(data.vehicle.logbookNumber);

    logBookKeys.map((element)=>
        (!Utils.isFieldEmpty(data['vehicle']['logbookNumber'][element])
         && (typeof(data['vehicle']['logbookNumber'][element])==='string' || 
         typeof(data['vehicle']['logbookNumber'][element])==='number' ||
         typeof(data['vehicle']['logbookNumber'][element])==='boolean' ))?
        logbookReshaped[element] = data.vehicle.logbookNumber[element]
        :''
    )


    const entryKeys = Object.keys(data.vehicle.entry);

    entryKeys.map((element)=>
        (!Utils.isFieldEmpty(data['vehicle']['entry'][element]) 
        && (typeof(data['vehicle']['entry'][element])==='string' || 
        typeof(data['vehicle']['entry'][element])==='number' ||
        typeof(data['vehicle']['entry'][element])==='boolean' ))?
        entryReshaped[element] = data.vehicle.entry[element]
        :''
    )
}

return {...ownerReshaped, ...vehicleReshaped, ...logbookReshaped, ...entryReshaped}
}