import React from 'react'

function StepFour({setTourSection}) {

    const pop = <div style={{marginLeft:'5%',marginTop:"35px",position:'relative',height:"150px",width:'300px'}}>
    <div style={{width:"20px",height:"20px",transform:"rotate(45deg)",position:'absolute',top:"-10px",left:"20px",border:"1px solid lightblue",zIndex:"4"}}></div>
    <div style={{width:"19px",height:"19px",transform:"rotate(45deg)",position:'absolute',top:"-9px",left:"21px",backgroundColor:"white",zIndex:"14"}}></div>
    <div style={{height:'100%',padding:'5px',zIndex:'10',borderRadius:'7px',border:"1px solid rgba(195, 212, 255, 1)",position:"absolute",top:'0px',left:"0px",right:'0px',backgroundColor:"white",display:'flex',flexDirection:"column",justifyContent:"space-between"}}>
    <div style={{height:"60%",boxSizing:"border-box",display:"flex",flexDirection:"column",justifyContent:'space-around'}}>
      <p><strong>Credit score generated.</strong> You can see score history.</p>
    </div>

    <div style={{border:"0.1px solid lightblue",display:"flex",justifyContent:"space-around",alignItems:"center",boxSizing:"border-box",borderWidth:'0.1px 0px 0px 0px',height:"40%"}}>
        <span onClick={()=>setTourSection('stepThree')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"white",cursor:'pointer',color:'#407BFF',border:"1px solid #407BFF"}}>Back</span>
        <span><strong>3 of 4</strong></span>
        <span onClick={()=>setTourSection('stepFive')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"#407BFF",cursor:'pointer',color:'white'}}>Next</span>
    </div>
    </div>
  </div>

const proceed = 
<>
    <div style={{width:'90%',margin:"0px auto",display:"flex",justifyContent:"space-between"}}>
        <div style={{display:"flex",flexDirection:'column'}}>
            <h3>Score History</h3>
            <p style={{fontSize:'11px',color:'grey',marginTop:"10px"}}>See all scores generated so far.</p>
        </div> 
        <div style={{width:'fit-content',backgroundColor:'#407BFF',color:'white',padding:"7px 12px",display:'flex',alignItems:"center",justifyContent:"center",borderRadius:"5px",}}>Generate a Score</div>   
    </div>
</>

const Table = [
    {customerId:'12340984',
    createdDate:'10th of April 2022',
    status:'PROCESSED'},
    {customerId:'28409382',
    createdDate:'10th of April 2022',
    status:'FAILED'},
    {customerId:'930458293',
    createdDate:'10th of April 2022',
    status:'PROCESSED'}
]

const filters = ['Name','Created Date','Status','Clear','Search']
  return (
    <div>
        <div style={{padding:'25px 25px'}}>
            {proceed}
        </div> 
        <div style={{width:"90%",display:"flex",margin:'15px auto',justifyContent:"space-between"}}>
            <p style={{width:"15%",display:"flex",alignItems:"center",justifyContent:"center",}}>Search</p>
            <div style={{width:"15%",display:"flex",height:"45px",borderRadius:"7PX",alignItems:"center",justifyContent:"start",boxSizing:"border-box",color:"black",border:"0.1px solid #407BFF",paddingLeft:'5px'}}>Name</div>
            <div style={{width:"15%",display:"flex",height:"45px",borderRadius:"7PX",alignItems:"center",justifyContent:"start",boxSizing:"border-box",color:"black",border:"0.1px solid #407BFF",paddingLeft:'5px'}}>Created Date</div>
            <div style={{width:"15%",display:"flex",height:"45px",borderRadius:"7PX",alignItems:"center",justifyContent:"start",boxSizing:"border-box",color:"black",border:"0.1px solid #407BFF",paddingLeft:'5px'}}>Status</div>
            <div style={{width:"15%",display:"flex",height:"45px",borderRadius:"7PX",alignItems:"center",justifyContent:"center",boxSizing:"border-box",color:"black",border:"0.1px solid #407BFF"}}>Clear</div>
            <div style={{width:"15%",display:"flex",height:"45px",borderRadius:"7PX",alignItems:"center",justifyContent:"center",boxSizing:"border-box",backgroundColor:'#407BFF',color:"white"}}>Search</div>
        </div>
        <div style={{width:'90%',margin:"10px auto",border:"0.1px solid lightblue",padding:'15px',display:'flex',flexWrap:'wrap',height:"150px",position:'relative'}}>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}><strong>Customer ID</strong></p>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}><strong>Created Date</strong></p>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}><strong>Status</strong></p>
                            <p style={{width:"10%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}></p>
                        </div>
            {Table.map(data=>
                        <div style={{width:"100%",display:"flex",justifyContent:"space-between",marginTop:'10px'}}>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}>{data.customerId}</p>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}>{data.createdDate}</p>
                            <p style={{width:"30%",textAlign:"left",paddingLeft:"10px",boxSizing:"border-box"}}>{data.status}</p>
                            <div style={{width:"10%",display:"flex",justifyContent:"end",boxSizing:"border-box",}}>
                                <span onClick={()=>setTourSection('stepFive')} style={{width:"100px",cursor:"pointer",color:"white",boxSizing:"border-box",backgroundColor:'#407BFF',padding:"0px 10px",borderRadius:'4px',display:'flex',alignItems:"center",justifyContent:"center"}}>View</span>
                            </div>
                        </div>)}
             
        </div>
        {pop}

        
    </div>
  )
}

export default StepFour