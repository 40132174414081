import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

const initialState = {
    status: FetchDataStatus.INITIAL,
    isError: false,  
    errorMessage: null,
    subscription: null,
  };

  export const getUserSubscription = createAsyncThunk('userSubscription',  async (accessToken) => {    
    let response = await client.get('/subscription',
      {
        "headers":
        {
          "Authorization": "Bearer " + accessToken
        }
      });

    return response.data;
  });

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
      initial: initialState,
      setSubscription: (state,action)=>{
        state.subscription = action.payload
      }
    },
    extraReducers(builder) {
      builder.addCase(getUserSubscription.pending, (state, action) => {
        state.status = FetchDataStatus.FETCHING;
      })
      .addCase(getUserSubscription.fulfilled, (state, action) => {
        state.status = FetchDataStatus.SUCCESS;
      })
      .addCase(getUserSubscription.rejected, (state, action) => {
        state.status = FetchDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
    }
  });

export const { initial,setSubscription} = subscriptionSlice.actions;

export const selectSubscription = state => state.subscription;

export default subscriptionSlice.reducer; 