import React,{useState} from 'react'
import arrowLeft from '../../../assests/icons/arrowLeftNew.png'
import loanIcon from '../../../assests/icons/loanIconColored.png'
import './loanManager.css'
import LoanManagementRecords from './LoanManagementRecords'
import { useNavigate } from 'react-router-dom'
import Modal from "react-modal";
import ReAssignModal from '../../../components/Modals/LoanModals/ManagementModals/ReAssignModal'
import AssignmentModal from '../../../components/Modals/LoanModals/ManagementModals/AssignmentModal'

function LoanManagePage({accessToken,user}) {
  const navigate = useNavigate()
  const [agenModalIsOpen, setAgentModal] = useState(false);
  const [reassigninglIsOpen, setAgentReassigning] = useState(false);
  const [loanKey,setLoanKey] = useState()

  function closeAgentModal() {
    setAgentModal(false);
    //dispatch(resetDownload());
  }

  function closeAssignmentModal() {
    setAgentReassigning(false);
    //dispatch(resetDownload());
  }



  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  return (
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section className='loanHead'>
           <section onClick={()=>navigate('/loanApproval')} className='loanContent'>
                <span><img src={arrowLeft}/></span>
                <span style={{marginLeft:'10px'}}>Back to Applications</span>
            </section>
            <section className='loanContent'>
                <span><img src={loanIcon}/></span>
                <p>Loan Application</p>
            </section>
        </section>

        <LoanManagementRecords accessToken={accessToken} user={user} setLoanKey={setLoanKey} setAgentModal={setAgentModal}/>


        <Modal isOpen={agenModalIsOpen} onRequestClose={closeAgentModal} style={customStyles}
          contentLabel="Agent Modal" >
          <ReAssignModal 
                           accessToken={accessToken} 
                           closeModal={closeAgentModal} setAgentReassigning={setAgentReassigning}
                           loanKey={loanKey}
                           />
      </Modal>

      <Modal isOpen={reassigninglIsOpen} onRequestClose={closeAssignmentModal} style={customStyles}
          contentLabel="ReAssigning Agent Modal" >
          <AssignmentModal 
                           accessToken={accessToken} 
                           closeModal={closeAssignmentModal}
                           
                           />
      </Modal> 
    </div>
  )
}

export default LoanManagePage