import React,{useState,useEffect} from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { uploadSignature,resetSignature,selectSignatureState} from '../../../redux/Slices/LoanSignatureUpload';
import Utils from '../../../library/Utils';


function SignatureUploadModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectSignatureState)
    const accessToken = props.accessToken
    const uploadError = props.uploadError
    const imgFile = props.imgFile
    const setImgUrl = props.setImgUrl
    const imgUrl = props.imgUrl
    const [imageFile,setImageFile] = useState(props.imgFile)
    const setUploadError = props.setUploadError
    const setShowName = props.setShowName
    const setShowForm = props.setShowForm
    const selectedImgList = props.selectedImgList
    const setSelectedImgList = props.setSelectedImgList
    const setStateForPreview = props.setStateForPreview
    const stateForPreview = props.stateForPreview
    let content = null;
    const params = {
        files:imageFile,
        accessToken,
    }

    const removeFile = (index)=>{
        let imageList = [...imageFile]
        let imageListUrl = [...imgUrl]
        imageList.splice(index,1)
        imageListUrl.splice(index,1)
        

        if(imageList.length === 0){
            props.closeModal();
            dispatch(resetSignature())
            setUploadError(null);
        }
        else{
            setImageFile(imageList)
            setImgUrl(imageListUrl)
        }
        //setShowName(false);    
    }

    const displaySigs = ()=>{
        const listSigHolder = [...imageFile]
        const newListSigHolder = []
        listSigHolder.forEach((element,index)=>{
            newListSigHolder.push({file:element,url:imgUrl[index]})
        })
        const imgList = [...selectedImgList,...newListSigHolder]
        setSelectedImgList(imgList)
        setShowName(true)
    }

    useEffect(()=>{
        if(loanState.signatureStatus===PostDataStatus.SUCCESS){
            displaySigs()
            const signatureArray = [...stateForPreview,...loanState.signature.data]
            setStateForPreview(signatureArray)
        }
    },[loanState])


    if(uploadError){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                        <p style={{color:"red",fontSize:'24px',fontWeight:"500",textAlign:"center"}}>Error!</p>
                        <div style={{display:"flex",flexDirection:"column",gap:'15px',justifyContent:"space-between"}}>
                            {uploadError.map((error,index)=>
                            <p key={index} style={{textAlign:"center",fontSize:'14px'}}>{error.message}</p>)}

                        </div>
                        <div className='buttonHold'>
                            <section onClick={()=>{props.closeModal();setUploadError(null)}}>Cancel</section>
                        </div>
                    </div>
    }

    if(!uploadError && loanState.signatureStatus===PostDataStatus.INITIAL){
        content =<div style={{width:'100%',display:"flex",flexDirection:'column',alignItems:"center",justifyContent:"center"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Proceed with Upload?</p>
            <div style={{display:"flex",columnGap:"15px",overflowX:'auto',width:'100%',justifyContent:imageFile.length>1?"space-between":"center"}}>
            {
                imageFile.map((imgF,index)=><div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"center"}}>
            
            <p style={{color:"rgba(64, 123, 255, 1)",fontSize:'24px',fontWeight:"500",textAlign:"center"}}>{` "${imgF.name}"`}</p>
            <span>
                {imgF.name.slice(imgF.name.length-3,imgF.name.length)==='pdf'?
                    <iframe src={imgUrl[index]} style={{width:'auto',maxWidth:'40vh',height:"auto"}}></iframe>: 
                    <img style={{width:"auto",maxWidth:'40vh'}} src={imgUrl[index]}/>}
            </span>
            <div className='centerButton'>
                  <section className='back' onClick={()=>removeFile(index)}>Cancel</section>
                  
            </div>

            
            
        </div>)
        }
        
        </div>
            <div className='centerButton'>
                <section className='proceed' onClick={()=>{dispatch(uploadSignature(params))}}>Proceed</section>
            </div>
        </div> 
    }

    if(loanState.signatureStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.signatureStatus===PostDataStatus.SUCCESS){
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Uploaded</p>
            <div className='centerButton'>   
                <section className='proceed' onClick={()=>{props.closeModal();dispatch(resetSignature())}}>Done!</section>
            </div> 
        </div>
    }

    if(loanState.signatureStatus===PostDataStatus.FAILURE){
        content = 
      <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanState.errorMessageSignature}</p>

          <div className='centerButton'>   
                <section className='proceed' onClick={()=>{props.closeModal();dispatch(resetSignature())}}>Close</section>
            </div>
      </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default SignatureUploadModal