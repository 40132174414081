import React from 'react'
import { PerformingRegionsFull } from '../MakeUpData/PerformingRegions/PerformingRegionsHold'

function RegionMain({setViewPort}) {
  const listHeader = ['Region','No Of States','No Of Agents','Total GDP (₦ millions)','Value','Action']
  return (
    <section className='AgentTable'>
            <section className='titleBreakHold'>
              <p className='title'>{PerformingRegionsFull.title}</p>
              
            </section>

            <div>
              <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                {listHeader.map((header,index)=>(header==='Action'?
                  <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                  )}
              </section>
              <section>
                {PerformingRegionsFull.data.map((content,index)=>
                
                <section className='AgentTableRow' key={index}>
                  <section>{content.region}</section>
                  <section>{content.noOfStatest}</section>
                  <section>{content.noOfAgents}</section>
                  <section>{content.gdp}</section>
                  <section>{content.value}</section>
                  <div onClick={()=>setViewPort('regionFinalBreak')} style={{fontWeight:"700",width:"10%",display:'flex',
                              justifyContent:"center",alignItems:"center",
                              cursor:"pointer",backgroundColor:"#2B52AA",
                              color:"white",padding:"10px 0px",
                              borderRadius:"5px"}}>View</div>
                </section>)}
              </section>
            </div>
        </section>
  )
}

export default RegionMain