export const ProceedCard = [
    {   id:0,
        title:'Micro Loans',
        extra:'This scorecard is for micro loans'
    },
    {   id:1,
        title:'Agricultural Loans',
        extra:'This scorecard is for agricultural loans'
    },
    {   id:2,
        title:'Empowerment Loans',
        extra:'This scorecard is for empowerment loans'
    }
]