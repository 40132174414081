import { toast } from "react-toastify";
import "../index.css";

import storage from "../utils/storage";

// Request interceptor
function requestInterceptor(url, options) {
  const token = storage.getToken();



  return { url, options };
}

// Response interceptor
async function responseInterceptor(response) {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }

    const error = await response.json();

    if (response.status === 400) {
      const errors = error?.message;
      const errorMessagesArr = Object.values(errors);

        toast.error(errors, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#1C256D",
            color: "#fff",
          },
        });
        throw new Error(errors);
    } else {
      const message = error.message;
      toast.error(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#1C256D",
          color: "#fff",
        },
      });
      throw new Error(message);
    }
  }
  return response.json();
}

const BASE_URL = process.env.REACT_APP_SELF_SERVICE_API_BASE_URL;

function getDetails(url,body) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
        },
    body
  });
}


function getRequest(url) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "GET",
    headers: {
        'Content-Type': 'application/json'
        },
    
  });
}

function updateDetails(url, body) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
        },
    body,
  });
}

function selfServicePost(url, body) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
        },
    body,
  });
}


async function fetchWithInterceptors(url, options) {
  const modifiedOptions = requestInterceptor(url, options);

  const response = await fetch(modifiedOptions.url, modifiedOptions.options);
  const modifiedResponse = await responseInterceptor(response);
  return modifiedResponse;
}
export const WebHookClient = {
    getDetails,
    getRequest,
    updateDetails,
    selfServicePost
};



/**
 * toast.error(errorMessagesArr[0].toString(), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#1C256D",
            color: "#fff",
          },
        });
 */