import { useNavigate, useParams } from "react-router";
import { useRef } from "react";
import { Table } from "../../components/Table";
import { InnerLayout } from "../../components/layout/SecondaryLayout";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactionProfileAsync,
  userTransactionProfileSelector,
} from "../../../../redux/Slices/fraudDetect/transactionSlice";
import SkeletonLoader from "../../components/loader/skeletonLoader";
import styles from "./index.module.css";

import dayjs from "../../../../utils/dayjs";

export const colorCode = (status) => {
  if (
    status.toLowerCase() === "approved" ||
    status.toLowerCase() === "allowed"
  ) {
    return "#2DA711";
  } else if (status.toLowerCase() === "pending") {
    return "#dab600";
  } else {
    return "#E40000";
  }
};

const Transaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headerRef = useRef(null)

  const params = useParams();

  const userTransactionProfile = useSelector(userTransactionProfileSelector);

  useEffect(() => {
    dispatch(fetchTransactionProfileAsync({ userId: params?.userId }));
  }, [dispatch, params?.userId]);

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[userTransactionProfile?.data?.data?.transactions])

  return (
    <InnerLayout>
      <div >
        <div className={styles.titleWrapper}>
          <h5 className={styles.formtTitle}>User Data</h5>

          <button className={styles.applyBtn}>Download Profile Report</button>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Transaction Profile</h5>
          <div className={styles.profileDetailList}>
            {userTransactionProfile.status === "loading" && (
              <>
                {[1, 2, 3, 4].map((item) => (
                  <div key={item} className={styles.profileDetailListItem}>
                    <SkeletonLoader count={1} height={20} width={100} />
                    <SkeletonLoader count={1} height={30} width={200} />
                  </div>
                ))}
              </>
            )}

            {userTransactionProfile.data &&
              userTransactionProfile.data.data && (
                <>
                  <div className={styles.profileDetailListItem}>
                    <h6>Full Name</h6>
                    <h5>{userTransactionProfile.data.data?.fullName}</h5>
                  </div>
                  <div className={styles.profileDetailListItem}>
                    <h6>Email Address</h6>
                    <h5>{userTransactionProfile.data.data?.email}</h5>
                  </div>
                  <div className={styles.profileDetailListItem}>
                    <h6>Phone Number</h6>
                    <h5>{userTransactionProfile.data.data?.phoneNumber}</h5>
                  </div>
                  <div className={styles.profileDetailListItem}>
                    <h6>Risk Score</h6>
                    <h5>{userTransactionProfile.data.data?.riskLevel}</h5>
                  </div>
                </>
              )}
          </div>
        </div>

        <div className={styles.tableContainer}>
          <h5 className={styles.profileDetailTitle}>Transaction History</h5>
          <Table
            showPagination={true}
            pageCount={10}
            loading={userTransactionProfile.status === "loading"}
            columns={[
              {
                title: "Transaction ID",
                field: "transactionId",
              },
              {
                title: "Amount",
                field: "amount",
              },

              {
                title: "Channel",
                field: "channel",
              },
              {
                title: "Risk Score",
                field: "riskScore",
              },
              {
                title: "Transaction Date",
                field: "transactionDate",
              },
              {
                title: "Decision Date",
                field: "decisionDate",
              },
              {
                title: "Status",
                field: "status",
                Cell: ({ entry }) => (
                  <span
                    style={{
                      color: colorCode(entry.status),
                    }}
                  >
                    {entry.status}
                  </span>
                ),
              },
              {
                title: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span>Action</span>
                  </div>
                ),
                field: "action",
                Cell: ({ entry }) => (
                  <div>
                    <button
                      onClick={() =>
                        navigate(
                          `/fraud-detect/${params.userId}/transactions/${entry.transactionId}`
                        )
                      }
                      className={styles.contained}
                    >
                      View
                    </button>
                  </div>
                ),
              },
            ].filter(Boolean)}
            data={userTransactionProfile?.data?.data?.transactions.map(
              (item) => ({
                ...item,
                amount: new Intl.NumberFormat().format(item.amount),
                decisionDate: dayjs(item.decisionDate).format("MMMM Do, YYYY"),
                transactionDate: dayjs(item.transactionDate).format(
                  "MMMM Do, YYYY"
                ),
              })
            )}
          />
        </div>
      </div>
    </InnerLayout>
  );
};

export default Transaction;
