import React,{useState,useEffect,useRef,useMemo} from 'react'
import loanIcon from '../../assests/icons/loanIconColored.png'
import { Link,useNavigate } from 'react-router-dom'
import './loan.css'
import LoanForm from './LoanForms/LoanForm'
import { useDispatch,useSelector } from 'react-redux'
import arrowLeft from '../../assests/icons/arrowLeftNew.png'
import { getLoanParameter,selectLoanState,getAllLoanSummary } from '../../redux/Slices/Loan'
import { resetLoanParameter } from '../../redux/Slices/Loan'
import { FetchDataStatus } from '../../library/Variables'
import { Table,SelectColumnFilter, DateRangeColumnFilter } from '../../components/Table/Table'
import { resetLoanFileData,resetLoanVerifiedData,resetApproval} from '../../redux/Slices/LoanFile'
import Spinner from '../../svg/Spinner/Spinner'
import Utils from '../../library/Utils'
import { getAllLoanData,resetLoanFilter,getFilteredLoan } from '../../redux/Slices/Loan'
import { resetLoanScore } from '../../redux/Slices/LoanScores'
import { resetCustomDecisionCreditScore } from '../../redux/Slices/CustomDecisionCustomCreditScore'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetDraftData } from '../../redux/Slices/DraftAndEditLoan'
import summaryLogo from '../../assests/icons/supportDocImg.png'
import SubmittedLoans from './FilteredTables/SubmittedLoans'
import SavedLoans from './FilteredTables/SavedLoans'

function Loans(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const accessToken = props.accessToken
    const user = props.user
    const permissions = user.user.permissions
    const loanDataState = useSelector(selectLoanState)
    const [showForm,setShowForm] = useState(false)
    const headers = ['All Applications']
    let tableData = [];
    const tableHead = ['Applicant','Client ID','Submitted Date','Approval Date', 'Status','Action']
    const [selectedHead,setSelectedHead] = useState(0)
    const [formPage,setFormPage] = useState(1)
    const [contentForm,setContentForm] = useState(null)
    const [summaryData,setSummaryData] = useState([])
    const [selectedFilter,setSelectedFilter] = useState('submitted')
    const navRef = useRef(null)
    let content = <div className="st-details-body-spinner"><Spinner /></div>;
    localStorage.setItem("insightsLastVisited", `/loanApproval/`);

    const openLoanForm = ()=>{
      if(permissions.createLoan){

        dispatch(resetLoanParameter())
        setShowForm(true);
        dispatch(getLoanParameter({accessToken}))
      }
      else{
        toast.info('You are not permitted to request a loan', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    const changeFilter = (filter)=>{
       dispatch(resetLoanFilter())
       setSelectedFilter(filter)
       dispatch(getFilteredLoan({accessToken,filter}))
    }

    useEffect(()=>{
      dispatch(getFilteredLoan({accessToken,filter:'submitted'}))
    },[])




    useEffect(()=>{
        if(loanDataState.loanSummaryStatus === FetchDataStatus.INITIAL)
          {
            dispatch(getAllLoanSummary({accessToken}))
          }

          if(!Utils.isFieldEmpty(loanDataState.loanSummaryData)){
              const summaryHold = [{title:'Total Submitted Loan Applications',
                                      img:summaryLogo,
                                      amount:loanDataState.loanSummaryData.totalNumberOfSubmittedApplications},
                                      {title:'Total Approved Loan Applications',
                                      img:summaryLogo,
                                      amount:loanDataState.loanSummaryData.totalNumberOfApprovedApplications},
                                      {title:'Total declined Loan Applications',
                                      img:summaryLogo,
                                      amount:loanDataState.loanSummaryData.totalNumberOfDeclinedApplications},
                                      {title:'Total Saved Loan Applications',
                                      img:summaryLogo,
                                      amount:loanDataState.loanSummaryData.totalNumberOfSubmittedApplications}
                                      ]
              setSummaryData(summaryHold)
          }
    },[loanDataState])


    useEffect(()=>{
        if(showForm){
            
        }
    },[showForm])

    const viewLoanApplication = (checker)=>{
      if(permissions.viewLoanData){
        dispatch(resetLoanFileData());
        dispatch(resetApproval());
        dispatch(resetCustomDecisionCreditScore());
        dispatch(resetLoanScore());
        dispatch(resetLoanVerifiedData())
      }
      else{
        toast.info('You are not permitted to view loan Applications', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    const theadersStatements = useMemo(() => [
        { 
          Header: 'Applicant',
          accessor: 'applicant'
        },
        { 
            Header: 'Client ID',
            accessor: 'clientId'
          },
        { 
          Cell: data => {
            let statusDisplay = data.row.original.submittedDate;
            statusDisplay = Utils.formatLongDateMonthAndYear(statusDisplay)
            return (
              <span>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Submitted Date',
          accessor: 'submittedDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
          Cell: data => {
            let statusDisplay = data.row.original.approvalDate;
            statusDisplay = statusDisplay==='--'? '--': Utils.formatLongDateMonthAndYear(statusDisplay)
            return (
              <span>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Approval Date',
          accessor: 'approvalDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
          Cell: data => {
            let statusDisplay = data.row.original.status;
            return (
              <span style={{ 
                fontWeight:'700',
                color: statusDisplay==='PENDING'?'rgba(251, 109, 6, 1)':statusDisplay==='APPROVED'?'rgba(0, 119, 12, 1)':statusDisplay==='DRAFT'?'#000000':'rgba(228, 0, 0, 1)'}}>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Status',
          accessor: 'status',
          Filter: SelectColumnFilter,
          filter: 'includes'
        },
        {
          id: 'button-column',
          disableSortBy: true,
          Cell: data => {
            let link = !permissions.viewLoanData?'/loanApproval':data.row.original.status==='DRAFT'
                       ?`/draftedForm/${data.row.original.key}`:`/loanApproval/${data.row.original.key}`;
            //let link = permissions.viewLoanData?`/loanApproval/${data.row.original.key}`:'/loanApproval';
            return (
              <div onClick={()=>viewLoanApplication(data.row.original.status)} style={{backgroundColor:'rgba(64, 123, 255, 1)',display:"flex",alignItems:'center',justifyContent:"center",color:'white',padding:'10px 0px',borderRadius:'4px',textAlign:'center'}}>
                <Link to={link} state={{ key: data.row.original.key }} style={{display:"flex",color:'white',width:'60px',textDecoration:'none',alignItems:"center",justifyContent:"center"}} >View</Link>
              </div>
            );
          }
        }]
      )

      if(loanDataState.getLoanDataStatus===FetchDataStatus.INITIAL){
         content = <Spinner/>
         dispatch(getAllLoanData({accessToken}))
            
      }

      
    if(loanDataState.getLoanDataStatus===FetchDataStatus.FETCHING){
        content = <Spinner/>
    }

    if(loanDataState.getLoanDataStatus===FetchDataStatus.SUCCESS){
        if(Utils.isFieldEmpty(loanDataState.getLoanDataStatus)){
            content = 
                <div style={{height:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
                    <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>No filled form to be displayed</p>
                    <p>Click on New Loan Application button</p>
                </div>
        }
        else{

            loanDataState.formUpload.forEach(form => {
                tableData.push({
                  key: form.key,
                  applicant: form.name,
                  submittedDate: form.createdDate,
                  clientId:form.clientId,
                  approvalDate: !Utils.isFieldEmpty(form.updatedDate) ? form.updatedDate : "--",
                  status: form.status
                })
              })

            content = <Table tableClass="statement-type-table" pagination={true} pageSize={[10]} data={tableData} columns={theadersStatements}
            tableExtra={false} tableFilter={true} />;
        }
    }

    if(loanDataState.getLoanDataStatus === FetchDataStatus.FAILURE){
      content = 
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanDataState.errorGetLoanData}</p>
      </div>
    }

    
  return (
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section ref={navRef} className='loanHead'>
            {showForm && <section onClick={()=>setShowForm(false)} className='loanContent'>
                <span><img src={arrowLeft}/></span>
                <span style={{marginLeft:'10px'}}>Back to Applications</span>
            </section>}
            <section className='loanContent'>
                <span><img src={loanIcon}/></span>
                <p>Loan Application</p>
            </section>
        </section>

        <>
          {!showForm && 
          
            <section className='summaryHold'>
            {loanDataState.loanSummaryStatus === FetchDataStatus.SUCCESS?
          
              <section className='summaryMainContent'>
              {summaryData.map((element,index)=>
              <div key={index} className='summaryBox'>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <img src={element.img}/>

                </span>
                <p style={{color:'#525252',fontSize:"16px",lineHeight:"20px",width:"70%"}}>{element.title}</p>
                <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"32px"}}>{element.amount}</p>
              </div>)}
              </section>
           
            : <Spinner/>}
        </section>}
        </>

       <>
        {
          !showForm &&

          <>

          <section style={{display:"flex",justifyContent:"start",width:"95%",margin:"0px auto",columnGap:'15px',alignItems:"center"}}>
            <div style={{color:'#2B52AA',fontWeight:"700",cursor:'pointer'}}>Loans</div>
            <div onClick={()=>openLoanForm()} style={{backgroundColor:"white",cursor:'pointer',border:"1px solid #2B52AA",color:"#2B52AA",display:"flex",justifyContent:"center",alignItems:"center",padding:"10px 20px",borderRadius:"7px"}}>Add New</div>
            <div onClick={()=>navigate('/LoanManagement')} style={{backgroundColor:"#2B52AA",cursor:'pointer',color:"white",display:"flex",justifyContent:"center",alignItems:"center",padding:"10px 20px",borderRadius:"7px"}}>Manage Activities</div>
        </section>


        <section className='filterHold'>
                <section onClick={()=>changeFilter('submitted') } className={selectedFilter==='submitted'?'filterHeaderSelect':"filterHeaderNonSelect"}>Submitted Applications</section>
                <section onClick={()=>changeFilter('saved') } className={selectedFilter==='saved'?'filterHeaderSelect':"filterHeaderNonSelect"}>Saved Applications</section>
        </section>
          </>
        }
       </>
        <section className='loanBody'>
            {showForm?
            <div className='top-form'>
                <section style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                    <span style={{color:'rgba(0, 0, 0, 1)',fontSize:'18px',fontWeight:'700'}}>New Application Form</span>
                </section>
                <section className='top-form-nav'>
                    <div onClick={()=>setFormPage(1)} className={formPage===1?'top-form-nav-but-colored':'top-form-nav-but-uncolored'}>1</div>
                    <div style={{width:'240px',height:'1px',backgroundColor:'rgba(64, 123, 255, 1)'}}></div>
                    <div onClick={()=>setFormPage(2)} className={formPage===2?'top-form-nav-but-colored':'top-form-nav-but-uncolored'}>2</div>
                </section>
            </div>:
            <>
          
            </>}
            <div className='mainBody'>
                {showForm?<LoanForm navRef={navRef} user={user} setFormPage={setFormPage} setShowForm={setShowForm} formPage={formPage} accessToken={accessToken}/>
                :<>
                {selectedFilter==='saved'?<SavedLoans accessToken={accessToken} user={user}/>
                : <SubmittedLoans accessToken={accessToken} user={user}/>}
                </>}
            </div>
        </section>
    </div>
  )
}

export default Loans