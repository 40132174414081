import React from 'react'
import ClientCard from './ClientCard'

function ClientInfo({configuration,customData,permissions,setSectionType,setSectionName,setSlicedData,setEditModalOpen,approvalStatus}) {
 
  return (
    <div style={{width:'100%'}}>
      <ClientCard filterValue='CLIENT_INFO' setSectionName={setSectionName} configuration={configuration} 
                  setSlicedData={setSlicedData} setEditModalOpen={setEditModalOpen} data={customData} 
                  setSectionType={setSectionType} approvalStatus={approvalStatus} permissions={permissions}/>
      <ClientCard filterValue='RESIDENTIAL_ADDRESS' configuration={configuration} setSlicedData={setSlicedData} 
                  setEditModalOpen={setEditModalOpen} data={customData} setSectionType={setSectionType} 
                  setSectionName={setSectionName} approvalStatus={approvalStatus} permissions={permissions}/>
      <ClientCard filterValue='LOAN_DETAILS' configuration={configuration} setSlicedData={setSlicedData} 
                  data={customData} setEditModalOpen={setEditModalOpen} setSectionType={setSectionType} 
                  setSectionName={setSectionName} approvalStatus={approvalStatus} permissions={permissions}/>
    </div>
  )
}

export default ClientInfo