
import { createSlice } from "@reduxjs/toolkit"

export const parameterSlice = createSlice({
    name: 'customParameters',
    initialState: {
      parameters: [],
    },

    reducers: {
        addParameter: (state,action) => {
        
            state.parameters = [...state?.parameters,action.payload]
        },

        deleteParameter: (state,action)=> {
            let mainArr = [...state.parameters]
            mainArr.splice(action.payload,1)
            state.parameters = mainArr
        }
    }
})


export const { addParameter,deleteParameter } = parameterSlice.actions;

export const selfCustomParameters = state => state.customParameters
export default parameterSlice.reducer;