import React, { useState } from "react";
import { selectDownloadPdfStatus, downloadPdfAsync, resetDownload } from "../../redux/Slices/DownloadPdf";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { PostDataStatus } from "../../library/Variables";
import Spinner from "../../svg/Spinner/Spinner";

function DownloadPdf(props) {
  let component = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const statementKey = props.statementKey;
  const configuration = props.configuration;
  const statementName = "Insights Report -" + props.statementName;
  const downloadPdfState = useSelector(selectDownloadPdfStatus);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);


  const resetForm = () => {
    dispatch(resetDownload());
    reset();
  }
  
  const onSubmit = (formData) => {
    let formParams = {
      accessToken: accessToken,
      statementKey: parseInt(statementKey),
      statementName: statementName,
      downloadAnalytics: formData.downloadAnalytics,
      downloadAffordability: formData.downloadAffordability,
      downloadBureau: formData.downloadBureau,
      downloadCreditScore: formData.downloadCreditScore
    }

    if(formData.downloadAnalytics === true || formData.downloadAffordability === true 
      || formData.downloadBureau === true || formData.downloadCreditScore === true) {
      dispatch(downloadPdfAsync(formParams));
      setErrorMessage(null);
    } else if(formData.downloadAnalytics === false && formData.downloadAffordability === false 
      && formData.downloadBureau === false && formData.downloadCreditScore === false) {
      setErrorMessage("Please select at least one");
    }
  }

  if(downloadPdfState.pdfStatus === PostDataStatus.INITIAL) {
    component = <div className="modal-dialog download-dialog">
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <div className="modal-header">
          <h2 className="detail-header">Download PDF Report</h2>
          <div className="close" onClick={props.close}>
            <img src="/assets/icons/close-color.svg" alt="Close" />
          </div>    
        </div>
        <h4 className="detail-header-dark">Include</h4>
        <hr />
        <section className='download-inputs'>
          {configuration.featureFlags.isAnalyticsEnabled === true &&
            <label className='download-input-container'>
              <input name="download" type={'checkbox'} {...register("downloadAnalytics")} />{' '}
              Analytics
            </label>
          }
          {configuration.featureFlags.isAffordabilityEnabled === true &&
            <label className='download-input-container'>
              <input name="download" type={'checkbox'} {...register("downloadAffordability")} />{' '}
              Affordability
            </label>
          }
          {configuration.featureFlags.isBureauDataEnabled === true &&
            <label className='download-input-container'>
              <input name="download" type={'checkbox'} {...register("downloadBureau")} />{' '}
              Credit Bureau
            </label>
          }
          {configuration.featureFlags.isCreditScoringEnabled === true &&
            <label className='download-input-container'>
              <input name="download" type={'checkbox'} {...register("downloadCreditScore")} />{' '}
              Credit Score
            </label>
          }
        </section>
        {errorMessage && <span className="download-form-errors" style={{ color: "red"}}>{errorMessage}</span>}
        <div className="submit-container">
          <button type="submit" className="button-solid button-square">
            Download
          </button>
        </div>
      </form>
    </div> 
  } else if(downloadPdfState.isDownloading === true && 
    (downloadPdfState.pdfStatus !== PostDataStatus.INITIAL && downloadPdfState.pdfStatus !== PostDataStatus.SUCCESS)) {
      component = <div className="modal-dialog">
        <div className="modal-header">
          <h2 className="detail-header">Please wait...</h2>
          <div className="close" onClick={props.close}>
            <img src="/assets/icons/close-color.svg" alt="Close" />
          </div>    
        </div>
        <Spinner />
      </div>
  } else if(downloadPdfState.pdfStatus === PostDataStatus.SUCCESS) {
      dispatch(resetDownload())
      props.close();
  } else if(downloadPdfState.pdfStatus === PostDataStatus.FAILURE) {
    component = <div className="modal-dialog download-dialog">
      <div className="modal-header">
          <h2 className="detail-header">Download Failed</h2>
          <div className="close" onClick={props.close}>
            <img src="/assets/icons/close-color.svg" alt="Close" />
          </div>    
        </div>
      <div className="modal-body-container">
        <div className="modal-body-message">
          <p>This could be due to:</p>
          <ul>
            <li>Insufficient wallet funds</li>
            <li>Network error</li>
            <li>Server error</li>
            <li>Session expiry</li>
          </ul>
          <p>If the problem persists, please contact support for further inquiries.</p>
          </div>
      </div>
      <div className="submit-container">
        <button className="button-solid button-square" onClick={() => resetForm()}>
          Try Again
        </button>
      </div>
    </div>
  } 

  return (
    component
  )
}

export default DownloadPdf;