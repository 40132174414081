import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables';
let trackNo

  export const getPhoneDataAsync = createAsyncThunk('getPhoneData', async (params) => {
    trackNo = params.phoneNumber
    let data = {
        msisdn: params.phoneNumber,
        statementKey: parseInt(params.statementKey),
    }
    data = JSON.stringify(data)
    
   const phoneData = await client.post('/verify/ke/phonenumber',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });

    return phoneData;
   }
 );

 

 export const getBankList = createAsyncThunk('getBankList',async (accessToken)=>{
  const listOfBanks = await client.get('/verify/ke/banklist',
  {
    "headers":
    {
     "Authorization": "Bearer " + accessToken,
    }
  });
  return listOfBanks.data
 })


 export const getAccountDataAsync = createAsyncThunk('getAccountData', async (params) => {
  trackNo = params.bankAccount
  let data = {
    account: params.bankAccount,
    statementKey: parseInt(params.statementKey),
    bankid: params.bankId
  }
  data = JSON.stringify(data)
 const AccountData = await client.post('/verify/ke/bankaccount',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });
  return AccountData;
 }
);





export const getKenyaStatementKycHistory = createAsyncThunk('getHistoryData',async (params)=> {
  const HistoricalData = await client.get(`/statements/${params.key}/kyc`,
  {
    "headers":
    {
     "Authorization": "Bearer " + params.accessToken,
    
    }
  });
   return HistoricalData.data;
})




export const KenyakycStatementSlice = createSlice({
  name: 'kenyaKycStatementData',
  initialState: {
    activeExtractions: [],
    bvnExtraction: [],
    ninExtraction:[],
    passportExtraction:[],
    licenseExtraction:[],
   
    extractionStatus: PostDataStatus.INITIAL,
    

    historyStatus: FetchDataStatus.INITIAL,
    historyExtractions:[],
    historyErrorMessage:null,
    isHistorytError:false,


    isError: false,
    errorMessage: null,
    reportData:null,
    reportStatus:FetchDataStatus.INITIAL,
    reportErrorMessage:null,
    isReportError:false,
    standAloneStatus:FetchDataStatus.INITIAL,
    standAloneData:null,
    standAloneErrorMessage:null,

    bankNames:[],
    bankNamesStatus:FetchDataStatus.INITIAL,
    bankNameError:null
  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetKenyaKycData: (state)=>{
      state.extractionStatus = PostDataStatus.INITIAL
      state.isError = false
      state.errorMessage = null
    },

    resetReport: (state)=>{
      state.reportData = null;
      state.reportStatus = FetchDataStatus.INITIAL
      state.reportErrorMessage = null
      state.isReportError = false
    }
  },
  
  extraReducers(builder) {
  builder.addCase(getPhoneDataAsync.pending, (state) => {
    state.extractionStatus = PostDataStatus.FETCHING;
  }).addCase(getPhoneDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',
                    verificationType:'Phone Number',
                    fullName: `${action.payload.data.name}`,
                    accountName: `${action.payload.data.name}`,
                    phoneNumber: action.payload.data.phone,
                    };
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.activeExtractions = [newObj,...state.activeExtractions]
  }).addCase(getPhoneDataAsync.rejected, (state,action) => {
    state.extractionStatus = PostDataStatus.FAILURE;
    state.isError = true;
    state.errorMessage = action.error.message;
  })









  .addCase(getAccountDataAsync.pending, (state) => {
    state.extractionStatus = PostDataStatus.FETCHING;
  })
  .addCase(getAccountDataAsync.fulfilled, (state,action) => {
    const newObj = {status:'FOUND',
                    verificationType:'Bank Account',
                    fullName: `${action.payload.data.name}`,
                    accountName: `${action.payload.data.name}`,
                    accountNumber: action.payload.data.account_number,
                    bankName: action.payload.data.bank_name,
                    };
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.activeExtractions = [newObj,...state.activeExtractions]
  }).addCase(getAccountDataAsync.rejected, (state,action) => {
    state.extractionStatus = PostDataStatus.FAILURE;
    state.isError = true;
    state.errorMessage = action.error.message;
  })



  .addCase(getBankList.pending, (state) => {
    state.bankNamesStatus = FetchDataStatus.FETCHING;
  })
  .addCase(getBankList.fulfilled, (state,action) => {
    state.bankNamesStatus = FetchDataStatus.SUCCESS;
    state.bankNames = action.payload;
  }).addCase(getBankList.rejected, (state,action) => {
    state.bankNamesStatus = FetchDataStatus.FAILURE;
    state.isError = true;
    state.errorMessage = action.error.message;
  })







  .addCase(getKenyaStatementKycHistory.pending, (state) =>{
    state.historyStatus = FetchDataStatus.FETCHING;
  })
  .addCase(getKenyaStatementKycHistory.fulfilled, (state,action)=>{
    
    state.historyExtractions = [...action.payload]
    state.historyStatus = FetchDataStatus.SUCCESS;
  })

  .addCase(getKenyaStatementKycHistory.rejected, (state,action)=>{
    state.historyStatus = FetchDataStatus.FAILURE
    state.historyErrorMessage = action.error.message
  })
  

  
  }
})


export const { addToExtractedData,resetKenyaKycData} = KenyakycStatementSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;
export const selecKycExtractions = state => state.kenyaKycStatementData.activeExtractions;
export const kycEtxractionStatus = state => state.kenyaKycStatementData.extractionStatus
export const selectKenyaStatementData = state => state.kenyaKycStatementData
export const kycErrorState = state => state.kenyaKycStatementData.isError
export const kycErrorMsg = state => state.kenyaKycStatementData.errorMessage

export const {resetReport} = KenyakycStatementSlice.actions


export default KenyakycStatementSlice.reducer;


/**
 * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    let mainArray = action.payload
    let newArray = []
    const mimeType = 'data:image/jpeg;base64,'
    for(let i=0;i<mainArray.length;i++){
      const newObj = mainArray[i]
      let img;
      let signature
      if(action.payload[i]['base64Image']){
        img = mimeType + action.payload[i]['base64Image']
      }
      
      if(action.payload[i]['photo']){
        img = action.payload[i]['photo']
      }
      if(action.payload[i]['signature']){
        signature = action.payload[i]['signature']
      }
      else{
        img = null;
      }
      
      const mainObj = {}
      delete newObj.base64Image;
      delete newObj.photo;
      delete newObj.signature;
      mainObj.data = newObj
      mainObj.kycType = action.payload[i]['verificationType']
      mainObj.status = 'FOUND'
      mainObj.img = img
      mainObj.signature = signature
      newArray.push(mainObj)
    }

    state.activeExtractions = [...newArray]
    state.historyStatus = PostDataStatus.SUCCESS;
  })
 */