import React,{useState,useEffect} from 'react'

function EditAndDeleteTenant({setIsDeleteOpen,setOpenEditModal}) {

  return (
    <section style={{backgroundColor:"white",
                      width:"150px",
                      borderRadius:"6px",
                      height:"80px",
                      position:'absolute',
                      bottom:'20px',
                      left:"30px",
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      display:'flex',
                      alignItems:'flex-start',
                      paddingLeft:'10px',
                      justifyContent:"center",
                      flexDirection:'column',
                      rowGap:'10px'}}>
        <span onClick={()=>setOpenEditModal(true)} style={{fontWeight:"600",
                        fontSize:'16px',
                        cursor:'pointer'}}>
            Edit
        </span>
        <span onClick={()=>{setIsDeleteOpen(true)}} style={{fontWeight:"600",
                        fontSize:'16px',
                        color:'red',
                        cursor:'pointer'}}>
            Delete
        </span>
    </section>
    
  )
}

export default EditAndDeleteTenant