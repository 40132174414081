import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

export const getAllCustomCreditScore = createAsyncThunk('customCreditScore', async (params) => {
  const customCreditScore = await client.get(`/creditscore/breakdown/${params.statementKey}`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customCreditScore.data;
})

export const getCustomScoreCardDefinitions = createAsyncThunk('creditscore/scorecard-definitions', async (params) => {
  const customCreditScore = await client.get(`/creditscore/scorecard-definitions`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customCreditScore.data;
})

export const updateCustomCreditScoreAsync = createAsyncThunk('creditscore/v2', async (params) => {
  let data = {
    statementKey: params.statementKey,
    scorecardDefinitionName: params.scorecardDefinitionName,
    CustomScorecardFields: params.CustomScorecardFields
  }
  data = JSON.stringify(data)
  const customCreditScore = await client.post(`/creditscore/v2`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
  if(!customCreditScore?.data){
    return customCreditScore
  }
  else{
    return customCreditScore.data;
  }
})

export const customCreditScoreSlice = createSlice({
  name: 'customCreditScore',
  initialState: {
    creditScores: { },
    creditScoresStatus: FetchDataStatus.INITIAL,
    customScoreCards: [],
    customCreditScoreStatus: FetchDataStatus.INITIAL,
    updateCustomCreditScoreStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetAllCustomCreditScore: state => {
      state.creditScores = { };
      state.creditScoresStatus = FetchDataStatus.INITIAL;
      state.customScoreCards = [];
      state.customCreditScoreStatus = FetchDataStatus.INITIAL;
      state.updateCustomCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomCreditScore: state => {
      state.customScoreCards = [];
      state.customCreditScoreStatus = FetchDataStatus.INITIAL;
      state.updateCustomCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetUpdateCreditScoreStatus: state => {
      state.updateCustomCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getAllCustomCreditScore.pending, (state) => {
      state.creditScoresStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllCustomCreditScore.fulfilled, (state, action) => {      
      state.creditScoresStatus = FetchDataStatus.SUCCESS;
      state.creditScores = action.payload;
    })
    .addCase(getAllCustomCreditScore.rejected, (state, action) => {
      state.creditScoresStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getCustomScoreCardDefinitions.pending, (state) => {
      state.customCreditScoreStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getCustomScoreCardDefinitions.fulfilled, (state, action) => {      
      state.customCreditScoreStatus = FetchDataStatus.SUCCESS;
      state.customScoreCards = action.payload;
    })
    .addCase(getCustomScoreCardDefinitions.rejected, (state, action) => {
      state.customCreditScoreStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateCustomCreditScoreAsync.pending, (state) => {
      state.updateCustomCreditScoreStatus = PostDataStatus.FETCHING;
    })
    .addCase(updateCustomCreditScoreAsync.fulfilled, (state) => {      
      state.updateCustomCreditScoreStatus = PostDataStatus.SUCCESS;
    })
    .addCase(updateCustomCreditScoreAsync.rejected, (state, action) => {
      state.updateCustomCreditScoreStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetCustomCreditScore, resetUpdateCreditScoreStatus, resetAllCustomCreditScore } = customCreditScoreSlice.actions;

export const selectCustomCreditScoreState = state => state.customCreditScore;

export default customCreditScoreSlice.reducer;