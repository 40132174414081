import React, {useState,useEffect} from 'react'
import logoHold from '../../../../assests/icons/amlIcon.svg'
import './Aml.css'
import IndividualScreening from './AmlComponents/IndividualScreening'
import BusinessScreening from './AmlComponents/BusinessScreening'
import AmlInfoExtractionModal from '../../../../components/Modals/AmlModals/AmlInfoExtractionModal'
import AmlBusinessModal from '../../../../components/Modals/AmlModals/AmlBusinessModal'
import Modal from "react-modal";
import AmlExtractionHistory 
  from '../../../IdVerification/VerificationContent/AmlContent/AmlComponents/AmlHistory/AmlExtractionHistory'
import { resetAmlHistoryRecord } from '../../../../redux/Slices/IdVerification/Kenya/AmlHistory'
import { useDispatch } from 'react-redux'
import { resetBusinessAmlCall,resetIndividualAmlCall } 
  from '../../../../redux/Slices/IdVerification/Kenya/IdVerificationKenyaAml'


function AmlContentSection(props) {
    const accessToken = props.accessToken
    const dispatch = useDispatch()
    const setContentToShow = props.setContentToShow
    const [slideState,setSlideState] = useState('')
    const [screenPage,setScreenPage] = useState('individual')
    const [amlModalOpen,setAmlModalOpen] = useState(false)
    const [amlBusinessModalOpen,setAmlBusinessModalOpen] = useState(false)
    const [showLowerSect,setShowLowerSect] = useState('proceed')
    const [slide,setSlide] = useState('screen')

    useEffect(()=>{
      dispatch(resetAmlHistoryRecord())
      dispatch(resetBusinessAmlCall())
      dispatch(resetIndividualAmlCall())
    },[dispatch])

    const closeAmlModal = ()=>{
      setAmlModalOpen(false)
    }

    const closeAmlBusinessModal = ()=>{
      setAmlBusinessModalOpen(false)
    }


    const cardHolder = [{id:0,title:'AML Individual Screening',
                      info:'This check is for Individual Screening'},
                      {id:1,title:'AML Business Screening',
                      info:'This check is for Business Screening'}]
                      const customStyles = {
                        content: {
                            transition: "0.125s ease-in-out",
                            transform: "scale(1)",
                            height: "40%",
                            width: "50%",
                            top: "33%",
                            left: "33%",
                            padding: "0 0 0 0",
                            overflowY: "scroll"
                        },
                        overlay: {
                            zIndex: 1000,
                            position: "absolute"
                        }
                        };
                  
                    const inputStyle = {
                      width: "100%",
                      marginTop: "10px",
                      border: 'none',
                      padding: '0px 24px 0px 24px',
                      boxSizing: "border-box",
                      backgroundColor: 'rgba(239, 244, 255, 1)',
                      outline: "none",
                      height: '40px'
                    };
                    

    return (
        <div>
            <section>
                <p style={{fontSize:'24px',color:"rgba(0, 0, 0, 1)",fontWeight:"700"}}>
                    AML Screening
                </p>
                <p style={{fontSize:'16px',color:"rgba(106, 113, 125, 1)"}}>
                    Tap any of the screening below to proceed with check
                </p>
            </section>
          
            <div style={{display:"flex",justifyContent:'space-between',columnGap:'10px',alignItems:'center',marginTop:"30px"}}>{cardHolder.map(card=>
                    <div onClick={()=>{card.id===0?setScreenPage('individual'):setScreenPage('business')}} style={{display:"flex",margin:'10px 0px',cursor:"pointer",width:"45%",borderRadius:'4px',height:'160px',flexDirection:'column',justifyContent:"space-around",padding:"10px",boxSizing:'border-box',backgroundColor:"rgba(255, 255, 255, 1)"}} key={card.id}>
                        <p><img src={logoHold}/></p>
                        <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                        <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                    </div>)}
            </div>

            <div style={{
                        marginTop:'25px',
                        display:"flex",
                        justifyContent:"start",
                        columnGap:"10px"}}>
              <sapn style={{color:slide==='history'?'#1162DC':'#C4C4C4',fontWeight:"700",cursor:"pointer"}} 
                  onClick={()=>setSlide('history')}>
                  AML History
              </sapn>
              <span>/</span>
              <span style={{color:slide==='screen'?'#1162DC':'#C4C4C4',fontWeight:"700",cursor:"pointer"}} 
                  onClick={()=>setSlide('screen')}>
                  AML Screening
              </span>
            </div>

            
            {slide==='history'?
              <AmlExtractionHistory 
                  accessToken={accessToken}
                  setContentToShow={setContentToShow}
              />:
                (screenPage==='individual'?
                    <IndividualScreening slideState={slideState} 
                                        setAmlModalOpen={setAmlModalOpen} 
                                        setSlideState={setSlideState} 
                                        accessToken={accessToken}/>
                  
                    :<BusinessScreening slideState={slideState}  
                                        setAmlBusinessModalOpen={setAmlBusinessModalOpen} 
                                        screenPage={screenPage} 
                                        accessToken={accessToken} 
                                        setSlideState={setSlideState}/>
                )
            }

            <Modal isOpen={amlModalOpen} 
                   onRequestClose={closeAmlModal} 
                   style={customStyles}
                   contentLabel="Extract Aml Data" >
                  <AmlInfoExtractionModal closeModal={closeAmlModal} 
                                          setContentToShow={setContentToShow} 
                                          setShowLowerSect={setShowLowerSect}/>
            </Modal>

            <Modal isOpen={amlBusinessModalOpen} 
                   onRequestClose={closeAmlBusinessModal} 
                   style={customStyles}
                   contentLabel="Extract Aml Data" >
                  <AmlBusinessModal closeModal={closeAmlBusinessModal} 
                                    setContentToShow={setContentToShow} 
                                    setShowLowerSect={setShowLowerSect}/>
            </Modal>
                
        </div>
    )
    }

    export default AmlContentSection