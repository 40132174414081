import { createSlice } from "@reduxjs/toolkit";

import { createRulesAsync, deleteRulesAsync, updateRulesAsync } from "./thunks";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const createRulesSlice = createSlice({
  name: "createRules",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createRulesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createRulesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(createRulesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export const { reset } = createRulesSlice.actions;

export const updateRulesSlice = createSlice({
  name: "updateRules",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateRulesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRulesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(updateRulesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export const { reset: resetUpdate } = updateRulesSlice.actions;

export const deleteRulesSlice = createSlice({
  name: "deleteRules",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(deleteRulesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRulesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(deleteRulesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});
