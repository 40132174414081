import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const fetchUserAddressAsync = createAsyncThunk(
  "fraudDetect/fetchUserAddressAsync",
  async (params) => {
    // const queryString = new URLSearchParams(params).toString();
    const response = await clientApi.get(
      `/api/addresses/user/${params.userId}`
    );

    return response;
  }
);
