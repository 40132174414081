import React,{useState} from 'react'
import { getVerifyLoanParameter,selectLoanFileState,uploadVerificationForm,resetVerificationUpload } from '../../../../redux/Slices/LoanFile'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import GuarantorBusinessSection from './verifcationSections/GuarantorBusiness';
import ClientHomeVerification from './verifcationSections/ClientHomeVerification';
import OwnerAssetVerification from './verifcationSections/OwnerAssetVerification';
import { FetchDataStatus,PostDataStatus } from '../../../../library/Variables';
import Spinner from '../../../../svg/Spinner/Spinner';
import '../../LoanForms/loanForm.css'
import { useParams } from 'react-router';
import SignatureUploadModal from '../../../../components/Modals/LoanModals/SignatureUploadModal'
import VerificationCard from './VerificationCard';
import { getLoanVerifiedData } from '../../../../redux/Slices/LoanFile';
import UploadVerificationModal from '../../../../components/Modals/LoanModals/uploadVerificationModal';
import Modal from 'react-modal'
import Utils from '../../../../library/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewSignatureModal from '../../../../components/Modals/LoanModals/ViewSignatureModal';
import { getSignature } from '../../../../redux/Slices/LoanSignatureUpload';

function FormVerification(props) {
  const dispatch = useDispatch()
  const [imgFile,setImgFile] = useState(null)
  const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
  const headers = ['Name of Item Found','Category','Market Value']
  const [fixedClientFields,setFixedClientFields] = useState([{name:'',category:'',marketValue:''}])
  const [clientSignaturePath,setclientSignaturePath] = useState(null)
  const [loader,setLoader] = useState(false)
  const showForm= props.showForm 
  const accessToken = props.accessToken
  const [modalIsOpen, setIsOpen] = useState(false);
  const [signatureModalIsOpen, setSignatureModal] = useState(false);
  const [lastThreeChars,setLastThreeChars] = useState()
  const verifiedStatus = props.verifiedStatus
  const newlyVerified = props.newlyVerified
  const setNewlyVerified = props.setNewlyVerified
  const configuration= props.configuration

  
  const { key } = useParams();
  const formState = useSelector(selectLoanFileState)
  let formContent
  let GUARANTOR_BUSINESS_LOCATION = []
  let CLIENT_HOME_VERIFICATION = []
  let HOME_OR_BUSINESS_ASSETS_VERIFICATION = []

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const supportCustomStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: 'auto',
      width: 'auto',
      
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };
  const onSubmit = (data) => {
    // Handle the form data
    
  };

  function openModal() {
    setIsOpen(true);
  }

  function openSignatureModal() {
    setSignatureModal(true);
  }
  function afterOpenModal() { }

  function closeUploadVerificationModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  function closeModal() {
    setSignatureModal(false);
    //dispatch(resetDownload());
  }

  const signaturePreview = ()=>{

    if(!Utils.isFieldEmpty(formState.verificationUploadData)){
      const sigPath = formState.verificationUploadData.data.verificationSignaturePath
      const signatureAddress =  {path:sigPath,type:'signature'}
      let threeChars = sigPath.slice(-3);
      setLastThreeChars(threeChars)
      dispatch(getSignature({accessToken,data:signatureAddress}));
      setSignatureModal(true)
    }

    else if(!Utils.isFieldEmpty(formState.loanVerifiedData)){
        const sigPath = formState.loanVerifiedData.data.verificationSignaturePath
        const signatureAddress =  {path:sigPath,type:'signature'}
        let threeChars = sigPath.slice(-3);
        setLastThreeChars(threeChars)
        dispatch(getSignature({accessToken,data:signatureAddress}));
        setSignatureModal(true)
    }
    
    
}

  const fillFormObj = async()=>{

    //const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>{customFieldAArray.push({fieldname:key,fieldvalue:value})})
    const isValid = await trigger(); 

    if (isValid && !Utils.isFieldEmpty(fixedClientFields[0].category) && 
        !Utils.isFieldEmpty(fixedClientFields[0].marketValue) && 
        !Utils.isFieldEmpty(fixedClientFields[0].name)) {

        const allFields = getValues()

        if(Utils.isFieldEmpty(imgFile)){
          toast.warning("upload signature", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
    
        else{

          const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>({fieldname:key,fieldvalue:value}))
          const customFieldObj = {
            loanApplicationKey:parseInt(key),
            verificationSignaturePath:clientSignaturePath,
            loanAssetsVerificationData:fixedClientFields,
            customLoanFields: [...arrayOfObjects]
          }
        
            
          dispatch(uploadVerificationForm({data:customFieldObj,accessToken}))
          setIsOpen(true)
        }
    
  
  }

  else{
    toast.error("ensure all neccessary fields are filled", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
    
  }

if(newlyVerified){
  
  if(formState.verificationUploadFormStatus === PostDataStatus.SUCCESS && formState.verificationUploadData!==null){
    const homeBusinessData = formState.verificationUploadData.data.customParameters
    formContent = <div style={{width:'80vw'}}>
    <VerificationCard data={formState.verificationUploadData.data.customParameters} filterValue='GUARANTOR_BUSINESS_LOCATION'/>
    <VerificationCard data={formState.verificationUploadData.data.customParameters} filterValue='CLIENT_HOME_VERIFICATION'/>

    <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
        <p style={{margin:'15px 0px',fontSize:'20px',fontWeight:'700',color:'rgba(42, 56, 164, 1)'}}>Home/Business Owner Asset Verification</p>

        <section style={{display:"flex",justifyContent:'space-between',alignItems:'center',margin:'20px 0px'}}>
          {headers.map(head=><span style={{color:'rgba(0, 0, 0, 1)',flex:1,fontSize:'18px',fontWeight:'500'}}>{head}</span>)}
        </section>
        <div>
            {formState.verificationUploadData.data.loanAssetsVerificationData.map(
              (item,index)=>
              <section key={index} style={{display:"flex",justifyContent:'space-between',alignItems:'center',margin:'20px 0px'}}>
                <span style={{flex:'1'}}>{item.name}</span>
                <span style={{flex:'1'}}>{item.category}</span>
                <span style={{flex:'1'}}>{item.marketValue?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.marketValue)
                :'---'}</span>
              </section>
            )}
            
        </div>
    </div>

    <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
        <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Fixed Asset Value</p>
        <span style={{color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'400'}}>{
          homeBusinessData[8]?.value?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, homeBusinessData[8]?.value)
              :'---'
        }</span>
            
    </div>

    <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
        <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'16px',fontWeight:'400'}}>Person At Home</p>
        {homeBusinessData.slice(9,homeBusinessData.length).map((data,index)=>
          <div key={index} style={{display:"flex",margin:'15px 0px',justifyContent:'space-between'}}>
              <span style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>{data.friendlyName}</span>
              <span style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>{data.value}</span>
          </div>
        )}
      
            
    </div>
    
    <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",
                    alignItems:'center',backgroundColor:'white',borderRadius:"10px",padding:"10px",
                    boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
        <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Agent Signature</p>
        <span onClick={()=>signaturePreview()} className='imgView'>view</span>
             
    </div>
  </div>
  }
}


  if(verifiedStatus && !newlyVerified){

    if(formState.loanVerifiedDataStatus === FetchDataStatus.INITIAL){
    
      formContent = <Spinner/>
      dispatch(getLoanVerifiedData({accessToken,key}))
    }

    if(formState.loanVerifiedDataStatus === FetchDataStatus.FETCHING){
   
      formContent = <Spinner/>
    }

    if(formState.loanVerifiedDataStatus === FetchDataStatus.SUCCESS){
      const homeBusinessData = formState.loanVerifiedData.data.customParameters
      formContent = <div style={{width:'100%'}}>
                        <VerificationCard data={formState.loanVerifiedData.data.customParameters} filterValue='GUARANTOR_BUSINESS_LOCATION'/>
                        <VerificationCard data={formState.loanVerifiedData.data.customParameters} filterValue='CLIENT_HOME_VERIFICATION'/>
                        <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
                            <p style={{margin:'15px 0px',fontSize:'20px',fontWeight:'700',color:'rgba(42, 56, 164, 1)'}}>Home/Business Owner Asset Verification</p>

                            <section style={{display:"flex",justifyContent:'space-between',alignItems:'center',margin:'20px 0px'}}>
                              {headers.map((head,index)=><span key={index} style={{color:'rgba(0, 0, 0, 1)',flex:1,fontSize:'18px',fontWeight:'500'}}>{head}</span>)}
                            </section>
                            <div>
                                {formState.loanVerifiedData.data.loanAssetsVerificationData.map(
                                  (item,index)=>
                                  <section key={index} style={{display:"flex",justifyContent:'space-between',alignItems:'center',margin:'20px 0px'}}>
                                    <span style={{flex:'1'}}>{item.name}</span>
                                    <span style={{flex:'1'}}>{item.category}</span>
                                    <span style={{flex:'1'}}>{item.marketValue?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.marketValue)
                                    :'---'}</span>
                                  </section>
                                )}
                                
                            </div>
                        </div>

                        <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
                          <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Fixed Asset Value</p>
                          <span style={{color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'400'}}>{
                            homeBusinessData[8]?.value?Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, homeBusinessData[8]?.value)
                                :'---'
                                }</span>        
                        </div>

                        <div style={{border:'1px solid rgba(217, 229, 255, 1)',backgroundColor:'white',borderRadius:"10px",padding:"10px",boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
                          <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'16px',fontWeight:'400'}}>Person At Home</p>
                          {homeBusinessData.slice(9,homeBusinessData.length).map((data,index)=>
                            <div key={index} style={{display:"flex",margin:"15px 0px",justifyContent:'space-between'}}>
                                <span style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>{data.friendlyName}</span>
                                <span style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>{data.value}</span>
                            </div>
                          )}
                        
                              
                        </div>

                        <div style={{border:'1px solid rgba(217, 229, 255, 1)',display:'flex',justifyContent:"space-between",
                                    alignItems:'center',backgroundColor:'white',borderRadius:"10px",padding:"10px",
                                    boxSizing:'border-box',width:'100%',margin:'25px auto'}}>
                            <p style={{color:'rgba(55, 58, 63, 1)',fontSize:'14px',fontWeight:'400'}}>Agent Signature</p>
                            <span onClick={()=>signaturePreview()} className='sigImgView'>view</span>           
                        </div>

                    </div>
    }
    
  }

  if(!verifiedStatus && !newlyVerified){
    if(formState.loanVerificationParamStatus === FetchDataStatus.INITIAL){
      formContent = <Spinner/>
      dispatch(getVerifyLoanParameter({accessToken}))
    }
  
    if(formState.loanVerificationParamStatus===FetchDataStatus.FETCHING){
      formContent = <Spinner/>
    }
  
    if(formState.loanVerificationParamStatus===FetchDataStatus.SUCCESS){
      formState.verificationParameters.filter(item=>
       {
        if(item.sectionKey===6){
          GUARANTOR_BUSINESS_LOCATION.push(item)
        }
        else if(item.sectionKey===7){
          CLIENT_HOME_VERIFICATION.push(item)
        }
        else if(item.sectionKey===8){
          HOME_OR_BUSINESS_ASSETS_VERIFICATION.push(item)
        }
  
       }
        )
  
  
      formContent = 
      <form onSubmit={handleSubmit(onSubmit)} style={{display:'flex',flexDirection:'column',backgroundColor:'transparent'}}>
            <>
              
              <div style={{width:'100%',display:'flex',flexDirection:'column',gap:'2em',alignItems:"center"}}>
              
                  <GuarantorBusinessSection register={register} erros={errors} info={GUARANTOR_BUSINESS_LOCATION}/>
                  <ClientHomeVerification register={register} erros={errors} info={CLIENT_HOME_VERIFICATION}/>
                  <OwnerAssetVerification imgFile={imgFile} setValue={setValue} setImgFile={setImgFile} paramKey={key} 
                                          clientSignaturePath={clientSignaturePath} showForm={props.showForm} 
                                          setShowForm={props.setShowForm} setclientSignaturePath={setclientSignaturePath} 
                                          setFixedClientFields={setFixedClientFields} accessToken={accessToken} 
                                          fixedClientFields={fixedClientFields} register={register} erros={errors} 
                                          info={HOME_OR_BUSINESS_ASSETS_VERIFICATION}/>
  
                  <div onClick={()=>fillFormObj()} className='buttonHold'>
                    <section >Submit</section>
                  </div>
              </div>
           
            </>
      </form>
    }
  
  }




  
 /**
  *  if(loanDataState.signatureStatus===PostDataStatus.SUCCESS && loanDataState.signatureStatus!==PostDataStatus.SUCCESS){
    //console.log(loanDataState.signature)
    setclientSignaturePath(loanDataState.signature.uuid)
    dispatch(resetSignature())
  }
  */
  return (
    <div className="sign-up-details-form-main">
    <div style={{width:'100%',height:'100%',display:"flex",margin:'30px 0px'}}>
      {formContent}
    </div>
    <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeUploadVerificationModal} style={customStyles}
          contentLabel="Add Signature" >
          <UploadVerificationModal setNewlyVerified={setNewlyVerified} accessToken={accessToken}  closeUploadVerificationModal={closeUploadVerificationModal} />
      </Modal> 

      <Modal isOpen={signatureModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={supportCustomStyles}
          contentLabel="Add Signature" >
          <ViewSignatureModal lastThreeChars={lastThreeChars}  accessToken={accessToken} closeModal={closeModal}/>
      </Modal>
    </div>
  )
}

export default FormVerification