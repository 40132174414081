import React from 'react'

function OptionInputComponent({index,setOptionLength,optionLength}) {


      const updateItemValue = (event,index) => {
        setOptionLength((prevItems) =>
          prevItems.map((item, i) =>
            i === index ? { ...item, value: event.target.value } : item
          )
        );
      };

      
  return (
    <input  className='inputFields' 
            placeholder='input option' 
            autocomplete="off" 
            type='text'
            onChange={(event)=>updateItemValue(event,index)}
            value= {optionLength[index]?.value || ''} 
                                    
                                    
                            />
  )
}

export default OptionInputComponent