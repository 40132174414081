import React, { useEffect } from 'react'
import Utils from '../../../../../library/Utils'
import { useForm } from "react-hook-form"
import { useDispatch } from 'react-redux'
//import { getAmlDetails,getAmlBusinessDetails} from '../../../../../redux/Slices/IdVerificationKenyaKyb'
//import { getAmlDetails,getAmlBusinessDetails } from '../../../../../redux/Slices/IdVerificationKenyaKyb'
import { getAmlBusinessDetails } from '../../../../../redux/Slices/IdVerification/Kenya/IdVerificationKenyaAml'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function BusinessScreening({slideState,setSlideState,setAmlBusinessModalOpen,accessToken}) {
  const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm()
    const dispatch = useDispatch()

  const fetchDat = ()=>{
    let dat = getValues()
    if(Utils.isFieldEmpty(dat.entity_name)){
        toast.warning("Fill all neccessary Fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      else{
          setAmlBusinessModalOpen(true) 
          dispatch(getAmlBusinessDetails({data:dat,accessToken}))
      }
  }


  return (
        <section>

        <div className='amlScreenHold'>
                <section className='amlScreenTitle'>AML Business Screening</section>
                <section className='amlScreenDescript'>Fill up the input fields with the appropriate information</section>

                <div style={{marginTop:'15px'}}>
                    
                    
                    <section style={{display:'flex',flexDirection:"column",rowGap:'6px'}}> 
                        <label>Business Name <span className="required">*</span></label>
                        <section className='businessInputHold'>
                            <input className="input-pill-single" placeholder='input business name' autoComplete="off" type='TEXT' 
                            {...register('entity_name', 
                                { 
                                required:{
                                    value:true,
                                    message:'Business Name is required'}, 
                                
                                })}/>
                        </section>
                        <span style={{ color: "red"}}>{errors?.businessname?.message}</span>
                    </section>
                    
                
                    <section style={{display:'flex',flexDirection:"column",rowGap:'6px'}}>
                        <label>Match score</label>
                        <section className='ScreenSlidingInput'>
                            <span>0%</span>
                            <input type='range'onChange={(event)=>setSlideState(event.target.value)} min="0" max="100"/>
                            <span>{Utils.isFieldEmpty(slideState)?'50%':`${slideState}%`}</span>
                        </section>
                        
                    </section>
                </div>
            </div>

            <section style={{width:'100%',display:"flex",justifyContent:"end",marginTop:'30px'}}>
                <section onClick={()=>fetchDat()}  className='proceedScreen'>Proceed</section>
            </section>
        </section>
  )
}

export default BusinessScreening

//<section onClick={()=>fetchDat()} className='proceedScreen'>Proceed</section>