import React,{useState} from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { uploadSignature,resetSignature,selectSignatureState,deleteImage,resetDeleteImg} from '../../../redux/Slices/LoanSignatureUpload';
import Utils from '../../../library/Utils';


function PreviewDeleteModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectSignatureState)
    const accessToken = props.accessToken
    const imgUrl = props.imgUrl
    const hitDelete = props.hitDelete
    const imgDetails = props.previewObj
    const selectedImgList = props.selectedImgList
    const setSelectedImgList = props.setSelectedImgList
    const closeModal = props.closeModal

    let content = null;
  

    const removeFile = ()=>{
        let imageList = [...selectedImgList]
        imageList.splice(imgDetails.index,1)
        setSelectedImgList(imageList)
        dispatch(deleteImage({accessToken,data:{"filePath":imgDetails.path}}))
            //setShowName(false);    
    }

    const cancelPreview = ()=>{
        resetDeleteImg()
        closeModal()
    }


    if(loanState.deleteImageStatus===PostDataStatus.INITIAL){
        content =<div style={{width:'100%',display:"flex",flexDirection:'column',alignItems:"center",justifyContent:"center"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>{hitDelete?'Proceed with Delete?':'Signature Display'}</p>
            
          <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            
            <p style={{color:"rgba(64, 123, 255, 1)",fontSize:'24px',fontWeight:"500",textAlign:"center"}}>{` "${imgDetails.name}"`}</p>
            <span>
                {imgDetails.name.slice(imgDetails.name.length-3,imgDetails.name.length)==='pdf'?
                    <iframe src={imgDetails.index} style={{width:'auto',maxWidth:'40vh',height:"auto"}}></iframe>: 
                    <img style={{width:"auto",maxWidth:'40vh'}} src={imgDetails.imgUrl}/>}
            </span>
            <div className='buttonHold'>
                  <section className='back' onClick={()=>cancelPreview()}>Cancel</section>
                  
            </div>

            
            
        </div>
        
      
            {hitDelete?<div className='buttonHold'>
                <section onClick={()=>removeFile()}>Delete</section>
            </div>:''}
        </div> 
    }

    if(loanState.deleteImageStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Deleting...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.deleteImageStatus===PostDataStatus.SUCCESS){
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Deleted</p>
            <div className='buttonHold'>   
                <section onClick={()=>{closeModal();dispatch(resetDeleteImg())}}>Done!</section>
            </div> 
        </div>
    }

    if(loanState.deleteImageStatus===PostDataStatus.FAILURE){
        content = 
      <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanState.errordeleteImage}</p>

          <div className='buttonHold'>   
                <section onClick={()=>{closeModal();dispatch(resetDeleteImg())}}>Close</section>
            </div>
      </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default PreviewDeleteModal