import React from "react";
import PermissionRow from "./PermissionRow";

function PermissionGroup(props) {
  const rows = props.group.rows.map((row, i) => {
    return <PermissionRow row={row} key={i} isDisabled={props.isDisabled} />
  })

  return <div className="permission-group">
    <h3>{props.title}</h3>
    {rows}
  </div>
}

export default PermissionGroup;