import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import Form from "../../components/Form/Form";
import AddPdf from "../../components/Modals/AddPdf";
import {
  DateRangeColumnFilter,
  SelectColumnFilter,
  Table,
} from "../../components/Table/Table";
import Utils from "../../library/Utils";
import { FetchDataStatus, StatementStatus } from "../../library/Variables";
import { reset } from "../../redux/Slices/AddPdf";
import { resetAffordability } from "../../redux/Slices/Affordability";
import { resetAll } from "../../redux/Slices/Bureau";
import {
  resetConsolidatedActiveStatement,
  selectConsolidatedStatementDetailsState,
} from "../../redux/Slices/ConsolidatedStatement";
import { resetCreditScore } from "../../redux/Slices/CreditScore";
import { resetAllCustomCreditScore } from "../../redux/Slices/CustomCreditScore";
import {
  getAllStatements,
  getStatementCount,
  selectMainState,
} from "../../redux/Slices/Main";
import {
  resetAllMobileInsights,
  selectMobileInsightState,
} from "../../redux/Slices/MobileInsight";
import {
  resetActiveStatement,
  selectStatementDetailsState,
} from "../../redux/Slices/StatementDetails";
import {
  getTransactionCount,
  resetTransactions,
  selectTransactionState,
} from "../../redux/Slices/Transactions";
import {
  getBalance,
  resetWalletExceptBalance,
  selectWalletState,
} from "../../redux/Slices/Wallet";
import Spinner from "../../svg/Spinner/Spinner";
import { getAllTenants,selectMutilTenantSlice } from "../../redux/Slices/Multinenant/MultiTenancyFile";
import "./Home.css";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

function Home(props) {
  localStorage.setItem("insightsLastVisited", "/home");

  let totalTransactionCount = 0;
  let totalStatementCount = 0;
  let currentBalance = 0;
  let subtitle;
  let tableData = [];
  let content = <Spinner />;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = props.user;
  const subscriptionState = props.subscriptionState;
  const accessToken = props.accessToken;
  const main = useSelector(selectMainState);
  const transactions = useSelector(selectTransactionState);
  const wallet = useSelector(selectWalletState);
  const statementDetailsState = useSelector(selectStatementDetailsState);
  const consolidatedState = useSelector(
    selectConsolidatedStatementDetailsState
  );
  const mobileinsight = useSelector(selectMobileInsightState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const configuration = props.configuration;
  const [isActiveSearch, setIsActiveSearch] = useState(false);






  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "33%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll",
    },
    overlay: {
      zIndex: 1000,
      position: "absolute",
    },
  };

  const searchFormFields = [
    {
      type: "text",
      id: "st-name-search",
      placeholder: "Name",
      param: "name",
      className: "form-default-container name",
    },
    {
      type: "DATE_RANGE",
      params: { first: "startDate", second: "endDate" },
      header: { value: "Insert date range", label: "Created Date Range" },
      className: "form-date-range-container",
    },
    {
      type: "SELECT",
      hiddenOptionLabel: "Status",
      options: [
        { name: "UPLOADED", value: "Uploaded", label: "Uploaded" },
        { name: "PROCESSED", value: "Processed", label: "Processed" },
        { name: "FAILED", value: "Failed", label: "Failed" },
        { name: "ALTERED", value: "Altered", label: "Altered" },
        {
          name: "NOTENOUGHTRANSACTIONS",
          value: "Not Enough Transactions",
          label: "Not Enough Transactions",
        },
        {
          name: "LESSTHAN6MONTHS",
          value: "Less Than 6 Months",
          label: "Less Than 6 Months",
        },
      ],
      id: "st-status-search",
      param: "status",
      className: "form-select-container status",
    },
    {
      type: "SELECT",
      hiddenOptionLabel: "Type",
      options: [
        { name: "MOBILE", value: "Mobile", label: "Mobile" },
        { name: "STATEMENT", value: "Statement", label: "Statement" },
        { name: "CONSOLIDATED", value: "Consolidated", label: "Consolidated" },
      ],
      id: "st-type-search",
      param: "type",
      className: "form-select-container type",
    },
  ];

  const params = {
    accessToken: accessToken,
    startDate: "",
    endDate: "",
    name: "",
    status: "",
    type: "",
  };

  const theadersStatements = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Created Date",
      accessor: "createdDate",
      Filter: DateRangeColumnFilter,
      filter: "dateRange",
    },
    {
      Header: "Updated Time",
      accessor: "updatedDate",
      Filter: DateRangeColumnFilter,
      filter: "dateRange",
    },
    {
      Cell: (data) => {
        let statusDisplay = data.row.original.processingStatus;

        if (statusDisplay === StatementStatus.NOTENOUGHTRANSACTIONS) {
          statusDisplay = "NOT ENOUGH TRANSACTIONS";
        }

        if (statusDisplay === StatementStatus.LESSTHAN6MONTHS) {
          statusDisplay = "LESS THAN 6 MONTHS";
        }

        return (
          <span
            style={{
              color: Utils.formatTextColor(
                data.row.original.processingStatus,
                "#000",
                "#E40000",
                "#00770C",
                "#FB6D06",
                null
              ),
            }}
          >
            {statusDisplay}
          </span>
        );
      },
      Header: "Status",
      accessor: "processingStatus",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Type",
      accessor: "type",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: (data) => {
        let link =
          data.row.original.type === "MOBILE"
            ? `/mobileinsight/${data.row.original.key}`
            : data.row.original.type === "CONSOLIDATED" &&
              configuration.currency.symbol === "KES"
            ? `/consolidatedstatement/${data.row.original.key}`
            : data.row.original.type === "CONSOLIDATED" &&
              configuration.currency.symbol === "NGN"
            ? `/consolidatedstatementNg/${data.row.original.key}`
            : `/statement/${data.row.original.key}`;
        return (
          <div className="table-row-buttons-container">
            <Link
              to={link}
              state={{ key: data.row.original.key }}
              className="button-square table-row-buttons edit"
              onClick={() => handleFormReset()}
            >
              View
            </Link>
          </div>
        );
      },
    },
  ]);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    dispatch(reset());
    dispatch(getAllStatements(params));
    dispatch(getStatementCount({ accessToken: accessToken }));
    dispatch(getTransactionCount({ accessToken: accessToken }));
    dispatch(getBalance({ accessToken: accessToken }));
  }

  function handleParentCallback(activeFields) {
    setIsActiveSearch(true);
    dispatch(getAllStatements(activeFields));
  }

  function handleFormReset() {
    params.startDate = "";
    params.endDate = "";
    params.name = "";
    params.status = "";
    params.type = "";

    setIsActiveSearch(false);
    dispatch(getAllStatements(params));
  }

  if (main.statementStatus === FetchDataStatus.SUCCESS) {
    if (!Utils.isFieldEmpty(main.statements)) {
      main.statements.forEach((statement) => {
        tableData.push({
          key: statement.key,
          name: statement.name,
          type: !Utils.isFieldEmpty(statement.type) ? statement.type : "-",
          createdDate: statement.createdDate,
          updatedDate: !Utils.isFieldEmpty(statement.updatedDate)
            ? statement.updatedDate
            : "-",
          processingStatus: statement.processingStatus,
        });
      });

      content = (
        <Table
          tableClass="statement-type-table"
          pagination={true}
          pageSize={[10]}
          data={tableData}
          columns={theadersStatements}
          tableExtra={false}
          tableFilter={true}
        />
      );
    } else if (Utils.isFieldEmpty(main.statements) && isActiveSearch === true) {
      content = (
        <div className="cards dash-no-statement-data">
          <h2 className="detail-header">There are no statements to display</h2>
          <p className="text-medium">Your search did not yield any results</p>
        </div>
      );
    } else {
      content = (
        <div className="cards dash-no-statement-data">
          <h2 className="detail-header">There are no statements to display</h2>
          <p className="text-medium">
            Please click the upload button to add statements
          </p>
          <button className="button-solid button-square" onClick={openModal}>
            Upload
          </button>
        </div>
      );
    }
  }

  totalStatementCount = main.totalStatementCount.count;
  totalTransactionCount = transactions.totalTransactionCount.count;
  currentBalance = wallet.currentBalance;

  useEffect(() => {
    // Reset the active statement
    if (
      statementDetailsState &&
      statementDetailsState.statementDetailsStatus !== FetchDataStatus.INITIAL
    ) {
      dispatch(resetActiveStatement());
      dispatch(resetAffordability());
      dispatch(resetCreditScore());
      dispatch(resetAllCustomCreditScore());
      dispatch(resetTransactions());
      dispatch(resetAll());
    }

    if (
      consolidatedState &&
      consolidatedState.statementDetailsStatus !== FetchDataStatus.INITIAL
    ) {
      dispatch(resetConsolidatedActiveStatement());
    }
    if (
      mobileinsight &&
      mobileinsight.mobileInsightStatus !== FetchDataStatus.INITIAL
    ) {
      dispatch(resetAllMobileInsights());
    }

    if (
      wallet.debitsStatus !== FetchDataStatus.INITIAL ||
      wallet.creditsStatus !== FetchDataStatus.INITIAL
    ) {
      dispatch(resetWalletExceptBalance());
    }

    // Statements
    if (
      Utils.isFieldEmpty(main) ||
      main.statementStatus === FetchDataStatus.INITIAL
    ) {
      dispatch(getAllStatements(params));
    }

    if (
      Utils.isFieldEmpty(main) ||
      main.totalStatementCountStatus === FetchDataStatus.INITIAL
    ) {
      dispatch(getStatementCount(params));
    }

    // Transactions
    if (
      Utils.isFieldEmpty(transactions) ||
      transactions.totalTransactionCountStatus === FetchDataStatus.INITIAL
    ) {
      dispatch(getTransactionCount(params));
    }

    //Wallet
    if (
      Utils.isFieldEmpty(wallet) ||
      wallet.balanceStatus === FetchDataStatus.INITIAL
    ) {
      dispatch(getBalance(params));
    }

    if (
      main.statementStatus === FetchDataStatus.FAILURE ||
      main.totalStatementCountStatus === FetchDataStatus.FAILURE ||
      main.totalTransactionCountStatus === FetchDataStatus.FAILURE ||
      wallet.balanceStatus === FetchDataStatus.FAILURE
    ) {
      navigate("/error");
    }
  }, [
    main,
    wallet,
    transactions,
    statementDetailsState,
    consolidatedState,
    dispatch,
  ]);
  return (
    <div className="dash-body">
    
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Statement"
      >
        <AddPdf
          configuration={configuration}
          accessToken={accessToken}
          subtitle={subtitle}
          close={closeModal}
        />
      </Modal>
      <div className="top-cards-container">
        <DetailsCard
          cardID={"hover-detail-card"}
          clickableFunction={() => navigate("/wallet")}
          subscriptionState={subscriptionState.subscription}
          role={user.user.role}
          hoverTitle={"View Wallet"}
          subtitle={"Current Wallet Balance"}
          title={Utils.formatCurrency(
            configuration.currency.walletFormat,
            configuration.currency.walletSymbol,
            currentBalance
          )}
          visualContent={<img src="/assets/icons/wallet-color.svg" alt="" />}
        />
        {configuration.featureFlags.isPdfUploadAllowed === true &&
        user.user.permissions.uploadPdf === true ? (
          <DetailsCard
            cardID={"hover-detail-card"}
            clickableFunction={openModal}
            hoverTitle={"Add New"}
            subtitle={"Total Statements"}
            title={Utils.formatNumber(
              configuration.currency.format,
              totalStatementCount
            )}
            visualContent={
              <img src="/assets/icons/statement-color.svg" alt="" />
            }
          />
        ) : (
          <DetailsCard
            subtitle={"Total Statements"}
            title={Utils.formatNumber(
              configuration.currency.format,
              totalStatementCount
            )}
            visualContent={
              <img src="/assets/icons/statement-color.svg" alt="" />
            }
          />
        )}
        <DetailsCard
          title={Utils.formatNumber(
            configuration.currency.format,
            totalTransactionCount
          )}
          subtitle={"Total Transactions"}
          visualContent={
            <img src="/assets/icons/transactions-color.svg" alt="" />
          }
        />
      </div>

      {Utils.isFieldEmpty(tableData) &&
      isActiveSearch === false &&
      configuration.featureFlags.isPdfUploadAllowed === false ? null : (
        <div className="dash-statement-add-container">
          <h2 className="detail-header">Statements</h2>
          {user.user.permissions.uploadPdf === true && (
            <button
              className="button-solid-darker button-square"
              onClick={openModal}
            >
              Add New
            </button>
          )}
        </div>
      )}
      <div
        className="st-search-container"
        id={
          Utils.isFieldEmpty(tableData) && isActiveSearch === false
            ? "no-statements-seach"
            : null
        }
      >
        <Form
          header={"Search"}
          accessToken={accessToken}
          parentCallback={(activeFields) => handleParentCallback(activeFields)}
          searchFormFields={searchFormFields}
        />
      </div>
      {content}
    </div>
  );
}

export default Home;
