import React, { useMemo } from "react";
import Utils from "../../../library/Utils";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import List from "../../../components/List/List";
import { Table } from '../../../components/Table/Table';

function Behavioral(props) {
  const configuration = props.configuration;
  const mainCardStyle = props.mainCardStyle;
  const behavioral = props.data;


  let inflowPercent = Utils.checkNull(Utils.formatPercentage(behavioral.percentOfInflowIrregularity));
  let latestLoanTransaction = [];
  let latestRepaymentTransaction = [];
  let loanTransactionsTable = [];
  let loanRepaymentTransactionsTable = [];
  let loanRepaymentTransactionsContent = null;

  const theadersLoanRepaymentTransactions = useMemo(() => [
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      disableSortBy: true,
    }], []
  )

  /* Latest Transactions */
  if (!Utils.isFieldEmpty(behavioral.latestLoanTransaction)) {
    latestLoanTransaction = behavioral.latestLoanTransaction[0];
  }

  if (!Utils.isFieldEmpty(behavioral.latestRepaymentTransaction)) {
    latestRepaymentTransaction = behavioral.latestRepaymentTransaction[0];
  }

  /* Loan Repayment Transactions Flows */
  if (!Utils.isFieldEmpty(behavioral.loanTransactions)) {
    behavioral.loanTransactions.forEach(element => {
      loanTransactionsTable.push({
        description: element.description,
        date: Utils.truncTime(element.date),
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount))
      });
    });
  }

  if (!Utils.isFieldEmpty(behavioral.loanRepaymentTransactions)) {
    behavioral.loanRepaymentTransactions.forEach(element => {
      loanRepaymentTransactionsTable.push({
        description: element.description,
        date: Utils.truncTime(element.date),
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount))
      });
    });
  }

  if ((!Utils.isFieldEmpty(loanTransactionsTable)) || (!Utils.isFieldEmpty(loanRepaymentTransactionsTable))) {
    loanRepaymentTransactionsContent = <div className="st-details-card-container-row st-details-table-row" >
      <div className="st-details-scroll-card">
        <div className='cards details-table-container' style={mainCardStyle}>
          <h2 className="detail-header">Loan Transactions</h2>
          {!Utils.isFieldEmpty(loanTransactionsTable)
            ? <Table data={loanTransactionsTable} columns={theadersLoanRepaymentTransactions} tableFilter={false} tableExtra={true}
                rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
      <div className="st-details-scroll-card">      
        <div className='cards details-table-container' style={mainCardStyle}>
          <h2 className="detail-header">Loan Repayment Transactions</h2> 
          {!Utils.isFieldEmpty(loanRepaymentTransactionsTable)
            ? <Table data={loanRepaymentTransactionsTable} columns={theadersLoanRepaymentTransactions} tableFilter={false} tableExtra={true} 
                rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
    </div>
  }

  return (
    <div className="st-details-card-container behavior-cards">
      <div className="st-details-card-container-row st-details-behavior-top-row">
        <DetailsCard detailClassName={"simple-list-container"}
          detailContent={<>
            <List listClass={'st-details-list'} headerClass="detail-header" listContainerClass={'st-details-list-main-container'} 
              listContent={[
                {
                  header: Utils.checkNull(Utils.formatPercentage(behavioral.accountActivity)),
                  detail: 'Account Activity'
                },
                {
                  header: Utils.checkNull(behavioral.accountSweep).toUpperCase(),
                  detail: 'Account Sweep'
                }
              ]}
            />
            <hr/>
            <List listClass={'st-details-list'} headerClass="detail-header" listContainerClass={'st-details-list-main-container'} 
              listContent={[
              {
                header: Utils.checkNull(Utils.formatPercentage(behavioral.gamblingRate)),
                detail: 'Gambling Rate'
              },
              {
                header: Utils.checkNull(behavioral.numberOfBettingTransactions),
                detail: 'Betting Transactions'
              }
            ]} />
          </>
          }
        />
        <DetailsCard detailClassName={'st-details-behavior-detail-inflow-main-container'}
          detailContent={
          <>
            <div className='st-details-behavior-inflow-container'>
              <div className='dc-status-bar-container'>
                <div className='dc-status-bar' style={{ height: inflowPercent }}></div>
              </div>
              <div className='dc-status-bar-details'>
                <p>Inflow Irregularity</p>
                <h2 className="detail-header">{inflowPercent}</h2>
              </div>
            </div>
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} headerClass="detail-header"
              listContent={[
                { detail: 'Monthly Inflow To Outflow Rate', header: Utils.checkNull(behavioral.monthToMonthInflowToOutflowRate)},
                { detail: 'Overall Inflow To Outflow Rate', header: Utils.checkNull(behavioral.overallInflowToOutflowRate) }
              ]} />
          </>} 
        />
      </div>
      <div className="st-details-card-container-row st-details-double-colored-bottom-row">
        <DetailsCard subtitle={'Loan Amount'} detailClassName={'st-detail-color-background-container'} titleClass="detail-header"
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, behavioral.totalLoanAmount))} 
          detailContent={<>
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              { header: 'Loan To Inflow Rate', detail: Utils.checkNull(Utils.formatPercentage(behavioral.loanToInflowRate))},
              { header: 'Average Monthly Loan Amount', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, behavioral.averageMonthlyLoanAmount))},
              { header: 'Number Of Loan Transaction(s)', detail: Utils.checkNull(behavioral.numberLoanTransactions)},
              { header: 'Latest Loan Transaction Date', detail: Utils.formatLongDate(Utils.formatDate(latestLoanTransaction.date))},
              { header: 'Latest Loan Transaction Amount', detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNullNumber(latestLoanTransaction.amount)) }
            ]} />
        </>} />
        <DetailsCard subtitle={'Loan Repayment Amount'} detailClassName={'st-detail-color-background-container'} titleClass="detail-header"
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, behavioral.totalLoanRepaymentAmount))}
          detailContent={<>
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
                { header: 'Loan Repayment to Inflow Rate', detail: Utils.checkNull(Utils.formatPercentage(behavioral.loanRepaymentToInflowRate))},
                { header: 'Average Monthly Loan Repayments', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, behavioral.averageMonthlyLoanRepaymentAmount))},
                { header: 'Number Of Repayment Transaction(s)', detail: Utils.checkNull(behavioral.numberRepaymentTransactions)},
                { header: 'Latest Repayment Transaction Date', detail: Utils.formatLongDate(Utils.formatDate(latestLoanTransaction.date))},
                { header: 'Latest Repayment Transaction Amount', detail: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNullNumber(latestRepaymentTransaction.amount)) }
            ]} />
        </>} />
      </div>
      {loanRepaymentTransactionsContent}
    </div>
  )
}

export default Behavioral;