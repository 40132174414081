import React, { useState } from 'react'
import RuleParamCardNavigator from './CreateDecision/RuleParamCardNavigator'
import PreviewScoreCard from './PreviewCard'
import SelfProceed from './Proceed'



function DecisionSelfService({accessToken}) {
    const [sectionToShow,setSection] = useState('proceed')
    const [previewCard,setPreviewCard] = useState()
  return (
    <div style={{padding:'30px 55px',boxSizing:"border-box"}}>
        
        {sectionToShow==='proceed'?<SelfProceed accessToken={accessToken} previewCard={previewCard} 
                                                setPreviewCard={setPreviewCard} setSection={setSection}/>
        :sectionToShow==='preview'?<RuleParamCardNavigator accessToken={accessToken} setSection={setSection}/>
        :sectionToShow==='viewScoreCard'?<PreviewScoreCard accessToken={accessToken} 
                                                           previewCard={previewCard} 
                                                           setSection={setSection}
                                                           setPreviewCard={setPreviewCard}
                                                           />:''}
    </div>
  )
}

export default DecisionSelfService