// import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams} from "react-router";
import {
  fetchParameterActionAsync,
  getParameterAction,
  parameterActions,
} from "../../../../redux/Slices/fraudDetect/actionSlice";
import {
  fetchMatchCriteriaAsync,
  getMatchCriteria,
  matchCriteriaSelector,
} from "../../../../redux/Slices/fraudDetect/matchCreteriaSlice";
import {
  fetchParameterActionValuesAsync,
  getActionValues,
  parameterActionValueSelector,
} from "../../../../redux/Slices/fraudDetect/parameterActionValueSlice";
import {
  fetchParametersAsync,
  getParameters,
} from "../../../../redux/Slices/fraudDetect/parametersSlice";
import { parameterSelector } from "../../../../redux/Slices/fraudDetect/parametersSlice/selector";
import {
  fetchRuleAsync,
  resetUpdate,
  ruleSelector,
  updateRuleSelector,
  updateRulesAsync,
} from "../../../../redux/Slices/fraudDetect/ruleSlice";
import { AlertModal } from "../../components/AlertModal";
import { ScoreSlider } from "../../components/ScoreSlider";
import { Label, Select, TextField } from "../../components/form";
import { MultiSelectInput } from "../../components/form/MultiSelectInput";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import Spinner from "../../components/loader";
import SkeletonLoader from "../../components/loader/skeletonLoader";
import styles from "./index.module.css";

const EditRules = ({ configuration, accessToken, user }) => {
  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(createRuleSchema),
  });

  const params = useParams();

  const navigate = useNavigate();

  const parameters = useSelector(parameterSelector);
  const actions = useSelector(parameterActions);
  const [selectedDisplay, setSelectedDisplay] = useState("list");

  const matchCreteria = useSelector(matchCriteriaSelector);

  const actionsValues = useSelector(parameterActionValueSelector);
  const createRuleApi = useSelector(updateRuleSelector);
  const ruleDetail = useSelector(ruleSelector);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchRuleAsync({ id: params.id }));
  }, [accessToken, dispatch, params.id]);

  useEffect(() => {
    dispatch(fetchParametersAsync({ accessToken }));
    dispatch(fetchMatchCriteriaAsync({ pageNumber: 1, pageSize: 5 }));
  }, [accessToken, dispatch]);

  useEffect(() => {
    const handlePreset = async () => {
      if (ruleDetail.data) {
        setLoading(true);
        const [parameterResponse, matchCriteriaResponse] = await Promise.all([
          getParameters(),
          getMatchCriteria(),
        ]);

        //  const parameterResponse = await getParameters();

        const actionResponse = await getParameterAction({
          id: ruleDetail.data?.data?.parameterKey,
        });

        const actions = actionResponse.data;

        const matchCriteria = matchCriteriaResponse.data;

        const parameters = parameterResponse?.data;

        const actionValueResponse = await getActionValues({
          id: ruleDetail.data?.data?.parameterKey,
          actionId: ruleDetail.data?.data?.actionKey,
        });

        const valuesArr = ruleDetail.data?.data?.values?.map(
          (item) => item.key
        );

        const displayType = ruleDetail.data?.data?.values?.find(
          (item) => item.displayType === "Input Field"
        );

        const defaultActionValues = valuesArr
          .map((val) => {
            const itemArr = actions.find(
              (item) => Number(item.key) === Number(val)
            );
            if (itemArr) {
              return {
                label: itemArr?.name,
                value: itemArr?.key,
              };
            }
            return null;
          })
          .filter(Boolean);

        const parameterValue = parameters.find(
          (item) => item.key === ruleDetail.data?.data?.parameterKey
        );

        const matchCriteriaDefault = matchCriteria.find(
          (item) => item.key === ruleDetail.data?.data?.matchCriteriaKey
        );

        const defaultAction = actions.find(
          (item) => item.key === ruleDetail.data?.data?.actionKey
        );

        // dispatch(fetchParameterActionAsync({ id: ruleDetail.data.parameterKey }));
        // dispatch(
        //   fetchParameterActionValuesAsync({
        //     id: ruleDetail.data.parameterKey,
        //     actionId: ruleDetail.data.actionKey,
        //   })
        // );


        let valueHold = ruleDetail?.data?.data?.values.map((element,index)=> 
          ({label:element.friendlyName,value:element.key}))
        const defaultData = {
          ...ruleDetail.data.data,
          matchCriteriaKey: {
            value: matchCriteriaDefault.key,
            label: matchCriteriaDefault?.friendlyName,
          },
          parameterKey: {
            value: parameterValue.key,
            label: parameterValue?.name,
          },
          actionKey: {
            value: defaultAction.key,
            label: defaultAction?.name,
          },
          //values: displayType?.name ?? defaultActionValues,
          values: valueHold,
          score: [
            ruleDetail.data?.data?.minScore,
            ruleDetail.data?.data?.maxScore,
          ],
        };

        methods.reset(defaultData);
        setLoading(false);
      }
    };
    handlePreset();
  }, [methods, ruleDetail.data]);

  const createRuleHandler = (data) => {
    let values =
      selectedDisplay === "input" && data.values?.map((item) => item.value);
    const payload = {
      oldName: ruleDetail.data?.data?.name,
      newName: data.name,
      friendlyName: data.name,
      parameterKey: data.parameterKey?.value,
      actionKey: data.actionKey?.value,
      ValueKeys: data.values.map((item, idx) => {
        return {
          newValueKey: selectedDisplay !== "input" ? item.value : null,
          newValue: selectedDisplay === "input" ? values[idx] : "",
        };
      }),
      // values:
      //   selectedDisplay === "input"
      //     ? data.values?.map((item) => item.value)
      //     : [],
      minScore: data.score[0],
      maxScore: data.score[1],
      addMatchCriteria: data.addMatchCriteria,
      matchCriteriaKey: data.matchCriteriaKey?.value,
    };

    const req = {
      id: params.id,
      payload,
    };

    dispatch(updateRulesAsync(req));
  };

  const validateNotEmpty = (value) => {
    if (!value.trim()) {
      return "This field cannot be empty.";
    }
    return true;
  };

  const validateArray = (value) => {
    if (!Array.isArray(value)) {
      return "This field must be an array";
    }
    if (value.length === 0) {
      return "Array cannot be empty";
    }
    return true;
  };

  if (ruleDetail.error && ruleDetail.error.code === 404) {
    return (
      <InnerLayout>
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          Hmm... this page does not exist.
        </div>
      </InnerLayout>
    );
  }

  return (
    <InnerLayout>
      <div className={styles.formWrapper}>
        <FormProvider {...methods}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();

              //   if (methods.formState?.errors?.apiError) {
              //     methods.clearErrors("apiError");
              //   }
              methods.handleSubmit(createRuleHandler)(event);
            }}
          >
            <div className={styles.titleWrapper}>
              <h5 className={styles.formtTitle}>Update Rule</h5>

              <button
                type="submit"
                className={styles.applyBtn}
                disabled={createRuleApi.status === "loading"}
              >
                {createRuleApi.status === "loading" ? (
                  <Spinner
                    visible={createRuleApi.status}
                    color="#fff"
                    size={20}
                  />
                ) : (
                  "Update Rule"
                )}
              </button>
            </div>

            <div className={styles.formContainers}>
              <div className={styles.formInput}>
                <Controller
                  name="name"
                  control={methods.control}
                  rules={{
                    required: "Name is required.",
                    validate: {
                      notEmpty: validateNotEmpty,
                    },
                  }}
                  render={({ field: { value, onChange }, formState }) => {
                    return (
                      <>
                        <Label>Name</Label>
                        {ruleDetail.status === "loading" || loading ? (
                          <SkeletonLoader height={50} baseColor="#ccc" />
                        ) : (
                          <TextField
                            name="name"
                            inputIsFullWidth={true}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      </>
                    );
                  }}
                />
              </div>

              <div className={styles.formFull}>
                <div className={styles.formInput}>
                  <Controller
                    control={methods.control}
                    name="parameterKey"
                    rules={{
                      required: "Parameter is required.",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Label>Select Paramter</Label>
                        {ruleDetail.status === "loading" || loading ? (
                          <SkeletonLoader height={50} baseColor="#ccc" />
                        ) : (
                          <Select
                            name="parameterKey"
                            className={styles.selectContainer}
                            options={parameters?.data?.data.map((item) => ({
                              label: item.name,
                              value: item.key,
                            }))}
                            value={value}
                            onChange={(e) => {
                              dispatch(
                                fetchParameterActionAsync({ id: e.value })
                              );
                              onChange(e);
                            }}
                            isLoading={parameters.status === "loading"}
                            isDisabled={parameters.status === "loading"}
                          />
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={styles.formInput}>
                  <Controller
                    control={methods.control}
                    name="actionKey"
                    rules={{
                      required: "Action is required.",
                    }}
                    render={({ field: { value, onChange }, formState }) => {
                      const parameter = methods.watch("parameterKey");

                      return (
                        <>
                          <Label>Select Action</Label>
                          {ruleDetail.status === "loading" || loading ? (
                            <SkeletonLoader height={50} baseColor="#ccc" />
                          ) : (
                            <Select
                              className={styles.selectContainer}
                              name="actionKey"
                              options={actions?.data?.data.map((item) => ({
                                label: item.name,
                                value: item.key,
                              }))}
                              value={value}
                              onChange={(e) => {
                                dispatch(
                                  fetchParameterActionValuesAsync({
                                    id: parameter.value,
                                    actionId: e.value,
                                  })
                                );
                                onChange(e);
                              }}
                              isLoading={actions?.status === "loading"}
                              isDisabled={
                                actions?.status === "loading" ||
                                actions?.status === "idle"
                              }
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles.formInput} style={{ width: "50%" }}>
                <Controller
                  control={methods.control}
                  name="values"
                  rules={{
                    required: "Values is required.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    const actionKey = methods.watch("actionKey");
                    const prevValues = methods.watch("values") || [];
                    const selectedAction = actions?.data?.data?.find(
                      (item) => item.key === actionKey?.value
                    );

                    if (
                      selectedAction &&
                      selectedAction.displayType === "Input Field"
                    ) {
                      setSelectedDisplay("input");
                    }

                    return (
                      <>
                        {selectedAction &&
                        selectedAction.displayType === "Input Field" ? (
                          <>
                            <Label>Enter Value</Label>
                            <MultiSelectInput
                              name="values"
                              inputIsFullWidth={true}
                              value={value}
                              prevValues={prevValues}
                              handleEnter={(data) => onChange(data)}
                              onChange={(data) => {
                                onChange(data);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Label>Select Value</Label>
                            <Select
                              name="values"
                              className={styles.selectContainer}
                              options={actionsValues?.data?.data?.map(
                                (item) => ({
                                  value: item.key,
                                  label: item.name,
                                })
                              )}
                              value={value}
                              onChange={(data) => {
                                onChange(data);
                              }}
                              isMulti
                              isLoading={actionsValues?.status === "loading"}
                              isDisabled={
                                actionsValues?.status === "loading" ||
                                actionsValues?.status === "idle"
                              }
                            />
                          </>
                        )}
                      </>
                    );
                  }}
                />
                {/* <Controller
                  control={methods.control}
                  name="values"
                  rules={{
                    required: "Values is required.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <Label>Select Value</Label>

                        {ruleDetail.status === "loading" ? (
                          <SkeletonLoader height={50} baseColor="#ccc" />
                        ) : (
                          <Select
                            name="values"
                            className={styles.selectContainer}
                            options={actionsValues?.data?.data?.map((item) => ({
                              label: item.name,
                              value: item.key,
                            }))}
                            value={value}
                            onChange={(data) => {
                              onChange(data);
                            }}
                            isMulti
                            isLoading={actionsValues?.status === "loading"}
                            isDisabled={
                              actionsValues?.status === "loading" ||
                              actionsValues?.status === "idle"
                            }
                          />
                        )}
                      </>
                    );
                  }}
                /> */}
              </div>

              <div
                className={styles.formInput}
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {ruleDetail.status === "loading" ? (
                  <SkeletonLoader height={50} baseColor="#ccc" />
                ) : (
                  <Controller
                    control={methods.control}
                    name="addMatchCriteria"
                    render={({ field }) => {
                      return (
                        <>
                          <input
                            type="checkbox"
                            name="addMatchCriteria"
                            {...field}
                          />
                          <Label>Add Match Criteria</Label>
                        </>
                      );
                    }}
                  />
                )}
              </div>

              <div style={{ marginBottom: "30px" }}>
                <Controller
                  control={methods.control}
                  name="matchCriteriaKey"
                  rules={{
                    required: "Match criteria  is required.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <Label>Match Criteria</Label>
                        <Select
                          name="matchCriteriaKey"
                          className={styles.selectContainer}
                          options={matchCreteria?.data?.data.map((item) => ({
                            value: item.key,
                            label: item.friendlyName,
                          }))}
                          value={value}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          isLoading={matchCreteria?.status === "loading"}
                          isDisabled={matchCreteria?.status === "loading"}
                        />
                      </>
                    );
                  }}
                />
              </div>

              <div>
                <Controller
                  name="score"
                  control={methods.control}
                  rules={{
                    required: "Score is required.",
                    validate: validateArray,
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <Label htmlFor="progressBar">
                          Set score for this rule
                        </Label>
                        {ruleDetail.status === "loading" ? (
                          <SkeletonLoader height={30} baseColor="#ccc" />
                        ) : (
                          <ScoreSlider
                            onChange={(data) => {
                              onChange(data);
                            }}
                            value={value}
                            name="score"
                          />
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </form>
        </FormProvider>

        {createRuleApi.status === "succeeded" && (
          <AlertModal
            showTitle={false}
            showCancelBtn={false}
            open={createRuleApi.status === "succeeded"}
            toggleModal={() => {}}
            handleDone={() => {
              dispatch(resetUpdate());
              navigate("/fraud-detect/rules");
            }}
          />
        )}
      </div>
    </InnerLayout>
  );
};

export default EditRules;
