import React, { useMemo } from "react";
import { Table } from "../../../components/Table/Table";

function RolesAndPermissions(props) {
  const tableData = props.data;
  const platformUser = props.platformUser;
  
  const theadersRoles = useMemo(() => [
    { 
      Header: "Role name",
      accessor: "label",
      disableSortBy: true,
    },
    {
      id: "action",
      disableSortBy: true,
      Cell: data => {
        let isDisabled = !platformUser.user.permissions.managePermissions;
        return (
          <button className="button-link link-color" onClick={() => props.parentCallback(data.row.original)} disabled={isDisabled}>
            Manage permissions</button>
        );
      }
    }]
  )

  return <div className="user-management-roles-main">
    <div className="user-management-roles-header-container">
      <h2 className="settings-title">ROLES AVAILABLE</h2>
      {platformUser.user.permissions.managePermissions === true &&
        <button className="button-square button-solid" onClick={() => props.addCustomRole()}>+ &nbsp; Create custom role</button>
      }
    </div>
    <Table tableClass="user-management-type-table" data={tableData} columns={theadersRoles} tableExtra={false} />
  </div>
}

export default RolesAndPermissions;