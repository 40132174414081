import classNames from "classnames";

import styles from "./index.module.css";

export const Label = ({ children, ...props }) => {
  return (
    <label {...props} className={classNames(styles.label, props.className)}>
      {children}
    </label>
  );
};
