import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { addToExtractedData,
         getPassportDataAsync,
         resetKycData,
         resetPassportData,
         selectKycEntireDataState } from '../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../library/Variables'
import errorImage from '../../../../assests/icons/errorImage.png'
import { getReportDetail,resetReport } from '../../../../redux/Slices/KycOptions'
import successImg from '../../../../assests/icons/amlFound.png'
import './passholder.css'


function PassportModal({setVerificationType,accessToken,keyFromRoute,
  setViewReport,setKycToShow,inputStyle,customStyles}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const onSubmit = (formData) => {
    const obj = {passportNumber:formData.passportNumber,
                 lastName:formData.lastName,
                 statementKey: keyFromRoute,
                 accessToken}
                 
    dispatch(getPassportDataAsync(obj))
  }


  const getDetails = (token,uuid,type)=>{
    setIsOpen(false);
    setKycToShow(null);
    dispatch(resetPassportData());
    dispatch(resetKycData());

    if(!['CAC','TIN'].includes(type)){
      setVerificationType(type);
      setViewReport(true)
      dispatch(resetReport())
      dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
  
}

  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div style={{padding:"30px"}}>
       <p className='title-class'>Passport Verification</p>
       <div style={{margin:'10px 0px'}}>
           <p>Input Passport Number</p>
           <input  style={inputStyle}{...register("passportNumber", { required: true })} />
           {errors.passportNumber && <span className="text-error">This field is required</span>}

           <p style={{marginTop:'15px'}}>Input Last name</p>
           <input  style={inputStyle}{...register("lastName", { required: true })} />
           {errors.lastName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}
        <p style={{height:'10px'}}></p>
       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                             setKycToShow(null);
                                             dispatch(resetPassportData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

const ModalSuccess = 
  <div className='modal-error'>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
      </div>
      <p style={{textAlign:'center',
                color:"#2A38A4",
                fontSize:"24px",
                fontWeight:"700"}}>
                Passport Extraction Successfull
      </p>
      <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
          Click Done to see
      </p>
      <div className='modal-initial-button-hold'>
            <span className='check' onClick={()=>getDetails(accessToken,kycState?.passportExtraction?.uuid,
                                                            kycState.passportExtraction?.type)}>
                                    Done
            </span>
            
      </div>
  </div>



  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.passportErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKycToShow(null);
                                                  dispatch(resetPassportData())}}>Cancel</span>
           
       </div>
  </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.passportStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.passportStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.passportStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess) 
        }

        if(kycState.passportStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default PassportModal