import React from "react";
import PermissionGroup from "./PermissionGroup";
import "./Permissions.css";
import Utils from "../../library/Utils";
import { useFormContext } from "react-hook-form";

function Permissions(props) {
  const { register, formState: { errors } } = useFormContext();
  const groups = props.groups.map((group, i) => {
    return <PermissionGroup group={group} title={group.title} key={i} isDisabled={props.isDisabled} />
  });

  return <div className="permissions-body">
    {!Utils.isFieldEmpty(props.type) 
      ? <>
          <h2 className="detail-header">{props.type}</h2>
          <p className="text-darker">The {props.type} team have the following privileges:</p>
        </>
      : <div className="user-management-input-container">
          <label className="text-darker">Role name</label>
          <input className="inputs" type="text" placeholder="Enter custom role name" 
            {...register("role", { required: "Role name is required" } )} />
          <span className="user-management-error-fields error-msg">
            {errors.role?.message}</span>
        </div>
    }    
    <div className="scrollbar">
      <div className="scrollable-content">{groups}</div>
    </div>
  </div>
}

export default Permissions;