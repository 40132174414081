import React from "react";
import { 
  useDispatch
} from 'react-redux';
import './Modal.css';
import { PostDataStatus } from '../../library/Variables';
import Spinner from '../../svg/Spinner/Spinner';
import { useSelector } from "react-redux";
import { selectAddAffordabilityStatus,selectAffordabilityState } from "../../redux/Slices/Affordability";

function ConfirmBox(props) {
  let component = null;
  const affordabilityState = useSelector(selectAffordabilityState)

  const dispatch = useDispatch();

  function submitConfirmType() {
    props.parentCallback();
  }

  if(props.typeStatus === PostDataStatus.INITIAL) {
    component = <div className='modal-dialog'>
      <div className="modal-header">
        <h2 className="detail-header">{props.initialStateTitle}</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>    
      </div>
      <div className='modal-body-container'>
        <p>{props.initialStateMessage}</p>
        <div className="submit-container">
          <button onClick={props.close} className="button-square button-outline">Cancel</button>
          <button onClick={submitConfirmType} className="button-square button-solid">Proceed</button>
        </div>
      </div>   
    </div>
  } else if(props.typeStatus === PostDataStatus.FETCHING) {
    component = <div className='modal-dialog'>
      <div className='modal-header header-single'>
        <h2 className="detail-header">{props.fetchingTitle}</h2>
      </div>
      <div className='modal-body-container'>
        <Spinner />
      </div>   
    </div>
  } else if (props.typeStatus === PostDataStatus.SUCCESS) {
    component = <div className='modal-dialog'>
      <div className="modal-header">
        <h2 className="detail-header">{props.successTitle}</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>    
      </div>
      <div className='modal-body-container'>
        <p>{props.successMessage}</p>
        <div className='submit-container'>
          <button onClick={props.close} className="button-square button-solid">Done</button>
        </div>
      </div> 
    </div>
  } else if(props.typeStatus === PostDataStatus.FAILURE) {
    const modifiedErrorMessageSlice = affordabilityState.errorMessage?.slice(1, -1);
    component = <div className='modal-dialog'>
      <div className='modal-header'>
        <h2 className="detail-header">{props.failTitle}</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>       
      </div>
      <div className='modal-body-container'>
        <p>{modifiedErrorMessageSlice}</p>
        <div className='submit-container'>
          <button onClick={() => dispatch(props.resetStatus())} className="button-square button-solid">Try Again</button>
        </div>
      </div>   
    </div>
  }

  return (
    component
  )
}

export default ConfirmBox;