import React,{useState} from 'react'
import { selectPasswordReset,resetPasswordReset } from '../../../redux/Slices/PasswordCollection/ResetPassword/ResetPasswordClient'
import { useDispatch,useSelector } from 'react-redux'
import { PostDataStatus } from '../../../library/Variables'
import amlFilled from '../../../assests/icons/amlFound.png'
import errorImg from '../../../assests/icons/errorImage.png'
import { useNavigate } from 'react-router'
import './ResetPass.css'



function ResetPassWordModal({closeModal}) {
    const dispatch = useDispatch()
    const passData = useSelector(selectPasswordReset)
    const navigate = useNavigate()
    let mainContent

    const proceedToView = ()=>{
        closeModal()
        dispatch(resetPasswordReset())
        navigate('/login')
    }

    const closeView = ()=>{
        closeModal()
        dispatch(resetPasswordReset())
        //navigate('/login')
    }


  

    if(passData.resetPassStatus === PostDataStatus.SUCCESS){
        mainContent =   <div style={{display:"flex",
                                     flexDirection:"column",
                                     height:"250px",
                                     justifyContent:"space-around",
                                     alignItems:"center"}}>
                            <span style={{display:"flex",
                                          alignItems:"center",
                                          justifyContent:"center"}}>
                                          <img src={amlFilled}/>
                            </span>
                            <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>Password Reset Completed</p>

                            <span onClick={()=>proceedToView()} className='buttonAml'>Proceed</span>
                        </div>
    }


    if(passData.resetPassStatus === PostDataStatus.FAILURE){
        mainContent =<div style={{display:"flex",
                                  flexDirection:"column",
                                  height:"250px",
                                  padding:'20px 0px',
                                  justifyContent:"space-around",
                                  alignItems:"center"}}>
        <span style={{display:"flex",
                      alignItems:"center",
                      justifyContent:"center",
                      padding:"5px"}}>
                      <img style={{width:"30px",height:"30px"}} src={errorImg}/>
        </span>
        <p style={{color:'#363C4F'}}>Something went wrong!</p>
        <p style={{color:"red",fontWeight:"500",fontSize:"20px",textAlign:"center",width:"80%",margin:'auto'}}>{passData.errorResetPass}</p>

        <span onClick={()=>closeView()} className='buttonAml'>Close</span>
</div>
    }
  return (
    <div style={{height:'auto'}}>
        {mainContent}
    </div>
  )
}

export default ResetPassWordModal