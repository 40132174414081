import { useEffect, useRef, useState } from "react";
import "./custom.css";

export const CustomDropDownMenu = ({ triggerText, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    setFocusedIndex(-1);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
    setFocusedIndex(-1);
  };

  const handleItemClick = (item) => {
    console.log(`Selected item: ${item}`);
    handleCloseDropdown();
  };

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="dropdown">
      <button onClick={handleToggleDropdown}>{triggerText}</button>
      <span></span>
      {isOpen && (
        <div
          ref={dropdownRef}
          tabIndex="-1"
          className="dropdown-menu"
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              handleCloseDropdown();
            }
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
