import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getApplicationAsync = createAsyncThunk('application', async (params) => {
  const application = await client.get("/application",
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });
  return application.data;
});

export const configSlice = createSlice({
  name: 'application',
  initialState: {
    applicationStatus: FetchDataStatus.INITIAL,
    client: {},
    isError: false,
    errorMessage: null
  },
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getApplicationAsync.pending, (state) => {
      state.applicationStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getApplicationAsync.fulfilled, (state, action) => {      
      state.applicationStatus = FetchDataStatus.SUCCESS;
      state.client = action.payload;
    })
    .addCase(getApplicationAsync.rejected, (state, action) => {
      state.applicationStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const selectApplicationState = state => state.application;

export default configSlice.reducer;