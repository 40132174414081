import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PostDataStatus,CRBGhanaCheckTypes, FetchDataStatus } from "../../../library/Variables";
import amlFilled from '../../../assests/icons/amlFound.png'
import Spinner from "../../../svg/Spinner/Spinner";
import "./crbModal.css";
import Utils from "../../../library/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCommercialCredit,
         getConsumerCredit,
         resetConsumerCreditGet,
         resetCommercialCreditGet,
         selectCrbGhanaData } from "../../../redux/Slices/CRB/Ghana/CrbGhana";

function GhanaCrbFetchModal(props) {
  let content = null;
  const setViewReport = props.setViewReport

  const dispatch = useDispatch();
  const [reportState,setReportState] = useState('')
  const field = props.field;
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const ghanaCrb = useSelector(selectCrbGhanaData);
  const [view, setView] = useState(PostDataStatus.INITIAL);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

  /**
   * const resetForm = () => {
    dispatch(resetConsumerCreditPost());
    dispatch(resetCommercialCreditPost())
  }
   */
  


  const proceedToView = ()=>{
    props.close()
    //resetForm()
}

const cancelModal = ()=>{
    props.close()
    dispatch(resetConsumerCreditGet())
    dispatch(resetCommercialCreditGet())
}

const successClose = ()=>{
    props.close()

    setViewReport(true)
}


  const handleReportSelect = (data)=>{
        setReportState(data)
  }

  const handleStateChanges = (state) => {
    if(state === FetchDataStatus.INITIAL) {
      setView(FetchDataStatus.INITIAL);
    } else if(state === FetchDataStatus.FETCHING) {
      setView(FetchDataStatus.FETCHING);
    } else if(state === FetchDataStatus.FAILURE) {
      setView(FetchDataStatus.FAILURE);
    }
    else if(state === FetchDataStatus.SUCCESS) {
        setView(FetchDataStatus.SUCCESS);
      }
  }

  useEffect(()=>{
    if(ghanaCrb.commercialCreditGetStatus === FetchDataStatus.FETCHING 
        || 
      ghanaCrb.consumerCreditGetStatus === FetchDataStatus.FETCHING ){
        handleStateChanges(FetchDataStatus.FETCHING)
    }

    if(ghanaCrb.commercialCreditGetStatus === FetchDataStatus.FAILURE 
        || 
        ghanaCrb.consumerCreditGetStatus === FetchDataStatus.FAILURE){
        handleStateChanges(FetchDataStatus.FAILURE)
       }

    if(ghanaCrb.commercialCreditGetStatus === FetchDataStatus.SUCCESS 
        || 
        ghanaCrb.consumerCreditGetStatus === FetchDataStatus.SUCCESS){
        handleStateChanges(FetchDataStatus.SUCCESS)
       }
  },[ghanaCrb.consumerCreditGetStatus,ghanaCrb.commercialCreditGetStatus])




  if(view === PostDataStatus.INITIAL) {
    content = <Spinner/>
  } 
  
  else if(view === PostDataStatus.FETCHING) {
    content = <Spinner />
  } 
  
    else if(view === PostDataStatus.SUCCESS){
       content =  <div style={{display:"flex",
                              flexDirection:"column",
                              height:"250px",
                              justifyContent:"space-around",
                              alignItems:"center"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={amlFilled}/></span>
                <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>CRB Fetch Successful.</p>
                <p style={{color:'#363C4F'}}>Click view report to see </p>

                <span 
                    onClick={()=>successClose()} 
                    className='buttonCrb'>
                    View Report
                </span>
        </div>
    }
    
    else if(view === PostDataStatus.FAILURE) {
    content = <div className="bureau-credit-check-form">
      <div style={{display:"flex",
                  justifyContent:"space-around",
                  flexDirection:"column",
                  alignItems:"center"}}>
        <h4 className="detail-header">Request Failed</h4>
        <p>We were unable to retrieve any data.</p>
        <p>{field.type === 'COMMERCIAL'?
            ghanaCrb.commercialCreditGetErrorMessage:
            ghanaCrb.consumerCreditGetErrorMessage}</p>
        <button className="bureau-credit-check-button button-solid button-square" onClick={() => cancelModal()}>
          Close
        </button>
      </div>
    </div>
  } 

  return (
    <div className="bureau-credit-check-dialog">
      <div className="bureau-credit-check-header">
        <img src="/assets/icons/close-color.svg" alt="Close" onClick={props.close} />
      </div>
      {content}
    </div>
  )
}

export default GhanaCrbFetchModal;