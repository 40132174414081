import { createSlice } from "@reduxjs/toolkit";

import { uploadTransactionAsync } from "./thunks";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};




export const fraudUploadTransactionSlice = createSlice({
  name: "fraudUploadTransactionSlice",
  initialState: {
    data: null,
    status: "idle",
  },
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadTransactionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadTransactionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(uploadTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message
      });
  },
});

export const { reset:resetTransactionUpload } = fraudUploadTransactionSlice.actions;