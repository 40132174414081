import React, { useMemo, useState, useCallback } from "react";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import "./Modal.css";
import { selectAccessToken } from "../../redux/Slices/Auth";
import {useDropzone} from "react-dropzone";
import {
  selectAddPdfStatus,
  uploadPdfAsync,
  reset
} from "../../redux/Slices/AddPdf";
import Spinner from "../../svg/Spinner/Spinner";
import Utils from "../../library/Utils";
import { PostDataStatus } from "../../library/Variables";

function AddPdf(props) {
  const dispatch = useDispatch();
  
  const accessToken = props.accessToken;
  const configuration = props.configuration;
  const addPdfState = useSelector(selectAddPdfStatus);
  const [radioSelected, setRadioSelected] = useState("CONSUMER");
  const [radioSelected2, setRadioSelected2] = useState("PDF");
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);

  const maxFileSize = configuration?.featureFlags?.maxFileUploadSize ?configuration.featureFlags.maxFileUploadSize :10000000
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  
  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };

  const onDropRejected = useCallback(fileRejections => {
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])

  const onDrop = useCallback(acceptedFiles => {
    setFilesSelected([...filesSelected, ...acceptedFiles]);
    setFilesRejected([]);
  }, [filesSelected])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: maxFileSize,
    filesizeBase: 1000,
    multiple: false,
    onDrop,
    onDropRejected
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const acceptedFileItems = filesSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeFile(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));


  const submitFileForProcessing = () => {
    let password = document.getElementById("st-password").value;

    let params = {
      pdf: filesSelected[0],
      filePassword: password,
      statementType: radioSelected,
      documentType: radioSelected2,
      accessToken: accessToken
    }
    dispatch(uploadPdfAsync(params));
  }

  const removeFile = (file) => {
    let newFilesArray = [...filesSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setFilesSelected(newFilesArray);
  }

  if(filesSelected.length > 1) {
    removeFile(filesSelected[0]);
  }

  let component = null;
  if(addPdfState.status === PostDataStatus.INITIAL){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload A PDF Statement</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container scrollbar">
        <div className="scrollable-content">
          <div {...getRootProps({style})} className="drop-container">
            <input {...getInputProps()} />
            <img src="/assets/icons/download-color.svg" alt="" />
            <p className="drop-caption">Drag "n" drop your file here, or <span className="detail-header" onClick={open} >
              Open File Dialog</span>
            </p>
            <p className="drop-subcaption text-medium">{`MAX FILE SIZE: ${Math.round((maxFileSize/1048576).toFixed(2))}MB`}</p>
            <p className="drop-subcaption text-medium">FILE SUPPORTED: PDF</p>
          </div>
          {filesRejected.length > 0 &&
            <span className="ap-file-rejected-error-message error-msg">
              {filesRejected[0].errors[0].code === "file-too-large" 
                ? "File is larger than 10MB"
                : filesRejected[0].errors[0].message}
            </span>
          }
          {acceptedFileItems.length > 0 &&
            <>
              <h4 className="text-medium">SELECTED FILE:</h4>
              <div className="ap-accepted-file-container">
                <ul>{acceptedFileItems}</ul>
                <div className="ap-statement-type-container">
                  <h4 className="text-dark">Select a Statement Type</h4>
                  <div className="ap-statement-type-radio-buttons-container">
                      <div className="ap-statement-type">
                        <label>Individual</label>
                        <input type="radio" name={"CONSUMER"} value={"CONSUMER"} 
                        checked={radioSelected === "CONSUMER" ? "checked" : false } 
                        onChange={(e) => setRadioSelected(e.target.name)} />
                      </div>
                      <div className="ap-statement-type">
                        <label>Business</label>
                        <input type="radio" name={"BUSINESS"} value={"BUSINESS"} 
                        checked={radioSelected === "BUSINESS" ? "checked" : false } 
                        onChange={(e) => setRadioSelected(e.target.name)} />
                      </div>
                      <div className="ap-statement-type">
                        <label>Mobile Money</label>
                        <input type="radio" name={"MOBILE_MONEY"} value={"MOBILE_MONEY"} 
                        checked={radioSelected === "MOBILE_MONEY" ? "checked" : false } 
                        onChange={(e) => setRadioSelected(e.target.name)} />
                      </div>
                      <div className="ap-statement-type">
                        <label>Mobile Money Business</label>
                        <input type="radio" name={"MOBILE_MONEY_BUSINESS"} value={"MOBILE_MONEY_BUSINESS"} 
                        checked={radioSelected === "MOBILE_MONEY_BUSINESS" ? "checked" : false } 
                        onChange={(e) => setRadioSelected(e.target.name)} />
                      </div>
                  </div>
                </div>
                <br/>
                { configuration.featureFlags.isOcrEnabled === true && <div className="ap-statement-type-container">
                  <h4 className="text-dark">Document type uploaded</h4>
                  <div className="ap-statement-type-radio-buttons-container">
                      <div className="ap-statement-type">
                        <label>PDF</label>
                        <input type="radio" name={"PDF"} value={"PDF"} 
                        checked={radioSelected2 === "PDF" ? "checked" : false } 
                        onChange={(e) => setRadioSelected2(e.target.name)} />
                      </div>
                      <div className="ap-statement-type">
                        <label>Scanned Document</label>
                        <input type="radio" name={"SCANNED"} value={"SCANNED"} 
                        checked={radioSelected2 === "SCANNED" ? "checked" : false }
                        onChange={(e) => setRadioSelected2(e.target.name)} />
                      </div>
                  </div>
                </div>}
                <div className="ap-password-container">
                  <h4 className="text-dark">File Password <span className="text-medium">(optional)</span></h4>
                  <input type="text" id="st-password" /> 
                </div>
              </div>
              <div className="submit-container">
                <button className="btn-submit button-solid" onClick={() => submitFileForProcessing()}>Upload</button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  } else if(addPdfState.status === PostDataStatus.FETCHING){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Please wait...</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <Spinner />
    </div>
  } else if(addPdfState.status === PostDataStatus.SUCCESS){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">PDF Statement Uploaded</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <p>Upload complete. Click done to see.</p>
        <div className="submit-container">
          <button className="btn-submit button-solid" onClick={props.close}>Done</button>
        </div>
      </div>
    </div>
  } else if(addPdfState.status === PostDataStatus.FAILURE) {
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload Failed</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <div className="modal-body-message">
          <p>{!Utils.isFieldEmpty(addPdfState.errorMessage) && (addPdfState.errorMessage).replace(/[""]/g, "")}</p>
          <p>If the problem persists, please contact support for further inquiries.</p>
        </div>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => dispatch(reset())}>Try Again</button>
      </div>
    </div>
  } 

  return (
    component
  )
}

export default AddPdf;