import React from 'react'
import './DecisionCard.css'
import { useParams } from 'react-router'
import { generateScore } from '../../../../../redux/Slices/LoanScores';
import { useDispatch } from 'react-redux';
import { generateScoreBreakDown } from '../../../../../redux/Slices/LoanScores';

function DecisionCard({title, subTitle, onClick, id,mainKey,selectedCard,setSelectedCard,accessToken,scoreStatus,openModal}) {
    const { key } = useParams();
    const dispatch =useDispatch()
   
  return (
   
        <div className='decision-card-wrapper' onClick={() => setSelectedCard(mainKey)} >
            <div className='inner-wrap'>
                <div className='top-section'>
                    <div className='left-img-wrap'>
                        <img src='/assets/icons/engine-icon.svg' />
                    </div>

                    <div className='right-img-wrap'>
                        {selectedCard===mainKey?<img src='/assets/icons/active-radio-btn.svg' />:''}
                    </div>
                </div>

                <div className='bottom-section'>
                    <h4>{title}</h4>

                    <p>{subTitle}</p>
                </div>
            </div>
        </div>
    
  )
}

export default DecisionCard