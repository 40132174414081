import React, { useMemo } from "react";
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import DetailsCard from '../../../components/DetailsCard/DetailsCard';
import List from '../../../components/List/List';
import { DateRangeColumnFilter, Table } from "../../../components/Table/Table";
import TotalStatementCard from "../../../components/TotalStatementCard/TotalStatementCard";
import TransactionOverviewCard from "../../../components/TransactionOverviewCard/TransactionOverviewCard";
import Utils from "../../../library/Utils";
import {
    selectTransactionState
} from '../../../redux/Slices/Transactions';
import './Overview.css';


function Overview(props) {
    let preparedTableData = [];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = props.accessToken;
    const user = props.user;
    const transactions = useSelector(selectTransactionState);
    const configuration = props.configuration;
    const keyFromRoute = props.keyFromRoute;
    const overview = props.data.activeStatement.overview;

    const params = {
        accessToken: props.accessToken,
        key: keyFromRoute
    };

    const theadersTransactions = useMemo(() => [
        {
            Header: 'Date',
            accessor: 'date',
            Filter: DateRangeColumnFilter,
            filter: 'dateRange'
        },
        {
            Header: 'Credit Turnover',
            accessor: 'creditTurnover'
        },
        {
            Header: 'Debit Turnover',
            accessor: 'debitTurnover'
        },
        {
            Header: 'Highest Balance',
            accessor: 'highestBalance'
        },
        {
            Header: 'Lowest Balance',
            accessor: 'lowestBalance'
        },
        {
            Header: 'Unpaid Items',
            accessor: 'unpaidItems'
        },
        {
            Header: 'Days in Debit',
            accessor: 'daysInDebit'
        }], []
    )

    const clear = () => {
        preparedTableData = [];
    }

    // if (!Utils.isFieldEmpty(transactions.transactions)){
    //     transactions.transactions.forEach(element => {
    //         preparedTableData.push({
    //             "date": Utils.formatLongDate(element.date),
    //             "description": element.description,
    //             "type": element.type,
    //             "amount": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount),
    //             "balance": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.balance)
    //         })
    //     });

    //     preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
    // }

    return (
        <div className="st-details-card-container">
            <div className="st-details-card-container-row st-overview-consolidate-top-row-container">
                <div className='st-overview-consolidate-top-row'>
                    <TotalStatementCard 
                        title={"Total Number Of Statements"}
                        titleValue={overview?.statementCount.totalCount}
                        processedValue={overview?.statementCount.successCount}
                        failedValue={overview?.statementCount.failCount}
                    />

                    {/* <DetailsCard detailClassName={"simple-list-container"}
                        detailContent={<>

                            <div className="wd100">
                                <Button name={"Total Number Of Statements: " + overview.statementCount.totalCount} className="button-link default-cursor mb" iconSrc="/assets/icons/document-total.svg" />
                                <Button name={"Total Processed: " + overview.statementCount.successCount} className="button-link default-cursor processed mb" />
                                <Button name={"Total Failed: " + overview.statementCount.failCount} className="button-link default-cursor failed" />
                            </div>
                        </>
                        }
                    /> */}
                    <DetailsCard detailContent={
                        <><div className="mb"><Button name={"Total Closing Balance"} className="button-link default-cursor" iconSrc="/assets/icons/wallet-3.svg" /></div>
                            <List listClass={'st-details-list'} detailClass={'detail-balance'} listContainerClass={'st-details-list-main-container'} headerClass={"detail-header mb"}
                                listContent={[
                                    {
                                        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview?.totalClosingBalance)),
                                        detail: null
                                    },
                                    {
                                        detail: 'This is the total closing balance accross all accounts'
                                    }
                                ]}
                            />
                        </>
                    } />
                    <DetailsCard detailContent={
                        <><div className="mb"><Button name={"Total Transactions"} className="button-link default-cursor" iconSrc="/assets/icons/transactions.svg" /></div>
                            <List listClass={'st-details-list'} detailClass={'detail-balance'} listContainerClass={'st-details-list-main-container'} headerClass={"detail-header mb"}
                                listContent={[
                                    {
                                        header: Utils.checkNull(overview.totalTransactions),
                                    },
                                    {
                                        detail: 'This is the total number of transactions accross all accounts'
                                    }
                                ]}
                            />
                        </>
                    } />
                </div>
                <div className="summary-row mb">
                    <h3 className="overview-heading">Total Overview</h3>
                    <div className='st-overview-consolidate-top-row summary-row sub-total-overview'>
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.totalCreditTurnover))}
                            headerClass={"header-summary"} titleClass={"detail-header overview-summary"} subtitle={"Total Credit Turnonver"} subtitleClass={"summary-subtitle"} />
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.totalDebitTurnover))}
                            headerClass={"header-summary"} titleClass={"detail-header overview-summary"} subtitle={"Total Debit Turnonver"} subtitleClass={"summary-subtitle"} />
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.averageCreditTurnover))}
                            headerClass={"header-summary"} titleClass={"detail-header overview-summary"} subtitle={"Average Credit Turnonver"} subtitleClass={"summary-subtitle"}
                        />
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.averageDebitTurnover))}
                            headerClass={"header-summary"} titleClass={"detail-header overview-summary"} subtitle={"Average Debit Turnonver"} subtitleClass={"summary-subtitle"}
                        />
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.averageHighestBalance))}
                            headerClass={"header-summary"} titleClass={"detail-header overview-summary"} subtitle={"Average Highest Balance"} subtitleClass={"summary-subtitle"}
                        />
                        <DetailsCard title={Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(overview.totalOverview.averageLowestBalance))}
                            titleClass={"detail-header overview-summary"} subtitle={"Average Lowest Balance"} subtitleClass={"summary-subtitle"}
                        />
                    </div>
                </div>

                <h3 className="detail-header">Transactions Overview</h3>

                {overview.transactionSummaries.map((item, index) =>

                    <div className="summary-row transaction-overview-subhead" key={index}>
                        <p className="overview-heading"> <span>Account ID:  </span>  {item.accountId}</p>

                        <div className="st-details-card-container-row self-transfer-flows">
                            <div className="inner-wrapper">
                                <TransactionOverviewCard 
                                    topDescription={"Total Credit Turnonver"}
                                    topValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.totalCreditTurnover))}
                                    bottomDescription={"Average Credit Turnonver"}
                                    bottomValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.averageCreditTurnover))}
                                />

                                <TransactionOverviewCard 
                                    topDescription={"Total Debit Turnonver"}
                                    topValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.totalDebitTurnover))}
                                    bottomDescription={"Average Debit Turnonver"}
                                    bottomValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.averageDebitTurnover))}
                                />
                            </div>
                        </div>
                        <div className="st-details-card-container-row self-transfer-flows">
                            <div className="inner-wrapper">
                                <TransactionOverviewCard 
                                    topDescription={"Average Highest Balance"}
                                    topValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.averageHighestBalance))}
                                    bottomDescription={"Average Lowest Balance"}
                                    bottomValue={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, item.averageLowestBalance))}
                                />

                                <TransactionOverviewCard 
                                    topDescription={ "Total Unpaid Items"}
                                    topValue={Utils.checkNull(item.totalUnpaidItems)}
                                    bottomDescription={"Total Days in Debit"}
                                    bottomValue={Utils.checkNull(item.totalDaysInDebit)}
                                />
                            </div>
                        </div>

                        {
                            item.statementTransactionDetails.forEach(element =>
                                preparedTableData.push({
                                    "date": Utils.formatLongDate(element.date),
                                    "creditTurnover": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.creditTurnover),
                                    "debitTurnover": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.debitTurnover),
                                    "highestBalance": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.highestBalance),
                                    "lowestBalance": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.lowestBalance),
                                    "unpaidItems": Utils.checkNull(element.unpaidItems),
                                    "daysInDebit": Utils.checkNull(element.daysInDebit)
                                })
                            )
                        }


                        {
                           <> <Table tableClass={" accounts-type-table consolidated-overview-table"}  pagination={true} pageSize={[10]} data={preparedTableData}
                                columns={theadersTransactions} />

                                {clear()}
                            </>
                        }
                    </div>)}
            </div>
        </div>
    )
}

export default Overview;