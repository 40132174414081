
import React,{useState,useEffect} from 'react'
import './index.css'
import { useDispatch,useSelector } from 'react-redux'
import { addSupportingDocsSelector,deleteSupportingDocsAsync } from '../../../../redux/Slices/fraudDetect/supportDocumentSlice'
import successLogo from '../../../../assests/icons/amlFound.png'
import failureLogo from '../../../../assests/icons/amlEmpty.png'
import Spinner from '../../../../svg/Spinner/Spinner'
import { reset } from '../../../../redux/Slices/fraudDetect/supportDocumentSlice'



function DeleteSupportFiles({id,setOpenDeleteModal,deletedFileKey,fileName}) {
  const supportDeleteState =  useSelector(addSupportingDocsSelector)
  const dispatch = useDispatch()
  const [reviewSection, setReviewSection] = useState('')



  const deleteDocs = ()=>{
    dispatch(deleteSupportingDocsAsync({key:deletedFileKey}))
  }

  const closeModal = ()=>{
    dispatch(reset())
    setOpenDeleteModal(false)
  }

  const reviewSectionIntro = <>
                            <section className='title'>
                                Delete Supporting Document
                            </section>

                            <section className='info'>
                                {`Proceed to delete ${fileName} ?`}
                            </section>

                            <section className='buttonHolder'>
                                <button className='block'
                                        onClick={()=>deleteDocs()}>
                                    Delete
                                </button>

                                <button className='allow'
                                        onClick={()=>closeModal()}>
                                    Cancel
                                </button>
                            </section>
                        </>

   

    const reviewSectionLoading = <>
                                    <section className='title'>
                                        Deleting...
                                    </section>

                                    <section className='buttonHolder'>
                                        <Spinner/>
                                    </section>
                                </>

    const reviewSectionSuccess = <>
                                    <section className='imgHold'>
                                        <img src={successLogo}/>
                                    </section>
                                    <section className='title'>
                                        Document deleted Successfully.
                                    </section>

                                    <section className='buttonClose'>
                                        <button onClick={()=>closeModal()} className='done'>
                                            Done
                                        </button>
                                    </section>
                                </>

    const reviewSectionFailed = <>
                                    <section className='imgHold'>
                                        <img src={failureLogo}/>
                                    </section>
                                    <section className='title-block'>
                                        Deleting Supporting Documents Failed
                                    </section>

                                    <section className='info'>
                                        {supportDeleteState.deleteSupportError}
                                    </section>

                                    <section className='buttonClose'>
                                        <button onClick={()=>closeModal()}  className='close'>
                                            Close
                                        </button>
                                    </section>
                                </>

  useEffect(()=>{
    if(supportDeleteState.deleteSupportStatus === 'idle'){       
        setReviewSection(reviewSectionIntro)
    }


    else if(supportDeleteState.deleteSupportStatus === 'loading'){
        setReviewSection(reviewSectionLoading)
    }

    else if(supportDeleteState.deleteSupportStatus === 'succeeded'){
        setReviewSection(reviewSectionSuccess)
    }
    else if(supportDeleteState.deleteSupportStatus === 'failed'){
        setReviewSection(reviewSectionFailed)
    }
  },[supportDeleteState.deleteSupportStatus])


  return (
    <div className='Review-hold'>
        {reviewSection}
    </div>
  )
}

export default DeleteSupportFiles