import React,{useState} from 'react'
import Button from '../../Button/Button'
import { ProceedCard } from '../ProceedCard'
import loanIcon from '../../../assests/icons/loanIcon.svg'
import radioIcon from '../../../assests/icons/radioIcon.png'

function StepTwo({step,setstep,setTourSection}) {

    const proceed = 
    <>
        <div className='de-sub-page-wrap'>
            <div className='subpage-header'>
                <h3 style={{paddingLeft:'35px'}}>Select a card to proceed</h3>
            </div>    
        </div>
    </>

const [cardValue,setCardValue] = useState(0)
  return (
    
    <>
   
   
      <div>
      <div style={{padding:'25px 25px'}}>
        {proceed}
    </div> 
        <div style={{marginTop:'30px',}}>
          <div style={{width:"90%",margin:'0px auto',display:"flex",justifyContent:"space-between"}}>
            {ProceedCard.map((card)=>
            <div onClick={()=>setCardValue(card.id)} style={{width:"25%",cursor:'pointer',height:'100px',borderRadius:"5px",display:"flex",flexDirection:"column",justifyContent:'space-between',padding:'10px',border:"1px solid rgba(195, 212, 255, 1)",backgroundColor:"white"}}>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <span><img src={loanIcon}/></span>
                {cardValue===card.id?
                <span><img src={radioIcon}/></span>
                :<span style={{width:"15px",height:'15px',borderRadius:'50%',border:'0.1px solid black',boxShadow:'0px 4px 6px rgba(0, 0, 0, 0.1)'}}></span>}
              </div>
              <p><strong>{card.title}</strong></p>
              <p>{card.extra}</p>
            </div>
            )}
          </div>
          
        </div>
  
        <div style={{marginLeft:'5%',marginTop:"35px",position:'relative',height:"150px",width:'300px'}}>
            <div style={{width:"20px",height:"20px",transform:"rotate(45deg)",position:'absolute',top:"-10px",left:"20px",border:"1px solid lightblue",zIndex:"4"}}></div>
            <div style={{width:"19px",height:"19px",transform:"rotate(45deg)",position:'absolute',top:"-9px",left:"21px",backgroundColor:"white",zIndex:"14"}}></div>
            <div style={{height:'100%',padding:'5px',zIndex:'10',borderRadius:'7px',border:"1px solid rgba(195, 212, 255, 1)",position:"absolute",top:'0px',left:"0px",right:'0px',backgroundColor:"white",display:'flex',flexDirection:"column",justifyContent:"space-between"}}>
            <div style={{height:"60%",boxSizing:"border-box",display:"flex",flexDirection:"column",justifyContent:'space-around'}}>
              <p><strong>Here's how we do it.</strong></p>
              <p style={{fontSize:"11px"}}>Simply select a loan type to be used for decision making</p>
            </div>
  
            <div style={{border:"0.1px solid lightblue",display:"flex",justifyContent:"space-around",alignItems:"center",boxSizing:"border-box",borderWidth:'0.1px 0px 0px 0px',height:"40%"}}>
                <span onClick={()=>setTourSection('stepOne')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"white",cursor:'pointer',color:'#407BFF',border:"1px solid #407BFF"}}>Back</span>
                <span><strong>1 of 4</strong></span>
                <span onClick={()=>setTourSection('stepThree')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"#407BFF",cursor:"pointer",color:'white'}}>Next</span>
            </div>
            </div>
          </div>
      </div>
  
  
  </>
  )
}

export default StepTwo