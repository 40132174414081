import { useLocation, useNavigate } from "react-router-dom";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Utils from "../../library/Utils";
import Spinner from "../../svg/Spinner/Spinner";
import { useEffect, useMemo, useState } from "react";
import amlFilled from '../../assests/icons/amlFound.png'
import errorImg from '../../assests/icons/errorImage.png'
import { useDispatch, useSelector } from "react-redux";
import { resetReviewedTransaction,
         resetPendingTransaction,
         resetFlaggedTransaction } from "../../redux/Slices/fraudDetect/transactionSlice";

import { resetTransactionUpload } from "../../redux/Slices/fraudDetect/transactionUploadSlice/uploadTransaction";
import {
  fetchFlaggedTransactionAsync,
  fetchPendingTransactionAsync,
  fetchReviewedTransactionAsync,
  fetchTransactionStatisticAsync,
  flaggedTransactionSelector,
  pendingTransactionSelector,
  reviewTransactionSelector,
  transactionStatisticSelector,
} from "../../redux/Slices/fraudDetect/transactionSlice";
import { uploadTransactionSelector } from "../../redux/Slices/fraudDetect/transactionUploadSlice/selector";
import { uploadTransactionAsync } from "../../redux/Slices/fraudDetect/transactionUploadSlice/thunks";
import { CustomDropDownMenu } from "./components/DropdownMenu";
import { FilterForm } from "./components/FilterForm";
import { CustomTabs } from "./components/Tab";
import { Table } from "./components/Table";
import TransStatisSkeleton from "./components/loader/transStatLoader";
import { CustomDialog } from "./components/Modal";
import styles from "./index.module.css";
import FileUploadDrop from "./components/FileUpload";
import { generateFormData } from "../../utils/generateFormData";

import dayjs from "../../utils/dayjs";
import { colorCode } from "./transactions/routes";

const FraudDetect = ({ configuration, accessToken, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const transStatis = useSelector(transactionStatisticSelector);
  const uploadTransactionApi = useSelector(uploadTransactionSelector);
  const flaggedTransaction = useSelector(flaggedTransactionSelector);
  const pendingTransaction = useSelector(pendingTransactionSelector);
  const reviewTransaction = useSelector(reviewTransactionSelector);
  const [transactionUploadModal, setTransactionUploadModal] = useState(false);
  const [reviewDataState,setReviewDataState] = useState([])
  const [files, setFiles] = useState([]);
  const removeFile = (index) => {
    setFiles((l) => [...l.slice(0, index), ...l.slice(index + 1)]);
  };


  
  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(createCustomRuleSchema),
  });

  const uploadTransactionFunction = (data) => {
    const payload = {
      file: files.length ? files[0].file : "",
      // FileUrl: files.length ? files[0].file : "",
    };

    dispatch(uploadTransactionAsync(generateFormData((payload))));
  };

  const resetPage = ()=>{
    dispatch(resetFlaggedTransaction())
    dispatch(resetPendingTransaction())
    dispatch(resetReviewedTransaction())
    dispatch(resetTransactionUpload())
  }

  const closeView = ()=>{
        setTransactionUploadModal(false)
  }



  useEffect(()=>{
    if(!Utils.isFieldEmpty(reviewTransaction?.data?.data)){
      const arrayTempHold = [...reviewTransaction?.data?.data]
      const stateHolder = arrayTempHold.sort((a,b)=>new Date(b.updated) - new Date(a.updated))
      setReviewDataState(stateHolder)
    }
  },[reviewTransaction])

  const [showFilter] = useState(false);

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const proceedToView = ()=>{
        setTransactionUploadModal(false)
        resetPage()
  }

  const status = queryParams.get("status");

  useEffect(() => {
    if (!status) {
      queryParams.set("status", "flagged");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.pathname, navigate, queryParams, status]);

  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    status: "flagged",
  });

  const [filterApproved, setFilterApproved] = useState({
    pageNumber: 1,
    pageSize: 10,
    status: "approved",
  });

  const [filterPending, setFilterPending] = useState({
    pageNumber: 1,
    pageSize: 10,
    status: "pending",
  });

  useEffect(() => {
    if(flaggedTransaction.status==='idle'){
      dispatch(fetchFlaggedTransactionAsync(filters));
    }
  }, [dispatch, filters,flaggedTransaction]);


  useEffect(() => {
    if(pendingTransaction.status==='idle'){
      dispatch(fetchPendingTransactionAsync(filterPending));
    }
  }, [dispatch, filterPending,pendingTransaction]);



  useEffect(() => {
    if(reviewTransaction.status==='idle'){
      dispatch(fetchReviewedTransactionAsync(filterApproved));
    }
    
  }, [dispatch, filterApproved,reviewTransaction]);



  useEffect(() => {
    dispatch(fetchTransactionStatisticAsync());
  }, [dispatch]);

  const items = [
    {
      label: "Flagged Profiles",
      key: "flagged",
      status: "status",
      children: (
        <Table
          showPagination={true}
          pageCount={flaggedTransaction?.data?.data?.totalPages}
          loading={flaggedTransaction.status === "loading"}
          gotoPage={(page) =>
            setFilters((prev) => ({ ...prev, pageNumber: page }))
          }
          columns={[
            {
              title: "Name",
              field: "name",
              sortable: true,
            },
            {
              title: "Amount",
              field: "amount",
              sortable: true,
            },

            {
              title: "Channel",
              field: "channel",
              sortable: true,
            },
            {
              title: "Risk Score",
              field: "riskScore",
              sortable: true,
            },
            {
              title: "Transaction Date",
              field: "transactionDate",
              sortable: true,
            },
            {
              title: "Updated Date",
              field: "updated",
              sortable: true,
            },
            {
              title: "Status",
              field: "status",
              sortable: true,
              Cell: ({ entry }) => (
                <span
                  style={{
                    color: colorCode(entry.status),
                  }}
                >
                  {entry.status}
                </span>
              ),
            },
            {
              title: (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>Action</span>

                  {showFilter && (
                    <span>
                      <CustomDropDownMenu
                        triggerElem={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.89844 2.10156H19.0984C20.1984 2.10156 21.0984 3.00156 21.0984 4.10156V6.30156C21.0984 7.10156 20.5984 8.10156 20.0984 8.60156L15.7984 12.4016C15.1984 12.9016 14.7984 13.9016 14.7984 14.7016V19.0016C14.7984 19.6016 14.3984 20.4016 13.8984 20.7016L12.4984 21.6016C11.1984 22.4016 9.39844 21.5016 9.39844 19.9016V14.6016C9.39844 13.9016 8.99844 13.0016 8.59844 12.5016L4.79844 8.50156C4.29844 8.00156 3.89844 7.10156 3.89844 6.50156V4.20156C3.89844 3.00156 4.79844 2.10156 5.89844 2.10156Z"
                              stroke="#2A38A4"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                      >
                        <FilterForm />
                      </CustomDropDownMenu>
                    </span>
                  )}

                  <span>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.501 12C22.501 17.52 18.021 22 12.501 22C6.98098 22 3.61098 16.44 3.61098 16.44M3.61098 16.44H8.13098M3.61098 16.44V21.44M2.50098 12C2.50098 6.48 6.94098 2 12.501 2C19.171 2 22.501 7.56 22.501 7.56M22.501 7.56V2.56M22.501 7.56H18.061"
                        stroke="#2A38A4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              ),
              field: "action",
              Cell: ({ entry }) => (
                <div>
                  <button
                    onClick={() =>
                      navigate(`/fraud-detect/${entry?.userKey}/transactions`)
                    }
                    className={styles.contained}
                  >
                    View
                  </button>
                </div>
              ),
            },
          ].filter(Boolean)}
          data={flaggedTransaction?.data?.data?.map((item) => {
            return {
              ...item,
              amount: new Intl.NumberFormat().format(item.amount),
              transactionDate: Utils.formatLongDateAndTime(item.transactionDate),
              updated: Utils.formatLongDateAndTime(item.updated),
            };
          })}
        />
      ),
    },

    {
      label: "Pending Cases",
      key: "pending",
      status: "status",
      children: (
        <Table
          showPagination={true}
          pageCount={pendingTransaction?.data?.data?.totalPages}
          loading={pendingTransaction.status === "loading"}
          gotoPage={(page) =>
            setFilters((prev) => ({ ...prev, pageNumber: page }))
          }
          columns={[
            {
              title: "Name",
              field: "name",
              sortable: true,
            },
            {
              title: "Amount",
              field: "amount",
              sortable: true,
            },

            {
              title: "Channel",
              field: "channel",
              sortable: true,
            },
            {
              title: "Risk Score",
              field: "riskScore",
              sortable: true,
            },
            {
              title: "Transaction Date",
              field: "transactionDate",
              sortable: true,
            },
            {
              title: "Updated Date",
              field: "updated",
              sortable: true,
            },
            {
              title: "Status",
              field: "status",
              sortable: true,
              Cell: ({ entry }) => (
                <span
                  style={{
                    color: colorCode(entry.status),
                  }}
                >
                  {entry.status}
                </span>
              ),
            },
            {
              title: (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>Action</span>

                  {showFilter && (
                    <span>
                      <CustomDropDownMenu
                        triggerElem={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.89844 2.10156H19.0984C20.1984 2.10156 21.0984 3.00156 21.0984 4.10156V6.30156C21.0984 7.10156 20.5984 8.10156 20.0984 8.60156L15.7984 12.4016C15.1984 12.9016 14.7984 13.9016 14.7984 14.7016V19.0016C14.7984 19.6016 14.3984 20.4016 13.8984 20.7016L12.4984 21.6016C11.1984 22.4016 9.39844 21.5016 9.39844 19.9016V14.6016C9.39844 13.9016 8.99844 13.0016 8.59844 12.5016L4.79844 8.50156C4.29844 8.00156 3.89844 7.10156 3.89844 6.50156V4.20156C3.89844 3.00156 4.79844 2.10156 5.89844 2.10156Z"
                              stroke="#2A38A4"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                      >
                        <FilterForm />
                      </CustomDropDownMenu>
                    </span>
                  )}

                  <span>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.501 12C22.501 17.52 18.021 22 12.501 22C6.98098 22 3.61098 16.44 3.61098 16.44M3.61098 16.44H8.13098M3.61098 16.44V21.44M2.50098 12C2.50098 6.48 6.94098 2 12.501 2C19.171 2 22.501 7.56 22.501 7.56M22.501 7.56V2.56M22.501 7.56H18.061"
                        stroke="#2A38A4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              ),
              field: "action",
              Cell: ({ entry }) => (
                <div>
                  <button
                    onClick={() =>
                      navigate(`/fraud-detect/${entry?.userKey}/transactions`)
                    }
                    className={styles.contained}
                  >
                    View
                  </button>
                </div>
              ),
            },
          ].filter(Boolean)}
          data={pendingTransaction?.data?.data?.map((item) => {
            return {
              ...item,
              amount: new Intl.NumberFormat().format(item.amount),
              transactionDate: Utils.formatLongDateAndTime(item.transactionDate),
              updated: Utils.formatLongDateAndTime(item.updated),
            };
          })}
        />
      ),
    },
    {
      label: "Reviewed Cases",
      key: "review",
      status: "status",
      children: (
        <Table
          showPagination={true}
          pageCount={reviewTransaction?.data?.data?.totalPages}
          loading={reviewTransaction?.status === "loading"}
          gotoPage={(page) =>
            setFilters((prev) => ({ ...prev, pageNumber: page }))
          }
          columns={[
            {
              title: "Name",
              field: "name",
              sortable: true,
            },
            {
              title: "Amount",
              field: "amount",
              sortable: true,
            },

            {
              title: "Channel",
              field: "channel",
              sortable: true,
            },
            {
              title: "Risk Score",
              field: "riskScore",
              sortable: true,
            },
            {
              title: "Transaction Date",
              field: "transactionDate",
              sortable: true,
            },
            {
              title: "Updated Date",
              field: "updated",
              sortable: true,
            },
            {
              title: "Status",
              field: "status",
              sortable: true,
              Cell: ({ entry }) => (
                <span
                  style={{
                    color: colorCode(entry.status),
                  }}
                >
                  {entry.status}
                </span>
              ),
            },
            {
              title: (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>Action</span>

                  {showFilter && (
                    <span>
                      <CustomDropDownMenu
                        triggerElem={
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.89844 2.10156H19.0984C20.1984 2.10156 21.0984 3.00156 21.0984 4.10156V6.30156C21.0984 7.10156 20.5984 8.10156 20.0984 8.60156L15.7984 12.4016C15.1984 12.9016 14.7984 13.9016 14.7984 14.7016V19.0016C14.7984 19.6016 14.3984 20.4016 13.8984 20.7016L12.4984 21.6016C11.1984 22.4016 9.39844 21.5016 9.39844 19.9016V14.6016C9.39844 13.9016 8.99844 13.0016 8.59844 12.5016L4.79844 8.50156C4.29844 8.00156 3.89844 7.10156 3.89844 6.50156V4.20156C3.89844 3.00156 4.79844 2.10156 5.89844 2.10156Z"
                              stroke="#2A38A4"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                      >
                        <FilterForm />
                      </CustomDropDownMenu>
                    </span>
                  )}

                  <span>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.501 12C22.501 17.52 18.021 22 12.501 22C6.98098 22 3.61098 16.44 3.61098 16.44M3.61098 16.44H8.13098M3.61098 16.44V21.44M2.50098 12C2.50098 6.48 6.94098 2 12.501 2C19.171 2 22.501 7.56 22.501 7.56M22.501 7.56V2.56M22.501 7.56H18.061"
                        stroke="#2A38A4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              ),
              field: "action",
              Cell: ({ entry }) => (
                <div>
                  <button
                    onClick={() =>
                      navigate(`/fraud-detect/${entry?.userKey}/transactions`)
                    }
                    className={styles.contained}
                  >
                    View
                  </button>
                </div>
              ),
            },
          ].filter(Boolean)}
          data={reviewDataState?.map((item) => {
            return {
              ...item,
              amount: new Intl.NumberFormat().format(item.amount),
              transactionDate: Utils.formatLongDateAndTime(item.transactionDate),
              updated: Utils.formatLongDateAndTime(item.updated),
            };
          })}
        />
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "transparent", height: "100%" }}>
      <section className="idVerifyHead">
        <section className="idVerifyContent">
          <span>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 20C16.1421 20 19.5 16.6421 19.5 12.5C19.5 8.35786 16.1421 5 12 5C7.85786 5 4.5 8.35786 4.5 12.5C4.5 16.6421 7.85786 20 12 20Z"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 4.5V2.5"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 12.5H2"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 20.5V22.5"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 12.5H22"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p>Fraud Detect</p>
        </section>
      </section>
      <section style={{ marginTop: "30px" }} className="dash-body">
        {transStatis.status === "loading" ? (
          <TransStatisSkeleton />
        ) : (
          <div className="top-cards-container">
            <>
              <DetailsCard
                title={Utils.formatNumber(
                  configuration.currency.format,
                  transStatis.data?.data?.flaggedProfiles ?? 0
                )}
                subtitle={"Flagged Profiles"}
                visualContent={
                  <img src="/assets/icons/fraud-detect.svg" alt="" />
                }
              />
              <DetailsCard
                title={Utils.formatNumber(
                  configuration.currency.format,
                  transStatis.data?.data?.pendingCases ?? 0
                )}
                subtitle={"Total Pending Cases"}
                visualContent={
                  <img src="/assets/icons/fraud-detect.svg" alt="" />
                }
              />
              <DetailsCard
                title={Utils.formatNumber(
                  configuration.currency.format,
                  transStatis.data?.data?.reviewedCases ?? 0
                )}
                subtitle={"Total Reviewed Cases"}
                visualContent={
                  <img src="/assets/icons/fraud-detect.svg" alt="" />
                }
              />
            </>
          </div>
        )}

        <section style={{display:'flex',justifyContent:'space-between'}}>
            <div className={styles.buttonGroup}>
              <button
                onClick={() => navigate("/fraud-detect/rules")}
                className={styles.contained}
              >
                Manage Rules
              </button>
              <button
                onClick={() => navigate("/fraud-detect/custom-rule")}
                className={styles.outlined}
              >
                Custom List
              </button>
            </div>
            <div>
              <button
                  onClick={() => setTransactionUploadModal(true)}
                  className={styles.outlined}
                >
                Upload Transaction
              </button>
            </div>
        </section>
      </section>
      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <CustomTabs
            name="status"
            defaultActiveKey={status}
            items={items}
            key={status}
          />
        </div>
      </div>

      {transactionUploadModal && (
        <CustomDialog
          open={transactionUploadModal}
          toggleModal={() => {setTransactionUploadModal(false);resetPage()}}
          title= {uploadTransactionApi.status === "loading" || uploadTransactionApi.status === "idle"? "Upload New Transaction":''}
          contentStyle={{
            width: "650px",
            height: "800px",
            overflowY: "auto",
          }}
        >
          {uploadTransactionApi.status === "loading" || uploadTransactionApi.status === "idle"?
          
          <div className={styles.customRuleForm}>
            <FormProvider {...methods}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  methods.handleSubmit(uploadTransactionFunction)(event);
                }}
              >
                <div className={styles.customFormContainers}>
 
                  <div className={styles.formFull}>
                    <FileUploadDrop
                      files={files}
                      handleFile={(files) => setFiles(files)}
                      removeFile={removeFile}
                      json={true}
                    />
                  </div>

                  {files.length>0 && <div className={styles.customFormBtnWrapper}>
                    <button
                      type="submit"
                      disabled={uploadTransactionApi.status === "loading"}
                      className={styles.customRuleSaveBtn}
                    >
                      {uploadTransactionApi.status === "loading" ? (
                       'Saving...'
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>}
                </div>
              </form>
            </FormProvider>
          </div>
          
          :uploadTransactionApi.status === "succeeded"?
          <div style={{display:"flex",
                      flexDirection:"column",
                      height:"250px",
                      justifyContent:"space-around",
                      alignItems:"center"}}>
                <span style={{display:"flex",
                              alignItems:"center",
                              justifyContent:"center"}}>
                              <img src={amlFilled}/>
                </span>
                <p style={{color:"#2A38A4",
                           fontWeight:"700",
                           fontSize:"24px"}}>
                    Document Uploaded Successfully.
                </p>

                <span onClick={()=>proceedToView()} className='buttonAml'>
                  Close
                </span>
        </div>:
        <div style={{display:"flex",
                    flexDirection:"column",
                    height:"200px",
                    justifyContent:"space-around",
                    alignItems:"center"}}>
          <span style={{display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        padding:"5px"}}>
              <img style={{width:"30px",
                          height:"30px"}} 
                          src={errorImg}/>
                          
          </span>
          <p style={{color:'#363C4F'}}>
            Something went wrong!
          </p>
          <p style={{color:"red",
                    fontWeight:"700",
                    fontSize:"24px"}}>
                    {uploadTransactionApi.error}
          </p>

          <span onClick={()=>closeView()} className='buttonAml'>Close</span>
        </div>}
        </CustomDialog>
      )}
    </div>
  );
};

export default FraudDetect;
