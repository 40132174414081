import React, { useMemo, useState } from "react";
import { Table, SelectColumnFilter } from "../../../components/Table/Table";
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';

function MobileTransactions(props) {
  let preparedTableData = [];

  const transactions = props.transactions; 
  const configuration = props.configuration;
  const [isLoading, setLoading] = useState(true);

  const theadersTransactions = useMemo(() => [
    { 
      Header: 'Narration',
      accessor: 'narration',
    },
    {
      Header: 'Transaction Type',
      accessor: 'type',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    }], []
  )

  if(transactions !== undefined && transactions !== null && transactions !== '' && transactions !== {}) {
    if(isLoading === true) {
      setLoading(false);
    }

    transactions.forEach(element => {
      preparedTableData.push({
        "narration": element.narration,
        "type": element.type,
        "amount": Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)
      })
    });

    preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
  }          

  return (
    <div>
      {isLoading === true 
        ? <Spinner />
        : preparedTableData.length > 0
          ? <div className="cards mb-transactions-type-table-container">
              <h2 className="detail-header">Transactions</h2>
              <p className="text-medium">
                A list of all transactions made on this account</p>
              <Table pagination={true} pageSize={[10]} data={preparedTableData} columns={theadersTransactions} 
                rowClass={"table-row-two-colored"} />
            </div>
          : <div className="cards mb-details-no-table-data-container">
              <h2 className="detail-header">There are no bank Transactions to display at this time</h2>
            </div>
      }
    </div>
  )
}

export default MobileTransactions;