
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import cardErrorIcon from '../../../assests/icons/amlEmpty.png'
import cardCreatedIcon from '../../../assests/icons/amlFound.png'
import Utils from '../../../library/Utils'
import { PostDataStatus } from '../../../library/Variables'
import { defineScoreCardName, selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../svg/Spinner/Spinner'
import '../CreateDecision/DefineScoreCard/definescorecard.css'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './SelfScoreCard.css'



function CreateSelfScoreCard({setSection,accessToken,closeModal}) {
    const [toggleState,setToggleState] = useState('POINTS')
    const dispatch = useDispatch()
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [cardState,setCardState] = useState()
    const [scoreCardDisplay,setScoreCardDisplay] = useState(<></>)
    let cardObj
    const saveScoreCard = ()=>{
        const allFields = getValues()
        cardObj = {
                    name:allFields.scoreName,
                    baseScore:parseInt(allFields.baseScore),
                    isCustomDecisionEngine:true,
                    type: toggleState==='DECISION'?'DECISION':'POINTS'}
        setCardState(cardObj)

        if(!Utils.isFieldEmpty(cardObj?.name) && !Utils.isFieldEmpty(cardObj?.baseScore) 
            && !Utils.isFieldEmpty(cardObj?.isCustomDecisionEngine)){
                dispatch(defineScoreCardName({data:cardObj,accessToken}))
        }
        else{
            toast.info('Fill all neccessary Fields.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
    }
    const selfScoreDef = useSelector(selectSelfServiceState)

    const scoreDefSuccess = <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={cardCreatedIcon}/>
                                </span>
                                <span className='cardInfo'>Your Card was created successfully.</span>
                                <span className='cardDirection'>Click done to see</span>

                                <section style={{display:"flex",
                                                 alignItems:"center",
                                                 justifyContent:"center",
                                                 width:"100%"}}>
                                    <span onClick={()=>closeModal()} className='button'>
                                        Done
                                    </span>
                                </section>
                                    
                            </section>

    const scoreDefFailure = <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={cardErrorIcon}/>
                                </span>
                                <span className='cardInfo'>Card Creation Failed</span>
                                <span className='cardFailed'>Something went wrong</span>

                                <section style={{display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                width:"100%"}}>
                                    <span onClick={()=>closeModal()} className='button'>
                                        Close
                                    </span>
                                </section>
                                    
                            </section>
    
 
    useEffect(()=>{
        if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.INITIAL){
            setScoreCardDisplay(<></>)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.FETCHING){
            setScoreCardDisplay(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
            setScoreCardDisplay(scoreDefSuccess)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.FAILURE){
            setScoreCardDisplay(scoreDefFailure)
        }
    },[selfScoreDef.selfServiceCardNameStatus,toggleState])




  return (
    <div style={{height:'350px',paddingTop:"20px"}}>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
          
        </section>

        <section style={{margin:'10px 0px',padding:'0px 0px'}}>
            {scoreCardDisplay}
        </section>
        
    </div>
  )
}

export default CreateSelfScoreCard


/**
 *  <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>

            {selfServiceState.parameterOptionsStatus === PostDataStatus.SUCCESS && <div onClick={()=>{dispatch(resetRules()); 
                            navigate('/DecisionEngineSelfService/RulesCreation')}} 
                            className='createEngineBut'>
                    Create Rules
            </div>}
        
        </section>
 */