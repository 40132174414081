import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { addToExtractedData,
         resetKycData,
         selectKycEntireDataState,
         getLicenseDataAsync,
         resetLicenseData } from '../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../library/Variables'
import { getReportDetail,resetReport } from '../../../../redux/Slices/KycOptions'
import errorImage from '../../../../assests/icons/errorImage.png'
import successImg from '../../../../assests/icons/amlFound.png'
import './licenseholder.css'


function LicenseModal({setVerificationType,accessToken,keyFromRoute,
  setViewReport,setKycToShow,inputStyle,customStyles}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const onSubmit = (formData) => {
    const obj = {licenseNumber:formData.inputName,
                statementKey: keyFromRoute,
                accessToken}
    dispatch(getLicenseDataAsync(obj))
  }


  const getDetails = (token,uuid,type)=>{
    setIsOpen(false);
    setKycToShow(null);
    dispatch(resetLicenseData());
    dispatch(resetKycData());

    if(!['CAC','TIN'].includes(type)){
      setVerificationType(type);
      setViewReport(true)
      dispatch(resetReport())
      dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
  
}


  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p className='title-class'>Driver's License Verification</p>
       <div style={{}}>
           <p>Input license Number</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKycToShow(null);
                                                  dispatch(resetLicenseData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>


const ModalSuccess = <div className='modal-error'>
<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
  <img style={{width:'40px'}} src={successImg}/>
</div>
<p style={{textAlign:'center',
           color:"#2A38A4",
           fontSize:"24px",
           fontWeight:"700"}}>LICENSE Extraction Successfull</p>
<p style={{color:'#363C4F',
          fontSize:"16px",
          fontWeight:"400" }}>
    Click Done to see
</p>
<div className='modal-initial-button-hold'>
      <span className='check' onClick={()=>getDetails(accessToken,kycState?.licenseExtraction?.uuid,
                                                            kycState.licenseExtraction?.type)}>
                              Done
      </span>
      
  </div>
</div>


  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kycState.licenseErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKycToShow(null);
                                                  dispatch(resetLicenseData())}}>Cancel</span>
           
       </div>
  </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.licenseStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.licenseStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.licenseStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess)
        }

        if(kycState.licenseStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default LicenseModal