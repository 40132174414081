import React, { useMemo } from "react";
import Utils from "../../../library/Utils";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import { Table } from "../../../components/Table/Table";
import List from "../../../components/List/List";

function Income(props) {
  let detailIncomeContent = null;
  let detailPaymentContent = null;
  let salaryTransactionsTable = [];
  let otherTransactionsTable = [];

  const configuration = props.configuration;
  const income = props.data;

  const theadersTransactions = useMemo(() => [
    { 
      Header: 'Date',
      accessor: 'date'
    },
    { 
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Description',
      accessor: 'description',
    }], []
  )

  detailIncomeContent = <List listClass={'st-details-column-list'} listContainerClass={'st-details-list-main-container'}
    detailClass="text-medium" listContent={[
      {
        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(income.averagePredictedSalary)),
        detail: 'Average Predicted Salary'
      },
      {
        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(income.averageOtherIncome)),
        detail: 'Average Other Income'
      },
      {
        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(income.lowestSalary)),
        detail: 'Lowest Salary'
      },
      { 
        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(parseFloat(income.mostRecentSalary))), 
        detail: 'Most Recent Salary'
      },
      {
        header: Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, Utils.checkNull(income.netAverageMonthlyEarning)),
        detail: 'Net Monthly Average Earning'
      }
    ]} />

  if(configuration.featureFlags.isFrequencyOfSalaryPaymentEnabled === true && configuration.featureFlags.isMissingSalaryPaymentEnabled === true) {
    detailPaymentContent = <List listClass={'st-details-column-list'} listContainerClass={'st-details-list-main-container'}  
      detailClass="text-medium" listContent={[
        {
          header: Utils.checkNull(income.numberOfSalaryPayments),
          detail: 'No. Of Salary Payments'
        },
        {
          header: Utils.checkNull(income.frequencyOfSalaryPayments),
          detail: 'Frequency Of Salary Payments'
        },
        {
          header: Utils.checkNull(income.expectedSalaryPaymentDay),
          detail: 'Expected Salary Payment Day'
        },
        {
          header: Utils.formatLongDate(Utils.formatDate(income.lastDateOfSalaryPayment)),
          detail: 'Latest Salary Date'
        },
        { 
          header: Utils.checkNull(income.numberOfOtherIncomePayments),
          detail: 'No. Of Other Income Payments'
        },
        { 
          header: Utils.checkNull(income.missingSalaryPayment),
          detail: 'Missing Salary Payment'
        }
    ]} />
  }
  else if(configuration.featureFlags.isFrequencyOfSalaryPaymentEnabled === true && configuration.featureFlags.isMissingSalaryPaymentEnabled === false){
    detailPaymentContent = <List listClass={'st-details-column-list'} listContainerClass={'st-details-list-main-container'}  
      detailClass="text-medium" listContent={[
        {
          header: Utils.checkNull(income.numberOfSalaryPayments),
          detail: 'No. Of Salary Payments'
        },
        {
          header: Utils.checkNull(income.frequencyOfSalaryPayments),
          detail: 'Frequency Of Salary Payments'
        },
        {
          header: Utils.checkNull(income.expectedSalaryPaymentDay),
          detail: 'Expected Salary Payment Day'
        },
        {
          header: Utils.formatLongDate(Utils.formatDate(income.lastDateOfSalaryPayment)),
          detail: 'Latest Salary Date'
        },
        { 
          header: Utils.checkNull(income.numberOfOtherIncomePayments),
          detail: 'No. Of Other Income Payments'
        }
    ]} />
  } 
  else if(configuration.featureFlags.isFrequencyOfSalaryPaymentEnabled === false && configuration.featureFlags.isMissingSalaryPaymentEnabled === true){
    detailPaymentContent = <List listClass={'st-details-column-list'} listContainerClass={'st-details-list-main-container'}  
      detailClass="text-medium" listContent={[
        {
          header: Utils.checkNull(income.numberOfSalaryPayments),
          detail: 'No. Of Salary Payments'
        },
        {
          header: Utils.checkNull(income.expectedSalaryPaymentDay),
          detail: 'Expected Salary Payment Day'
        },
        {
          header: Utils.formatLongDate(Utils.formatDate(income.lastDateOfSalaryPayment)),
          detail: 'Latest Salary Date'
        },
        { 
          header: Utils.checkNull(income.numberOfOtherIncomePayments),
          detail: 'No. Of Other Income Payments'
        },
        { 
          header: Utils.checkNull(income.missingSalaryPayment),
          detail: 'Missing Salary Payment'
        }
    ]} />
  }
  else if(configuration.featureFlags.isFrequencyOfSalaryPaymentEnabled === false && configuration.featureFlags.isMissingSalaryPaymentEnabled === false){
    detailPaymentContent = <List listClass={'st-details-column-list'} listContainerClass={'st-details-list-main-container'}  
    detailClass="text-medium" listContent={[
      {
        header: Utils.checkNull(income.numberOfSalaryPayments),
        detail: 'No. Of Salary Payments'
      },
      {
        header: Utils.checkNull(income.expectedSalaryPaymentDay),
        detail: 'Expected Salary Payment Day'
      },
      {
        header: Utils.formatLongDate(Utils.formatDate(income.lastDateOfSalaryPayment)),
        detail: 'Latest Salary Date'
      },
      { 
        header: Utils.checkNull(income.numberOfOtherIncomePayments),
        detail: 'No. Of Other Income Payments'
      }
  ]} />
  }

  const prepareIncomeTables = (array, newArray) => {
    array.forEach(element => {
      let date = element['month-name'] + " " + element.year;
      let hiddenDate = element.year + '/' + element['month-name'] + '/01';

      newArray.push({
        date: date,
        hiddenDate: Utils.formatDate(hiddenDate),
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)),
        description: element.description,
      });
    });

    newArray.sort((first, second) => new Date(second.hiddenDate) - new Date(first.hiddenDate));
  }

  if(!Utils.isFieldEmpty(income.salaryTransactions) && configuration.featureFlags.isSalaryAndOtherIncomeTransactionsEnabled === true) {
    prepareIncomeTables(income.salaryTransactions, salaryTransactionsTable);
  }

  if(!Utils.isFieldEmpty(income.otherIncomeTransactions) && configuration.featureFlags.isSalaryAndOtherIncomeTransactionsEnabled === true) {
    prepareIncomeTables(income.otherIncomeTransactions, otherTransactionsTable);
  }
    
  return (
    <div className="st-details-card-container">
      <div className="st-details-card-container-row st-analytics-income" >
        <DetailsCard title="Income" titleClass="detail-header" subtitle={"Salary and Other Income"} subtitleClass="text-medium" 
          separator={true} detailContent={detailIncomeContent} separatorClass="separator-light" detailClassName={'st-column-list-container'} />
        <DetailsCard title="Payments" titleClass="detail-header" detailContent={detailPaymentContent} subtitle={"All payments made"}
          subtitleClass="text-medium" detailClassName={'st-column-list-container'} separatorClass="separator-light" separator={true} />
      </div>
      {salaryTransactionsTable.length > 0 &&
        <div className="st-details-scroll-card st-analytics-income">
          <div className='cards details-table-container'>
            <h2 className="detail-header">Salary Transactions</h2> 
            <Table data={salaryTransactionsTable} columns={theadersTransactions} rowClass={"table-row-two-colored"}
              tableFilter={false} tableExtra={true} />
          </div>
        </div>
      }
      {otherTransactionsTable.length > 0 &&
        <div className="st-details-scroll-card st-analytics-income">      
          <div className='cards details-table-container'>
            <h2 className="detail-header">Other Transactions</h2> 
            <Table data={otherTransactionsTable} columns={theadersTransactions} rowClass={"table-row-two-colored"}
              tableFilter={false} tableExtra={true} />
          </div>
        </div>
      }
    </div>
  )
}

export default Income;