import React, { useState } from "react";
import Tabs from '../../components/Tabs/Tabs';
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import BankAccount from "./BankAccount/BankAccount";
import LendingAccount from "./LendingAccount/LendingAccount";
import List from "../../components/List/List";
import LogoContainer from "../../components/LogoContainer/LogoContainer";
import Utils from "../../library/Utils";
import { MobileBankAccountType } from "../../library/Variables";

function Accounts(props) {
  let tabBodiesAccounts = [];
  let banksContent = null;
  let lendingContent = null;

  const account = props.accounts;
  const configuration = props.configuration;
  const accessToken = props.accessToken;
  const [activeTab, setActiveTab] = useState('');
  const [accountInfo, setAccountInfo] = useState({ name: '', senderKey: 0, accountKey: 0, accountName: '', accountNumber: 0 });

  if(!Utils.isFieldEmpty(account.banks) && (!Utils.isFieldEmpty(account.banks.banks))) {
    banksContent = <div className="mb-account-tab-content">
      {account.banks.banks.map((element, i) => {
        let bankAccounts = [];

        element.accounts.forEach(acc => {
          bankAccounts.push({ 
            header: acc.accountName, 
            detail: acc.accountNumber, 
            link: () => { 
              setAccountInfo({ 
                name: element.bankName, 
                senderKey: element.key, 
                accountKey: acc.key, 
                accountName: acc.accountName, 
                accountNumber: acc.accountNumber }) 
              setActiveTab(MobileBankAccountType.BANK_ACCOUNT); 
            },
          });
        });

        return <DetailsCard title={element.bankName.toUpperCase()} titleClass="detail-header-white" key={i}
          headerExtra={<LogoContainer name={element.bankName} />}
          detailContent={<>
            <h3>Accounts</h3>
            <p>Click on each account to view analysis</p>
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} listContent={bankAccounts} />
          </>} detailClassName={'mb-account-list-container'}
          />
        })}
    </div>  

    tabBodiesAccounts.push(
      {
        title: 'Bank Accounts',
        content: banksContent
      }
    )
  }

  if(!Utils.isFieldEmpty(account.lenders) && (!Utils.isFieldEmpty(account.lenders.lenders))) {
    lendingContent = 
      <div className="mb-lenders-tab-content">
        <h2 className="detail-header">Click on each account to see a list of SMS</h2>
        <div className="mb-account-tab-content lending">
          {account.lenders.lenders.map(lender => {
            return <DetailsCard headerExtra={<LogoContainer name={lender.lenderName} /> }
              title={lender.lenderName.toUpperCase()} titleClass="detail-header-white"
              headerLink={() => { 
                setAccountInfo({ 
                  senderKey: lender.key, 
                  name: lender.lenderName });
                setActiveTab(MobileBankAccountType.LENDING_ACCOUNT); 
              }} 
            />
          })}
        </div>
      </div>

    tabBodiesAccounts.push(
      {
        title: 'Lending Accounts',
        content: lendingContent
      }
    )
  }

  return (
    activeTab === MobileBankAccountType.BANK_ACCOUNT
    ? <BankAccount accountInfo={accountInfo} parentCallback={() => setActiveTab(null)} configuration={configuration} accessToken={accessToken} />
    : activeTab === MobileBankAccountType.LENDING_ACCOUNT
      ? <LendingAccount accountInfo={accountInfo} parentCallback={() => setActiveTab(null)} accessToken={accessToken} />
      : <div className="mb-tab-details">
          {!Utils.isFieldEmpty(account.banks.banks) && Utils.isFieldEmpty(account.lenders.lenders)
            ? <>
                <h2 className="detail-header">Bank Accounts</h2>
                {banksContent}
              </> 
            : Utils.isFieldEmpty(account.banks.banks) && !Utils.isFieldEmpty(account.lenders.lenders)
              ? <>
                  <h2 className="detail-header">Lenders</h2>
                  {lendingContent}
                </> 
              : <Tabs tabBodies={tabBodiesAccounts} extraClass='analytics-tabs' background={true} />
          }
        </div>
  )
}

export default Accounts;