import Spinner from "../../../svg/Spinner/Spinner";
import "../Modal.css";
import "./UserManagement.css";
import { FetchDataStatus } from "../../../library/Variables";
import { deleteUserAsync, resetDeleteUser } from "../../../redux/Slices/UserManagement";
import { useDispatch } from "react-redux";

function DeleteUser(props) {
  let content = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const userManagement = props.userManagement;
  const user = props.user;

  const handleDeleteUser = () => {
    dispatch(deleteUserAsync({
      accessToken: accessToken,
      userKey: user.id
    }))
  }

  const handleResetDeleteUser = () => {
    dispatch(resetDeleteUser());
  }

  if(userManagement.deleteUserStatus === FetchDataStatus.INITIAL) {
    content = <div className="user-management-form-container">
      <div className="modal-body-container centered-container">
        <p>Are you sure?</p>
      </div>
      <div className="submit-container">
        <button onClick={props.close}>Cancel</button>
        <button className="delete-user-button" onClick={() => handleDeleteUser()}>Delete</button>
      </div>
    </div>
  } else if(userManagement.deleteUserStatus === FetchDataStatus.FETCHING) {
    content = <Spinner />
  } else if(userManagement.deleteUserStatus === FetchDataStatus.SUCCESS) {
    content = <div className="modal-body-container centered-container">
      <div className="modal-body-message">
        <p>User has been deleted</p>
      </div>
      <div className="submit-container">
        <button onClick={props.close} >Close</button>
      </div>
    </div>
  } else if(userManagement.deleteUserStatus === FetchDataStatus.FAILURE) {
    content = <div className="modal-body-container centered-container">
      <p>Unable to delete user</p>
      <div className="submit-container">
        <button onClick={() => handleResetDeleteUser()}>Try again</button>
      </div>
    </div>
  }
  
  return(
    <div className="modal-dialog user-management-modal delete-user">
      <div className="modal-header header-single">
        <h2 className="text-warning">Delete User</h2>
      </div>
      {content}
    </div>
  )
}

export default DeleteUser;