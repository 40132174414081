import React, { useMemo } from "react";
import Utils from "../../../library/Utils";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import { Table } from "../../../components/Table/Table";
import List from "../../../components/List/List";

function CashFlow(props) {
  let monthlyInflowTable = [];
  let monthlyOutflowTable = [];
  let weeklyInflowTable = [];
  let weeklyOutflowTable = [];
  let monthlyFlowsContent = null;
  let weeklyFlowsContent = null;
  
  const configuration = props.configuration;
  const cashFlow = props.data;
  
  const theadersMonthlyFlows = useMemo(() => [
    { 
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true
    },
    { 
      Header: 'Amount',
      accessor: 'amount',
      disableSortBy: true
    }], []
  )

  const theadersWeeklyFlows = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
    },
    {
      Header: 'Week',
      accessor: 'week',
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      disableSortBy: true,
    }], []
  )

  /* Monthly Flows */
  if(!Utils.isFieldEmpty(cashFlow.monthlyInflow)) {
    cashFlow.monthlyInflow.forEach(element => {
      monthlyInflowTable.push({
        date: element["month_name"] + ' ' + element.year,
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)),
      });
    });
  }

  if(!Utils.isFieldEmpty(cashFlow.monthlyOutflow)) {
    cashFlow.monthlyOutflow.forEach(element => {
      monthlyOutflowTable.push({
        date: element["month_name"] + ' ' + element.year,
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)),
      });
    });
  }

  if((!Utils.isFieldEmpty(monthlyInflowTable)) || (!Utils.isFieldEmpty(monthlyOutflowTable))) {
    monthlyFlowsContent = <div className="st-details-card-container-row st-details-table-row table-two-col" >
      <div className="st-details-scroll-card">
        <div className='cards details-table-container'>
          <h2 className="detail-header">Monthly Outflow</h2>
          {!Utils.isFieldEmpty(monthlyOutflowTable)
            ? <Table data={monthlyOutflowTable} columns={theadersMonthlyFlows} tableFilter={false} tableExtra={true} rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
      <div className="st-details-scroll-card">      
        <div className='cards details-table-container'>
          <h2 className="detail-header">Monthly Inflow</h2> 
          {!Utils.isFieldEmpty(monthlyInflowTable)
            ? <Table data={monthlyInflowTable} columns={theadersMonthlyFlows} tableFilter={false} tableExtra={true} rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
    </div>
  }

  /* Weekly Flows */
  if (!Utils.isFieldEmpty(cashFlow.weeklyInflow)) {
    cashFlow.weeklyInflow.forEach(element => {
      weeklyInflowTable.push({
        date: element["month_name"] + ' ' + element.year,
        week: element.week,
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)),
      });
    });
  }

  if (!Utils.isFieldEmpty(cashFlow.weeklyOutflow)) {
    cashFlow.weeklyOutflow.forEach(element => {
      weeklyOutflowTable.push({
        date: element["month_name"] + ' ' + element.year,
        week: element.week,
        amount: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.amount)),
      });
    });
  }

  if(!Utils.isFieldEmpty(weeklyInflowTable) || !Utils.isFieldEmpty(weeklyOutflowTable)) {
    weeklyFlowsContent = <div className="st-details-card-container-row st-details-table-row table-align-right-last-row" >
      <div className="st-details-scroll-card">
        <div className='cards details-table-container'>
          <h2 className="detail-header">Weekly Outflow</h2>
          {!Utils.isFieldEmpty(weeklyOutflowTable)
            ? <Table data={weeklyOutflowTable} columns={theadersWeeklyFlows} tableFilter={false} tableExtra={true} rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
      <div className="st-details-scroll-card">      
        <div className='cards details-table-container'>
          <h2 className="detail-header">Weekly Inflow</h2> 
          {!Utils.isFieldEmpty(weeklyInflowTable)
            ? <Table data={weeklyInflowTable} columns={theadersWeeklyFlows} tableFilter={false} tableExtra={true} rowClass={"table-row-two-colored"} />
            : <div className='st-details-no-table-data-container'>
                <h4>No table data to show</h4>
              </div>
          }
        </div>
      </div>
    </div>
  }
    
  return (
    <div className="st-details-card-container cash-flow-content">
      <div className="st-details-card-container-row st-details-cash-top-row">
        <DetailsCard subtitle="Valid Credit" titleClass="detail-header"
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.validCredit))} />
        <DetailsCard subtitle={"Closing Balance"} titleClass="detail-header"
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.closingBalance))} />
        <DetailsCard subtitle={"Average Monthly Balance"} titleClass="detail-header"
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageMonthlyBalance))} />
        <DetailsCard subtitle={"Average Weekly Balance"} titleClass="detail-header" 
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageWeeklyBalance))} />
        <DetailsCard subtitle={"Expense Over Inflow"} titleClass="detail-header" 
          title={Utils.formatPercentage(cashFlow.percentageOfExpenseOverInflow)} />
      </div>
      <div className="st-details-card-container-row st-details-double-colored-bottom-row">
        <DetailsCard subtitle={'Total Turnover'} detailClassName={'st-detail-color-background-container'} headerExtra={<p>Debits</p>}
          title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.totalDebitTurnOver))}
          titleClass="detail-header" detailContent={<>
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              { header: 'Average Weekly Debits', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageWeeklyDebits))},
              { header: 'Average Monthly Debits', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageMonthlyDebits))}
            ]} />
        </>} />
        <DetailsCard subtitle={'Total Turnover'} detailClassName={'st-detail-color-background-container'} headerExtra={<p>Credits</p>}
          titleClass="detail-header" title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.totalCreditTurnover))}
          detailContent={<>
          <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
            listContent={[
              { header: 'Average Weekly Credits', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageWeeklyCredits))},
              { header: 'Average Monthly Credits', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, cashFlow.averageMonthlyCredits))}
            ]} />
        </>} />
      </div>
      {monthlyFlowsContent}
      {weeklyFlowsContent}
    </div>
  )
}

export default CashFlow;