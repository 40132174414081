import React from 'react'
import { reAssignLoanAgents,selectLoanManagement } from '../../../../redux/Slices/Loans/LoanManagement/LoanManagement';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../library/Variables';
import { resetLoanFilter } from '../../../../redux/Slices/Loan';


function AssignmentModal(props) {
    const dispatch = useDispatch()
    const loanManagementState = useSelector(selectLoanManagement)
    const accessToken = props.accessToken


    let content = null;
    const params = {
        files:props.imgFile,
        accessToken,
    }

    const successDone = ()=>{
        props.closeModal()
        dispatch(resetLoanFilter())
    }

  

 

    if(loanManagementState.reAssignAgentStatus===FetchDataStatus.FETCHING){
 

        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Re-assigning...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanManagementState.reAssignAgentStatus===FetchDataStatus.SUCCESS){
      
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Agent Succesfully reassigned</p>
            <div className='buttonHold'>   
                <section onClick={()=>successDone()}>Done!</section>
            </div> 
        </div>
    }

    if(loanManagementState.reAssignAgentStatus===FetchDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanManagementState.errorMessageFormUpload}</p>

            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal()}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default AssignmentModal