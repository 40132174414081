import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

export const getKeyCloakToken = createAsyncThunk('getKeyCloakToken', async (params) => {
  let data = {
    client_id: params.client_id,
    username: params.username,
    password: params.password,
    grant_type: params.grant_type
  };
  
  const token = await client.postKeycloak(`${params.base_url}/realms/${params.realm}/protocol/openid-connect/token`,data, null);
  //const token = await client.postKeycloak(`${baseLink}/realms/${params.realm}/protocol/openid-connect/token`,data, null);

  return token.data;
});

export const addNewUserAsync = createAsyncThunk('newUser', async (params) => {
  let data = {
    username: params.username,
    password: params.password
  };

  const newUser = await client.post(`/Application/create-keycloak-user`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

   return newUser.data;
  }
);


export const resetPasswordAsync = createAsyncThunk('resetPassword', async (params) => {
  let data = {
    email: params.email,
    tenant:params.tenant
  };

  const resetPassword = await client.postSelfService(`/onboard/forgot-password`, data, null);

   return resetPassword.data;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: false,
    loggingIn: false,
    accessToken: '',
    keyCloakTokenStatus: FetchDataStatus.INITIAL,
    adNewUserStatus: PostDataStatus.INITIAL,
    resetPasswordStatus: PostDataStatus.INITIAL,
    keyCloakToken: {},
    isError: false,
    errorMessage: null
  },
  reducers: {
    reset: state => {
      state.loggingIn = false;
      state.keyCloakTokenStatus = FetchDataStatus.INITIAL;
      state.adNewUserStatus = PostDataStatus.INITIAL;
      state.resetPasswordStatus = PostDataStatus.INITIAL;
      state.keyCloakToken = {};
      state.isError = false;
      state.errorMessage = null;
    },

    resetResetPassWord: {
      reset: state => {
        state.resetPasswordStatus = PostDataStatus.INITIAL;
      },
    },

    loggingIn: state => {
      state.loggingIn = true;
      state.keyCloakTokenStatus = FetchDataStatus.FETCHING;
      state.keyCloakToken = {};
      state.isError = false;
      state.errorMessage =  null
    },
    failedLogin: state => {
      state.loggedIn = false;
      state.loggingIn = false;
      state.accessToken = '';
      state.keyCloakTokenStatus = FetchDataStatus.FAILURE;
      state.keyCloakToken = null;
      state.isError = true;
      state.errorMessage =  ""
    },
    loggedIn: (state, action) => {
      state.loggedIn = true;
      state.loggingIn = false;
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.keyCloakTokenStatus = FetchDataStatus.SUCCESS;
      state.keyCloakToken = action.payload;
      state.isError = false;
      state.errorMessage =  null
    },
    logout: state => {
      localStorage.removeItem('insightsAccessToken');
      localStorage.removeItem('insightsAccessExpiry');
      localStorage.removeItem('insightsLoggedInTime');
      state.loggedIn = false;
      state.loggingIn = false;
      state.accessToken = '';
      state.keyCloakTokenStatus = FetchDataStatus.INITIAL;
      state.keyCloakToken = null;
      state.isError = false;
      state.errorMessage =  null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getKeyCloakToken.pending, (state) => {
      state.keyCloakTokenStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getKeyCloakToken.fulfilled, (state, action) => {      
      state.keyCloakTokenStatus = FetchDataStatus.SUCCESS;
      state.keyCloakToken = action.payload;
    })
    .addCase(getKeyCloakToken.rejected, (state, action) => {
      state.keyCloakTokenStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(addNewUserAsync.pending, (state) => {
      state.adNewUserStatus = PostDataStatus.FETCHING;
    })
    .addCase(addNewUserAsync.fulfilled, (state) => {      
      state.adNewUserStatus = PostDataStatus.SUCCESS;
    })
    .addCase(addNewUserAsync.rejected, (state, action) => {
      state.adNewUserStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(resetPasswordAsync.pending, (state) => {
      state.resetPasswordStatus = PostDataStatus.FETCHING;
    })
    .addCase(resetPasswordAsync.fulfilled, (state) => {      
      state.resetPasswordStatus = PostDataStatus.SUCCESS;
    })
    .addCase(resetPasswordAsync.rejected, (state, action) => {
      state.resetPasswordStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loggedIn, loggingIn, logout, failedLogin, reset,resetResetPassWord } = authSlice.actions;

export const selectLoggedIn = state => state.auth.loggedIn;
export const selectLoggingIn = state => state.auth.loggingIn;
export const selectAccessToken = state => state.auth.accessToken;
export const selectRefreshTokenCheckInterval = state => state.auth.refreshTokenCheckInterval;
export const selectkeycloakTokenState = state => state.auth;
export const selectaddNewUserState = state => state.auth;
export const selectresetPasswordState = state => state.auth;

export default authSlice.reducer;