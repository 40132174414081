import classNames from "classnames";
import { forwardRef, useId } from "react";

import ErrorDisplay from "./ErrorDisplay";
import styles from "./index.module.css";

export const Input = forwardRef((props, ref) => {
  if (props.as) {
    return (
      <textarea
        {...props}
        ref={ref}
        className={classNames(
          styles.input,
          props.isFullWidth && styles.fullWidth,
          props.className
        )}
      ></textarea>
    );
  }
  return (
    <input
      {...props}
      ref={ref}
      className={classNames(
        styles.input,
        props.isFullWidth && styles.fullWidth,
        props.className
      )}
    />
  );
});

export const InputField = forwardRef((props, ref) => {
  const id = useId();
  const {
    inputIsFullWidth,
    name,
    className,
    type,
    labelClassName,
    placeholder,
    showAsteriskIndicator,
    addOnLeading,
    addOnSuffix,
    readOnly,
    disabled,
    inputContainer,
    ...passThrough
  } = props;

  return (
    <>
      <div className={classNames(styles.inputContainer, inputContainer)}>
        <Input
          isFullWidth={inputIsFullWidth}
          id={id}
          type={type}
          placeholder={placeholder}
          {...passThrough}
          ref={ref}
          disabled={readOnly || disabled}
          className={className}
        />
      </div>
      <ErrorDisplay fieldName={props.name} />
    </>
  );
});
export const TextField = forwardRef((props, ref) => {
  return <InputField ref={ref} {...props} />;
});
