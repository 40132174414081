import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

 export const getAffordabilityDetails = createAsyncThunk('affordability', async (params) => {
   const affordability = await client.get(`/affordability/${params.keyFromRoute}`,
   {
     "headers":
     {
       "Authorization": "Bearer " + params.accessToken
     }
   });

    return affordability.data;
   }
 );

export const newAffordabilityModelAsync = createAsyncThunk('newAffordability', async (params) => {
  let data = {
    dti: params.dti,
    loanTenure: params.duration,
    statementKey: params.keyFromRoute,
	  averageMonthlyLoanRepaymentAmount: params.avgLoanRepay
  }
  data = JSON.stringify(data)
  const affordability = await client.post(`/affordability`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
if(!affordability?.data){
  return affordability
}
else{
  return affordability.data;
}
  }
);

export const affordabilitySlice = createSlice({
  name: 'affordability',
  initialState: {
    activeAffordability: { },
    affordabilityDetailsStatus: FetchDataStatus.INITIAL,
    addAffordabilityStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetAffordability: state => {
      state.activeAffordability = { };
      state.affordabilityDetailsStatus = FetchDataStatus.INITIAL;
      state.addAffordabilityStatus = PostDataStatus.INITIAL;
    },
    resetAffordabilityStatus: state => {
      state.addAffordabilityStatus = PostDataStatus.INITIAL;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAffordabilityDetails.pending, (state) => {
      state.affordabilityDetailsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAffordabilityDetails.fulfilled, (state, action) => {      
      state.affordabilityDetailsStatus = FetchDataStatus.SUCCESS;
      state.activeAffordability = action.payload;
    })
    .addCase(getAffordabilityDetails.rejected, (state, action) => {
      state.affordabilityDetailsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(newAffordabilityModelAsync.pending, (state) => {
      state.addAffordabilityStatus = PostDataStatus.FETCHING;
    })
    .addCase(newAffordabilityModelAsync.fulfilled, (state) => {      
      state.addAffordabilityStatus = PostDataStatus.SUCCESS;
    })
    .addCase(newAffordabilityModelAsync.rejected, (state, action) => {
      state.addAffordabilityStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, affordability, resetAffordability, resetAffordabilityStatus } = affordabilitySlice.actions;

export const selectAffordabilityState = state => state.affordability;

export const selectAddAffordabilityStatus = state => state.affordability.addAffordabilityStatus;

export default affordabilitySlice.reducer;