import React from 'react'
import agentAvatarIcon from '../../../../../assests/icons/agentAvatar.png'
import DynamicCard from '../DynamicCard'
import { businessDetailsData, personalDetailsData } from '../PersonalDetails'

function AgentInfoSlide() {
  return (
    <section style={{width:"100%", height:'fit-content',padding:"30px",display:"flex",flexDirection:"column",rowGap:"20px",boxSizing:"border-box"}}>
            <div style={{display:"flex",justifyContent:"left",columnGap:"10px"}}>
                <div className='flexCenter'>
                    <img src={agentAvatarIcon}/>
                </div>
                <div style={{display:"flex",flexDirection:"column",rowGap:"5px",justifyContent:"space-around"}}>
                    <span className='AgentName'>
                        Emmanuel Job
                    </span>
                    <span className='AgentFirm'>
                        Starships Ventures
                    </span>
                </div>
                <div className='AgentButtonHolder'>
                    <span className='AgentButton'>
                        Agent
                    </span>
                </div>
            </div>

            <DynamicCard data={personalDetailsData}/>

            <DynamicCard data={businessDetailsData}/>
        </section>
  )
}

export default AgentInfoSlide