import Utils from "../../../../library/Utils";


export const CrbHistoryMainSerializer = (arr)=>{
    let crbHistoryReshaped = []
    for (let i=0;i<arr.length;i++){
        if(arr[i].creditType === 'ghana_commercial_credit_report'){
            const newObj = {}
            newObj.createdDate = !Utils.isFieldEmpty(arr[i].createdDate)?arr[i].createdDate:'--'
            newObj.IDNUMBER = !Utils.isFieldEmpty(arr[i].bvn)?arr[i].bvn:'--'
            newObj.reportType = !Utils.isFieldEmpty(arr[i]?.reportType)?arr[i]?.reportType:'--'
            newObj.creditType = 'COMMERCIAL CREDIT'
            newObj.reference = arr[i]?.reference
            newObj.status = arr[i].status

            crbHistoryReshaped.push(newObj)
        }

        else{
            if(arr[i].status ==='pending'){
                const newObj = {}
            newObj.createdDate = !Utils.isFieldEmpty(arr[i].createdDate)?arr[i].createdDate:'--'
            newObj.IDNUMBER = '--'
            newObj.reportType = !Utils.isFieldEmpty(arr[i]?.reportType)?arr[i]?.reportType:'--'
            newObj.creditType = 'CONSUMER CREDIT'
            newObj.reference = arr[i]?.reference
            newObj.status = arr[i].status

            crbHistoryReshaped.push(newObj)
            }

            if(arr[i].status === 'found'){
                if(Utils.isFieldEmpty((arr[i].ghanaConsumerCreditData?.personalDetailsSummary))){
                    const newObj = {}
                    newObj.createdDate = !Utils.isFieldEmpty(arr[i].createdDate)?arr[i].createdDate:'--'
                    newObj.IDNUMBER = '--'
                    newObj.reportType = !Utils.isFieldEmpty(arr[i]?.reportType)?arr[i]?.reportType:'--'
                    newObj.creditType = 'CONSUMER CREDIT'
                    newObj.reference = arr[i]?.reference
                    newObj.status = arr[i].status

                    crbHistoryReshaped.push(newObj)
                }

                else{

                    const newObj = {}
                    newObj.createdDate = !Utils.isFieldEmpty(arr[i].createdDate)?arr[i].createdDate:'--'
                    newObj.IDNUMBER = !Utils.isFieldEmpty(arr[i].ghanaConsumerCreditData
                        .personalDetailsSummary.cellularNo)
                        ?arr[i].ghanaConsumerCreditData
                        .personalDetailsSummary.cellularNo
                        :'--'
                    newObj.reportType = !Utils.isFieldEmpty(arr[i]?.reportType)?arr[i]?.reportType:'--'
                    newObj.creditType = 'CONSUMER CREDIT'
                    newObj.reference = arr[i]?.reference
                    newObj.status = arr[i].status
        
                    crbHistoryReshaped.push(newObj)
                }
            }
            
        }
    }

        return crbHistoryReshaped

    
}



/**
 * newObj.IDNUMBER = !Utils.isFieldEmpty(arr[i].ghanaConsumerCreditData
                               .personalDetailsSummary.cellularNo)
                               ?arr[i].ghanaConsumerCreditData
                               .personalDetailsSummary.cellularNo
                               :'--'
 */