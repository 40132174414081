import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { getSpecificAml,selectKenyaAmlExtractions } 
    from '../../../../../redux/Slices/IdVerification/Kenya/IdVerificationKenyaAml'
import errorImg from '../../../../../assests/icons/errorImage.png'
import './amlProceed.css'
import ScreenedInformation from './ScreeningComponents/ScreenedInformation'
import SourceWebLinks from './ScreeningComponents/Adverse Media/SourceWebLinks'
import { selectAmlHistorydata } from '../../../../../redux/Slices/IdVerification/Kenya/AmlHistory'
import { PostDataStatus, FetchDataStatus } from '../../../../../library/Variables'

function AmlProceed({accessToken,setContentToShow}) {
  const dispatch = useDispatch()
  const AmlIndividualData = useSelector(selectKenyaAmlExtractions)
  const amlRecordData = useSelector(selectAmlHistorydata)
  const [selectedIndex,setSelectedIndex] = useState(0)
  const [headerSelected,setHeaderSelected] = useState('information')
  const [amlDataHouse,setAmlDataHouse] = useState()
  const [resulList,setResultList] = useState([])
  let screeningDetails;
 

    useEffect(() => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    }, []);

    useEffect(()=>{
        if(AmlIndividualData.amlExtractionStatus === PostDataStatus.SUCCESS){
            setAmlDataHouse(AmlIndividualData.amlExtraction.entity.MatchResults)
            setResultList(AmlIndividualData.amlExtraction.entity.MatchResults)
            dispatch(getSpecificAml(
                {key:AmlIndividualData.amlExtraction.entity.MatchResults[0].ProfileId,accessToken}))
            setSelectedIndex(AmlIndividualData.amlExtraction.entity.MatchResults[0].ProfileId)
        }
        if(amlRecordData.activeHistoryRecordStatus === FetchDataStatus.SUCCESS){
            setAmlDataHouse(amlRecordData.activeHistoryRecord.entity.MatchResults)
            setResultList(amlRecordData.activeHistoryRecord.entity.MatchResults)
            dispatch(getSpecificAml(
                {key:amlRecordData.activeHistoryRecord.entity.MatchResults[0].ProfileId,accessToken}))
            setSelectedIndex(amlRecordData.activeHistoryRecord.entity.MatchResults[0].ProfileId)
        }
    },[AmlIndividualData.amlExtractionStatus,amlRecordData.activeHistoryRecordStatus])

  

    const onGetNewDetail = (index)=>{
            setSelectedIndex(resulList[index].ProfileId)
            dispatch(getSpecificAml({key:resulList[index].ProfileId,accessToken}))
    }

    const onChangeList = (text)=>{
        const filteredObjects = amlDataHouse.filter(obj => {
            // Check if the text is present in the sentence
            return obj.Name.toLowerCase().includes(text.toLowerCase());
          });
        
          //return filteredObjects;
          setResultList(filteredObjects)
    }

    if(AmlIndividualData.amlParticipantExtractionStatus === FetchDataStatus.FETCHING){
        screeningDetails = <Spinner/>
                
    }


    const screeningHeader = <section style={{width:"100%",marginBottom:"30px"}}>
                                <section className='screenHeader'>
                                    <span onClick={()=>setHeaderSelected('information')} className={headerSelected==='information'?'selected':'unselected'}>
                                        Information
                                    </span>

                                    <span onClick={()=>setHeaderSelected('adverseMedia')} className={headerSelected==='adverseMedia'?'selected':'unselected'}>
                                        Adverse Media
                                    </span>
                                </section>
                            </section>

    if(AmlIndividualData.amlParticipantExtractionStatus === FetchDataStatus.SUCCESS){
            screeningDetails = <div style={{display:"flex",flexDirection:"column",height:"auto",justifyContent:"space-around",alignItems:"center",width:"100%"}}>
            
                                {headerSelected==='information'? 
                                    <ScreenedInformation data={AmlIndividualData.amlParticipantExtraction.entity.result}/>
                                    :<SourceWebLinks sourceLinks={AmlIndividualData.amlParticipantExtraction.entity.result.sourceWebLinks}/>
                                }

                                        
                                </div>
        
    
    }

    if(AmlIndividualData.amlParticipantExtractionStatus === FetchDataStatus.FAILURE){
        screeningDetails =<div style={{display:"flex",flexDirection:"column",height:"200px",justifyContent:"space-around",alignItems:"center"}}>
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",padding:"5px"}}>
            <img style={{width:"30px",height:"30px"}} src={errorImg}/>
        </span>
        <p style={{color:'#363C4F'}}>Something went wrong!</p>
        <p style={{color:"red",fontWeight:"700",fontSize:"24px"}}>{AmlIndividualData.amlParticipantExtractionErrorMessage}</p>
    </div>
    }


  return (
    <div style={{display:"flex",justifyContent:"space-between",columnGap:"3%",padding:"20px 35px",boxSizing:"border-box"}}>
        <section style={{width:'27%',display:"flex",flexDirection:"column",overflowX:'hidden',boxSizing:"border-box",justifyContent:"space-between",alignItems:"center"}}>
            <section onClick={()=>setContentToShow('main')} style={{width:"100%",textAlign:"left",marginBottom:"15px",paddingLeft:"5px",cursor:"pointer",boxSizing:"border-box"}}>Back</section>
            <section style={{textAlign:"left",width:"100%",marginBottom:"30px"}}>
                <input onChange={(e)=>onChangeList(e.target.value)} type='text' placeholder='input text' style={{outline:"none",border:"0.5px solid white",height:"40px",width:"250px",paddingLeft:"10px"}}/>
            </section>
            <section style={{display:"flex",flexDirection:"column",width:"100%",height:"90vh",overflow:"auto",overflowX:'hidden'}}>
                {resulList.map((element,index)=>
                    <section key={index} onClick={()=>onGetNewDetail(index)} style={{display:"flex",width:"100%",cursor:"pointer",flexDirection:"column",
                                        backgroundColor:selectedIndex===element.ProfileId?'white':"",alignItems:"flex-start",rowGap:"10px",
                                        marginBottom:'35px',padding:"10px",boxSizing:"border-box",
                                        border:selectedIndex===element.ProfileId?"2px solid #2A38A4":"",
                                        borderWidth:selectedIndex===element.ProfileId?"0px 2px 0px 0px":""}}>
                        <span style={{color:'#1F2228',textAlign:"left",fontWeight:"500",fontSize:"20px"}}>{element.Name}</span>
                        <div >
                            <span style={{color:"#696969",textAlign:"left",fontSize:"16px"}}>MatchType</span>
                            &nbsp; &nbsp;
                            <span style={{color:"#696969",textAlign:"left",fontSize:"16px"}}>{element.MatchType}</span>
                        </div>

                        <div>
                            <span style={{color:"#696969",textAlign:"left",fontSize:"16px"}}>NameMatchScore</span>
                            &nbsp; &nbsp;
                            <span style={{color:"#696969",textAlign:"left",fontSize:"16px"}}>{element.NameMatchScore}</span>
                        </div>
                    </section>)}
            </section>
        </section>

        <section style={{width:"70%",height:"100vh"}}>
                {AmlIndividualData.amlParticipantExtractionStatus===FetchDataStatus.SUCCESS && screeningHeader}
                <div style={{backgroundColor:"white"}}>
                    {screeningDetails}
                </div>

                
        </section>
    </div>
  )
}

export default AmlProceed

/**
 * {(kybData.amlParticipantExtraction.entity.result.relationships.length>0 && kybData.amlParticipantExtractionStatus===FetchDataStatus.SUCCESS)
                 && <div style={{backgroundColor:"white"}}>
                        <ScreenedInfoRelationships data={kybData.amlParticipantExtraction.entity.result.relationships}/>
                </div>}
 */