import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import arrowLeft from '../../../assests/icons/arrowLeftNew.png'
import bankIcon from '../../../assests/icons/bankIconBlue.png'
import CreditListUi from '../AgencyComponentsHold/CreditListDataUi'
import AgentInfoSlide from './AgentFolders/TabSlides/AgentInfoSlide'
import TransactionAnalysis from './AgentFolders/TabSlides/TransactionAnalysis/TransactionAnalysis'
import './agentsBreakdown.css'


function AgentsBreakdown() {
    const navigate = useNavigate()
    const [tabState,setTabState] = useState('agentInfo')



  const changeAgentsBreakdown = (filter)=>{
        setTabState(filter)
  }

  return (
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section className='loanHead'>
            <section onClick={()=>navigate('/agencyBanking')} className='loanContent'>
                <span><img src={arrowLeft}/></span>
                <span style={{marginLeft:'10px'}}>Back to Applications</span>
            </section>
            <section className='loanContent'>
                <span><img src={bankIcon}/></span>
                <p>Agency Banking</p>
            </section>
        </section>

  

        <section className='filterHold'>
                <section onClick={()=>changeAgentsBreakdown('agentInfo') } className={tabState==='agentInfo'?'filterHeaderSelect':"filterHeaderNonSelect"}>Agent Information</section>
                <section onClick={()=>changeAgentsBreakdown('transactAnalysis') } className={tabState==='transactAnalysis'?'filterHeaderSelect':"filterHeaderNonSelect"}>Transaction Analysis</section>
                <section onClick={()=>changeAgentsBreakdown('creditHistory') } className={tabState==='creditHistory'?'filterHeaderSelect':"filterHeaderNonSelect"}>Credit History</section>
        </section>

        {tabState==='agentInfo'?
        <AgentInfoSlide/>:
        tabState==='transactAnalysis'?
        <TransactionAnalysis/>:
        <CreditListUi/>}

        
    </div>
  )
}

export default AgentsBreakdown