import React, { useEffect } from "react";
import {
  selectMobileInsightState,
  getLenderSms,
  resetActiveLenderAccountSms
} from '../../../redux/Slices/MobileInsight';
import { useParams } from "react-router-dom";
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import Spinner from "../../../svg/Spinner/Spinner";
import MobileSms from "./MobileSms";
import LogoContainer from "../../../components/LogoContainer/LogoContainer";
import "./LendingAccount.css";
import Utils from "../../../library/Utils";
import { FetchDataStatus } from "../../../library/Variables";

function LendingAccount(props) {
  let { key } = useParams();
  let keyFromRoute = window.location.href.split('/');

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const insight = useSelector(selectMobileInsightState);

  const params = { 
    accessToken: accessToken,
    key: key,
    senderKey: props.accountInfo.senderKey
  };

  keyFromRoute = keyFromRoute[keyFromRoute.length - 1];
  params.keyFromRoute = keyFromRoute;
  
  if(Utils.isFieldEmpty(insight.lenderSms) && insight.lenderSmsStatus === FetchDataStatus.INITIAL) {
    dispatch(getLenderSms(params));
  }

  useEffect(() => {
    if(insight && insight.lenderSmsStatus !== FetchDataStatus.INITIAL) {
      dispatch(resetActiveLenderAccountSms());
    }
  }, [])

  return (
    insight.lenderSmsStatus === FetchDataStatus.FETCHING || insight.lenderSmsStatus === FetchDataStatus.INITIAL
    ? <Spinner />
    : <div className="mb-lending-sms">
        <button onClick={() => props.parentCallback()} className="button-solid-darkest mb-back-button">
          🡨 Back to accounts
        </button>
        <div className="mb-lending-header-container">
          <LogoContainer name={props.accountInfo.name} />
          <div className="mb-lending-header-text-container">
            <h2>{props.accountInfo.name}</h2>
            <p className="text-medium">A list of all SMS sent</p>
          </div>
        </div>        
        <MobileSms sms={insight.lenderSms} />
      </div>
  )
}

export default LendingAccount;