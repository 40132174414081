import React,{useState,useEffect} from 'react'
import './kyc.css'
import logoHold from '../../../assests/icons/kycLogo.png'
import Modal from 'react-modal'
import ConfirmBox from '../../../components/Modals/ConfirmBox'
import BvnModal from './BvnHolder/BvnModal'
import NinModal from './NinHolder/NinModal'
import LicenseModal from './LicenseHolder/LicenseModal'
import PassportModal from './PassportHolder/PassportModal'
import { useSelector } from 'react-redux'
import { selectKycEntireDataState } from '../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../library/Variables'
import ExtractionHistory from './kycHistory/ExtractionHistory'
import ExtractionReport from './kycReporter/ExtractionReport'
import { getAllKycDataHistory } from '../../../redux/Slices/KycOptions'
import Spinner from '../../../svg/Spinner/Spinner'
import { useDispatch } from 'react-redux'
import Utils from '../../../library/Utils'

if (process.env.NODE_ENV !== "test") {
    Modal.setAppElement("#root");
  }


function Kyc(props) {
  const accessToken = props.accessToken
  
  const kycState = useSelector(selectKycEntireDataState)
  const [viewReport,setViewReport] = useState(false)
  const [historyArray,setHistoryArray] =  useState(kycState.activeExtractions)
  const keyFromRoute = props.keyFromRoute  
  const dispatch = useDispatch()


  const cardHolder = [{id:0,title:'BVN',
                      info:'This check requires BVN of the individual'},
                      {id:1,title:'NIN',
                      info:'This check requires NIN of the individual'},
                      {id:2,title:'Passport',
                      info:'This check requires the passport number'},
                      {id:3,title:'Driver’s License',
                      info:'This check requires license number'}]

  const [modalIsOpen, setIsOpen] = useState(false);

  const [kycToShow,setKycToShow] = useState(null)
  const [kycModal,setKycModal] = useState(null)
  const [reportData,setReportData] = useState(null)
  const [verificationType,setVerificationType] = useState()
  const customStyles = {
      content: {
          transition: "0.125s ease-in-out",
          transform: "scale(1)",
          height: "33%",
          width: "50%",
          top: "33%",
          left: "33%",
          padding: "0 0 0 0",
          overflowY: "scroll"
      },
      overlay: {
          zIndex: 1000,
          position: "absolute"
      }
      };

  const inputStyle = {
    width: "100%",
    marginTop: "10px",
    border: 'none',
    padding: '0px 24px 0px 24px',
    boxSizing: "border-box",
    backgroundColor: 'rgba(239, 244, 255, 1)',
    outline: "none",
    height: '40px'
  };

  function openModal(){
      setIsOpen(true);
    }
        
          function afterOpenModal(){}


  useEffect(()=>{
    if(kycState.extractionStatus===PostDataStatus.SUCCESS||kycState.historyStatus===PostDataStatus.SUCCESS){
      setHistoryArray(kycState.activeExtractions)
    }
  },[kycState.extractionStatus,kycState.historyStatus])


  useEffect(()=>{
   
      dispatch(getAllKycDataHistory({key:keyFromRoute,accessToken}))
    
      
  },[])
  
  const tableHeaders = ['Status','KYC Type','Created Date','Full Name','Tracking Number']
   
  return (
    <>
        
        <div>

            {kycModal}
            {kycToShow===null?null
            :kycToShow===0?<BvnModal keyFromRoute={keyFromRoute} accessToken={accessToken} setViewReport={setViewReport} customStyles={customStyles} setKycToShow={setKycToShow} inputStyle={inputStyle}/>:
            kycToShow===1?<NinModal keyFromRoute={keyFromRoute} accessToken={accessToken} setViewReport={setViewReport} customStyles={customStyles} setKycToShow={setKycToShow} inputStyle={inputStyle}/>:
            kycToShow===2?<PassportModal keyFromRoute={keyFromRoute} accessToken={accessToken} setViewReport={setViewReport} customStyles={customStyles} setKycToShow={setKycToShow} inputStyle={inputStyle}/>:
            kycToShow===3?<LicenseModal keyFromRoute={keyFromRoute} accessToken={accessToken} setViewReport={setViewReport} customStyles={customStyles} setKycToShow={setKycToShow} inputStyle={inputStyle}/>:''}
            <p className='title-class'>KYC Verification</p>
            <p className='title-subclass'>Select a KYC verification method below.</p>
        
            <div style={{display:"flex",flexWrap:'wrap',justifyContent:'space-between',alignItems:'center',marginTop:"30px"}}>{cardHolder.map(card=>
                <div onClick={()=>{setKycToShow(card.id)}} style={{display:"flex",margin:'10px 0px',cursor:"pointer",width:"280px",borderRadius:'4px',height:'130px',flexDirection:'column',justifyContent:"space-around",padding:"10px",boxSizing:'border-box',backgroundColor:"rgba(255, 255, 255, 1)"}} key={card.id}>
                    <p><img src={logoHold}/></p>
                    <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                    <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                </div>)}
            </div>
        
            {viewReport && <ExtractionReport verificationType={verificationType} viewReport={viewReport} setViewReport={setViewReport}/>}


        {(kycState.historyStatus==='POSTING' && !viewReport)?
            <div className="spinner-container">
                <Spinner></Spinner>
            </div>
          :
          (kycState.historyStatus==='SUCCESS' && historyArray.length===0 && !viewReport)?
          <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
            You currently have no historically extracted KYC document on this Statement.
          </div>:
          (kycState.historyStatus==='SUCCESS' && historyArray.length>0 && !viewReport)?
          <ExtractionHistory 
              setVerificationType={setVerificationType}
              setViewReport={setViewReport} viewReport={viewReport} 
              historyArray={historyArray} tableHeaders={tableHeaders}
              accessToken={accessToken}
              />:
          (kycState.historyStatus==='FAILURE' && !viewReport)?
          <div style={{width:'100%',color:'red',textAlign:'center',marginTop:'30px'}}>
            Something went wrong!.
          </div>:''}
        
              </div>
    </>
  )
}

export default Kyc

//{Object.keys(historyArray).map(key=> <p>{key}</p>)}

//<div>{historyArray.map(data=><p>{Object.keys(data).map(key=> <span>{key}</span>)}</p>)}</div>}

/**
 * 
            {historyArray.length>0 &&
            }
 */


  //{(historyArray.length>0 && !viewReport) && <ExtractionHistory setReportData={setReportData} setViewReport={setViewReport} viewReport={viewReport} historyArray={historyArray} tableHeaders={tableHeaders}/>}


  /**
   * <div className="spinner-container">
            <Spinner></Spinner>
          </div>
   */