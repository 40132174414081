import React from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import Utils from '../../../library/Utils';
import './Analytics.css';
import Behavioral from './Behavioral';
import CashFlow from './CashFlow';
import Income from './Income';
import Pattern from './Pattern';
import Spend from './Spend';

function Analytics(props) {
  let tabBodiesArray = [];
  let statement = props?.statement;

  const configuration = props.configuration;

  // Income Analysis
  if((statement.activeStatement.statementType).toUpperCase() !== "BUSINESS") {
    if(!Utils.isFieldEmpty(statement.activeStatement.incomeAnalysis)){
      let content = <Income data={statement.activeStatement.incomeAnalysis} configuration={configuration} />

      tabBodiesArray.push({
        title: 'Income',
        iconSrc: <img src='/assets/icons/income-color.svg' alt='' />,
        content: content
      });
    }
  }

  // Spend Analysis
  if(!Utils.isFieldEmpty(statement.activeStatement.spendAnalysis)){
    let content = <Spend data={statement.activeStatement.spendAnalysis} configuration={configuration} />

    tabBodiesArray.push({
      title: 'Spend',
      iconSrc: <img src='/assets/icons/spend-color.svg' alt='' />,
      content: content
    });
  } 

  // Pattern
  if(!Utils.isFieldEmpty(statement.activeStatement.transactionPatternAnalysis)){
    let content = <Pattern data={statement.activeStatement.transactionPatternAnalysis} agentLocation={statement.activeStatement.agentLocationAnalysis}
      configuration={configuration} />

    tabBodiesArray.push({
      title: 'Pattern',
      iconSrc: <img src='/assets/icons/pattern-color.svg' alt='' />,
      content: content
    }); 
  }
  
  // Behavioral Analysis
  if(!Utils.isFieldEmpty(statement.activeStatement.behavioralAnalysis)){
    let content = <Behavioral data={statement.activeStatement.behavioralAnalysis} configuration={configuration} />

    tabBodiesArray.push({
      title: 'Behavioral',  
      iconSrc: <img src='/assets/icons/behavior-color.svg' alt='' />,
      content: content
    });
  } 

  // Cash Flow Analysis
  if(!Utils.isFieldEmpty(statement.activeStatement.cashFlowAnalysis)){
    let content = <CashFlow data={statement.activeStatement.cashFlowAnalysis} configuration={configuration} /> 

    tabBodiesArray.push({
      title: 'Cash Flow',
      iconSrc: <img src='/assets/icons/cash-flow-color.svg' alt='' />,
      content: content
    });
  }

  return (
    <Tabs tabBodies={tabBodiesArray} extraClass='analytics-tabs' background={true} />
  )
}

export default Analytics;