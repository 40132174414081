import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectKybEntireDataState,resetKybData,getTinDataAsync,resetTinData } from '../../../../../redux/Slices/KybOptions'
import { PostDataStatus } from '../../../../../library/Variables'
import errorImage from '../../../../../assests/icons/errorImage.png'
import successImg from '../../../../../assests/icons/amlFound.png'
import './cacModal.css'
import { getReportDetailKyb,resetReportKyb } from '../../../../../redux/Slices/KybOptions'


function TinModal({accessToken,inputStyle,customStyles,setKybToShow,setViewReport,setVerificationType}) {
  const dispatch = useDispatch()
  const kybState = useSelector(selectKybEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const onSubmit = (formData) => {
    const obj = {tin:formData.inputName,
                accessToken}
    dispatch(getTinDataAsync(obj))
  }


  const getDetails = (token,uuid,type)=>{
    setIsOpen(false);
    setKybToShow(null);
    dispatch(resetTinData());
    dispatch(resetKybData());                                     

    setVerificationType(type);
    setViewReport(true)
    dispatch(resetReportKyb())
    dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
}

  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p className='title-class'>TIN Verification</p>
       <div style={{}}>
           <p>Input TIN Number</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKybToShow(null);
                                                  dispatch(resetTinData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

  const ModalSuccess = 
      <div className='modal-error'>
          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img style={{width:'40px'}} src={successImg}/>
          </div>
          <p style={{textAlign:'center',
                    color:"#2A38A4",
                    fontSize:"24px",
                    fontWeight:"700"}}>
                    TIN Extraction Successfull
          </p>
          <p style={{color:'#363C4F',
                    fontSize:"16px",
                    fontWeight:"400" }}>
              Click Done to see
          </p>
          <div className='modal-initial-button-hold'>
                <span className='check' onClick={()=>getDetails(accessToken,kybState?.tinExtraction?.uuid,
                                                              kybState?.tinExtraction?.type)}>
                                        Done
                </span>
                
          </div>
    </div>


  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kybState.errorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                                  setKybToShow(null);
                                                  dispatch(resetTinData())}}>Cancel</span>
           
       </div>
  </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kybState.tinStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kybState.tinStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kybState.tinStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess)
        }

        if(kybState.tinStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kybState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default TinModal