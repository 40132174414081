import React from "react";
import { useFieldArray } from "react-hook-form";

export default ({ nestIndex, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `statementBureauAccountData[${nestIndex}].statementBureauAccountContactHistory`
  });

  return (
    <>
      {fields.map((item, index) => {
        return <fieldset key={item.id}>
          <h3 style={{ margin: '0' }}>Contact History</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Contact Type</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(
                 `statementBureauAccountData[${nestIndex}].statementBureauAccountContactHistory[${index}].contactType`,
                { required: true,
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Invalid contact type"
                  } })} />
            </div>
            <div className="bureau-input-container">
              <label>Date Reported</label>
              <input className="inputs account-form-inputs" type={'date'} {...register(
                 `statementBureauAccountData[${nestIndex}].statementBureauAccountContactHistory[${index}].dateReported`, 
                { required: true })} />
            </div>
            <div className="bureau-input-container">
              <label>Details</label>
              <input className="inputs account-form-inputs" type={'text'} {...register(
                 `statementBureauAccountData[${nestIndex}].statementBureauAccountContactHistory[${index}].details`, 
                { required: true,
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Invalid details"
                  } })} />
            </div>
          </div>
          <br />
          <button type="button" onClick={() => remove(index)} className="button-square button-outline">
            Remove Contact
          </button>
        </fieldset>
      })}
      <br />
      <div className="bureau-form-buttons">
        <button type="button" onClick={() => append()} className="button-square button-solid">
          Add Contact
        </button>
      </div>
    </>
  );
};
