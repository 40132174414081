import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";


export const getVerifyLoanParameter = createAsyncThunk ('loanFile/getLoanParameters',async(params)=>{
    const loanParameters = await client.get('/loan/loan-verification-parameters',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return loanParameters.data;
} )
 


export const uploadVerificationForm = createAsyncThunk('loanFile/formUpload', async (params) => {
  //console.log(params.data)

  const data = JSON.stringify(params.data)
  const verifiedFormData = await client.post('/loan/verify', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken,
        'Content-Type': 'application/json'
      }
  });
  return verifiedFormData;
});
 

export const updateLoanApproval = createAsyncThunk('loanFile/loanUpdate', async (params) => {
  //console.log(params.data)

  const data = JSON.stringify(params.data)
  const approvedData = await client.post('/loan/update', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken,
        'Content-Type': 'application/json'
      }
  });
  return approvedData;
});


export const getLoanFileData = createAsyncThunk('loanFile/data',async(params)=>{
       const loanData = await client.get(`/loan/${params.key}`,
       {
         "headers":
         {
           "Authorization": "Bearer " + params.accessToken
         }
       });
     
       return loanData.data;
     } )


     export const getLoanVerifiedData = createAsyncThunk('loanFile/verified',async(params)=>{
        const verificationData = await client.get(`/loan/verification/${params.key}`,
        {
          "headers":
          {
            "Authorization": "Bearer " + params.accessToken
          }
        });
      
        return verificationData.data;
      } )

  
/**
 * export const uploadSignature = createAsyncThunk('loan/signature', async (params) => {
  const data = new FormData();
  data.append('files', params.files);
 /**
  *  params.files.forEach((file) => {
    data.append('files', file);
  });
  */



 /**
  *   const signatureData = await client.post('/loan/upload', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  });
  return signatureData;
});

  */

export const loanFileSlice = createSlice({
    name: 'loanFile',
    initialState: {
      verificationParameters: [],
      loanVerificationParamStatus: FetchDataStatus.INITIAL,
      isErrorVerificationParameters: false,
      errorMessageVerificationParameters: null,

      loanFileData:null,
      loanFileDataStatus: FetchDataStatus.INITIAL,
      isErrorGetLoanFileData:false,
      errorGetLoanFileData:null,

      loanVerifiedDataStatus:FetchDataStatus.INITIAL,
      loanVerifiedData:null,
      isErrorLoanFileData: false,
      errorLoanFileData: null,


      verificationUploadData:null,
      verificationUploadFormStatus:PostDataStatus.INITIAL,
      isErrorVerificationUpload: false,
      errorVerificationUpload: null,


      
      approvalDataStatus:PostDataStatus.INITIAL,
      approvalData:null,
      isErrorApprovalData: false,
      errorApprovalData: null
      
      
      
    },

    reducers: {
        resetLoanParameter: state => {
            state.loanParameters = [];
            state.loanParametersStatus = FetchDataStatus.INITIAL;
            state.isErrorParameters = false;
            state.errorMessageParameters = null;
        },

        resetLoanFileData: state =>{
          state.loanFileDataStatus = FetchDataStatus.INITIAL;
          state.loanFileData = null;
          state.isErrorGetLoanFileData = false;
          state.errorGetLoanFileData = null;
        }
        ,
        resetLoanVerifiedData: state =>{
          state.loanVerifiedData = null;
          state.loanVerifiedDataStatus = FetchDataStatus.INITIAL;
          state.isErrorLoanFileData = false;
          state.errorLoanFileData = null;
        }
        ,
        resetVerificationUpload: state =>{
          state.verificationUploadFormStatus = PostDataStatus.INITIAL;
          state.verificationUploadData = null;
          state.isErrorVerificationUpload = false;
          state.errorVerificationUpload = null;
        },
        resetApproval: state =>{
          state.approvalData= null;
          state.approvalDataStatus = PostDataStatus.INITIAL;
          state.isErrorApprovalData = false;
          state.errorApprovalData = null;
        }
    },
    extraReducers(builder) {
        builder.addCase(getVerifyLoanParameter.pending, (state) => {
            state.loanVerificationParamStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getVerifyLoanParameter.fulfilled, (state, action) => {
            state.loanVerificationParamStatus = FetchDataStatus.SUCCESS;
            state.verificationParameters = action.payload;
          })
          .addCase(getVerifyLoanParameter.rejected, (state, action) => {
            state.loanVerificationParamStatus = FetchDataStatus.FAILURE;
            state.isErrorVerificationParameters = true;
            state.errorMessageVerificationParameters = action.error.message;
          })
          .addCase(getLoanVerifiedData.pending, (state) => {
            state.loanVerifiedDataStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getLoanVerifiedData.fulfilled, (state, action) => {
            state.loanVerifiedDataStatus = FetchDataStatus.SUCCESS;
            //console.log(action.payload)
            state.loanVerifiedData = action.payload;
          })
          .addCase(getLoanVerifiedData.rejected, (state, action) => {
            state.loanVerifiedDataStatus = FetchDataStatus.FAILURE;
            state.isErrorLoanFileData = true;
            state.errorLoanFileData = action.error.message;
          })
          .addCase(uploadVerificationForm.pending, (state) => {
            state.verificationUploadFormStatus = PostDataStatus.FETCHING;
          })
          .addCase(uploadVerificationForm.fulfilled, (state, action) => {
            state.verificationUploadFormStatus = PostDataStatus.SUCCESS;
            state.verificationUploadData = action.payload;
          })
          .addCase(uploadVerificationForm.rejected, (state, action) => {
            state.verificationUploadFormStatus = PostDataStatus.FAILURE;
            state.isErrorVerificationUpload = true;
            state.errorVerificationUpload = action.error.message;
          })
          .addCase(getLoanFileData.pending, (state) => {
            state.loanFileDataStatus = FetchDataStatus.FETCHING;
          })
          .addCase(getLoanFileData.fulfilled, (state, action) => {
            state.loanFileDataStatus = FetchDataStatus.SUCCESS;
            state.loanFileData = action.payload;
          })
          .addCase(getLoanFileData.rejected, (state, action) => {
            state.loanFileDataStatus = FetchDataStatus.FAILURE;
            state.isErrorGetLoanFileData = true;
            state.errorGetLoanFileData = action.error.message;
          })
          .addCase(updateLoanApproval.pending, (state) => {
            state.approvalDataStatus = PostDataStatus.FETCHING;
          })
          .addCase(updateLoanApproval.fulfilled, (state, action) => {
        
            state.approvalDataStatus = PostDataStatus.SUCCESS;
            state.approvalData = action.payload;
          })
          .addCase(updateLoanApproval.rejected, (state, action) => {
            state.approvalDataStatus = PostDataStatus.FAILURE;
            state.isErrorApprovalData = true;
            state.errorApprovalData = action.error.message;
          })
    }
})


export const { resetLoanParameter,resetSignature,resetGetAllLoanData,resetApproval,resetLoanFileData,resetVerificationUpload,resetLoanVerifiedData } = loanFileSlice.actions;

export const selectLoanFileState = state => state.loanFile
export default loanFileSlice.reducer;