
import Utils from "../../../../../../library/Utils";

export const BrsObjectSerializer = (data)=>{

let BrsDataReshape = {business:[],persons:[]}

if(!Utils.isFieldEmpty(data)){
    const keyLength = Object.keys(data) 
    const personalInt = [...data.partners]
    for (let i=0; i<keyLength.length; i++){
        let objData = {}
        let personArray = []
        if(!Utils.isFieldEmpty(data[keyLength[i]])){
            if(keyLength[i] === 'businessName'){

                objData = {
                    key:'Business Name',
                    value: Utils.checkNull(data.businessName) 
                }
                BrsDataReshape.business.push(objData)
            }

            if(keyLength[i] === 'regNumber'){

                objData = {
                    key:'Registration Number',
                    value: Utils.checkNull(data.regNumber) 
                }
                BrsDataReshape.business.push(objData)
            }

            if(keyLength[i] === 'status'){

                objData = {
                    key:'Registration Number',
                    value: data.status==="registered"?'REGISTERED':'NON-REGISTERED'  
                }
                BrsDataReshape.business.push(objData)
            }

            if(keyLength[i] === 'physicalAddress'){

                objData = {
                    key:'Address',
                    value: Utils.checkNull(data.physicalAddress) 
                }

                BrsDataReshape.business.push(objData)
            }

            if(keyLength[i] === 'registrationDate'){

                objData = {
                    key:'Date Registered',
                    value: Utils.checkNull(data.registrationDate) 
                }
                BrsDataReshape.business.push(objData)
            }
            
            if(keyLength[i] === 'phoneNumber1'){

                objData = {
                    key:'Phone Number',
                    value: Utils.checkNull(data.phoneNumber1) 
                }
                BrsDataReshape.business.push(objData)
            }
            }
        }

    for (let j=0; j<personalInt.length; j++){
  
        let interestArray = []
        if(!Utils.isFieldEmpty(personalInt[j].name)){
            const obj = {
                key:'Name',
                value: personalInt[j].name
            }
            interestArray.push(obj)
        }

        if(!Utils.isFieldEmpty(personalInt[j].type)){
            const obj = {
                key:'Role',
                value: personalInt[j].type
            }
            interestArray.push(obj)
        }

        
        if(!Utils.isFieldEmpty(personalInt[j].shares)){
            const obj = {
                key:'Number of Shares',
                value: Utils.checkNull(personalInt[j].shares[0].number_of_shares)
            }
            interestArray.push(obj)

            const newObj = {
                key:'Share Type',
                value: Utils.checkNull(personalInt[j].shares[0].name)
            }
            interestArray.push(newObj)
        }
        BrsDataReshape.persons.push(interestArray)
    }

    

  

}

return {...BrsDataReshape}
}