import React, {useState,useEffect} from 'react'
import logoHold from '../../../../assests/icons/kycLogo.png'
import RbcModal from './KybModals/RbcModal'
import { selectKenyaKybExtractions } from '../../../../redux/Slices/IdVerificationKenyaKyb'
import { useSelector,useDispatch } from 'react-redux'
import ExtractionReport from '../../../Statement/KYC/kycReporter/ExtractionReport'
import { CountryCodes } from '../../../../library/CountryCodes'
import Spinner from '../../../../svg/Spinner/Spinner'
import KybExtractionHistory from './KybDocumentation/kybExtactionHistory'
import KybExtractionReport from './KybDocumentation/KybReport'
import { getStandAloneDataHistory } from '../../../../redux/Slices/IdVerificationKenyaKyb'
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables'
import './KybContent.css'


function KybContentSection({accessToken,configuration,keyFromRoute}) {
    const kybState = useSelector(selectKenyaKybExtractions)
    const dispatch = useDispatch()
    const [viewReport,setViewReport] = useState(false)
    const [reportData,setReportData] = useState(null)
  const [historyArray,setHistoryArray] =  useState([])
  const [verificationType,setVerificationType] = useState()


  useEffect(()=>{
  },[kybState.activeExtractions])
    const [kybToShow,setKybToShow] = useState(null)
    const cardHolder = [{id:0,title:'BRS Verification',
                      info:'This check requires RC Number of business'}]
                      const customStyles = {
                        content: {
                            transition: "0.125s ease-in-out",
                            transform: "scale(1)",
                            height: "40%",
                            width: "50%",
                            top: "33%",
                            left: "33%",
                            padding: "0 0 0 0",
                            overflowY: "scroll"
                        },
                        overlay: {
                            zIndex: 1000,
                            position: "absolute"
                        }
                        };
                  
                    const inputStyle = {
                      width: "100%",
                      marginTop: "10px",
                      border: 'none',
                      padding: '0px 24px 0px 24px',
                      boxSizing: "border-box",
                      backgroundColor: 'rgba(239, 244, 255, 1)',
                      outline: "none",
                      height: '40px'
                    };
                    
    const tableHeaders = ['Status','KYB Type','Created Date','Full Name','Tracking Number']

    useEffect(()=>{
        if(kybState.rbcExtractionStatus===PostDataStatus.SUCCESS||kybState.standAloneStatus===FetchDataStatus.SUCCESS){
          setHistoryArray([...kybState.activeExtractions.kycDataSummaries])
        }
      },[kybState.rbcExtractionStatus,kybState.standAloneStatus])
    
    
      useEffect(()=>{
       if(kybState.standAloneStatus === PostDataStatus.INITIAL){
        dispatch(getStandAloneDataHistory({accessToken,page:1,perPage:10}))

       }
          
      },[kybState.extractionStatus,kybState.standAloneStatus])
   
    return (
        <div>
            <section>
                <p style={{fontSize:'24px',color:"rgba(0, 0, 0, 1)",fontWeight:"700"}}>
                    KYB Verification
                </p>
                <p style={{fontSize:'16px',color:"rgba(106, 113, 125, 1)"}}>
                    Select a KYB verification method below
                </p>
            </section>
            {kybToShow===null?null
                :kybToShow===0?<RbcModal keyFromRoute={keyFromRoute} 
                                         accessToken={accessToken} 
                                         setViewReport={setViewReport} 
                                         customStyles={customStyles} 
                                         setKybToShow={setKybToShow} 
                                         inputStyle={inputStyle}
                                         setVerificationType={setVerificationType}
                                         />:
                ''
            }
            <div style={{display:"flex",justifyContent:'space-between',columnGap:'10px',alignItems:'center',marginTop:"30px"}}>
                {configuration.countryCode === CountryCodes.KENYA? cardHolder.map(card=>
                    <div onClick={()=>{setKybToShow(card.id)}} style={{display:"flex",margin:'10px 0px',cursor:"pointer",width:"45%",borderRadius:'4px',height:'160px',flexDirection:'column',justifyContent:"space-around",padding:"10px",boxSizing:'border-box',backgroundColor:"rgba(255, 255, 255, 1)"}} key={card.id}>
                        <p><img src={logoHold}/></p>
                        <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                        <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                    </div>)
                    :<div style={{minHeight:"150px",
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:"space-around",
                                backgroundColor:'white',
                                width:'100%',
                                borderRadius:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                margin:'30px auto'}}>
                        <p style={{fontWeight:'700',
                                   fontStyle:'italic',
                                   fontSize:'22px'}}>
                                Anticipate KYB!
                        </p>

                        <p style={{textAlign:'center',
                                    fontSize:'15px',
                                    marginTop:'10px'}}>
                                    Coming Soon
                        </p>
                    </div>}
            </div>

            {viewReport && <KybExtractionReport verificationType={verificationType} viewReport={viewReport} setViewReport={setViewReport}/>}


            {(kybState.standAloneStatus===FetchDataStatus.FETCHING && !viewReport)?
                <div className="spinner-container">
                    <Spinner></Spinner>
                </div>
            :
            (kybState.standAloneStatus===FetchDataStatus.SUCCESS && historyArray.length===0 && !viewReport)?
            <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                You currently have no historically extracted KYC document.
            </div>:
            (kybState.standAloneStatus==='SUCCESS' && historyArray.length>0 && !viewReport)?
            <KybExtractionHistory 
                setVerificationType={setVerificationType}
                accessToken={accessToken}
                setViewReport={setViewReport} viewReport={viewReport} 
                historyArray={historyArray} tableHeaders={tableHeaders}
                setHistoryArray={setHistoryArray}
                kybState={kybState}
                />:
                (kybState.standAloneStatus==='FAILURE' && !viewReport)?
            <div style={{width:'100%',color:'red',textAlign:'center',marginTop:'30px'}}>
                Something went wrong!.
            </div>:''}
        </div>
    )
    }

    export default KybContentSection