import React,{useMemo,useEffect,useState} from 'react'
import { Table,SelectColumnFilter, DateRangeColumnFilter } from '../../../components/Table/Table';
import Utils from '../../../library/Utils';
import { useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom'
import { selectLoanState } from '../../../redux/Slices/Loan';
import { FetchDataStatus } from '../../../library/Variables';
import Spinner from '../../../svg/Spinner/Spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetLoanFileData,resetLoanVerifiedData,resetApproval } from '../../../redux/Slices/LoanFile';
import { resetCustomDecisionCreditScore } from '../../../redux/Slices/CustomDecisionCustomCreditScore';
import { resetLoanScore } from '../../../redux/Slices/LoanScores';

function SubmittedLoans(props) {
    let content;
    const dispatch = useDispatch()
    const accessToken = props.accessToken
    const user = props.user
    let tableData = [];
    const permissions = user.user.permissions
    const loanState = useSelector(selectLoanState)

    const [mainContent,setMainContent] = useState(<Spinner/>)

    const viewLoanApplication = (checker)=>{
        if(permissions.viewLoanData){
          dispatch(resetLoanFileData());
          dispatch(resetApproval());
          dispatch(resetCustomDecisionCreditScore());
          dispatch(resetLoanScore());
          dispatch(resetLoanVerifiedData())
        }
        else{
          toast.info('You are not permitted to view loan Applications', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }

    const theadersStatements = useMemo(() => [
        
        {
            Cell: data => {
              let branchName = data.row.original.branch;
              branchName = !Utils.isFieldEmpty(branchName)?branchName:'---' 
              return (
                <span>
                  {branchName}
                </span>
              );
            },
            Header: 'Branch Name',
            accessor: 'branch',
          },
        { 
            Header: 'Agent Name',
            accessor: 'agentName'
          },
          { 
            Header: 'Applicant Name',
            accessor: 'name'
          },
        { 
          Cell: data => {
            let statusDisplay = data.row.original.createdDate;
            statusDisplay = Utils.formatLongDateMonthAndYear(statusDisplay)
            return (
              <span>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Submitted Date',
          accessor: 'createdDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
          Cell: data => {
            let dateUpdate = data.row.original.updatedDate;
            dateUpdate = dateUpdate==='--'? '--': Utils.formatLongDateMonthAndYear(dateUpdate)
            return (
              <span>
                {dateUpdate}
              </span>
            );
          },
          Header: 'Updated Date',
          accessor: 'updatedDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
          Cell: data => {
            let statusDisplay = data.row.original.status;
            return (
              <span style={{ 
                fontWeight:'700',
                color: statusDisplay==='PENDING'?'rgba(251, 109, 6, 1)':statusDisplay==='APPROVED'?'#2DA771':statusDisplay==='DRAFT'?'black':'#FF3A44'}}>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Status',
          accessor: 'status',
          Filter: SelectColumnFilter,
          filter: 'includes'
        },
        {
          id: 'button-column',
          disableSortBy: true,
          Cell: data => {
            let link = !permissions.viewLoanData?'/loanApproval':data.row.original.status==='DRAFT'
                       ?`/draftedForm/${data.row.original.key}`:`/loanApproval/${data.row.original.key}`;
            //let link = permissions.viewLoanData?`/loanApproval/${data.row.original.key}`:'/loanApproval';
            return (
              <div onClick={()=>viewLoanApplication(data.row.original.status)} style={{backgroundColor:'rgba(64, 123, 255, 1)',display:"flex",alignItems:'center',justifyContent:"center",color:'white',padding:'10px 0px',borderRadius:'4px',textAlign:'center'}}>
                <Link to={link} state={{ key: data.row.original.key }} style={{display:"flex",color:'white',width:'60px',textDecoration:'none',alignItems:"center",justifyContent:"center"}} >View</Link>
              </div>
            );
          }
        }]
      )


      useEffect(()=>{
        if(loanState.loanFilterStatus === FetchDataStatus.INITIAL){
            content = <Spinner/>
            setMainContent(content)
        }
        if(loanState.loanFilterStatus === FetchDataStatus.FETCHING){
            content = <Spinner/>
            setMainContent(content)
        }

        if(loanState.loanFilterStatus === FetchDataStatus.SUCCESS){
            loanState.loanFilterData.forEach(form => {
                tableData.push({
                  key: form.key,
                  name: form.name,
                  agentName: form.agentName,
                  createdDate: form.createdDate,
                  branch:form.branch,
                  clientId:form.clientId,
                  updatedDate: !Utils.isFieldEmpty(form.updatedDate) ? form.updatedDate : "--",
                  status: form.status
                })
              })
            content = <Table tableClass="statement-type-table" pagination={true} pageSize={[10]} data={tableData} 
                        columns={theadersStatements}
                        tableExtra={false} tableFilter={true} />;
            setMainContent(content)
        }
      },[loanState.loanFilterStatus])

  return (
    <div>
        {mainContent}
    </div>
  )
}

export default SubmittedLoans