import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";
let download = require('downloadjs');

//https://api.dev.insights-periculum.com/loan/score/download/1

export const downloadLoanBreakDown = createAsyncThunk('loanScore/scoreDownload', async (params) => {
  setDownloadingTrue();
  const statement = await client.getBlob(`/loan/score/download/${params.key}`,
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, 'report', "application/pdf");
    });
  });

  return statement;
});


export const generateScore = createAsyncThunk('loanScore/data',async(params)=>{
    const loanData = await client.get(`/loan/generatescore/${params.loanKey}/${params.cardDefinitionKey}`,
    {
      "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
    });
  
    return loanData.data;
  } )


  export const generateScoreBreakDown = createAsyncThunk('loanScore/breakdown',async(params)=>{
    const loanData = await client.get(`/loan/getscorev2/${params.loanKey}`,
    {
      "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
    });
  
    return loanData.data;
  } )


export const loanScoreSlice = createSlice({
    name: 'loanScore',
    initialState: {
        scoreData:null,
        scoreDataStatus:FetchDataStatus.INITIAL,
        isErrorScoreData:false,
        errorScoreData:null,

        scoreBreakDownStatus: FetchDataStatus.INITIAL,
        scoreBreakDownData:null,
        isErrorScoreBreakDown:false,
        errorScoreBreakDown :null,


        loanPdf: null,
        loanPdfStatus: FetchDataStatus.INITIAL,
        isError: false,
        errorMessage: null,
        isDownloading: false,
    },

    reducers: {
      resetLoanScore: state => {
          state.scoreDataStatus = FetchDataStatus.INITIAL;
          state.scoreBreakDownStatus = FetchDataStatus.INITIAL;
          state.scoreBreakDownData = null;
          state.scoreData = null;

          state.isErrorScoreData = false;
          state.errorScoreData = null;

      },
      setDownloadingFalse: state => {
        state.isDownloading = false;
      },
      setDownloadingTrue: state => {
        state.isDownloading = true;
      }
    }
      ,

    extraReducers(builder) {
        builder.addCase(generateScore.pending, (state) => {
            state.scoreDataStatus = FetchDataStatus.FETCHING;
          })
          .addCase(generateScore.fulfilled, (state, action) => {
            state.scoreDataStatus = FetchDataStatus.SUCCESS;
            state.scoreData = action.payload;
          })
          .addCase(generateScore.rejected, (state, action) => {
            state.scoreDataStatus = FetchDataStatus.FAILURE;
            state.isErrorScoreData = true;
            state.errorScoreData = action.error.message;
           
          })
          .addCase(generateScoreBreakDown.pending, (state) => {
            state.scoreBreakDownStatus = FetchDataStatus.FETCHING;
          })
          .addCase(generateScoreBreakDown.fulfilled, (state, action) => {
            state.scoreBreakDownStatus = FetchDataStatus.SUCCESS;
            state.scoreBreakDownData = action.payload;
          })
          .addCase(generateScoreBreakDown.rejected, (state, action) => {
            state.scoreBreakDownStatus = FetchDataStatus.FAILURE;
            state.isErrorScoreBreakDown = true;
            state.errorScoreBreakDown = action.error.message;
          })


          builder.addCase(downloadLoanBreakDown.pending, (state) => {
            state.loanPdfStatus = FetchDataStatus.FETCHING;
            state.loanIsDownloading = true;
          })
          .addCase(downloadLoanBreakDown.fulfilled, (state, action) => {      
            state.loanPdfStatus = FetchDataStatus.SUCCESS;
            state.pdf = action.payload;
            state.loanIsDownloading = true;
          })
          .addCase(downloadLoanBreakDown.rejected, (state, action) => {
            state.loanPdfStatus = FetchDataStatus.FAILURE;
            state.isError = true;
            state.errorMessage = action.error.message;
            state.isDownloading = false;
          })




        
    }
})

export const { resetLoanScore, setDownloadingFalse, setDownloadingTrue } = loanScoreSlice.actions;

export const selectLoanScoreState = state => state.loanScore
export default loanScoreSlice.reducer;


/**
 *  export const generateScoreBreakDown = createAsyncThunk('loanScore/breakdown',async(params)=>{
    const loanData = await client.get(`/loan/getscore/${params.loanKey}`,
    {
      "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
    });
  
    return loanData.data;
  } )
 */