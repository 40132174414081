import React, { useState } from 'react';
import './Accordion.css';

function Accordion(props) {
  const [activeRow, setActiveRow] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  let activeRowBody = props.accordionBodies[activeRow];

  if(activeRowBody === null || activeRowBody === undefined){
    return null;
  }
 
  const accordionRows = props.accordionBodies.map((accordionBody, i) => {
    
    if(activeRow === i) {
      return <div key={i + "-" + activeRow}>
        <div className="acc-row" key={i} id={isOpen ? 'active-acc-row-open' : 'active-acc-row'} >
          {accordionBody.headers.map((header, j) =>
            typeof header === 'object'
            ? <h4 style={{ color: header.color, background: header.color + "1A"}} key={i + "-" + j}>{header.text}</h4>
            : <h4  key={i + "-" + j}>{header}</h4>
          )}
          <div className="acc-row-right">
            <button onClick={() => { setIsOpen(!isOpen) } }>{isOpen ? "-" : "+"}</button>
          </div>
        </div>
        {isOpen && 
          <div className="acc-bodies-container">
            {accordionBody.content}
          </div>
        }
      </div>
    } else {
      return <div className="acc-row" key={i + "-" + activeRow}>
        {accordionBody.headers.map((header, j) =>
          typeof header === 'object'
          ? <h4 style={{ color: header.color, background: header.color + "1A" }}  key={i + "-" + j}>{header.text}</h4>
          : <h4 key={i + "-" + j}>{header}</h4>
        )}
        <div className="acc-row-right">
          <button onClick={() => { setActiveRow(i); setIsOpen(true) } }>{"+"}</button>
        </div>
      </div>
    }
  })

  return (
    <div className="accordion-container scrollbar" key={activeRowBody.title}>
      {accordionRows}
    </div>
  )
}

export default Accordion;