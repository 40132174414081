import React, {useEffect,useState} from 'react'
import { selectLoanManagement,getAllLoanAgents,reAssignLoanAgents} from '../../../../redux/Slices/Loans/LoanManagement/LoanManagement';
import { PostDataStatus, FetchDataStatus } from '../../../../library/Variables';
import Spinner from '../../../../svg/Spinner/Spinner';
import { useDispatch,useSelector } from 'react-redux';
import cancelButton from '../../../../assests/icons/cancelLoanSection.png'
import searchIcon from '../../../../assests/icons/searchIcon.png'
import Utils from '../../../../library/Utils';

function ReAssignModal(props) {
    const dispatch = useDispatch()
    const loanManageState = useSelector(selectLoanManagement)
    const accessToken = props.accessToken
    const closeModal = props.closeModal
    const setAgentReassigning = props.setAgentReassigning
    const loanKey= props.loanKey
    const [managerState,setManagerState] = useState([])
    let content 
 
    useEffect(()=>{
        if(!Utils.isFieldEmpty(loanManageState.loanAgentData)){
            setManagerState(loanManageState.loanAgentData)
        }
    },[loanManageState.loanAgentStatus])


    const onChangeManagement = (value)=>{
        value = value.toLowerCase()
        const output = loanManageState.loanAgentData.filter(val => val.firstName.toLowerCase().includes(value) 
                                                            || val.lastName.toLowerCase().includes(value))

        setManagerState(output)
    }


    const setNewAgent = (id)=>{
        dispatch(reAssignLoanAgents({accessToken,userkey:id,loanKey}))
        setAgentReassigning(true)
        closeModal()
    }

    if(loanManageState.loanAgentStatus===FetchDataStatus.INITIAL){
        
        content = <div style={{display:"flex",width:"100%",padding:"15px",boxSizing:"border-box",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                        <span style={{fontWeight:"700"}}>Reassign Agent</span>
                        <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <img style={{display:"flex",alignItems:"center",justifyContent:"center"}} src={cancelButton}/>
                        </span>
                    </div>

                    <input placeholder='Search' style={{height:"35px",width:"100%",paddingLeft:"20px",boxSizing:"border-box",backgroundColor:"#F6F8FC",border:"1px solid #D9D9D9",outline:"none",borderRadius:"7px"}}/>
                    <Spinner/>
            </div>

       
        dispatch(getAllLoanAgents({accessToken}))
            
    }


    if(loanManageState.loanAgentStatus===FetchDataStatus.FETCHING){
 

        content = <div style={{display:"flex",width:"100%",padding:"15px",boxSizing:"border-box",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                        <span style={{fontWeight:"700"}}>Reassign Agent</span>
                        <span onClick={()=>closeModal()} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <img style={{display:"flex",alignItems:"center",justifyContent:"center"}} src={cancelButton}/>
                        </span>
                    </div>

                    <input placeholder='Search' style={{height:"35px",width:"100%",paddingLeft:"20px",boxSizing:"border-box",backgroundColor:"#F6F8FC",border:"1px solid #D9D9D9",outline:"none",borderRadius:"7px"}}/>
                    <Spinner/>
            </div>
            
    }

    if(loanManageState.loanAgentStatus===FetchDataStatus.SUCCESS){
      
        content = <div style={{display:"flex",width:"100%",padding:"15px",boxSizing:"border-box",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between",maxHeight:"350px"}}>
                    <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                        <span style={{fontWeight:"700"}}>Reassign Agent</span>
                        <span onClick={()=>closeModal()} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <img  style={{display:"flex",alignItems:"center",justifyContent:"center"}} src={cancelButton}/>
                        </span>
                    </div>
                    
                    <input onChange={(event)=>onChangeManagement(event.target.value)} placeholder='Search' style={{width:"100%",padding:"15px 0px 15px 15px",boxSizing:"border-box",backgroundColor:"#F6F8FC",border:"1px solid #D9D9D9",outline:"none",borderRadius:"7px"}}/>

                    <div style={{width:"100%",display:"flex",justifyContent:"space-between",flexDirection:"column",overflowY:"scroll"}}>
                        {managerState.map((data,index)=>
                            <section onClick={()=>setNewAgent(data.id,)} key={index} style={{height:"35px",padding:"15px 0px 15px 15px",display:"flex",alignItems:"center",justifyContent:"start",width:"100%",marginTop:"10px",paddingLeft:"20px",boxSizing:"border-box",backgroundColor:"#F6F8FC",border:"1px solid #D9D9D9",borderRadius:"7px"}}>
                                <span>{data.firstName}</span>
                                &nbsp; &nbsp;
                                <span>{data.lastName}</span>
                            </section>
                        )}
                        
                    </div>
            </div>
    }

    if(loanManageState.editDraftStatus===FetchDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanManageState.errorEditDraft}</p>

            
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default ReAssignModal