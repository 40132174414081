import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchParameterActionAsync,
  parameterActions,
} from "../../../../redux/Slices/fraudDetect/actionSlice";
import {
  fetchMatchCriteriaAsync,
  matchCriteriaSelector,
} from "../../../../redux/Slices/fraudDetect/matchCreteriaSlice";
import {
  fetchParameterActionValuesAsync,
  parameterActionValueSelector,
} from "../../../../redux/Slices/fraudDetect/parameterActionValueSlice";
import { fetchParametersAsync } from "../../../../redux/Slices/fraudDetect/parametersSlice";
import { parameterSelector } from "../../../../redux/Slices/fraudDetect/parametersSlice/selector";
import {
  createRuleSelector,
  createRulesAsync,
  reset,
} from "../../../../redux/Slices/fraudDetect/ruleSlice";
import { AlertModal } from "../../components/AlertModal";
import { ScoreSlider } from "../../components/ScoreSlider";
import { Label, Select, TextField } from "../../components/form";
import { MultiSelectInput } from "../../components/form/MultiSelectInput";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import Spinner from "../../components/loader";
import styles from "./index.module.css";

const CreateRule = ({ configuration, accessToken, user }) => {
  const navigate = useNavigate();
  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(createRuleSchema),
  });

  methods.watch("parameterKey");

  const parameters = useSelector(parameterSelector);
  const actions = useSelector(parameterActions);

  const actionsValues = useSelector(parameterActionValueSelector);

  const matchCreteria = useSelector(matchCriteriaSelector);
  const createRuleApi = useSelector(createRuleSelector);
  const [selectedDisplay, setSelectedDisplay] = useState("list");

  const dispatch = useDispatch();
  const [showTextField, setShowTextField] = useState(false);

  useEffect(() => {
    dispatch(fetchParametersAsync({ accessToken }));
    dispatch(fetchMatchCriteriaAsync({ pageNumber: 1, pageSize: 5 }));
  }, [accessToken, dispatch]);

  const createRuleHandler = (data) => {
    const payload = {
      name: data.name,
      friendlyName: data.name,
      parameterKey: data.parameterKey?.value,
      actionKey: data.actionKey?.value,
      ValueKeys:
        selectedDisplay !== "input"
          ? data.values.map((item) => item.value)
          : [],
      values:
        selectedDisplay === "input"
          ? data.values?.map((item) => item.value)
          : [],
      minScore: data.score[0],
      maxScore: data.score[1],
      addMatchCriteria: data.addMatchCriteria,
      matchCriteriaKey: data.matchCriteriaKey?.value,
    };

    dispatch(createRulesAsync(payload));
  };

  const validateNotEmpty = (value) => {
    if (!value.trim()) {
      return "This field cannot be empty.";
    }
    return true;
  };

  const validateArray = (value) => {
    if (!Array.isArray(value)) {
      return "This field must be an array";
    }
    if (value.length === 0) {
      return "Array cannot be empty";
    }
    return true;
  };

  return (
    <InnerLayout>
      <div className={styles.formWrapper}>
        <FormProvider {...methods}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();

              //   if (methods.formState?.errors?.apiError) {
              //     methods.clearErrors("apiError");
              //   }
              methods.handleSubmit(createRuleHandler)(event);
            }}
          >
            <div className={styles.titleWrapper}>
              <h5 className={styles.formtTitle}>Create Rule</h5>

              <button
                type="submit"
                className={styles.applyBtn}
                disabled={createRuleApi.status === "loading"}
              >
                {createRuleApi.status === "loading" ? (
                  <Spinner
                    visible={createRuleApi.status}
                    color="#fff"
                    size={20}
                  />
                ) : (
                  "Apply Rule"
                )}
              </button>
            </div>

            <div className={styles.formContainers}>
              <div className={styles.formInput}>
                <Controller
                  name="name"
                  control={methods.control}
                  rules={{
                    required: "Name is required.",
                    validate: {
                      notEmpty: validateNotEmpty,
                    },
                  }}
                  render={({ field: { value, onChange }, formState }) => {
                    return (
                      <>
                        <Label>Name</Label>
                        <TextField
                          name="name"
                          inputIsFullWidth={true}
                          value={value}
                          onChange={onChange}
                        />
                      </>
                    );
                  }}
                />
              </div>

              <div className={styles.formFull}>
                <div className={styles.formInput}>
                  <Controller
                    control={methods.control}
                    name="parameterKey"
                    rules={{
                      required: "Parameter is required.",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Label>Select Paramter</Label>
                        <Select
                          name="parameterKey"
                          className={styles.selectContainer}
                          options={parameters?.data?.data?.map((item) => ({
                            value: item.key,
                            label: item.name,
                          }))}
                          value={value}
                          onChange={(e) => {
                            dispatch(
                              fetchParameterActionAsync({ id: e.value })
                            );
                            onChange(e);
                          }}
                          isLoading={parameters.status === "loading"}
                          isDisabled={parameters.status === "loading"}
                        />
                      </>
                    )}
                  />
                </div>
                <div className={styles.formInput}>
                  <Controller
                    control={methods.control}
                    name="actionKey"
                    rules={{
                      required: "Action is required.",
                    }}
                    render={({ field: { value, onChange }, formState }) => {
                      const parameter = methods.watch("parameterKey");

                      return (
                        <>
                          <Label>Select Action</Label>
                          <Select
                            className={styles.selectContainer}
                            name="actionKey"
                            options={actions?.data?.data?.map((item) => ({
                              value: item.key,
                              label: item.name,
                            }))}
                            value={value}
                            onChange={(e) => {
                              dispatch(
                                fetchParameterActionValuesAsync({
                                  id: parameter.value,
                                  actionId: e.value,
                                })
                              );
                              onChange(e);
                            }}
                            isLoading={actions?.status === "loading"}
                            isDisabled={
                              actions?.status === "loading" ||
                              actions?.status === "idle"
                            }
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles.formInput} style={{ width: "50%" }}>
                <Controller
                  control={methods.control}
                  name="values"
                  rules={{
                    required: "Values is required.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    const actionKey = methods.watch("actionKey");
                    const prevValues = methods.watch("values") || [];

                    const selectedAction = actions?.data?.data?.find(
                      (item) => item.key === actionKey?.value
                    );

                    if (
                      selectedAction &&
                      selectedAction.displayType === "Input Field"
                    ) {
                      setSelectedDisplay("input");
                    }

                    return (
                      <>
                        {selectedAction &&
                        selectedAction.displayType === "Input Field" ? (
                          <>
                            <Label>Enter Value</Label>
                            <MultiSelectInput
                              name="values"
                              inputIsFullWidth={true}
                              value={value}
                              prevValues={prevValues}
                              handleEnter={(data) => onChange(data)}
                              onChange={(data) => {
                                onChange(data);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Label>Select Value</Label>
                            <Select
                              name="values"
                              className={styles.selectContainer}
                              options={actionsValues?.data?.data?.map(
                                (item) => ({
                                  value: item.key,
                                  label: item.name,
                                })
                              )}
                              value={value}
                              onChange={(data) => {
                                onChange(data);
                              }}
                              isMulti
                              isLoading={actionsValues?.status === "loading"}
                              isDisabled={
                                actionsValues?.status === "loading" ||
                                actionsValues?.status === "idle"
                              }
                            />
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </div>

              <div
                className={styles.formInput}
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Controller
                  control={methods.control}
                  name="addMatchCriteria"
                  render={({ field }) => {
                    return (
                      <>
                        <input
                          type="checkbox"
                          name="addMatchCriteria"
                          {...field}
                        />
                        <Label>Add Match Criteria</Label>
                      </>
                    );
                  }}
                />
              </div>

              <div style={{ marginBottom: "30px" }}>
                <Controller
                  control={methods.control}
                  name="matchCriteriaKey"
                  rules={{
                    required: "Match criteria  is required.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <Label>Match Criteria</Label>
                        <Select
                          name="matchCriteriaKey"
                          className={styles.selectContainer}
                          options={matchCreteria?.data?.data.map((item) => ({
                            value: item.key,
                            label: item.friendlyName,
                          }))}
                          value={value}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          isLoading={matchCreteria?.status === "loading"}
                          isDisabled={matchCreteria?.status === "loading"}
                        />
                      </>
                    );
                  }}
                />
              </div>

              <div>
                <Controller
                  name="score"
                  control={methods.control}
                  rules={{
                    required: "Score is required.",
                    validate: validateArray,
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <>
                        <Label htmlFor="progressBar">
                          Set score for this rule
                        </Label>
                        <ScoreSlider
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                          name="score"
                        />
                      </>
                    );
                  }}
                />
              </div>

              {/* <RuleScorer progress={40} /> */}
            </div>
          </form>
        </FormProvider>
      </div>

      {createRuleApi.status === "succeeded" && (
        <AlertModal
          showTitle={false}
          showCancelBtn={false}
          open={createRuleApi.status === "succeeded"}
          toggleModal={() => {}}
          handleDone={() => {
            dispatch(reset());
            navigate("/fraud-detect/rules");
          }}
        />
      )}
    </InnerLayout>
  );
};

export default CreateRule;
