import classNames from "classnames";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import { Fragment, useEffect, useState,useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  fetchUserAddressAsync,
  userAddressSelector,
} from "../../../../redux/Slices/fraudDetect/addressSlice";

import dayjs from "../../../../utils/dayjs";

import {
  createTransactionCommentAsync,
  createTransactionCommentSelector,
  reset as resetComment,
} from "../../../../redux/Slices/fraudDetect/commentSlice";
import {
  addSupportingDocsAsync,
  deleteSupportingDocsAsync,
  addSupportingDocsSelector,
  reset,
} from "../../../../redux/Slices/fraudDetect/supportDocumentSlice";
import {
  fetchTransactionDetailsAsync,
  fetchTransactionsByIdAsync,
  fraudTransactionByIdSelector,
  fraudTransactionDetailsSelector,
} from "../../../../redux/Slices/fraudDetect/transactionSlice";
import { generateFormData } from "../../../../utils/generateFormData";
import { AlertModal } from "../../components/AlertModal";
import { AssetDialog } from "../../components/AlertModal/AssetDialog";
import FileUploadDrop from "../../components/FileUpload";
import { CustomDialog } from "../../components/Modal";
import { TextField } from "../../components/form";
import Spinner from "../../components/loader";
import SkeletonLoader from "../../components/loader/skeletonLoader";
import styles from "./index.module.css";
import TransactionReview from "../../components/TransactionReview";
import DeleteSupportFiles from "../../components/DeleteSupportDocs";
import Utils from "../../../../library/Utils";
import deleteIcon from "../../../../assests/icons/cancelSupport.png"



const TransactionDetails = ({accessToken,configuration}) => {
  const [openComment, setOpenComment] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openTransactionReview, setOpenTransactionReview] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedFileName,setDeletedFileName] = useState('')
  const [deletedFileKey,setDeletedFileKey] = useState('')
  const [files, setFiles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const headerRef = useRef(null)

  const params = useParams();

  useEffect(()=>{
    if(!openDeleteModal){
      dispatch(reset())
    }
  },[openDeleteModal])

  const methods = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  const userTransactionDetails = useSelector(fraudTransactionDetailsSelector);

  const addSupportingDocs = useSelector(addSupportingDocsSelector);

  const createTransactionCommentApi = useSelector(
    createTransactionCommentSelector
  );

  const userAddress = useSelector(userAddressSelector);

  const transactionApi = useSelector(fraudTransactionByIdSelector);


  useEffect(() => {
    dispatch(fetchTransactionDetailsAsync({ id: params.transactionId }));
  }, [dispatch, params.transactionId, refresh]);

  useEffect(() => {
    dispatch(fetchTransactionDetailsAsync({ id: params.transactionId }));
    dispatch(fetchTransactionsByIdAsync({ id: params.transactionId }));

    
  }, [dispatch, params.transactionId]);

  useEffect(()=>{
    if(addSupportingDocs.deleteSupportStatus==='succeeded'){
      dispatch(fetchTransactionDetailsAsync({ id: params.transactionId }));
      dispatch(fetchTransactionsByIdAsync({ id: params.transactionId }));
    }
  },[addSupportingDocs.deleteSupportStatus])

  useEffect(() => {
    dispatch(fetchUserAddressAsync({ userId: params.userId }));
  }, [dispatch, params.userId]);

  const submitCommentHandler = (data) => {
    // setOpenComment(false);
    // setOpenAlert(true);

    const payload = {
      comment: data?.comment,
      userKey: params.userId,
      transactionKey: params.transactionId,
    };

    dispatch(createTransactionCommentAsync(payload));
  };

  const removeFile = (index) => {
    setFiles((l) => [...l.slice(0, index), ...l.slice(index + 1)]);
  };

  const handleSubmit = () => {
    const payload = {
      file: files[0].file,
      transactionKey: params.transactionId,
    };

    dispatch(addSupportingDocsAsync(generateFormData(payload)));
  };

  useEffect(()=>{
    headerRef.current?.scrollIntoView({behavior: 'smooth'});
  },[])

  return (
    <section ref={headerRef}>
      <InnerLayout>
      <div>
        <div className={styles.titleWrapper}>
          <h5 className={styles.formtTitle}>User Data</h5>

          <button
            className={classNames(styles.applyBtn, styles.applyBtn_contained)}
            onClick={() => setOpenTransactionReview(true)}
          >
            Review Transaction
          </button>
          <button className={styles.applyBtn}>Download Profile Report</button>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Transaction Profile</h5>
          <div className={styles.profileDetailList}>
            {transactionApi.status === "loading" && (
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                  <div key={item} className={styles.profileDetailListItem}>
                    <SkeletonLoader count={1} height={20} width={100} />
                    <SkeletonLoader count={1} height={30} width={200} />
                  </div>
                ))}
              </>
            )}
            {transactionApi.data && transactionApi.data.data && (
              <>
                <div className={styles.profileDetailListItem}>
                  <h6>Transaction Date</h6>
                  <h5>
                    {dayjs(transactionApi.data.data?.transactionDate).format(
                      "MMMM Do, YYYY"
                    )}
                  </h5>
                </div>

                <div className={styles.profileDetailListItem}>
                  <h6>Transaction Time</h6>
                  <h5>
                    {dayjs(transactionApi.data.data?.transactionDate).format(
                      "hh:mm A"
                    )}
                  </h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Transaction Type</h6>
                  <h5>{transactionApi.data.data?.transactionType}</h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Amount</h6>
                  <h5>
                    {new Intl.NumberFormat().format(
                      transactionApi.data.data?.amount
                    )}
                  </h5>
                </div>

                <div className={styles.profileDetailListItem}>
                  <h6>Currency</h6>
                  <h5>
                    {/* <span>₦</span> &nbsp; */}
                    {transactionApi.data.data?.currency}
                  </h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Channel</h6>
                  <h5>{transactionApi.data.data?.channel}</h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Payment Type</h6>
                  <h5>{transactionApi.data.data?.paymentType}</h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Risk Score</h6>
                  <h5>{transactionApi.data.data?.riskScore}</h5>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Address Details</h5>
          <div className={styles.profileDetailList}>
            {userAddress.status === "loading" && (
              <>
                {[1, 2, 3].map((item) => (
                  <div key={item} className={styles.profileDetailListItem}>
                    <SkeletonLoader count={1} height={20} width={100} />
                    <SkeletonLoader count={1} height={30} width={200} />
                  </div>
                ))}
              </>
            )}

            {userAddress.data &&
              userAddress.data.data &&
              userAddress.data.data.map((item) => (
                <Fragment key={item?.key}>
                  <div className={styles.profileDetailListItemAddress}>
                    <h6>Street</h6>
                    <h5>{item?.street}</h5>
                  </div>

                  <div className={styles.profileDetailListItemAddress}>
                    <h6>Country</h6>
                    <h5>{item?.country}</h5>
                  </div>
                  <div className={styles.profileDetailListItemAddress}>
                    <h6>City</h6>
                    <h5>{item?.city}</h5>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Sender Details</h5>
          <div className={styles.profileDetailList}>
            {transactionApi.status === "loading" && (
              <>
                {[1, 2, 3].map((item) => (
                  <div key={item} className={styles.profileDetailListItem}>
                    <SkeletonLoader count={1} height={20} width={100} />
                    <SkeletonLoader count={1} height={30} width={200} />
                  </div>
                ))}
              </>
            )}
            {transactionApi.data && transactionApi.data?.data && (
              <>
                <div className={styles.profileDetailListItem}>
                  <h6>Account Name</h6>
                  <h5>{transactionApi.data?.data?.senderAccountName}</h5>
                </div>

                <div className={styles.profileDetailListItem}>
                  <h6>Bank Name</h6>
                  <h5>{transactionApi.data?.data?.senderBankName}</h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Account Number</h6>
                  <h5>{transactionApi.data?.data?.senderAccountNumber}</h5>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Receiver Details</h5>
          <div className={styles.profileDetailList}>
            {transactionApi.status === "loading" && (
              <>
                {[1, 2, 3].map((item) => (
                  <div key={item} className={styles.profileDetailListItem}>
                    <SkeletonLoader count={1} height={20} width={100} />
                    <SkeletonLoader count={1} height={30} width={200} />
                  </div>
                ))}
              </>
            )}

            {transactionApi.data && transactionApi.data?.data && (
              <>
                <div className={styles.profileDetailListItem}>
                  <h6>Account Name</h6>
                  <h5>{transactionApi.data?.data?.receiverAccountName}</h5>
                </div>

                <div className={styles.profileDetailListItem}>
                  <h6>Bank Name</h6>
                  <h5>{transactionApi.data?.data?.receiverBankName}</h5>
                </div>
                <div className={styles.profileDetailListItem}>
                  <h6>Account Number</h6>
                  <h5>{transactionApi.data?.data?.receiverAccountNumber}</h5>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.profileDetail}>
          <h5 className={styles.profileDetailTitle}>Key Observations</h5>
          <ul className={styles.profileDetailBullet}>
            {transactionApi.status === "loading" && (
              <>
                <SkeletonLoader count={1} height={30} width={200} />
              </>
            )}

            {transactionApi.data && transactionApi.data?.data && (
              <li dangerouslySetInnerHTML={{ __html: transactionApi.data?.data?.observations }} >
                
              </li>
            )}
          </ul>
        </div>
        <div className={styles.profileDetail}>
          <div className={styles.profileDetailHeader}>
            <h5 className={styles.profileDetailTitle}>Supporting Document</h5>
            <div
              className={styles.profileDetailHeaderRight}
              onClick={() => setOpenDocument(true)}
            >
              <span>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 16H24"
                    stroke="#407BFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 24V8"
                    stroke="#407BFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span>Add supporting document</span>
            </div>
          </div>

          <div className={styles.profileDetailListDocWrapper}>
            {userTransactionDetails.status === "loading" && <div>Loading</div>}

            {userTransactionDetails.data &&
            userTransactionDetails?.data?.data &&
            userTransactionDetails?.data?.data?.supportingDocuments?.length ? (
              userTransactionDetails?.data?.data?.supportingDocuments.map(
                (item, idx) => (
                  <AssestItem item={item} key={idx} 
                              setDeletedFileName={setDeletedFileName} 
                              setDeletedFileKey={setDeletedFileKey}
                              setOpenDeleteModal={setOpenDeleteModal}
                              />
                  // <div key={item?.id} className={styles.profileDetailListDoc}>
                  //   <span onClick={() => setOpenAssetDialog(true)}>
                  //     <svg
                  //       width="31"
                  //       height="34"
                  //       viewBox="0 0 31 34"
                  //       fill="none"
                  //       xmlns="http://www.w3.org/2000/svg"
                  //     >
                  //       <path
                  //         d="M21.9473 24.0838H9.56836"
                  //         stroke="#407BFF"
                  //         stroke-width="1.54308"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //       <path
                  //         d="M21.9473 16.912H9.56836"
                  //         stroke="#407BFF"
                  //         stroke-width="1.54308"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //       <path
                  //         d="M14.2919 9.74789H9.56836"
                  //         stroke="#407BFF"
                  //         stroke-width="1.54308"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //       <path
                  //         fill-rule="evenodd"
                  //         clip-rule="evenodd"
                  //         d="M22.276 0.992188C22.276 0.992188 9.1135 0.999046 9.09292 0.999046C4.36081 1.02819 1.43066 4.14179 1.43066 8.89105V24.6579C1.43066 29.4312 4.3831 32.5568 9.15636 32.5568C9.15636 32.5568 22.3171 32.5517 22.3394 32.5517C27.0715 32.5225 30.0034 29.4072 30.0034 24.6579V8.89105C30.0034 4.11779 27.0493 0.992188 22.276 0.992188Z"
                  //         stroke="#407BFF"
                  //         stroke-width="1.54308"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //     </svg>
                  //   </span>
                  //   <div className={styles.docsDetail}>
                  //     <h6>Supporting Document.pdf</h6>
                  //     <div className={styles.docsDetailMeta}>
                  //       <p>24 Jan , 2024 at 10:00</p>

                  //       <ul>
                  //         <li> 80.18 kb</li>
                  //       </ul>
                  //     </div>
                  //   </div>
                  //   <span>
                  //     <svg
                  //       width="25"
                  //       height="25"
                  //       viewBox="0 0 25 25"
                  //       fill="none"
                  //       xmlns="http://www.w3.org/2000/svg"
                  //     >
                  //       <path
                  //         d="M15.6883 12.3534C15.6883 14.3334 14.0883 15.9334 12.1083 15.9334C10.1283 15.9334 8.52832 14.3334 8.52832 12.3534C8.52832 10.3734 10.1283 8.77344 12.1083 8.77344C14.0883 8.77344 15.6883 10.3734 15.6883 12.3534Z"
                  //         stroke="#2A38A4"
                  //         stroke-width="1.5"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //       <path
                  //         d="M12.1082 20.6203C15.6382 20.6203 18.9282 18.5403 21.2182 14.9403C22.1182 13.5303 22.1182 11.1603 21.2182 9.75031C18.9282 6.15031 15.6382 4.07031 12.1082 4.07031C8.57824 4.07031 5.28824 6.15031 2.99824 9.75031C2.09824 11.1603 2.09824 13.5303 2.99824 14.9403C5.28824 18.5403 8.57824 20.6203 12.1082 20.6203Z"
                  //         stroke="#2A38A4"
                  //         stroke-width="1.5"
                  //         stroke-linecap="round"
                  //         stroke-linejoin="round"
                  //       />
                  //     </svg>
                  //   </span>
                  // </div>
                )
              )
            ) : (
              <div>No document</div>
            )}
            {/* <div className={styles.profileDetailListDoc}>
              <span>
                <svg
                  width="31"
                  height="34"
                  viewBox="0 0 31 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.9473 24.0838H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.9473 16.912H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.2919 9.74789H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.276 0.992188C22.276 0.992188 9.1135 0.999046 9.09292 0.999046C4.36081 1.02819 1.43066 4.14179 1.43066 8.89105V24.6579C1.43066 29.4312 4.3831 32.5568 9.15636 32.5568C9.15636 32.5568 22.3171 32.5517 22.3394 32.5517C27.0715 32.5225 30.0034 29.4072 30.0034 24.6579V8.89105C30.0034 4.11779 27.0493 0.992188 22.276 0.992188Z"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className={styles.docsDetail}>
                <h6>Supporting Document.pdf</h6>
                <div className={styles.docsDetailMeta}>
                  <p>24 Jan , 2024 at 10:00</p>

                  <ul>
                    <li> 80.18 kb</li>
                  </ul>
                </div>
              </div>
              <span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6883 12.3534C15.6883 14.3334 14.0883 15.9334 12.1083 15.9334C10.1283 15.9334 8.52832 14.3334 8.52832 12.3534C8.52832 10.3734 10.1283 8.77344 12.1083 8.77344C14.0883 8.77344 15.6883 10.3734 15.6883 12.3534Z"
                    stroke="#2A38A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.1082 20.6203C15.6382 20.6203 18.9282 18.5403 21.2182 14.9403C22.1182 13.5303 22.1182 11.1603 21.2182 9.75031C18.9282 6.15031 15.6382 4.07031 12.1082 4.07031C8.57824 4.07031 5.28824 6.15031 2.99824 9.75031C2.09824 11.1603 2.09824 13.5303 2.99824 14.9403C5.28824 18.5403 8.57824 20.6203 12.1082 20.6203Z"
                    stroke="#2A38A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>

            <div className={styles.profileDetailListDoc}>
              <span>
                <svg
                  width="31"
                  height="34"
                  viewBox="0 0 31 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.9473 24.0838H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.9473 16.912H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.2919 9.74789H9.56836"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.276 0.992188C22.276 0.992188 9.1135 0.999046 9.09292 0.999046C4.36081 1.02819 1.43066 4.14179 1.43066 8.89105V24.6579C1.43066 29.4312 4.3831 32.5568 9.15636 32.5568C9.15636 32.5568 22.3171 32.5517 22.3394 32.5517C27.0715 32.5225 30.0034 29.4072 30.0034 24.6579V8.89105C30.0034 4.11779 27.0493 0.992188 22.276 0.992188Z"
                    stroke="#407BFF"
                    stroke-width="1.54308"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className={styles.docsDetail}>
                <h6>Supporting Document.pdf</h6>
                <div className={styles.docsDetailMeta}>
                  <p>24 Jan , 2024 at 10:00</p>

                  <ul>
                    <li> 80.18 kb</li>
                  </ul>
                </div>
              </div>
              <span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6883 12.3534C15.6883 14.3334 14.0883 15.9334 12.1083 15.9334C10.1283 15.9334 8.52832 14.3334 8.52832 12.3534C8.52832 10.3734 10.1283 8.77344 12.1083 8.77344C14.0883 8.77344 15.6883 10.3734 15.6883 12.3534Z"
                    stroke="#2A38A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.1082 20.6203C15.6382 20.6203 18.9282 18.5403 21.2182 14.9403C22.1182 13.5303 22.1182 11.1603 21.2182 9.75031C18.9282 6.15031 15.6382 4.07031 12.1082 4.07031C8.57824 4.07031 5.28824 6.15031 2.99824 9.75031C2.09824 11.1603 2.09824 13.5303 2.99824 14.9403C5.28824 18.5403 8.57824 20.6203 12.1082 20.6203Z"
                    stroke="#2A38A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div> */}
          </div>
        </div>

        <div className={styles.profileDetail}>
          <div className={styles.profileDetailHeader}>
            <h5 className={styles.profileDetailTitle}>Comments</h5>
            <div
              className={styles.profileDetailHeaderRight}
              onClick={() => setOpenComment(true)}
            >
              <span>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 16H24"
                    stroke="#407BFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 24V8"
                    stroke="#407BFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span>Add a comment</span>
            </div>
          </div>

          <div className={styles.profileDetailListCommWrapper}>
            {userTransactionDetails.status === "loading" && <div>Loading</div>}
            {userTransactionDetails.data &&
            userTransactionDetails?.data?.data &&
            userTransactionDetails?.data?.data?.comments?.length ? (
              userTransactionDetails?.data?.data?.comments.map((item) => (
                <div key={item?.id} className={styles.profileDetailListDoc}>
                  <span className={styles.avatarName}>
                    {item?.commentedBy[0]}
                  </span>
                  <div className={styles.docsDetail}>
                    <h6>{item?.commentedBy}</h6>
                    <div className={styles.docsDetailMeta}>
                      <p>{item?.commentText}</p>
                    </div>
                  </div>
                  <span>{Utils.formatLongDateAndTime(item?.createdDate)}</span>
                </div>
              ))
            ) : (
              <div>No comment</div>
            )}
            {/* <div className={styles.profileDetailListDoc}>
              <span className={styles.avatarName}>F</span>
              <div className={styles.docsDetail}>
                <h6>Falaye Ronke</h6>
                <div className={styles.docsDetailMeta}>
                  <p>This transaction is above limit.</p>
                </div>
              </div>
              <span>1 month ago</span>
            </div>

            <div className={styles.profileDetailListDoc}>
              <span className={styles.avatarName}>A</span>
              <div className={styles.docsDetail}>
                <h6>Adewale Andrew</h6>
                <div className={styles.docsDetailMeta}>
                  <p>This transaction is above limit.</p>
                </div>
              </div>
              <span>1 month ago</span>
            </div> */}
          </div>
        </div>
      </div>
      {createTransactionCommentApi.status === "succeeded" && (
        <AlertModal
          showTitle={false}
          showCancelBtn={false}
          open={createTransactionCommentApi.status === "succeeded"}
          toggleModal={() => {}}
          handleDone={() => {
            setRefresh((prev) => !prev);
            setOpenComment(false);
            dispatch(resetComment());
          }}
        />
      )}
      {openComment && (
        <CustomDialog
          open={openComment}
          toggleModal={() => setOpenComment(false)}
          title="Comment"
        >
          <div className={styles.commentForm}>
            <FormProvider {...methods}>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  methods.handleSubmit(submitCommentHandler)(event);
                }}
              >
                <div className={styles.formInput}>
                  <Controller
                    name="comment"
                    control={methods.control}
                    render={({ field: { value, onChange }, formState }) => {
                      return (
                        <>
                          <TextField
                            inputIsFullWidth={true}
                            value={value}
                            onChange={onChange}
                            placeholder="Write a comment"
                            required={true}
                            name="comment"
                            inputContainer={styles.textareaContainer}
                            as="textarea"
                            rows="6"
                            className={styles.textContainer}
                          />
                        </>
                      );
                    }}
                  />
                  {/* <TextField
                    placeholder="Write a comment"
                    required={true}
                    name="comment"
                    inputIsFullWidth={true}
                    inputContainer={styles.textareaContainer}
                    as="textarea"
                    rows="6"
                    className={styles.textContainer}
                    {...methods.register("comment")}
                  /> */}
                </div>

                <div className={styles.commentBtnWrapper}>
                  <button
                    disabled={createTransactionCommentApi.status === "loading"}
                    type="submit"
                    className={styles.contained}
                  >
                    {createTransactionCommentApi.status === "loading" ? (
                      <Spinner
                        visible={createTransactionCommentApi.status}
                        color="#fff"
                        size={20}
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </CustomDialog>
      )}

      {openDocument && (
        <CustomDialog
          open={openDocument}
          toggleModal={() => setOpenDocument(false)}
          title="Upload Supporting Document"
          contentStyle={{
            overflowY: "auto",
          }}
        >
          <div style={{ marginTop: "40px" }}>
            <FileUploadDrop
              files={files}
              handleFile={(files) => setFiles(files)}
              removeFile={removeFile}
              handleSubmit={handleSubmit}
              loading={addSupportingDocs.status === "loading"}
              showSubmit={true}
            />
          </div>
        </CustomDialog>
      )}

      {openDeleteModal && (
        <CustomDialog
          open={openDeleteModal}
          toggleModal={() => setOpenDeleteModal(false)}
          title="Deleting Support Documents"
          contentStyle={{
            overflowY: "auto",
          }}
        >
          <div style={{ marginTop: "40px" }}>
            <DeleteSupportFiles
                id={params.transactionId}
                setOpenDeleteModal={setOpenDeleteModal}
                fileName={deletedFileName}
                deletedFileKey={deletedFileKey}
            />
          </div>
        </CustomDialog>
      )
      }

      {openTransactionReview && (
        <CustomDialog
          open={openTransactionReview}
          toggleModal={() => setOpenTransactionReview(false)}
          title="Reviewing Transaction"
          contentStyle={{
            overflowY: "auto",
          }}
        >
          <div style={{ marginTop: "40px" }}>
            <TransactionReview
                id={params.transactionId}
                setOpenTransactionReview={setOpenTransactionReview}
              accessToken={accessToken}
            />
          </div>
        </CustomDialog>
      )}




      {addSupportingDocs.status === "succeeded" && (
        <AlertModal
          showTitle={false}
          showCancelBtn={false}
          open={addSupportingDocs.status === "succeeded"}
          toggleModal={() => {}}
          handleDone={() => {
            setRefresh((prev) => !prev);
            setOpenDocument(false);

            dispatch(reset());
          }}
        />
      )}
    </InnerLayout>
    </section>
    
  );
};

const AssestItem = ({ item,setOpenDeleteModal,setDeletedFileName,setDeletedFileKey }) => {
  const [openAssetDialog, setOpenAssetDialog] = useState(false);

  return (
    <>
      <div key={item?.id} className={styles.profileDetailListDoc}>
        <span>
          <svg
            width="31"
            height="34"
            viewBox="0 0 31 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9473 24.0838H9.56836"
              stroke="#407BFF"
              stroke-width="1.54308"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.9473 16.912H9.56836"
              stroke="#407BFF"
              stroke-width="1.54308"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.2919 9.74789H9.56836"
              stroke="#407BFF"
              stroke-width="1.54308"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.276 0.992188C22.276 0.992188 9.1135 0.999046 9.09292 0.999046C4.36081 1.02819 1.43066 4.14179 1.43066 8.89105V24.6579C1.43066 29.4312 4.3831 32.5568 9.15636 32.5568C9.15636 32.5568 22.3171 32.5517 22.3394 32.5517C27.0715 32.5225 30.0034 29.4072 30.0034 24.6579V8.89105C30.0034 4.11779 27.0493 0.992188 22.276 0.992188Z"
              stroke="#407BFF"
              stroke-width="1.54308"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <div className={styles.docsDetail}>
          <h6>{item?.documentName}</h6>
          <div className={styles.docsDetailMeta}>
            <p> {Utils.formatLongDateAndTime(item?.dateUploaded)}</p>

            <ul>
              <li> 80.18 kb</li>
            </ul>
          </div>
        </div>
        <span onClick={() => setOpenAssetDialog(true)}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6883 12.3534C15.6883 14.3334 14.0883 15.9334 12.1083 15.9334C10.1283 15.9334 8.52832 14.3334 8.52832 12.3534C8.52832 10.3734 10.1283 8.77344 12.1083 8.77344C14.0883 8.77344 15.6883 10.3734 15.6883 12.3534Z"
              stroke="#2A38A4"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.1082 20.6203C15.6382 20.6203 18.9282 18.5403 21.2182 14.9403C22.1182 13.5303 22.1182 11.1603 21.2182 9.75031C18.9282 6.15031 15.6382 4.07031 12.1082 4.07031C8.57824 4.07031 5.28824 6.15031 2.99824 9.75031C2.09824 11.1603 2.09824 13.5303 2.99824 14.9403C5.28824 18.5403 8.57824 20.6203 12.1082 20.6203Z"
              stroke="#2A38A4"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span style={{
              cursor:'pointer'
              }}
              onClick={()=>{setOpenDeleteModal(true);
                            setDeletedFileName(item?.documentName);
                            setDeletedFileKey(item?.documentId)
                            }
                      }>
            <img src={deleteIcon}/>
          </span>
      </div>
      {openAssetDialog && (
        <AssetDialog
          showTitle={false}
          open={openAssetDialog}
          showCancelBtn={false}
          docUrl={item?.documentUrl}
          docName={item?.documentName}
          toggleModal={() => setOpenAssetDialog(false)}
        />
      )}
    </>
  );
};

export default TransactionDetails;
