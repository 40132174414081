import { CustomDialog } from "../Modal";

import styles from "./index.module.css";

export const AlertModal = ({ open, toggleModal, handleDone }) => {
  return (
    <CustomDialog
      open={open}
      toggleModal={toggleModal}
      showTitle={false}
      showCancelBtn={false}
      contentStyle={{ maxWidth: "472px", borderRadius: "20px" }}
    >
      <div className={styles.containerWrapper}>
        <div>
          <span>
            <svg
              width="61"
              height="61"
              viewBox="0 0 61 61"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.336 7.11719H19.6635C12.111 7.11719 7.37598 12.4647 7.37598 20.0322V40.4522C7.37598 48.0197 12.0885 53.3672 19.6635 53.3672H41.3335C48.911 53.3672 53.626 48.0197 53.626 40.4522V20.0322C53.626 12.4647 48.911 7.11719 41.336 7.11719Z"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.5996 30.245L27.5346 36.1775L39.3996 24.3125"
                stroke="#2A38A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>

        <div className={styles.textWrapper}>
          <h5>Your action was successful</h5>
          <p>Click done to see</p>
        </div>
        <div className={styles.btnWrapper}>
          <button onClick={handleDone} className={styles.contained}>
            View Done
          </button>
        </div>
      </div>
    </CustomDialog>
  );
};
