import React from 'react'
import directionIcon from '../../../assests/icons/arrowLeft.png'
function StepOne({setEngineTour,setTourSection,step,setstep}) {
  return (
    <section className='stepOne'>
      <div className="inner-tour-hold">
        <p style={{color:'rgba(31, 34, 40, 1)',fontWeight:'700',fontSize:'25px'}}>Experience Credit Decision Engine</p>
        <p style={{color:'rgba(0, 0, 0, 1)',fontSize:'18px'}}>This feature is currently not available to you yet. You can see how companies make
          custom credit decisions with Insights.
        </p>
        <div className="tour-button-hold">
          <div className='tour-button-one' onClick={()=>setEngineTour(false)}>Cancel</div>
          <div className='tour-button-two' onClick={()=>{setTourSection('stepTwo')}}>
              <span style={{height:'100%',display:"flex",alignItems:"center"}}>Start  a tour</span>
              <span style={{display:"flex",alignItems:"center",marginLeft:'12px',justifyContent:"center",height:'100%'}}><img src={directionIcon}/></span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StepOne