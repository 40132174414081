import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostDataStatus } from "../../../../library/Variables";
import { client } from "../../../../api/Httpclient";



export const postChangePassword = createAsyncThunk ('changePassword',async(params)=>{
    const data = JSON.stringify(params.body)
    const changePassResponse = await client.post('/platformuser/change-password',data,
      {
        "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
      });

  return changePassResponse.data;
} )


export const changePassWordSLice = createSlice({
    name: 'changePassWord',
    initialState: {

      changePassStatus: PostDataStatus.INITIAL,
      changePassData: null,
      isErrorChangePass:false,
      errorChangePass:null,
    },

    reducers: {
        resetChangePassword: state => {

            state.changePassStatus =  PostDataStatus.INITIAL;
            state.changePassData =  null;
            state.isErrorChangePass = false;
            state.errorChangePass = null;
        }
        
    },
    extraReducers(builder) {
        builder.addCase(postChangePassword.pending, (state) => {
            state.changePassStatus = PostDataStatus.FETCHING;
          })
          .addCase(postChangePassword.fulfilled, (state, action) => {
            state.changePassStatus = PostDataStatus.SUCCESS;
            state.changePassData = action.payload;
          })
          .addCase(postChangePassword.rejected, (state, action) => {
            state.changePassStatus = PostDataStatus.FAILURE;
            state.isErrorChangePass = true;
            state.errorChangePass = action.error.message;
          })

    }
})


export const { resetChangePassword } = changePassWordSLice.actions;

export const selectChangePassword = state => state.changePassWord
export default changePassWordSLice.reducer;