import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";

export const editLoanSection = createAsyncThunk('editLoan/edit',async(params)=>{
    const data = JSON.stringify(params.data)
    const editedResp = await client.post('/loan/update-application-section',data,
    {
        "headers":
          {
            "Authorization": "Bearer " + params.accessToken,
            'Content-Type': 'application/json'
          }
      });
  
    return editedResp;
  } )


export const editLoanSlice = createSlice({
    name: 'editSections',
    initialState: {
        editResponse:null,
        editResponseStatus:PostDataStatus.INITIAL,
        isErrorEditResponse:false,
        errorEditResponse:null,
    },

    reducers: {
      resetEditLoanSection: state => {
          state.editResponse = null;
          state.editResponseStatus = PostDataStatus.INITIAL;
          state.isErrorEditResponse = false;
          state.errorEditResponse = null;

      },}
      ,

    extraReducers(builder) {
        builder.addCase(editLoanSection.pending, (state) => {
            state.editResponseStatus = PostDataStatus.FETCHING;
          })
          .addCase(editLoanSection.fulfilled, (state, action) => {
            state.editResponseStatus = PostDataStatus.SUCCESS;
            state.editResponse = action.payload;
          })
          .addCase(editLoanSection.rejected, (state, action) => {
            state.editResponseStatus = PostDataStatus.FAILURE;
            state.isErrorEditResponse = true;
            state.errorEditResponse = action.error.message;
           
          })
    }
})

export const { resetEditLoanSection } = editLoanSlice.actions;

export const selectEditLoanSectionState = state => state.editSections
export default editLoanSlice.reducer;