export const PARAMETERS = [
  {
    value: "1",
    label: "Transaction",
  },
  {
    value: "2",
    label: "User",
  },
  {
    value: "3",
    label: "Card Details",
  },
  {
    value: "4",
    label: "Device",
  },
  {
    value: "5",
    label: "Address",
  },
  {
    value: "6",
    label: "AML Screening",
  },
];

export const ACTIONS = [
  {
    value: "1",
    label: "Amount",
  },
  {
    value: "2",
    label: "Bank Name",
  },
  {
    value: "3",
    label: "Account Name",
  },
  {
    value: "4",
    label: "Account Number",
  },
  {
    value: "5",
    label: "Currency",
  },
  {
    value: "6",
    label: "Payment Type",
  },
  {
    value: "8",
    label: "Gateway",
  },
  {
    value: "9",
    label: "References",
  },
  {
    value: "10",
    label: "Total Value",
  },
];

export const VALUES = [
  {
    value: "1",
    label: "US Dollars (USD)",
  },
  {
    value: "2",
    label: "Euro (EUR)",
  },
  {
    value: "3",
    label: "Nigerian Naira (NGN)",
  },
  {
    value: "4",
    label: "Pound Sterling (GBP)",
  },
  {
    value: "5",
    label: "Canadian Dollars (CAD)",
  },
  {
    value: "6",
    label: "Kenyan Shilling (KES)",
  },
];

export const RULES = [
  {
    id: "1",
    name: "Emmanuel Job",
    riskScore: "70",
    createdDate: "April 14th, 2023",
    updatedDate: "April 14th, 2023",
  },
  {
    id: "2",
    name: "Emmanuel Job",
    riskScore: "70",
    createdDate: "April 14th, 2023",
    updatedDate: "April 14th, 2023",
  },
  {
    id: "3",
    name: "Emmanuel Job",
    riskScore: "70",
    createdDate: "April 14th, 2023",
    updatedDate: "April 14th, 2023",
  },
  {
    id: "4",
    name: "Emmanuel Job",
    riskScore: "70",
    createdDate: "April 14th, 2023",
    updatedDate: "April 14th, 2023",
  },
];

export const RULE = {
  key: 2,
  name: "Restrict transaction",
  friendlyName: "Prevent transaction above #XXX,000",
  score: 90,
  parameterKey: 1,
  actionKey: 2,
  values: "1,2",
  matchCriteria: "",
  tenantKey: 1,
};
