import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';
let download = require('downloadjs');

export const downloadCsvAsync = createAsyncThunk('downloadCsv', async (params) => {
  setDownloadingTrue();
  const statement = await client.getBlob(`/statements/downloadconsolidatedreport/${params.keyFromRoute}`,
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, "Consolidated_Report_For_Statement_"+params.keyFromRoute+".csv", "text/csv");
    });
  });

  return statement;
});

export const downloadCsvSlice = createSlice({
  name: 'downloadCsv',
  initialState: {
    csv: null,
    csvStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    isDownloading: false,
  },
  reducers: {
    resetDownloadCsv: state => {
      state.csv = null;
      state.csvStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
      state.isDownloading = false;
    },
    setDownloadingFalse: state => {
      state.isDownloading = false;
    },
    setDownloadingTrue: state => {
      state.isDownloading = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(downloadCsvAsync.pending, (state) => {
      state.csvStatus = FetchDataStatus.FETCHING;
      state.isDownloading = true;
    })
    .addCase(downloadCsvAsync.fulfilled, (state, action) => {      
      state.csvStatus = FetchDataStatus.SUCCESS;
      state.csv = action.payload;
      state.isDownloading = true;
    })
    .addCase(downloadCsvAsync.rejected, (state, action) => {
      state.csvStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
      state.isDownloading = false;
    })
  }
});

export const { resetDownloadCsv, setDownloadingFalse, setDownloadingTrue } = downloadCsvSlice.actions;

export const selectDownloadCsvStatus = state => state.downloadCsv;

export default downloadCsvSlice.reducer;