import React,{useMemo,useState} from 'react'
import Speedometer from '../../../../../../svg/Speedometer/Speedometer'
import { selectLoanScoreState } from '../../../../../../redux/Slices/LoanScores';
import { useSelector,useDispatch } from 'react-redux';
import Utils from '../../../../../../library/Utils';
import { ConfigureInitialView } from '../../../../../DecisionEngine/ViewScorePage/ViewLoanScore';
import { FetchDataStatus,PostDataStatus,CustomCreditScoreViews} from '../../../../../../library/Variables';
import { selectCustomDecisionCreditScoreState } from '../../../../../../redux/Slices/CustomDecisionCustomCreditScore';
import { resetLoanScore } from '../../../../../../redux/Slices/LoanScores';
import { downloadLoanBreakDown } from '../../../../../../redux/Slices/LoanScores';
import { getCustomDecisionScoreCardDefinitions } from '../../../../../../redux/Slices/CustomDecisionCustomCreditScore';


function ScoreCardView(props) {
    const configuration = props.configuration
    const loanScoreState = useSelector(selectLoanScoreState)
    const dispatch = useDispatch()
    const setRefreshScore = props.setRefreshScore
    let currentScore
    let content;
    const showData = true
    const user = props.user
    const accessToken = props.accessToken
    let initialView
    const [initialViewTitle, setInitialViewTitle] = useState(false)
    const refresherFunction = ()=>{
      setRefreshScore(true)
      dispatch(resetLoanScore())
      dispatch(getCustomDecisionScoreCardDefinitions({accessToken}))
    }

    const downloadScorePdf = (token,key)=>{
      dispatch(downloadLoanBreakDown({accessToken:token,key}))
    }
    //look into individualScoreBreakdowns for revamp
    const [individualScoreBreakdowns, setIndividualScoreBreakdowns] = useState(loanScoreState.scoreBreakDownData.scoresBreakdowns)
    const [pageActive, setPageActive] =  useState(CustomCreditScoreViews.INITIAL)
    const customCreditScore = useSelector(selectCustomDecisionCreditScoreState);
    const theadersCreditScoreRules = useMemo(() => [
        { 
          id: 'friendlyLeftHandSide',
          accessor: 'friendlyLeftHandSide'
        },
        { 
          id: 'actualLeftHandSide',
          accessor: 'actualLeftHandSide'
        },
        {
          id: 'friendlyOperator',
          accessor: 'friendlyOperator'
        },
        {
          id: 'friendlyRightHandSide',
          accessor: 'friendlyRightHandSide'
        },
        {
          Cell: data => {
            return (
              <span className="detail-header">
                {data.row.original.obtainedPoints}
              </span>
            );
          },
          id: 'obtainedPoints',
          accessor: 'obtainedPoints'
        }], []
      )

    

    if(loanScoreState.scoreBreakDownStatus === FetchDataStatus.SUCCESS){
        initialView = ConfigureInitialView({
            credit: loanScoreState.scoreBreakDownData,
            individualScoreBreakdowns: individualScoreBreakdowns,
            refresherFunction:() => refresherFunction(),
            accordion: {
              id: "custom-credit-score-accordion"
            },
            loadScorePdf:downloadScorePdf,
            configuration: configuration, 
            tableHeaders: theadersCreditScoreRules, 
            update: () => setPageActive(CustomCreditScoreViews.SCORE_CARDS),
            isUpdatable: true,
            accessToken: accessToken,
            loanScoreState: loanScoreState,
            user: user,
            individualScoreBreakdowns,
            showData,
            view: initialViewTitle,
            updateInitialView: () => setInitialViewTitle(true),
            handleViewScore: (element) => setIndividualScoreBreakdowns(element)
          }).mainBodyContent;

          content = initialView
    }
  return (
    <div>
        {content}
    </div>
  )
}

export default ScoreCardView