import classNames from "classnames";
import ReactSelect from "react-select";
import ErrorDisplay from "./ErrorDisplay";
import styles from "./index.module.css";

export const Select = ({
  components,
  menuPlacement,
  variant = "default",
  controlStyle,
  ...props
}) => {
  const { ...restProps } = props;
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#DFE8FF" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 2px #DFE8FF" : provided.boxShadow,
      padding: "10px",
      ...controlStyle?.(provided, state),

      // minHeight: "50px",
    }),
  };
  return (
    <>
      <ReactSelect
        // isLoading={true}
        // isDisabled={true}
        classNames={{
          control: (state) =>
            classNames(styles.control, props.classNames?.control),
          valueContainer: () => classNames(props.classNames?.valueContainer),
          menuList: () => classNames(props.classNames?.menuList),
          // ...classNames,
        }}
        components={components}
        {...restProps}
        styles={customStyles}
      />
      <ErrorDisplay fieldName={props.name} />
    </>
  );
};
