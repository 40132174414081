import React, {useEffect} from 'react'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import nairaIcon from '../../../../assests/icons/nairaIcon.png'
import { naijaStates } from './NigerianStates';
import AmountInput from '../../../../components/loanComponents/AmountInput';
import Utils from '../../../../library/Utils';


function ResidentialAddressSection(props) {

  const register = props.register
    const errors = props.errors
    const setValue = props.setValue
    const slicedData = props?.slicedData ? props.slicedData : ''



    useEffect(()=>{
      if(!Utils.isFieldEmpty(slicedData)){
        let amountObject = {}
        let phoneObject = {}
        props.info.forEach((element,index) => {
            slicedData.forEach((sliced)=>{
              if(sliced.friendlyName===element.friendlyName){
                  setValue(element.name,sliced.value)
                
              }
  
            })
        });
      }
  },[])

  
  const optionChecker = (data)=>{
    if(data.friendlyName==='Number Of Dependents'){
      let numbersArray = [];
      for (let i = 1; i <= 20; i++) {
          numbersArray.push(i);
      }
      return numbersArray
    }

    if(data.friendlyName==='State'){
      let statesArray = [];
      for (let i = 0; i <= naijaStates.length; i++) {
        statesArray.push(naijaStates[i]);
      }
      return statesArray
    }

    else{
      return data.options
    }
}


  return (
    <div style={{width:'100%',boxSizing:"border-box",boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius: '10px',backgroundColor:"white",padding:'2em'}}>
              <p className='formTitle'>Residential Address</p>
              
              <section className='sign-up-details-inputs' >
              {
                lodash.sortBy(props.info,'rank').map((data,id)=><div style={{width:"100%"}} id={id}  key={id}>
                {data.displayType==='SELECT'?
                  <div className='sign-up-details-input-container'>
                  <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                  <div className='selectHolder'>
                      <select  {...register(`${data.name}`, { required: data.isRequired?true:false })} 
                        onChange={(e) => props.handleListSelect(e.target.value)}>
                        <option value="">Select an option</option>
                        {optionChecker(data).map((element, i) => {
                          return <option value={element} key={i}>{element}</option>
                        })}
                      </select>
                  </div>
                  <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                  </div>:

                  data.displayType==='TEXT'|| data.displayType==='NUMBER'?
                        <div className='sign-up-details-input-container'> 
                        <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                        <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
                        { 
                          required: data.isRequired?true:false, 
                          
                        })} />
                        <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                        </div>
                        :
                        <AmountInput data={data} register={register} setValue={setValue} errors={errors}/>
                }
                </div>)
              }</section>
      </div>
  )
}

export default ResidentialAddressSection