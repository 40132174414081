import React, { useMemo, useState } from "react";
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import { Table } from "../../../components/Table/Table";

function MobileSms(props) {
  let tableData = [];

  const sms = props.sms; 
  const [isLoading, setLoading] = useState(true);

  const theadersSMS = useMemo(() => [
    { 
      id: 'date',
      accessor: 'date',
      Cell: data => {
        return (
          <div className="table-cell-with-caption">
            <label className="text-dark">Date:</label>
            <h3>{data.row.original.date}</h3>
          </div>
        )
      }
    },
    {
      id: 'body',
      accessor: 'body'
    }], []
  )

  if(!Utils.isFieldEmpty(sms)) {
    if(isLoading === true) {
      setLoading(false);
    }

    sms.forEach(element => {
      tableData.push({
        date: Utils.formatLongDate(element.date),
        body: element.body
      })
    });
  }          

  return (
    <div>
      {isLoading === true 
        ? <Spinner />
        : tableData.length > 0
          ? <Table tableClass="mb-lending-sms-type-table" pagination={true} pageSize={[10]} showHeader={false}
            data={tableData} columns={theadersSMS} tableExtra={false} />
          : <div className="cards mb-details-no-table-data-container">
              <h2 className="detail-header">There are no SMS transactions to display at this time</h2>
            </div>
      }
    </div>
  )
}

export default MobileSms;