import React, { useEffect, useMemo, useState } from "react";
import Modal from 'react-modal';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ConfirmBox from "../../../../../components/Modals/ConfirmBox";
import Utils from "../../../../../library/Utils";
import { FetchDataStatus } from "../../../../../library/Variables";
import {
  getVoneCreditScores,
  resetCreditScore,
  resetCreditScoreStatus,
  selectVOneCreditScoreState,
  updateVoneCreditScoreAsync
} from "../../../../../redux/Slices/ConsolidatedCreditScore";
import Spinner from "../../../../../svg/Spinner/Spinner";
import { configureInitialView } from "../../../../Statement/CreditScore/CreditScoreUtils";
import '../ConsolidatedCustomScore/ConsolidatedCreditScore.css';


if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

function VOneCreditScore(props) {
  let mainBodyContent = <Spinner />;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const keyFromRoute = props.keyFromRoute;
  const configuration = props.configuration;
  const user = props.user;
  const creditScore = useSelector(selectVOneCreditScoreState);
  const [modalIsOpen, setIsOpen] = useState(false);

  const params = {
    accessToken: accessToken,
    baseStatementKey: keyFromRoute
  }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '33%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  useEffect(()=>{
    dispatch(resetCreditScore())
    dispatch(resetCreditScoreStatus())
  },[])
  const theadersCreditScoreRules = useMemo(() => [
    { 
      id: 'friendlyLeftHandSide',
      accessor: 'friendlyLeftHandSide'
    },
    { 
      id: 'actualLeftHandSide',
      accessor: 'actualLeftHandSide'
    },
    {
      id: 'friendlyOperator',
      accessor: 'friendlyOperator'
    },
    {
      id: 'friendlyRightHandSide',
      accessor: 'friendlyRightHandSide'
    },
    {
      Cell: data => {
        return (
          <span className="detail-header">
            {data.row.original.obtainedPoints}
          </span>
        );
      },
      id: 'obtainedPoints',
      accessor: 'obtainedPoints'
    }], []
  )

  function openModal(){
    setIsOpen(true);
  }

  function afterOpenModal(){
  }

  function closeModal(){
    setIsOpen(false);
    dispatch(resetCreditScore());
  }

  const handleConfirmCallback = () => {
    dispatch(updateVoneCreditScoreAsync(params));
  }

  useEffect(() => {
    if((Utils.isFieldEmpty(creditScore)) || creditScore.creditScoreStatus === FetchDataStatus.INITIAL) {
      dispatch(getVoneCreditScores(params));
    }
  }, [creditScore, dispatch])

  if((!Utils.isFieldEmpty(creditScore.activeCreditScore)) && creditScore.creditScoreStatus === FetchDataStatus.SUCCESS) {
    mainBodyContent = configureInitialView({
      credit: creditScore.activeCreditScore, 
      accordion: {
        id: "credit-score-accordion"
      },
      configuration: configuration, 
      tableHeaders: theadersCreditScoreRules, 
      update: () => openModal(),
      isUpdatable: creditScore.activeCreditScore.canGenerateNewScore,
      user: user
    }).mainBodyContent;
  }
  
  return (
    <>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} 
        contentLabel="Update Credit Score">
        <ConfirmBox close={closeModal} typeStatus={creditScore.updateCreditScoreStatus}
          initialStateTitle={"Update Credit Score"} failTitle={"Credit Score Update Failed"}
          initialStateMessage={"This action will incur a charge to your account. Are you sure you wish to proceed?"}
          fetchingTitle={"Updating Credit Score. Please wait..."} successTitle={"Credit Score Updated"}
          successMessage={"Click done to view"}
          resetStatus={() => dispatch(resetCreditScore())} parentCallback={() => handleConfirmCallback()} />
      </Modal>
      <div className="cs-main-body">
        {mainBodyContent}
      </div>
    </>
  )
}

export default VOneCreditScore;