import React from 'react'
import Tabs from '../../../../../../../components/Tabs/Tabs'
import CashFlowAnalysis from './CashFlowAnalysis';
import PatternComponent from './PatternComponent';
import BehaviouralFlow from './BehaviouralFlow';

function MainAnalysis() {
    let tabBodiesArray = [];

    tabBodiesArray.push({
        title: "Cash Flow",
        content: <CashFlowAnalysis/>
      });

      tabBodiesArray.push({
        title: "Pattern",
        content: <PatternComponent/>
      });

      tabBodiesArray.push({
        title: "Behavioural",
        content: <BehaviouralFlow/>
      });
    const tabsHold = <Tabs  tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
  return (
    <div style={{marginTop:"30px"}}>
        {tabsHold}
    </div>
  )
}

export default MainAnalysis