import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getStatementDetails = createAsyncThunk('consolidatedStatements/details', async (params) => {
  const statement = await client.get(`/statements/consolidatedoverview/${params.keyFromRoute}`,  
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  }); 

  return statement.data;
});

export const statementDetailsSlice = createSlice({
  name: 'consolidatedStatementDetails',
  initialState: {
    activeStatement: { },
    statementDetailsStatus: FetchDataStatus.INITIAL,
    affordability: { },
    affordabilityStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetConsolidatedActiveStatement: state => {
      state.activeStatement = {};
      state.statementDetailsStatus = FetchDataStatus.INITIAL;
      state.affordability = {};
      state.affordabilityStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getStatementDetails.pending, (state) => {
      state.statementDetailsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getStatementDetails.fulfilled, (state, action) => {      
      state.statementDetailsStatus = FetchDataStatus.SUCCESS;
      state.activeStatement = action.payload;
    })
    .addCase(getStatementDetails.rejected, (state, action) => {
      state.statementDetailsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetConsolidatedActiveStatement } = statementDetailsSlice.actions;

export const selectConsolidatedStatementDetailsState = state => state.consolidatedStatementDetails;

export default statementDetailsSlice.reducer;