import React, { useEffect, useState } from 'react'
import Modal from "react-modal"
import { useDispatch, useSelector } from 'react-redux'
import errorIcon from '../../../assests/icons/amlEmpty.png'
import arrowToLeft from '../../../assests/icons/arrowToLeft.png'
import { FetchDataStatus } from '../../../library/Variables'
import { getScoreCardRulesByKey, resetParameterByKey, selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../svg/Spinner/Spinner'
import RuleList from '../CreateDecision/DefineScoreCard/DefinitionComponents/RuleList'
import RulesModal from '../Modals/RulesModal'
import './preview.css'


function PreviewParameter({setSection,accessToken,previewCard,setMainParameterKey,key,setParamMetaData,paramMetaData,setShowScoreCard,mainScoreCardKey,mainParameterKey}) {

    const dispatch = useDispatch()
    const [RuleContent,setRuleContent] = useState(<Spinner/>)

   
    const selfScoreDef = useSelector(selectSelfServiceState)
    const [selfScoreCardIsOpen, setSelfScoreCardIsOpen] = useState(false);

    const [createParamIsOpen, setcreateParamIsOpen] = useState(false);
    const [createRuleIsOpen, setcreateRuleIsOpen] = useState(false);
    const [editScoreCardIsOpen, setEditScoreCardIsOpen] = useState(false);
    const [deleteParamModalIsOpen, setDeleteParamModalIsOpen] = useState(false);
    const [paramName,setParamName] = useState(paramMetaData?.name)
    const [parameterType,setParameterType] = useState(paramMetaData?.type)

    
      function afterOpenModal() { }
    
      function closeSelfScoreCardModal() {
              setSelfScoreCardIsOpen(false)
    
        //dispatch(resetDownload());
      }

      function closeParamCreateModal() {
        setcreateParamIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeRuleCreateModal() {
            setcreateRuleIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeEditScoreCardModal() {
            setEditScoreCardIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeDeletParameterModal() {
            setDeleteParamModalIsOpen(false)

        //dispatch(resetDownload());
        }
    
      const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '33%',
          width: '80%',
          top: '33%',
          left: '33%',
          padding: '20px',
          boxSizing:'border-box',
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };



    useEffect(()=>{
    
            //dispatch(getSelfServiceCustomParams({accessToken}))
            if(selfScoreDef.selfServiceGetRulesByKeyStatus === FetchDataStatus.INITIAL){
                dispatch(getScoreCardRulesByKey({accessToken,cardKey:previewCard.key,parameterKey:paramMetaData.key}))
            }
            
        
        
    },[selfScoreDef.selfServiceGetRulesByKeyStatus])

    useEffect(()=>{
        if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.INITIAL){
            setRuleContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.FETCHING){
            setRuleContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.SUCCESS){
            const Content = <RuleList setSection={setSection} 
                                            selfScoreDef={selfScoreDef}
                                            setcreateRuleIsOpen={setcreateRuleIsOpen}
                                            setDeleteParamModalIsOpen={setDeleteParamModalIsOpen}
                                            accessToken={accessToken}
                                            name={previewCard.name}
                                            setParamName={setParamName}
                                            />
            setRuleContent(Content)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.FAILURE){
            const Content = <section className='errorClassBox'>
                                <div className='imgHold'>
                                    <img src={errorIcon}/>
                                </div>
                                <div className='errorTitle'>
                                    <i>An error Occured</i>
                                </div>
                                <div className='errorMssg'>
                                    {selfScoreDef.selfServiceGetRulesByKeyErrorMessage}
                                </div>
                            </section>
            setRuleContent(Content)
        }

    },[selfScoreDef.selfServiceGetRulesByKeyStatus])


  return (
    <div>
        <section onClick={()=>{setShowScoreCard(true);dispatch(resetParameterByKey())}} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
           
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            <div> 
                <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Preview Parameter</p>
                <section style={{height:"300px",rowGap:"10px",padding:"10px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                        ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{paramMetaData?.name}</p>
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{paramMetaData?.name}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{paramMetaData?.type}</span>
                                
                        </div>



                </section>

        
            </div>
        </section>

        {RuleContent}


        <Modal isOpen={createRuleIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Rules." >
            <RulesModal closeModal={closeRuleCreateModal}
                              accessToken={accessToken}
                              mainParameterKey={mainParameterKey}
                              mainScoreCardKey={mainScoreCardKey}
                              paramName={paramName}
                              setParamName={setParamName}
                              parameterType={parameterType}
                              setMainParameterKey={setMainParameterKey}
                              />
        </Modal>

      


    </div>
  )
}

export default PreviewParameter