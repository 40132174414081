import React from 'react'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { selectLoanFileState } from '../../../redux/Slices/LoanFile';

function UploadVerificationModal(props) {
    const dispatch = useDispatch()
   
    const formState = useSelector(selectLoanFileState)
    const accessToken = props.accessToken
   
    const setNewlyVerified = props.setNewlyVerified

    let content = <Spinner/>;
   

    if(formState.verificationUploadFormStatus === PostDataStatus.FETCHING){
        content = <Spinner/> 
    }
  
    if(formState.verificationUploadFormStatus === PostDataStatus.SUCCESS){

       content =  <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Verification Form Uploaded</p>
            <div className='buttonHold'>   
                <section onClick={()=>{setNewlyVerified(true); props.closeUploadVerificationModal()}}>Done!</section>
            </div> 
        </div>
   
  }

  if(formState.verificationUploadFormStatus === PostDataStatus.FAILURE){
    console.log(formState)
    content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{formState.errorVerificationUpload}</p>

            <div className='buttonHold'>   
                <section onClick={()=>{props.closeUploadVerificationModal()}}>Close</section>
            </div>
        </div>
  }

  

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default UploadVerificationModal