import React from 'react'
import Utils from '../../../../../../library/Utils'

function RegNumberType({reformedBrsData}) {
 
  return (
    <div style={{padding:"20px",backgroundColor:"white",borderRadius:"15px"}}>
        <p style={{color:'rgba(42, 56, 164, 1)',fontSize:"20px",fontWeight:"700"}}>Information report on BRS</p>
        <div className='report-fields'>

            <p 
                style={{
                    color:'#000000',
                    fontSize:'18px',
                    fontWeight:"700",
                    marginTop:"30px"
                }}>Business Information</p>
            {reformedBrsData.business.map((data,index)=> 
                <div className='keyValue-holder' key={index}>
                    <p className='key'>{data.key}</p>
                    <p className='value'>{Utils.checkNull(data.value)}</p>
                </div>
            )}
        </div>

        <div className='report-fields'>

        <p 
            style={{
                color:'#000000',
                fontSize:'18px',
                fontWeight:"700",
                marginTop:"30px"
            }}>
            Person of Interest
        </p>
            {reformedBrsData.persons.map((element,index)=>
            <section style={{
                marginTop:'30px'
            }}>
                {element.map((item,ind)=><div key={index} >
                {ind===4 && <strong>Other Shareholder Company Interest :</strong>}
          
                    <div className='keyValue-holder' key={ind}>
                        <p className='key'>{item.key}</p>
                        <p className='value'>{Utils.checkNull(item.value)}</p>
                    </div>

               
            </div>)}
            </section>
            )}
            
        </div>

    </div>
  )
}

export default RegNumberType