import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { BureauCreditCheckTypes, PostDataStatus } from "../../library/Variables";
import Spinner from "../../svg/Spinner/Spinner";
import "./BureauCreditCheck.css";
import Utils from "../../library/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postBlacklistAsync, postCreditCheckAsync, resetAll, selectBureauState } from "../../redux/Slices/Bureau";

function BureauCreditCheck(props) {
  let content = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const statementKey = props?.statementKey;
  const configuration = props.configuration;
  const field = props.field;
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const bureau = useSelector(selectBureauState);
  const [view, setView] = useState(PostDataStatus.INITIAL);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

  const resetForm = () => {
    dispatch(resetAll());
    reset();
  }
  
  const onSubmit = (formData) => {
    if(field.type === BureauCreditCheckTypes.CRA_NANO) {
      dispatch(postBlacklistAsync({
        bvn: formData.bvn,
        statementKey: statementKey,
        accessToken : accessToken
      }));
    } else if(field.type === BureauCreditCheckTypes.INDIVIDUAL) {
      dispatch(postCreditCheckAsync({
        bvn: formData.bvn,
        statementKey: statementKey,
        accessToken : accessToken,
        source: field.sources[formData.source].name
      }));
    } else if(field.type === BureauCreditCheckTypes.BUSINESS) {
      dispatch(postCreditCheckAsync({
        bvn: formData.bvn,
        statementKey: statementKey,
        accessToken : accessToken,
        source: "sme/crc"
      }));
    }
  }

  const handleStateChanges = (state) => {
    if(state === PostDataStatus.INITIAL) {
      setView(PostDataStatus.INITIAL);
    } else if(state === PostDataStatus.FETCHING) {
      setView(PostDataStatus.FETCHING);
    } else if(state === PostDataStatus.FAILURE) {
      setView(PostDataStatus.FAILURE);
    }
  }

  useEffect(() => {
    if(field.type === BureauCreditCheckTypes.CRA_NANO) {
      handleStateChanges(bureau.blacklistGenerateStatus);
    } else if(field.type === BureauCreditCheckTypes.INDIVIDUAL || field.type === BureauCreditCheckTypes.BUSINESS) {
      handleStateChanges(bureau.creditCheckGenerateStatus);
    }

    if(bureau.creditCheckGenerateStatus === PostDataStatus.SUCCESS || bureau.blacklistGenerateStatus === PostDataStatus.SUCCESS) {
      resetForm();
      toast.success("Credit Check data retrieved successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      props.close();
    }
  }, [bureau.creditCheckGenerateStatus, bureau.blacklistGenerateStatus])

  if(view === PostDataStatus.INITIAL) {
    let price = !Utils.isFieldEmpty(field.sources) ? field.sources[selectedSourceIndex].price : field.price;

    content = <form onSubmit={handleSubmit(onSubmit)} className="bureau-credit-check-form">
      <h4 className="detail-header">{field.title}</h4>
      <p className="text-medium-dark">
        <strong>Note: </strong>Please ensure BVN is correct before submitting. Each call to the Credit Bureau will incur a charge
         of {Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, price)}</p>
      {!Utils.isFieldEmpty(field.sources) && 
        <div className="bureau-credit-check-input-container">
          <label>Please select a source</label>
          <div className="bureau-credit-check-radio-fieldset">
            {field.sources.map((source, i) => {
              return <label className="bureau-credit-check-radio" key={i}>
                <input type="radio" name={source.name} value={i} {...register("source", { required: "Please select a source" })}
                  onChange={(e) => setSelectedSourceIndex(e.target.value)} />
                {source.label}
              </label> 
            })}
          </div>
        </div>
      }
      <div className="bureau-credit-check-input-container">
        <label>{field.type === BureauCreditCheckTypes.BUSINESS ? "RC Number" : "BVN"}</label>
        <input type="text" className="inputs" {...register("bvn", { required: "BVN is required" })}/>
      </div>
      <label className="bureau-credit-check-accept-check">
        <input type="checkbox" {...register("chargeAccept",
          { required: "You must agree to the charge before generating a report" })} />
        <p>By checking this box, you are agreeing to the call fee</p>
      </label>
      {errors.source?.message && <span className="text-error">{errors.source?.message}</span>}
      {errors.bvn?.message && <span className="text-error">{errors.bvn?.message}</span>}
      {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}
      <div>
        <button type="submit" className="bureau-credit-check-button button-solid button-square">
          Generate
        </button>
      </div>
    </form>
  } else if(view === PostDataStatus.FETCHING) {
    content = <Spinner />
  } else if(view === PostDataStatus.FAILURE) {
    content = <div className="bureau-credit-check-form">
      <div>
        <h4 className="detail-header">Request Failed</h4>
        <p>We were unable to retrieve any data.</p>
        <button className="bureau-credit-check-button button-solid button-square" onClick={() => resetForm()}>
          Try Again
        </button>
      </div>
    </div>
  } 

  return (
    <div className="bureau-credit-check-dialog">
      <div className="bureau-credit-check-header">
        <img src="/assets/icons/close-color.svg" alt="Close" onClick={props.close} />
      </div>
      {content}
    </div>
  )
}

export default BureauCreditCheck;