import { Navigate, Outlet } from "react-router-dom";
import Utils from "./Utils";
import { FetchDataStatus } from "./Variables";

export const ProtectedRoute = ({ user, redirectPath = "/", errorPath = "/error", isSettingsPage, notFoundPath = "/404", children }) => {
  if(user.status === FetchDataStatus.FAILURE) {
    return <Navigate to={errorPath} replace />;
  } else if(Utils.isFieldEmpty(user.user)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

/**
 * else if(!Utils.isFieldEmpty(user.user) && (isSettingsPage === true && user.user.permissions.canViewAllSettings === false)) {
    return <Navigate to={notFoundPath} replace />;
  }
 */