import React, {useState} from 'react'
import './kychistory.css'
import Utils from '../../../../library/Utils';
import { useDispatch } from 'react-redux';
import { getReportDetail,resetReport } from '../../../../redux/Slices/KycOptions';
import { getReportDetailKyb,resetReportKyb } from '../../../../redux/Slices/KybOptions';


function KenyaKycHistory({viewReport,setViewReport,
                          historyArray,tableHeaders,
                          accessToken,setVerificationType,
                          setHistoryArray,fullData,
                          setSelectedFilter,selectedFilter,
                          setTableHeaders,accountHeaders,
                          phoneHeaders}) {

    const dispatch = useDispatch()

    //swap filters
    const changeFilter = (filter)=>{
        const filteredArray = fullData
                            .filter(element => element.verificationType=== filter);
        setHistoryArray(filteredArray)
        setSelectedFilter(filter)
        if(filter==='PHONE_NUMBER'){
            setTableHeaders(phoneHeaders)
        }
        else if('ACCOUNT_NUMBER'){
            setTableHeaders(accountHeaders)
        }
        //dispatch(getFilteredLoan({accessToken,filter}))
     }
    

  return (
    <div className='kyc-history-holder'>
                
                

                <section className='filterHold'>
                    <section onClick={()=>changeFilter('PHONE_NUMBER') } 
                        className={selectedFilter==='PHONE_NUMBER'?'filterHeaderSelect':"filterHeaderNonSelect"}>
                        Phone Number Verification
                    </section>

                    <section onClick={()=>changeFilter('ACCOUNT_NUMBER') } 
                        className={selectedFilter==='ACCOUNT_NUMBER'?'filterHeaderSelect':"filterHeaderNonSelect"}>
                        Bank Account Verification
                    </section>
                </section>
                
                <div className='kyc-table-hold'>
                    <div className='header-hold'>
                        {tableHeaders.map(head=><p key={tableHeaders.indexOf(head)}>{head}</p>)}
                    </div>
                    <div className='body' >{historyArray.map(history=>
                        selectedFilter==='PHONE_NUMBER'?
                            (Utils.isFieldEmpty(historyArray)?
                                <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                                    You currently have no historical Phone Number Verification.
                                </div>:
                                <div className='inner' key={historyArray.indexOf(history)}>
                                    <p className='found' >SUCCESSFUL</p>
                                    <p className='element'>{history.verificationType}</p>
                                    <p className='element'><span>{history.fullName}</span></p>
                                    <p  className='element'>{history.trackingNumber}</p>
                                
                                </div>)
                            :selectedFilter==='ACCOUNT_NUMBER'?
                            (Utils.isFieldEmpty(historyArray)?
                                <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                                    You currently have no historical Account Number Verification.
                                </div>:
                            <div className='inner' key={historyArray.indexOf(history)}>
                                <p className='found' >SUCCESSFUL</p>
                                <p className='element'>{history.verificationType}</p>
                                <p className='element'><span>{history.fullName}</span></p>
                                <p className='element'><span>{history.fullName}</span></p>
                                <p  className='element'>{history.trackingNumber}</p>
                                
                            </div>)
                        :null)}
                    </div>

                </div>


            </div>
  )
}

export default KenyaKycHistory