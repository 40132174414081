import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';

export const getAllBureauReports = createAsyncThunk('getAllBureauReports', async (params) => {
  const bureau = await client.get(`/statements/getcustombureaudata/${params.key}`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

   return bureau.data;
});


export const getAllCrbReports = createAsyncThunk('getAllHistoricalCrbReports', async (params) => {
  const bureau = await client.get('/statements/custombureaudata/all',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

   return bureau.data;
});



export const postCreditCheckAsync = createAsyncThunk('getCreditCheck', async (params) => {
  let data = {
    bvn: params.bvn,
    statementKey: params.statementKey
  };

  data = JSON.stringify(data)
  const bureau = await client.post(`/statements/${params.source}`,data,
  {
     "headers":
     {
       "Authorization": "Bearer " + params.accessToken,
       'Content-Type': 'application/json'
     }
  });
  if(!bureau?.data){
    return bureau
  }
  else{

    return bureau.data;
  }
});

export const postBlacklistAsync = createAsyncThunk('getBlacklist', async (params) => {
  let data = {
    bvn: params.bvn,
    statement_key: params.statementKey
  };
  data = JSON.stringify(data)
  const bureau = await client.post(`/statements/blacklistsearch`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });

  if(!bureau?.data){
    return bureau
  }
  else{

    return bureau.data;
  }
});

export const addNewBureauReportAsync = createAsyncThunk('newBureau', async (params) => {
  let data = params.data;
  data = JSON.stringify(data)
  const bureau = await client.post(`/statements/custombureaudata`,data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });
  if(!bureau?.data){
    return bureau
  }
  else{

    return bureau.data;
  }
  }
);

export const bureauSlice = createSlice({
  name: 'bureau',
  initialState: {
    allBureauReports: [],
    allBureauReportsStatus: FetchDataStatus.INITIAL,
    blacklistGenerateStatus: PostDataStatus.INITIAL,
    creditCheckGenerateStatus: PostDataStatus.INITIAL,
    addNewReportStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null,


    CrbHistoricalData:[],
    CrbHistoricalDataStatus: FetchDataStatus.INITIAL,
    CrbHistoricalDataIsError: false,
    CrbHistoricalDataErrorMessage:null,
  },
  reducers: {
    resetAll: state => {
      state.allBureauReports = [];
      state.allBureauReportsStatus = FetchDataStatus.INITIAL;
      state.blacklistGenerateStatus = PostDataStatus.INITIAL;
      state.creditCheckGenerateStatus = PostDataStatus.INITIAL;
      state.addNewReportStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;

      state.CrbHistoricalData = [];
      state.CrbHistoricalDataStatus = FetchDataStatus.INITIAL;
      state.CrbHistoricalDataIsError = false;
      state.CrbHistoricalDataErrorMessage = null
    }
  },
  extraReducers(builder) {
    builder.addCase(getAllBureauReports.pending, (state) => {
      state.allBureauReportsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllBureauReports.fulfilled, (state, action) => {      
      state.allBureauReportsStatus = FetchDataStatus.SUCCESS;
      state.allBureauReports = action.payload;
    })
    .addCase(getAllBureauReports.rejected, (state, action) => {
      state.allBureauReportsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })




    builder.addCase(getAllCrbReports.pending, (state) => {
      state.CrbHistoricalDataStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getAllCrbReports.fulfilled, (state, action) => {      
      state.CrbHistoricalDataStatus = FetchDataStatus.SUCCESS;
      state.CrbHistoricalData = action.payload;
    })
    .addCase(getAllCrbReports.rejected, (state, action) => {
      state.CrbHistoricalDataStatus = FetchDataStatus.FAILURE;
      state.CrbHistoricalDataIsError = true;
      state.CrbHistoricalDataErrorMessage = action.error.message;
    })



    .addCase(addNewBureauReportAsync.pending, (state) => {
      state.addNewReportStatus = PostDataStatus.FETCHING;
    })
    .addCase(addNewBureauReportAsync.fulfilled, (state) => {      
      state.addNewReportStatus = PostDataStatus.SUCCESS;
    })
    .addCase(addNewBureauReportAsync.rejected, (state, action) => {
      state.addNewReportStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(postBlacklistAsync.pending, (state) => {
      state.blacklistGenerateStatus = PostDataStatus.FETCHING;
    })
    .addCase(postBlacklistAsync.fulfilled, (state) => {      
      state.blacklistGenerateStatus = PostDataStatus.SUCCESS;
    })
    .addCase(postBlacklistAsync.rejected, (state, action) => {
      state.blacklistGenerateStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(postCreditCheckAsync.pending, (state) => {
      state.creditCheckGenerateStatus = PostDataStatus.FETCHING;
    })
    .addCase(postCreditCheckAsync.fulfilled, (state) => {      
      state.creditCheckGenerateStatus = PostDataStatus.SUCCESS;
    })
    .addCase(postCreditCheckAsync.rejected, (state, action) => {
      state.creditCheckGenerateStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { resetAll } = bureauSlice.actions;

export const selectBureauState = state => state.bureau;

export default bureauSlice.reducer;