import React from 'react'
import { CashFlowInData, CashFlowOutData } from '../DataHolder/TransactDataBank'
import AnalysisCards from './AnalysisCards'
import TransactionHist from './TransactionHistory'

function CashFlowAnalysis() {

    const dataMainOne = [
        {item:'Total Credit',value:'₦500.00'},
        {item:'Total Debit',value:'₦500.00'},
        {item:'Current Balance',value:'₦500.00'},
        {item:'Average Balance',value:'₦500.00'}
    ]

    const dataMainTwo = [
        {item:'Highest Deposit',value:'₦500,000.00'},
        {item:'Highest Withdrawal',value:'₦500,000.00'},
    ]

    const listHeader = ['Year','Count','Amount']

  return (
    <div>
        <section style={{display:'flex',justifyContent:'space-between',columnGap:'6.5%'}}>
        {dataMainOne.map((element,index)=>
        <AnalysisCards key={index} 
                       width='20%' 
                       dataOne={element.item} 
                       dataTwo={element.value}/>)}
        </section>
        
        <section style={{display:'flex',columnGap:'6.5%',justifyContent:'space-between'}}>
            {dataMainTwo.map((element,index)=>
            <AnalysisCards key={index} 
                        width='45%' 
                        dataOne={element.item} 
                        dataTwo={element.value}/>)}
        </section>

        <section style={{display:'flex',columnGap:'6.5%',justifyContent:'space-between'}}>
            <TransactionHist 
             TransactionDataHistory={CashFlowOutData} 
            listHeader={listHeader} 
            fullWidth={false}/>

            <TransactionHist 
            TransactionDataHistory={CashFlowInData} 
            listHeader={listHeader} 
            fullWidth={false}/>
        </section>
    </div>
  )
}

export default CashFlowAnalysis