import React, { useEffect, useRef, useState } from "react";
import "./ActionMenu.css";

function ActionMenu(props) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const links = props.links.map((element, i) => {
    return <button key={i} style={{ color: element.color }} onClick={() => element.function()}>{element.action}</button>
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if(menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
  }, [menuRef]);

  return <div className="action-menu" ref={menuRef}>
    <button className="action-menu-toggle-button" onClick={() => setIsOpen(!isOpen)}>
      <img src="/assets/icons/action-menu.svg" alt={isOpen ? "close" : "open"} />
    </button>
    {isOpen &&
      <div className="action-links-container">
        {links}
      </div>
    }            
  </div>
}

export default ActionMenu;