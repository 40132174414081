import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const createTransactionCommentAsync = createAsyncThunk(
  "fraudDetect/createTransactionComment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        `/api/comments`,
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
        }
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
