import React from 'react'

function AnalysisCards({width,dataOne,dataTwo,key}) {
  return (
    <div style={{width:width,
                padding:'30px',
                boxSizing:"border-box",
                marginTop:'20px',
                borderRadius:'10px',
                backgroundColor:'white'}} key={key}>
        <section style={{color:'#000000',fontSize:"18px",fontWeight:"400",textAlign:"center"}}>
            {dataOne}
        </section>
        <section style={{color:'#2A38A4',fontSize:"32px",fontWeight:"700",textAlign:"center"}}>
            {dataTwo}
        </section>
    </div>
  )
}

export default AnalysisCards