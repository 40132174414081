import React from 'react'
import Utils from '../../../../../../../library/Utils'
import { Link } from 'react-router-dom';


function MediaDetails({media}) {
    
  let Content;
  if(Utils.isFieldEmpty(media)){
    Content = <span style={{padding:'10px'}}>No Media data for this Business In Particular.</span>
  }

  if(!Utils.isFieldEmpty(media)){
    
    Content = <div style={{}}>
                {media.map((element,index)=>
                    <section key={index} style={{display:"flex",justifyContent:'space-between',flexDirection:"column",width:"100%",marginBottom:"30px"}}>
                        <div style={{marginBottom:"15px"}} >{element?.title?element.title:""}</div>
                        <a href={element.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none',color:"blue" }}>
                          {element.snippet}
                        </a>
                    </section>)
                }
             </div>
  }



  return (
    <div style={{width:"100%",padding:"15px",boxSizing:"border-box"}}>
        {Content}
    </div>
  )
}

export default MediaDetails