import React from 'react'
import Utils from "../../../../../library/Utils"
import SerialOutPutPrint from './SerialOutPutPrint'

function ScoreOutput({data}) {
  const ScoreKeys = Object.keys(data)
  return (
    <>
      {!Utils.isFieldEmpty(ScoreKeys) && <SerialOutPutPrint keys={ScoreKeys} data={data} header={'Score Output Data'}/>}
    </>
    
    
  )
}

export default ScoreOutput