import { createSlice } from "@reduxjs/toolkit";

import { fetchUserAddressAsync } from "./thunks";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fraudUserAddressSlice = createSlice({
  name: "fetchUserAddressSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserAddressAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchUserAddressAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});
