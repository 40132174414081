import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { getAccountDataAsync,
        selectKenyaStatementData,
        resetKenyaKycData,
        getPhoneDataAsync,getKenyaStatementKycHistory } from '../../../../redux/Slices/StatementKyc/Kenya/KenyaStatementKyc'
import { addToExtractedData,getBvnDataAsync,resetKycData,selectKycEntireDataState } from '../../../../redux/Slices/KycOptions'
import { PostDataStatus } from '../../../../library/Variables'
import errorImage from '../../../../assests/icons/errorImage.png'
import './phoneholder.css'
import successImg from '../../../../assests/icons/amlFound.png'
import errorImg from '../../../../assests/icons/amlEmpty.png'

function PhoneModal(props) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKenyaStatementData)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const accessToken = props.accessToken
  const keyFromRoute = props.keyFromRoute
  const onSubmit = (formData) => {

    const obj = {bankAccount:formData.bankAccount,
        statementKey: keyFromRoute,
        phoneNumber:formData.phoneNumber,
        accessToken
        }
       
        dispatch(getPhoneDataAsync(obj))

  }


  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div className='formDiv'>
       <p className='title-class'>Phone Number Verification</p>
       <div style={{}}>
           <p>Input Phone Number</p>
           <input  style={props.inputStyle}{...register("phoneNumber", { required: true })} />
           {errors.phoneNumber && <span className="text-error">This field is required</span>}
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                    props.setKycToShow(null);dispatch(resetKenyaKycData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div style={{height:"300px"}}>
    <Spinner></Spinner>
    <p style={{textAlign:'center',color:'#2A38A4',fontSize:"24px",fontWeight:"700"}}>Verification in progress...</p>
    <p style={{textAlign:'center',color:"#363C4F",fontSize:"16px",fontWeight:'400'}}>Hold on. This will only take a few seconds</p>
  </div>

 

const ModalError = <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={errorImg}/>
        </div>
        <p style={{textAlign:'center',
                    fontSize:"24px",
                    fontWeight:"700",
                    color:'#E40000'}}>
                    {kycState.errorMessage}
        </p>

        <p style={{color:'#363C4F',
                    fontSize:"16px",
                    fontWeight:"400" }}>
            Click Done to see
        </p>

        <div className='modal-initial-button-hold'>
            <span className='failed' onClick={()=>{setIsOpen(false);props.setKycToShow(null);dispatch(resetKenyaKycData())}}>Cancel</span>
            
        </div>
        </div>


    const ModalSuccess = <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
        </div>
        <p style={{textAlign:'center',
                color:"#2A38A4",
                fontSize:"24px",
                fontWeight:"700"}}>Phone Number checked successfull</p>
        <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
            Click Done to see
        </p>
        <div className='modal-initial-button-hold'>
            <span className='check' onClick={()=>{
                                                 dispatch(getKenyaStatementKycHistory({key:keyFromRoute,accessToken}))
                                                 setIsOpen(false);
                                                 props.setKycToShow(null);
                                                 dispatch(resetKenyaKycData());
                                                 }}>Done</span>
            
        </div>
    </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.extractionStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.extractionStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.extractionStatus===PostDataStatus.SUCCESS){
            
            //props.setKycToShow(null)
            //setIsOpen(false)
            //dispatch(resetKenyaKycData())
            setModal(ModalSuccess)
            
        }

        if(kycState.extractionStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={props.customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default PhoneModal