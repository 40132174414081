import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../../../library/Variables";




  export const postBasicCrb = createAsyncThunk('crbKenya/basicCrb', async (params) => {
    
    const data = JSON.stringify(params.data)
    const postBasicCrbResponse = await client.post('/statements/ke/crb',data,
        {
            "headers":
              {
                "Authorization": "Bearer " + params.accessToken,
                'Content-Type': 'application/json'
              }
          });
    
        return postBasicCrbResponse;
    }
    );

    
  export const postAdvancedCrb = createAsyncThunk('crbKenya/advancedCrb', async (params) => {
    
    const data = JSON.stringify(params.data)
    const postAdvancedCrbResponse = await client.post('/statements/ke/crb-advanced',data,
        {
            "headers":
              {
                "Authorization": "Bearer " + params.accessToken,
                'Content-Type': 'application/json'
              }
          });
    
        return postAdvancedCrbResponse;
    }
    );



    
  export const postBusinessCrb = createAsyncThunk('crbKenya/businessCrb', async (params) => {
    
    const data = JSON.stringify(params.data)
    const postBusinessCrbResponse = await client.post('/statements/ke/crb-business',data,
        {
            "headers":
              {
                "Authorization": "Bearer " + params.accessToken,
                'Content-Type': 'application/json'
              }
          });
    
        return postBusinessCrbResponse;
    }
    );







 


export const crbKenyaDataSlice = createSlice({
  name: 'crbKenyaData',
  initialState: {
    crbKenyaBasicData: null,
    crbKenyaBasicStatus: PostDataStatus.INITIAL,
    crbKenyaBasicisError: false,
    crbKenyaBasicErrorMessage:null,


    crbKenyaAdvancedData: null,
    crbKenyaAdvancedStatus: PostDataStatus.INITIAL,
    crbKenyaAdvancedisError: false,
    crbKenyaAdvancedErrorMessage:null,


    crbKenyaBusinessData: null,
    crbKenyaBusinessStatus: PostDataStatus.INITIAL,
    crbKenyaBusinessisError: false,
    crbKenyaBusinessErrorMessage:null,


  },
  reducers: {
  
    resetCrbKenyaBasic: (state)=>{
      state.crbKenyaBasicData = null;
      state.crbKenyaBasicStatus = PostDataStatus.INITIAL;
      state.crbKenyaBasicisError = false;
      state.crbKenyaBasicErrorMessage = null;

    },


    resetCrbKenyaAdvanced: (state)=>{
        state.crbKenyaAdvancedData = null;
        state.crbKenyaAdvancedStatus = PostDataStatus.INITIAL;
        state.crbKenyaAdvancedisError = false;
        state.crbKenyaAdvancedErrorMessage = null;

    },


    resetCrbKenyaBusiness: (state)=>{
      state.crbKenyaBusinessData = null;
      state.crbKenyaBusinessStatus = PostDataStatus.INITIAL;
      state.crbKenyaBusinessisError = false;
      state.crbKenyaBusinessErrorMessage = null;

  }
  },
  
  extraReducers(builder) {

  builder.addCase(postBasicCrb.pending, (state)=>{
    state.crbKenyaBasicStatus = PostDataStatus.FETCHING
  })
  .addCase(postBasicCrb.fulfilled,(state,action)=>{
    state.crbKenyaBasicStatus = PostDataStatus.SUCCESS
    state.crbKenyaBasicData = action.payload
  })
  .addCase(postBasicCrb.rejected,(state,action)=>{
    state.crbKenyaBasicStatus = PostDataStatus.FAILURE
    state.crbKenyaBasicErrorMessage = action.error.message
    state.crbKenyaBasicisError = true

  })



  .addCase(postAdvancedCrb.pending, (state)=>{
    state.crbKenyaAdvancedStatus = PostDataStatus.FETCHING
  })
  .addCase(postAdvancedCrb.fulfilled,(state,action)=>{
    state.crbKenyaAdvancedStatus = PostDataStatus.SUCCESS
    state.crbKenyaAdvancedData = action.payload
  })
  .addCase(postAdvancedCrb.rejected,(state,action)=>{
    state.crbKenyaAdvancedStatus = PostDataStatus.FAILURE
    state.crbKenyaAdvancedErrorMessage = action.error.message
    state.crbKenyaAdvancedisError = true

  })



  .addCase(postBusinessCrb.pending, (state)=>{
    state.crbKenyaBusinessStatus = PostDataStatus.FETCHING
  })
  .addCase(postBusinessCrb.fulfilled,(state,action)=>{
    state.crbKenyaBusinessStatus = PostDataStatus.SUCCESS
    state.crbKenyaBusinessData = action.payload
  })
  .addCase(postBusinessCrb.rejected,(state,action)=>{
    state.crbKenyaBusinessStatus = PostDataStatus.FAILURE
    state.crbKenyaBusinessErrorMessage = action.error.message
    state.crbKenyaBusinessisError = true

  })





  
  }
})


export const { resetCrbKenyaBasic,
                resetCrbKenyaAdvanced,
                resetCrbKenyaBusiness,
               } = crbKenyaDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export const selectCrbKenyaData  = state => state.crbKenyaData


export default crbKenyaDataSlice.reducer;