import Spinner from "../../../svg/Spinner/Spinner";
import "../Modal.css";
import "./UserManagement.css";
import { PostDataStatus } from "../../../library/Variables";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetUpdateRole, updateRoleAsync } from "../../../redux/Slices/UserManagement";
import Permissions from "../../Permissions/Permissions";

function ManageRole(props) {
  let content = null;

  const dispatch = useDispatch();
  const methods = useForm();
  const accessToken = props.accessToken;
  const userManagement = props.userManagement;
  const role = props.role;
  const allRoles = role.generalPermissions.concat(role.managePermissions, role.settingsPermissions);

  const groups = [
    { 
      title: "General",
      rows: role.generalPermissions
    },
    {
      title: "Manage roles and permissions",
      rows: role.managePermissions
    },
    {
      title: "Settings",
      rows: role.settingsPermissions
    }
  ]

  const params = {
    accessToken: accessToken,
    data: {
      permissionKeys: []
    }
  }

  const onSubmit = (formData) => {
    params.data.roleKey = role.roleId;

    allRoles.forEach(element => {
      if(formData[element.name] === true) {
        params.data.permissionKeys.push(element.key);
      }
    });

    dispatch(updateRoleAsync(params));
  }

  const resetForm = () => {
    methods.reset();
    dispatch(resetUpdateRole());
  }

  if(userManagement.updateRoleStatus === PostDataStatus.INITIAL) {
    content = <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="user-management-form-container">
        <div className="modal-body-container">
          <Permissions groups={groups} type={role.label} isDisabled={false} />
        </div>
        <div className="submit-container">
          <button type="button" className="button-square button-outline" onClick={props.close}>Cancel</button>
          <button type="submit" className="button-square button-solid">Update</button>
        </div>
      </form>
    </FormProvider>
  } else if(userManagement.updateRoleStatus === PostDataStatus.FETCHING) {
    content = <Spinner />
  } else if(userManagement.updateRoleStatus === PostDataStatus.SUCCESS) {
    content = <div className="modal-body-container">
      <div className="modal-body-message">
        <p>Role updated successfully</p>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={props.close}>Close</button>
      </div>
    </div>
  } else if(userManagement.updateRoleStatus === PostDataStatus.FAILURE) {
    content = <div className="modal-body-container">
      <p>Failed to update role</p>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => resetForm()}>Try again</button>
      </div>
    </div>
  }
  
  return(
    <div className="modal-dialog user-management-modal">
      <div className="modal-header">
        <h2 className="detail-header"></h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      {content}
    </div>
  )
}

export default ManageRole;