import React,{useState,useEffect,useMemo} from 'react'
import './tenantMgt.css'
import AddNewTenantModal from '../../../../components/Modals/MultiTenantModals/AddNewTenant'
import Modal from "react-modal";
import { useSelector,useDispatch } from 'react-redux';
import { FetchDataStatus } from '../../../../library/Variables';
import { Table, SelectColumnFilter, DateRangeColumnFilter } from '../../../../components/Table/Table';
import Utils from '../../../../library/Utils';
import tenantUserOption from '../../../../assests/svg-icons/tenantUserOption.svg'
import { fetchAllClients,selectTenantUsersState } from '../../../../redux/Slices/Settings/MultiTenantManagement/TenantPerUser';
import { resetDeleteUser } from '../../../../redux/Slices/UserManagement';
import { selectUserManagementState } from '../../../../redux/Slices/UserManagement';
import DeleteUser from '../../../../components/Modals/UserManagement/DeleteUser';
import EditAndDeleteTenant from './EditAndDeleteTenant';
import EditTenantModal from '../../../../components/Modals/MultiTenantModals/EditTenantModal';
import errorImg from '../../../../assests/icons/errorImage.png'
import { deleteUserAsync } from '../../../../redux/Slices/UserManagement';
import Spinner from '../../../../svg/Spinner/Spinner';



function TenantManagement({accessToken}) {
  const dispatch = useDispatch()
  const [openTenantModal, setOpenTenantModal] = useState(false);
  const tenantState = useSelector(selectTenantUsersState)
  const [userLists,setUserList] = useState([])
  const [deleteModalIsOpen, setIsDeleteOpen] = useState(false);
  const userManagement = useSelector(selectUserManagementState);
  const [selectId,setSelectedId] = useState('')
  const [showOptions,setShowOptions] = useState(false)
  const [openEditModal,setOpenEditModal] = useState(false)
  const [clientName,setClientName] = useState('')
  const [clientEmail,setClientEmail] = useState('')
  const [existingTenants,setExistingTenants] = useState([])

  const [user, setUser] = useState({});

  const afterOpenModal = () => {}

  const closeDeleteModal = () => {
    if(userManagement.deleteUserStatus === FetchDataStatus.SUCCESS || userManagement.deleteUserStatus === FetchDataStatus.FAILURE) {
      dispatch(resetDeleteUser());
    }
    setIsDeleteOpen(false);
  }


  function statusColor(statusCode){
    var color = "#000000"
    if(statusCode == '200'){
      color = "#4da64b"
    }
    else if(statusCode == '400' || statusCode == '404'){
      color = "#f5880c"
    }
    else if(statusCode == '500'){
      color = "#c93f3c"
    }
    return color;
  }

  function statusDescription(statusCode){
    var status = statusCode
    if(statusCode == '200'){
      status = "SUCCESS"
    }
    else if(statusCode == '400'){
      status = "INVALID REQUEST"
    }
    else if(statusCode == '404'){
      status = "NOT FOUND"
    }
    else if(statusCode == '500'){
      status = "FAILED"
    }
    return status;
  }

  useEffect(()=>{
    if(tenantState.allTenantUserStatus === FetchDataStatus.INITIAL){
        dispatch(fetchAllClients({accessToken}))
    }

    if(tenantState.allTenantUserStatus === FetchDataStatus.SUCCESS){
        let userTempList = []
     
        tenantState.allTenantUsersData.forEach(element => {
          userTempList.push(
            {
              "user": !Utils.isFieldEmpty(element.firstName) ? element.firstName + ' ' +element?.lastName: '--',
              "noOfAccnt": element.noOfAccounts,
              "countries": element.countries,
              "tenants": element.linkedTenants,
              "id": element.id,
              "email": element.email,
              "firstName": element.firstName,
              "lastName": element.lastName
              
            }
          )
        });
        setUserList(userTempList)
    }
    
  },[tenantState.allTenantUserStatus])

  const theadersWalletDebits = useMemo(() => [
    { 
      Header: 'User',
      accessor: 'user',
    },
    { 
      Header: 'Number of Accounts',
      accessor: 'noOfAccnt'
    },    

    {
      Cell: data => {
        let listOfCountries = data.row.original.countries;
        return (
          <div style={{display:"flex",columnGap:"7px"}}>
            {listOfCountries.map(el=><span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <img src={el}/>
            </span>)}
          </div>
        );
      },
      Header: 'Countries',
      accessor: 'tenants',
      Filter: SelectColumnFilter,
    },

    {
      Cell: data => {
        let tenantId = data.row.original.id;
        let tenantsLinked = data.row.original.tenants;
        let name = data.row.original.firstName + ' '+data.row.original.lastName;
        let email = data.row.original.email;
        let listOfFlags = data.row.original.countries;
        let createdFlagTenant = []
        for(let i=0; i<tenantsLinked.length;i++){
          createdFlagTenant.push({url:listOfFlags[i],name:tenantsLinked[i]})
        }

        return (
          <section style={{position:'relative'}}>
            <div onClick={()=>{setSelectedId(tenantId);
                               setShowOptions(!showOptions);
                               setClientName(name);
                               setClientEmail(email);
                               setExistingTenants(createdFlagTenant)}} 
                 style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:'pointer',}}>
                <img src={tenantUserOption}/>
            </div>
            {(selectId === tenantId && showOptions) && <EditAndDeleteTenant tenantUserOption={tenantUserOption} 
                                                                            setIsDeleteOpen={setIsDeleteOpen}
                                                                            setOpenEditModal={setOpenEditModal}
                              
                               />}
        </section>

        );
      },
      Header: 'Action',
      accessor: 'id',
    },
  ], [selectId,showOptions]
  )

  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "50%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const customDeleteStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "33%",
      top: "33%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const closeTenantModalFunc = () => {
    setOpenTenantModal(false);
  }

  const closeTenantEditModalFunc = () => {
    setOpenEditModal(false);
  }


  return (
    <div style={{height:'auto',marginBottom:"20px"}}>
        <section className='tenantHeader'>
            <div className='Manage'>
              Manage Account
            </div>

            <div className='tenantInfoParent'>
              <section className='tenantInfoHold' >
                <span className='exploretab'>
                  Explore
                </span>
                <span className='exploretabLower'>
                  We have created an easy and straightforward process for managing accounts to ensure you have the best experience
                </span>
              </section>
              <section className='tenantButton' onClick={()=>setOpenTenantModal(true)}>
                Add New User
              </section>
            </div>
        </section>
        <section style={{width:"100%",
                         backgroundColor:"white"}}>
                         {
                          tenantState.allTenantUserStatus === FetchDataStatus.INITIAL?
                            <Spinner/>
                          :
                          tenantState.allTenantUserStatus === FetchDataStatus.FETCHING?
                            <Spinner/>
                          :
                          tenantState.allTenantUserStatus === FetchDataStatus.SUCCESS?
                          <>
                            {!Utils.isFieldEmpty(userLists) && <Table data={userLists} 
                                                      columns={theadersWalletDebits} 
                                                      pagination={true} 
                                                      pageSize={[10]} 
                                                      filterColor={"#2A38A4"} 
                                                      filterClass="filter-primary-darkest" 
                                                      tableExtra={false} 
                                                      tableFilter={true} 
                                                      rowClass={"table-row-two-colored"} />}
                          </>:
                          <div style={{display:"flex",
                                      flexDirection:"column",
                                      height:"200px",
                                      justifyContent:"space-around",
                                      alignItems:"center"}}>
                              <span style={{display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            padding:"5px"}}>
                                    <img style={{width:"30px",
                                                height:"30px"}} src={errorImg}/>

                              </span>
                              <p style={{color:'#363C4F'}}>Something went wrong!</p>
                              <p style={{color:"red",
                                         fontWeight:"700",
                                         fontSize:"24px"}}>
                                  {tenantState.allTenantUserErrorMessage}
                              </p>

        
                          </div>
                         }

            

        </section>

        <Modal isOpen={openTenantModal}  style={customStyles} >
            <AddNewTenantModal        
                close={closeTenantModalFunc} 
                accessToken={accessToken} 
                />
      </Modal>

      <Modal isOpen={openEditModal}  style={customStyles} >
            <EditTenantModal        
                close={closeTenantEditModalFunc} 
                accessToken={accessToken} 
                existingTenants={existingTenants}
                clientName={clientName}
                clientEmail={clientEmail}
                setSelectedId={setSelectedId}
                />
      </Modal>


      <Modal isOpen={deleteModalIsOpen} 
             onAfterOpen={afterOpenModal} 
             onRequestClose={closeDeleteModal} 
             style={customDeleteStyles} >
             <DeleteUser close={closeDeleteModal} 
                          userManagement={userManagement} 
                          user={{id:selectId}} 
                          accessToken={accessToken} />
      </Modal>
    </div>
  )
}

export default TenantManagement