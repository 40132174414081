import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus,PostDataStatus } from '../../library/Variables';

const initialState = {
    supportDocDataStatus:PostDataStatus.INITIAL,
    supportDocData:null,
    supportDocDataisError:false,
    supportDocDataerrorMessage:null,
    
  };

  export const getSupportDocData = createAsyncThunk('supportDocs/data',  async (params) => {    
    let response = await client.get(`/loan/support-documents/${params.key}`,
      {
        "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
      });

    return response.data;
  });


  export const getCategories = createAsyncThunk('supportDocs/categories',  async (params) => {    
    let response = await client.get('/loan/categories',
      {
        "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
      });

    return response.data;
  });


  export const getSupportDocFile = createAsyncThunk('supportDocs/getFile',async (params) => {
  
  
    const data = JSON.stringify(params.data)
    const signature = await client.post('/loan/view-file', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return signature;
  })


  export const deleteSupportDocFile = createAsyncThunk('supportDocs/deleteFile',async (params) => {
  
  
    const data = JSON.stringify(params.data)
    const resp = await client.post('/loan/deletefile', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return resp;
  })





  export const uploadSupportPdf = createAsyncThunk('supportDocs/uploadDocs', async (params) => {
    let data = new FormData();

    data.append('categoryIds', params.categoryIds);
    data.append('names', params.statementTypes);
    data.append('loanApplicationKey',params.loanApplicationKey)
    params.files.forEach((file) => {
      data.append('files', file);
    });
    
    const statement = await client.post('/loan/add-support-documents', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken
        }
    });
    return statement.data;
  });

export const supportingDocSlice = createSlice({
    name: 'supportingDocs',
    initialState: {
        supportDocDataStatus:PostDataStatus.INITIAL,
        supportDocData:null,
        supportDocDataisError:false,
        supportDocDataerrorMessage:null,

        supportDocUploadStatus:PostDataStatus.INITIAL,
        supportDocUploadData:null,
        supportDocUploadisError:false,
        supportDocUploaderrorMessage:null,

        supportCategoriesStatus:FetchDataStatus.INITIAL,
        supportCategoryData:null,
        supportCategoryisError:false,
        supportCategoryerrorMessage:null,

        supportDocFileStatus: PostDataStatus.INITIAL,
        supportDocFileData: null,
        supportDocFileisError: false,
        supportDocErrorMessage:null,


        deleteDocStatus: PostDataStatus.INITIAL,
        deleteDocResponse: null,
        deleteDocResponseisError: false,
        deleteDocResponseErrorMessage:null
      },
    reducers: {
      resetDocsFetch : state => {
        state.supportDocDataStatus = FetchDataStatus.INITIAL;
        state.supportDocData = null;
        state.supportDocDataerrorMessage = null;
        state.supportDocDataisError = false;
      }
      ,
      resetDocUpload : state => {
        state.supportDocUploadStatus = PostDataStatus.INITIAL;
        state.supportDocUploadData = null;
        state.supportDocUploadisError = false;
        state.supportDocUploaderrorMessage = null;
      },

      resetDocFileUpload : state => {
        state.supportDocFileStatus = PostDataStatus.INITIAL;
        state.supportDocFileData = null;
        state.supportDocFileisError = false;
        state.supportDocErrorMessage = null;
      },

      resetDeleteDoc : state =>{
        state.deleteDocStatus = PostDataStatus.INITIAL;
        state.deleteDocResponse = null;
        state.deleteDocResponseisError = false;
        state.deleteDocResponseErrorMessage = null;
      }
    },
    extraReducers(builder) {
      builder.addCase(getSupportDocData.pending, (state, action) => {
        state.supportDocDataStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getSupportDocData.fulfilled, (state, action) => {
        state.supportDocDataStatus = FetchDataStatus.SUCCESS;
        state.supportDocData = action.payload
      })
      .addCase(getSupportDocData.rejected, (state, action) => {
        state.supportDocDataStatus = FetchDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
      })


      .addCase(uploadSupportPdf.pending, (state, action) => {
        state.supportDocUploadStatus = PostDataStatus.FETCHING;
      })
      .addCase(uploadSupportPdf.fulfilled, (state, action) => {
        state.supportDocUploadStatus = PostDataStatus.SUCCESS;
        state.supportDocUploadData = action.payload
      })
      .addCase(uploadSupportPdf.rejected, (state, action) => {
        state.supportDocUploadStatus = PostDataStatus.FAILURE;
        state.supportDocUploadisError = true;
        state.supportDocUploaderrorMessage = action.error.message;
      })




      .addCase(getCategories.pending, (state, action) => {
        state.supportCategoriesStatus = FetchDataStatus.INITIAL;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.supportCategoriesStatus = FetchDataStatus.SUCCESS;
        state.supportCategoryData = action.payload
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.supportCategoriesStatus = PostDataStatus.FAILURE;
        state.supportCategoryisError = true;
        state.supportCategoryerrorMessage = action.error.message;
      })


      .addCase(getSupportDocFile.pending, (state, action) => {
        state.supportDocFileStatus = PostDataStatus.INITIAL;
      })
      .addCase(getSupportDocFile.fulfilled, (state, action) => {
        state.supportDocFileStatus = FetchDataStatus.SUCCESS;
        state.supportDocFileData = action.payload
      })
      .addCase(getSupportDocFile.rejected, (state, action) => {
        state.supportDocFileStatus = PostDataStatus.FAILURE;
        state.supportDocFileisError = true;
        state.supportDocErrorMessage = action.error.message;
      })



      .addCase(deleteSupportDocFile.pending, (state, action) => {
        state.deleteDocStatus = PostDataStatus.INITIAL;
      })
      .addCase(deleteSupportDocFile.fulfilled, (state, action) => {
        state.deleteDocStatus = FetchDataStatus.SUCCESS;
        state.deleteDocResponse = action.payload
      })
      .addCase(deleteSupportDocFile.rejected, (state, action) => {
        state.deleteDocStatus = PostDataStatus.FAILURE;
        state.deleteDocResponseisError = true;
        state.deleteDocResponseErrorMessage = action.error.message;
      })




    }
  });

export const { resetDocUpload,resetDocsFetch,resetDocFileUpload,resetDeleteDoc} = supportingDocSlice.actions;

export const selectSupportDocs = state => state.supportingDocs;

export default supportingDocSlice.reducer; 