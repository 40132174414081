import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import nairaIcon from '../../../../assests/icons/nairaIcon.png'
import { naijaStates } from './NigerianStates';
import AmountInput from '../../../../components/loanComponents/AmountInput';
import PhoneInput from '../../../../components/loanComponents/PhoneInput';
import Utils from '../../../../library/Utils';

function ClientIdSection(props) {
    const register = props.register
    const setValue = props.setValue
    const errors = props?.errors
    const fixedClientFields = props.fixedClientFields
    const setFixedClientFields = props.setFixedClientFields
    const setTriggerAmountSave = props.setTriggerAmountSave
    const triggerAmountSave = props.triggerAmountSave
    const triggerPhoneSave = props.triggerPhoneSave
    const setTriggerPhoneSave = props.setTriggerPhoneSave
    const firstName = props.firstName
    const lastName = props.lastName
    const middleName = props.middleName
    const clientId = props.clientId
    const slicedData = props?.slicedData ? props.slicedData : ''
    const [clientIdError,setClientIdError] = useState(null)
    const [firstNameError,setFirstNameError] = useState(null)
    const [lastNameError,setLastNameError] = useState(null)
    const [middleNameError,setMiddleNameError] = useState(null)


    const optionChecker = (data)=>{
        if(data.friendlyName==='Number Of Dependents'){
          let numbersArray = [];
          for (let i = 1; i <= 20; i++) {
              numbersArray.push(i);
          }
          return numbersArray
        }

        if(data.friendlyName==='State'){
          let statesArray = [];
          for (let i = 0; i <= naijaStates.length; i++) {
            statesArray.push(i);
          }
          return statesArray
        }

        else{
          return data.options
        }
    }

    useEffect(()=>{
      if(!Utils.isFieldEmpty(firstName) || !Utils.isFieldEmpty(lastName) || !Utils.isFieldEmpty(middleName) 
        || !Utils.isFieldEmpty(clientId)){
        setFixedClientFields({firstName,lastName,middleName,clientId});
      }
    },[])

    const handleFirstNameChange = (event) => {
      const value = event.target.value;
      setFixedClientFields({...fixedClientFields,firstName:value});
      
    };

    /**
     * 
     * @param {   const handleFirstNameChange = (event) => {
      const value = event.target.value;
      const isValid = /^[a-zA-Z\s(){}\[\]&.-_-]*$/.test(value);
      setFirstNameError(isValid ? null : 'First Name format is invalid');
      if(isValid){
        setFixedClientFields({...fixedClientFields,firstName:value});
      }
    };} event 
     */

    const handleMiddleNameChange = (event) => {
      const value = event.target.value;
      setFixedClientFields({...fixedClientFields,middleName:value});
      
    };

    const handleLastNameChange = (event) => {
      const value = event.target.value;
      setFixedClientFields({...fixedClientFields,lastName:value});
      
    };
    

    const handleClientIdChange = (event) => {
      const value = event.target.value;
        setFixedClientFields({...fixedClientFields,clientId:value});
    };

    

    useEffect(()=>{
        if(!Utils.isFieldEmpty(slicedData)){
          let phoneObject = {}
          let amountObject = {}
          props.info.forEach((element,index) => {
              slicedData.forEach((sliced)=>{
                if(sliced.friendlyName===element.friendlyName){
                  if(sliced.displayType === 'CURRENCY'){
                  
                    let key = sliced.friendlyName;
                    let value = sliced.value;
                    amountObject[key] = value;
                      
                  }

                  else if(sliced.friendlyName.includes('Phone')){
                    let key = sliced.friendlyName;
                    let value = sliced.value;
                    phoneObject[key] = value;
                    setTriggerPhoneSave(sliced.value)
                  }

                  else{

                    setValue(element.name,sliced.value)
                  }
                }

                setTriggerPhoneSave(phoneObject)
                setTriggerAmountSave(amountObject)
              })
          });
        }
    },[])
  
  return (
    <div style={{width:'100%',boxSizing:"border-box",boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius: '10px',backgroundColor:"white",padding:'2em'}}>
              <p className='formTitle'>Client Information</p>
              <section className='sign-up-details-top-inputs'>
                <div className='sign-up-details-top-input-container'> 
                  <label>First Name <span className="required">*</span></label>
                  <input className="input-pill-single" placeholder='input first name' autoComplete="off" type='TEXT' 
                  value={fixedClientFields.firstName} onChange={(event)=>handleFirstNameChange(event)} />
                  {firstNameError && <p style={{ color: "red"}}>{firstNameError}</p>}
                </div>

                <div className='sign-up-details-top-input-container'> 
                  <label>Middle Name </label>
                  <input className="input-pill-single" placeholder='input first name' autoComplete="off" type='TEXT' 
                  value={fixedClientFields.middleName} onChange={(event)=>handleMiddleNameChange(event)} />
                  {middleNameError && <p style={{ color: "red"}}>{middleNameError}</p>}
                </div>

                <div className='sign-up-details-top-input-container'> 
                  <label>Last Name <span className="required">*</span></label>
                  <input className="input-pill-single" placeholder='input first name' autoComplete="off" type='TEXT' 
                  value={fixedClientFields.lastName} onChange={(event)=>handleLastNameChange(event)} />
                  {lastNameError && <p style={{ color: "red"}}>{lastNameError}</p>}
                </div>
              </section>
              <section className='sign-up-details-inputs' >
                <div className='sign-up-details-input-container'> 
                  <label>Client ID Number <span className="required">*</span></label>
                  <input className="input-pill-single" placeholder='input first name' autoComplete="off" type='NUMBER' 
                  value={fixedClientFields.clientId} onChange={(event)=>handleClientIdChange(event)} />
                  {clientIdError && <p style={{ color: "red"}}>{clientIdError}</p>}
                </div>
                {
                  lodash.sortBy(props.info,'rank').map((data,id)=><div style={{width:"100%"}} key={id} id={id}>
                  {data.displayType==='SELECT'?
                    <div className='sign-up-details-input-container'>
                    <label>{data.friendlyName} <span className="required" >{data.isRequired?'*':''}</span></label>
                    <div className='selectHolder'>
                        <select  {...register(`${data.name}`, { required: data.isRequired?true:false, })} 
                          onChange={(e) => props.handleListSelect(e.target.value)}>
                          <option value="">Select an option</option>
                          {optionChecker(data).map((element, i) => {
                            return <option value={element} key={i}>{element}</option>
                          })}
                        </select>
                    </div>
                    <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                    </div>:

                    data.displayType==='TEXT'|| data.displayType==='NUMBER'?
                          <div style={{width:"100%"}}>
                            {data.friendlyName.toLowerCase().includes('phone')?
                            <PhoneInput data={data} register={register} setValue={setValue} errors={errors}
                                        triggerPhoneSave={triggerPhoneSave} 
                            />:
                              <div className='sign-up-details-input-container'> 
                                <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                                <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autoComplete="off" type={data.displayType}  {...register(`${data.name}`, 
                                { 
                                  required: data.isRequired?true:false, 
                                
                                })} />
                                <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                            </div>
                            }
                          </div>
                          :

                          <AmountInput triggerAmountSave={triggerAmountSave} data={data} register={register} setValue={setValue} errors={errors}/>
                    
                  }
                  </div>)
                }
              </section>
      </div>
        
  )
}

export default ClientIdSection