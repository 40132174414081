import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus,PostDataStatus } from "../../../../library/Variables";




export const generateAuditTrail = createAsyncThunk('Audit/changes',async(params)=>{
    const auditData = await client.get(`/loan/audit-trail/${params.loanKey}`,
    {
      "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
    });
  
    return auditData.data;
  } )




export const auditSlice = createSlice({
    name: 'auditTrail',
    initialState: {
        auditTrailData:null,
        auditTrailStatus:FetchDataStatus.INITIAL,
        isErrorAuditTrail:false,
        errorAuditTrail:null,
    },

    reducers: {
      resetAuditTrail: state => {
          state.auditTrailStatus = FetchDataStatus.INITIAL;
          state.auditTrailData = null;
          state.isErrorAuditTrail = false;
          state.errorAuditTrail = null;

      }
    }
      ,

    extraReducers(builder) {
        builder.addCase(generateAuditTrail.pending, (state) => {
            state.auditTrailStatus = FetchDataStatus.FETCHING;
          })
          .addCase(generateAuditTrail.fulfilled, (state, action) => {
            state.auditTrailStatus = FetchDataStatus.SUCCESS;
            state.auditTrailData = action.payload;
          })
          .addCase(generateAuditTrail.rejected, (state, action) => {
            state.auditTrailStatus = FetchDataStatus.FAILURE;
            state.isErrorAuditTrail = true;
            state.errorAuditTrail = action.error.message;
           
          })

        
    }
})

export const { resetAuditTrail } = auditSlice.actions;

export const selectAuditTrailRecord = state => state.auditTrail
export default auditSlice.reducer;