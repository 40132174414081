import React from "react";
import Utils from "../../library/Utils";
import './List.css';

function List(props) {
  const listContent = props.listContent.map((element, i) => {
    let header = <h2 className={props.headerClass}>{element.header}</h2>;

    if(!Utils.isFieldEmpty(element.color)) {
      return (
        <li className="list-legend-type" key={i}>
          <section className="list-legend-circle-with-header">
            <div style={{ background: element.color,padding:'10px' }} className='list-legend-circle'></div>
            <p className={props.detailClass}>{element.detail}</p>
          </section>
          <p>{element.header}</p>
        </li>
      )
    } else {
      return (
        <li className={props.listRowClass} key={i}>
          {!Utils.isFieldEmpty(element.iconSrc) && element.iconSrc}
          <div className="list-type-content">
            {element.link !== undefined
              ? <div className="list-detail-with-link-container" onClick={() => element.link()}>
                  {!Utils.isFieldEmpty(element.header) && header }
                  <div className="list-detail-link">
                    <p className={props.detailClass}>{element.detail}</p>
                    <span>🡲</span>
                  </div>
                </div>
              : <>
                  {!Utils.isFieldEmpty(element.header) && header}
                  <p className={props.detailClass}>{element.detail}</p>
                </>
            }
          </div>
          {element.sublist &&
            <div className="sublist-type-content">
            {!Utils.isFieldEmpty(element.sublist.header) && 
              <h2 className={props.headerClass}>{element.sublist.header}</h2>}
            {element.sublist.detail && <p style={{ color: element.sublist.textColor }}>{element.sublist.detail}</p>}
          </div>
          }
        </li>
      )
    }
  })

  return (
    <div className={props.listContainerClass}>
      {props.title && <h3 className="list-type-title">{props.title}</h3>}
      {props.detail && <h4 className={"list-type-detail " + props.detailSymbolClass}>
        {props.detail} <span>{props.detailSymbol}</span>
      </h4>}
      <ul className={props.listClass}>
        {listContent}
      </ul>
    </div>
  )
}

export default List;