import { createSlice } from "@reduxjs/toolkit";

import { updateReviewAsync } from "./thunks";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const updateReviewSlice = createSlice({
  name: "updateReview",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    }},

  extraReducers: (builder) => {
    builder
      .addCase(updateReviewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateReviewAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(updateReviewAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { reset:resetTransactionReview } = updateReviewSlice.actions;