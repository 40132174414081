import React, { useState } from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { selectLoanFileState,updateLoanApproval,resetLoanFileData} from '../../../redux/Slices/LoanFile';


function LoanApprovalModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectLoanFileState)
    const accessToken = props.accessToken
    const [approvalChoice,setApprovalChoice] = useState()
 


    let content = null;
    

    const approveLoan = (option)=>{
        if(option==='reject'){
            setApprovalChoice(false)
            const params = {
                data:{loanApplicationKey:props.loanApplicationKey,
                    approvalStatus: "DECLINED",},
                    accessToken
            } 

            dispatch(updateLoanApproval(params))
        }

        else{
            setApprovalChoice(true)
            const params = {
                data:{loanApplicationKey:props.loanApplicationKey,
                    approvalStatus: "APPROVED",},
                    accessToken
            }

            dispatch(updateLoanApproval(params))
        }
    }


    if(loanState.approvalDataStatus===PostDataStatus.INITIAL){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Proceed with Loan Verification</p>
            <div className='buttonHold'>
                  <section className='reject' onClick={()=>approveLoan('reject')}>DECLINE</section>
                  <section onClick={()=>approveLoan('approve')}>APPROVE</section>
            </div>
            
        </div>
    }

    if(loanState.approvalDataStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>{approvalChoice?'Approving...':'Rejecting...'}</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.approvalDataStatus===PostDataStatus.SUCCESS){
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>{approvalChoice?'Succesfully Approved':'Succesfully Declined'}</p>
            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();dispatch(resetLoanFileData())}}>Done!</section>
            </div> 
        </div>
    }

    if(loanState.approvalDataStatus===PostDataStatus.FAILURE){
        content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanState.errorApprovalData}</p>

            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();dispatch(resetLoanFileData())}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default LoanApprovalModal