import React,{useState,useMemo,useCallback, useEffect} from 'react'
import { useForm } from "react-hook-form";
import lodash from 'lodash'
import nairaIcon from '../../../../../assests/icons/nairaIcon.png'
import deleteIcon from '../../../../../assests/icons/DeleteItem.png'
import {useDropzone} from "react-dropzone";
import { v4 as uuidv4 } from 'uuid';
import SignatureUploadModal from '../../../../../components/Modals/LoanModals/SignatureUploadModal';
import Modal from "react-modal";
import { selectLoanState,resetSignature } from '../../../../../redux/Slices/Loan';
import { useSelector,useDispatch } from 'react-redux';
import { PostDataStatus } from '../../../../../library/Variables';
import { selectLoanFileState } from '../../../../../redux/Slices/LoanFile';
import { getLoanVerifiedData } from '../../../../../redux/Slices/LoanFile';
import Spinner from '../../../../../svg/Spinner/Spinner';
import AmountInput from '../../../../../components/loanComponents/AmountInput';
import { selectSignatureState } from '../../../../../redux/Slices/LoanSignatureUpload';
import AmountInDynamic from '../../../../../components/loanComponents/AmountInDynamic';
import PreviewDeleteModal from '../../../../../components/Modals/LoanModals/PreviewAndDeleteSig';
import cancelImg from '../../../../../assests/icons/cancelImage.png'
import SignatureVerificationModal from '../../../../../components/Modals/LoanModals/VerificationSignatureModal';
import './ownerAsset.css'


function OwnerAssetVerification(props) {

  const register = props.register
    const errors = props.errors
    const imgFile = props.imgFile
    const setImgFile = props.setImgFile
    const setValue = props.setValue
    const signatureState = useSelector(selectSignatureState)
    const dispatch = useDispatch()
    const clientSignaturePath = props.clientSignaturePath
    const setclientSignaturePath = props.setclientSignaturePath
    const loanDataState = useSelector(selectLoanState)
    const loanFileState = useSelector(selectLoanFileState)
    const ownerData = lodash.sortBy(props.info,'rank')
    const [fieldData,setFieldData] = useState(ownerData)
    const fixedClientFields = props.fixedClientFields
    const setFixedClientFields = props.setFixedClientFields
    const [imgUrl,setImgUrl] = useState(null)
    const [fileHold,setFilehold] = useState(null)
    const accessToken = props.accessToken
    const [NameOfItemFoundError,setNameOfItemFoundError] = useState(null)
    const [uploadError,setUploadError] = useState(null)
    const [CategoryError,setCategoryError] = useState(null)
    const [MarketValueError,setMarketValueError] = useState(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputValue,setInputValue] = useState('')
    const [exportValue,setExportValue] = useState()
    const [currentValue,setCurrentValue] = useState(null)
    const [showName,setShowName] = useState(false)
    const [selectedImgList,setSelectedImgList] = useState([])
    const [previewDeleteIsOpen, setPreviewDeleteIsOpen] = useState(false);
    const [hitDelete,setHitDelete] = useState()
    const setShowForm= props.setShowForm
    const [stateForPreview, setStateForPreview] = useState([])
    const [previewObj,setPreviewObj] = useState()

   
    const [filesSelected, setFilesSelected] = useState([]);
    const paramKey = props.paramKey
  const [filesRejected, setFilesRejected] = useState([]);
  let mainContent = <Spinner/>
    const categoryOptions = ['Vehicle','Furniture','Electronics']
  const optionChecker = (data)=>{
    if(data.friendlyName==='Number Of Dependents'){
      let numbersArray = [];
      for (let i = 1; i <= 20; i++) {
          numbersArray.push(i);
      }
      return numbersArray
    }
    else{
      return data.options
    }
}




const handleNameOfItemChange = (event,index) => {
  const value = event.target.value;
  const entireArray = [...fixedClientFields]
  let changedItem = fixedClientFields[index]
  changedItem.name = value
  entireArray.splice(index,1,changedItem)
  setFixedClientFields([...entireArray]);
  
};

const handleMarketValueChange = (event,index) => {

  let number = event.target.value
        
    if((number.length >currentValue?.length) || !currentValue){
      number = number.split("")
      if(isNaN(parseInt(number[number.length-1]))){
        if(number[0]===number[number.length-1]){
          event.target.value = ''
        }
        else{

          const formatter = new Intl.NumberFormat('en-US');
          const formattedNumber = formatter.format(exportValue);
          event.target.value = formattedNumber
        }
      
      }
      else{

        let mainValue = inputValue+number[number.length-1]
        setInputValue(mainValue)
        mainValue = parseInt(mainValue)
        setExportValue(mainValue.toString())
        const entireArray = [...fixedClientFields]
        let changedItem = fixedClientFields[index]
        changedItem.marketValue = mainValue
        entireArray.splice(index,1,changedItem)
        setFixedClientFields([...entireArray]);
        const formatter = new Intl.NumberFormat('en-US');
        
        const formattedNumber = formatter.format(mainValue);
        // Remove non-numeric characters
        setCurrentValue(formattedNumber)
        event.target.value = formattedNumber
        
      }
    }
    else{
      if(number===''){
        event.target.value = ''
        setCurrentValue(null)
        setInputValue('')
        setExportValue('')
        const entireArray = [...fixedClientFields]
        let changedItem = fixedClientFields[index]
        changedItem.marketValue = number
        entireArray.splice(index,1,changedItem)
        setFixedClientFields([...entireArray]);
      }
      else{

        let number = exportValue.toString()
        number = number.split("")
        number.splice(number.length-1,1)
        number = number.join('')
        number = parseInt(number)
        setExportValue(number.toString())
        const entireArray = [...fixedClientFields]
        let changedItem = fixedClientFields[index]
        changedItem.marketValue = number
        entireArray.splice(index,1,changedItem)
        setFixedClientFields([...entireArray]);
        setInputValue(number.toString())
        const formatter = new Intl.NumberFormat('en-US');

        const formattedNumber = formatter.format(number);
        setCurrentValue(formattedNumber)
        event.target.value = formattedNumber
    
      }
        }
        

  /**
   * const value = parseInt(event.target.value);
  const entireArray = [...fixedClientFields]
  let changedItem = fixedClientFields[index]
  changedItem.marketValue = value
  entireArray.splice(index,1,changedItem)
  setFixedClientFields([...entireArray]);
   */

  
};


const handleCategoryChange = (event,index) => {

  const value = event.target.value;
  const entireArray = [...fixedClientFields]
  let changedItem = fixedClientFields[index]
  changedItem.category = value
  entireArray.splice(index,1,changedItem)
  setFixedClientFields([...entireArray]);

};

const itemMainObj =  {labelName:'Name of Item Found',
triggerFuncName:handleNameOfItemChange,
valueName:fixedClientFields.NameOfItemFound,
typeName:'TEXT',
errorName:NameOfItemFoundError,
labelCat:'Category',
triggerFuncCat:handleCategoryChange,
valueCat:fixedClientFields.Category,
typeCat:'SELECT',
errorCat:CategoryError,
labelMarket:'Market Value',
triggerFuncMarket:handleMarketValueChange,
valueMarket:fixedClientFields.MarketValue,
typeMarket:'NUMBER',
errorMarket:MarketValueError}


const [itemArrayHold,setItemArrayHold] = useState([itemMainObj])

const increaseItemArray = ()=>{
    let prevValueObj = [...fixedClientFields]
    const newObj = {name:'',category:'',marketValue:''}
    setFixedClientFields([...prevValueObj,newObj])
    setItemArrayHold([...itemArrayHold,itemMainObj])
}

const deleteItem = (index)=>{
  let newArrayHold = [...itemArrayHold]
  let prevValueObj = [...fixedClientFields]
  prevValueObj.splice(index,1)
  newArrayHold.splice(index,1)
  setFixedClientFields([...prevValueObj])
  setItemArrayHold([...newArrayHold])
}


function openModal() {
  setIsOpen(true);
}

function afterOpenModal() { }

function closeModal() {
  setIsOpen(false);
  //dispatch(resetDownload());
}

function closePreviewDeleteModal() {
  setPreviewDeleteIsOpen(false)

  //dispatch(resetDownload());
}

function closePreviewDeleteModal() {
  setPreviewDeleteIsOpen(false)

  //dispatch(resetDownload());
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#F7F7F7"
};

const acceptStyle = {
  borderColor: "#F7F7F7"
};

const rejectStyle = {
  borderColor: "#F7F7F7"
};

const customStyles = {
  content: {
    transition: '0.125s ease-in-out',
    transform: 'scale(1)',
    height: '33%',
    width: '50%',
    top: '33%',
    left: '33%',
    padding: '0 0 0 0',
    overflowY: 'scroll'
  },
  overlay: {
    zIndex: 1000,
    position: 'absolute'
  }
};




const onDrop = useCallback(acceptedFiles => {

  if(acceptedFiles[0]){
    const {path, name,type} = acceptedFiles[0]

    const file = acceptedFiles[0]; 
    let filebody  = {
      id : uuidv4(),
      path : path,
      name : name
    };
    const reader = new FileReader();
    reader.onload = (e) => {
      const previewURL = e.target.result;
      setImgUrl(previewURL);
      setImgFile(file)
      setIsOpen(true)
    };
    reader.readAsDataURL(file);

    setFilehold(filebody)
  }
 
}, [fileHold])



const onDropRejected = useCallback(fileRejections => {
  setUploadError(fileRejections[0].errors)
  setIsOpen(true)
  setFilesRejected([...filesRejected, ...fileRejections]);
  setFilesSelected([]);
}, [filesRejected])
//uploadError is an array of objects containing code & message
const {
  getRootProps,
  getInputProps,
  open,
  isDragActive,
  isDragAccept,
  isDragReject
} = useDropzone({
  accept: { 
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'] 
        },
  maxSize: 10000000,
  filesizeBase: 1000,
  multiple: false,
  onDrop,
  onDropRejected
});

const style = useMemo(() => ({
  ...baseStyle,
  ...(isDragActive ? activeStyle : {}),
  ...(isDragAccept ? acceptStyle : {}),
  ...(isDragReject ? rejectStyle : {})
}), [
  isDragActive,
  isDragReject,
  isDragAccept
]);

useEffect(()=>{
    //dispatch(getLoanVerifiedData({accessToken,key:paramKey}))
},[])



useEffect(()=>{
  if(signatureState.verificationSignatureStatus===PostDataStatus.SUCCESS && signatureState.verificationSignature!==null){
    setclientSignaturePath(signatureState.verificationSignature.data)
  }
},[signatureState])


useEffect(()=>{
  if(!showName){
    setImgUrl(null);
    setImgFile(null)
    setFilehold(null)
  }
},[showName])



const preparePreviewObj = (index,state)=>{
  let prevObj = {
                path:stateForPreview[index], 
                name:selectedImgList[index].file.name,index,
                imgUrl:imgUrl[index]
              }
  if(state===true){
    setPreviewObj(prevObj)
    setHitDelete(true)
    setPreviewDeleteIsOpen(true)
  }
  else{
    setPreviewObj(prevObj)
    setHitDelete(false)
    setPreviewDeleteIsOpen(true)
  }
 
}


  return (
    <div style={{width:'100%',boxSizing:"border-box",boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderRadius: '10px',backgroundColor:"white",padding:'2em'}}>
              <p className='formTitle'>Home/Business Owner Asset Verification</p>
              <p style={{margin:'20px 0px',color:'rgba(54, 60, 79, 1)',fontSize:'16px',fontWeight:"500"}}>Declaration of Items Found</p>
              <div>


              {itemArrayHold.map(
                (item,index)=>
              
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",gap:'6px'}}>

                    <section className='customVerification-section'>
                       
                      <div className='sign-up-details-input-container'>
                        <label>{item.labelName} <span className="required">*</span></label>

                        <input className="input-pill-single" placeholder='input name of item' autocomplete="off" type='TEXT' 
                            value={fixedClientFields[index].name} onChange={(event)=>handleNameOfItemChange(event,index)} />
                        {item.errorName && <p style={{ color: "red"}}>{item.errorName}</p>}
                     </div>
  
                    <div className='sign-up-details-input-container'>
                      <label>{item.labelCat} <span className="required">*</span></label>

                      <div className='selectHolder'>
                        
                        <select  
                        value={fixedClientFields[index].category} onChange={(event)=>handleCategoryChange(event,index)}
                        >
                          <option value="">Select an option</option>
                          {categoryOptions.map((element, i) => {
                            return <option value={element} key={i}>{element}</option>
                          })}
                        </select>
                      </div>
                      {item.errorCat && <p style={{ color: "red"}}>{item.errorCat}</p>}
                    </div>
                      
                    <AmountInDynamic item={item} index={index} fixedClientFields={fixedClientFields} setFixedClientFields={setFixedClientFields}/>
                </section>
                <span onClick={()=>deleteItem(index)} style={{display:itemArrayHold.length===1?'none':'flex',alignItems:'center',cursor:'pointer',justifyContent:"center",height:"40px",width:'70px',marginTop:'20px'}}>
                    <img src={deleteIcon}/>
                </span>
              </div>)}
             
              <div onClick={()=>increaseItemArray()} style={{margin:'10px 0px',textAlign:'right',color:'rgba(64, 123, 255, 1)',fontSize:'16px',cursor:'pointer',fontWeight:"700"}}>Add more items</div>
              </div>



              <section className='sign-up-details-inputs' >
              {
                fieldData.map((data,id)=><div style={{width:"100%"}} id={id}>
                {data.displayType==='SELECT'?
                  <div className='sign-up-details-input-container'>
                  <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                  <div className='selectHolder'>
                      <select  {...register(`${data.name}`, { required: data.isRequired?true:false,  })} 
                    >
                        <option value="">Select an option</option>
                        {optionChecker(data).map((element, i) => {
                          return <option value={element} key={i}>{element}</option>
                        })}
                      </select>
                  </div>
                  <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                  </div>:

                  data.displayType==='TEXT'|| data.displayType==='NUMBER'?
                    <div className='sign-up-details-input-container'> 
                      <label>{data.friendlyName} <span className="required">{data.isRequired?'*':''}</span></label>
                      <input className="input-pill-single" placeholder={`input ${data.friendlyName}`} autocomplete="off" type={data.displayType}  {...register(`${data.name}`, 
                      { 
                        required: data.isRequired?true:false, 
                        
                      })} />
                      <span style={{ color: "red"}}>{errors?.[data.name]?.message}</span>
                    </div>
                        :
                  <AmountInput data={data} register={register} setValue={setValue} errors={errors}/>
                }
                
                </div>)
              }</section>
              
             
              <div {...getRootProps({style})} style={{borderRadius:'7px',cursor:'pointer',width:'100%',marginTop:'20px'}}>
                    <p style={{fontSize:'16px'}}>Agent Signature</p>
                    <section style={{height:'70px',fontSize:'16px',border:'1px dashed rgba(64, 123, 255, 1)',display:'flex',alignItems:'center',color:'rgba(240, 246, 255, 1)',justifyContent:'center',color:'rgba(64, 123, 255, 1)'}}>
                    {imgFile?imgFile.name:'Upload file here'}
                        <input {...getInputProps()} />
                    </section>
                </div>

              

                <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
                  contentLabel="Add Signature for Verification" >
                  <SignatureVerificationModal  setShowName={setShowName} setShowForm={setShowForm} imgUrl={imgUrl} imgFile={imgFile} 
                                         accessToken={accessToken} setUploadError={setUploadError} closeModal={closeModal} 
                                         uploadError={uploadError}/>
              </Modal> 


              <Modal isOpen={previewDeleteIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closePreviewDeleteModal} style={customStyles}
                  contentLabel="Signature View And Delete." >
                  <PreviewDeleteModal hitDelete={hitDelete} previewObj={previewObj} accessToken={accessToken} 
                            selectedImgList={selectedImgList} setSelectedImgList={setSelectedImgList}
                            closeModal={closePreviewDeleteModal}
                            />
            </Modal>
      </div>
  )
}

export default OwnerAssetVerification


