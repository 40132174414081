import React, {useState,useEffect} from 'react'
import logoHold from '../../../../assests/icons/kycLogo.png'
import NaiModal from './KycModal/NaiContent/NaiModal'
import AlienIdModal from './KycModal/NaiContent/AlienModal'
import { selectKycEntireDataState } from '../../../../redux/Slices/IdVerificationKenya'
import { useSelector,useDispatch } from 'react-redux'
import ExtractionReport from '../../../Statement/KYC/kycReporter/ExtractionReport'
import IdReportKenya from '../../IdReportKenya'
import Spinner from '../../../../svg/Spinner/Spinner'
import IdHistoryKenya from '../../IdHistoryKenya'
import KybExtractionReport from '../KybContent/KybDocumentation/KybReport'
import { getStandAloneDataHistoryKenya } from '../../../../redux/Slices/IdVerificationKenya'
import VehiclePlateModal from './KycModal/VehicleContent/VehiclePlateModal'
import { CountryCodes } from '../../../../library/CountryCodes'
import DrivingContentModal from './KycModal/DrivingContent/DrivingContentModal'
import KraPinModal from './KycModal/KraContent/KraPinModal'
import BankAccountModal from './KycModal/BankAccountContent/BankAccountModal'
import PhoneModal from './KycModal/PhoneNumberContent/PhoneNumberModal'
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables'
import './KycContent.css'
import Configuration from '../../../../redux/Slices/Configuration'



function KycContentSection({accessToken,configuration,keyFromRoute}) {
    const kycState = useSelector(selectKycEntireDataState)
    const dispatch = useDispatch()
    const [viewReport,setViewReport] = useState(false)
    const [reportData,setReportData] = useState(null)
  const [historyArray,setHistoryArray] =  useState([])
  const [verificationType,setVerificationType] = useState()

    const [kycToShow,setKycToShow] = useState(null)
    const cardHolder = [{id:0,title:'National ID',
                      info:'Requires the ID of the individual'},
                      {id:1,title:'Alien ID',
                      info:'Requires the ID of the individual'},
                      {id:2,title:'Vehicle Number Plate',
                      info:'Requires number plate of the vehicle'},
                      {id:3,title:'Driving License',
                      info:'Requires ID of the individual'},
                      {id:4,title:'KRA Pin',
                      info:'Requires pin of the individual'},
                    
                      {id:6,title:'Bank Account Verification',
                      info:'This check requires bank account of the individual'}]
                      const customStyles = {
                        content: {
                            transition: "0.125s ease-in-out",
                            transform: "scale(1)",
                            height: "33%",
                            width: "50%",
                            top: "33%",
                            left: "33%",
                            padding: "0 0 0 0",
                            overflowY: "scroll"
                        },
                        overlay: {
                            zIndex: 1000,
                            position: "absolute"
                        }
                        };
                  
                    const inputStyle = {
                      width: "100%",
                      marginTop: "10px",
                      border: 'none',
                      padding: '0px 24px 0px 24px',
                      boxSizing: "border-box",
                      backgroundColor: 'rgba(239, 244, 255, 1)',
                      outline: "none",
                      height: '40px'
                    };
                    
    const tableHeaders = ['Status','KYC Type','Created Date','Full Name','Tracking Number']

    useEffect(()=>{
        if(kycState.standAloneStatus===FetchDataStatus.SUCCESS
          ||kycState.standAloneStatus===FetchDataStatus.SUCCESS
          ||kycState.naiExtractionStatus===PostDataStatus.SUCCESS
          ||kycState.alienExtractionStatus===PostDataStatus.SUCCESS
          ||kycState.plateExtractionStatus===PostDataStatus.SUCCESS
          ||kycState.KraExtractionStatus===PostDataStatus.SUCCESS
          ||kycState.DriverExtractionStatus===PostDataStatus.SUCCESS
          ||kycState.PhoneDataStatus===PostDataStatus.SUCCESS
          ||kycState.BankAccountStatus===PostDataStatus.SUCCESS){
          setHistoryArray([...kycState.activeExtractions.kycDataSummaries])
        }
      },[kycState.extractionStatus,kycState.standAloneStatus,kycState.naiExtractionStatus,
         kycState.alienExtractionStatus,kycState.plateExtractionStatus,
         kycState.KraExtractionStatus, kycState.DriverExtractionStatus,kycState.PhoneDataStatus,kycState.BankAccountStatus
      ])
    
    
      useEffect(()=>{
       
          //dispatch(getStandAloneDataHistoryKenya({accessToken}))
          if(kycState.extractionStatus===FetchDataStatus.INITIAL){
            dispatch(getStandAloneDataHistoryKenya({accessToken,page:1,perPage:10}))
          }
        
          
      },[kycState.extractionStatus])
   
    return (
        <div>
            <section>
                <p style={{fontSize:'24px',color:"rgba(0, 0, 0, 1)",fontWeight:"700"}}>
                    KYC Verification
                </p>
                <p style={{fontSize:'16px',color:"rgba(106, 113, 125, 1)"}}>
                    Select a KYC verification method below
                </p>
            </section>
            {kycToShow===null?null
                :kycToShow===0?<NaiModal keyFromRoute={keyFromRoute} 
                                         accessToken={accessToken} 
                                         setViewReport={setViewReport} 
                                         customStyles={customStyles} 
                                         setKycToShow={setKycToShow} 
                                         inputStyle={inputStyle}
                                         setVerificationType={setVerificationType}
                                         />
                :

                kycToShow===1?<AlienIdModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}
                                            setVerificationType={setVerificationType}
                                            />
                :

                kycToShow===2?<VehiclePlateModal keyFromRoute={keyFromRoute} 
                                                accessToken={accessToken} 
                                                setViewReport={setViewReport} 
                                                customStyles={customStyles} 
                                                setKycToShow={setKycToShow} 
                                                inputStyle={inputStyle}
                                                setVerificationType={setVerificationType}
                                                />
                :

                kycToShow===3?<DrivingContentModal keyFromRoute={keyFromRoute} 
                                                    accessToken={accessToken} 
                                                    setViewReport={setViewReport} 
                                                    customStyles={customStyles} 
                                                    setKycToShow={setKycToShow} 
                                                    inputStyle={inputStyle}
                                                    setVerificationType={setVerificationType}
                                                    />
                :

                kycToShow===4?<KraPinModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}
                                            setVerificationType={setVerificationType}
                                            />
                :

                kycToShow===5?<PhoneModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}
                                            setVerificationType={setVerificationType}
                                            />
                :

                kycToShow===6? <BankAccountModal keyFromRoute={keyFromRoute} 
                                            accessToken={accessToken} 
                                            setViewReport={setViewReport} 
                                            customStyles={customStyles} 
                                            setKycToShow={setKycToShow} 
                                            inputStyle={inputStyle}
                                            setVerificationType={setVerificationType}
                                            />
                :

                ''
            }
            <div style={{display:"flex",flexWrap:'wrap',justifyContent:'start',columnGap:'10px',rowGap:"10px",alignItems:'center',marginTop:"30px"}}>
                {configuration.countryCode === CountryCodes.KENYA? cardHolder.map(card=>
                    <div onClick={()=>{setKycToShow(card.id)}} style={{display:"flex",cursor:"pointer",width:"31%",borderRadius:'4px',height:'150px',flexDirection:'column',justifyContent:"space-around",padding:"10px",boxSizing:'border-box',backgroundColor:"rgba(255, 255, 255, 1)"}} key={card.id}>
                        <p><img src={logoHold}/></p>
                        <p style={{fontWeight:'500',fontSize:"20PX",color:'rgba(54, 60, 79, 1)'}}>{card.title}</p>
                        <p style={{fontSize:'12px',fontWeight:'400',color:'rgba(31, 34, 39, 1)'}}>{card.info}</p>
                    </div>):
                    <div style={{minHeight:"150px",
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:"space-around",
                                backgroundColor:'white',
                                width:'100%',
                                borderRadius:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                margin:'30px auto'}}>
                        <p style={{fontWeight:'700',
                                   fontStyle:'italic',
                                   fontSize:'22px'}}>
                                Anticipate KYC!
                        </p>

                        <p style={{textAlign:'center',
                                    fontSize:'15px',
                                    marginTop:'10px'}}>
                                    Coming Soon
                        </p>
                    </div>}
            </div>

            {viewReport && (
                ['CAC','TIN'].includes(verificationType)?
                <KybExtractionReport verificationType={verificationType} viewReport={viewReport} setViewReport={setViewReport}/>:

                <IdReportKenya verificationType={verificationType} viewReport={viewReport} setViewReport={setViewReport}/>)}


            {(kycState.standAloneStatus===FetchDataStatus.FETCHING && !viewReport)?
                <div className="spinner-container">
                    <Spinner></Spinner>
                </div>
            :
            (kycState.standAloneStatus===FetchDataStatus.SUCCESS && historyArray.length===0 && !viewReport)?
            <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                You currently have no historically extracted KYC document.
            </div>:
            (kycState.standAloneStatus==='SUCCESS' && historyArray.length>0 && !viewReport)?
            <IdHistoryKenya 
            setVerificationType={setVerificationType}
            accessToken={accessToken}
            setViewReport={setViewReport} viewReport={viewReport} 
            historyArray={historyArray} tableHeaders={tableHeaders}
            kycState={kycState} setHistoryArray={setHistoryArray}
            />:
            (kycState.standAloneStatus==='FAILURE' && !viewReport)?
            <div style={{width:'100%',color:'red',textAlign:'center',marginTop:'30px'}}>
                Something went wrong!.
            </div>:''}
        </div>
    )
    }

    export default KycContentSection