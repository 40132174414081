import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { PostDataStatus } from '../../library/Variables';
import Utils from "../../library/Utils";

export const uploadPdfAsync = createAsyncThunk('upload', async (params) => {
  let data = new FormData();
  data.append('file', params.pdf);
  data.append('statementType', params.statementType);
  data.append('documentType', params.documentType);
  data.append('password', params.filePassword);
  
  const statement = await client.put('/statements', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  });
  return statement.data;
});

export const uploadLinkedPdfAsync = createAsyncThunk('upload', async (params) => {
  const data = new FormData();
  // data.append('files', params.files);
  if(!Utils.isFieldEmpty(params.bvn)){

    data.append('baseStatementKey', params.baseStatementKey);
    data.append('uniqueIdentifier', params.uniqueIdentifier);
    data.append('passwords', params.passwords);
    data.append('bvn', params.bvn);
    data.append('statementTypes', params.statementTypes);
    params.files.forEach((file) => {
      data.append('files', file);
    });
  }

  else{
    data.append('baseStatementKey', params.baseStatementKey);
    data.append('uniqueIdentifier', params.uniqueIdentifier);
    data.append('passwords', params.passwords);
    data.append('bvn', '');
    data.append('statementTypes', params.statementTypes);
    params.files.forEach((file) => {
      data.append('files', file);
    });
  }


  const statement = await client.put('/statements/link-multiple-pdfs', data, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  });
  return statement.data;
});

export const addPdfSlice = createSlice({
  name: 'addpdf',
  initialState: {
    status: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null
  },
  reducers: {
    reset: state => {
      state.status = PostDataStatus.INITIAL;
      state.errorMessage = null;
      state.isError = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(uploadPdfAsync.pending, (state) => {
      state.status = PostDataStatus.FETCHING;
    })
    .addCase(uploadPdfAsync.fulfilled, (state) => {      
      state.status = PostDataStatus.SUCCESS;
    })
    .addCase(uploadPdfAsync.rejected, (state, action) => {
      state.status = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  },
  extraReducers(builder) {
    builder.addCase(uploadLinkedPdfAsync.pending, (state) => {
      state.status = PostDataStatus.FETCHING;
    })
    .addCase(uploadLinkedPdfAsync.fulfilled, (state) => {      
      state.status = PostDataStatus.SUCCESS;

    })
    .addCase(uploadLinkedPdfAsync.rejected, (state, action) => {
      state.status = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { reset } = addPdfSlice.actions;

export const selectAddPdfStatus = state => state.addpdf;

export default addPdfSlice.reducer;