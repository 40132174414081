import Utils from "../../../../library/Utils";
import { CamelDataSerializer } from "./CrbCamelCaseSerializer";




export const CreditAccountObjSerializer = (obj)=>{
    let AccountSummaryReshape = []
    let mainObj = {...obj}
    delete mainObj.rating
    let reshapedData = CamelDataSerializer(mainObj)
    const basicDataKeys = Object.keys(mainObj);
    const basicDataValues = Object.values(mainObj)

    for(let i=0;i<basicDataKeys.length;i+=4){
        const newItem = {
            attribute:reshapedData[i].itemKey.slice(0, -6),
            eur:reshapedData[i].itemValue,
            gbp:reshapedData[i+1].itemValue,
            ghs:reshapedData[i+2].itemValue,
            usd:reshapedData[i+3].itemValue,
        }

        AccountSummaryReshape.push(newItem)

    }


    return AccountSummaryReshape
}