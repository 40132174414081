import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Utils from '../../../library/Utils';
import { PostDataStatus } from '../../../library/Variables';
import { resetParameterByKey, resetUploadParameter, resetUploadParameterOptions, selectSelfServiceState, uploadParameter, uploadParameterOptions } from '../../../redux/Slices/SelfServiceDecision';
import { selectSupportDocs } from '../../../redux/Slices/SupportDocs';
import Spinner from '../../../svg/Spinner/Spinner';
import './UploadParam.css';
import UploadParamOptionsSection from './UploadParamOptionsSection';
import UploadParamSection from './UploadParamSection';



function UploadParameterModal({accessToken,close,setUploadParameterModalIsOpen}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {key} = useParams()
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);

  const [OptionFilesRejected,setOptionFilesRejected] = useState([])
  const [OptionFileSelected,setOptionFilesSelected] = useState([])

  const selfState = useSelector(selectSelfServiceState)

  const supportDocState = useSelector(selectSupportDocs)


  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  
  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };


  const removeFile = (file) => {
    let newFilesArray = [...filesSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setFilesSelected(newFilesArray);
  }


  const removeOptions = (file) => {
    let newFilesArray = [...OptionFileSelected];
    newFilesArray.splice(newFilesArray.indexOf(file), 1);
    setOptionFilesSelected(newFilesArray);
  }

  const acceptedFileItems = filesSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeFile(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));


  const acceptedOptionFileItems = OptionFileSelected.map(file => (
    <li key={file.path}>
      <div className="ap-accepted-file-info">
        <img src="/assets/icons/statement-color.svg" alt="" />
        <div>
          <h4 className="text-dark">{file.path}</h4>
          <span className="text-medium">{file.size / 1000000} MB</span>
        </div> 
      </div>
      <button onClick={() => removeOptions(file)} className="delete-pdf-button">
        <img src="/assets/icons/delete-color.svg" alt="Delete" />
      </button>
    </li>
  ));






  function closepop() {
    dispatch(resetUploadParameter());
    dispatch(resetUploadParameterOptions());
    dispatch(resetParameterByKey())
    //setRefreshStatement(true)
    close();
  }





  // if(filesSelected.length > 1) {
  //   removeFile(filesSelected[0]);
  // }

  const submitFileForProcessing = () => {
    let filesArray = []
    let statementTypeArray = []
    let categoryIds = []

    filesSelected.map(({pdf, categoryId, name}) => {
      filesArray.push(pdf[0])
      categoryIds.push(categoryId)
      statementTypeArray.push(name)
    })

    if(Utils.isFieldEmpty(filesArray) || Utils.isFieldEmpty(statementTypeArray) || Utils.isFieldEmpty(categoryIds
      || statementTypeArray.includes(' ') || categoryIds.includes(' '))){
      toast.warning("Input all Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    else{
      let params = {
        accessToken: accessToken,
        file: filesArray,
      
      }
      dispatch(uploadParameter(params));
    }
    
    

  }



  const submitOption = () => {
    let filesArray = []
    let statementTypeArray = []
    let categoryIds = []

    OptionFileSelected.map(({pdf, categoryId, name}) => {
      filesArray.push(pdf[0])
      categoryIds.push(categoryId)
      statementTypeArray.push(name)
    })

    if(Utils.isFieldEmpty(filesArray) || Utils.isFieldEmpty(statementTypeArray) || Utils.isFieldEmpty(categoryIds
      || statementTypeArray.includes(' ') || categoryIds.includes(' '))){
      toast.warning("Input all Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    else{
      let params = {
        accessToken: accessToken,
        file: filesArray,
      
      }
      dispatch(uploadParameterOptions(params));
    }
    
    

  }


  let component = null;
  let componentOptions = null;
  if(selfState.uploadParameterStatus === PostDataStatus.INITIAL){

   component = <UploadParamSection closepop={closepop} 
                                   setFilesRejected={setFilesRejected}
                                   setFilesSelected={setFilesSelected}
                                   activeStyle={activeStyle}
                                   baseStyle={baseStyle}
                                   acceptStyle={acceptStyle}
                                   rejectStyle={rejectStyle}
                                   filesRejected={filesRejected}
                                   acceptedFileItems={acceptedFileItems}
                                   filesSelected={filesSelected}
                                   removeFile={removeFile}
                                   submitFileForProcessing={submitFileForProcessing}
                                   accessToken={accessToken}
                                   /> 

  }

  else if(selfState.uploadParameterStatus === PostDataStatus.FETCHING){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Please wait...</h2>
        <div className="close" onClick={()=>closepop()}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <Spinner />
    </div>
  } else if(selfState.uploadParameterStatus === PostDataStatus.SUCCESS){
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">CSV Parameter File Uploaded</h2>
        <div className="close" onClick={() => closepop()}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <p>Upload complete. Click done to see.</p>
        <div className="submit-container">
          <button className="btn-submit button-solid" onClick={() => {closepop()}}>Done</button>
        </div>
      </div>
    </div>
  } else if(selfState.uploadParameterStatus === PostDataStatus.FAILURE) {
    component = <div className="modal-dialog">
      <div className="modal-header">
        <h2 className="detail-header">Upload Failed</h2>
        <div className="close" onClick={closepop}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <div className="modal-body-container">
        <div className="modal-body-message">
          <p>{!Utils.isFieldEmpty(selfState.uploadParameterErrorMessage) && (selfState.uploadParameterErrorMessage).replace(/[""]/g, "")}</p>
          <p>If the problem persists, please contact support for further inquiries.</p>
        </div>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => dispatch(resetUploadParameter())}>Try Again</button>
      </div>
    </div>
  } 



  if(selfState.uploadParameterOptionStatus === PostDataStatus.INITIAL){
    componentOptions = <UploadParamOptionsSection closepop={closepop} 
                                                  OptionFilesRejected={OptionFilesRejected}
                                                  acceptedOptionFileItems={acceptedOptionFileItems}
                                                  setOptionFilesRejected={setOptionFilesRejected}
                                                  setOptionFilesSelected={setOptionFilesSelected}
                                                  removeOptions={removeOptions}
                                                  submitOption={submitOption}
                                                  acceptStyle={acceptStyle}
                                                  rejectStyle={rejectStyle}
                                                  baseStyle={baseStyle}
                                                  activeStyle={activeStyle}
                                                  OptionFileSelected={OptionFileSelected}
                                                  accessToken={accessToken}

    />
    }

    else if(selfState.uploadParameterOptionStatus === PostDataStatus.FETCHING){
        component = <div className="modal-dialog">
          <div className="modal-header">
            <h2 className="detail-header">Please wait...</h2>
            <div className="close" onClick={()=>closepop()}>
              <img src="/assets/icons/close-color.svg" alt="Close" />
            </div>        
          </div>
          <Spinner />
        </div>
      } else if(selfState.uploadParameterOptionStatus === PostDataStatus.SUCCESS){
        component = <div className="modal-dialog">
          <div className="modal-header">
            <h2 className="detail-header">CSV Option File Uploaded</h2>
            <div className="close" onClick={() => closepop()}>
              <img src="/assets/icons/close-color.svg" alt="Close" />
            </div>        
          </div>
          <div className="modal-body-container">
            <p>Upload complete. Click done to see.</p>
            <div className="submit-container">
              <button className="btn-submit button-solid" onClick={() => {closepop()}}>Done</button>
            </div>
          </div>
        </div>
      } else if(selfState.uploadParameterOptionStatus === PostDataStatus.FAILURE) {
        component = <div className="modal-dialog">
          <div className="modal-header">
            <h2 className="detail-header">Upload Failed</h2>
            <div className="close" onClick={()=>closepop()}>
              <img src="/assets/icons/close-color.svg" alt="Close" />
            </div>        
          </div>
          <div className="modal-body-container">
            <div className="modal-body-message">
              <p>{!Utils.isFieldEmpty(selfState.uploadParameterOptionErrorMessage) && (selfState.uploadParameterOptionErrorMessage).replace(/[""]/g, "")}</p>
              <p>If the problem persists, please contact support for further inquiries.</p>
            </div>
          </div>
          <div className="submit-container">
            <button className="button-square button-solid" onClick={() => dispatch(resetUploadParameterOptions())}>Try Again</button>
          </div>
        </div>
      } 

  return (
    <section style={{height:'600px',overflow:'auto'}}>
        <div>{component}</div>
        <div>{componentOptions}</div>
    </section>
  )
}

export default UploadParameterModal