


import React from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { selectSignatureState,resetVerificationSig,uploadVerificationSignature} from '../../../redux/Slices/LoanSignatureUpload';


function SignatureVerificationModal(props) {
    const dispatch = useDispatch()
    const loanState = useSelector(selectSignatureState)
    const accessToken = props.accessToken
    const uploadError = props.uploadError
    const imgFile = props.imgFile
    const setUploadError = props.setUploadError
    const setShowName = props.setShowName
    const setShowForm = props.setShowForm


    let content = null;
    const params = {
        files:props.imgFile,
        accessToken,
    }

    if(uploadError){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{color:"red",fontSize:'24px',fontWeight:"500",textAlign:"center"}}>Error!</p>
            <div style={{display:"flex",flexDirection:"column",gap:'15px',justifyContent:"space-between"}}>
            {uploadError.map((error,index)=>
            <p key={index} style={{textAlign:"center",fontSize:'14px'}}>{error.message}</p>)}

            </div>
            <div className='buttonHold'>
                  <section onClick={()=>{props.closeModal();setUploadError(null)}}>Cancel</section>
                </div>
        </div>
    }

    if(!uploadError && loanState.verificationSignatureStatus===PostDataStatus.INITIAL){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Proceed with Upload?</p>
            <p style={{color:"rgba(64, 123, 255, 1)",fontSize:'24px',fontWeight:"500",textAlign:"center"}}>{` "${imgFile.name}"`}</p>
            <span>
                {imgFile.name.slice(imgFile.name.length-3,imgFile.name.length)==='pdf'?
                    <iframe src={props.imgUrl} style={{width:'auto',maxWidth:'40vh',height:"auto"}}></iframe>: 
                    <img style={{width:"auto",maxWidth:'40vh'}} src={props.imgUrl}/>}
            </span>
            <div className='buttonHold'>
                  <section className='back' onClick={()=>{props.closeModal();setShowName(false);setUploadError(null);dispatch(resetVerificationSig())}}>Cancel</section>
                  <section onClick={()=>{setShowName(true);dispatch(uploadVerificationSignature(params))}}>Proceed</section>
            </div>
            
        </div>
    }

    if(loanState.verificationSignatureStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Uploading...</p>
                    <Spinner/>
            </div>
            
    }

    if(loanState.verificationSignatureStatus===PostDataStatus.SUCCESS){
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Uploaded</p>
            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();dispatch(resetVerificationSig())}}>Done!</section>
            </div> 
        </div>
    }

    if(loanState.verificationSignatureStatus===PostDataStatus.FAILURE){
        content = 
      <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanState.errorMessageVerificationSignature}</p>

          <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();dispatch(resetVerificationSig())}}>Close</section>
            </div>
      </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default SignatureVerificationModal

