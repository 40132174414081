import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
let download = require('downloadjs');


export const getSelfServiceScoreCardDefinitions = createAsyncThunk('selfService/scorecard-definitions', async (params) => {
    
  const customDecisionCreditScore = await client.get(`/creditscore/all-scorecard-definitions`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customDecisionCreditScore.data;
})

export const defineScoreCardName = createAsyncThunk('selfService/scoreName', async(params)=>{
    const data = JSON.stringify(params.data)
    const selfCardName = await client.post('/creditscore/scorecard-definitions',data,{
        "headers":{
            "Authorization": "Bearer " + params.accessToken,
            'Content-Type': 'application/json'
        }
    })

    return selfCardName;
})


//edit-score-card endpoints
export const editScoreCardDetails = createAsyncThunk('selfService/editScoreCard', async(params)=>{
  const data = params.data
  const editedCard = await client.patch('/creditscore/scorecard-definitions',data,{
      "headers":{
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
      }
  })

  return editedCard;
})



export const getSelfServiceCustomParams = createAsyncThunk('selfService/parameters', async (params) => {
    
  const customParameters = await client.get(`/creditscore/custom-parameters`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customParameters.data;
})


export const getScoreCardRulesByKey = createAsyncThunk('selfService/getRulesByKey', async (params) => {
    
  const customParameters = await client.get(`/creditscore/scorecard-rules/${params.cardKey}/${params.parameterKey}`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customParameters.data;
})



export const getSelfServiceCustomParamsByKey = createAsyncThunk('selfService/cardKey/parameters', async (params) => {
    
  const customParameters = await client.get(`/creditscore/custom-parameters/${params.key}`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customParameters.data;
})


export const defineCustomParameters = createAsyncThunk('selfService/postParameters', async(params)=>{
  const data = JSON.stringify(params.data)
  const postCustomParameters = await client.post('/creditscore/custom-parameter',data,{
      "headers":{
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
      }
  })

  return postCustomParameters;
})



export const deleteCustomParameters = createAsyncThunk('selfService/deleteParameters', async(params)=>{

  const deleteCustomParametersReport = await client.get(`/creditscore/delete-custom-parameter/${params.key}`,{
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return deleteCustomParametersReport.data;
})


export const deleteScoreCardRules = createAsyncThunk('selfService/deleteRules', async(params)=>{

  const deleteCustomParametersReport = await client.get(`/creditscore/delete-rule/${params.ruleKey}`,{
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return deleteCustomParametersReport.data;
})



export const defineCustomParametersOptions = createAsyncThunk('selfService/postParameterOptions', async(params)=>{
  const data = JSON.stringify(params.data)
  const postParameterOptions = await client.post('/creditscore/custom-parameter-options',data,{
      "headers":{
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
      }
  })

  return postParameterOptions;
})

export const createRules = createAsyncThunk('selfService/createRules',async(params)=>{
  const data = JSON.stringify(params.data)
  const rulesResponse = await client.post('/creditscore/scorecard-rules',data,{
    "headers":{
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  })

  return rulesResponse;
})

export const getScoreCardRules = createAsyncThunk('selfService/getRules', async(params)=>{
       const scoreRules = await client.get(`/creditscore/scorecard-rules-options/${params.key}`, {
        "headers": {
          "Authorization": "Bearer " + params.accessToken
        }
      })
    
      return scoreRules.data;
})


export const uploadParameter = createAsyncThunk('selfService/uploadParameter', async (params) => {
  let formData = new FormData()

  formData.append(`file`,params.file[0])
  const uploadParamResponse = await client.post('/creditscore/upload-parameters', formData, 
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  });
  return uploadParamResponse;
});


export const uploadParameterOptions = createAsyncThunk('selfService/uploadParameter/options', async (params) => {
  let formData = new FormData()

  formData.append(`file`,params.file[0])


        const uploadParamOptionsResponse = await client.post('/creditscore/upload-parameter-options', formData, 
      {
        "headers":
          {
            "Authorization": "Bearer " + params.accessToken
          }
      });
      return uploadParamOptionsResponse;
});



export const downloadParamTemplate = createAsyncThunk('selfService/downloadParamTemplate', async (params) => {
  setDownloadingTrue();
  const loanReport = await client.getBlob('/creditscore/parameters-template',
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, params.loanName+params.key, "application/pdf");
    });
  });

  return loanReport;
});




export const downloadOptionsTemplate = createAsyncThunk('selfService/downloadOptionTemplate', async (params) => {
  setDownloadingTrue();
  const loanReport = await client.getBlob('/creditscore/parameter-options-template',
  {
    "headers":
      {
        "Authorization": "Bearer " + params.accessToken
      }
  })
  .then(res => {
    res.data.then(blob => {
      setDownloadingFalse();
      download(blob, params.loanName+params.key, "application/pdf");
    });
  });

  return loanReport;
});



export const SelfServiceCreditScoreSlice = createSlice({
  name: 'selfServiceCreditScore',
  initialState: {
    customDecisionCreditScores: { },
    customDecisionCreditScoresStatus: FetchDataStatus.INITIAL,
    selfServiceDefinitionList: [],
    customDecisionStatements: [],
    particularDecisionCreditScore : { },


    selfServiceDefinitionListStatus: FetchDataStatus.INITIAL,
    selfServiceCardNameStatus: PostDataStatus.INITIAL,
    selfServiceCardRepsonse:{},
    selfServiceCardNameError:false,
    selfServiceCardNameErrorMessage:null,

    selfServiceParametersStatus: FetchDataStatus.INITIAL,
    selfServiceParameterData: {},
    selfServiceParameterError:false,
    selfServiceParameterErrorMessage:null,

    customParametersCreationStatus:PostDataStatus.INITIAL,
    customParametersState:{},
    customParametersStateError:false,
    customParametersErrorMessage:null,
    isError: false,
    errorMessage: null,

    parameterOptionsStatus: PostDataStatus.INITIAL,
    parameterOptionsStatusData: {},
    parameterOptionsStatusError:false,
    parameterOptionsStatusErrorMessage:null,

    parameterRulesStatus: PostDataStatus.INITIAL,
    parameterRulesStatusData: {},
    parameterRulesStatusError:false,
    parameterRulesStatusErrorMessage:null,

    selfServiceParametersByKeyStatus : FetchDataStatus.INITIAL,
    selfServiceParameterByKeyData : {},
    selfServiceParameterByKeyIsError : false,
    selfServiceParameterByKeyErrorMessage : null,

    deleteCustomParameterStatus : FetchDataStatus.INITIAL,
    deleteCustomParameterData : {},
    deleteCustomParameterIsError : false,
    deleteCustomParameterErrorMessage : null,


    selfServiceEditScoreCardStatus : PostDataStatus.INITIAL,
    selfServiceEditScoreCardData : {},
    selfServiceEditScoreCardIsError : false,
    selfServiceEditScoreCardErrorMessage : null,


    selfServiceGetRulesByKeyStatus : FetchDataStatus.INITIAL,
    selfServiceGetRulesByKeyData : {},
    selfServiceGetRulesByKeyIsError : false,
    selfServiceGetRulesByKeyErrorMessage : null,


    selfServiceDeleteRulesByKeyStatus : FetchDataStatus.INITIAL,
    selfServiceDeleteRulesByKeyData : {},
    selfServiceDeleteRulesByKeyIsError : false,
    selfServiceDeleteRulesByKeyErrorMessage : null,




    scoreCardRulesStatus : FetchDataStatus.INITIAL,
    scoreCardRuleData : [],
    scoreCardStatusError: false,
    scoreCardStatusErrorMessage:null,

    uploadParameterStatus : PostDataStatus.INITIAL,
    uploadParameterData : {},
    uploadParameterIsError : false,
    uploadParameterErrorMessage : null,


    uploadParameterOptionStatus : FetchDataStatus.INITIAL,
    uploadParameterOptionData : {},
    uploadParameterOptionIsError : false,
    uploadParameterOptionErrorMessage : null,
  },
  reducers: {
    resetAllCustomDecisionCreditScore: state => {
      state.customDecisionCreditScores = { };
      state.particularDecisionCreditScore = { };
      //state.particularDecisionCreditScoresStatus = FetchDataStatus.INITIAL
      state.customDecisionCreditScoresStatus = FetchDataStatus.INITIAL;
      state.selfServiceDefinitionList = [];
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      //state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomDecisionCreditScore: state => {
      state.selfServiceDefinitionList = [];
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      //state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomUpdateCreditScoreStatus: state => {
      //state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },

    resetRules: state =>{
      state.parameterRulesStatus =  PostDataStatus.INITIAL;
      state.parameterRulesStatusData =  {};
      state.parameterRulesStatusError = false;
      state.parameterRulesStatusErrorMessage = null;
    }

    ,
    resetParameter: state =>{
      state.customParametersCreationStatus = PostDataStatus.INITIAL;
      state.customParametersState = {};
      state.customParametersStateError = false;
      state.customParametersErrorMessage = null;
    },

    resetParameterByKey: state =>{
      state.selfServiceParametersByKeyStatus = FetchDataStatus.INITIAL;
      state.selfServiceParameterByKeyData = {};
      state.selfServiceParameterByKeyIsError = false;
      state.selfServiceParameterByKeyErrorMessage = null;

    },

    resetDeleteCustomParam : state =>{
      state.deleteCustomParameterStatus = FetchDataStatus.INITIAL;
      state.deleteCustomParameterData = {};
      state.deleteCustomParameterIsError = false;
      state.deleteCustomParameterErrorMessage = null;
    },


    resetEditScoreCard : state =>{
      state.selfServiceEditScoreCardStatus = PostDataStatus.INITIAL;
      state.selfServiceEditScoreCardData = {};
      state.selfServiceEditScoreCardIsError = false;
      state.selfServiceEditScoreCardIsError = null;
    },

    resetGetRulesByKey : state =>{
      state.selfServiceGetRulesByKeyStatus = FetchDataStatus.INITIAL;
      state.selfServiceGetRulesByKeyData = {};
      state.selfServiceGetRulesByKeyIsError = false;
      state.selfServiceGetRulesByKeyErrorMessage = null;
    
    },

    resetDeleteRulesByKey : state =>{
      state.selfServiceDeleteRulesByKeyStatus = FetchDataStatus.INITIAL;
      state.selfServiceDeleteRulesByKeyData = {};
      state.selfServiceDeleteRulesByKeyIsError = false;
      state.selfServiceDeleteRulesByKeyErrorMessage = null;

    
    },

    resetUploadParameter : state=>{
      state.uploadParameterStatus = PostDataStatus.INITIAL;
      state.uploadParameterData = {};
      state.uploadParameterIsError = false;
      state.uploadParameterErrorMessage = null;
    },

    resetUploadParameterOptions : state=>{
      state.uploadParameterOptionStatus = PostDataStatus.INITIAL;
      state.uploadParameterOptionData = {};
      state.uploadParameterOptionIsError = false;
      state.uploadParameterOptionErrorMessage = null;
    },

    resetSelfService: state =>{
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      state.selfServiceDefinitionList = [];
      state.selfServiceCardNameStatus = PostDataStatus.INITIAL;
      state.selfServiceCardRepsonse = {};


      state.selfServiceParametersStatus = FetchDataStatus.INITIAL;
      state.selfServiceParameterData = {};
      state.selfServiceParameterError = false;


      state.selfServiceParametersByKeyStatus = FetchDataStatus.INITIAL;
      state.selfServiceParameterByKeyData = {};
      state.selfServiceParameterByKeyIsError = false;
      state.selfServiceParameterByKeyErrorMessage = null;

      state.selfServiceCardNameErrorMessage = null;
      state.selfServiceParameterError = null;
      state.customParametersCreationStatus = PostDataStatus.INITIAL;
      state.customParametersState = {};
      state.customParametersStateError = false;
      state.customParametersErrorMessage = null;
    

      state.parameterOptionsStatus = PostDataStatus.INITIAL;
      state.parameterOptionsStatusError = false;
      state.parameterOptionsStatusErrorMessage = null;
      state.parameterOptionsStatusData = {}

      state.parameterRulesStatus =  PostDataStatus.INITIAL;
      state.parameterRulesStatusData =  {};
      state.parameterRulesStatusError = false;
      state.parameterRulesStatusErrorMessage = null;
    },

    setDownloadingTrue: state => {
      state.isDownloading = true;
    },

    setDownloadingFalse: state => {
      state.isDownloading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelfServiceScoreCardDefinitions.pending, (state) => {
      state.selfServiceDefinitionListStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getSelfServiceScoreCardDefinitions.fulfilled, (state, action) => {      
      state.selfServiceDefinitionListStatus = FetchDataStatus.SUCCESS;
      state.selfServiceDefinitionList = action.payload;
    })
    .addCase(getSelfServiceScoreCardDefinitions.rejected, (state, action) => {
      state.selfServiceDefinitionListStatus = FetchDataStatus.FAILURE;
      state.selfServiceCardNameError = true;
      state.selfServiceCardNameErrorMessage = action.error.message;
    })



    .addCase(defineScoreCardName.pending, (state) => {
        state.selfServiceCardNameStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineScoreCardName.fulfilled, (state, action) => {      
        state.selfServiceCardNameStatus = PostDataStatus.SUCCESS;
        state.selfServiceCardRepsonse = action.payload;
      })
      .addCase(defineScoreCardName.rejected, (state, action) => {
        state.selfServiceCardNameStatus = PostDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(getSelfServiceCustomParams.pending, (state) => {
        state.selfServiceParametersStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getSelfServiceCustomParams.fulfilled, (state, action) => {      
        state.selfServiceParametersStatus = FetchDataStatus.SUCCESS;
        state.selfServiceParameterData = action.payload;
      })
      .addCase(getSelfServiceCustomParams.rejected, (state, action) => {
        state.selfServiceParametersStatus = FetchDataStatus.FAILURE;
        state.selfServiceParameterError = true;
        state.selfServiceParameterErrorMessage = action.error.message;
      })

      .addCase(defineCustomParameters.pending, (state) => {
        state.customParametersCreationStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineCustomParameters.fulfilled, (state, action) => {      
        state.customParametersCreationStatus = PostDataStatus.SUCCESS;
        state.customParametersState = action.payload;
      })
      .addCase(defineCustomParameters.rejected, (state, action) => {
        state.customParametersCreationStatus = PostDataStatus.FAILURE;
        state.customParametersStateError = true;
        state.customParametersErrorMessage = action.error.message;
      })

      .addCase(defineCustomParametersOptions.pending, (state) => {
        state.parameterOptionsStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineCustomParametersOptions.fulfilled, (state, action) => {      
        state.parameterOptionsStatus = PostDataStatus.SUCCESS;
        state.parameterOptionsStatusData = action.payload;
      })
      .addCase(defineCustomParametersOptions.rejected, (state, action) => {
        state.parameterOptionsStatus = PostDataStatus.FAILURE;
        state.parameterOptionsStatusError = true;
        state.parameterOptionsStatusErrorMessage = action.error.message;
      })

      .addCase(createRules.pending, (state) => {
        state.parameterRulesStatus = PostDataStatus.FETCHING;
      })
      .addCase(createRules.fulfilled, (state, action) => {      
        state.parameterRulesStatus = PostDataStatus.SUCCESS;
        state.parameterRulesStatusData = action.payload;
      })
      .addCase(createRules.rejected, (state, action) => {
        state.parameterRulesStatus = PostDataStatus.FAILURE;
        state.parameterRulesStatusError = true;
        state.parameterOptionsStatusErrorMessage = action.error.message;
      })

      .addCase(getScoreCardRules.pending, (state) => {
        state.scoreCardRulesStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getScoreCardRules.fulfilled, (state, action) => {      
        state.scoreCardRulesStatus = FetchDataStatus.SUCCESS;
        state.scoreCardRuleData = action.payload;
      })
      .addCase(getScoreCardRules.rejected, (state, action) => {
        state.scoreCardRulesStatus = FetchDataStatus.FAILURE;
        state.scoreCardStatusError = true;
        state.scoreCardStatusErrorMessage = action.error.message;
      })

      

      .addCase(getSelfServiceCustomParamsByKey.pending, (state) => {
        state.selfServiceParametersByKeyStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getSelfServiceCustomParamsByKey.fulfilled, (state, action) => {      
        state.selfServiceParametersByKeyStatus = FetchDataStatus.SUCCESS;
        state.selfServiceParameterByKeyData = action.payload;
      })
      .addCase(getSelfServiceCustomParamsByKey.rejected, (state, action) => {
        state.selfServiceParametersByKeyStatus = FetchDataStatus.FAILURE;
        state.selfServiceParameterByKeyIsError = true;
        state.selfServiceParameterByKeyErrorMessage = action.error.message;
      })


      .addCase(deleteCustomParameters.pending, (state) => {
        state.deleteCustomParameterStatus = FetchDataStatus.FETCHING;
      })
      .addCase(deleteCustomParameters.fulfilled, (state, action) => {      
        state.deleteCustomParameterStatus = FetchDataStatus.SUCCESS;
        state.deleteCustomParameterData = action.payload;
      })
      .addCase(deleteCustomParameters.rejected, (state, action) => {
        state.deleteCustomParameterStatus = FetchDataStatus.FAILURE;
        state.deleteCustomParameterIsError = true;
        state.deleteCustomParameterErrorMessage = action.error.message;
      })


      .addCase(editScoreCardDetails.pending, (state) => {
        state.selfServiceEditScoreCardStatus = PostDataStatus.FETCHING;
      })
      .addCase(editScoreCardDetails.fulfilled, (state, action) => {      
        state.selfServiceEditScoreCardStatus = PostDataStatus.SUCCESS;
        state.selfServiceEditScoreCardData = action.payload;
      })
      .addCase(editScoreCardDetails.rejected, (state, action) => {
        state.selfServiceEditScoreCardStatus = PostDataStatus.FAILURE;
        state.selfServiceEditScoreCardIsError = true;
        state.selfServiceEditScoreCardIsError = action.error.message;
      })


      .addCase(getScoreCardRulesByKey.pending, (state) => {
        state.selfServiceGetRulesByKeyStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getScoreCardRulesByKey.fulfilled, (state, action) => {      
        state.selfServiceGetRulesByKeyStatus = FetchDataStatus.SUCCESS;
        state.selfServiceGetRulesByKeyData = action.payload;
      })
      .addCase(getScoreCardRulesByKey.rejected, (state, action) => {
        state.selfServiceGetRulesByKeyStatus = FetchDataStatus.FAILURE;
        state.selfServiceGetRulesByKeyIsError = true;
        state.selfServiceGetRulesByKeyErrorMessage = action.error.message;
      })



      .addCase(uploadParameter.pending, (state) => {
        state.uploadParameterStatus = PostDataStatus.FETCHING;
      })
      .addCase(uploadParameter.fulfilled, (state, action) => {      
        state.uploadParameterStatus = PostDataStatus.SUCCESS;
        state.uploadParameterData = action.payload;
      })
      .addCase(uploadParameter.rejected, (state, action) => {
        state.uploadParameterStatus = PostDataStatus.FAILURE;
        state.uploadParameterIsError = true;
        state.uploadParameterErrorMessage = action.error.message;
      })


      .addCase(uploadParameterOptions.pending, (state) => {
        state.uploadParameterOptionStatus = PostDataStatus.FETCHING;
      })
      .addCase(uploadParameterOptions.fulfilled, (state, action) => {      
        state.uploadParameterOptionStatus = PostDataStatus.SUCCESS;
        state.uploadParameterOptionData = action.payload;
      })
      .addCase(uploadParameterOptions.rejected, (state, action) => {
        state.uploadParameterOptionStatus = PostDataStatus.FAILURE;
        state.uploadParameterOptionIsError = true;
        state.uploadParameterOptionErrorMessage = action.error.message;
      })




      .addCase(deleteScoreCardRules.pending, (state) => {
        state.selfServiceDeleteRulesByKeyStatus = FetchDataStatus.FETCHING;
      })
      .addCase(deleteScoreCardRules.fulfilled, (state, action) => {      
        state.selfServiceDeleteRulesByKeyStatus = FetchDataStatus.SUCCESS;
        state.selfServiceDeleteRulesByKeyData = action.payload;
      })
      .addCase(deleteScoreCardRules.rejected, (state, action) => {
        state.selfServiceDeleteRulesByKeyStatus = FetchDataStatus.FAILURE;
        state.selfServiceDeleteRulesByKeyIsError = true;
        state.selfServiceDeleteRulesByKeyErrorMessage = action.error.message;
      })


      
      
      
      
  }
});

export const { resetCustomDecisionCreditScore,resetParameter,resetSelfService, 
              resetCustomUpdateCreditScoreStatus,resetAllCustomDecisionCreditScore,
              resetRules,resetDeleteCustomParam,resetParameterByKey,resetEditScoreCard 
              ,resetGetRulesByKey,resetDeleteRulesByKey,resetUploadParameterOptions,
              resetUploadParameter,setDownloadingTrue,setDownloadingFalse} = SelfServiceCreditScoreSlice.actions;

export const selectSelfServiceState = state => state.selfServiceCreditScore;

export default SelfServiceCreditScoreSlice.reducer;