import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchDataStatus } from '../../../library/Variables'
import { getSelfServiceCustomParamsByKey, selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../svg/Spinner/Spinner'
import './preview.css'
import PreviewParameter from './PreviewParameter'
import ScoreCardPreview from './ScoreCardPreview'

function PreviewScoreCard({setSection,accessToken,previewCard,setPreviewCard,key}) {

    const dispatch = useDispatch()
    const [ParamContent,setParamContent] = useState(<Spinner/>)
    const [showScoreCard,setShowScoreCard] = useState(true)
    const [mainScoreCardKey,setMainScoreCardKey] = useState(previewCard.key)
    const [mainParameterKey,setMainParameterKey] = useState('')
    const [paramName,setParamName] = useState('')

   
    const selfScoreDef = useSelector(selectSelfServiceState)
    const [selfScoreCardIsOpen, setSelfScoreCardIsOpen] = useState(false);
    const [paramMetaData,setParamMetaData] = useState('')




    useEffect(()=>{
    
            //dispatch(getSelfServiceCustomParams({accessToken}))
            if(selfScoreDef.selfServiceParametersByKeyStatus === FetchDataStatus.INITIAL){
                dispatch(getSelfServiceCustomParamsByKey({accessToken,key:previewCard.key}))
            }
            
        
        
    },[selfScoreDef.selfServiceParametersByKeyStatus])



  return (
    <>
        {showScoreCard?
        <ScoreCardPreview showScoreCard={showScoreCard}
                          setShowScoreCard={setShowScoreCard}
                          accessToken={accessToken}
                          setSection={setSection}
                          previewCard={previewCard}
                          setPreviewCard={setPreviewCard}
                          setParamMetaData={setParamMetaData}
                          setMainParameterKey={setMainParameterKey}
                          setParamName={setParamName}
                          paramName={paramName}
                          mainScoreCardKey={mainScoreCardKey}
                          mainParameterKey={mainParameterKey}
        />:
        <PreviewParameter showScoreCard={showScoreCard}
                          setShowScoreCard={setShowScoreCard}
                          accessToken={accessToken}
                          setSection={setSection}
                          previewCard={previewCard}
                          setParamMetaData={setParamMetaData}
                          paramMetaData={paramMetaData}
                          setMainParameterKey={setMainParameterKey}
                          mainScoreCardKey={mainScoreCardKey}
                          mainParameterKey={mainParameterKey}
                          />}
    </>
    
  )
}

export default PreviewScoreCard