import React from 'react'
import affordabilityIcon from '../../../../../../assests/icons/affordMainIcon.svg'
import analyticsIcon from '../../../../../../assests/icons/AnalyticsIcon.svg'
import transactIcon from '../../../../../../assests/icons/transactMainIcon.svg'
import Tabs from '../../../../../../components/Tabs/Tabs'
import DynamicCard from '../../DynamicCard'
import { businessDetailsData, TransactionDataHistory } from './DataHolder/TransactDataBank'
import AffordabilityForm from './TransactionComponents/AffordabilityForm'
import MainAnalysis from './TransactionComponents/MainAnalysis'
import TransactionHist from './TransactionComponents/TransactionHistory'

function TransactionAnalysis() {
    const svgConstant = '../../../../../../assests/icons'
    let tabBodiesArray = [];
    const listHeader = ['Date','Description','Type','Amount','Balance']

    tabBodiesArray.push({
        title: 'Analytics',
        iconSrc: <img src= {analyticsIcon} alt='' />,
        content: <MainAnalysis/>
      });


    tabBodiesArray.push({
      title: 'Transaction',
      iconSrc: <img src= {transactIcon} alt='' />,
      content: <TransactionHist TransactionDataHistory={TransactionDataHistory} 
                                listHeader={listHeader} fullWidth={true}/>
    });


    tabBodiesArray.push({
      title: 'Affordability',
      iconSrc: <img src={affordabilityIcon} />,
      content: <AffordabilityForm/>
    }); 

  return (
    <div style={{width:"100%", height:'fit-content',padding:"30px",display:"flex",flexDirection:"column",rowGap:"20px",boxSizing:"border-box"}}>
        <DynamicCard data={businessDetailsData}/>

        <Tabs tabBodies={tabBodiesArray} extraClass='analytics-tabs' background={true} />
    </div>
  )
}

export default TransactionAnalysis