
import Utils from "../../../library/Utils";

export const driverLicenseSerializer = (data)=>{

let driverLicenceDataReshaped = {}


if(!Utils.isFieldEmpty(data)){
    const driverKeys = Object.keys(data);

    driverKeys.map((element)=>
        (!Utils.isFieldEmpty(data[element]) && 
        (typeof(data[element])==='string' || 
        typeof(data[element])==='number' ||
        typeof(data[element])==='boolean'))?
        driverLicenceDataReshaped[element] = data[element]
        :''
    )

}

return {...driverLicenceDataReshaped}
}