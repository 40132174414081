import React,{useState,useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import eyeSlashIcon from '../../assests/svg-icons/eye-slash.svg'
import eyeIcon from '../../assests/svg-icons/eye.svg'
import { selectPasswordReset,
         postPasswordReset } 
         from '../../redux/Slices/PasswordCollection/ResetPassword/ResetPasswordClient';
import ResetPassWordModal from '../../components/Modals/PasswordModals/ResetPassWordModal';
import { useSelector,useDispatch } from 'react-redux';
import { selectConfigurationState } from '../../redux/Slices/Configuration';
import Spinner from '../FraudDetect/components/loader';
import './resetPass.css'
import { PostDataStatus } from '../../library/Variables';
import Utils from '../../library/Utils'
import Modal from "react-modal";



function ResetPassword() {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const configuration = useSelector(selectConfigurationState);
    const resetState = useSelector(selectPasswordReset)
    const [contentHold,setContentHold] = useState()
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showPassword,setShowPassword] = useState(false)
    const [showConfirmPass,setShowConfirmPass] = useState(false)
    const resetCode = queryParams.get('resetCode');
    const tenant = queryParams.get('tenantName');

    function onSubmitLogin(formData){
      dispatch(postPasswordReset({
        body:{
          resetCode,
          password: formData.password,
          tenant,
          cPassword:formData.cpassword,
        }}) );
    }

    function closeModal() {
      setIsOpen(false);
      //dispatch(resetDownload());
    }

    
  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

    const formContent = <>
        <h2 className='detail-header'>Reset Password</h2>
        <span className='formDirective'>Please, enter your new password and confirm password</span>
        <form onSubmit={handleSubmit(onSubmitLogin)} className="login-form-container">
          <div className="login-input-container">
            <label className="input-labels">Password</label>
            <div className="input-container">
              <input className="login-inputs inputs" type={showPassword?'text':'password'} placeholder="Enter your password"
                {...register("password", { required: "Password is required" })} />
                <img onClick={()=>setShowPassword(!showPassword)}
                  src={showPassword?eyeIcon:eyeSlashIcon} 
                  alt='Password icon' 
                  className="login-icon input-icon lock"
                  />
            </div>
            <span className="error-msg">{errors.password?.message}</span>
          </div>

          <section className='policyTitle'>
            <section className='policyHeader'>Our Password policy includes:</section>
            <ul>
              <li>{`Password must contain minimum length of 
                  ${configuration.configuration.passwordPolicyConfiguration.minimumLength}
                  letters.`}
              </li>

              <li>{`Password must contain maximum length of 
                  ${configuration.configuration.passwordPolicyConfiguration.maximumLength}
                  letters.`}
              </li>

               <li>{`Password must contain at least
                  ${configuration.configuration.passwordPolicyConfiguration.lowercase}
                  lowercase letter.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.configuration.passwordPolicyConfiguration.uppercase}
                  uppercase letter.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.configuration.passwordPolicyConfiguration.number}
                  number.`}
              </li>

              <li>{`Password must contain at least
                  ${configuration.configuration.passwordPolicyConfiguration.specialCharacter}
                  special character.`}
              </li>

              {(!Utils.isFieldEmpty(configuration.configuration.passwordPolicyConfiguration.duration)  
                && configuration.configuration.passwordPolicyConfiguration.duration>0)
                && 
                <li>{`Password would only be valid for a period of 
                    ${configuration.configuration.passwordPolicyConfiguration.duration}
                    days.`}
                </li>
              }

              
            </ul>
          </section>
          <div className="login-input-container">
            <label className="input-labels">Confirm Password</label>
            <div className="input-container">
              <input className="login-inputs inputs" type={showConfirmPass?'text':'password'} placeholder="Enter your password"
                {...register("cpassword", { required: "Confirm Password is required" })} />
              <img src={showConfirmPass?eyeIcon:eyeSlashIcon} 
                   onClick={()=>setShowConfirmPass(!showConfirmPass)}
                   className="login-icon input-icon lock" alt='Password icon' />
            </div>
            <span className="error-msg">{errors.password?.message}</span>
          </div>

          <div className="buttons-container">
            <button type='submit' 
                    className="button-square btn-login"
                    disabled={resetState.resetPassStatus===PostDataStatus.FETCHING?true:false}>
                      {resetState.resetPassStatus===PostDataStatus.FETCHING?'Submitting...':'Submit'}
                    </button>
          </div>
        </form>
      </>

    const  mainContent = <div className="login-right-container">
                            <img src='/assets/logo/logo_small.svg' className='login-insights-logo' alt='Insights' />
                            {formContent}
                        </div>

  useEffect(()=>{
    if(resetState.resetPassStatus===PostDataStatus.INITIAL){
      setContentHold(mainContent)
    }

    else if(resetState.resetPassStatus===PostDataStatus.FETCHING){
      setContentHold(mainContent)
    }
    else {
      setContentHold(mainContent)
      setIsOpen(true)
        }
  },[resetState.resetPassStatus,showPassword,showConfirmPass])


  return (
    <div className="login-body">
      <div className="login-main">
        <div className="login-left">
          <div className='login-left-container'>
            <img src='/assets/images/bg-image.svg' alt='' />
          </div>
        </div>     
        <div className="login-right">
          {contentHold}      
        </div>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}
          contentLabel="Reset Password State" >
          <ResetPassWordModal closeModal={closeModal}/>
      </Modal> 
      </div>
    </div>
  )
}

export default ResetPassword