import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
let trackNo

export const getNationalAndAlienId = createAsyncThunk('kenyaID/createNAI', async (params) => {
    trackNo = params.idNumber
    let data = {
        number: params.idNumber,
    }
    data = JSON.stringify(data)
    
   const naiData = await client.post('/verify/idnumber',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });

    return naiData;
   }
 );


 export const getAlienId = createAsyncThunk('kenyaID/createAlienID', async (params) => {
  trackNo = params.idNumber
  let data = {
      number: params.idNumber,
  }
  data = JSON.stringify(data)
  
 const naiData = await client.post('/verify/alienid',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return naiData;
 }
);


export const getPlateNumber = createAsyncThunk('kenyaID/createPlateNumber', async (params) => {
  trackNo = params.obj.number

  let data = JSON.stringify(params.obj)
  
 const plateData = await client.post('/verify/plate',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return plateData;
 }
);


export const getKraData = createAsyncThunk('kenyaID/createKraData', async (params) => {
  trackNo = params.obj.number
  
  let data = JSON.stringify(params.obj)
  
 const KraData = await client.post('/verify/krapin',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return KraData;
 }
);


export const getDriverKenya = createAsyncThunk('kenyaID/createDriverLicence', async (params) => {
  trackNo = params.obj.number
  let data = JSON.stringify(params.obj)
  
 const driverData = await client.post('/verify/license_ke',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return driverData;
 }
);


export const getAccountDataID = createAsyncThunk('kenyaID/getBankAccountData', async (params) => {
  trackNo = params.bankAccount
  let data = {
    account: params.bankAccount,
    statementKey: null,
    bankid: params.bankId
  }
  data = JSON.stringify(data)
 const AccountData = await client.post('/verify/ke/bankaccount',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });
  return AccountData;
 }
);


export const getPhoneDataID = createAsyncThunk('kenyaID/getPhoneData', async (params) => {
  trackNo = params.phoneNumber
  let data = {
      msisdn: params.phoneNumber,
      statementKey: parseInt(params.statementKey),
  }
  data = JSON.stringify(data)
  
 const phoneData = await client.post('/verify/ke/phonenumber',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return phoneData;
 }
);


export const getBankListID = createAsyncThunk('kenyaID/getBankList',async (accessToken)=>{
  const listOfBanks = await client.get('/verify/ke/banklist',
  {
    "headers":
    {
     "Authorization": "Bearer " + accessToken,
    }
  });
  return listOfBanks.data
 })






 export const getReportDetail = createAsyncThunk('kenyaID/getReportData',async(params)=>{
  const type = params.type.toLowerCase()
  const reportData = await client.get(`/verify/history/${type}/${params.uuid}`,{
    "headers":{
      "Authorization": "Bearer " + params.accessToken,
    }
  });
  return reportData.data;
})

export const getStandAloneDataHistoryKenya = createAsyncThunk('kenyaID/getStandAloneHistoryData',async (params)=> {

  try {
    const response = await client.get(
      `/verify/kychistory/${params.page}/${params.perPage}/INDIVIDUAL`,
      {
        headers: {
          Authorization: "Bearer " + params.accessToken,
        },
      }
    );
 // This should now correctly log the data
    return response.data; // Make sure to return the correct data
  } catch (error) {
    console.error('Error fetching KYC history', error);
    throw error; // Throw error so it can be handled by Redux
  }

})



 export const idKenyaDataSlice = createSlice({
    name: 'kenyaIdVerification',
    initialState: {
      extractionStatus : PostDataStatus.INITIAL,
      isError : false,
      errorMessage : null,
      activeExtractions:[],

      standAloneStatus:FetchDataStatus.INITIAL,
      standAloneErrorMessage:null,

      reportData : null,
      reportStatus : FetchDataStatus.INITIAL,
      reportErrorMessage : null,
      isReportError : false,


      naiExtraction: {},
      naiExtractionStatus: PostDataStatus.INITIAL,
      naiExtractionisError: false,
      naiExtractionErrorMessage: null,


      alienExtraction: {},
      alienExtractionStatus: PostDataStatus.INITIAL,
      alienExtractionisError: false,
      alienExtractionErrorMessage: null,

      PlateExtraction: {},
      plateExtractionStatus: PostDataStatus.INITIAL,
      plateExtractionisError: false,
      plateExtractionErrorMessage: null,

      KraExtraction: {},
      KraExtractionStatus: PostDataStatus.INITIAL,
      KraExtractionisError: false,
      KraExtractionErrorMessage: null,

      DriverExtraction: {},
      DriverExtractionStatus: PostDataStatus.INITIAL,
      DriverExtractionisError: false,
      DriverExtractionErrorMessage: null,

      BankAccountExtraction:{},
      BankAccountStatus:PostDataStatus.INITIAL,
      BankAccountisError:false,
      BankAccountErrorMessage:null,


      PhoneDataExtraction:{},
      PhoneDataStatus:PostDataStatus.INITIAL,
      PhoneDataisError:false,
      PhoneDataErrorMessage:null,


      bankNames:[],
      bankNamesStatus:FetchDataStatus.INITIAL,
      bankNameError:null



    },
    reducers: {
      addToExtractedData: (state,action) => {
        //state.activeExtractions = [...state.activeExtractions,action.payload]
      },

      resetNAIstatus: (state)=>{
        state.naiExtraction = {};
        state.naiExtractionStatus = PostDataStatus.INITIAL
        state.naiExtractionErrorMessage = null
        state.naiExtractionisError = false
      },

      resetAlienstatus: (state)=>{
        state.alienExtraction = {};
        state.alienExtractionStatus = PostDataStatus.INITIAL
        state.alienExtractionErrorMessage = null
        state.alienExtractionisError = false
      },

      resetPlateNumbertatus: (state)=>{
        state.PlateExtraction = {};
        state.plateExtractionStatus = PostDataStatus.INITIAL
        state.plateExtractionErrorMessage = null
        state.plateExtractionisError = false
      },

      resetKraStatus: (state)=>{
        state.KraExtraction = {};
        state.KraExtractionStatus = PostDataStatus.INITIAL
        state.KraExtractionErrorMessage = null
        state.KraExtractionisError = false
      },

      resetKenyaDriverLicenseStatus: (state)=>{
        state.DriverExtraction = {};
        state.DriverExtractionStatus = PostDataStatus.INITIAL
        state.DriverExtractionErrorMessage = null
        state.DriverExtractionisError = false
      },


      resetReport: (state)=>{
        state.reportData = null;
        state.reportStatus = FetchDataStatus.INITIAL
        state.reportErrorMessage = null
        state.isReportError = false
      },
      resetDataStatus: (state)=>{
        state.extractionStatus = PostDataStatus.INITIAL
        state.isError = false
        state.errorMessage = null
      },

      resetBankAccounts: (state)=>{
        state.BankAccountStatus = PostDataStatus.INITIAL
        state.BankAccountErrorMessage = null
        state.BankAccountisError = false
      },

      resetPhoneNumber: (state)=>{
        state.PhoneDataStatus = PostDataStatus.INITIAL
        state.PhoneDataErrorMessage = null
        state.PhoneDataisError = false
        state.PhoneDataExtraction = {}
      }
    },
    
    extraReducers(builder) {
    builder.addCase(getNationalAndAlienId.pending, (state) => {
      state.naiExtractionStatus = PostDataStatus.FETCHING;
    }).addCase(getNationalAndAlienId.fulfilled, (state,action) => {
      const newObj = action.payload
      const mimeType = 'data:image/jpeg;base64,'
      const img = mimeType + action.payload['photo']
      const mainObj = {}
      delete newObj.photo;
      mainObj.data = newObj
      mainObj.verificationType = "IDNUMBER"
      mainObj.status = 'FOUND'
      mainObj.trackingNumber = action.payload['idNumber']
      mainObj.fullName = `${action.payload['firstName']} ${action.payload['lastName']}  ${action.payload['otherName']?action.payload['otherName']:''}`
      mainObj.img = img
      state.naiExtractionStatus = PostDataStatus.SUCCESS;
      state.naiExtraction = action.payload;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [mainObj,...state.activeExtractions]
    }).addCase(getNationalAndAlienId.rejected, (state,action) => {
      state.naiExtractionStatus = PostDataStatus.FAILURE;
      state.naiExtractionisError = true;
      state.naiExtractionErrorMessage = action.error.message;
    })


    .addCase(getAlienId.pending, (state) => {
      state.alienExtractionStatus = PostDataStatus.FETCHING;
    }).addCase(getAlienId.fulfilled, (state,action) => {
      const newObj = action.payload
      const mimeType = 'data:image/jpeg;base64,'
      const img = mimeType + action.payload['photo']
      const mainObj = {}
      delete newObj.photo;
      mainObj.data = newObj
      mainObj.verificationType = "ALIENIDNUMBER"
      mainObj.status = 'FOUND'
      mainObj.trackingNumber = action.payload['idNumber']
      mainObj.fullName = `${action.payload['firstName']} ${action.payload['lastName']}  ${action.payload['otherName']?action.payload['otherName']:''}`
      mainObj.img = img
      state.alienExtractionStatus = PostDataStatus.SUCCESS;
      state.alienExtraction = action.payload
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [mainObj,...state.activeExtractions]
    }).addCase(getAlienId.rejected, (state,action) => {
      state.alienExtractionStatus = PostDataStatus.FAILURE;
      state.alienExtractionisError = true;
      state.alienExtractionErrorMessage = action.error.message;
    })




    .addCase(getPlateNumber.pending, (state) => {
      state.plateExtractionStatus = PostDataStatus.FETCHING;
    }).addCase(getPlateNumber.fulfilled, (state,action) => {
      const newObj = action.payload
      const mimeType = 'data:image/jpeg;base64,'
      const img = mimeType + action.payload['photo']
      const mainObj = {}
      delete newObj.photo;
      mainObj.data = newObj
      mainObj.verificationType = "PLATE_NUMBER"
      mainObj.status = 'FOUND'
      mainObj.dateCreated = action.payload['registrationDate']
      mainObj.trackingNumber = action.payload['regNo']
      mainObj.fullName = `${action.payload['owner'][0]?.FIRSTNAME} ${action.payload['owner'][0]?.MIDDLENAME}  
                          ${action.payload['owner'][0]?.LASTNAME}`
      mainObj.img = img
      state.plateExtractionStatus = PostDataStatus.SUCCESS;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [mainObj,...state.activeExtractions]
      state.PlateExtraction = action.payload
    }).addCase(getPlateNumber.rejected, (state,action) => {
      state.plateExtractionStatus = PostDataStatus.FAILURE;
      state.plateExtractionisError = true;
      state.plateExtractionErrorMessage = action.error.message;
    })





    .addCase(getKraData.pending, (state) => {
      state.KraExtractionStatus = PostDataStatus.FETCHING;
    }).addCase(getKraData.fulfilled, (state,action) => {
      const newObj = action.payload
      const mimeType = 'data:image/jpeg;base64,'
      const img = mimeType + action.payload['photo']
      const mainObj = {}
      delete newObj.photo;
      mainObj.data = newObj
      mainObj.verificationType = "KRAPIN"
      mainObj.status = 'FOUND'
      mainObj.trackingNumber = action.payload['pinNo']
      mainObj.fullName = `${action.payload['tradingBusinessName']}`
      mainObj.img = img
      mainObj.uuid=action.payload['uuid']
      state.KraExtractionStatus = PostDataStatus.SUCCESS;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [mainObj,...state.activeExtractions]
      state.KraExtraction = action.payload
    }).addCase(getKraData.rejected, (state,action) => {
      state.KraExtractionStatus = PostDataStatus.FAILURE;
      state.KraExtractionisError = true;
      state.KraExtractionErrorMessage = action.error.message;
    })






    .addCase(getDriverKenya.pending, (state) => {
      state.DriverExtractionStatus = PostDataStatus.FETCHING;
    }).addCase(getDriverKenya.fulfilled, (state,action) => {
      const newObj = action.payload
      const mimeType = 'data:image/jpeg;base64,'
      const img = mimeType + action.payload['photo']
      const mainObj = {}
      delete newObj.photo;
      mainObj.data = newObj
      mainObj.verificationType = "DRIVER_LICENCE_KENYA"
      mainObj.status = 'FOUND'
      mainObj.trackingNumber = action.payload['idNumber']
      mainObj.fullName = `${action.payload['firstName']} ${action.payload['lastName']}  ${action.payload['otherName']?action.payload['otherName']:''}`
      mainObj.img = img
      state.DriverExtractionStatus = PostDataStatus.SUCCESS;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [mainObj,...state.activeExtractions]
      state.DriverExtraction = action.payload
    }).addCase(getDriverKenya.rejected, (state,action) => {
      state.DriverExtractionStatus = PostDataStatus.FAILURE;
      state.DriverExtractionisError = true;
      state.DriverExtractionErrorMessage = action.error.message;
    })







    .addCase(getAccountDataID.pending, (state) => {
      state.BankAccountStatus = PostDataStatus.FETCHING;
    })
    .addCase(getAccountDataID.fulfilled, (state,action) => {

      const newObj = {status:'FOUND',
                      verificationType:'ACCOUNT_NUMBER',
                      fullName: `${action.payload.data.name}`,
                      statue: 'FOUND',
                      trackingNumber: action.payload.data.account_number,
                      };
      state.BankAccountStatus = PostDataStatus.SUCCESS;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [newObj,...state.activeExtractions]
      state.BankAccountExtraction = action.payload
    }).addCase(getAccountDataID.rejected, (state,action) => {
      state.BankAccountStatus = PostDataStatus.FAILURE;
      state.BankAccountisError = true;
      state.BankAccountErrorMessage = action.error.message;
    })





    .addCase(getPhoneDataID.pending, (state) => {
      state.PhoneDataStatus = PostDataStatus.FETCHING;
    }).addCase(getPhoneDataID.fulfilled, (state,action) => {

      const newObj = {status:'FOUND',
                      verificationType:'PHONE_NUMBER',
                      fullName: `${action.payload.data.name}`,
                      trackingNumber: action.payload.data.phone,
                      };
      state.PhoneDataStatus = PostDataStatus.SUCCESS;
      state.extractionStatus = PostDataStatus.SUCCESS;
      //state.activeExtractions = [newObj,...state.activeExtractions]
      state.PhoneDataExtraction = action.payload
    }).addCase(getPhoneDataID.rejected, (state,action) => {
      state.PhoneDataStatus = PostDataStatus.FAILURE;
      state.PhoneDataisError = true;
      state.PhoneDataErrorMessage = action.error.message;
    })






    .addCase(getBankListID.pending, (state) => {
      state.bankNamesStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getBankListID.fulfilled, (state,action) => {
      state.bankNamesStatus = FetchDataStatus.SUCCESS;
      state.bankNames = action.payload;
    }).addCase(getBankListID.rejected, (state,action) => {
      state.bankNamesStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })


  
  
  
    
    .addCase(getReportDetail.pending, (state)=>{
      state.reportStatus = FetchDataStatus.FETCHING

    })
    
    .addCase(getReportDetail.fulfilled, (state,action)=>{
      state.reportStatus = FetchDataStatus.SUCCESS
      state.reportData = action.payload
    })
    
    .addCase(getReportDetail.rejected,(state,action)=>{
      state.reportStatus = FetchDataStatus.FAILURE
      state.reportErrorMessage = action.error.message
      state.isReportError = true
    })


    




    .addCase(getStandAloneDataHistoryKenya.pending, (state)=>{
      state.standAloneStatus = FetchDataStatus.FETCHING
    })
    .addCase(getStandAloneDataHistoryKenya.fulfilled,(state,action)=>{
      state.standAloneStatus = FetchDataStatus.SUCCESS
      state.activeExtractions = action.payload
    })
    .addCase(getStandAloneDataHistoryKenya.rejected,(state,action)=>{
      state.standAloneStatus = FetchDataStatus.FAILURE
      state.standAloneErrorMessage = action.error.message
    })
}})
  
  

  
  
  export const {resetNAIstatus,
                resetReport,
                resetDataStatus,
                resetAlienstatus,
                resetKenyaDriverLicenseStatus,
                resetKraStatus,
                resetPlateNumbertatus,
                resetPhoneNumber,
                resetBankAccounts} = idKenyaDataSlice.actions;
  
  
  //export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;
  export const selectKycEntireDataState = state => state.kenyaIdVerification
 
  
  
  
  
  export default idKenyaDataSlice.reducer;
  
  
  /**
   * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
      let mainArray = action.payload
      let newArray = []
      const mimeType = 'data:image/jpeg;base64,'
      for(let i=0;i<mainArray.length;i++){
        const newObj = mainArray[i]
        let img;
        let signature
        if(action.payload[i]['base64Image']){
          img = mimeType + action.payload[i]['base64Image']
        }
        
        if(action.payload[i]['photo']){
          img = action.payload[i]['photo']
        }
        if(action.payload[i]['signature']){
          signature = action.payload[i]['signature']
        }
        else{
          img = null;
        }
        
        const mainObj = {}
        delete newObj.base64Image;
        delete newObj.photo;
        delete newObj.signature;
        mainObj.data = newObj
        mainObj.kycType = action.payload[i]['verificationType']
        mainObj.status = 'FOUND'
        mainObj.img = img
        mainObj.signature = signature
        newArray.push(mainObj)
      }
  
      state.activeExtractions = [...newArray]
      state.historyStatus = PostDataStatus.SUCCESS;
    })
   */