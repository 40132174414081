import Accordion from "../../../components/Accordion/Accordion";
import List from "../../../components/List/List";
import { Table } from "../../../components/Table/Table";
import Utils from "../../../library/Utils";
import Speedometer from "../../../svg/Speedometer/Speedometer";

const bestColor = "#4EB151";
const goodColor = "#87DE65";
const middleColor = "#F2D366";
const badColor = "#FFBC73";
const worstColor = "#FF725E";

// Format detail String based on type
export const formatDetailValue = (type, value, configuration) => {
  if(!Utils.isFieldEmpty(type) && type.toUpperCase() === 'CURRENCY') {
    return Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol,value);
  } else if(type !== null && type !== undefined && (type.toUpperCase() === 'PERCENT' || type.toUpperCase() === "PERCENTAGE")) {
    return Utils.formatPercentage(value);
  } else if(type !== null && type !== undefined && type.toUpperCase() === 'TEXT') {
    return value;
  } 
}

// Format score and color 
export const formatPointValue = (type, didPass, obtainedPoints, isOverall) => {
  let points = null;
  let color = null;

  if(type === 'RULES') {        
    didPass === true ? points = 'True' : didPass === false ? points = 'False' : points = 'N/A';
  } else if(type === 'POINTS') {
    if(isOverall || obtainedPoints === 0) {
      points = String(obtainedPoints);
      color = "#f2d366";
    } else {
     if(obtainedPoints > 0) {
       points = "+ " + obtainedPoints;
       color = "#04b942";
     } else if(obtainedPoints < 0) {
       points = String(obtainedPoints).replace("-", "- ");
       color = "#ff725e";
     }
    }
  }

  return { points, color };
}

export const breakdownByColor = (score, highestScore, lowestScore) => {
  let interval = (highestScore - lowestScore) / 5;
  let adjustedScore = score - lowestScore;
  let color = null;

  for(let i = 1; i < 6; i++) {
    let x = interval * i;
    
    if(i === 1 && adjustedScore <= x) {
      color = worstColor;
      break;
    } else if(i === 2 && adjustedScore <= x) {
      color = badColor;
      break;
    } else if(i === 3 && adjustedScore <= x) {
      color = middleColor;
      break;
    } else if(i === 4 && adjustedScore <= x) {
      color = goodColor;
      break;
    } else if(i === 5 && adjustedScore <= x) {
      color = bestColor;
      break;
    }
  }

  return color;
}

export const getScoreRatingDescription = (score, didPass, highestScore, lowestScore, rangesSorted) => {
  let sentiment = null;
  let sentimentColor = null;

  if(score !== null) {
    rangesSorted.forEach(element => {
      if(score >= element.minScore && score < element.maxScore) {
        sentiment = element.sentiment;
      } else if(score <= 0) {
        sentiment = rangesSorted[0].sentiment;
      }
    });
    
    sentimentColor = breakdownByColor(score, highestScore, lowestScore);
  } else if(score === null && didPass) {
    sentimentColor = bestColor;
  } else if(score === null && !didPass) {
    sentimentColor = worstColor;
  } else {
    sentimentColor = middleColor;
  }

  return { sentiment, sentimentColor };
}

export const evaluateScore = (score, didPass, type, highestScore, lowestScore) => {
  if(type === "POINTS" && score !== null) {
    if(score >= highestScore) {
      return highestScore;
    } else if(score < lowestScore && score > 0) {
      return lowestScore;
    } else if(score <= 0) {
      return 0;
    } else  {
      return score;
    }
  } else if(type === "RULES" && didPass !== null) {
    if(didPass === true) {
      return "Passed";
    } else if(didPass === false) {
      return "Failed";
    } 
  } else {
    return "ERROR";
  }
}

export const configureInitialView = ({ credit, accordion, configuration, tableHeaders, update, isUpdatable, user }) => {
  let creditScores = [];
  let accBodiesArray = [];
  let currentRules = [];
  let currentRulesSorted = [];
  let currentScore = 0;
  let currentScoreContent = null;
  let currentBreakdownContent = null;
  let currentScoreSentiment = null;
  let mainBodyContent = null;
  let ranges = [];
  let highestScore = null;
  let lowestScore = null;

  if(configuration.clientScoreRanges !== null && configuration.clientScoreRanges !== undefined) {
    configuration.clientScoreRanges.forEach(element => {
      ranges.push(element);
    });

    ranges.sort((firstMin, secondMin) => firstMin.minScore - secondMin.minScore);

    lowestScore = ranges[0].minScore;
    highestScore = ranges[(ranges.length - 1)].maxScore;
  }
  
  if(!Utils.isFieldEmpty(credit.scoresBreakdowns)) {
    (credit.scoresBreakdowns).forEach(element => {
      creditScores.push(element);
    });

    creditScores.sort(function(a, b) {
      return new Date(b.createdDate) - new Date(a.createdDate);
    });
    currentScore = creditScores[0];

    // Setup Accordion and Table data
    creditScores.forEach(cs => {
      let data = [];
  
      Utils.groupByLHS(cs.rules).forEach((element, i) => {
        let rightHandSide = null;
        let operator = element.friendlyOperator;
  
        if(element.rightHandSide.includes("$Statement.") === true) {
          rightHandSide =  element.friendlyRightHandSide + ": " 
            + formatDetailValue(element.friendlyRightHandSideDisplayType, element.actualRightHandSide, configuration);
        } else if(element.friendlyOperator.toUpperCase() === 'BETWEEN') {
          rightHandSide = 'Between ' + element.friendlyRightHandSide;
          operator = null;
        } else {
          rightHandSide = formatDetailValue(element.friendlyRightHandSideDisplayType, element.actualRightHandSide, configuration);
        }
        
        data.push({
          friendlyLeftHandSide: element.friendlyLeftHandSide,
          actualLeftHandSide: formatDetailValue(element.friendlyLeftHandSideDisplayType, element.actualLeftHandSide, configuration),
          friendlyOperator: operator,
          friendlyRightHandSide: rightHandSide,
          obtainedPoints: element.obtainedPoints,
          didPass: element.didPass,
          passingSentiment: element.passingSentiment
        })
      });

      data.sort(function(a, b) {
        var leftHandNameA = a.friendlyLeftHandSide.toUpperCase();
        var leftHandNameB = b.friendlyLeftHandSide.toUpperCase();
        if (leftHandNameA < leftHandNameB) {
          return -1;
        }
        if (leftHandNameA > leftHandNameB) {
          return 1;
        }

        return 0;
      });
  
      let content = <>
        <h3 className="detail-header">Breakdown</h3>
        {cs.baseScore !== null &&
          <div className="cs-base-points-container">
            <h3>Base Points</h3>
            <h3>{currentScore.baseScore}</h3>
          </div>
        }
        <div className="cr-table-container scrollbar">
          <Table data={data} columns={tableHeaders} />
        </div>
      </>;

      if(accordion.id === "custom-credit-score-accordion") {
        accBodiesArray.push({
          headers: [
            { 
              text: <><span className="text-medium-dark">Scorecard name: </span>{Utils.checkNull(cs.scorecardName)}</>,
              color: "#000" 
            },
            Utils.formatLongDate(cs.createdDate),
            { 
              text: evaluateScore(Utils.checkNegativeNumber(cs.calculatedScore), cs.didPass, cs.type, highestScore, lowestScore),
              color: getScoreRatingDescription(cs.calculatedScore, cs.didPass, highestScore, lowestScore, ranges).sentimentColor 
            }
          ],
          content: content
        });
      } else {
        accBodiesArray.push({
          headers: [
            Utils.formatLongDate(cs.createdDate),
            { 
              text: evaluateScore(Utils.checkNegativeNumber(cs.calculatedScore), cs.didPass, cs.type, highestScore, lowestScore),
              color: getScoreRatingDescription(cs.calculatedScore, cs.didPass, highestScore, lowestScore, ranges).sentimentColor 
            }
          ],
          content: content
        });
      }
    });

    // Set up Current Score Breakdown List
    currentScore.rules.forEach(el => {
      currentRules.push(el);
    })

    currentRules.sort(function(a, b) {
      let leftHandNameA = a.friendlyLeftHandSide.toUpperCase();
      let leftHandNameB = b.friendlyLeftHandSide.toUpperCase();
      
      if (leftHandNameA < leftHandNameB) {
        return -1;
      }
      if (leftHandNameA > leftHandNameB) {
        return 1;
      }

      return 0;
    });

    Utils.groupByLHS(currentRules).forEach(current => {
      let rightHandSide = null;
  
      if((current.rightHandSide.includes("$Statement.") === true) || (current.friendlyOperator.toUpperCase() === 'BETWEEN')) {
        rightHandSide = current.friendlyRightHandSide;
      } else {
        rightHandSide = formatDetailValue(current.friendlyRightHandSideDisplayType, current.actualRightHandSide, configuration);
      }
  
      currentRulesSorted.push({
        header: current.friendlyLeftHandSide,
        detail: formatDetailValue(current.friendlyLeftHandSideDisplayType, current.actualLeftHandSide, configuration),
        sublist: {
          header: ((current.operator).replace(/_/g, " ")).toLowerCase() + " " + rightHandSide,
          detail: formatPointValue(currentScore.type, current.didPass, current.obtainedPoints).points,
          textColor: formatPointValue(currentScore.type, current.didPass, current.obtainedPoints).color
        }
      })
    })

    // Format content of Current Credit Score based on type
    if(currentScore.type === "POINTS" && currentScore.calculatedScore !== null) {
      currentScoreSentiment = getScoreRatingDescription(
        evaluateScore(currentScore.calculatedScore, null, currentScore.type, highestScore, lowestScore), 
        currentScore.didPass, highestScore, lowestScore, ranges).sentiment;

      currentScoreContent = <div className="cs-current-speedometer-container">
        <Speedometer obtainedPoints={currentScore.calculatedScore} minScore={lowestScore} maxScore={highestScore} 
          passingSentiment={currentScoreSentiment} customGrade={currentScore?.customGrade} checkCustomGrade={configuration?.featureFlags?.isCustomGradeEnabled} />
      </div>
    } else if(currentScore.type === "POINTS" && currentScore.calculatedScore === null) {
      currentScoreContent = <div className="cs-current-rule-container">
        <img src="/assets/icons/danger-solid-orange.svg" alt="" />
        <h4 className="text-warning">Error</h4>
      </div>
    } else if(currentScore.type === "RULES") {
      currentScoreContent = <div className="cs-current-rule-container">
        {currentScore.didPass === null
          ? <>
              <img src="/assets/icons/danger-solid-orange.svg" alt="" />
              <h4 className="text-warning">Error</h4>
            </>
          : currentScore.didPass 
            ? <>
                <img src="/assets/icons/tick-square-solid-green.svg" alt="" />
                <h4 className="text-success">Passed</h4>
              </>
            : <>
                <img src='/assets/icons/close-square-color-red.svg' alt='' />
                <h4 className="text-error">Failed</h4>
              </>
        }
      </div>
    }

    // Format content of Current Score Breakdown
    if((currentScore.type === "RULES" && currentScore.didPass !== null) 
    || (currentScore.type === "POINTS" && currentScore.calculatedScore !== null)) {
      currentBreakdownContent = <>
        {currentScore.baseScore !== null &&
        <>
          <div className="cs-base-points-container detail-header">
            <h3>Base Points</h3>
            <p>{currentScore.baseScore}</p>
          </div>
          <hr className="separator-blue" />
        </>
        }
        <div className="cs-breakdown-scroll-container scrollbar">
          <List listContent={currentRulesSorted} lineColor={true} />
        </div>
        <hr className="separator-blue" />
        <div className="cs-breakdown-total-container detail-header">
          <h4>Total</h4>
          <p>
            {formatPointValue(currentScore.type, currentScore.didPass, Utils.checkNegativeNumber(currentScore.calculatedScore), true).points}
          </p>
        </div>
      </>
    } else {
      currentBreakdownContent = <p>An unexpected error has occured. Please contact us to resolve the issue.</p>;
    }

    mainBodyContent = <>
      <div className="cs-current-container">

        {
          user.user.permissions?.viewCurrentScore === true && 
          <div className="cs-current-score">
            <div className="cs-current-header">
              <div className="cs-current-header-text">
                <h3 className="detail-header">Current Score</h3>
                {accordion.id === "custom-credit-score-accordion" &&
                  <p className="text-medium">Scorecard used:
                    <span className="text-dark">{Utils.checkNull(currentScore.scorecardName)}</span>
                  </p>
                }
                <p className="text-medium">Last updated:
                  <span className="text-dark">{Utils.formatLongDate(currentScore.createdDate)}</span>
                </p>
              </div>
              {isUpdatable && user.user.permissions.generateScorecard === true && 
                <button className="button-square button-solid" onClick={update} >Update</button>
              }
            </div>
            {currentScoreContent}
          </div>
        }

        {user.user.permissions.checkScorecard === true &&
          <div className="cs-current-breakdown">
            { currentBreakdownContent }
          </div>
        }
      </div>
      {user.user.permissions.checkScorecard === true &&
        <div className="cards cs-history-container">
          <h3 className="detail-header">Score History</h3>
          <p className="text-medium">See all scores generated so far</p>
          <div className="cs-history-scroll-container" id={accordion.id}>
            <Accordion accordionBodies={accBodiesArray} />
          </div>
        </div>
      } 
    </>
  } else {
    mainBodyContent = <>
      <div className="cs-ns-current-container">
        <div className="cs-current-header">
          <h2 className="detail-header">There are currently no Credit Scores to display.</h2>
        </div>
        <p>Please click the update button or <a href={"mailto:support@periculum.io"} 
          className='link-color'>contact us</a> for more information.</p>
        {isUpdatable && user.user.permissions.generateScorecard === true && 
          <button className="button-square button-solid" onClick={update} >Update</button>}
      </div>
    </>
  }

  return { mainBodyContent }
}