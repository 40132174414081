import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Modal from 'react-modal';
import Utils from '../../../library/Utils';
import { InputType } from '../../../library/Variables';
import CreditConfirmBox from './CreditConfirmBox/CreditConfirm';
import './FormPage.css';

// if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
// }


function FormPage({formTitle, setPageActive, parentBackCallback, data, radioSelected, parentCallback}) {
    let inputs = null;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState('INITIAL')
    const [selectValue, setSelectValue] = useState('')
    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const cardSelected = data[radioSelected];
    
    const customStyles = {
        content: {
        transition: '0.125s ease-in-out',
        transform: 'scale(1)',
        height: '33%',
        width: 'unset',
        top: '33%',
        left: '33%',
        padding: '0 0 0 0',
        overflowY: 'scroll'
        },
        overlay: {
        zIndex: 1000,
        position: 'absolute'
        }
    };

    if(!Utils.isFieldEmpty(cardSelected.customParameters)) {
        inputs = cardSelected.customParameters.map((parameter, i) => {
          let field = null;
          
          
          if(parameter.displayType.toUpperCase() === InputType.SELECT) {
            field = <select {...register(parameter.name)} >
              {parameter.options.map((option, j) => {
                return <option key={j} value={option}>{option}</option>
              })}
            </select>      
          } else {
            field = <input type={parameter.displayType} {...register(parameter.name)} />
          }
        
    
          return <div className={parameter.isComputed? "emptyField": "custom-credit-score-input-field"} key={parameter.displayType + (i + 1) }>
            <label className="input-labels text-darker">{Utils.separateText(parameter.name)}</label>
            {field}
            <span className="error-msg">{errors[parameter.name]?.message}</span>
          </div>
        
        })

        // let idItem = <div className="custom-credit-score-input-field" key={"TEXT" + 0}>
        //   <label className="input-labels text-darker">{Utils.separateText("Customer ID")}</label>
        //   <input type={"TEXT"} {...register("CustomerId", {required: "Customer ID is required"})} />
        //   <span className="error-msg">{errors["Customer ID"]?.message}</span>
        // </div>
        let idItem = <div className= "custom-credit-score-input-field" key={"TEXT" + 0}>
          <label className="input-labels text-darker">{Utils.separateText("Customer ID")} <span className='required-info'>*</span>  </label>
          <input type={"TEXT"} id='customer-id' {...register("Customer ID", {required: "Customer ID is required"})} />
          <span className="error-msg">{errors["Customer ID"]?.message}</span>
        </div>

        inputs.unshift(idItem)
    }

    function afterOpenModal(){}

    function closeModal(){
        setIsOpen(false);
    } 


    const onSubmit = (formData) => {
        let data = {"CustomScorecardFields":[]};
        
        for(const property in formData) {
          
          if(property !== "chargeAccept") {
           
            if(property ===  "Customer ID" ){
              
              data[property] = formData[property];
            }

            else{
              const fielddata = {"fieldname":property,"fieldvalue":formData[property],"isComputed": formData[property]===''?true:false}
              
              data["CustomScorecardFields"].unshift(fielddata)
            }
            //data[property] = formData[property];
          }   
          
        }

        

        data.CustomerId = document.getElementById("customer-id").value;
        parentCallback(data);
      }

  return (
    <>
        <Modal isOpen={modalIsOpen} style={customStyles} onAfterOpen={afterOpenModal} onRequestClose={closeModal} contentLabel="Add Statement">
            {/* <AddPdf /> */}
            <CreditConfirmBox  close={closeModal} typeStatus={confirmStatus}
                initialStateTitle={"Generate New Affordability"} failTitle={"Credit Score Update Failed"}
                initialStateMessage={"This action will incur a charge to your account. Are you sure you wish to proceed?"}
                fetchingTitle={"Generating Score"} successTitle={"Affordability Generated"}
                successMessage={"Credit Score Generated"}
                setConfirmStatus={setConfirmStatus}
                setPageActive={setPageActive}
            />
            {/* <h2>hrlooooo</h2> */}
        </Modal>
    
        <div className='de-sub-page-wrap'>
            <div className='subpage-header'>
                <div>
                    <h3>{cardSelected.scorecardDefinitionName}</h3>
                    <p>Add variables</p>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-wrap'>
                    <div className='inner-form-wrap'>
                        <div className='score-card-form-inputs-container'>
                        {inputs}
                    </div>

                    <div className='form-footer'>
                        <label className="custom-credit-accept-check">
                            <input type="checkbox" id="custom-credit-charge-input" {...register("chargeAccept", 
                            { required: "You must agree to the charge before generating a report" })} />
                            <p>By checking this box, you are agreeing to the call fee</p>
                        </label>
                        <span className="error-msg">{errors.chargeAccept?.message}</span>
                    </div>
                </div>
            </div>

            <div className='de-footer-btns-wrap'>
                <button className="button-square button-outline" onClick={parentBackCallback}>
                    Cancel
                </button>
                
                <button  className="button-square button-solid" type='submit'>
                    Generate Score
                </button>
            </div>
            </form>

        </div>


    </>
  )
}

export default FormPage