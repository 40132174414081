export const monthlyRepaymentReShape = (data)=>{
    const historyObj = {title:"Name History",data:[]}

    for (let i =0; i<data.length; i++){
        
        let newArr = []
        newArr.push(data[i].subscriberName)
        newArr.push(data[i].accountNo)
        newArr.push(data[i].company)
        newArr.push(data[i].dateAccountOpened)
        newArr.push(data[i].closedDate)

        historyObj.data.push(newArr)
    }

    return historyObj

}