import React,{useEffect, useState} from 'react'
import idVerifyIcon from '../../assests/icons/IdVerificationColored.png'
import './IdVerification.css'
import Tabs from '../../components/Tabs/Tabs';
import KycContentSection from './VerificationContent/KycContent/KycContentSection';
import KybContentSection from './VerificationContent/KybContent/KybContentSection';
import { useParams } from 'react-router';
import AmlContentSection from './VerificationContent/AmlContent/AmlContentSection';
import AmlBusProceed from '../IdVerification/VerificationContent/AmlContent/AmlComponents/AmlBusinessProceed';
import AmlProceed from '../IdVerification/VerificationContent/AmlContent/AmlComponents/AmlProceed';

function IdVerificationKenya(props) {
  const { key } = useParams();
  const accessToken = props.accessToken
  const configuration = props.configuration
  let tabBodiesArray = [];
  const [contentToShow,setContentToShow] = useState('main')
  const [mainContent,setMainContent] = useState('')

  tabBodiesArray.push({
    title: "KYC Verification ",
    content: <KycContentSection accessToken={accessToken} 
                                keyFromRoute={key}
                                configuration ={configuration}
                                />
  });

  tabBodiesArray.push({
    title: "KYB Verification ",
    content: <KybContentSection accessToken={accessToken} 
                                keyFromRoute={key}
                                configuration={configuration}
                                />
  });

  tabBodiesArray.push({
    title: "AML",
    content: <AmlContentSection accessToken={accessToken} keyFromRoute={key} setContentToShow={setContentToShow}/>
  });
/**
 *   tabBodiesArray.push({
    title: "KYB Verification",
    content: null
  });
  tabBodiesArray.push({
    title: "AML",
    content: null
  });
  tabBodiesArray.push({
    title: "Document Verification",
    content: null
  });
 */
useEffect(()=>{
    if(contentToShow==='main'){
     const content = <Tabs tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
      setMainContent(content)
    }

    else if(contentToShow==='amlDetails'){
      const Content = <AmlProceed accessToken={accessToken} setContentToShow={setContentToShow}/>
      setMainContent(Content)
    }

    else if(contentToShow==='amlBusDetails'){
      const Content = <AmlBusProceed accessToken={accessToken} setContentToShow={setContentToShow}/>
      setMainContent(Content)
    }
},[contentToShow])

  
  return (
    
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section className='idVerifyHead'>
            <section className='idVerifyContent'>
                <span><img src={idVerifyIcon}/></span>
                <p>Identity Verification</p>
            </section>
        </section>
        <section style={{marginTop:'30px'}}>
          {mainContent}
        </section>
    </div>
  )
}

export default IdVerificationKenya