import "./Statement.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useSelector,
  useDispatch
} from "react-redux";
import {
  selectStatementDetailsState,
  getStatementDetails
} from "../../redux/Slices/StatementDetails";
import Spinner from "../../svg/Spinner/Spinner";
import Utils from "../../library/Utils";
import Analytics from "./Analytics/Analytics";
import Affordability from "./Affordability/Affordability";
import Bureau from "./Bureau/Bureau";
import CreditScore from "./CreditScore/CreditScore";
import Transactions from "./Transactions/Transactions";
import Kyc from "./KYC/Kyc";
import Tabs from "../../components/Tabs/Tabs";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import Button from "../../components/Button/Button";
import DownloadPdf from "../../components/Modals/DownloadPdf";
import AddLinkedPdf from "../../components/Modals/AddLinkedPdf";
import AddLinkedPdfNg from "../../components/Modals/AddLinkedPdfNg";
import Modal from "react-modal";
import { resetDownload } from "../../redux/Slices/DownloadPdf";
import { FetchDataStatus, StatementStatus } from "../../library/Variables";
import CustomCreditScore from "./CreditScore/CustomCreditScore";
import NotEnabled from "./NotEnabled/NotEnabled";
import KycKenya from "./KYC/KycKenya";
import KycTour from "../../components/KycTour/KycTour";
import StartKycTour from "../../components/KycTour/Starter/StartKycTour";
import { CountryCodes } from "../../library/CountryCodes";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

function Statement(props) {
  let tabBodiesArray = [];
  let content = <div className="st-details-body-spinner"><Spinner /></div>;
  let subtitle;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key } = useParams();
  const accessToken = props.accessToken;
  const user = props.user;
  const statement = useSelector(selectStatementDetailsState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [addPdfModalIsOpen, setAddPdfModalIsOpen] = useState(false);
  const [addPdfModalIsOpenNg, setAddPdfModalIsOpenNg] = useState(false);
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [kycTourPrompt,setKycTourPrompt] = useState(true)

  const configuration = props.configuration;
  
  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  localStorage.setItem("insightsLastVisited", `/statement/${key}`);

  const params = {
    accessToken: accessToken,
    keyFromRoute: key
  };


  function closePdfModal() {
    setAddPdfModalIsOpen(false);
  }

  function closePdfModalNg(){
    setAddPdfModalIsOpenNg(false)
  }

  function downloadReport() {
      setDownloadModalIsOpen(true);
  }

  function closeDownloadModal() {
    setDownloadModalIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    dispatch(resetDownload());
  }

  useEffect(() => {
    
    if (Utils.isFieldEmpty(statement) || statement.statementDetailsStatus === FetchDataStatus.INITIAL) {
      dispatch(getStatementDetails(params));
    }

    if (statement.statementDetailsStatus === FetchDataStatus.FAILURE &&
      (statement.activeStatement.processingStatus !== StatementStatus.FAILED
        && statement.activeStatement.processingStatus !== StatementStatus.NOTENOUGHTRANSACTIONS
        && statement.activeStatement.processingStatus !== StatementStatus.LESSTHAN6MONTHS
        && statement.activeStatement.processingStatus !== StatementStatus.UPLOADED)) {
      navigate("/error");
    }
  }, [statement, dispatch, navigate])


 

  /* Prepare Tabs */
  if ((statement.activeStatement !== null && statement.activeStatement !== undefined)
    && statement.statementDetailsStatus === FetchDataStatus.SUCCESS) {
    let analytics = <Analytics statement={statement} configuration={configuration} />;
    let affordability = <NotEnabled name={"Affordability"} message={"to set up affordability"} />;
    let bureau = null;
    let scoreCard = <NotEnabled name={"Scorecard"} message={"to set up your credit scorecard."} />;
    let transactions = null;
    let kyc_verification = null;

    tabBodiesArray.push({
      title: "Analytics",
      content: analytics
    });

    if (configuration.featureFlags && configuration.featureFlags.isAffordabilityEnabled === true) {
      affordability = <Affordability analytics={statement} configuration={configuration} accessToken={accessToken} keyFromRoute={key} />
    }

    if (user.user.permissions.checkAffordability === true) {
      tabBodiesArray.push({
        title: "Affordability",
        content: affordability
      });
    }

    if (configuration.featureFlags && configuration.featureFlags.isBureauDataEnabled === true
      && user.user.permissions.checkBureauData === true) {
      bureau = <Bureau accessToken={accessToken} configuration={configuration} keyFromRoute={key} />

      if (!Utils.isFieldEmpty(bureau)) {
        tabBodiesArray.push({
          title: "Bureau Data",
          content: bureau
        });
      }
    }

    if (configuration.featureFlags.isV2ScorecardEnabled === true && configuration.featureFlags.isV1ScorecardEnabled === false) {
      scoreCard = <CustomCreditScore configuration={configuration} accessToken={accessToken} keyFromRoute={key} user={user} />
    } else if (configuration.featureFlags.isV1ScorecardEnabled === true && configuration.featureFlags.isV2ScorecardEnabled === false) {
      scoreCard = <CreditScore configuration={configuration} accessToken={accessToken} keyFromRoute={key} user={user} />
    }

    if (user.user.permissions.generateScorecard === true || user.user.permissions.checkScorecard === true) {
      tabBodiesArray.push({
        title: "Score Card",
        content: scoreCard
      });
    }

    if (configuration.featureFlags && configuration.featureFlags.isTransactionsTabEnabled === true) {
      transactions = <Transactions accessToken={accessToken} configuration={configuration} keyFromRoute={key} />

      tabBodiesArray.push({
        title: "Transactions",
        content: transactions
      });
    }

    if (configuration.featureFlags && configuration.featureFlags.isKycEnabled) {

    {configuration.countryCode === CountryCodes.NIGERIA?  
    kyc_verification = <Kyc accessToken={accessToken} 
                            keyFromRoute={key}
                            configuration={configuration}
                        />:
        
    kyc_verification = <KycKenya accessToken={accessToken} 
                                 keyFromRoute={key} 
                                 configuration={configuration}/>

       
        
    }
        
    
 
        tabBodiesArray.push({
          title: "KYC Verification",
          content: kyc_verification
        });
      

    }

   /**
    *  if (configuration.featureFlags && !configuration.featureFlags.isKycEnabled) {
 
      if(kycTourPrompt===false){
        kyc_verification = <KycTour setKycTourPrompt={setKycTourPrompt}/>
      }

      if(kycTourPrompt===true){
        kyc_verification =<StartKycTour setKycTourPrompt={setKycTourPrompt}/>
      }

        tabBodiesArray.push({
          title: "KYC Verification",
          content: kyc_verification
        });
     

    }
    */

    

  }

  if (statement.isLoading === false) {
    let button = null;
    let mainContent = null;

    if (statement.activeStatement.processingStatus !== StatementStatus.FAILED
      && statement.activeStatement.processingStatus !== StatementStatus.NOTENOUGHTRANSACTIONS
      && statement.activeStatement.processingStatus !== StatementStatus.LESSTHAN6MONTHS
      && statement.activeStatement.processingStatus !== StatementStatus.ALTERED
      && statement.activeStatement.processingStatus !== StatementStatus.UPLOADED) {

      if (user.user.permissions.downloadReport === true) {
        button = <div className="st-report-buttons-container">
          <Button name={"Download Report"} className="button-link" iconSrc="/assets/icons/download-solid.svg"
            clickFunction={() => downloadReport()} />
        </div>
      }

      mainContent = <Tabs setKycTourPrompt={setKycTourPrompt} tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
    } else if (statement.activeStatement.processingStatus === StatementStatus.FAILED) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>There was an error during file processing. Periculum will work to resolve this issue.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    } else if (statement.activeStatement.processingStatus === StatementStatus.NOTENOUGHTRANSACTIONS) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>This statement does not have enough transactions needed for processing.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    } else if (statement.activeStatement.processingStatus === StatementStatus.LESSTHAN6MONTHS) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>This statement does not have up to 6 months transactions needed for processing.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    }else if (statement.activeStatement.processingStatus === StatementStatus.ALTERED) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>Periculum detected that the content of this statement may have been altered</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a> for more details.</p>
          </>} />
      </div>
    } else {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Processing File"}
          headerExtra={<img src="/assets/icons/arrows-rotate-color.svg" alt="" />}
          detailContent={<p>This statement has not been fully processed. Try viewing it again at a later time.</p>} />
      </div>
    }

    content = <div className="st-details-body">
      <Modal isOpen={downloadModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeDownloadModal} contentLabel="Download Report">
        <DownloadPdf accessToken={accessToken} statementKey={key} statementName={statement.activeStatement.name}
          configuration={configuration} close={closeDownloadModal} />
      </Modal>
      <Modal isOpen={addPdfModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closePdfModal} style={customStyles}
        contentLabel="Add Statement" >
        <AddLinkedPdf configuration={configuration} accessToken={accessToken} statementKey={key}  subtitle={subtitle} close={closePdfModal} />
      </Modal>

      <Modal isOpen={addPdfModalIsOpenNg} onAfterOpen={afterOpenModal} onRequestClose={closePdfModalNg} style={customStyles}
        contentLabel="Add Statement" >
        <AddLinkedPdfNg configuration={configuration} accessToken={accessToken} statementKey={key} userId='' subtitle={subtitle} close={closePdfModalNg} />
      </Modal>
      <div className="st-top-buttons">
        <Button name={"Back"} className="button-link back-button detail-header-dark" iconSrc="/assets/icons/left-square-solid.svg"
          clickFunction={() => navigate(-1)} />
        {button}
      </div>
      <div className="st-details-main-header-container">
        <div className="st-details-main-header">
          <div className="st-details-main-header-stats-container">
            {
              button = <div className="st-report-buttons-container">
                <h2 className="detail-header">{statement.activeStatement.name}</h2>
                {(configuration.featureFlags.isConsolidatedStatementEnabled && configuration.CountryCodes === CountryCodes.KENYA && statement.activeStatement.processingStatus!=='FAILED') && <Button name={"Link another statement"} className="button-link link-statement" clickFunction={() => setAddPdfModalIsOpen(true)} iconSrc="/assets/icons/link-new.svg" />}
                {(configuration.featureFlags.isConsolidatedStatementEnabled && configuration.CountryCodes === CountryCodes.NIGERIA && statement.activeStatement.processingStatus!=='FAILED') && <Button name={"Link another statement"} className="button-link link-statement" clickFunction={() => setAddPdfModalIsOpenNg(true)} iconSrc="/assets/icons/link-new.svg" />}
              </div>
            }
          </div>
          <div className="st-details-main-header-stats-container">
            <div className="st-details-main-header-sub">
              <img src="/assets/icons/calendar-color.svg" alt="" />
              <h3>Statement Period:</h3>
              <p>{Utils.formatLongDate(statement?.activeStatement?.cashFlowAnalysis?.firstDateInStatement) + " - " +
                Utils.formatLongDate(statement?.activeStatement?.cashFlowAnalysis?.lastDateInStatement)}
              </p>
            </div>
            <div className="st-details-main-header-sub">
              <img src="/assets/icons/calendar-color.svg" alt="" />
              <h3>PDF Created Date:</h3>
              <p>{Utils.formatLongDateAndTime(statement?.activeStatement?.pdfCreatedDate)}
              </p>
            </div>
            <div className="st-details-main-header-sub">
              <img src="/assets/icons/calendar-color.svg" alt="" />
              <h3>PDF Modified Date:</h3>
              <p>{Utils.formatLongDateAndTime(statement?.activeStatement?.pdfModifiedDate)}
              </p>
            </div>
            {!Utils.isFieldEmpty(statement?.activeStatement?.clientFullName) &&
              <div className="st-details-main-header-sub">
                <img src="/assets/icons/profile-color.svg" alt="" />
                <h3>Customer Name:</h3>
                <p>{statement?.activeStatement?.clientFullName}
                </p>
              </div>
            }
            {!Utils.isFieldEmpty(statement?.activeStatement?.uploadedBy) &&
            (user.user.role == 'ADMIN') && 
              <div className="st-details-main-header-sub">
                <img src="/assets/icons/profile-color.svg" alt="" />
                <h3>Uploaded By:</h3>
                <p>{statement?.activeStatement?.uploadedBy}
                </p>
              </div>
            }            
            {!Utils.isFieldEmpty(statement?.activeStatement?.accountId) &&
              <div className="st-details-main-header-sub">
                <img src="/assets/icons/profile-color.svg" alt="" />
                <h3>Account Number:</h3>
                <p>{statement?.activeStatement?.accountId}
                </p>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="st-main-details-container">
        <div className="st-main-details-top">
          <div className="st-main-details">
            <div className="st-main-details-image-container">
              <img src="/assets/icons/calendar-white.svg" alt="" />
            </div>
            <div className="st-main-details-text-container" >
              <label>Created</label>
              <p>{Utils.formatLongDate(statement?.activeStatement?.createdDate)}</p>
            </div>
          </div>
          <div className="st-main-details">
            <div className="st-main-details-image-container">
              <img src="/assets/icons/calendar-white.svg" alt="" />
            </div>
            <div className="st-main-details-text-container" >
              <label>Transacting Month(s)</label>
              <p>{statement?.activeStatement?.cashFlowAnalysis?.numberOfTransactingMonths}</p>
            </div>
          </div>
          <div className="st-main-details">
            <div className="st-main-details-image-container">
              <img src="/assets/icons/status-white.svg" alt="" />
            </div>
            <div className="st-main-details-text-container" >
              <label>Status</label>
              <p>{statement?.activeStatement?.processingStatus === StatementStatus.NOTENOUGHTRANSACTIONS
                ? "Not Enough Transactions"
                : Utils.capitalizeFirstLetter(statement?.activeStatement?.processingStatus)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {mainContent}
    </div>
  }

  return content;
}

export default Statement;


/**
 *      if(kycTourPrompt===false){
        kyc_verification = <KycTour setKycTourPrompt={setKycTourPrompt}/>
      }

      if(kycTourPrompt===true){
        kyc_verification =<StartKycTour setKycTourPrompt={setKycTourPrompt}/>
      }

      if (user.user.permissions.checkAffordability === true) {
        tabBodiesArray.push({
          title: "KYC Verification",
          content: kyc_verification
        });
      }

 */