import React from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { resetDocFileUpload,selectSupportDocs } from '../../../redux/Slices/SupportDocs';
import closeModalFile from '../../../assests/icons/closeModalFile.png'

function ViewSupportModal(props) {
    const dispatch = useDispatch()
    const supportState = useSelector(selectSupportDocs)
    const accessToken = props.accessToken
    const uploadError = props.uploadError
    const imgFile = props.imgFile
    const setUploadError = props.setUploadError
    const setShowForm = props.setShowForm
    const lastThreeChars = props.lastThreeChars

    let content = null;
    const params = {
        files:props.imgFile,
        type:'support',
        accessToken,
    }

  

    if( supportState.supportDocFileStatus===PostDataStatus.INITIAL){
        content = <div style={{display:"flex",width:lastThreeChars==='pdf'?'90vh':'auto',flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Fetching...</p>
                    <Spinner/>
            </div>
    }

    if(supportState.supportDocFileStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",
                    justifyContent:"space-between",width:lastThreeChars==='pdf'?'90vh':'auto'}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Fetching...</p>
                    <Spinner/>
            </div>
            
    }

    if(supportState.supportDocFileStatus===PostDataStatus.SUCCESS){
       
        let signatureExtension  = lastThreeChars==='png'?'image/png':lastThreeChars==='pdf'?'application/pdf':'image/jpeg'

        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between",height:'100%'}}>
            <section style={{display:'flex',alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <p style={{textAlign:"center",fontSize:'16px'}}>View File</p>
                <span onClick={()=>{props.close();dispatch(resetDocFileUpload())}} style={{display:"flex",cursor:"pointer",alignItems:"center",justifyContent:"center"}}>
                    <img src={closeModalFile}/>
                </span>
            </section>
            <div style={{width:lastThreeChars==='pdf'?'100%':'40%',height:'100%',margin:'0px auto',display:"flex",
                        alignItems:"center",justifyContent:"Center"}}>
            {
                signatureExtension==='application/pdf'?
                <embed
                    type="application/pdf"
                    title="PDF Viewer"
                    style={{width:'80vw',height:"100%",margin:'auto'}}
                    src={`data:${signatureExtension};base64,${supportState.supportDocFileData.data}`}
                />
               
                :
                <img style={{width:'400px',objectFit:'contain'}} src={`data:${signatureExtension};base64,${supportState.supportDocFileData.data}`}/>
            }</div>
        </div>
    }

    if(supportState.supportDocFileStatus===PostDataStatus.FAILURE){
        content = <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around"
        ,backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        ,margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{supportState.supportDocErrorMessage}</p>

          <div className='buttonHold'>   
                <section onClick={()=>{props.close();dispatch(resetDocFileUpload())}}>close</section>
            </div> 
    </div>
    }

  return (
    <div style={{height:lastThreeChars==='pdf'?'90vh':'auto',backgroundColor:'white',display:"flex",justifyContent:"center",
                alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",
                boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default ViewSupportModal