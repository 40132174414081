import React from 'react';
import './Tabs.css';

function TabBody(props) {
  let items = props.headerItems;
  let listHeaderItems = null;
  const showForm = props?.showForm

  if(items !== null && items !== undefined && items !== '') {
    listHeaderItems = items.map((item, i) => {
      return(
        <div className="tab-body-header" key={item.fieldLabel + i}>
          <h3>{item.fieldLabel}:</h3>
          <p>{item.fieldValue}</p>
        </div>
      )} 
    );
  }
  
  return (
    <div className={showForm?'tab-body-form':'tab-body-no-form'}>
      {items && <div className="tab-body-header-container">{listHeaderItems}</div>}
      {props.content}
    </div>
  )
}

export default TabBody;