import {useState,useEffect} from 'react'
import Utils from "../../../../library/Utils";
import DetailsCard from "../../../../components/DetailsCard/DetailsCard";
import List from "../../../../components/List/List";
import LoanPerformance from './PremiumFolder/LoanPerformance';
import LoanHistory from './PremiumFolder/LoanHistory';
import "./BureauReport.css";

function BureauReportCentralPremium(props) {
  const configuration = props.configuration;
  const fieldData = props.report;
  const contentTwoHeadsFull = ['Loan Performance','Loan History']
  const [tabSelectedFull,setTabSelectedFull] = useState('Loan Performance')

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])

  return (
    <div className="bureau-report-main">
        <div className="bureau-report-view-col">
            <DetailsCard title="Customer Profile" titleClass="detail-header" bodyExtraClass="details-bottom"
            detailContent={
                <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"}
                listContent={[
                    { header: "Full Name", detail: Utils.checkNull(fieldData.fullName)},
                    { header: "BVN", detail: fieldData.bvn },
                    { header: "Business ID", detail: Utils.formatRegionNames(Utils.checkNull(fieldData.businessId))},
                    { header: "Date of Birth", detail: fieldData.dateOfBirth},
                    { header: "Gender", detail: fieldData.gender},
                    { header: "Address", detail: fieldData.address}
                ]} />
            } 
            />

        </div>

        <div className="bureau-report-view-col">
            <DetailsCard title="Score" titleClass="detail-header" bodyExtraClass="details-bottom"
            detailContent={
                <List listClass={"st-details-list"} listContainerClass={"st-details-list-main-container"}
                listContent={[
                    { header: "Number of Delinquent Facilities", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfDelinquentFacilities)},
                    { header: "Total Number of Loans", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfDelinquentFacilities) },
                    { header: "Active Loans", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfActiveLoans) },
                    { header: "Closed Loans", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfClosedLoans) },
                    { header: "Maximum Number of Days", detail: Utils.checkNull(fieldData.data[0]?.maxNoOfDays) },
                    { header: "Total Overdue", detail: Utils.checkNull(fieldData.data[0]?.totalOverdue) },
                    { header: "Highest Loan Amount", detail: Utils.checkNull(fieldData.data[0]?.highestLoanAmount) },
                    { header: "Total Outstanding", detail: Utils.checkNull(fieldData.data[0]?.totalOutstanding) },
                    { header: "Total Number of Institutions", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfInstitutions) },
                    { header: "Total Monthly Installment", detail: Utils.checkNull(fieldData.data[0]?.totalMonthlyInstallment) },
                    { header: "Total Number of Overdue Accounts", detail: Utils.checkNull(fieldData.data[0]?.totalOverdue) },
                    { header: "Total Number of Performing Loans", detail: Utils.checkNull(fieldData.data[0]?.totalNoOfPerformingLoans) },
                ]} />
            } 
            />

        </div>

        <div className='contentPremiumTabs'>
            { contentTwoHeadsFull.map((element,index)=>
                      <div 
                           onClick={()=>setTabSelectedFull(element)}
                           className={tabSelectedFull===element?'selecTed':'unselecTed'}>
                        {element}
                      </div>)
            }
        </div>
        {tabSelectedFull==='Loan Performance'?<LoanPerformance performanceData={fieldData.data[0]?.loanPerformance}/>
        
        :<LoanHistory performanceData={fieldData.data[0]?.loanHistory}/>}
    </div>
  )
}

export default BureauReportCentralPremium