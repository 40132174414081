import React from 'react'
import SerialOutPutPrint from './SerialOutPutPrint';
import Utils from '../../../../../library/Utils';

function NonPerformingAccounts({data}) {
  const AllKeys = Object.keys(data)

  const filteredKeys = AllKeys.filter(str => str.includes('npa'));

  return (
    <>
      {!Utils.isFieldEmpty(filteredKeys) && <SerialOutPutPrint keys={filteredKeys} data={data} header={'Non-Performing Accounts (NPA)'}/>}
    </>
    
  )
}

export default NonPerformingAccounts