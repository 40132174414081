import React from "react";
import Utils from "../../../../library/Utils";
import { useForm } from "react-hook-form";
import AccountsFieldset from "./AccountsFieldset";
import "./BureauForm.css";

function BureauForm(props) {
  const defaultValues = {
    statementBureauAccountData: []
  }
  
  const { register, control, reset, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ defaultValues });
  const keyFromRoute = props.keyFromRoute;
  const accessToken = props.accessToken;

  const onSubmit = (formData) => {
    if(Utils.isFieldEmpty(formData.statementBureauAccountData)) {
      formData.statementBureauAccountData = [];
    } else {
      formData.statementBureauAccountData.forEach(element => {
        if(Utils.isFieldEmpty(element.statementBureauAccountPaymentHistory)) {
          element.statementBureauAccountPaymentHistory = [];
        }
        if(Utils.isFieldEmpty(element.statementBureauAccountAddressHistory)) {
          element.statementBureauAccountAddressHistory = [];
        }
        if(Utils.isFieldEmpty(element.statementBureauAccountContactHistory)) {
          element.statementBureauAccountContactHistory = [];
        }
      });
    }

    formData.statementKey = keyFromRoute;

    const formParams = {
      data: formData,
      accessToken: accessToken
    };

    props.submitCallbackFunction(formParams);
  }

  const cancelForm = () => {
    reset(); 
    props.callbackFunction()
  }
  
  return (
    <div className="bureau-form-main">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="cards bureau-form-section">
          <h3 className="detail-header">Customer Profile</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>First Name</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("firstName", 
                { 
                  required: "First Name is required", 
                  pattern: {
                    value: /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/,
                    message: "Invalid first name"
                  } 
                })} />
              <span className="bureau-form-errors error-msg">{errors.firstName?.message}</span>
            </div>
            <div className="bureau-input-container">
              <label>Last Name</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("lastName",
                { required: "Last Name is required", 
                  pattern: {
                    value: /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/,
                    message: "Invalid last name"
                  } 
                })} />
              <span className="bureau-form-errors error-msg">{errors.lastName?.message}</span>
            </div>
            <div className="bureau-input-container">
              <label>BVN</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("bvn")} />
            </div>
            <div className="bureau-input-container">
              <label>Citizenship</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("citizenship")} />
            </div>
            <div className="bureau-input-container">
              <label>Date of Birth</label>
              <input className="inputs bureau-form-inputs" type={'date'} {...register("dateOfBirth")} />
            </div>
          </div>
        </section>
        <section className="cards bureau-form-section">
          <h3 className="detail-header">Report Info</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Product Name</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("productName")} />
            </div>
            <div className="bureau-input-container">
              <label>Report Date</label>
              <input className="inputs bureau-form-inputs" type={'date'} {...register("reportDate")} />
            </div>
          </div>
        </section>
        <section className="cards bureau-form-section">
          <h3 className="detail-header">Credit</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Has Credit Facilities</label>
              <select id="creditHasCreditFacilities" className="inputs bureau-form-inputs" {...register("creditHasCreditFacilities")}>
                <option value="">Select an option</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>
            </div>
            <div className="bureau-input-container">
              <label>No. of Delinquent Facilities</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("creditNumberOfDelinqCreditFacilities")} />
            </div>
            <div className="bureau-input-container">
              <label>Last Reported Date</label>
              <input className="inputs bureau-form-inputs" type={'date'} {...register("creditLastReportedDate")} />
            </div>
          </div>
        </section>
        <section className="cards bureau-form-section">
          <h3 className="detail-header">MF Credit</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Has Credit Facilities</label>
              <select id="mfCreditHasCreditFacilities" className="inputs bureau-form-inputs" 
                {...register("mfCreditHasCreditFacilities")}>
                <option value="">Select an option</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>
            </div>
            <div className="bureau-input-container">
              <label>No. of Delinquent Facilities</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("mfCreditNumberOfDelinqCreditFacilities")} />
            </div>
          </div>
        </section>
        <section className="cards bureau-form-section">
          <h3 className="detail-header">MG Credit</h3>
          <div className="bureau-form-section-inputs">
            <div className="bureau-input-container">
              <label>Has Credit Facilities</label>
              <select id="mgcreditHasCreditFacilities" className="inputs bureau-form-inputs" 
                {...register("mgcreditHasCreditFacilities")}>
                <option value="">Select an option</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>
            </div>
            <div className="bureau-input-container">
              <label>No. of Delinquent Facilities</label>
              <input className="inputs bureau-form-inputs" type={'text'} {...register("mgCreditNumberOfDelinqCreditFacilities")}/>
            </div>
          </div>
        </section>
        <section className="cards bureau-form-section">
          <AccountsFieldset {...{ control, register, defaultValues, getValues, setValue, errors }} />
        </section>
        <section className="bureau-form-section">
          <div className="bureau-form-buttons">
            <button type="button" className="button-square button-outline" onClick={() => cancelForm()}>Cancel</button>
            <button type="submit" className="button-square button-solid">Submit</button>
          </div>
        </section>
      </form>
    </div>
  )
}

export default BureauForm;