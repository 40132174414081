import React from 'react'
import './kycreport.css'
import { resetReport } from '../../../../redux/Slices/KycOptions';
import { selectKycEntireDataState } from '../../../../redux/Slices/KycOptions';
import { useSelector } from 'react-redux';
import Spinner from '../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../library/Variables';
import Utils from '../../../../library/Utils' 

function ExtractionReport(props) {
  let kycState = useSelector(selectKycEntireDataState)
  const mimeType = 'data:image/jpeg;base64,'
  
    let content

    if(kycState.reportStatus === FetchDataStatus.FETCHING){
      content = <Spinner/>
    }

    if(kycState.reportStatus === FetchDataStatus.SUCCESS){
      let userReport = {...kycState.reportData}
      let userImg = null;
      let userSignature = null;

      if(!Utils.isFieldEmpty(kycState.reportData?.base64Image)){
        userImg = mimeType + kycState.reportData?.base64Image
      }
      if(!Utils.isFieldEmpty(kycState.reportData?.signaturee)){
        userSignature = mimeType + kycState.reportData?.signature
      }
      
    

      delete userReport?.base64Image;
      delete userReport?.photo;
      delete userReport?.signature;
      
    let reportKeys = Object.keys(userReport);
    reportKeys = reportKeys.filter(element=>element!=='uuid')
      content = 
          <>
            <div className='roof'>
              <span 
                  className={props.viewReport?'span-view-report':'span-view-history'} 
                  onClick={()=>{props.setViewReport(false)}}>
                  History
              </span>
              <span>/</span>
              <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
            </div>

            <p className='title-class'>{`${props.verificationType} Verification`}</p>
            <p style={{margin:'10px 0px'}}>{`Here's the report from the ${props.verificationType} identity check`}</p>

            <div className='report-contentKYC'>
              <div className='report-fields'>
                  {reportKeys.map(key=>
                  (!Utils.isFieldEmpty(userReport[key]) && <div className='keyValue-holder' key={reportKeys.indexOf(key)}>
                    <p className='key'>{key}</p>
                    <p className='value'>{userReport[key]}</p>
                  </div>))}
              </div>

              {userImg?<div className='img-container'>
                  <p className='photo'>Photo</p>
                  <div className='imgKycBox'>
                    <img src={userImg} style={{width:'auto'}}/>
                  </div>
                
              </div>: ''
              }
            </div>
          </>
    }

  if(kycState.reportStatus===FetchDataStatus.FAILURE){
    content = content = 
    <>
      <div className='roof'>
        <span 
            className={props.viewReport?'span-view-report':'span-view-history'} 
            onClick={()=>{props.setViewReport(false)}}>
            History
        </span>
        <span>/</span>
        <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
      </div>
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{kycState.reportErrorMessage?kycState.reportErrorMessage:'Try again'}</p>
      </div>
    </>
    
  }
  return (
   
    <div className='report-hold'>
      {content}
    </div>

  )
}

export default ExtractionReport

//