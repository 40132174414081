import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import idVerifyIcon from "../../assests/icons/idVerificationIcon.png";
import loanIcon from "../../assests/icons/loanIcon.png";
import bankIcon from "../../assests/icons/bankIcon.png";
import crbLogo from "../../assests/svg-icons/crbSideLogo.svg"
import EngineTour from "../EngineTour/EngineTour";
function SideBarContent(props) {
  const user = props.user;
  const configuration = props.configuration;
  const [engineTour, setEngineTour] = useState(false);
  const [tourSection, setTourSection] = useState("stepOne");
  const lastVisited = localStorage.getItem("insightsLastVisited");
  const [showLoan, setShowLoan] = useState(false);

  return (
    <>
      {engineTour && !configuration.featureFlags?.isDecisionEngineEnabled && (
        <EngineTour
          tourSection={tourSection}
          setTourSection={setTourSection}
          setEngineTour={setEngineTour}
        />
      )}
      <div className="logo-container">
        <Link to="/home" className="logo-link">
          <img
            src="/assets/logo/logo_full.svg"
            alt="Insights"
            className="insights-logo"
          />
        </Link>
      </div>
      <div className="sidebar-links">
        <NavLink
          end
          to="/home"
          className="sidebar-link-row"
          onClick={() => props.clearHistory()}
        >
          <img src="/assets/icons/home.svg" alt="" />
          <p>Home</p>
        </NavLink>

        {configuration?.featureFlags?.isKycEnabled && (
          <NavLink end to="/IdentityVerification" className="sidebar-link-row">
            <img src={idVerifyIcon} alt="" />
            <p>ID Verification</p>
          </NavLink>
        )}

        {configuration?.featureFlags?.isBureauDataEnabled
           && (
          <NavLink end to="/creditBureau" className="sidebar-link-row">
            <img src={crbLogo} alt="" />
            <p>Credit Bureau</p>
          </NavLink>
        )}
      </div>
      <div className="sidebar-links">
        {configuration?.featureFlags?.isDecisionEngineEnabled && (
          <NavLink end to="/decisionengine" className="sidebar-link-row">
            <img src="/assets/icons/scan-white.svg" alt="" />
            <p>Decision Engine</p>
          </NavLink>
        )}
      </div>
      <div className="sidebar-links">
        {!configuration?.featureFlags?.isDecisionEngineEnabled && (
          <section
            className="sidebar-link-row"
            onClick={() => setEngineTour(true)}
          >
            <img src="/assets/icons/scan-white.svg" alt="" />
            <p>Decision Engine</p>
          </section>
        )}
      </div>
      <div className="sidebar-links">
        {configuration?.featureFlags?.isLoanApplicationEnabled ? (
          <NavLink
            end
            to="/loanApproval"
            onClick={() => props.loanCall()}
            className="sidebar-link-row"
          >
            <img src={loanIcon} alt="" />
            <p>Loans</p>
          </NavLink>
        ) : (
          ""
        )}
      </div>
      <div className="sidebar-links">
        {configuration?.featureFlags?.isFraudMonitoringEnabled && (
          <NavLink end to="/fraud-detect" className="sidebar-link-row">
            <img src="/assets/icons/gps.svg" alt="" />
            <p>Fraud Detect</p>
          </NavLink>
        )}
      </div>
      <div className="sidebar-links">
        {configuration?.featureFlags?.isAgencyBankingEnabled && (
          <NavLink end to="/agencyBanking" className="sidebar-link-row">
            <img src={bankIcon} alt="" />
            <p>Agency Banking</p>
          </NavLink>
        )}
      </div>
      <div className="sidebar-links">
        <NavLink end to="/wallet" className="sidebar-link-row">
          <img src="/assets/icons/wallet.svg" alt="" />
          <p>Wallet</p>
        </NavLink>
      </div>
      <div className="sidebar-links">
       
          <NavLink
            end
            to="/settings"
            className="sidebar-link-row"
            onClick={() => props.clearHistory()}
          >
            <img src="/assets/icons/setting.svg" alt="" />
            <p>Settings</p>
          </NavLink>
        
      </div>
      <div className="sidebar-links">
        <div className="sidebar-link-row" onClick={() => props.logout()}>
          <img src="/assets/icons/logout.svg" alt="" />
          <p>Logout</p>
        </div>
      </div>
    </>
  );
}

export default SideBarContent;

//onClick={()=>setEngineTour(true)}

//configuration.featureFlags?.isDecisionEngineEnabled?lastVisited:'/decisionengine'

/**
 * <div className="sidebar-links">
        {user.user.permissions.canViewAllSettings === true && (
          <NavLink
            end
            to="/settings"
            className="sidebar-link-row"
            onClick={() => props.clearHistory()}
          >
            <img src="/assets/icons/setting.svg" alt="" />
            <p>Settings</p>
          </NavLink>
        )}
      </div>
 */
