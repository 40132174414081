import React from "react";
import Modal from "react-modal";
import "./index.css";

export const CustomDialog = ({
  open,
  toggleModal,
  children,
  title,
  showTitle = true,
  showCancelBtn = true,
  contentStyle,
}) => {
  const customStyles = {
    content: {
      ...contentStyle,
    },
  };

  return (
    <Modal
      isOpen={open}
      // onAfterOpen={toggleModal}
      onRequestClose={toggleModal}
      style={customStyles}
      className="DialogContent"
      overlayClassName="DialogOverlay"
    >
      {showTitle && <h2>{title}</h2>}

      {showCancelBtn && (
        <span className="IconButton" aria-label="Close" onClick={toggleModal}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.9896 15.9922L16.0029 23.9789"
              stroke="#6A717D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.999 23.9922L15.999 15.9922"
              stroke="#6A717D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.58301 20.0026C4.58301 31.5643 8.43801 35.4193 19.9997 35.4193C31.5613 35.4193 35.4163 31.5643 35.4163 20.0026C35.4163 8.44094 31.5613 4.58594 19.9997 4.58594C8.43801 4.58594 4.58301 8.44094 4.58301 20.0026Z"
              stroke="#6A717D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      )}

      {children}
    </Modal>
  );
};
