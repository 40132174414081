import React, { useState } from 'react';
import { CustomCreditScoreViews } from '../../../../library/Variables';
import "./CustomScore.css";

function ScoreCards(props) {
  const data = props.data;
  const [radioSelected, setRadioSelected] = useState(props.radioSelected);

  const scoreCards = data.map((element, i) => {
    return <label key={element.scorecardDefinitionKey + "-" + i} className={"score-card-container"}>
      <div className="score-card-icon-container">
        <img className="score-card-icon" src="/assets/icons/favorite-chart-white.svg" alt="" />
        <input type={"radio"} name={element.scorecardDefinitionName} value={element.scorecardDefinitionName} 
          checked={i === radioSelected ? "checked" : false} onChange={() => setRadioSelected(i)} />
      </div>
      <h2 className="text-dark">{element.scorecardDefinitionName}</h2>
      {/* <p>{element.description}</p> */}
    </label>
  })

  const customizeCard = () => {
    props.parentCallback(CustomCreditScoreViews.FORM, radioSelected);
  }

  const cancel = () => {
    props.parentCancelCallback();
  }

  return (
    <div className="custom-score-main-container">
      <div>
        <h2 className="detail-header">Pick a scorecard</h2>
        <p className="text-medium">Select a scorecard to proceed</p>
      </div>      
      <div className="score-cards-container">{scoreCards}</div>
      <div className="score-card-submit-container">
        <button className="button-outline" onClick={() => cancel()}>Cancel</button>
        <button className="button-solid" onClick={() => customizeCard()}>Next step</button>
      </div>
    </div>
  )
}

export default ScoreCards;