import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getMobileInsightDetails = createAsyncThunk('mobileinsight/details', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/overview`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const getMobileBanks = createAsyncThunk('mobileinsight/banks', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/banks`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const getMobileLenders = createAsyncThunk('mobileinsight/lenders', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/lenders`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const getBankInsights = createAsyncThunk('mobileinsight/banks/insights', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/banks/${params.senderKey}/${params.accountKey}/insights`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const getBankTransactions = createAsyncThunk('mobileinsight/banks/transactions', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/banks/${params.senderKey}/${params.accountKey}/transactions`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const getLenderSms = createAsyncThunk('mobileinsight/lenders/sms', async (params) => {
  const mobileInsight = await client.get(`/mobile/v2/${params.keyFromRoute}/lenders/rawSMS/${params.senderKey}`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return mobileInsight.data;
  }
);

export const mobileInsightSlice = createSlice({
  name: 'mobileInsight',
  initialState: {
    activeInsight: { },
    mobileInsightStatus: FetchDataStatus.INITIAL,
    banks: [],
    banksStatus: FetchDataStatus.INITIAL,
    bankInsight: { },
    bankInsightStatus: FetchDataStatus.INITIAL,
    bankTransactions: [],
    bankTransactionsStatus: FetchDataStatus.INITIAL,
    lenders: [],
    lendersStatus: FetchDataStatus.INITIAL,
    lenderSms: [],
    lenderSmsStatus: FetchDataStatus.INITIAL,
    isLoading: true,
    isError: false,
    errorMessage: null
  },
  reducers: {
    resetActiveBankAccountInsights: state => {
      state.bankInsight = { };
      state.bankInsightStatus = FetchDataStatus.INITIAL;
      state.bankTransactions = [];
      state.bankTransactionsStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetActiveLenderAccountSms: state => {
      state.lenderSms = [];
      state.lenderSmsStatus = FetchDataStatus.INITIAL;
    },
    resetAllMobileInsights: state => {
      state.activeInsight = {};
      state.mobileInsightStatus = FetchDataStatus.INITIAL;
      state.isLoading = true;
      state.banks = [];
      state.banksStatus = FetchDataStatus.INITIAL;
      state.bankInsight = { };
      state.bankInsightStatus = FetchDataStatus.INITIAL;
      state.bankTransactions = [];
      state.bankTransactionsStatus = FetchDataStatus.INITIAL;
      state.lenders = [];
      state.lendersStatus = FetchDataStatus.INITIAL;
      state.lenderSms = [];
      state.lenderSmsStatus = FetchDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getMobileInsightDetails.pending, (state) => {
      state.mobileInsightStatus = FetchDataStatus.FETCHING;
      state.isLoading = true;
    })
    .addCase(getMobileInsightDetails.fulfilled, (state, action) => {      
      state.mobileInsightStatus = FetchDataStatus.SUCCESS;
      state.isLoading = false;
      state.activeInsight = action.payload;
    })
    .addCase(getMobileInsightDetails.rejected, (state, action) => {
      state.mobileInsightStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getMobileBanks.pending, (state) => {
      state.banksStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getMobileBanks.fulfilled, (state, action) => {      
      state.banksStatus = FetchDataStatus.SUCCESS;
      state.banks = action.payload;
    })
    .addCase(getMobileBanks.rejected, (state, action) => {
      state.banksStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getBankInsights.pending, (state) => {
      state.bankInsightStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getBankInsights.fulfilled, (state, action) => {      
      state.bankInsightStatus = FetchDataStatus.SUCCESS;
      state.bankInsight = action.payload;
    })
    .addCase(getBankInsights.rejected, (state, action) => {
      state.bankInsightStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getBankTransactions.pending, (state) => {
      state.bankTransactionsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getBankTransactions.fulfilled, (state, action) => {      
      state.bankTransactionsStatus = FetchDataStatus.SUCCESS;
      state.bankTransactions = action.payload;
    })
    .addCase(getBankTransactions.rejected, (state, action) => {
      state.bankTransactionsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getMobileLenders.pending, (state) => {
      state.lendersStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getMobileLenders.fulfilled, (state, action) => {      
      state.lendersStatus = FetchDataStatus.SUCCESS;
      state.lenders = action.payload;
    })
    .addCase(getMobileLenders.rejected, (state, action) => {
      state.lendersStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getLenderSms.pending, (state) => {
      state.lenderSmsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getLenderSms.fulfilled, (state, action) => {      
      state.lenderSmsStatus = FetchDataStatus.SUCCESS;
      state.lenderSms = action.payload;
    })
    .addCase(getLenderSms.rejected, (state, action) => {
      state.lenderSmsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, mobileInsight, resetActiveBankAccountInsights, resetActiveLenderAccountSms, resetAllMobileInsights } = mobileInsightSlice.actions;

export const selectMobileInsightState = state => state.mobileInsight;

export default mobileInsightSlice.reducer;