import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const fetchRules = createAsyncThunk(
  "fraudDetect/fetchRules",
  async (params, { rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams(params).toString();
      const response = await clientApi.get(`/api/rules?${queryString}`);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const createRulesAsync = createAsyncThunk(
  "fraudDetect/createRules",
  async (data) => {
    const response = await clientApi.post(`/api/rules`, JSON.stringify(data), {
      "Content-Type": "application/json",
    });

    return response;
  }
);

export const updateRulesAsync = createAsyncThunk(
  "fraudDetect/updateRules",
  async (data) => {
    const response = await clientApi.put(
      `/api/rules`,
      JSON.stringify(data.payload),
      {
        "Content-Type": "application/json",
      }
    );

    return response;
  }
);

export const fetchRuleAsync = createAsyncThunk(
  "fraudDetect/fetchRule",
  async (params, { rejectWithValue }) => {
    try {
      const response = await clientApi.get(`/api/rules/${params.id}`);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

// custom rules

export const createCustomRulesAsync = createAsyncThunk(
  "fraudDetect/createCustomRules",
  async (data) => {
    const response = await clientApi.post(`/api/customlists`, data);

    return response;
  }
);

export const fetchCustomRuleAsync = createAsyncThunk(
  "fraudDetect/fetchCustomRules",
  async (params) => {
    const response = await clientApi.get(`/api/customlists`);

    return response;
  }
);

export const fetchCustomRuleByIdAsync = createAsyncThunk(
  "fraudDetect/fetchCustomById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await clientApi.get(`/api/customlists/${params.id}`);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRulesAsync = createAsyncThunk(
  "fraudDetect/deleteRules",
  async (params) => {
    const response = await clientApi.delete(`/api/rules/${params?.id}`);

    return response;
  }
);

export const deleteCustomRulesAsync = createAsyncThunk(
  "fraudDetect/deleteCustomRule",
  async (params) => {
    const response = await clientApi.delete(`/api/customlists/${params?.id}`);

    return response;
  }
);
