import { addSupportingDocsAsync,deleteSupportingDocsAsync } from "./thunks";

import { createSlice } from "@reduxjs/toolkit";

export const addSupportingDocSlice = createSlice({
  name: "addSupportingDocs",
  initialState: {
    data: null,
    status: "idle",
    error: null,

    deleteSupportData:null,
    deleteSupportStatus: "idle",
    deleteSupportError: null,
  },
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;

      state.deleteSupportData = null;
      state.deleteSupportStatus = "idle";
      state.deleteSupportError = null
    },

    
  },

  extraReducers: (builder) => {
    builder
      .addCase(addSupportingDocsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSupportingDocsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(addSupportingDocsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })


      .addCase(deleteSupportingDocsAsync.pending, (state) => {
        state.deleteSupportStatus = "loading";
      })
      .addCase(deleteSupportingDocsAsync.fulfilled, (state, action) => {
        state.deleteSupportStatus = "succeeded";
        state.deleteSupportData = action.payload;
      })

      .addCase(deleteSupportingDocsAsync.rejected, (state, action) => {
        state.deleteSupportStatus = "failed";
        state.deleteSupportError = action.payload;
      });
  },
});

export const { reset } = addSupportingDocSlice.actions;
