import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus } from "../../../../library/Variables";


 
  export const getAmlHistory = createAsyncThunk('AmlHistory', async (params) => {
    
    
   const amlHistoryResponse = await client.get('/verify/aml-screen/history',
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
     }
   });
  
    return amlHistoryResponse.data;
   }
  );




  export const getAmlHistoryRecord = createAsyncThunk('AmlHistory/record', async (params) => {
  
   const amlHistRecord = await client.get(`/verify/aml-screen/history/${params.historyId}`,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
     }
   });
  
    return amlHistRecord.data;
   }
  );




export const amlDataHistoryReports = createSlice({
  name: 'amlHistoryExtraction',
  initialState: {
    activeHistory: [],
    activeHistoryStatus: FetchDataStatus.INITIAL,
    activeHistoryisError: false,
    activeHistoryErrorMessage:null,


    activeHistoryRecord: [],
    activeHistoryRecordStatus: FetchDataStatus.INITIAL,
    activeHistoryRecordisError: false,
    activeHistoryRecordMessage:null,

  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetAmlHistory: (state)=>{
      state.activeHistory = [];
      state.activeHistoryStatus = FetchDataStatus.INITIAL;
      state.activeHistoryisError = false;
      state.activeHistoryErrorMessage = null;
    },


    resetAmlHistoryRecord: (state)=>{
        state.activeHistoryRecord = [];
        state.activeHistoryRecordStatus = FetchDataStatus.INITIAL;
        state.activeHistoryRecordisError = false;
        state.activeHistoryRecordMessage = null;
    }
  },
  
  extraReducers(builder) {

  builder.addCase(getAmlHistory.pending, (state)=>{
    state.activeHistoryStatus = FetchDataStatus.FETCHING
  })
  .addCase(getAmlHistory.fulfilled,(state,action)=>{
    state.activeHistoryStatus = FetchDataStatus.SUCCESS
    state.activeHistory = [...action.payload]
  })
  .addCase(getAmlHistory.rejected,(state,action)=>{
    state.activeHistoryStatus = FetchDataStatus.FAILURE
    state.activeHistoryErrorMessage = action.error.message
  })


  .addCase(getAmlHistoryRecord.pending, (state)=>{
    state.activeHistoryRecordStatus = FetchDataStatus.FETCHING
  })
  .addCase(getAmlHistoryRecord.fulfilled,(state,action)=>{
    state.activeHistoryRecordStatus = FetchDataStatus.SUCCESS
    state.activeHistoryRecord = action.payload
  })
  .addCase(getAmlHistoryRecord.rejected,(state,action)=>{
    state.activeHistoryRecordStatus = FetchDataStatus.FAILURE
    state.activeHistoryRecordMessage = action.error.message
  })


  
  }
})


export const { resetAmlHistory,resetAmlHistoryRecord} = amlDataHistoryReports.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export const selectAmlHistorydata  = state => state.amlHistoryExtraction


export default amlDataHistoryReports.reducer;