import React from 'react'
import { selectLoanState, uploadSignature, resetSignature } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { generateScore } from '../../../redux/Slices/LoanScores';
import { generateScoreBreakDown } from '../../../redux/Slices/LoanScores';
import { selectLoanScoreState } from '../../../redux/Slices/LoanScores';
import { resetLoanFileData } from '../../../redux/Slices/LoanFile';
import { getLoanFileData } from '../../../redux/Slices/LoanFile';


function ViewScoreModal(props) {
    const dispatch = useDispatch()
    const loanScoreState = useSelector(selectLoanScoreState)
    const accessToken = props.accessToken
    const cardKey = props.cardKey
    const loanKey = props.loanKey


    let content = <Spinner/>;
   

    if(loanScoreState.scoreDataStatus === PostDataStatus.FETCHING){
    
        content = <Spinner/> 
    }
  
    if(loanScoreState.scoreDataStatus === PostDataStatus.SUCCESS){

       content =  <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Uploaded</p>
            <div className='centerButton'>   
                <section className='proceed' onClick={()=>{dispatch(generateScoreBreakDown({accessToken,loanKey})); 
                                                      props.closeModal();
                                                      dispatch(resetLoanFileData());
                                                      dispatch(getLoanFileData({accessToken,key:loanKey}))}}>View Generated Score</section>
            </div> 
        </div>
   
  }

  if(loanScoreState.scoreDataStatus === PostDataStatus.FAILURE){
    content = 
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-between"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanScoreState.errorScoreData}</p>

            <div className='centerButton'>   
                <section className='proceed' onClick={()=>{props.closeModal()}}>Close</section>
            </div>
        </div>
  }

  

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default ViewScoreModal