import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PostDataStatus,CRBGhanaCheckTypes } from "../../../library/Variables";
import amlFilled from '../../../assests/icons/amlFound.png'
import Spinner from "../../../svg/Spinner/Spinner";
import "./crbModal.css";
import 'react-toastify/dist/ReactToastify.css';
import { resetAll } from "../../../redux/Slices/Bureau";
import { postCommercialCredit,
         postConsumerCredit,
         resetConsumerCreditPost,
         resetConsumerCreditGet,
         resetCommercialCreditPost,
         selectCrbGhanaData } from "../../../redux/Slices/CRB/Ghana/CrbGhana";

function GhanaCrbModal(props) {
  let content = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const statementKey = props.statementKey;
  const configuration = props.configuration;
  const [dateOfBirth,setDateOfBirth] = useState('')
  const [reportState,setReportState] = useState('')
  const field = props.field;
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const ghanaCrb = useSelector(selectCrbGhanaData);
  const [view, setView] = useState(PostDataStatus.INITIAL);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

  const resetForm = () => {
    dispatch(resetConsumerCreditPost());
    dispatch(resetCommercialCreditPost())
    reset();
  }
  
  const onSubmit = (formData) => {
    if (field.type === CRBGhanaCheckTypes.COMMERCIAL) {
      const data = {
          report_type: reportState === 'Full' ? "full" : 'basic',
          business_registration_number: formData.business_registration_number,
          statementKey: statementKey !== undefined ? statementKey : null
      }
      dispatch(postCommercialCredit({
          data,
          accessToken
      }));
      handleStateChanges(PostDataStatus.FETCHING)
      
    } else if (field.type === CRBGhanaCheckTypes.CONSUMER) {
      const data = {
          report_type: reportState === 'Full' ? "full" : 'basic',
          name: formData.name,
          date_of_birth: dateOfBirth,
          statementKey: statementKey !== undefined ? statementKey : null
      }
      dispatch(postConsumerCredit({
          data,
          accessToken
      }));
      handleStateChanges(PostDataStatus.FETCHING)
    }
  }

  const proceedToView = ()=>{
    props.close()
    dispatch(props.setViewReport(false))
    resetForm()
}

  const successfulProceed = ()=>{
    dispatch(resetAll())
    props.close()
    //dispatch(props.setViewReport(false))
    resetForm()
  }

  const handleReportSelect = (data)=>{
        setReportState(data)
  }

  const handleStateChanges = (state) => {
    if(state === PostDataStatus.INITIAL) {
      setView(PostDataStatus.INITIAL);
    } else if(state === PostDataStatus.FETCHING) {
      setView(PostDataStatus.FETCHING);
    } else if(state === PostDataStatus.FAILURE) {
      setView(PostDataStatus.FAILURE);
    }
    else if(state === PostDataStatus.SUCCESS) {
        setView(PostDataStatus.SUCCESS);
      }
  }

  useEffect(()=>{
    if(ghanaCrb.commercialCreditPostStatus === PostDataStatus.FAILURE 
        || 
      ghanaCrb.commercialCreditPostStatus === PostDataStatus.FAILURE){
        handleStateChanges(PostDataStatus.FAILURE)
    }

    if(ghanaCrb.commercialCreditPostStatus === PostDataStatus.SUCCESS 
        || 
       ghanaCrb.consumerCreditPostStatus === PostDataStatus.SUCCESS){
        handleStateChanges(PostDataStatus.SUCCESS)
       }
  },[ghanaCrb.commercialCreditPostStatus,ghanaCrb.consumerCreditPostStatus])


  if(view === PostDataStatus.INITIAL) {


    content = <form onSubmit={handleSubmit(onSubmit)} className="bureau-credit-check-form">
      <h4 className="detail-header">{field.title}</h4>
      <p className="text-medium-dark">
        <strong>Note: </strong>Please ensure all inputed data is correct before submitting. Each call to the Credit Bureau will incur a charge.</p>
     
      {(field.type === CRBGhanaCheckTypes.CONSUMER) ?
      <div className="bureau-credit-check-input-container">
        <label>Customer’s Name</label>
        <input type="text" className="inputs" {...register("name", { required: "Name is required" })}/>
      </div>
      :
      <div className="bureau-credit-check-input-container">
        <label>Business Registration Number</label>
        <input type="number" className="inputs" {...register("business_registration_number", 
        { required: "business registration number is required" })}/>
      </div>
      }

      {(field.type === CRBGhanaCheckTypes.CONSUMER) && <div className='bureau-credit-check-input-container'> 
        <label>Date of Birth</label>
        <input className="input-pill-single" autoComplete="off" type='date' 
        onChange={(event)=>setDateOfBirth(event.target.value)} />
            
      </div>}

      <div className='bureau-credit-check-input-container'>
        <label>Report Type</label>
        <div  className="bureau-credit-check-input-container">
            <select style={{height:"100%",width:"100%"}} {...register('report_type', { required: 'report type is required' })} 
              onChange={(e) => handleReportSelect(e.target.value)}>
              <option value="">Select a report type</option>
              {['Full','Basic'].map((element, i) => {
                return <option value={element} key={i}>{element}</option>
              })}
            </select>
        </div>
      </div>

      <label className="bureau-credit-check-accept-check">
        <input type="checkbox" {...register("chargeAccept",
          { required: "You must agree to the charge before generating a report" })} />
        <p>By checking this box, you are agreeing to the call fee</p>
      </label>
      {errors.name?.message && <span className="text-error">{errors.name?.message}</span>}
      {errors.report_type?.message && <span className="text-error">{errors.report_type?.message}</span>}
      {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}
      <div>
        <button type="submit" className="bureau-credit-check-button button-solid button-square">
          Generate
        </button>
      </div>
    </form>
  } else if(view === PostDataStatus.FETCHING) {
    content = <Spinner />
  } 
  
    else if(view === PostDataStatus.SUCCESS){
       content =  <div style={{display:"flex",
                              flexDirection:"column",
                              height:"250px",
                              justifyContent:"space-around",
                              alignItems:"center"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={amlFilled}/></span>
                <p style={{color:"#2A38A4",fontWeight:"700",fontSize:"24px"}}>CRB Check Available</p>
                <p style={{color:'#363C4F'}}>Click view report to see </p>

                <span 
                    onClick={()=>successfulProceed()} 
                    className='buttonCrb'>
                    View Report
                </span>
        </div>
    }
    
    else if(view === PostDataStatus.FAILURE) {
    content = <div className="bureau-credit-check-form">
      <div>
        <h4 className="detail-header">Request Failed</h4>
        <p>We were unable to retrieve any data.</p>
        <p>{field.type === CRBGhanaCheckTypes.COMMERCIAL?
            ghanaCrb.commercialCreditPostErrorMessage:
            ghanaCrb.consumerCreditPostErrorMessage}</p>
        <button className="bureau-credit-check-button button-solid button-square" onClick={() => resetForm()}>
          Try Again
        </button>
      </div>
    </div>
  } 

  return (
    <div className="bureau-credit-check-dialog">
      <div className="bureau-credit-check-header">
        <img src="/assets/icons/close-color.svg" alt="Close" onClick={props.close} />
      </div>
      {content}
    </div>
  )
}

export default GhanaCrbModal;