import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../library/Variables";

export const generateDraft = createAsyncThunk('draftEdit/draft', async (params) => {
    const data = JSON.stringify(params.data)
    const draftData = await client.post('/loan/save', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return draftData;
  });


  export const editDraft = createAsyncThunk('draftEdit/editDraft', async (params) => {
    const data = JSON.stringify(params.data)
    const draftData = await client.post('/loan/edit-saved-application', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return draftData;
  });


  export const submitDraft = createAsyncThunk('draftEdit/submitDraft', async (params) => {
    const data = JSON.stringify(params.data)
    const draftData = await client.post('/loan/submit-saved-application', data, 
    {
      "headers":
        {
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
        }
    });
    return draftData;
  });





export const draftEditSlice = createSlice({
    name: 'draftEdit',
    initialState: {
        draftedData:null,
        draftedDataStatus:PostDataStatus.INITIAL,
        isErrordraftedData:false,
        errordraftedData:null,

        editDraftData:null,
        editDraftStatus:PostDataStatus.INITIAL,
        isErrorEditDraft:false,
        errorEditDraft:null,


        savedDraftData:null,
        savedDraftDataStatus:PostDataStatus.INITIAL,
        isErrorSavedDraftData:false,
        errorSavedDraftData:null,
    },

    reducers: {
      resetDraftData: state => {
          state.draftedData = null;
          state.scoreBreakDownStatus = PostDataStatus.INITIAL;
          state.isErrordraftedData = false;
          state.errordraftedData = null;

      },

      resetEditDraft: state => {
        state.editDraftData = null;
        state.editDraftStatus = PostDataStatus.INITIAL;
        state.isErrorEditDraft = false;
        state.errorEditDraft = null;

    },

    resetSubmitDraft: state => {
      state.savedDraftData = null;
      state.savedDraftDataStatus = PostDataStatus.INITIAL;
      state.isErrorSavedDraftData = false;
      state.errorSavedDraftData = null;

  }
    
    }
      ,

    extraReducers(builder) {
        builder.addCase(generateDraft.pending, (state) => {
            state.draftedDataStatus = PostDataStatus.FETCHING;
          })
          .addCase(generateDraft.fulfilled, (state, action) => {
            state.draftedDataStatus = PostDataStatus.SUCCESS;
            state.draftedData = action.payload;
          })
          .addCase(generateDraft.rejected, (state, action) => {
            state.draftedDataStatus = PostDataStatus.FAILURE;
            state.isErrordraftedData = true;
            state.errordraftedData = action.error.message;
           
          })


          .addCase(editDraft.pending, (state) => {
            state.editDraftStatus = PostDataStatus.FETCHING;
          })
          .addCase(editDraft.fulfilled, (state, action) => {
            state.editDraftStatus = PostDataStatus.SUCCESS;
            state.editDraftData = action.payload;
          })
          .addCase(editDraft.rejected, (state, action) => {
            state.editDraftStatus = PostDataStatus.FAILURE;
            state.isErrorEditDraft = true;
            state.errorEditDraft = action?.error?.message ?action?.error?.message :'Please, try again at some other time.';
           
          })


          .addCase(submitDraft.pending, (state) => {
            state.savedDraftDataStatus = PostDataStatus.FETCHING;
          })
          .addCase(submitDraft.fulfilled, (state, action) => {
            state.savedDraftDataStatus = PostDataStatus.SUCCESS;
            state.savedDraftData = action.payload;
          })
          .addCase(submitDraft.rejected, (state, action) => {
            state.savedDraftDataStatus = PostDataStatus.FAILURE;
            state.isErrorSavedDraftData = true;
            state.errorSavedDraftData = action?.error?.message ?action?.error?.message :'Please, try again at some other time.';
           
          })
    }
})

export const { resetDraftData,resetEditDraft,resetSubmitDraft } = draftEditSlice.actions;

export const selectDraftState = state => state.draftEdit

export default draftEditSlice.reducer;