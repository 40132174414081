import React, {useState} from 'react'
import nairaIcon from '../../assests/icons/nairaIcon.png'


function AmountInDynamic({item,fixedClientFields,setFixedClientFields,index}) {
    const [inputValue,setInputValue] = useState('')
    const [exportValue,setExportValue] = useState()
    const [currentValue,setCurrentValue] = useState(null)

    const handleMarketValueChange = (event,index) => {

        let number = event.target.value
              
          if((number.length >currentValue?.length) || !currentValue){
            number = number.split("")
            if(isNaN(parseInt(number[number.length-1]))){
              if(number[0]===number[number.length-1]){
                event.target.value = ''
              }
              else{
      
                const formatter = new Intl.NumberFormat('en-US');
                const formattedNumber = formatter.format(exportValue);
                event.target.value = formattedNumber
              }
            
            }
            else{
      
              let mainValue = inputValue+number[number.length-1]
              setInputValue(mainValue)
              mainValue = parseInt(mainValue)
              setExportValue(mainValue.toString())
              const entireArray = [...fixedClientFields]
              let changedItem = fixedClientFields[index]
              changedItem.marketValue = mainValue
              entireArray.splice(index,1,changedItem)
              setFixedClientFields([...entireArray]);
              const formatter = new Intl.NumberFormat('en-US');
              
              const formattedNumber = formatter.format(mainValue);
              // Remove non-numeric characters
              setCurrentValue(formattedNumber)
              event.target.value = formattedNumber
              
            }
          }
          else{
            if(number===''){
              event.target.value = ''
              setCurrentValue(null)
              setInputValue('')
              setExportValue('')
              const entireArray = [...fixedClientFields]
              let changedItem = fixedClientFields[index]
              changedItem.marketValue = number
              entireArray.splice(index,1,changedItem)
              setFixedClientFields([...entireArray]);
            }
            else{
      
              let number = exportValue.toString()
              number = number.split("")
              number.splice(number.length-1,1)
              number = number.join('')
              number = parseInt(number)
              setExportValue(number.toString())
              const entireArray = [...fixedClientFields]
              let changedItem = fixedClientFields[index]
              changedItem.marketValue = number
              entireArray.splice(index,1,changedItem)
              setFixedClientFields([...entireArray]);
              setInputValue(number.toString())
              const formatter = new Intl.NumberFormat('en-US');
      
              const formattedNumber = formatter.format(number);
              setCurrentValue(formattedNumber)
              event.target.value = formattedNumber
          
            }
              }
              
      
        /**
         * const value = parseInt(event.target.value);
        const entireArray = [...fixedClientFields]
        let changedItem = fixedClientFields[index]
        changedItem.marketValue = value
        entireArray.splice(index,1,changedItem)
        setFixedClientFields([...entireArray]);
         */
      
        
      };
      

    

  return (
    <div className='sign-up-details-input-container'>
                          <label>{item.labelMarket} <span className="required">*</span></label>

                          <section className='currency-section'>
                              <span><img src={nairaIcon}/></span>
                              <input placeholder= 'input Market value' autocomplete="off" type='TEXT'
                               onChange={(event)=>handleMarketValueChange(event,index)} /> 
                          </section>
                          {item.errorMarket && <p style={{ color: "red"}}>{item.errorMarket}</p>}
                      </div>
  )
}

export default AmountInDynamic