import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import { 
  selectBureauState,
  getAllBureauReports,
  addNewBureauReportAsync,
  resetAll
} from "../../../redux/Slices/Bureau";
import { CrbHistoryMainSerializer } from "./CrbSerializer/CrbHistorySerializer";
import Utils from "../../../library/Utils";
import Spinner from "../../../svg/Spinner/Spinner";
import "./Bureau.css";
import BureauConsumerBasic from "./BureauCrbReport/BureauConsumerBasic/BureauConsumerBasic";
import BureauForm from "./Form/BureauForm";
import BureauReport from "./Report/BureauReport";
import Modal from "react-modal";
import GhanaCrbModal from "../../../components/Modals/CrbModals/CrbModal";
import GhanaCrbFetchModal from "../../../components/Modals/CrbModals/CrbGetModal";
import { BureauGenerateViewType, 
         CreditCheckCardFields, 
         FetchDataStatus, 
         PostDataStatus,
         CrbGhanaCardFields } from "../../../library/Variables";

import BureauCreditCheck from "../../../components/Modals/BureauCreditCheck";
import { Table } from "../../../components/Table/Table";
import CreditCheckCards from "./Sections/CreditCheckCards";
import BureauReportBusiness from "./Report/BureauReportBusiness";
import BureauReportIndividual from "./Report/BureauReportIndividual";
import { CamelDataSerializer } from "./CrbSerializer/CrbCamelCaseSerializer";
import { CRBGhanaCheckTypes } from "../../../library/Variables";
import { resetConsumerCreditPost,
         resetCommercialCreditGet, 
         resetCommercialCreditPost,
         resetConsumerCreditGet,
         getConsumerCredit,
         getCommercialCredit,
         selectCrbGhanaData } from "../../../redux/Slices/CRB/Ghana/CrbGhana";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Bureau(props) {
  let content = <Spinner />;
  let subheader = null;

  const accessToken = props.accessToken;
  const keyFromRoute = parseInt(props.keyFromRoute);
  const configuration = props.configuration;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bureau = useSelector(selectBureauState);
  const camelState = useSelector(selectCrbGhanaData)
  const [view, setView] = useState(BureauGenerateViewType.INITIAL);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [crbModalIsOpen, setCrbModalOpen] = useState(false);
  const [crbGetModalIsOpen, setCrbGetModalOpen] = useState(false);
  const [fieldType, setFieldType] = useState(null);
  const [fetchFields,setFetchFields] = useState(null)
  const [report, setReport] = useState(null);
  const [reportFormat,setReportFormat] = useState(null)
  const [viewReport,setViewReport] = useState(false)
  const [crbHistoryState,setCrbHistoryState] = useState('')

  const consumerFunctions = (data,status)=>{
    if(status === 'pending'){
      toast.error("Your CRB request is being processed.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    else{
      dispatch(getConsumerCredit({ref:data.row.original.reference,accessToken}));
      setFetchFields('CONSUMER');
      setReportFormat(data.row.original.reportType)
      openCrbGetModal()
    }
  
  }

  const commercialFunctions = (data)=>{
    //dispatch(getCommercialCredit({ref:data.row.original.reference,accessToken}));
    //setFetchFields('COMMERCIAL');
    //setReportFormat(data.row.original.reportType)
    //openCrbGetModal()
  }

  
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])

  useEffect(()=>{
    if(bureau.allBureauReportsStatus === FetchDataStatus.SUCCESS){
      setCrbHistoryState(CrbHistoryMainSerializer(bureau.allBureauReports))
    }
  },[bureau.allBureauReportsStatus])


  
  const theaders = useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: data => {
        let status = !Utils.isFieldEmpty(data.row.original.status) ? data.row.original.status : "Found";

        return (
          <div className="table-row-status" style={{
            color: Utils.formatTextColor(status, "#808080", "#c1121f", "#00770C", "#FF725E")
          }}>{status.toUpperCase()}</div>
        )
      }
    },
    { 
      Header: "Created Date",
      accessor: "createdDate",
      disableSortBy: true,
      Cell: data => {
        return Utils.formatLongDateAndTime(data.row.original.createdDate)
      }
    },
    {
      Header: "Full Name",
      id: "fullName",
      disableSortBy: true,
      Cell: data => {
        let fullName = !Utils.isFieldEmpty(data.row.original.fullName) 
          ? data.row.original.fullName 
          : data.row.original.firstName + " " + data.row.original.lastName;

        return fullName;
      }
    },
    { 
      Header: "BVN/RC Number",
      id: "bvn",
      Cell: data => {
        return data.row.original.bvn || data.row.original.businessRegisterationNumber
      },
      disableSortBy: true
    },
    {
      Header: "Tag",
      Cell: data => {
        return "CREDITBUREAU"
      },
      disableSortBy: true
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: data => {
        let status = !Utils.isFieldEmpty(data.row.original.status) ? data.row.original.status : "Found";
        let isDisabled = status.toUpperCase() === "FOUND" ? false : true;

        return (
          <button className="button-square button-solid" onClick={() => {
            setView(BureauGenerateViewType[(data.row.original.providerSource).toUpperCase()]);
            setReport(data.row.original);
          }} disabled={isDisabled}>View</button>
        );
      }
    }]
  )



  const theadersCrb = useMemo(() => [
   
    { 
      Header: "Created Date",
      accessor: "createdDate",
      disableSortBy: true,
      Cell: data => {
        return Utils.formatLongDateAndTime(data.row.original.createdDate)
      }
    },
    {
      Header: "Credit Type",
      id: "creditType",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.creditType;
      }
    },
    {
      Header: "Report Type",
      id: "reportType",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.reportType;
      }
    },
    { 
      Header: "ID Number",
      id: "idnumber",
      Cell: data => {
        return data.row.original.IDNUMBER 
      },
      disableSortBy: true
    },
    { 
      Header: "Status",
      id: "status",
      Cell: data => {
        return <span style={{color:data.row.original.status==='pending'?'black':'green'}}>
                  {data.row.original.status}
                </span>
      },
      disableSortBy: true
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: data => {
        let status = data.row.original.status;
        let isDisabled = status.toUpperCase() === "FOUND" ? false : true;

        return (
          <button className={(data.row.original.creditType==='CONSUMER CREDIT' && data.row.original.status==='found')?
                            "button-square button-solid":"disableCrbButton"} onClick={() => {
            data.row.original.creditType==='CONSUMER CREDIT'?
            consumerFunctions(data,status):
            commercialFunctions(data)
          }} >View</button>
        );
      }
    }]
  )




  const params = {
    accessToken: accessToken,
    key: keyFromRoute,
  }
  
  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "50%",
      left: "33%",
      padding: "0 0 0 0",
      fontFamily: configuration.fontFamily,
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const afterOpenModal = () => {}

  const closeModal = () => {
    setIsOpen(false);
  }


  const openCrbModal = () => {
    setCrbModalOpen(true);
  }

  const afterOpenCrbModal = () => {}

  const closeCrbModal = () => {
    setCrbModalOpen(false);
    dispatch(resetCommercialCreditPost())
    dispatch(resetConsumerCreditPost())
  }

  const closeCrbGetModal = () => {
    setCrbGetModalOpen(false);
  }


  const openCrbGetModal = () => {
    setCrbGetModalOpen(true);
  }

  


  const handleCrbSelection = (index) => {
    setFieldType(CrbGhanaCardFields[index])
    openCrbModal();
  }


  const handleCardSelected = (index) => {
    setFieldType(CreditCheckCardFields[index]);
    openModal();
  }

  const submitCallbackFunction = (formParams) => {
    setView(BureauGenerateViewType.INITIAL);
    dispatch(addNewBureauReportAsync(formParams));
  }



  useEffect(() => {
    if(bureau.allBureauReportsStatus === FetchDataStatus.INITIAL) {
      dispatch(getAllBureauReports(params));
    }

    if(bureau.allBureauReportsStatus === FetchDataStatus.FAILURE) {
      navigate("/error");
    }
  }, [bureau.allBureauReportsStatus, dispatch, navigate])




  useEffect(() => {
    if(bureau.addNewReportStatus === PostDataStatus.SUCCESS || bureau.addNewReportStatus === PostDataStatus.FAILURE) {
      dispatch(resetAll());
    }
  }, [bureau.addNewReportStatus, dispatch])

  if(configuration.featureFlags.isBlacklistEnabled === true || configuration.featureFlags.isCreditchekEnabled === true) {
    subheader = <p className="text-medium-dark">Tap any of the cards below to proceed with check</p>
  }

  if(view === BureauGenerateViewType.INITIAL) {
    content = <div>
      <div className="bureau-check-header-container">
        <div>
          <h3 className="detail-header">Bureau Data Check</h3>
          {!Utils.isFieldEmpty(subheader) && subheader}
        </div>
        {(configuration.featureFlags.isCustomBureauDataEnabled === true && configuration.countryCode !== "GHA") &&
          <button className="button-square button-solid" onClick={() => setView(BureauGenerateViewType.FORM)}>Add Custom Data</button>
        }
      </div>
      <div className="bureau-creditcheck-type-container">
      {
        configuration.countryCode !== "GHA" ?
        <>
            {configuration.featureFlags.isBlacklistEnabled &&
              <CreditCheckCards parentCallback={() => handleCardSelected(0)} 
                field={{ title: "CRC Nano Data", subtitle: "This check requires BVN" }} />
            }
            {configuration.featureFlags.isCreditchekEnabled &&
              <>
                <CreditCheckCards parentCallback={() => handleCardSelected(1)} 
                  field={{ title: "Bureau Data for Individual", subtitle: "This check requires BVN of the individual" }} />
                <CreditCheckCards parentCallback={() => handleCardSelected(2)}
                  field={{ title: "Bureau Data for Business", subtitle: "This check requires BVN of the business" }} />
              </>
            }
        </>
        :
        <>
        <CreditCheckCards parentCallback={() => handleCrbSelection(0)} 
            field={{ title: "Bureau Data for Business", 
                     subtitle: "This check requires enterprise name.",
                     type:CRBGhanaCheckTypes.COMMERCIAL}} />

        <CreditCheckCards parentCallback={() => handleCrbSelection(1)} 
            field={{ title: "Bureau Data for Individual", 
                     subtitle: "This check requires consumer name and date of birth.",
                     type:CRBGhanaCheckTypes.CONSUMER }} />
        </>
      }
        

        
      </div>
      {bureau.allBureauReportsStatus === FetchDataStatus.FETCHING
        ? <Spinner />
        : !Utils.isFieldEmpty(bureau.allBureauReports) 
          && <>
              <hr />
              <div className="bureau-all-reports-table-container">
                <h3 className="detail-header">Check History</h3>
                {
                  configuration.countryCode !== "GHA"?
                  <Table data={bureau.allBureauReports} 
                         columns={theaders} 
                         tableClass="bureau-all-reports-table" />
                         :
                  <>
                    {!viewReport?<>
                    
                            <div className='top-section'>
                              <span className={viewReport?'span-view-report':'span-view-history'}>History</span>
                              <span>/</span>
                              <span className={viewReport?'span-view-history':'span-view-report'} >View report</span>
                            </div>

                            {
                              Utils.isFieldEmpty(crbHistoryState)?
                              <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                                    You currently have no historical Data on CRB.
                              </div>
                              :
                              <Table data={crbHistoryState}
                                columns={theadersCrb} 
                                tableClass="bureau-all-reports-table" 

                              />
                            }
                            

                    </>
                    :
                    <>
                      <BureauConsumerBasic setViewReport={setViewReport}/>
                    </>
                    }
                
                  </>
                }
                
              </div>
            </>
      }
    </div>
  } else if(view === BureauGenerateViewType.FORM) {
    content = <BureauForm callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} keyFromRoute={keyFromRoute}
      submitCallbackFunction={(formParams) => submitCallbackFunction(formParams)} accessToken={props.accessToken} />
  } else if(view === BureauGenerateViewType.CUSTOM || view === BureauGenerateViewType.BLACKLIST) {
    content = <BureauReport report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
  } else if(view === BureauGenerateViewType.CREDITCHEK) {
    if(!Utils.isFieldEmpty(report.businessRegisterationNumber)) {
      content = <BureauReportBusiness report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
    } else {
      content = <BureauReportIndividual report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
    }
  }

  return (
    <div className="bureau-main">
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} >
        <BureauCreditCheck field={fieldType} close={closeModal} accessToken={accessToken} statementKey={keyFromRoute}
          configuration={configuration} />
      </Modal>

      <Modal isOpen={crbModalIsOpen} onAfterOpen={afterOpenCrbModal} style={customStyles} >
        <GhanaCrbModal field={fieldType} 
                        
                        close={closeCrbModal} 
                        accessToken={accessToken} 
                        statementKey={keyFromRoute}
                        configuration={configuration} 
                        setViewReport={setViewReport}
                        />
      </Modal>

      <Modal isOpen={crbGetModalIsOpen} onAfterOpen={afterOpenCrbModal} onRequestClose={closeCrbGetModal} style={customStyles} >
        <GhanaCrbFetchModal field={fetchFields} 
                            close={closeCrbGetModal} 
                            setViewReport={setViewReport}
                            reportFormat={reportFormat}
                            />
      </Modal>

      
      {content}
    </div>
  )
}

export default Bureau;


/**
 * <Table data={CrbHistoryMainSerializer(bureau.allBureauReports)} 
                                columns={theadersCrb} 
                                tableClass="bureau-all-reports-table" 

                              />
 */