import Spinner from "../../../svg/Spinner/Spinner";
import "../Modal.css";
import "./UserManagement.css";
import { FetchDataStatus, PostDataStatus } from "../../../library/Variables";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetUpdateUser, updateUserAsync } from "../../../redux/Slices/UserManagement";

function EditUser(props) {
  let content = null;

  const dispatch = useDispatch();
  const accessToken = props.accessToken;
  const platformUser = props.platformUser;
  const userManagement = props.userManagement;
  const user = props.user;
  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.roleId,
    businessUserId : user.businessUserId?user.businessUserId:'N/A',
    branchNumber : user.branchNumber?user.branchNumber:'N/A'  
  }
  const { register, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

  const onSubmit = (formData) => {
    dispatch(updateUserAsync({
      accessToken: accessToken,
      data: {
        userKey: user.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.email,
        role: parseInt(formData.role),
        businessUserId : formData.businessUserId,
        branchNumber : formData.branchNumber   
      }
    }))
  }

  const resetForm = () => {
    reset();
    dispatch(resetUpdateUser());
  }

  if(userManagement.updateUserStatus === PostDataStatus.INITIAL) {
    content = <form onSubmit={handleSubmit(onSubmit)} className="user-management-form-container">
      <div className="modal-body-container user-management-form-fields scrollbar">
        <div className="scrollable-content">
          <div className="user-management-input-container">
            <label className="text-darker">First name</label>
            <input className="inputs" type="text" placeholder="Enter first name" 
              {...register("firstName", { required: "First name is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.firstName?.message}</span>
          </div>
          <div className="user-management-input-container">
            <label className="text-darker">Last name</label>
            <input className="inputs" type="text" placeholder="Enter last name" 
              {...register("lastName", { required: "Last name is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.lastName?.message}</span>
          </div>
          <div className="user-management-input-container">
            <label className="text-darker">Email address</label>
            <input className="inputs" type="text" placeholder="Enter email address" {...register("email", 
              { required: "Email address is required", 
                pattern: {
                  value: /^([_a-z0-9]+[\._a-z0-9]*)(\+[a-z0-9]+)?@(([a-z0-9-]+\.)*[a-z]{2,4})$/,
                  message: "Invalid email address"
                } } )} />
            <span className="user-management-error-fields error-msg">
              {errors.email?.message}</span>
          </div>
          {platformUser.user.permissions.reassignRoles === true &&
            <div className="user-management-input-container">
              <label className="text-darker">Role</label>
              <select {...register("role")} className="user-management-select-inputs-container">
                {userManagement.userRoles.map((element, i) => {
                  return <option key={i} value={i + 1}>{element.label}</option>
                })}
              </select>
              <span className="user-management-error-fields error-msg"></span>
            </div>
          }   
          <div className="user-management-input-container">
            <label className="text-darker">Business User ID</label>
            <input className="inputs" type="text" placeholder="Enter business user ID" 
              {...register("businessUserId", { required: "Business user ID is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.businessUserId?.message}</span>
          </div>

          <div className="user-management-input-container">
            <label className="text-darker">Branch Number</label>
            <input className="inputs" type="text" placeholder="Enter branch number" 
              {...register("branchNumber", { required: "Branch Number is required" } )} />
            <span className="user-management-error-fields error-msg">
              {errors.branchNumber?.message}</span>
          </div>       
        </div>
      </div>
      <div className="submit-container">
        <button type="button" className="button-square button-outline" onClick={props.close}>Cancel</button>
        <button type="submit" className="button-square button-solid">Update</button>
      </div>
    </form>
  } else if(userManagement.updateUserStatus === PostDataStatus.FETCHING) {
    content = <Spinner />
  } else if(userManagement.updateUserStatus === PostDataStatus.SUCCESS) {
    content = <div className="modal-body-container">
      <div className="modal-body-message">
        <p>User has been updated</p>
      </div>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={props.close} >Close</button>
      </div>
    </div>
  } else if(userManagement.updateUserStatus === PostDataStatus.FAILURE) {
    content = <div className="modal-body-container">
      <p></p>
      <div className="submit-container">
        <button className="button-square button-solid" onClick={() => resetForm()}>Try again</button>
      </div>
    </div>
  }
  
  return(
    <div className="modal-dialog user-management-modal">
      <div className="modal-header">
        <h2 className="detail-header">Edit User</h2>
        <div className="close" onClick={props.close}>
          <img src="/assets/icons/close-color.svg" alt="Close" />
        </div>        
      </div>
      <hr />
      {content}
    </div>
  )
}

export default EditUser;