import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router';



function BreakDown({AgentsPreview,PerformingAgents,labelBreakdown,AgentsList,listAgentsHeader,data,options,setViewPort}) {
  ChartJS.register(CategoryScale, 
                  LinearScale, 
                  BarElement, 
                  Title, 
                  Tooltip, 
                  Legend);
  const navigate = useNavigate()
  return (
    <div>
        <section style={{display:"flex",justifyContent:"space-around"}}>
          <div className='agentBreakDownHold'>
            <section className='titleBreakHold'>
              <p className='title'>{AgentsPreview.title}</p>
              <p className='view'>View All</p>
            </section>
            
            <section>
              {AgentsPreview.data.map((element,index)=>
                <section key={index} style={{display:"flex",justifyContent:"space-between",margin:"10px 0px"}}>
                  <section style={{color:'#1F2227',fontWeight:"400",fontSize:"16px"}}>
                    {element.place}
                  </section>
                  <section style={{color:'#1F2227',fontWeight:"700",fontSize:"16px"}}>
                    {element.number}
                  </section>
                </section>
              )}
            </section>
          </div>

          <div className='agentBreakDownHoldChart'>
            <section className='titleBreakHold'>
              <p className='title'>Top Performing Regions</p>
              <p className='view' onClick={()=>setViewPort('performingRegions')}>View All</p>
            </section>
            <section>in millions</section>
            <div style={{width:"500px",
                         height:"100%",
                         margin: '0 auto',
                         boxSizing:"border-box",
                         position:"relative",
                         backgroundColor:"white" }}>
              <div style={{position:'absolute',
                           height:"70px",
                           width:'100%',
                           top:"5px",
                           display:"flex",
                           flexWrap:'wrap',
                           justifyContent:'start',
                           columnGap:'10px',
                           rowGap:"10px",
                           alignItems:'center'
                           }}>
                  {labelBreakdown.map((element,index)=>
                    <span style={{width:"31%",
                                  display:'flex',
                                  justifyContent:"center",
                                  alignItems:"center",
                                  boxShadow:"0 4px 6px rgba(0, 0, 0, 0.1)",
                                  backgroundColor:"white",
                                  borderRadius:"7px"}} key={index}>{element}</span>)}
              </div>
              <div style={{width:"100%",marginTop:"80px"}}>
                <Bar data={data} options={options} />
              </div>
            </div>
          </div>

          <div className='agentBreakDownHold'>
            <section className='titleBreakHold'>
              <p className='title'>{PerformingAgents.title}</p>
              <p className='view'>View All</p>
            </section>
            <section>
              {PerformingAgents.data.map((element,index)=>
                <section key={index} style={{display:"flex",justifyContent:"space-between",margin:"10px 0px"}}>
                  <section style={{color:'#1F2227',fontWeight:"400",fontSize:"16px"}}>
                    {element.name}
                  </section>
                  <section style={{color:'#1F2227',fontWeight:"700",fontSize:"16px"}}>
                    {element.value}
                  </section>
                </section>
              )}
            </section>
          </div>

          
        </section>

        <section className='AgentTable'>
            <section className='titleBreakHold'>
              <p className='title'>{AgentsList.title}</p>
              <p className='view' onClick={()=>setViewPort('listOfAgents')}>View All</p>
            </section>

            <div>
              <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                {listAgentsHeader.map((header,index)=>(header==='Action'?
                  <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                  )}
              </section>
              <section>
                {AgentsList.data.map((content,index)=>
                
                <section className='AgentTableRow' key={index}>
                  <section>{content.ID}</section>
                  <section>{content.AgentName}</section>
                  <section>{content.BusinessName}</section>
                  <section>{content.Location}</section>
                  <section>{content.amount}</section>
                  <div 
                  onClick={()=>navigate('/agentBreakdown')}
                  style={{fontWeight:"700",width:"10%",display:'flex',
                              justifyContent:"center",alignItems:"center",
                              cursor:"pointer",backgroundColor:"#2B52AA",
                              color:"white",padding:"10px 0px",
                              borderRadius:"5px"}}>View</div>
                </section>)}
              </section>
            </div>
        </section>
    </div>
  )
}

export default BreakDown