import classNames from "classnames";
import SkeletonLoader from "./skeletonLoader";

import styles from "./index.module.css";

const TransStatisSkeleton = () => {
  return (
    <div
      className={classNames(
        "top-cards-container",
        "top-cards-container-loader"
      )}
    >
      <div className={styles.loaderWrap}>
        <div>
          <SkeletonLoader count={1} height={50} width={50} circle={true} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={200} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={80} />
        </div>
      </div>
      <div className={styles.loaderWrap}>
        <div>
          <SkeletonLoader count={1} height={50} width={50} circle={true} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={200} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={80} />
        </div>
      </div>
      <div className={styles.loaderWrap}>
        <div>
          <SkeletonLoader count={1} height={50} width={50} circle={true} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={200} />
        </div>
        <div>
          <SkeletonLoader count={1} height={20} width={80} />
        </div>
      </div>
    </div>
  );
};

export default TransStatisSkeleton;
