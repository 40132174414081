import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus } from '../../library/Variables';

export const getTransactionCount = createAsyncThunk('transactions/getTransacationCount', async (params) => {
  const totalTransactionCount = await client.get('/transactions/count',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return totalTransactionCount.data;
});

export const getTransactions = createAsyncThunk('transactions/getTransactions', async (params) => {
  const transactions = await client.get(`/statements/${params.key}/transactions`,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return transactions.data;
})

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactions: [],
    transactionStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    isLoading: true,
    totalTransactionCount: 0,
    totalTransactionCountStatus: FetchDataStatus.INITIAL
  },
  reducers: {
    resetTransactions: state => {
      state.transactions = [];
      state.transactionStatus = FetchDataStatus.INITIAL;
      state.isLoading = true;
    }
  },
  extraReducers(builder) {
    builder.addCase(getTransactionCount.pending, (state) => {
      state.totalTransactionCountStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getTransactionCount.fulfilled, (state, action) => {
      state.totalTransactionCountStatus = FetchDataStatus.SUCCESS;
      state.totalTransactionCount = action.payload;
    })
    .addCase(getTransactionCount.rejected, (state, action) => {
      state.totalTransactionCountStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getTransactions.pending, (state) => {
      state.transactionStatus = FetchDataStatus.FETCHING;
      state.isLoading = true;
    })
    .addCase(getTransactions.fulfilled, (state, action) => {
      state.transactionStatus = FetchDataStatus.SUCCESS;
      state.transactions = action.payload;
      state.isLoading = false;
    })
    .addCase(getTransactions.rejected, (state, action) => {
      state.transactionStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
      state.isLoading = true;
    })
  }
});

export const { loading, transactions, resetTransactions } = transactionSlice.actions;

export const selectTransactionState = state => state.transactions;

export default transactionSlice.reducer;