import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WebHookClient } from "../../../../api/webHookApi";
import { PostDataStatus } from "../../../../library/Variables";




const {selfServicePost} = WebHookClient

export const postPasswordReset = createAsyncThunk ('resetPassword',async(params)=>{
    const body = JSON.stringify(params.body)
    const resetResponse = await selfServicePost('/onboard/reset-password',body);
  return resetResponse.data;
} )


export const resetPassWordSLice = createSlice({
    name: 'resetPassWord',
    initialState: {

      resetPassStatus: PostDataStatus.INITIAL,
      resetPassData: null,
      isErrorResetPass:false,
      errorResetPass:null,
    },

    reducers: {
        resetPasswordReset: state => {

            state.resetPassStatus =  PostDataStatus.INITIAL;
            state.resetPassData =  null;
            state.isErrorResetPass = false;
            state.errorResetPass = null;
        }
        
    },
    extraReducers(builder) {
        builder.addCase(postPasswordReset.pending, (state) => {
            state.resetPassStatus = PostDataStatus.FETCHING;
          })
          .addCase(postPasswordReset.fulfilled, (state, action) => {
            state.resetPassStatus = PostDataStatus.SUCCESS;
            state.resetPassData = action.payload;
          })
          .addCase(postPasswordReset.rejected, (state, action) => {
            state.resetPassStatus = PostDataStatus.FAILURE;
            state.isErrorResetPass = true;
            state.errorResetPass = action.error.message;
          })

    }
})


export const { resetPasswordReset } = resetPassWordSLice.actions;

export const selectPasswordReset = state => state.resetPassWord
export default resetPassWordSLice.reducer;