import React from 'react'

function StepThree({setTourSection}) {


    const pop = <div style={{marginLeft:'5%',marginTop:"35px",position:'relative',height:"150px",width:'300px'}}>
    <div style={{width:"20px",height:"20px",transform:"rotate(45deg)",position:'absolute',top:"-10px",left:"20px",border:"1px solid lightblue",zIndex:"4"}}></div>
    <div style={{width:"19px",height:"19px",transform:"rotate(45deg)",position:'absolute',top:"-9px",left:"21px",backgroundColor:"white",zIndex:"14"}}></div>
    <div style={{height:'100%',padding:'5px',zIndex:'10',borderRadius:'7px',border:"1px solid rgba(195, 212, 255, 1)",position:"absolute",top:'0px',left:"0px",right:'0px',backgroundColor:"white",display:'flex',flexDirection:"column",justifyContent:"space-between"}}>
    <div style={{height:"60%",boxSizing:"border-box",display:"flex",flexDirection:"column",justifyContent:'space-around'}}>
      <p>Fill in customers detail to <strong>generate score.</strong></p>
    </div>

    <div style={{border:"0.1px solid lightblue",display:"flex",justifyContent:"space-around",alignItems:"center",boxSizing:"border-box",borderWidth:'0.1px 0px 0px 0px',height:"40%"}}>
        <span onClick={()=>setTourSection('stepTwo')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"white",color:'#407BFF',border:"1px solid #407BFF",cursor:'pointer'}}>Back</span>
        <span><strong>2 of 4</strong></span>
        <span onClick={()=>setTourSection('stepFour')} style={{width:"70px",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"#407BFF",cursor:'pointer',color:'white'}}>Next</span>
    </div>
    </div>
  </div>






    const proceed = 
    <>
        <div style={{width:'90%',margin:"0px auto"}}>
            <div style={{display:"flex",flexDirection:'column'}}>
                <h3>Micro Loans</h3>
                <p style={{fontSize:'11px',color:'grey',marginTop:"10px"}}>Add variables</p>
            </div>    
        </div>
    </>

    const fields = ['Customer ID','Age Range','Employment','Years of Employment','Number of Delinquent Facilities']
  return (
    <div>
        <div style={{padding:'25px 25px'}}>
            {proceed}
        </div> 
        <div style={{width:'90%',margin:"10px auto",border:"0.1px solid lightblue",padding:'15px',display:'flex',flexWrap:'wrap',height:"200px",position:'relative'}}>
            {fields.map(field=><input placeholder={field} style={{width:'450px',paddingLeft:'15px',borderRadius:'4px',backgroundColor:'rgba(248, 251, 255, 1)',border:"0.2px solid rgba(64, 123, 255, 1)",height:'35px',margin:'7px 20px'}}/>)}
            <div style={{width:'90%',margin:"10px auto",display:"flex",justifyContent:'end'}}>
                <span  style={{width:"auto",padding:'7px 10px',display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"white",color:'#407BF',border:"1px solid #407BFF"}}>Cancel</span>
                <span  style={{width:"auto",padding:'7px 10px',marginLeft:"15px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",backgroundColor:"#407BF",color:'white'}}>Generate  A Score</span>
            </div>   
        </div>
        {pop}

        
    </div>
  )
}

export default StepThree