import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WebHookClient } from "../../../api/webHookApi";
import { PostDataStatus } from "../../../library/Variables";



const {getDetails,updateDetails} = WebHookClient

export const getHookDetails = createAsyncThunk ('webHook/get-details',async(params)=>{
    const body = JSON.stringify(params.body)
    const detailsResponse = await getDetails('/insights/webhook-details',body);
  return detailsResponse.data;
} )


export const updateHookDetails = createAsyncThunk ('webHook/update-details',async(params)=>{
    const body = JSON.stringify(params.body)
    const detailsResponse = await updateDetails('/insights/update-webhook',body   
 );

  return detailsResponse.data;
} )







 


export const webHookSLice = createSlice({
    name: 'webHook',
    initialState: {

      fetchHookDetailsStatus: PostDataStatus.INITIAL,
      fetchHookDetailsData: null,
      isErrorfetchHookDetails:false,
      errorfetchHookDetails:null,

      updateHookDetailsStatus: PostDataStatus.INITIAL,
      updateHookDetailsData: null,
      isErrorupdateHookDetails:false,
      errorupdateHookDetails:null
    },

    reducers: {
        resetFetchHook: state => {

            state.fetchHookDetailsStatus =  PostDataStatus.INITIAL;
            state.fetchHookDetailsData =  null;
            state.isErrorfetchHookDetails = false;
            state.errorfetchHookDetails = null;
        }
        ,
        resetUpdateHook:state => {
            state.updateHookDetailsStatus =  PostDataStatus.INITIAL;
            state.updateHookDetailsData =  null;
            state.isErrorupdateHookDetails = false;
            state.errorupdateHookDetails = null;
        }
       
    },
    extraReducers(builder) {
        builder.addCase(getHookDetails.pending, (state) => {
            state.fetchHookDetailsStatus = PostDataStatus.FETCHING;
          })
          .addCase(getHookDetails.fulfilled, (state, action) => {
            state.fetchHookDetailsStatus = PostDataStatus.SUCCESS;
            state.fetchHookDetailsData = action.payload;
          })
          .addCase(getHookDetails.rejected, (state, action) => {
            state.fetchHookDetailsStatus = PostDataStatus.FAILURE;
            state.isErrorfetchHookDetails = true;
            state.errorfetchHookDetails = action.error.message;
          })


          .addCase(updateHookDetails.pending, (state) => {
            state.updateHookDetailsStatus = PostDataStatus.FETCHING;
          })
          .addCase(updateHookDetails.fulfilled, (state, action) => {
            state.updateHookDetailsStatus = PostDataStatus.SUCCESS;
            state.updateHookDetailsData = action.payload;
          })
          .addCase(updateHookDetails.rejected, (state, action) => {
            state.updateHookDetailsStatus = PostDataStatus.FAILURE;
            state.isErrorupdateHookDetails = true;
            state.errorupdateHookDetails = action.error.message;
          })

         

    }
})


export const { resetFetchHook, resetUpdateHook } = webHookSLice.actions;

export const selectHookSlice = state => state.webHook
export default webHookSLice.reducer;