export const PerformingRegionsFull ={title:'Top Performing Regions', data: [
    {region:'North-Central',
    noOfStatest:'5 States',
    noOfAgents:'34,000',
    gdp:'10,450,000',
    value:'50%'
    },
    {region:'North-West',
    noOfStatest:'5 States',
    noOfAgents:'44,000',
    gdp:'10,890,000',
    value:'60%'
    },
    {region:'North-East',
    noOfStatest:'3 States',
    noOfAgents:'14,000',
    gdp:'6,450,000',
    value:'30%'
    },
    {region:'South-South',
    noOfStatest:'5 States',
    noOfAgents:'44,000',
    gdp:'9,450,000',
    value:'49%'
    },
    {region:'South-East',
    noOfStatest:'5 States',
    noOfAgents:'44,000',
    gdp:'10,450,000',
    value:'65%'
    },
    {region:'South-West',
    noOfStatest:'5 States',
    noOfAgents:'54,000',
    gdp:'10,450,000',
    value:'65%'
    }
]}

export const RegionBreakDownData ={title:'Top Performing Regions', data: [
    {state:'Benue State',
    noOfAgents:'10,000',
    gdp:'1,450,000',
    value:'50%',
    volume:'40%',
    service:'35%'
    },
    {state:'Kogi State',
    noOfAgents:'4,500',
    gdp:'750,000',
    value:'40%',
    volume:'40%',
    service:'35%'
    },
    {state:'Niger State',
    noOfAgents:'3,000',
    gdp:'1,450,000',
    value:'50%',
    volume:'40%',
    service:'35%'
    },
    {state:'Plateau State',
    noOfAgents:'3,000',
    gdp:'1,450,000',
    value:'50%',
    volume:'40%',
    service:'55%'
    },
    {state:'Nassarawa State',
    noOfAgents:'1,000',
    gdp:'1,000,000',
    value:'50%',
    volume:'40%',
    service:'35%'
    },
    {state:'Abuja',
    noOfAgents:'4,000',
    gdp:'1,450,000',
    value:'50%',
    volume:'40%',
    service:'65%'
    }
]}