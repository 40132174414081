import React, { useState } from "react";
import { Table } from "../../../../components/Table/Table";
import "./TableView.css";

export function TableView(props) {
  const theaders = props.theaders;
  const data = props.data;
  const [isShown, setIsShown] = useState(false);

  return <div className="report-table-view">
    <div className="report-table-view-header">
      <h3>{props.title}</h3>
      <button className="button-link link-color" onClick={() => setIsShown(!isShown)}>{!isShown ? "Show" : "Hide"}</button>
    </div>
    {isShown && <Table data={data} columns={theaders} pageSize={[10]} />}
  </div>
}