import React,{useState,useEffect} from 'react'
import { selectCrbGhanaData } from '../../../../../redux/Slices/CRB/Ghana/CrbGhana';
import { useSelector } from 'react-redux';
import { CreditAccountObjSerializer } from '../../CrbSerializer/CreditAccountSerializer';
import { AccountTypeSerializer } from '../../CrbSerializer/AccountTypeSerializer';
import CrbHistoryTables from '../../../../CreditBureau/BureauComponents/HistoryTables/CrbHistoryTables';
import Spinner from '../../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../../library/Variables';
import Utils from '../../../../../library/Utils';
import HistoryBreakDown from '../../../../CreditBureau/BureauComponents/HistoryTables/HistoryBreakDown/HistoryBreakDown';
import MonthlyRepaymentTable from '../../../../CreditBureau/BureauComponents/AccMonthlyPay/MonthlyRepaymentTable';
import AgreementMonthlyTable from '../../../../CreditBureau/BureauComponents/AgreementSummary/AgreementSummary';
import AccountRatingPreview from '../../../../CreditBureau/BureauComponents/AccountRating/AccountRating';
import CreditAcctSummaryPreview from '../../../../CreditBureau/BureauComponents/CreditAccSummary/CreditAcctSummary';
import './bureauConsumer.css'

function BureauConsumerBasic({reportFormat,setViewReport,viewReport}) {
  let bureauState = useSelector(selectCrbGhanaData)
  const [accountRate,setAccountRate] = useState(true)
  const [tabSelectedFull,setTabSelectedFull] = useState('History')
  const [tabSelectedBasic,setTabSelectedBasic] = useState('Account Rating')
  const [accTypeState,setAccType] = useState([])
  const [accSumData,setAccSumData] = useState([])
  let mainObjSerializer
  let mainAccntSerializer


  if(!Utils.isFieldEmpty(bureauState.consumerCreditGet.data?.report)){
    mainObjSerializer = {...bureauState.consumerCreditGet.data.report.creditAccountSummary}
    mainAccntSerializer = {...bureauState.consumerCreditGet.data.report.accountRating}
    delete mainObjSerializer.rating
  }
  
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[bureauState.consumerCreditGet.data])

  useEffect(()=>{
    if(!Utils.isFieldEmpty(bureauState.consumerCreditGet.data?.report)){
    
      mainAccntSerializer = {...bureauState.consumerCreditGet.data.report.accountRating}
      const accountTypes = AccountTypeSerializer(mainAccntSerializer)
      setAccType(accountTypes)
      mainObjSerializer = {...bureauState.consumerCreditGet.data.report.creditAccountSummary}
      delete mainObjSerializer.rating
      const accountSumm = CreditAccountObjSerializer(mainObjSerializer)
      setAccSumData(accountSumm)
    }
  },[])

  const accountSumm = CreditAccountObjSerializer(mainObjSerializer)
  const accountTypes = AccountTypeSerializer(mainAccntSerializer)
  const tableHeaders = ['Attribute','EUR','GBP','GHC','USD']
  const tableHeadersAccntTypes = ['Account Types','Bad Accounts','Good Accounts']
  const contentTwoHeadsFull = ['History','Account Rating','Credit Account Summary','Credit Agreement Summary','Account Monthly Payment History']
  const contentTwoHeadsBasic = ['Account Rating','Credit Account Summary']
    let content
    let contentTwo

    const switchUi = (type)=>{
      setTabSelectedBasic(type)
      if(type==='Account Rating'){
        setAccountRate(true)
      }
      else{
        setAccountRate(false)
      }

    }

    if(bureauState.consumerCreditGetStatus === FetchDataStatus.FETCHING){
      content = <Spinner/>
    }

    if(bureauState.consumerCreditGetStatus === FetchDataStatus.SUCCESS){
      let userReport = {...bureauState.consumerCreditGet}


      if(Utils.isFieldEmpty(userReport.data.report)){
      
        content = 
            <>
             
              <div className='report-contentBureauOne'>
                  <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                      No Reports was Found.
                  </div>
              </div>
            </>
      }
      
  

      
    if(!Utils.isFieldEmpty(userReport.data.report)){
      const reportKeys = Object.keys(userReport.data.report.personalDetailsSummary);
      const midWay = Math.ceil(reportKeys.length/2)
      let reportKeyOne = reportKeys.slice(0,midWay)
      reportKeyOne = reportKeyOne.filter(item => item !== 'header');
      const reportKeyTwo = reportKeys.slice(midWay,reportKeys.length)
      const reportBureau = userReport.data.report.personalDetailsSummary
      content = 
          <>
            
            <section className='reportOverallHolder'>
                <section className='headerItem'>
                  {reportBureau['header']}
                </section>
                <section className='reportOverallContent'>
                    <div className='report-contentBureauOne'>
                      <div className='personalDet'>
                          {reportKeyOne.map(key=>
                          <div className='keyValue-holder' key={reportKeyOne.indexOf(key)}>
                            <p className='key'>{key}</p>
                            <p className='value'>
                                {!Utils.isFieldEmpty(reportBureau[key])?
                                    reportBureau[key]
                                    :'--'}
                            </p>
                          </div>)}
                      </div>
                    </div>

                    <div className='report-contentBureauOne'>
                      <div className='personalDet'>
                          {reportKeyTwo.map(key=>
                          <div className='keyValue-holder' key={reportKeyTwo.indexOf(key)}>
                            <p className='key'>{key}</p>
                            <p className='value'>
                                {!Utils.isFieldEmpty(reportBureau[key])?
                                    reportBureau[key]
                                    :'--'}
                            </p>
                          </div>)}
                      </div>
                    </div>
                </section>
                
            </section>
            

            
            {reportFormat==='full' && <section style={{
              backgroundColor:'white',
              width:'100%',
              padding:'0% 2.5% 0% 2.5%',
              boxSizing:'border-box',
              paddingBottom:'30px',
              borderRadius:"15px"
            }}>
              <CrbHistoryTables columnTitle={['Title','Surname','First Name','Other Names','Birth Date','Last Updated']}
                                spread = {'wide'}
                                content={userReport.data.report.nameHistory}
                                title = {'nameHistory'}
              />
            </section>}
          </>

        contentTwo = 
            <section style={{marginTop:'40px'}}>
               
                <section className='contentTwoTabs'>
                  {reportFormat==='basic'?contentTwoHeadsBasic.map((element,index)=>
                    <div 
                         onClick={()=>switchUi(element)}
                         className={tabSelectedBasic===element?'selecTed':'unselecTed'}>
                      {element}
                    </div>):
                    contentTwoHeadsFull.map((element,index)=>
                      <div 
                           onClick={()=>setTabSelectedFull(element)}
                           className={tabSelectedFull===element?'selecTed':'unselecTed'}>
                        {element}
                      </div>)}
                </section>

                <div className='contentTwoBody'>
                <div className='rbcTable'>
                  <>
                    {reportFormat==='basic'?
                    
                    <>
                    {!accountRate&& <section style={{margin:"30px 30px",
                                                    display:"flex",
                                                    justifyContent:"start",
                                                    columnGap:"25px"}}>
                                            <div className='creditSummaryClass'>
                                                Credit Account Summary Rating
                                            </div>
                                            <div className='creditSummaryClass'>
                                                {bureauState.consumerCreditGet.data.report.creditAccountSummary.rating}
                                            </div>
                                    </section>
                        }

                        <div className='header-hold'>
                            <>
                                {accountRate?
                              
                                <>
                                    

                                        <div className='Attri'>
                                          Account Types
                                        </div>

                                        <div className='currencyTitle'>
                                          Bad Accounts
                                        </div>

                                        <div className='currencyTitle'>
                                          Good Accounts
                                        </div>

                                    
                                </>
                                

                                :
                                
                                <>
                                        <div className='Attri'>
                                          Attribute
                                        </div>

                                        <div className='currencyTitle'>
                                          EUR
                                        </div>

                                        <div className='currencyTitle'>
                                          GBP
                                        </div>

                                        <div className='currencyTitle'>
                                          GHC
                                        </div>

                                        <div className='currencyTitle'>
                                          USD
                                        </div>
                            
                                </>
                                
                                
                                }
                            </>
                          
                        </div>

                        <>
                        {accountRate?
                            <div className='body'>
                      
                                {accountTypes.map(accType=>
                                    <div className='inner' key={accountTypes.indexOf(accType)}>
                                        <p className='found' >{accType.accType}</p>
                                        <p className='element'>{accType.bad}</p>
                                        <p className='element'>{accType.good}</p>
                                    </div>)
                                }
                            </div>
                            :

                            <div className='body' >
                                {

                                accountSumm.map(accSumm=>
                                    
                                    <>
                                        
                                        <div className='inner' key={accountSumm.indexOf(accSumm)}>
                                            <p className='foundAcc' >{accSumm.attribute}</p>
                                            <p className='elementAcc'>{accSumm.eur}</p>
                                            <p className='elementAcc'>{accSumm.gbp}</p>
                                            <p className='elementAcc'><span>{accSumm.ghs}</span></p>
                                            <p className='elementAcc'>{accSumm.usd}</p>
                                            
                                        </div> 
                                    </>
                                    )
                                }
                            </div>
                            }
                        </>
                    </>
                    :
                    <>
                            {
                              tabSelectedFull==='History'?
                              <HistoryBreakDown userReport={userReport}/>:
                              tabSelectedFull==='Account Monthly Payment History'?
                              <MonthlyRepaymentTable userReport={userReport}/>
                              :tabSelectedFull==='Credit Agreement Summary'?
                              <AgreementMonthlyTable userReport={userReport}/>:
                              tabSelectedFull==='Account Rating'?
                              <AccountRatingPreview accountTypes={accTypeState}/>:
                              <CreditAcctSummaryPreview accountSumm={accSumData}/>
                            }
                    </>}
                  </>
                     
                        
                        

                    </div>
                </div>
            </section>
    }

        
    }

  if(bureauState.consumerCreditGetStatus===FetchDataStatus.FAILURE){
    content = content = 
    <>
      <div className='roof'>
        <span 
            className={viewReport?'span-view-report':'span-view-history'} 
            onClick={()=>{setViewReport(false)}}>
            History
        </span>
        <span>/</span>
        <span className={viewReport?'span-view-history':'span-view-report'}>View report</span>
      </div>
      <div style={{minHeight:"150px",
                   display:'flex',
                   flexDirection:'column',
                   alignItems:'center',
                   justifyContent:"space-around",
                   backgroundColor:'white',
                   width:'70%',
                   borderRadius:'10px',
                   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                   margin:'30px auto',
                   border:'3px solid red'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>
          {bureauState.consumerCreditGetErrorMessage
          ?bureauState.consumerCreditGetErrorMessage
          :'Try again'}</p>
      </div>
    </>
    
  }
  return (
   
    <div className='report-hold'>
      <div className='roof'>
          <span 
              className={viewReport?'span-view-report':'span-view-history'} 
              onClick={()=>{setViewReport(false)}}>
              History
          </span>
          <span>/</span>
          <span className={viewReport?'span-view-history':'span-view-report'}>View report</span>
      </div>

      <p className='title-class'>{reportFormat==='basic'? 'Bureau Data for Individual':'Bureau Data for Business'}</p>
      <p style={{margin:'10px 0px'}}>{`Here's the report from the Bureau Data check`}</p>

      {content}
      {contentTwo}
    </div>

  )
}

export default BureauConsumerBasic

//