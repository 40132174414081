import React from 'react'
import Utils from '../../../../../../library/Utils'

function ScreenedBusInformation({data}) {
  return (
    <div style={{padding:'15px',boxSizing:"border-box",display:"flex",flexDirection:"column",rowGap:"20px",width:"100%"}}>
        <span style={{color:"#1F2228",fontSize:"20px",fontWeight:"700",width:"100%"}}>Personal Information</span>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Name</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.name)?data.name:'---'}</section>
            </section>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Passport ID</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.passportId)?data.passportId:'---'}</section>
            </section>
        </div>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Date of Birth</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.dob)?data.dob:'---'}</section>
            </section>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Place of Birth</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.pob)?data.pob:'---'}</section>
            </section>
        </div>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Countries Associated</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.countryName)?data.countryName:'---'}</section>
            </section>
        </div>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Aliases</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.aliases)?data.aliases[0].name:'---'}</section>
            </section>
        </div>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Government Designation</section>
                <section className='screenInfoResult'>{!Utils.isFieldEmpty(data.govDesignation)?data.govDesignation:'---'}</section>
            </section>
        </div>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
            <section style={{display:"flex",flexDirection:"column",rowGap:"5px",width:"40%"}}>
                <section className='screenInfoTitle'>Sanctions</section>
                <section className='screenInfoResult'>---</section>
            </section>
        </div>
    </div>
  )
}

export default ScreenedBusInformation