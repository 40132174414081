import React, {useState} from 'react'
import '../../../../DecisionEngine/ProceedPage/ProceedPage.css'
//import { CustomCreditScoreViews } from '../../../library/Variables';
import DecisionCard from './DecisionCard';
import { selectLoanScoreState } from '../../../../../redux/Slices/LoanScores';
import { useSelector } from 'react-redux';
import ViewScoreModal from '../../../../../components/Modals/LoanModals/ViewScoreModal'
import UploadVerificationModal from '../../../../../components/Modals/LoanModals/ViewScoreModal';
import Modal from 'react-modal'
import ScoreCardView from './ScoreCard/ScoreCardView';
import { FetchDataStatus } from '../../../../../library/Variables';
import Spinner from '../../../../../svg/Spinner/Spinner';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { generateScore,generateScoreBreakDown } from '../../../../../redux/Slices/LoanScores';
import { selectLoanFileState } from '../../../../../redux/Slices/LoanFile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ProceedSection({setPageActive, setFormTitle, data, parentCallback,user,setRefreshScore,configuration,radioSelected,accessToken,setNewScore, scoreStatus, newlyScored}) {
  const [radioSelect, setRadioSelect] = useState(radioSelected);
  const loanScoreStatus = useSelector(selectLoanScoreState)
  const loanFileState = useSelector(selectLoanFileState)
  const permissions = user.user.permissions
  const [modalIsOpen, setIsOpen] = useState(false)
  const [selectedCard,setSelectedCard] = useState('')
  const {key} = useParams()
  const dispatch = useDispatch()
  let content = <Spinner/>
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  const firstScore = ()=>{
    if(permissions.generateLoanCreditScore){
      if(scoreStatus){
        if(loanFileState.loanFileData.data.canGenerateScore === true){
          openModal()
          dispatch(generateScore({loanKey:key,cardDefinitionKey:selectedCard,accessToken}))
        }
        else{
          dispatch(generateScoreBreakDown({accessToken,loanKey:key}))
        }
      }
      else{
        openModal()
        dispatch(generateScore({loanKey:key,cardDefinitionKey:selectedCard,accessToken}))
      }
    }

    else{

      toast.info('You are not permitted to generate a score', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

}

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };
  // const cardList = [
  //   {title: 'Micro Loans', subTitle: 'This scorecard is for micro loans'},
  //   {title: 'Agricultural Loans', subTitle: 'This scorecard is for agricultural loans'},
  //   {title: 'Empowerment Loans', subTitle: 'This scorecard is for empowerment loans'},
  // ]


 /**
  *  const handleCardClicked = (id) => {
    parentCallback(CustomCreditScoreViews.FORM, id)
  }
  */
 

 if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.INITIAL ){

  content =  <div className='de-sub-page-wrap'>
  <div className='subpage-header'>
      <h3>Select a card to proceed</h3>
  </div>

  <div style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
      {
        data.map((item, id) => {
          return (
            <DecisionCard 
              key={item.scorecardDefinitionKey + "-" + id}
              id={id}
              title={item.scorecardDefinitionName}
              value={item.scorecardDefinitionName}
              subTitle={""}
              mainKey={item.scorecardDefinitionKey}
              accessToken={accessToken}
              openModal={openModal}
              scoreStatus={scoreStatus}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              
            />
          )
        })
      }
  </div>

  <div className='buttonHold'>
    {selectedCard!==''? <section onClick={()=>firstScore()}>Proceed</section>:''}
  </div>
  <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
    contentLabel="create a score" >
    <ViewScoreModal loanKey={key} accessToken={accessToken}  closeModal={closeModal} />
  </Modal> 
</div>
}

if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.FETCHING){

    content = <Spinner/>
}

if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.SUCCESS){
  
  setNewScore(true)
  content = <ScoreCardView user={user} accessToken={accessToken} configuration={configuration} setRefreshScore={setRefreshScore}/>
}

if(loanScoreStatus?.scoreBreakDownStatus === FetchDataStatus.FAILURE){
   
  content = 
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanScoreStatus.errorScoreBreakDown}</p>
      </div>
            
}


  return (
    <>
      {content}  
    </>
  )
}

export default ProceedSection

// onClick={handleCardClicked}