import React from "react";

import CreatableSelect from "react-select/creatable";
import ErrorDisplay from "./ErrorDisplay";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export const MultiSelectInput = ({
  onChange,
  value,
  prevValues,
  name,
  controlStyle,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        onChange([...prevValues, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#DFE8FF" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 2px #DFE8FF" : provided.boxShadow,
      padding: "10px",
      ...controlStyle?.(provided, state),

      // minHeight: "50px",
    }),
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        // onChange={(newValue) => setValue(newValue)}
        onChange={(newValue) => onChange(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Enter value and press enter..."
        value={value}
        styles={customStyles}
      />
      <ErrorDisplay fieldName={name} />
    </>
  );
};
