import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm,useFormContext } from 'react-hook-form'
import Spinner from '../../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { getBankListID,
         getAccountDataID,
         selectKycEntireDataState,
         resetBankAccounts,resetDataStatus } from '../../../../../../redux/Slices/IdVerificationKenya'
import { FetchDataStatus,PostDataStatus } from '../../../../../../library/Variables'
import successImg from '../../../../../../assests/icons/amlFound.png'
import errorImg from '../../../../../../assests/icons/amlEmpty.png'
import './bankAccount.css'
import { getReportDetail,resetReport } from '../../../../../../redux/Slices/IdVerificationKenya'
import { getReportDetailKyb,resetReportKyb } from '../../../../../../redux/Slices/IdVerificationKenyaKyb'
import arrowDown from '../../../../../../assests/icons/arrowDownIcon.png'



function BankAccountModal({accessToken,keyFromRoute,setKycToShow,inputStyle,setViewReport,setVerificationType}) {
  const dispatch = useDispatch()
  const kycState = useSelector(selectKycEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [bankId,setBankId] = useState('')

  const loadStyle = {
    width: "100%",
    marginTop: "10px",
    border: 'none',
    padding: '0px 24px 0px 24px',
    boxSizing: "border-box",
    backgroundColor: '#d3d3d3',
    outline: "none",
    height: '40px',
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center"
  };

  
  useEffect(()=>{
    dispatch(getBankListID(accessToken))
  },[dispatch,accessToken])


  const customStyles = {
    content: {
        transition: "0.125s ease-in-out",
        transform: "scale(1)",
        height: "50%",
        width: "50%",
        top: "25%",
        left: "33%",
        padding: "0 0 0 0",
        overflowY: "scroll",
    },
    overlay: {
        zIndex: 1000,
        position: "absolute"
    }
    };


  const onSubmit = (formData) => {
  const obj = {bankAccount:formData.bankAccount,
                statementKey: null,
                bankId:formData.bankId,
                accessToken}         
  
   dispatch(getAccountDataID(obj))
  }


  const getDetails = (token,uuid,type)=>{

    setKycToShow(null);
    setIsOpen(false);
    dispatch(resetDataStatus());
    dispatch(resetBankAccounts())

    if(['CAC','TIN'].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
    }
    else if(['IDNUMBER',
            'ALIENIDNUMBER',
            'DRIVER_LICENCE_KENYA',
            'KRAPIN',
            'PLATE_NUMBER',
            'PHONE_NUMBER',
            'ACCOUNT_NUMBER'
             ].includes(type)){
        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReport())
        dispatch(getReportDetail({type,uuid,accessToken:token}))
    }
}

  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div style={{padding:"20px",height:"400px",boxSizing:"border-box"}}>
       <p className='title-class'>Bank Account Verification</p>
       <section style={{display:"flex",flexDirection:"column",justifyContent:'space-around',height:"90%"}}>
          <div style={{}}>
              <p>Select bank</p>
              {kycState.bankNamesStatus === FetchDataStatus.SUCCESS ? (<select style={inputStyle}{...register("bankId", { required: "Bank Name is required" })} onChange={(e) => setBankId(e.target.value)}>
                          <option value="">Select an option</option>
                          {kycState.bankNames.map((element, i) => {
                            return <option value={element.id} key={i}>{element.name}</option>
                          })}
              </select>):
              <div style={loadStyle}>
                  <span>loading...</span>
                  <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img src={arrowDown}/>
                  </span>
              </div>}
              {errors.bankAccount && <span className="text-error">This field is required</span>}
          </div>

          <div style={{}}>
              <p>Input Bank Account Number</p>
              <input  style={inputStyle}{...register("bankAccount", { required: "Bank Account is required" })} />
              <span className="text-error">{errors?.bankId?.message}</span>
          </div>
          <label className="bureau-credit-check-accept-check">
              <input type="checkbox" {...register("chargeAccept",
              { required: "You must agree to the charge before generating a report" })} />
              <p>By checking this box, you are agreeing to the call fee</p>
          </label>
          {errors.chargeAccept && <span className="text-error">{errors.chargeAccept?.message}</span>}

          <div className='modal-initial-button-hold'>
              <span className='cancel' onClick={()=>{
                                          setIsOpen(false);
                                          setKycToShow(null);
                                          dispatch(resetBankAccounts())}}
                                          >
                  Cancel
              </span>
              <button className='check' type="submit" >Check</button>
          </div>
       </section>
       
   </div>
  </form>

  const ModalLoading = <div style={{height:"300px"}}>
    <Spinner></Spinner>
    <p style={{textAlign:'center',color:'#2A38A4',fontSize:"24px",fontWeight:"700"}}>Verification in progress...</p>
    <p style={{textAlign:'center',color:"#363C4F",fontSize:"16px",fontWeight:'400'}}>Hold on. This will only take a few seconds</p>
  </div>

  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImg}/>
    </div>
    <p style={{textAlign:'center',
                fontSize:"24px",
                 fontWeight:"700",
                 color:'#E40000'}}>
                 {kycState.BankAccountErrorMessage}
    </p>

    <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
          Click Done to see
      </p>

    <div className='modal-initial-button-hold'>
           <span className='failed' onClick={()=>{
                                                    setIsOpen(false);
                                                    setKycToShow(null);
                                                    dispatch(resetBankAccounts())}}
                                                    >
              Cancel
          </span>
           
       </div>
  </div>

const ModalSuccess = <div className='modal-error'>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={successImg}/>
      </div>
      <p style={{textAlign:'center',
                 color:"#2A38A4",
                 fontSize:"24px",
                 fontWeight:"700"}}>Bank Account checked successfull</p>
      <p style={{color:'#363C4F',
                fontSize:"16px",
                fontWeight:"400" }}>
          Click Done to see
      </p>
      <div className='modal-initial-button-hold'>
            <span className='check' onClick={()=>getDetails(accessToken,kycState?.BankAccountExtraction?.uuid,
                                                            kycState.BankAccountExtraction?.type)}>Done</span>
            
        </div>
      </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kycState.BankAccountStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kycState.BankAccountStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kycState.BankAccountStatus===PostDataStatus.SUCCESS){
            //props.setKycToShow(null)
            //dispatch(resetKenyaKycData())
            setModal(ModalSuccess)
            
        }

        if(kycState.BankAccountStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }
  },[kycState])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default BankAccountModal