import React from 'react'
import './DecisionEngineCard.css'

function DecisionEngineCard({title, subTitle, onClick, id}) {
  return (
    <>
        <div className='selfCards' onClick={() => onClick(id)} >
            <div style={{display:"flex",flexDirection:"column",height:"100%",justifyContent:"space-around"}}>
                <div style={{display:"flex",alignItems:"center",columnGap:"5px"}}>
                    <div className='left-img-wrap'>
                        <img src='/assets/icons/engine-icon.svg' />
                    </div>

                    <div className='right-img-wrap'>
                        <img src='/assets/icons/active-radio-btn.svg' />
                    </div>
                </div>

                <div className='bottom-section'>
                    <h4>{title}</h4>

                    <p>{subTitle}</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default DecisionEngineCard