
import { useMemo, useState } from "react";
import { Table } from "../../../../../../components/Table/Table";
import Utils from "../../../../../../library/Utils";


function AccountInformationSerializer({data,configuration}) {
    const bodyHolder = []
    let tableData = [];
    const [showReport,setShowReport] = useState(false)
    const [reportIndex,setReportIndex] = useState(0)
    data.forEach((newData,index)=>{
        bodyHolder.push([
            {value: newData.account_number,
             key:'Account Number'
            },
            {value: newData.account_status,
                key:'Account Status'
            },
            {value: Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, newData.current_balance),
             key:'Current Balance'
            },
            {value: newData.days_in_arrears,
             key:'Days in Arrears'
            },
            {value: newData.delinquency_code,
            key:'Delinquency Code'
            },
            {value: newData.highest_days_in_arrears,
            key:'Highest Day in Arrears'
            },
            {value: Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, newData.last_payment_amount),
            key:'Last Payment Amount'
            },
            {value: Utils.formatLongDateAndTime(newData.last_payment_date),
            key:'Last Payment Date'
            },
            {value: Utils.formatLongDateAndTime(newData.loaded_at),
             key:'Loaded At'
            },
            {value: Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol,newData.original_amount),
             key:'Original Amount'
            },
            {value: Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol,newData.overdue_balance),
             key:'Overdue Balance'
            }
            
          ])
    })


    data.forEach((form,index) => {
        tableData.push({
          account_number: form.account_number,
          account_status: form.account_status,
          current_balance: Utils.formatCurrency(configuration.currency.walletFormat, configuration.currency.walletSymbol, form.current_balance) ,
          date_opened:Utils.formatLongDateAndTime(form.date_opened),
          last_payment_date: Utils.formatLongDateAndTime(form.last_payment_date) 
          
        })
      })

      const openReport = (data)=>{
        setReportIndex(data);
        setShowReport(true)
      }

      const theadersStatements = useMemo(() => [
        { 
          Header: 'Account Number',
          accessor: 'account_number'
        },
        { 
            Header: 'Account Status',
            accessor: 'account_status'
          },

          { 
            Header: 'Current Balance',
            accessor: 'current_balance'
          },

          { 
            Header: 'Date Opened',
            accessor: 'date_opened'
          },

          { 
            Header: 'Last Payment Date',
            accessor: 'last_payment_date'
          },

        {
          id: 'button-column',
          disableSortBy: true,
          accessor: 'indexVal',
          Cell: ({row}) => {
            
            //let link = permissions.viewLoanData?`/loanApproval/${data.row.original.key}`:'/loanApproval';
            return (
              <div  onClick={()=>openReport(row.index)} style={{backgroundColor:'rgba(64, 123, 255, 1)',
                                                                display:"flex",
                                                                alignItems:'center',
                                                                cursor:'pointer',
                                                                justifyContent:"center",
                                                                color:'white',
                                                                padding:'10px 0px',
                                                                borderRadius:'4px',
                                                                textAlign:'center'}}>
                    View
              </div>
            );
          }
        }]
      )






  return (
    <>
       {showReport? 
            <>
                <div className='roof'>
                    <span 
                        className={showReport?'span-view-report':'span-view-history'} 
                        onClick={()=>{setShowReport(false)}}>
                        History
                    </span>
                    <span>/</span>
                    <span className={showReport?'span-view-history':'span-view-report'}>View report</span>
                </div>

                <section>
                    {bodyHolder[reportIndex].map((element,index)=>
                    <div key={index} style={{display:'flex',justifyContent:'space-between',marginTop:'25px'}}>
                        <section>
                            {element.key}
                        </section>

                        <section>
                            {element.value}
                        </section>
                    </div>)}
                </section>
            </>
            :
            <Table tableClass="statement-type-table" 
                pagination={true} pageSize={[10]} 
                data={tableData} 
                columns={theadersStatements}
                tableExtra={false} 
                tableFilter={true} 

            />
            
        
        }
    </>
  )
}

export default AccountInformationSerializer