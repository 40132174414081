import React from 'react'
import { selectLoanState } from '../../../redux/Slices/Loan'
import { PostDataStatus } from '../../../library/Variables';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner';
import { resetDocFileUpload,selectSupportDocs } from '../../../redux/Slices/SupportDocs';
import { resetDeleteDoc,resetDocsFetch } from '../../../redux/Slices/SupportDocs';



function DeleteSupportModal(props) {
    const dispatch = useDispatch()
    const supportState = useSelector(selectSupportDocs)
    const accessToken = props.accessToken

    let content = null;
    const params = {
        files:props.imgFile,
        type:'support',
        accessToken,
    }

  

    if( supportState.deleteDocStatus===PostDataStatus.INITIAL){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Deleting...</p>
                    <Spinner/>
            </div>
    }

    if(supportState.deleteDocStatus===PostDataStatus.FETCHING){
        content = <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
                    <p style={{textAlign:"center",fontSize:'16px'}}>Deleting...</p>
                    <Spinner/>
            </div>
            
    }

    if(supportState.deleteDocStatus===PostDataStatus.SUCCESS){
       
        
        content =   
        <div style={{display:"flex",flexDirection:"column",gap:'10px',alignItems:"center",justifyContent:"space-between"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Deleted</p>
            <div className='buttonHold'>   
                <section onClick={()=>{props.close();dispatch(resetDeleteDoc());dispatch(resetDocsFetch())}}>close</section>
            </div> 
        </div>
    }

    if(supportState.deleteDocStatus===PostDataStatus.FAILURE){
        content = <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around"
        ,backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        ,margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{supportState.deleteDocResponseErrorMessage}</p>

          <div className='buttonHold'>   
                <section onClick={()=>{props.close();dispatch(resetDeleteDoc())}}>close</section>
            </div> 
    </div>
    }

  return (
    <div style={{minHeight:'120px',backgroundColor:'white',display:"flex",justifyContent:"center",alignItems:"center",margin:"0px auto",width:"90%",padding:"20px",boxSizing:"border-box"}}>
        {content}
    </div>
  )
}

export default DeleteSupportModal