import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../../../api/Httpclient";
import { FetchDataStatus, PostDataStatus } from "../../../../library/Variables";

let trackNo

 
  export const getAmlDetails = createAsyncThunk('kenyaAml/AML', async (params) => {
    
   
    const data = JSON.stringify(params.data)
    
   const amlResponse = await client.post('/verify/aml-screen',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });
  
    return amlResponse;
   }
  );


  export const getAmlBusinessDetails = createAsyncThunk('kenyaAml/businessAML', async (params) => {
    
   
    const data = JSON.stringify(params.data)
    
   const amlResponse = await client.post('/verify/aml-screen/business',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });
  
    return amlResponse;
   }
  );



  
  export const getSpecificAml = createAsyncThunk('kenyaAml/particularAml',async (params)=> {
    const amlSpecificData = await client.get(`/verify/aml-screen/${params.key}`,
    {
      "headers":
      {
       "Authorization": "Bearer " + params.accessToken,
      
      }
    });
     return amlSpecificData.data;
  })


  export const getSpecificAmlBusiness = createAsyncThunk('kenyaAml/particularBusinessAml',async (params)=> {
    const amlSpecificData = await client.get(`/verify/aml-screen/business/info/${params.key}`,
    {
      "headers":
      {
       "Authorization": "Bearer " + params.accessToken,
      
      }
    });
     return amlSpecificData.data;
  })




export const getAmlDataHistory = createAsyncThunk('kenyaAml/KybHistoryData',async (params)=> {
  const HistoricalData = await client.get('/verify/history',
  {
    "headers":
    {
     "Authorization": "Bearer " + params.accessToken,
    
    }
  });
   return HistoricalData.data;
})


export const amlDataSlice = createSlice({
  name: 'kenyaAmlExtraction',
  initialState: {
    activeExtractions: [],
    standAloneStatus: FetchDataStatus.INITIAL,
    standAloneErrorMessage:null,


    amlExtraction: null,
    amlExtractionStatus: PostDataStatus.INITIAL,
    amlExtractionisError: false,
    amlExtractionErrorMessage: null,

    amlParticipantExtraction: null,
    amlParticipantExtractionStatus: FetchDataStatus.INITIAL,
    amlParticipantExtractionisError: false,
    amlParticipantExtractionErrorMessage: null,


    amlBusParticipantExtraction: null,
    amlBusParticipantExtractionStatus: FetchDataStatus.INITIAL,
    amlBusParticipantExtractionisError: false,
    amlBusParticipantExtractionErrorMessage: null,


    amlBusinessExtraction: null,
    amlBusinessExtractionStatus: PostDataStatus.INITIAL,
    amlBusinessExtractionError: false,
    amlBusinessExtractionErrorMessage:null,
  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetAmlParticipant: (state)=>{
      state.amlParticipantExtraction = null;
      state.amlParticipantExtractionStatus = FetchDataStatus.INITIAL;
      state.amlParticipantExtractionisError = false;
      state.amlParticipantExtractionErrorMessage = null;
    },

    resetAmlBusParticipant: (state)=>{
      state.amlBusParticipantExtraction = null;
      state.amlBusParticipantExtractionStatus = FetchDataStatus.INITIAL;
      state.amlBusParticipantExtractionisError = false;
      state.amlBusParticipantExtractionErrorMessage = null;
    },

    resetIndividualAmlCall: (state)=>{
      state.amlExtraction = null;
      state.amlExtractionStatus = PostDataStatus.INITIAL;
      state.amlExtractionErrorMessage = null;
    },

    resetBusinessAmlCall: (state)=>{
      state.amlBusinessExtraction = null;
      state.amlBusinessExtractionStatus = PostDataStatus.INITIAL;
      state.amlBusinessExtractionErrorMessage = null;
    }
  },
  
  extraReducers(builder) {

  builder.addCase(getAmlDataHistory.pending, (state)=>{
    state.standAloneStatus = FetchDataStatus.FETCHING
  })
  .addCase(getAmlDataHistory.fulfilled,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.SUCCESS
    state.activeExtractions = [...action.payload]
  })
  .addCase(getAmlDataHistory.rejected,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.FAILURE
    state.standAloneErrorMessage = action.error.message
  })


  .addCase(getAmlDetails.pending, (state)=>{
    state.amlExtractionStatus = PostDataStatus.FETCHING
  })
  .addCase(getAmlDetails.fulfilled,(state,action)=>{
    state.amlExtractionStatus = PostDataStatus.SUCCESS
    state.amlExtraction = action.payload
  })
  .addCase(getAmlDetails.rejected,(state,action)=>{
    state.amlExtractionStatus = PostDataStatus.FAILURE
    state.amlExtractionErrorMessage = action.error.message
  })


  .addCase(getSpecificAml.pending, (state)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.FETCHING
  })
  .addCase(getSpecificAml.fulfilled,(state,action)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.SUCCESS
    state.amlParticipantExtraction = action.payload
  })
  .addCase(getSpecificAml.rejected,(state,action)=>{
    state.amlParticipantExtractionStatus = FetchDataStatus.FAILURE
    state.amlParticipantExtractionErrorMessage = action.error.message
  })


  .addCase(getSpecificAmlBusiness.pending, (state)=>{
    state.amlBusParticipantExtractionStatus = FetchDataStatus.FETCHING
  })
  .addCase(getSpecificAmlBusiness.fulfilled,(state,action)=>{
    state.amlBusParticipantExtractionStatus = FetchDataStatus.SUCCESS
    state.amlBusParticipantExtraction = action.payload
  })
  .addCase(getSpecificAmlBusiness.rejected,(state,action)=>{
    state.amlBusParticipantExtractionStatus = FetchDataStatus.FAILURE
    state.amlBusParticipantExtractionErrorMessage = action.error.message
  })


  .addCase(getAmlBusinessDetails.pending, (state)=>{
    state.amlBusinessExtractionStatus = PostDataStatus.FETCHING
  })
  .addCase(getAmlBusinessDetails.fulfilled,(state,action)=>{
    state.amlBusinessExtractionStatus = PostDataStatus.SUCCESS
    state.amlBusinessExtraction = action.payload
  })
  .addCase(getAmlBusinessDetails.rejected,(state,action)=>{
    state.amlBusinessExtractionStatus = PostDataStatus.FAILURE
    state.amlBusinessExtractionErrorMessage = action.error.message
  })


  
  }
})


export const { addToExtractedData,
               resetAmlParticipant,
               resetAmlBusParticipant,
               resetBusinessAmlCall,
               resetIndividualAmlCall} = amlDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;

export const selectKenyaAmlExtractions = state => state.kenyaAmlExtraction


export default amlDataSlice.reducer;


/**
 * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    let mainArray = action.payload
    let newArray = []
    const mimeType = 'data:image/jpeg;base64,'
    for(let i=0;i<mainArray.length;i++){
      const newObj = mainArray[i]
      let img;
      let signature
      if(action.payload[i]['base64Image']){
        img = mimeType + action.payload[i]['base64Image']
      }
      
      if(action.payload[i]['photo']){
        img = action.payload[i]['photo']
      }
      if(action.payload[i]['signature']){
        signature = action.payload[i]['signature']
      }
      else{
        img = null;
      }
      
      const mainObj = {}
      delete newObj.base64Image;
      delete newObj.photo;
      delete newObj.signature;
      mainObj.data = newObj
      mainObj.kycType = action.payload[i]['verificationType']
      mainObj.status = 'FOUND'
      mainObj.img = img
      mainObj.signature = signature
      newArray.push(mainObj)
    }

    state.activeExtractions = [...newArray]
    state.historyStatus = PostDataStatus.SUCCESS;
  })
 */