import React, { useState } from 'react';
import DecisionEngineCard from '../../../components/DecisionEngineCard/DecisionEngineCard';
import { CustomCreditScoreViews } from '../../../library/Variables';
import './ProceedPage.css';

function ProceedPage({setPageActive, setFormTitle, data, parentCallback, radioSelected}) {
  const [radioSelect, setRadioSelect] = useState(radioSelected);

  const handleCardClicked = (id) => {
    parentCallback(CustomCreditScoreViews.FORM, id)
  }

  return (
    <>
        <div className='de-sub-page-wrap'>
            <div className='subpage-header'>
                <h3>Select a card to proceed</h3>
            </div>

            <section style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px',marginTop:"25px"}}>
            {
                  data.map((item, id) => {
                    if(item.customParameters.length >0){
                    
                    return (
                      <DecisionEngineCard 
                        key={item.scorecardDefinitionKey + "-" + id}
                        id={id}
                        title={item.scorecardDefinitionName}
                        value={item.scorecardDefinitionName}
                        subTitle={""}
                        onClick={handleCardClicked}
                      />
                    )
                    }
                  })
                }
            </section>
        </div>
    </>
  )
}

export default ProceedPage