import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Label, TextField } from "../form";
import Spinner from "../loader";
import styles from "./index.module.css";

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const FileUploadDrop = ({
  files,
  handleFile,
  removeFile,
  showPreview,
  handleSubmit,
  loading,
  showSubmit,
  excel,
  json
}) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const transformFiles = acceptedFiles.map(async (file) => {
        const base64String = await getBase64(file);
        return {
          file,
          base64String,
        };
      });
      const response = await Promise.all(transformFiles);
      handleFile(response);
    },
    [handleFile]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: excel?{
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
    }:

    json?{
      "application/json": [".json"],
    }
    :
    {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    multiple: false,
  });

  return (
    <div style={{ width: "100%" }}>
      <div {...getRootProps()} className={styles.uploadWrapper}>
        <input {...getInputProps()} />
        <span style={{ marginBottom: "20px" }}>
          <svg
            width="29"
            height="34"
            viewBox="0 0 29 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_6903_75868"
              // style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="29"
              height="34"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 0.351562H28.4203V33.4407H0V0.351562Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_6903_75868)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.62178 2.85156C4.86011 2.85156 2.56678 5.08823 2.50178 7.84656V25.7116C2.44178 28.5466 4.67511 30.8799 7.48345 30.9416H20.9551C23.7368 30.8416 25.9384 28.5466 25.9201 25.7199V10.8982L18.1968 2.85156H7.64178H7.62178ZM7.64178 33.4416H7.43178C3.24511 33.3516 -0.0882195 29.8716 0.00178047 25.6849V7.81656C0.100114 3.68156 3.51345 0.351562 7.61678 0.351562H7.64678H18.7284C19.0684 0.351562 19.3934 0.489896 19.6301 0.734896L28.0734 9.5299C28.2951 9.76156 28.4201 10.0732 28.4201 10.3949V25.7116C28.4468 29.8932 25.1868 33.2916 20.9984 33.4416H7.64178Z"
                fill="#1162DC"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.163 11.9759H22.573C19.5213 11.9676 17.043 9.48094 17.043 6.43427V1.58594C17.043 0.895937 17.603 0.335938 18.293 0.335938C18.983 0.335938 19.543 0.895937 19.543 1.58594V6.43427C19.543 8.1076 20.903 9.47094 22.5763 9.47594H27.163C27.853 9.47594 28.413 10.0359 28.413 10.7259C28.413 11.4159 27.853 11.9759 27.163 11.9759Z"
              fill="#1162DC"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5703 24.834C12.8803 24.834 12.3203 24.274 12.3203 23.584V13.5156C12.3203 12.8256 12.8803 12.2656 13.5703 12.2656C14.2603 12.2656 14.8203 12.8256 14.8203 13.5156V23.584C14.8203 24.274 14.2603 24.834 13.5703 24.834Z"
              fill="#1162DC"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5694 24.8308C13.2377 24.8308 12.9177 24.6992 12.6844 24.4625L8.77607 20.5392C8.2894 20.0492 8.29107 19.2575 8.7794 18.7708C9.2694 18.2842 10.0611 18.2842 10.5477 18.7742L13.5694 21.8108L16.5911 18.7742C17.0777 18.2842 17.8694 18.2842 18.3594 18.7708C18.8477 19.2575 18.8494 20.0492 18.3627 20.5392L14.4544 24.4625C14.2211 24.6992 13.9011 24.8308 13.5694 24.8308Z"
              fill="#1162DC"
            />
          </svg>
        </span>
        <div className={styles.uploadDesc}>
          <p className={styles.dragTitle}>Drag & Drop your file here</p>
          <p classname={styles.fileDropMeta}>
            or <span className={styles.uploadInput}>Select file here</span>
          </p>
          <p className={styles.uploadLimitDesc}>YOU CAN UPLOAD MORE THAN ONE FILE</p>
          <p className={styles.uploadLimitDesc}>{` FILE SUPPORTED: ${excel?'XLS or XSLX':json?'JSON':'PNG, JPG, JPEG OR PDF'}`
          
          }</p>
        </div>
      </div>

      {showPreview && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          {files && files.length
            ? files.map((item, index) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      height: "100px",
                      width: "100px",
                      border: "1px solid #407BFF",
                      padding: "10px",
                    }}
                  >
                    <span
                      onClick={() => removeFile(index)}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        right: 0,
                        zIndex: 999,
                      }}
                    >
                      <IconClose width="20px" height="20px" />
                    </span>

                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        objectFit: "cover",
                        position: "absolute",
                      }}
                      src={item.base64String}
                      alt={item.file?.name}
                    />
                  </div>
                );
              })
            : null}
        </div>
      )}

      {files && files.length ? (
        <div className={styles.selectedWrapper}>
          <h4 className={styles.selectedHeader}>Selected</h4>

          <div className={styles.formWrapper}>
            {files.map((item, index) => (
              <div>
                <div className={styles.inputWrapper}>
                  <Label>File name</Label>
                  <div className={styles.formInput}>
                    <span>
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.1935 27.0443H14.1602"
                          stroke="#407BFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M26.1935 20.0677H14.1602"
                          stroke="#407BFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.7518 13.1068H14.1602"
                          stroke="#407BFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.5143 4.58594C26.5143 4.58594 13.7193 4.5926 13.6993 4.5926C9.09931 4.62094 6.25098 7.6476 6.25098 12.2643V27.5909C6.25098 32.2309 9.12098 35.2693 13.761 35.2693C13.761 35.2693 26.5543 35.2643 26.576 35.2643C31.176 35.2359 34.026 32.2076 34.026 27.5909V12.2643C34.026 7.62427 31.1543 4.58594 26.5143 4.58594Z"
                          stroke="#407BFF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <div className={styles.textFieldInput}>
                      <TextField
                        // readOnly
                        value={item.file?.name}
                        placeholder="Name"
                        required={true}
                        name="Name"
                        inputIsFullWidth={true}
                        inputContainer={styles.inputContainer}
                        className={styles.textContainer}
                      />
                    </div>

                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => removeFile(index)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.3248 9.46875C19.3248 9.46875 18.7818 16.2037 18.4668 19.0407C18.3168 20.3957 17.4798 21.1898 16.1088 21.2148C13.4998 21.2618 10.8878 21.2648 8.27979 21.2098C6.96079 21.1828 6.13779 20.3788 5.99079 19.0478C5.67379 16.1858 5.13379 9.46875 5.13379 9.46875"
                          stroke="#E40000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.708 6.24219H3.75"
                          stroke="#E40000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4406 6.239C16.6556 6.239 15.9796 5.684 15.8256 4.915L15.5826 3.699C15.4326 3.138 14.9246 2.75 14.3456 2.75H10.1126C9.53358 2.75 9.02558 3.138 8.87558 3.699L8.63258 4.915C8.47858 5.684 7.80258 6.239 7.01758 6.239"
                          stroke="#E40000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                

                
              </div>
            ))}

            {showSubmit && (
              <div className={styles.btnWrapper}>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={styles.submitBtn}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner visible={loading} color="#fff" size={20} />
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

function IconClose(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
      />
    </svg>
  );
}

export default FileUploadDrop;
