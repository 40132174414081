import { toast } from "react-toastify";
import "../index.css";

import storage from "../utils/storage";

// Request interceptor
function requestInterceptor(url, options) {
  const token = storage.getToken();

  if (token) {
    options.headers["Authorization"] = `Bearer ${token}`;
  }

  return { url, options };
}

// Response interceptor
async function responseInterceptor(response) {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }

    const error = await response.json();

    if (response.status === 400) {
      const errors = error?.errors;
      const errorMessagesArr = Object.values(errors);

      errorMessagesArr.forEach((error) => {
        toast.error(error.toString(), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            backgroundColor: "#1C256D",
            color: "#fff",
          },
        });
      });
    } else {
      const message = error.message;
      toast.error(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#1C256D",
          color: "#fff",
        },
      });
      throw new Error(message);
    }
  }
  return response.json();
}

const BASE_URL = process.env.REACT_APP_FRAUD_DETECT_API_BASE_URL;

function get(url, headers = {}) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "GET",
    headers: { ...headers },
  });
}

function post(url, body, headers = {}) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "POST",
    headers: { ...headers },
    body,
  });
}

function put(url, body, headers = {}) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "PUT",
    headers: { ...headers },
    body,
  });
}

function del(url, headers = {}) {
  return fetchWithInterceptors(`${BASE_URL}${url}`, {
    method: "DELETE",
    headers: { ...headers },
  });
}

async function fetchWithInterceptors(url, options) {
  const modifiedOptions = requestInterceptor(url, options);

  const response = await fetch(modifiedOptions.url, modifiedOptions.options);
  const modifiedResponse = await responseInterceptor(response);
  return modifiedResponse;
}
export const clientApi = {
  get,
  post,
  put,
  delete: del,
};
