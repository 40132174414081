import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from "react-modal"
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import arrowToLeft from '../../../../assests/icons/arrowToLeft.png'
import selectedIcon from '../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../assests/icons/nonSelected.png'
import Utils from '../../../../library/Utils'
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables'
import { defineScoreCardName, getSelfServiceCustomParams, getSelfServiceCustomParamsByKey, selectSelfServiceState } from '../../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../../svg/Spinner/Spinner'
import CreateSelfScoreCard from '../../Modals/CreateSelfScoreCard'
import RulesModal from '../../Modals/RulesModal'
import DefineParameters from '../DefineParameters/DefineParameters'
import './definescorecard.css'
import ParameterList from './DefinitionComponents/ParameterList'
import ScoreDefinitionInitial from './DefinitionComponents/ScoreDefinitionInitial'
import ScoreDefinitionSuccess from './DefinitionComponents/ScoreDefinitionSuccess'



function DefineScoreCard({setSection,accessToken,setCustomDecisionCard,customDecisionCard,setShowCardCreated,setParamMetaData,setParamName,mainParameterKey,paramName,setMainParameterKey,mainScoreCardKey,cardState,setCardState}) {
    const [toggleState,setToggleState] = useState('POINTS')
    const [parameterType,setParameterType] = useState('')
    const dispatch = useDispatch()
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [ParamContent,setParamContent] = useState(<Spinner/>)

    const [selfScoreCardIsOpen, setSelfScoreCardIsOpen] = useState(false);
    const [createParamIsOpen, setcreateParamIsOpen] = useState(false);
    const [createRuleIsOpen, setcreateRuleIsOpen] = useState(false);
    
      function afterOpenModal() { }
    
      function closeSelfScoreCardModal() {
              setSelfScoreCardIsOpen(false)
    
        //dispatch(resetDownload());
      }

      function closeParamCreateModal() {
        setcreateParamIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeRuleCreateModal() {
            setcreateRuleIsOpen(false)

        //dispatch(resetDownload());
        }
    
      const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '33%',
          width: '80%',
          top: '33%',
          left: '33%',
          padding: '20px',
          boxSizing:'border-box',
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };


  

    let cardObj
    const saveScoreCard = ()=>{
        const allFields = getValues()
        cardObj = {
                    name:allFields.scoreName,
                    baseScore:parseInt(allFields.baseScore),
                    isCustomDecisionEngine:true,
                    type: toggleState,
                    isCustomDecisionEngine: customDecisionCard}
        setCardState(cardObj)

        if(!Utils.isFieldEmpty(cardObj?.name) && !Utils.isFieldEmpty(cardObj?.baseScore) 
            && !Utils.isFieldEmpty(cardObj?.isCustomDecisionEngine)){
                dispatch(defineScoreCardName({data:cardObj,accessToken}))
        }
        else{
            toast.info('Fill all neccessary Fields.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
    }
    const selfScoreDef = useSelector(selectSelfServiceState)

    const scoreDefSuccess = <ScoreDefinitionSuccess cardState={cardState} setToggleState={setToggleState} 
                            selectedIcon={selectedIcon} unselectedIcon={unselectedIcon} setSection={setSection}/>

    const scoreDef = <ScoreDefinitionInitial toggleState={toggleState} saveScoreCard={saveScoreCard} 
                        selectedIcon={selectedIcon} unselectedIcon={unselectedIcon} 
                        setToggleState={setToggleState} register={register} setSection={setSection}
                        setCustomDecisionCard={setCustomDecisionCard} customDecisionCard={customDecisionCard}
                        />
                       
    const [scoreCardDisplay,setScoreCardDisplay] = useState(scoreDef)


    
 
    useEffect(()=>{
        if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.INITIAL){
            setScoreCardDisplay(scoreDef)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.FETCHING){
            setSelfScoreCardIsOpen(true)
            setScoreCardDisplay(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
            setScoreCardDisplay(scoreDefSuccess)
        }
    },[selfScoreDef.selfServiceCardNameStatus,toggleState,customDecisionCard])


    useEffect(()=>{
        if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
            dispatch(getSelfServiceCustomParams({accessToken}))
        }
        
    },[selfScoreDef.selfServiceCardNameStatus])


    useEffect(()=>{
        if(selfScoreDef.selfServiceParametersByKeyStatus === FetchDataStatus.INITIAL && selfScoreDef.selfServiceCardNameStatus===PostDataStatus.SUCCESS ){
            dispatch(getSelfServiceCustomParamsByKey({accessToken,key:selfScoreDef.selfServiceCardRepsonse.key}))
        }
        
    },[selfScoreDef.selfServiceParametersByKeyStatus,selfScoreDef.selfServiceCardNameStatus])


    useEffect(()=>{
        if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.INITIAL){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.FETCHING){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.SUCCESS){
            const Content = <ParameterList setSection={setSection} 
                                           selfScoreDef={selfScoreDef}
                                           setcreateParamIsOpen={setcreateParamIsOpen}
                                           setcreateRuleIsOpen={setcreateRuleIsOpen}
                                           customDecisionCard={customDecisionCard}
                                           setShowCardCreated={setShowCardCreated}
                                           setParamMetaData={setParamMetaData}
                                           />
            setParamContent(Content)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.FAILURE){
            const Content = <div className='ParamBody'>
                                <p className='ItalicContent'>
                                        Something Went Wrong.
                                </p>

                                <p style={{textAlign:'center',
                                            fontSize:'15px',
                                            marginTop:'10px'}}>
                                            Try Again.
                                </p>
                            </div>
            setParamContent(Content)
        }

    },[selfScoreDef.selfServiceParametersByKeyStatus])


  return (
    <div>
        <section onClick={()=>setSection('proceed')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
          
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            {scoreCardDisplay}
        </section>

        {selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS && ParamContent}

     

        <Modal isOpen={createParamIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Custom Parameters." >
            <DefineParameters closeModal={closeParamCreateModal}
                              accessToken={accessToken}
                              setcreateRuleIsOpen = {setcreateRuleIsOpen}
                              setShowCardCreated={setShowCardCreated}
                              setParamName={setParamName}
                              />
        </Modal>

        <Modal isOpen={selfScoreCardIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create ScoreCard Name." >
               <CreateSelfScoreCard setSection={setSection} 
                                accessToken={accessToken} 
                                closeModal={closeSelfScoreCardModal}
                                setcreateParamIsOpen={setcreateParamIsOpen}
                                
               />
        </Modal>

        <Modal isOpen={createRuleIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Rules." >
            <RulesModal closeModal={closeRuleCreateModal}
                              accessToken={accessToken}
                              customDecisionCard={customDecisionCard}
                              mainScoreCardKey={mainScoreCardKey}
                              setMainParameterKey={setMainParameterKey}
                              setParamName={setParamName}
                              setParameterType={setParameterType}
                              paramName={paramName}
                              mainParameterKey={mainParameterKey}
                              parameterType={parameterType}
                              />
        </Modal>

   
        
        
    </div>
  )
}

export default DefineScoreCard

   /**
         <Modal isOpen={selfScoreCardIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create ScoreCard Name." >
               <CreateSelfScoreCard setSection={setSection} 
                                accessToken={accessToken} 
                                closeModal={closeSelfScoreCardModal}
                                setcreateParamIsOpen={setcreateParamIsOpen}
                                
               />
        </Modal>
         */