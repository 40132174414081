import React,{useState} from 'react'
import { resetEditLoanSection,editLoanSection,selectEditLoanSectionState } from '../../../redux/Slices/EditLoanSection'
import BusinessInfoSection from '../../../pages/Loans/LoanForms/subSections/BusinessInfoSection'
import ResidentialAddressSection from '../../../pages/Loans/LoanForms/subSections/ResidentialAddressSection'
import SalesEstimateSection from '../../../pages/Loans/LoanForms/subSections/SalesEstimateSection'
import ClientIdSection from '../../../pages/Loans/LoanForms/subSections/ClientIdSection'
import LoanDetSection from '../../../pages/Loans/LoanForms/subSections/LoanDetSection'
import { useForm } from "react-hook-form"
import './EditLoan.css'
import cancelLoanSection from '../../../assests/icons/cancelLoanSection.png'
import Utils from '../../../library/Utils'
import { useDispatch,useSelector } from 'react-redux'
import Spinner from '../../../svg/Spinner/Spinner'
import { PostDataStatus } from '../../../library/Variables'
import { resetLoanFileData } from '../../../redux/Slices/LoanFile'
import { useParams } from 'react-router'

function EditLoanSectionModal(props) {
    const sectionType = props.sectionType
    const slicedData = props.slicedData
    const firstName = props.firstName
    const lastName = props.lastName
    const middleName = props.middleName
    const clientId = props.clientId
    const accessToken = props.accessToken
    const sectionName = props.sectionName
    const editLoanState = useSelector(selectEditLoanSectionState)
    const dispatch = useDispatch()
    let contentPart
    const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm()
    const handleListSelect = (value) => {}
    const [fixedClientFields,setFixedClientFields] = useState({firstName:'',lastName:'',middleName:'',clientId:''})
    const {key} = useParams()

    const onSubmitForm = ()=>{
        const allFields = getValues()
        let customFieldObj
        
        if(sectionType==='Client Information'){
                
                const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>
                {
                    if(value){
                        return {fieldname:key,fieldvalue:value}
                    }
                    else{
                        return {fieldname:key,fieldvalue:''}
                    }
                }
                )
                customFieldObj = {
                    key,
                    sectionName,
                  "firstName":fixedClientFields.firstName,
                  "lastName":fixedClientFields.lastName,
                  "middleName":fixedClientFields.middleName,
                  "clientId":fixedClientFields.clientId,
                  customLoanFields: [...arrayOfObjects]
        }}
        
        else{
            const arrayOfObjects = Object.entries(allFields).map(([key, value]) =>
                {
                    if(value){
                        return {fieldname:key,fieldvalue:value}
                    }
                    else{
                        return {fieldname:key,fieldvalue:''}
                    }
                })
            customFieldObj = {
                key,
                sectionName,
                customLoanFields: [...arrayOfObjects]     
             }
        }
          
        dispatch(editLoanSection({accessToken,data:customFieldObj}))
    }


    if(editLoanState.editResponseStatus === PostDataStatus.INITIAL){
            
            if(sectionType==='Client Information'){
                contentPart = <ClientIdSection fixedClientFields={fixedClientFields} setValue={setValue} setFixedClientFields={setFixedClientFields} register={register} erros={errors} 
                            handleListSelect={handleListSelect} info={props.client_Info} slicedData={slicedData}
                            triggerAmountSave={props.triggerAmountSave} setTriggerAmountSave={props.setTriggerAmountSave}
                            setTriggerPhoneSave={props.setTriggerPhoneSave} triggerPhoneSave={props.triggerPhoneSave} 
                            firstName={firstName} lastName={lastName} middleName={middleName} clientId={clientId}
                            />
            }

            else if(sectionType==='Residential Address'){
                contentPart = <ResidentialAddressSection register={register} setValue={setValue} erros={errors} 
                                handleListSelect={handleListSelect} 
                                slicedData={slicedData} info={props.residential_Address}/>
            }

            else if(sectionType==='Loan Details'){
                contentPart = <LoanDetSection register={register} setValue={setValue} erros={errors} handleListSelect={handleListSelect} 
                            slicedData={slicedData} info={props.loan_Details} triggerAmountSave={props.triggerAmountSave} 
                            setTriggerAmountSave={props.setTriggerAmountSave}
                            setTriggerPhoneSave={props.setTriggerPhoneSave} triggerPhoneSave={props.triggerPhoneSave} />
            }

            else if(sectionType==='BUSINESS DETAILS'){
                contentPart = <BusinessInfoSection register={register} erros={errors} setValue={setValue} handleListSelect={handleListSelect} 
                            slicedData={slicedData} info={props.business_Info} triggerAmountSave={props.triggerAmountSave} 
                            setTriggerAmountSave={props.setTriggerAmountSave}
                            setTriggerPhoneSave={props.setTriggerPhoneSave} triggerPhoneSave={props.triggerPhoneSave}/>
            }

            else {
                contentPart = <SalesEstimateSection register={register} erros={errors} setValue={setValue} 
                            getValues={getValues} handleListSelect={handleListSelect} 
                            slicedData={slicedData} info={props.sales_Estimate} triggerAmountSave={props.triggerAmountSave} 
                            setTriggerAmountSave={props.setTriggerAmountSave}
                            setTriggerPhoneSave={props.setTriggerPhoneSave} triggerPhoneSave={props.triggerPhoneSave}/>
            }
    }

    if (editLoanState.editResponseStatus === PostDataStatus.FETCHING){
        contentPart =  
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'80px',boxSizing:"border-box",
                    justifyContent:"space-between",height:"100%"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Saving...</p>
            <Spinner/>
        </div>
    }

    if(editLoanState.editResponseStatus === PostDataStatus.SUCCESS){
        contentPart =  
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'80px',boxSizing:"border-box",
                    justifyContent:"space-between",height:"100%"}}>
            <p style={{textAlign:"center",fontSize:'16px'}}>Succesfully Saved</p>
            <div className='buttonHold'>   
                <section onClick={()=>{dispatch(resetLoanFileData());dispatch(resetEditLoanSection());
                                      props.closeModal()}}>Done</section>
            </div>
        </div>
    }

    if(editLoanState.editResponseStatus === PostDataStatus.FAILURE){
        contentPart =  
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'80px',boxSizing:"border-box",
                    justifyContent:"space-between",height:"100%"}}>
            <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
            <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{editLoanState.errorEditResponse}</p>

            <div className='buttonHold'>   
                <section onClick={()=>{props.closeModal();dispatch(resetEditLoanSection())}}>Close</section>
            </div>
        </div>
    }

  return (
    <div style={{width:'70vw',height:'500px',overflow:'auto'}}>
        <section style={{display:"flex",justifyContent:"space-between",alignItems:"center",
                        position:'absolute',top:'0px',height:'70px',width:"70vw",padding:"0px 15px",
                        backgroundColor:"white",boxSizing:"border-box"}}>
            <span style={{fontSize:"23px",color:'rgba(19, 15, 38, 1)',fontWeight:"700"}}>{`EDIT ${sectionType}`}</span>
            <span onClick={()=>{props.closeModal()}} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:'pointer'}}><img src={cancelLoanSection}/></span>
        </section>
        <div className={editLoanState.editResponseStatus === PostDataStatus.INITIAL?"sign-up-details-form-main":'loadingForm'}>
            {contentPart} 

            {editLoanState.editResponseStatus === PostDataStatus.INITIAL && <section style={{display:"flex",justifyContent:"end",width:'100%',marginTop:"20px",marginRight:"15px",marginBottom:"20px"}}>
                <section onClick={()=>onSubmitForm()} style={{backgroundColor:"rgba(64, 123, 255, 1)",borderRadius:"10px",color:"white",height:"50px"
                        ,width:"150px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                    Save
                </section>
            </section>}
        </div>
        
    </div>
  )
}

export default EditLoanSectionModal