import React from "react";

function LogoContainer(props) {
  return (
    <div className="no-logo-url" style={props.mainStyle}>
      {props.name.charAt(0)}
    </div> 
  )
}

export default LogoContainer;