import React,{useState,useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectKybEntireDataState,
         resetKybData,
         getCacDataAsync, 
         getAdvancedCacDataAsync,
         resetAdvancedCacData,
         resetCacData} from '../../../../../redux/Slices/KybOptions'
import { PostDataStatus } from '../../../../../library/Variables'
import errorImage from '../../../../../assests/icons/errorImage.png'
import selectedIcon from '../../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../../assests/icons/nonSelected.png'
import successImg from '../../../../../assests/icons/amlFound.png'
import './cacModal.css'
import { getReportDetailKyb,resetReportKyb } from '../../../../../redux/Slices/KybOptions'

function CacModal({accessToken,inputStyle,customStyles,setKybToShow,setViewReport,setVerificationType}) {
  const dispatch = useDispatch()
  const [simpleSearchType,setSimpleSearchType] = useState(true)
  const kybState = useSelector(selectKybEntireDataState)
  const [modalIsOpen, setIsOpen] = useState(true);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmit = (formData) => {
    const obj = {rcNumber:formData.inputName,
                accessToken}
    if(simpleSearchType){

      dispatch(getCacDataAsync(obj))
    }

    else{
      dispatch(getAdvancedCacDataAsync(obj))
    }
  }

  const SearchTypeSet = (data)=>{
    if(data==='simple'){
      setSimpleSearchType(true)
    }
    else{
      setSimpleSearchType(false)
    }
  }


  const getDetails = (token,uuid,type)=>{
        setIsOpen(false);
        setKybToShow(null);
        dispatch(resetCacData());
        dispatch(resetKybData());                                    
        dispatch(resetAdvancedCacData());  

        setVerificationType(type);
        setViewReport(true)
        dispatch(resetReportKyb())
        dispatch(getReportDetailKyb({type,uuid,accessToken:token}))
}



  const modalInitial = <form  onSubmit={handleSubmit(onSubmit)}>
  <div style={{padding:'15px',height:'300px',display:"flex",justifyContent:"space-around",flexDirection:'column'}}>
       <p className='title-class'>CAC Verification</p>
       <div style={{marginTop:'15px'}}>
           <p>Input RC Number</p>
           <input  style={inputStyle}{...register("inputName", { required: true })} />
           {errors.inputName && <span className="text-error">This field is required</span>}
       </div>
       <div style={{margin:'15px 0px'}}>
            <p style={{color:'rgba(54, 60, 79, 1)',fontSize:'16px'}}>Select search type</p>
            <div style={{display:"flex",columnGap:"10px",marginTop:"15px"}}>
                <section onClick={()=>SearchTypeSet('simple')} style={{display:'flex',cursor:'pointer',justifyContent:"center",alignItems:"center",columnGap:"5px"}}>
                    <span style={{display:'flex',justifyContent:"center",alignItems:"center"}}><img src={simpleSearchType?selectedIcon:unselectedIcon}/></span>
                    <span style={{fontWeight:simpleSearchType?'700':'400'}}>Simple CAC Lookup</span>
                </section>
                <section onClick={()=>SearchTypeSet('advanced')} style={{display:'flex',cursor:'pointer',justifyContent:"center",alignItems:"center",columnGap:"5px"}}>
                    <span style={{display:'flex',justifyContent:"center",alignItems:"center"}}><img src={!simpleSearchType?selectedIcon:unselectedIcon}/></span>
                    <span style={{fontWeight:!simpleSearchType?'700':'400'}}>Advanced CAC Lookup</span>
                </section>
            </div>
       </div>
       <label className="bureau-credit-check-accept-check">
           <input type="checkbox" {...register("chargeAccept",
           { required: "You must agree to the charge before generating a report" })} />
           <p>By checking this box, you are agreeing to the call fee</p>
       </label>
       {errors.chargeAccept?.message && <span className="text-error">{errors.chargeAccept?.message}</span>}

       <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                             setKybToShow(null);
                                             dispatch(resetCacData());
                                             dispatch(resetAdvancedCacData())}}>Cancel</span>
           <button className='check' type="submit" >Check</button>
       </div>
   </div>
  </form>

  const ModalLoading = <div className='modal-loading'>
    <Spinner></Spinner>
    <p style={{textAlign:'center'}}>Loading...</p>
  </div>

const ModalSuccess = 
    <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <img style={{width:'40px'}} src={successImg}/>
        </div>
        <p style={{textAlign:'center',
                  color:"#2A38A4",
                  fontSize:"24px",
                  fontWeight:"700"}}>
                  CAC Extraction Successfull
        </p>
        <p style={{color:'#363C4F',
                  fontSize:"16px",
                  fontWeight:"400" }}>
            Click Done to see
        </p>
        <div className='modal-initial-button-hold'>
              <span className='check' onClick={()=>getDetails(accessToken,kybState?.cacExtraction?.uuid,
                                                              kybState?.cacExtraction?.type)}>
                                      Done
              </span>
              
        </div>
    </div>


const ModalSuccessAdvanced = 
    <div className='modal-error'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <img style={{width:'40px'}} src={successImg}/>
        </div>
        <p style={{textAlign:'center',
                  color:"#2A38A4",
                  fontSize:"24px",
                  fontWeight:"700"}}>
                  Advanced CAC Extraction Successfull
        </p>
        <p style={{color:'#363C4F',
                  fontSize:"16px",
                  fontWeight:"400" }}>
            Click Done to see
        </p>
        <div className='modal-initial-button-hold'>
              <span className='check' onClick={()=>getDetails(accessToken,kybState?.advancedCacExtraction?.uuid,
                                                              kybState?.advancedCacExtraction?.type)}>
                                      Done
              </span>
              
        </div>
    </div>



  const ModalError = <div className='modal-error'>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <img style={{width:'40px'}} src={errorImage}/>
    </div>
    <p style={{textAlign:'center'}}>{kybState.cacErrorMessage}</p>

    <div className='modal-initial-button-hold'>
           <span className='cancel' onClick={()=>{setIsOpen(false);
                                             setKybToShow(null); 
                                             dispatch(resetCacData())}}>Cancel</span>
           
       </div>
  </div>

const ModalErrorAdvanced = <div className='modal-error'>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <img style={{width:'40px'}} src={errorImage}/>
      </div>
      <p style={{textAlign:'center'}}>{kybState.advancedCacErrorMessage}</p>

      <div className='modal-initial-button-hold'>
            <span className='cancel' onClick={()=>{setIsOpen(false);
                                              setKybToShow(null); 
                                              dispatch(resetAdvancedCacData())}}>Cancel</span>
            
        </div>
      </div>

  
  const [modal,setModal] = useState(modalInitial)

  useEffect(()=>{
        if(kybState.cacStatus===PostDataStatus.INITIAL){
            setModal(modalInitial)
        }
        if(kybState.cacStatus===PostDataStatus.FETCHING){
            setModal(ModalLoading)
        }

        if(kybState.cacStatus===PostDataStatus.SUCCESS){
            setModal(ModalSuccess)
        }

        if(kybState.advancedCacStatus===PostDataStatus.SUCCESS){
          setModal(ModalSuccessAdvanced)
        }

        if(kybState.cacStatus===PostDataStatus.FAILURE){
          setModal(ModalError)
        }

        
        if(kybState.advancedCacStatus===PostDataStatus.FAILURE){
          setModal(ModalErrorAdvanced)
        }
    },[kybState,simpleSearchType])


  return (
    <Modal isOpen={modalIsOpen}  style={customStyles}
            contentLabel="Add Affordability Model" >
                {modal}
            </Modal>
  )
}

export default CacModal