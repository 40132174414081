import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const addSupportingDocsAsync = createAsyncThunk(
  "fraudDetect/addSupportingDocs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(`/api/supporting-documents`, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);


export const deleteSupportingDocsAsync = createAsyncThunk(
  "fraudDetect/deleteSupportingDocs",
  async (params) => {
    const key = params.key
    const response = await clientApi.delete(
      `/api/supporting-documents/${key}`
    );

    return response;
  }
);