import classNames from "classnames";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import ErrorDisplay from "./form/ErrorDisplay";
import "./index.css";

export const ScoreSlider = ({
  progress = 40,
  onChange,
  value,
  name,
  ...props
}) => {
  const getColor = () => {
    const startColor = "#5A8DFF";
    const endColor = "#2040B2";
    return `linear-gradient(to right, ${startColor} ${progress}%, ${endColor} ${
      100 - progress
    }%)`;
  };

  const marks = {
    40: {
      style: {
        color: "red",
      },
      label: (
        <span
          style={{
            backgroundColor: "#5A8DFF",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20.83px",
          }}
        >
          Allowed
        </span>
      ),
    },

    90: {
      label: (
        <span
          style={{
            backgroundColor: "#2A38A4",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20.83px",
          }}
        >
          Blocked
        </span>
      ),
    },
  };
  return (
    <>
      <Slider
        onChange={onChange}
        value={value}
        range
        allowCross={false}
        DotStyle={{
          height: 30,
          width: 30,
          marginLeft: -10,
          marginTop: -8,
          color: "#fff",
          boxShadow: "8px 8px 45px -px rgba(42, 56, 164, 1)",
          backgroundColor: "#5A8DFF",
          border: "none",
        }}
        defaultValue={[40, 90]}
        //   min={0}
        //   max={100}
        //   marks={marks}
        //   step={null}
        //   included={false}
        railStyle={{
          height: "14px",
          borderRadius: "20px",
          background: getColor(),
        }}
        trackStyle={{
          height: "14px",
          borderRadius: "20px",
          background: "none",
        }}
        handleStyle={{
          height: 30,
          width: 30,
          marginLeft: -10,
          marginTop: -8,
          color: "#fff",
          boxShadow: "8px 8px 45px -px rgba(42, 56, 164, 1)",
          backgroundColor: "#5A8DFF",
          border: "none",
        }}
        min={0}
        max={100}
        //  marks={marks}
        included={false}
        handleRender={handleRender}
        // defaultValue={20}
        {...props}
      />
      <div style={{ marginTop: "20px" }}>
        <ErrorDisplay fieldName={name} />
      </div>
    </>
  );
};

export const handleRender = (node, props) => {
  return (
    <div
      {...node.props}
      className={classNames(node.props.className, "custom-rc-handle")}
    >
      {props.value}

      {props.index === 0 && (
        <span
          style={{
            backgroundColor: "#5A8DFF",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20.83px",
            position: "absolute",
            top: "40px",
          }}
        >
          Allowed
        </span>
      )}

      {props.index === 1 && (
        <span
          style={{
            backgroundColor: "#2A38A4",
            padding: "10px",
            color: "#fff",
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20.83px",
            position: "absolute",
            top: "40px",
          }}
        >
          Blocked
        </span>
      )}
    </div>
  );
};
