import { createSlice } from "@reduxjs/toolkit";

import {
  createCustomRulesAsync,
  deleteCustomRulesAsync,
  fetchCustomRuleAsync,
  fetchCustomRuleByIdAsync,
} from "./thunks";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const createCustomRulesSlice = createSlice({
  name: "createCustomRules",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createCustomRulesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCustomRulesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(createCustomRulesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action;
      });
  },
});

export const customRulesSlice = createSlice({
  name: "customRules",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomRuleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomRuleAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchCustomRuleAsync.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const customRuleSlice = createSlice({
  name: "customRule",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomRuleByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomRuleByIdAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(fetchCustomRuleByIdAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const deleteCustomRuleSlice = createSlice({
  name: "deleteCustomRule",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(deleteCustomRulesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCustomRulesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })

      .addCase(deleteCustomRulesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export const { reset } = createCustomRulesSlice.actions;
