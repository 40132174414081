import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Utils from "../../../../library/Utils";
import {
  deleteRuleSelector,
  deleteRulesAsync,
  fetchRules,
  rulesSelector,
} from "../../../../redux/Slices/fraudDetect/ruleSlice";
import { ConfirmDialog } from "../../components/AlertModal/ConfirmDialog";
import { Table } from "../../components/Table";
import { InnerLayout } from "../../components/layout/SecondaryLayout";
import styles from "./index.module.css";

const RulesDashboard = ({ configuration, accessToken, user }) => {
  const navigate = useNavigate();

  const deleteRule = useSelector(deleteRuleSelector);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const rules = useSelector(rulesSelector);
  const [ruleList,setRuleList] = useState()
  const [selectedRule,setSelectedRule] = useState()
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(()=>{
    if(!Utils.isFieldEmpty(rules.data?.data?.items)){
      let dataHold = [...rules.data?.data?.items]
      dataHold.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setRuleList(dataHold)
    }
  },[rules])


  useEffect(() => {
    dispatch(fetchRules(filters));
  }, [accessToken, dispatch, filters, refresh]);

  useEffect(() => {
    if (
      !deleteRule.status !== "loading" &&
      deleteRule &&
      deleteRule.data &&
      deleteRule.data.data
    ) {
      setRefresh((prev) => !prev);
    }
  }, [deleteRule]);
  return (
    <InnerLayout>
      <div className={styles.formWrapper}>
        <div className={styles.titleWrapper}>
          <h5 className={styles.formtTitle}>Manage Rules</h5>

          <button
            className={styles.applyBtn}
            onClick={() => navigate("/fraud-detect/create-rule")}
          >
            Create Rule
          </button>
        </div>

        <div className={styles.tableContainer}>
          <Table
            showPagination={true}
            loading={rules.status === "loading"}
            pageCount={rules?.data?.data?.totalPages}
            gotoPage={(page) =>
              setFilters((prev) => ({ ...prev, pageNumber: page }))
            }
            columns={[
              {
                title: "Rule Name",
                field: "name",
                sortable: true,
              },

              {
                title: "Risk Score",
                field: "score",
                sortable: true,
                Cell: ({ entry }) => (
                  <span>
                    {entry.minScore}, {entry.maxScore}
                  </span>
                ),
              },
              {
                title: "Created Date",
                field: "createdAt",
                sortable: true,
              },
              {
                title: "Updated Date",
                field: "updatedAt",
                sortable: true,
              },

              {
                title: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span>Action</span>
                  </div>
                ),
                field: "action",
                Cell: ({ entry }) => <ActionMenu entry={entry} />,
              },
            ].filter(Boolean)}
            data={
              // rules.data &&
              ruleList?.map((item) => ({
                ...item,
                updatedAt: Utils.formatLongDateAndTime(item?.updatedAt),
                createdAt: Utils.formatLongDateAndTime(item?.createdAt),
              })) ?? []
            }
          />
        </div>
      </div>
    </InnerLayout>
  );
};

const ActionMenu = ({ entry }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const deleteRule = useSelector(deleteRuleSelector);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <div style={{ display: "flex", gap: "18px" }}>
        <button
          onClick={() => navigate(`/fraud-detect/rules/${entry?.key}`)}
          className={styles.contained}
        >
          View
        </button>

        <span onClick={() => setOpenDeleteDialog(true)}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.3248 9.96875C19.3248 9.96875 18.7818 16.7037 18.4668 19.5407C18.3168 20.8957 17.4798 21.6898 16.1088 21.7148C13.4998 21.7618 10.8878 21.7648 8.27979 21.7098C6.96079 21.6828 6.13779 20.8788 5.99079 19.5478C5.67379 16.6858 5.13379 9.96875 5.13379 9.96875"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.708 6.74219H3.75"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.4406 6.739C16.6556 6.739 15.9796 6.184 15.8256 5.415L15.5826 4.199C15.4326 3.638 14.9246 3.25 14.3456 3.25H10.1126C9.53358 3.25 9.02558 3.638 8.87558 4.199L8.63258 5.415C8.47858 6.184 7.80258 6.739 7.01758 6.739"
              stroke="#E40000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      {openDeleteDialog && (
        <ConfirmDialog
          showTitle={false}
          showCancelBtn={false}
          open={openDeleteDialog}
          toggleModal={() => {}}
          description="Are you sure you want to block this rule?"
          loading={deleteRule.status === "loading"}
          handleDone={() => navigate("/fraud-detect/rules")}
          okHandler={() => dispatch(deleteRulesAsync({ id: entry.name }))}
          cancelHandler={() => setOpenDeleteDialog(false)}
        />
      )}
    </>
  );
};

export default RulesDashboard;
