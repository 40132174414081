import React from 'react'
import { useForm } from "react-hook-form";
import { AffordabilityData } from '../DataHolder/TransactDataBank';

function AffordabilityForm() {

  const listHeader = ['Date','Loan Tenure','DTI']
  const { register, watch, formState: { errors }, handleSubmit, reset } = useForm();
  return (
    <div className="affordability-body">
        <div className="affordability-new-container">
          <div className="affordability-new-text-container">
            <div className="affordability-header-info">
              <h1 className="detail-header">Generate New Affordability</h1>
              <div className="tooltip">
                <img src="/assets/icons/info-circle-color.svg" alt="" />
                <span className="tooltip-text">
                  {"Our affordability model helps to predict future ranges of disposable income, and liability using past income. " +
                  "The model uses liability status, loan application and transaction data. This helps  " 
                  + '' + " to drive more informed lending decisions"}
                </span>
              </div>
            </div>
            <p className="affordability-header-note text-medium" >
              A charge will be incurred each time a new Affordability Model is generated.
            </p>
          </div>
          <form onSubmit={()=>''} className="affordability-form-container">
            <div className="affordability-form-fields">
              <div className="aff-inputs-container">
                <div className="aff-inputs">
                  <h4>DTI (%)</h4>
                  <input className="inputs" type="text"  {...register("dti", { 
                    required: "DTI is required",
                    pattern: {
                      value: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i,
                      message: "DTI must be between 1 to 100"
                    },
                    min: {
                      value: 0.01,
                      message: "DTI must be greater than 0"
                    }
                  } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.dti?.message}</span>
                </div>
                <div className="aff-inputs">
                 <h4>Loan Tenure (months)</h4>
                  <input className="inputs" type="text" {...register("loanTenure", 
                    { required: "Loan Tenure is required", 
                      pattern: {
                        value: /^(\d?)+(\.\d{1,2})?$/,
                        message: "Loan Duration must be a number"
                      },
                      min: {
                        value: 0.01,
                        message: "Loan Duration must be greater than 0"
                      } } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.loanTenure?.message}</span>
                </div>
                <div className="aff-inputs">
                  <h4>Monthly Loan Repayment <span>(optional)</span></h4>
                  <input className="inputs" type="text" defaultValue={'3 months'}
                    {...register("loanRepay", { required: "Enter 0 if excluding from calculation" } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.loanRepay?.message}</span>
                </div>
              </div>
              <p className="aff-default-fields-message text-medium">
                <span>Note: </span>
                Default values come from the corresponding Statement Analytics. To override default values, change them in 
                the input fields above.</p>
            </div>
            <div className="aff-btn">
              <button type="submit" className="button-square button-solid">Generate</button>
            </div>
          </form>
        </div>


        <section className='AgentTable'>
              <section className='titleBreakHold'>
                <p style={{
                    color:'#2A38A4',
                    fontSize:"28px",
                    fontWeight:"700"
                }}>{AffordabilityData.title}</p>
                
              </section>
  
              <div>
                <section style={{display:"flex",justifyContent:'space-between',marginTop:"20px"}}>
                  {listHeader.map((header,index)=>(header==='Action'?
                    <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                  :<section style={{fontWeight:"700",width:"18%",textAlign:"center"}}>{header}</section>)
                                    )}
                </section>
                <section className='TransactTableRow'>
                  {AffordabilityData.data.map((content,index)=>
                  
                  <div  style={{display:"flex",justifyContent:'space-between'}} key={index}>
                    <section style={{width:"18%",textAlign:"center"}}>{content.date}</section>
                    <section style={{width:"18%",textAlign:"center"}}>{content.loanTenure}</section>
                    <section style={{width:"18%",textAlign:"center"}}>{content.dti}</section>
                  </div>)}
                </section>
              </div>
          </section>
        </div>
        
  )
}

export default AffordabilityForm