import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../../../api/clientApi";

export const getMatchCriteria = () => clientApi.get(`/api/match-criteria`);

export const fetchMatchCriteriaAsync = createAsyncThunk(
  "fraudDetect/fetchMatchCriteriaAsync",
  async (params) => {
    const response = await getMatchCriteria();

    return response;
  }
);
