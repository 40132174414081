import React from 'react'

import SerialOutPutPrint from './SerialOutPutPrint';
import Utils from '../../../../../library/Utils';

function CreditAndEnquire({data}) {
  const AllKeys = Object.keys(data)

  const filteredKeys = AllKeys.filter(str => (str.includes('credit') || str.includes('enquiries')));

  return (
    <>
        {!Utils.isFieldEmpty(filteredKeys) && <SerialOutPutPrint keys={filteredKeys} data={data} header={'Credit History and Enquiries'}/>}
    </>
  )
}

export default CreditAndEnquire

