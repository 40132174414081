import React,{useEffect, useState} from 'react'
import './formPrev.css'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import calendarIcon from '../../../assests/icons/calendarNew.png'
import arrowLeft from '../../../assests/icons/arrowLeftNew.png'
import clientIcon from '../../../assests/icons/clientIdIcon.png'
import infoIcon from '../../../assests/icons/InfoSquareIcon.png'
import profileIcon from '../../../assests/icons/profileNew.png'
import Tabs from '../../../components/Tabs/Tabs'
import ClientInfo from './clientInfo/ClientInfo'
import BusinessInfo from './businessInfo/BusinessInfo'
import FormVerification from './verification/FormVerification'
import FormCreditScore from './creditScore/FormCreditScore'
import SupportDocument from './supportDocuments/SupportDocument'
import { getLoanFileData,selectLoanFileState } from '../../../redux/Slices/LoanFile'
import { PostDataStatus,FetchDataStatus } from '../../../library/Variables'
import LoanApprovalModal from '../../../components/Modals/LoanModals/LoanAprovalModal'
import Modal from "react-modal";
import {
  useSelector,
  useDispatch 
} from 'react-redux';
import Spinner from '../../../svg/Spinner/Spinner'
import Utils from '../../../library/Utils'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetGetAllLoanData } from '../../../redux/Slices/Loan'
import downloadIcon from '../../../assests/icons/docDownload.png'
import { selectLoanReportPdfStatus,downloadLoanPdfAsync,resetLoanDownload } from '../../../redux/Slices/LoanReportDownload'
import { selectLoanState,getLoanParameter} from '../../../redux/Slices/Loan'
import EditLoanSectionModal from '../../../components/Modals/LoanModals/EditLoanSectionModal'
import { resetEditLoanSection } from '../../../redux/Slices/EditLoanSection'
import AuditTrail from './AuditTrail/AuditTrail'


function FormPreview(props) {
  const dispatch = useDispatch()
  const accessToken = props.accessToken
  const user = props.user
  const permissions = user.user.permissions
  const configuration = props.configuration
  const loanPreviewState = useSelector(selectLoanFileState)
  const loanParamState = useSelector(selectLoanState)
  const [showForm,setShowForm] = useState(true)
  const [newlyVerified,setNewlyVerified] = useState(false)
  const [newlyScored,setNewScore] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalOpen] = useState(false);
  const navigate = useNavigate()
  const loanReportState = useSelector(selectLoanReportPdfStatus)
  const [sectionType,setSectionType] = useState('')
  const [clientInf,setClientInf] = useState([])
  const [loanDetts,setLoanDetss] = useState([])
  const [businessInf,setBusinessInf] = useState([])
  const [residentialAdd,setResidentialAdd] = useState([])
  const [salesEst,setSalesEst] = useState([])
  const [slicedData,setSlicedData] = useState([])
  const [refreshScore,setRefreshScore] = useState(false)
  const [triggerAmountSave,setTriggerAmountSave] = useState('')  
  const [triggerPhoneSave,setTriggerPhoneSave] = useState('') 
  const [sectionName,setSectionName] = useState()  
  

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  function closeEditModal() {
    setEditModalOpen(false);
    setTriggerAmountSave('')
    setTriggerPhoneSave('')
    dispatch(resetEditLoanSection())
    //dispatch(resetDownload());
  }



  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const supportCustomStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: 'auto',
      width: '70vw',
      position:'relative',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  useEffect(()=>{
    if(loanReportState.loanPdfStatus === FetchDataStatus.SUCCESS){
      toast.success("Report downloaded", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      dispatch(resetLoanDownload())
    }

    if(loanReportState.loanPdfStatus === FetchDataStatus.FAILURE){
      toast.error("Download Failed" , {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      dispatch(resetLoanDownload())
    }
  },[loanReportState.loanPdfStatus])


  const downloadLoanReport = ()=>{
    if(permissions.downloadLoanRequest){
      const mainData = loanPreviewState.loanFileData.data
      
      const loanName = `Loan Application for ${mainData.firstName} ${mainData.lastName} ${mainData.middleName?mainData.middleName:''}`
      dispatch(downloadLoanPdfAsync({accessToken,key,loanName}))
    }
    else{
      toast.warning("You are not permitted to download loan reports", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
      
  }

  const approveNewLoan = ()=>{
      if(loanPreviewState?.loanFileData?.data?.approvalStatus === 'PENDING'){
        if(permissions.approveLoanStatus){

          //if((newlyVerified || loanPreviewState?.loanFileData?.data?.isVerified) && (newlyScored || loanPreviewState?.loanFileData?.data?.isScoreGenerated)){
          if((newlyVerified || loanPreviewState?.loanFileData?.data?.isVerified)){
            setIsOpen(true)
          }
    
          else{
            toast.warning("Ensure Verification Form is filled.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        }

        else{
          toast.info('You are not permitted to approve a loan', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }
      }

      else{
        toast.info(`Application ${loanPreviewState?.loanFileData?.data?.approvalStatus} already.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

  
  }


  

      useEffect(()=>{
        if(loanParamState.loanParametersStatus===FetchDataStatus.SUCCESS){
          let client_Info = []
          let business_Info = []
          let residential_Address = []
          let loan_Details = []
          let sales_Estimate = []
          loanParamState.loanParameters.filter(item=>
              {
                if(item.sectionKey===1){
                  business_Info.push(item)
                }
                else if(item.sectionKey===2){
                  client_Info.push(item)
                }
                else if(item.sectionKey===3){
                  residential_Address.push(item)
                }
      
                else if(item.sectionKey===4){
                  loan_Details.push(item)
                }
                else if(item.sectionKey===5){
                  sales_Estimate.push(item)
                }
              }
            )
            setClientInf(client_Info)
            setBusinessInf(business_Info)
            setSalesEst(sales_Estimate)
            setLoanDetss(loan_Details)
            setResidentialAdd(residential_Address)
        }
      },[loanParamState])



  let content
  const { key } = useParams();
  let tabBodiesArray = []
  localStorage.setItem("insightsLastVisited", `/loanApproval/${key}`);

  if(loanPreviewState.loanFileDataStatus === FetchDataStatus.INITIAL || loanParamState.loanParametersStatus===FetchDataStatus.INITIAL){
    content = <Spinner/>
    dispatch(getLoanFileData({accessToken,key}))
    dispatch(getLoanParameter({accessToken}))
  }

  if(loanPreviewState.loanFileDataStatus === FetchDataStatus.FETCHING){
    content = <Spinner/>
  }


  if(loanPreviewState.loanFileDataStatus===FetchDataStatus.SUCCESS && loanPreviewState.loanFileData!==null){
    const mainData = loanPreviewState.loanFileData.data
    const customData = loanPreviewState.loanFileData.data?.customParameters
    
  
   
    let clientInfo = <ClientInfo configuration={configuration} customData={customData} setSectionType={setSectionType}
                      setEditModalOpen={setEditModalOpen} approvalStatus={mainData.approvalStatus} setSlicedData={setSlicedData} 
                      permissions={permissions} setSectionName={setSectionName}
                      />;
    let businessInfo = <BusinessInfo signaturePath={loanPreviewState.loanFileData.data?.documents} accessToken={accessToken} 
                        setSectionType={setSectionType} approvalStatus={mainData.approvalStatus} customData={customData} 
                        configuration={configuration} 
                        setEditModalOpen={setEditModalOpen} setSlicedData={setSlicedData} 
                        permissions={permissions} setSectionName={setSectionName}
                        />
    let verification = <FormVerification configuration={configuration}  newlyVerified={newlyVerified} setNewlyVerified={setNewlyVerified} verifiedStatus={loanPreviewState.loanFileData.data?.isVerified} showForm={showForm} setShowForm={setShowForm} customData={customData} accessToken={accessToken}/>;
    let creditScore = <FormCreditScore  user={user} configuration={configuration} 
                                        scoreStatus={loanPreviewState.loanFileData.data?.isScoreGenerated} 
                                        newlyScored={newlyScored} setNewScore={setNewScore} accessToken={accessToken} 
                                        verifiedStatus={loanPreviewState.loanFileData.data?.isVerified} 
                                        newlyVerified={newlyVerified} customData={customData}
                                        setRefreshScore={setRefreshScore} refreshScore={refreshScore}
                                        />;
    let supportDocuments = <SupportDocument accessToken={accessToken} configuration={configuration}/>

    let auditTrailInfo = <AuditTrail accessToken={accessToken}/>
  
      tabBodiesArray.push({
        title: "Client Info",
        content: clientInfo
      });
  
      tabBodiesArray.push({
        title: "Business Info",
        content: businessInfo
      });
  
      tabBodiesArray.push({
        title: "Supporting Documents",
        content: supportDocuments
      });


      tabBodiesArray.push({
        title: "Verification",
        content: verification
      });
  
     permissions.viewLoanScorecard && tabBodiesArray.push({
        title: "Credit Score",
        content: creditScore
      }) 
      

      tabBodiesArray.push({
        title: "Audit trail",
        content: auditTrailInfo
      });


      content =  <div className='previewHold'>
      <section className='PreviewHead'>
            <section  onClick={()=>{dispatch(resetGetAllLoanData());navigate('/loanApproval')}} className='backView'>
                <span><img src={arrowLeft}/></span>
                <span style={{marginLeft:'10px'}}>Back to Applications</span>
            </section>
            <section className='section-top-hold'>
                <div >
                    <section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={profileIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Applicant:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:'rgba(19, 15, 38, 1)'}}>{`${mainData.firstName} ${mainData.lastName} ${mainData.middleName?mainData.middleName:''}`}</span>
                    </section>
                    <section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={infoIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Status:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:mainData.approvalStatus==='PENDING'?'rgba(251, 109, 6, 1)':
                      mainData.approvalStatus==='APPROVED'?'rgba(0, 119, 12, 1)':'rgba(228, 0, 0, 1)'}}>{mainData.approvalStatus}</span>
                    </section><section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={clientIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Client ID:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:'rgba(19, 15, 38, 1)'}}>{mainData.clientId}</span>
                    </section>
                </div>
                <div >
                    <section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={calendarIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Submitted Date:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:'rgba(19, 15, 38, 1)'}}>{Utils.formatLongDateMonthAndYear(mainData.createdDate)}</span>
                    </section>
                    <section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={calendarIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Verification Date:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:'rgba(19, 15, 38, 1)'}}>{Utils.isFieldEmpty(mainData?.verificationDate)?"--": Utils.formatLongDateMonthAndYear(mainData?.verificationDate)}</span>
                    </section>
                    <section style={{gap:'7px',display:'flex'}}>
                      <span><img style={{display:'flex',alignItems:'center',justifyContent:"center"}} src={calendarIcon}/></span>
                      <span style={{fontSize:'16px',fontWeight:"400",color:'rgba(106, 113, 125, 1)'}}>Approval Date:</span>
                      <span style={{fontSize:"16px",fontWeight:"500",color:'rgba(19, 15, 38, 1)'}}>{ Utils.isFieldEmpty(mainData?.updatedDate)?"--": Utils.formatLongDateMonthAndYear(mainData.updatedDate)}</span>
                    </section>
                </div>
                <div style={{display:"flex",flexDirection:'column',alignItems:"center",rowGap:"10px"}} >
                  <section onClick={()=>approveNewLoan()} style={{textAlign: 'right',cursor:"pointer",height:'50px',color:'white',fontSize: '14px',backgroundColor:'rgba(64, 123, 255, 1)',
                  width: '150px',
                  display: mainData.approvalStatus==='APPROVED'?'none':mainData.approvalStatus==='DECLINED'?'none':'flex',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '7px'}}>Loan Approval</section>

                  <section onClick={()=>downloadLoanReport()} style={{display:"flex",justifyContent:"space-around",alignItems:'center',width:"150px",columnGap:'3px',borderRadius: '7px',cursor:'pointer',height:'50px',color:'white',backgroundColor:'rgba(64, 123, 255, 1)'}}>
                    <span><img src={downloadIcon}/></span>
                    <span style={{color:'white',fontSize:"13px"}}>Download report</span>
                  </section>
                </div>
            </section>

            <section style={{marginTop:'30px'}}>
                <div></div>
                <Tabs tabBodies={tabBodiesArray} extraClass="top-level-tabs" showForm={showForm} />
            </section>
      </section>

      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Add Signature" >
          <LoanApprovalModal setShowForm={setShowForm} accessToken={accessToken} loanApplicationKey={key} closeModal={closeModal}/>
      </Modal> 


      <Modal isOpen={editModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeEditModal} style={supportCustomStyles}
          contentLabel="Edit Loan Section" >
          <EditLoanSectionModal client_Info={clientInf} residential_Address={residentialAdd} 
                                business_Info={businessInf} sales_Estimate={salesEst} loan_Details={loanDetts} 
                                setShowForm={setShowForm} accessToken={accessToken} loanApplicationKey={key} 
                                closeModal={closeEditModal} sectionType={sectionType} slicedData={slicedData}
                                triggerAmountSave={triggerAmountSave} setTriggerAmountSave={setTriggerAmountSave}
                                triggerPhoneSave={triggerPhoneSave} setTriggerPhoneSave={setTriggerPhoneSave}
                                firstName={mainData.firstName} lastName={mainData.lastName} middleName={mainData.middleName}
                                clientId={mainData.clientId} sectionName={sectionName}
                                />
      </Modal> 
    </div>
  }

  if(loanPreviewState.loanFileDataStatus === FetchDataStatus.FAILURE){
    content = 
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{loanPreviewState.errorGetLoanFileData}</p>
      </div>
  }

  return (
   <div>
      {content}
   </div>
  )
}

export default FormPreview