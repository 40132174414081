import React from 'react'
import Utils from '../../../../../../../library/Utils'



function SourceWebLinks({sourceLinks}) {
    
  let Content;
  if(Utils.isFieldEmpty(sourceLinks)){
    Content = <span style={{padding:'10px'}}>No Web Source Links of this Person In Particular.</span>
  }

  if(!Utils.isFieldEmpty(sourceLinks)){
    const linkArray = sourceLinks.split(';')
    Content = <div style={{}}>
                {linkArray.map((element,index)=>
                    <section style={{display:"flex",justifyContent:'space-between',width:"100%",marginBottom:"10px"}}>
                        <span>{index+1}</span>
                        <a href={element}>{element}</a>
                    </section>)
                }
             </div>
  }



  return (
    <div style={{width:"100%",padding:"15px",boxSizing:"border-box"}}>
        {Content}
    </div>
  )
}

export default SourceWebLinks